import { useCallback } from "react";
import { IDataSetModel } from "../../models/dataSets/IDataSetModel";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import { ICreateDataSetModel } from "../../models/dataSets/ICreateDataSetModel";
import { ISchemaValidationModel } from "../../models/dataSets/ISchemaValidationModel";
import { IConversionRequestModel } from "../../models/dataSets/IConversionRequestModel";
import { IConversionResponseModel } from "../../models/dataSets/IConversionResponseModel";
import { IExportModel } from "../../models/export-import/IExportImportModel";
import { IDataSetSchemaModel } from "../../models/dataSets/IDataSetSchemaModel";
import { IDataSetSourceRequest } from "../../models/dataSets/IDataSetSourceRequest";
import { IDataSetSourceResult } from "../../models/dataSets/IDataSetSourceResult";
import { ICloneDataSetModel } from "../../models/dataSets/ICloneDataSetModel";
import { DataSetType } from "../../models/dataSets/DataSetType";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { DataSetStatus } from "../../models/dataSets/DataSetStatus";
import { IHistoryModel } from "../../models/history/IHistoryModel";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { IVersioningModel, VersionComment } from "../../models/versioning/VersioningModels";
import { IMultipleDependenciesRequest } from "../../models/dependencies/IMultipleDependenciesRequest";

/**
 * The data set api hook return interface.
 */
interface DataSetApiHookReturn {
    clone: (cloneDataSetModel: ICloneDataSetModel) => Promise<IDataSetModel>;
    convertJsonToSchema: (model: IConversionRequestModel) => Promise<IConversionResponseModel>;
    convertXmlToXsd: (model: IConversionRequestModel) => Promise<IConversionResponseModel>;
    create: (dataSet: ICreateDataSetModel) => Promise<IDataSetModel>;
    remove: (dataSetId: string) => Promise<void>;
    removeRecords: (dataSetIds: string[]) => Promise<IDeleteErrorResult[]>;
    exportDataSets: (exportModel: IExportModel) => Promise<void>;
    generateSchema: (model: IDataSetSchemaModel) => Promise<string>;
    generateUniqueName: (dataSetId: string) => Promise<{ name: string }>;
    get: (dataSetId: string, usePublicEndpoint?: boolean) => Promise<IDataSetModel>;
    getDataSets: () => Promise<IDataSetModel[]>;
    getDependenciesRecords: (
        datasetId: string,
        dependencyType: DependencyType,
        offset?: number,
        sortFields?: ISortField[],
        filterValue?: string,
    ) => Promise<IEntityResult<IDependencyModel>>;
    getMultipleDependencies: (ids: string[], dependencyType: DependencyType) => Promise<MultipleDependenciesResult>;
    getRecords: (
        filterValue?: string,
        filterType?: DataSetType[],
        filterStatus?: DataSetStatus[],
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IDataSetModel>>;
    getHistoryRecords: (
        dataSetId: string,
        updatedEntityDate?: string,
        offset?: number,
    ) => Promise<IEntityResult<IHistoryModel>>;
    getSource: (request: IDataSetSourceRequest, usePublicEndpoint?: boolean) => Promise<IDataSetSourceResult>;
    nameIsUnique: (name: string) => Promise<boolean>;
    update: (
        dataSetId: string,
        dataSet: Partial<IDataSetModel>,
        versionComment: VersionComment,
    ) => Promise<IDataSetModel>;
    validateSchema: (model: ISchemaValidationModel) => Promise<boolean>;
    updateVersionComment: (versionId: string, comment: VersionComment) => Promise<string>;
    getVersioningRecords: (
        dataSetId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IVersioningModel>>;
    restoreVersion: (datasetId: string, versionId: string, comment: VersionComment) => Promise<IDataSetModel>;
}

/**
 * The use data set api hook.
 */
export const useDataSetApi = (projectId: string): DataSetApiHookReturn => {
    const baseUrl = useCallback(
        (dataSetId?: string) => `/_api/project/${projectId}/datasets${dataSetId ? "/" + dataSetId : ""}`,
        [projectId],
    );

    const versionUrl = useCallback(() => `${baseUrl()}/version/`, [baseUrl]);

    const { httpGetJson, httpPost, httpPut, httpDelete, httpDeleteResponseJson, httpDownload } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const clone = useCallback(
        (cloneDataSetModel: ICloneDataSetModel): Promise<IDataSetModel> =>
            cancellableRequest<IDataSetModel, ICloneDataSetModel>(
                { url: `${baseUrl()}/clone`, body: cloneDataSetModel },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost],
    );

    const convertJsonToSchema = useCallback(
        (model: IConversionRequestModel): Promise<IConversionResponseModel> =>
            cancellableRequest<IConversionResponseModel, IConversionRequestModel>(
                { url: `${baseUrl()}/convertjsontoschema`, body: model },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost],
    );

    const convertXmlToXsd = useCallback(
        (model: IConversionRequestModel): Promise<IConversionResponseModel> =>
            cancellableRequest<IConversionResponseModel, IConversionRequestModel>(
                { url: `${baseUrl()}/convertxmltoxsd`, body: model },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost],
    );

    const create = useCallback(
        (dataSet: ICreateDataSetModel): Promise<IDataSetModel> =>
            cancellableRequest<IDataSetModel, ICreateDataSetModel>({ url: baseUrl(), body: dataSet }, httpPost),
        [baseUrl, cancellableRequest, httpPost],
    );

    const remove = useCallback(
        (dataSetId: string): Promise<void> => cancellableRequest({ url: `${baseUrl(dataSetId)}` }, httpDelete),
        [baseUrl, cancellableRequest, httpDelete],
    );

    const removeRecords = useCallback(
        (dataSetIds: string[]): Promise<IDeleteErrorResult[]> =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: baseUrl(), body: dataSetIds },
                httpDeleteResponseJson,
            ),
        [baseUrl, cancellableRequest, httpDeleteResponseJson],
    );

    const exportDataSets = useCallback(
        (exportModel: IExportModel): Promise<void> =>
            cancellableRequest<void, IExportModel>({ url: `${baseUrl()}/export`, body: exportModel }, httpDownload),
        [baseUrl, cancellableRequest, httpDownload],
    );

    const generateSchema = useCallback(
        (model: IDataSetSchemaModel): Promise<string> =>
            cancellableRequest<string, IDataSetSchemaModel>(
                { url: `${baseUrl()}/generateschema`, body: model },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost],
    );

    const generateUniqueName = useCallback(
        (dataSetId: string): Promise<{ name: string }> =>
            cancellableRequest<{ name: string }>(
                { url: `${baseUrl()}/getuniquename`, queryParams: { dataSetId } },
                httpGetJson,
            ),
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const get = useCallback(
        (dataSetId: string, usePublicEndpoint?: boolean): Promise<IDataSetModel> =>
            cancellableRequest<IDataSetModel>(
                {
                    url: usePublicEndpoint
                        ? `/_api/public/project/${projectId}/datasets/${dataSetId}`
                        : `${baseUrl(dataSetId)}`,
                    authenticated: !usePublicEndpoint,
                },
                httpGetJson,
            ),
        [baseUrl, cancellableRequest, httpGetJson, projectId],
    );

    const getDataSets = useCallback(
        (): Promise<IDataSetModel[]> =>
            cancellableRequest<IDataSetModel[]>(
                {
                    url: `${baseUrl()}/getdatasets`,
                },
                httpGetJson,
            ),
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const getDependenciesRecords = useCallback(
        (
            datasetId: string,
            dependencyType: DependencyType,
            offset?: number,
            sortFields?: ISortField[],
            filterValue?: string,
        ): Promise<IEntityResult<IDependencyModel>> => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                filters.EntityName = filterValue;
                filters.EntityType = filterValue;
            }

            return cancellableRequest<IEntityResult<IDependencyModel>>(
                {
                    url: `${baseUrl(datasetId)}/dependencies`,
                    queryParams: normalizeQueryParams(
                        {
                            filters,
                            sortFields,
                            offset,
                            mode: dependencyType,
                        },
                        true,
                        "EntityName",
                    ),
                },
                httpGetJson,
            );
        },
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const getMultipleDependencies = useCallback(
        (ids: string[], dependencyType: DependencyType) => {
            return cancellableRequest<MultipleDependenciesResult, IMultipleDependenciesRequest>(
                { url: `${baseUrl()}/multipleDependencies`, body: { entityIds: ids, mode: dependencyType } },
                httpPost,
            );
        },
        [baseUrl, cancellableRequest, httpPost],
    );

    const getRecords = useCallback(
        (
            filterValue?: string,
            filterType?: DataSetType[],
            filterStatus?: DataSetStatus[],
            sortFields?: ISortField[],
            offset?: number,
        ) => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                filters.Name = filterValue;
                filters.Description = filterValue;
            }

            if (filterType && filterType.length > 0) {
                filters.Type = JSON.stringify(filterType);
            }

            if (filterStatus && filterStatus.length > 0) {
                const filterStatusArray = [
                    filterStatus.includes("System.Public").toString(),
                    (!filterStatus.includes("System.Private")).toString(),
                ];
                filters.IsPublic = JSON.stringify(Array.from(new Set(filterStatusArray.map((v) => v))));
            }

            return cancellableRequest<IEntityResult<IDataSetModel>>(
                {
                    url: baseUrl(),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [baseUrl, httpGetJson, cancellableRequest],
    );

    const getHistoryRecords = useCallback(
        (dataSetId: string, updatedEntityDate?: string, offset?: number): Promise<IEntityResult<IHistoryModel>> => {
            const filters: Record<string, string> = {};

            if (updatedEntityDate) {
                filters.lastUpdatedDate = updatedEntityDate;
            }

            return cancellableRequest<IEntityResult<IHistoryModel>>(
                {
                    url: `${baseUrl()}/history/${dataSetId}`,
                    queryParams: normalizeQueryParams({
                        filters,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [baseUrl, httpGetJson, cancellableRequest],
    );

    const getSource = useCallback(
        (request: IDataSetSourceRequest, usePublicEndpoint?: boolean): Promise<IDataSetSourceResult> =>
            cancellableRequest<IDataSetSourceResult, IDataSetSourceRequest>(
                {
                    url: usePublicEndpoint
                        ? `/_api/public/project/${projectId}/datasets/getsource`
                        : `${baseUrl()}/getsource`,
                    authenticated: !usePublicEndpoint,
                    body: request,
                },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost, projectId],
    );

    const nameIsUnique = useCallback(
        (name: string): Promise<boolean> =>
            cancellableRequest<boolean>({ url: `${baseUrl()}/nameisunique`, queryParams: { name } }, httpGetJson),
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const update = useCallback(
        (dataSetId: string, dataSet: Partial<IDataSetModel>, versionComment: VersionComment): Promise<IDataSetModel> =>
            cancellableRequest<IDataSetModel, Partial<IDataSetModel & { versionComment: VersionComment }>>(
                {
                    url: `${baseUrl(dataSetId)}`,
                    body: {
                        ...dataSet,
                        versionComment: versionComment,
                    },
                },
                httpPut,
            ),
        [baseUrl, cancellableRequest, httpPut],
    );

    const getVersioningRecords = useCallback(
        (
            dataSetId: string,
            filterValue?: string,
            sortFields?: ISortField[],
            offset?: number,
        ): Promise<IEntityResult<IVersioningModel>> => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                filters.Version = filterValue;
                filters.Comment = filterValue;
            }

            return cancellableRequest<IEntityResult<IVersioningModel>>(
                {
                    url: `${versionUrl()}${dataSetId}`,
                    queryParams: normalizeQueryParams(
                        {
                            filters,
                            sortFields,
                            offset,
                        },
                        false,
                    ),
                },
                httpGetJson,
            );
        },
        [cancellableRequest, httpGetJson, versionUrl],
    );

    const updateVersionComment = useCallback(
        (versionId: string, comment: VersionComment): Promise<string> =>
            cancellableRequest<string, VersionComment>(
                { url: `${versionUrl()}comment/${versionId}`, body: comment },
                httpPut,
            ),
        [cancellableRequest, httpPut, versionUrl],
    );

    const validateSchema = useCallback(
        (model: ISchemaValidationModel): Promise<boolean> =>
            cancellableRequest<boolean, ISchemaValidationModel>(
                { url: `${baseUrl()}/validateschema`, body: model },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost],
    );

    const restoreVersion = useCallback(
        (datasetId: string, versionId: string, comment: VersionComment): Promise<IDataSetModel> =>
            cancellableRequest<IDataSetModel, VersionComment>(
                {
                    url: `${versionUrl()}restore`,
                    body: comment,
                    queryParams: {
                        versionId,
                        datasetId,
                    },
                },
                httpPut,
            ),
        [cancellableRequest, httpPut, versionUrl],
    );

    return {
        clone,
        convertJsonToSchema,
        convertXmlToXsd,
        create,
        remove,
        removeRecords,
        exportDataSets,
        generateSchema,
        generateUniqueName,
        get,
        getDataSets,
        getDependenciesRecords,
        getMultipleDependencies,
        getRecords,
        getHistoryRecords,
        getSource,
        nameIsUnique,
        update,
        validateSchema,
        getVersioningRecords,
        updateVersionComment,
        restoreVersion,
    };
};
