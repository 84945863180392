import { EntityType } from "../models/EntityType";

type EntityMap = { [key: string]: EntityType };

export const dependencyTypeMap: EntityMap = {
    dataset: "DataSet",
    template: "Template",
    language: "Language",
    templatelanguage: "TemplateLanguage",
};
