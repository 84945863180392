import { useCallback, useMemo } from "react";
import { useHttpRequest } from "../HttpRequestHook";
import {
    ContentEntityIdType,
    ContentEntityIdTypeName,
    IContentEntityActionModel,
    IContentEntityModel,
    IContentSummarizeModel,
    ISubFolderModel,
} from "../../models/contentManager/ContentManagerApiModels";
import { BulkActionSummaryCancelResult, BulkOperationSummaryWithPayload } from "../../models/BulkOperationModel";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { ISortField } from "../../models/ISortField";
import { SortOrder } from "../../models/SortOrder";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { IEntityResult } from "../../models/IEntityResult";
import { useRequestWithCancelHandler } from "../RequestWithCancelHandler";
import { createCancelledActionsResult } from "../../helpers/BulkActionHelper";
import { useContentManagerCancellableRequest } from "../ContentManagerCancellableRequestHook";
import { useCancellableRequest } from "../CancellableRequestHook";

interface ContentEntityApiHookReturn {
    copy: (
        model: IContentEntityActionModel,
        abortController: AbortController,
    ) => Promise<BulkActionSummaryCancelResult<ISubFolderModel[]>>;
    getFolderContentRecords: (
        folderId: string | null,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IContentEntityModel>>;
    getSearchContentRecords: (
        folderId: string | null,
        searchValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IContentEntityModel>>;
    move: (model: IContentEntityActionModel) => Promise<BulkOperationSummaryWithPayload<string[]>>;
    removeRecords: (entities: ContentEntityIdType[]) => Promise<IDeleteErrorResult[]>;
    upload: (
        folderId: string | null,
        fileData: FormData,
        abortController: AbortController,
    ) => Promise<BulkActionSummaryCancelResult<IContentSummarizeModel>>;
    download: (targetedEntities: ContentEntityIdTypeName[], fileName: string) => Promise<void>;
}

interface IContentEntityApiProps {
    projectId: string;
    checkParentExistsOnError: boolean;
}

export const useContentEntityApi = ({
    projectId,
    checkParentExistsOnError,
}: IContentEntityApiProps): ContentEntityApiHookReturn => {
    const contentEntityUrl = useMemo(() => `/_api/project/${projectId}/contententity`, [projectId]);

    const { httpDeleteResponseJson, httpGetJson, httpPost, httpPut, httpUpload, httpDownload } = useHttpRequest();
    const { requestWithCancelHandler } = useRequestWithCancelHandler();
    const { cancellableRequest } = useCancellableRequest();
    const { contentManagerCancellableRequest } = useContentManagerCancellableRequest({
        projectId,
        checkParentExistsOnError,
    });

    const copy = useCallback(
        async (
            model: IContentEntityActionModel,
            abortController: AbortController,
        ): Promise<BulkActionSummaryCancelResult<ISubFolderModel[]>> => {
            const url = `${contentEntityUrl}/copy`;
            return await contentManagerCancellableRequest(() =>
                requestWithCancelHandler<
                    BulkActionSummaryCancelResult<ISubFolderModel[]>,
                    BulkActionSummaryCancelResult<ISubFolderModel[]>,
                    IContentEntityActionModel
                >(
                    {
                        url,
                        body: model,
                        signal: abortController.signal,
                    },
                    httpPost,
                    createCancelledActionsResult([]),
                ),
            );
        },
        [contentEntityUrl, contentManagerCancellableRequest, requestWithCancelHandler, httpPost],
    );

    const getFolderContentRecords = useCallback(
        (folderId: string | null, sortFields: ISortField[] = [], offset?: number) => {
            const defaultTypeSortField = { name: "Type", order: SortOrder.Desc };

            if (sortFields.every((sf) => sf.name !== "Type")) {
                sortFields.unshift(defaultTypeSortField);
            }

            const queryParams = normalizeQueryParams({
                sortFields,
                offset,
            });

            if (folderId) {
                queryParams.folderId = folderId;
            }

            return contentManagerCancellableRequest(() =>
                cancellableRequest<IEntityResult<IContentEntityModel>>(
                    {
                        url: `${contentEntityUrl}/list`,
                        queryParams,
                    },
                    httpGetJson,
                ),
            );
        },
        [contentManagerCancellableRequest, cancellableRequest, contentEntityUrl, httpGetJson],
    );

    const getSearchContentRecords = useCallback(
        (folderId: string | null, searchValue?: string, sortFields?: ISortField[], offset?: number) => {
            const searchQuery: Record<string, string> = {};

            if (searchValue) {
                searchQuery.Name = searchValue;
            }

            const queryParams = normalizeQueryParams({
                filters: searchQuery,
                sortFields,
                offset,
            });

            if (folderId) {
                queryParams.folderId = folderId;
            }

            return contentManagerCancellableRequest(() =>
                cancellableRequest<IEntityResult<IContentEntityModel>>(
                    {
                        url: `${contentEntityUrl}/search`,
                        queryParams,
                    },
                    httpGetJson,
                ),
            );
        },
        [contentManagerCancellableRequest, cancellableRequest, contentEntityUrl, httpGetJson],
    );

    const move = useCallback(
        (model: IContentEntityActionModel) =>
            contentManagerCancellableRequest(() =>
                cancellableRequest<BulkOperationSummaryWithPayload<string[]>, IContentEntityActionModel>(
                    { url: `${contentEntityUrl}/move`, body: model },
                    httpPut,
                ),
            ),
        [contentManagerCancellableRequest, cancellableRequest, contentEntityUrl, httpPut],
    );

    const removeRecords = useCallback(
        (entities: ContentEntityIdType[]) =>
            contentManagerCancellableRequest(() =>
                cancellableRequest<IDeleteErrorResult[], ContentEntityIdType[]>(
                    { url: contentEntityUrl, body: entities },
                    httpDeleteResponseJson,
                ),
            ),
        [cancellableRequest, contentEntityUrl, contentManagerCancellableRequest, httpDeleteResponseJson],
    );

    const upload = useCallback(
        async (
            folderId: string | null,
            fileData: FormData,
            abortController: AbortController,
        ): Promise<BulkActionSummaryCancelResult<IContentSummarizeModel>> => {
            const url = `${contentEntityUrl}/upload`;
            return await contentManagerCancellableRequest(() =>
                requestWithCancelHandler<
                    BulkActionSummaryCancelResult<IContentSummarizeModel>,
                    BulkActionSummaryCancelResult<IContentSummarizeModel>,
                    FormData
                >(
                    {
                        url,
                        body: fileData,
                        queryParams: { folderId },
                        signal: abortController.signal,
                    },
                    httpUpload,
                    createCancelledActionsResult({
                        affectedFolders: [],
                        filesUploadContentLength: 0,
                    }),
                ),
            );
        },
        [contentEntityUrl, contentManagerCancellableRequest, requestWithCancelHandler, httpUpload],
    );

    const download = useCallback(
        (targetedEntities: ContentEntityIdTypeName[], fileName: string) =>
            contentManagerCancellableRequest(() =>
                cancellableRequest<void, ContentEntityIdTypeName[]>(
                    { url: `${contentEntityUrl}/download`, body: targetedEntities, fileName },
                    httpDownload,
                ),
            ),
        [cancellableRequest, contentEntityUrl, contentManagerCancellableRequest, httpDownload],
    );

    return {
        copy,
        getFolderContentRecords,
        getSearchContentRecords,
        move,
        removeRecords,
        upload,
        download,
    };
};
