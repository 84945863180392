import React from "react";
import { useDefaultDropdownMenuItems } from "../../../hooks/dropdownMenuItems/DefaultDropdownMenuItems";
import { EntityDropdownMenu } from "../../dropdowns/EntityDropdownMenu";
import { useEditContentItemDropdownMenu } from "../../../hooks/contentManager/DetailsView/EditContentItemDropdownMenuHook";
import { FoldersTree } from "../../../models/contentManager/FolderTree";

interface IContentManagerItemDropdownMenuProps {
    canDelete: boolean;
    canEdit: boolean;
    canRead: boolean;
    foldersTree: FoldersTree;
    parentFolderId: string | null;
}

export const ContentManagerItemDropdownMenu: React.FC<IContentManagerItemDropdownMenuProps> = ({
    canDelete,
    canEdit,
    canRead,
    foldersTree,
    parentFolderId,
}) => {
    const defaultItems = useDefaultDropdownMenuItems(canDelete);
    const customContentManagerItems = useEditContentItemDropdownMenu({
        canEdit,
        canRead,
        foldersTree,
        parentFolderId,
    });

    return (
        <EntityDropdownMenu
            items={[...customContentManagerItems, ...defaultItems]}
            testSelectorValue="entityActionsMenu"
        />
    );
};
