import { useCallback } from "react";
import { useUserContext } from "../contexts/UserContext";

interface IUseLocaleFormatResult {
    formatNumber: (num: number) => string;
}

export const useLocaleFormat = (): IUseLocaleFormatResult => {
    const { locale } = useUserContext();

    const formatNumber = useCallback((num: number) => num.toLocaleString(locale), [locale]);

    return {
        formatNumber,
    };
};
