import React, { useMemo } from "react";
import classNames from "classnames";
import { Nav, TabContent } from "reactstrap";
import { NavTab, INavTabProps } from "./NavTab";

import "./NavTabs.scss";

/**
 * The NavTabs props interface.
 */
export interface INavTabsProps extends React.PropsWithChildren {
    activeTab?: string | number;
    navTabs: INavTabProps[];
    innerTabs?: JSX.Element;
    tabContentClassName?: string;
}

/**
 * The NavTabs component.
 */
export const NavTabs: React.FC<INavTabsProps> = ({ activeTab, children, navTabs, innerTabs, tabContentClassName }) => {
    const displayTabs = useMemo(() => navTabs.filter((navTabProps) => !navTabProps.hidden), [navTabs]);

    return (
        <>
            <div className="nav-tabs-header">
                <Nav tabs role="tablist">
                    {displayTabs.map((navTabProps) => (
                        <NavTab
                            role="tab"
                            aria-controls={navTabProps.tabcontrol}
                            aria-selected={navTabProps.name === activeTab}
                            key={navTabProps.name}
                            active={navTabProps.name === activeTab}
                            {...navTabProps}
                        />
                    ))}
                </Nav>
                {innerTabs}
            </div>
            <TabContent activeTab={activeTab} className={classNames("nav-tab-content", tabContentClassName)}>
                {children}
            </TabContent>
        </>
    );
};
