import React, { useCallback, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    ListGroup,
    Navbar,
    NavItem,
    Nav,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { ProfileDropdownMenu } from "./ProfileDropdownMenu";
import { Button } from "../buttons/Button";
import { SearchNavbarItem } from "../search/SearchNavbarItem";
import { Breadcrumb } from "../breadcrumbs/Breadcrumb";
import { useHeaderNavBarContext } from "../../contexts/HeaderNavBarContext";
import { NavBarSearchContext } from "../../contexts/NavBarSearchContext";
import { useMobileMediaQuery } from "../../hooks/MobileMediaQueryHook";
import { SearchNavbarToggle } from "../search/SearchNavbarToggle";

import "./DefaultNavbar.scss";

const breadcrumbResizeDebounceValue = 250;
let currentBreadcrumbResizeDebounce: number;

/**
 * The Default Navbar props interface.
 */
interface IDefaultNavbarProps {
    sidenavOpen: boolean;
    toggleSidenav?: () => void;
}

/**
 * The Default Navbar functional component.
 */
export const DefaultNavbar: React.FunctionComponent<IDefaultNavbarProps> = ({
    sidenavOpen,
    toggleSidenav,
}: IDefaultNavbarProps) => {
    const { t } = useTranslation();
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    const headerNavBarContext = useHeaderNavBarContext();
    const [breadcrumbContainerWidth, setBreadcrumbContainerWidth] = useState(window.innerWidth);

    const isMobile = useMobileMediaQuery();

    const onToggleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
        e.preventDefault();
        setIsPanelVisible(!isPanelVisible);
    };

    const renderSearchContent = () => (
        <SearchNavbarItem
            onSearch={headerNavBarContext.search.onSearch}
            transform={headerNavBarContext.search.transform}
            searchScopeKey={headerNavBarContext.search.searchScopeKey}
            isPanelVisible={isPanelVisible}
        />
    );

    const handleBreadcrumbContainerResize = useCallback(() => {
        currentBreadcrumbResizeDebounce && clearTimeout(currentBreadcrumbResizeDebounce);
        currentBreadcrumbResizeDebounce = window.setTimeout(() => {
            const windowWidth = window.innerWidth;
            setBreadcrumbContainerWidth(windowWidth);
        }, breadcrumbResizeDebounceValue);
    }, []);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        setBreadcrumbContainerWidth(windowWidth);

        window.addEventListener("resize", handleBreadcrumbContainerResize);

        return () => window.removeEventListener("resize", handleBreadcrumbContainerResize);
    }, [handleBreadcrumbContainerResize]);

    const navBarSearchContextValue = useMemo(() => {
        return { setIsPanelVisible };
    }, []);

    return (
        <>
            <NavBarSearchContext.Provider value={navBarSearchContextValue}>
                <Navbar className={classnames("navbar-top navbar-expand")} container={false}>
                    <Collapse navbar isOpen={true}>
                        {isMobile && (
                            <Nav className="align-items-center" navbar>
                                <NavItem className="d-md-none">
                                    <Button
                                        ariaLabel={t(sidenavOpen ? "Navigation.Toggle.Hide" : "Navigation.Toggle.Show")}
                                        className={classnames("sidenav-toggler", { active: sidenavOpen })}
                                        onClick={toggleSidenav}
                                        color="light"
                                    >
                                        <div className="sidenav-toggler-inner">
                                            <i className="fas fa-bars" />
                                        </div>
                                    </Button>
                                </NavItem>
                            </Nav>
                        )}

                        <Nav
                            className="d-none d-sm-block breadcrumb-container"
                            aria-label={t("Accessibility.Breadcrumb")}
                            tag={"nav"}
                        >
                            <Breadcrumb containerWidth={breadcrumbContainerWidth} />
                        </Nav>

                        <Nav className="align-items-center ms-auto" navbar tag={"nav"}>
                            {isMobile && (
                                <SearchNavbarToggle onMouseDown={onToggleClick} isPanelVisible={isPanelVisible} />
                            )}
                            {!isMobile && !isPanelVisible && (
                                <SearchNavbarToggle onMouseDown={onToggleClick} isPanelVisible={isPanelVisible} />
                            )}
                            {!isMobile && renderSearchContent()}
                            <UncontrolledDropdown nav direction="end">
                                <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden">
                                    <div className="px-3 py-3">
                                        <h6 className="text-sm text-muted m-0">
                                            <Trans
                                                i18nKey="DefaultNavbar.Notifications"
                                                values={{ notificationCount: 13 }}
                                            />
                                        </h6>
                                    </div>

                                    <ListGroup flush></ListGroup>

                                    <DropdownItem className="text-center text-info font-weight-bold py-3" tag="a">
                                        <Trans i18nKey="DefaultNavbar.ViewAll" />
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav navbar>
                            <ProfileDropdownMenu />
                        </Nav>
                    </Collapse>
                    {isMobile && isPanelVisible && renderSearchContent()}
                </Navbar>
            </NavBarSearchContext.Provider>
        </>
    );
};
