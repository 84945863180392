import React, { useCallback, useMemo, useState, useEffect } from "react";
import { ProjectScope } from "../../components/users/ProjectScope";
import { CreateUsersInvitationForm } from "../../components/users/CreateUsersInvitationForm";
import { useModalContext } from "../../contexts/ModalContext";
import { IProjectModel } from "../../models/project/IProjectModel";
import { NotificationService } from "../../services/NotificationService";
import { IInvitationResult, useUsersInvitationHook } from "./UsersInvitationHook";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { UserGroupAvatar } from "../../components/userGroups/UserGroupAvatar";
import { UserGroupTypeMapping } from "../../models/user/IUserGroups";

interface IUseInviteProjectOwnerHookResult {
    showInviteModal: (project: IProjectModel) => void;
}

const supportedInvitationLanguages = ["en", "fr"];

const ownerGroupId = "008F753E-F36B-1410-871C-00E29C9D0F76";

const ownerGroup = [
    {
        value: ownerGroupId,
        label: UserGroupTypeMapping.projectOwner,
        componentIcon: <UserGroupAvatar groupName={UserGroupTypeMapping.projectOwner} parentType="dropdown" />,
    },
];

export const useInviteProjectOwnerModal = (): IUseInviteProjectOwnerHookResult => {
    const { showModal, closeModal, isDisplayed } = useModalContext();
    const { refreshTable } = useTableRefreshContext();
    const [project, setProject] = useState<IProjectModel | null>(null);
    const { createUsersInvitation } = useUsersInvitationHook({ projectId: project?.projectId });

    const projectScope = useMemo(() => project?.name && <ProjectScope name={project?.name} />, [project?.name]);

    const onModalClosed = useCallback(() => {
        closeModal();
        setProject(null);
    }, [closeModal]);

    const onCreated = useCallback(
        ({ inviteCount, inviteSent }: IInvitationResult) => {
            onModalClosed();
            refreshTable("organization-projects-table");
            if (inviteCount === inviteSent) {
                NotificationService.addSuccessNotification({
                    messageKey: "CreateUsersInvitationModal.AllSent",
                });
            } else if (inviteSent > 0) {
                NotificationService.addWarningNotification({
                    messageKey: "CreateUsersInvitationModal.PartiallySent",
                });
            } else {
                NotificationService.addErrorNotification({
                    messageKey: "CreateUsersInvitationModal.NoneSent",
                });
            }
        },
        [onModalClosed, refreshTable],
    );

    useEffect(() => {
        if (project && !isDisplayed) {
            showModal({
                contentToDisplay: CreateUsersInvitationForm,
                entity: null,
                onCreate: createUsersInvitation,
                onCreated,
                formPropsToFlow: {
                    supportedLanguages: supportedInvitationLanguages,
                    availableGroups: ownerGroup,
                    scope: projectScope,
                    projectId: project.projectId,
                },
                modalProps: {
                    onClose: onModalClosed,
                    className: "createUsersInvitationModal",
                    titleKey: "UsersInvitation.InviteOwners",
                    titleIconClassName: "fas fa-user text-green",
                    createLabel: "UsersInvitation.Invite",
                    unsavedWarningBody: "UsersInvitation.UnsavedWarningBody",
                    expandable: true,
                },
            });
        }
    }, [createUsersInvitation, isDisplayed, onCreated, onModalClosed, project, projectScope, showModal]);

    const showInviteModal = useCallback((selectedProject: IProjectModel) => {
        setProject(selectedProject);
    }, []);

    return {
        showInviteModal,
    };
};
