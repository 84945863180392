import React, { useCallback, useMemo, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { TableRowAction, ITableRowActionProps } from "./TableRowAction";
import { TableRowLoadingAction } from "./TableRowLoadingAction";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";

import "./TableRowActions.scss";

/**
 * The table row actions props interface.
 */
interface ITableRowActionsProps {
    actions: ITableRowActionProps[] | null;
    onOpen: () => void;
}

/**
 * The table row actions component.
 */
export const TableRowActions: React.FC<ITableRowActionsProps> = ({ actions, onOpen }) => {
    const [dropDownOpened, setDropDownOpened] = useState(false);
    const { setSelector } = useTestSelector();
    const permissionKeys = useMemo<string[]>(
        () => (actions ? actions.map((a) => a.permissions || []).flat() : []),
        [actions],
    );
    const { isAllowed, isUnknown, isDenied } = usePermissionCheck({ permissionKeys });
    const internalActions = useMemo(() => {
        if (!actions || permissionKeys.some((rp) => isUnknown(rp))) {
            return null;
        }

        return actions
            .filter((action) => {
                if (!action.permissions || action.permissions.length === 0 || action.transform) {
                    return true;
                }

                return action.permissions.every((permission) => isAllowed(permission));
            })
            .map((action) => {
                return action.transform ? action.transform(action, { isAllowed, isDenied }) : action;
            })
            .filter((action): action is ITableRowActionProps => !!action);
    }, [actions, permissionKeys, isAllowed, isDenied, isUnknown]);

    const toggleDropdown = useCallback(() => {
        setDropDownOpened((prevOpen) => {
            if (!prevOpen) {
                onOpen();
            }

            return !prevOpen;
        });
    }, [onOpen]);

    return (
        <Dropdown direction="start" isOpen={dropDownOpened} toggle={toggleDropdown}>
            <DropdownToggle data-toggle="dropdown" aria-expanded={dropDownOpened} tabIndex={-1} outline>
                <i className="far fa-ellipsis" />
            </DropdownToggle>
            <DropdownMenu className="custom-dropdown-menu" container="body" {...setSelector("rowActionsMenu")}>
                {!internalActions ? (
                    <TableRowLoadingAction />
                ) : (
                    internalActions.map((actionProps) => {
                        return (
                            <TableRowAction
                                key={actionProps.label}
                                {...actionProps}
                                enableTestSelector={dropDownOpened}
                            />
                        );
                    })
                )}
            </DropdownMenu>
        </Dropdown>
    );
};
