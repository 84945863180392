import React, { Component } from "react";
import authService, { IAuthorizationState, AuthenticationResultStatus } from "./AuthorizeService";
import { loginActions, queryParameterNames, applicationPaths } from "./ApiAuthorizationConstants";
import { extractErrorMessage } from "../../helpers/ErrorHelper";
import { LoadingScreen } from "../loading/LoadingScreen";
import { accountPaths } from "../../PathConstants";

/**
 * The Login props interface.
 */
interface ILoginProps {
    action: string;
}

/**
 * The Login state interface.
 */
interface ILoginState {
    message?: string | null;
}

/**
 * The Login component class.
 *
 * The main responsibility of this component is to handle the user's login process.
 * This is the starting point for the login process. Any component that needs to authenticate
 * a user can simply perform a redirect to this component with a returnUrl query parameter and
 * let the component perform the login and return back to the return url.
 */
export class Login extends Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);

        this.state = {};
    }

    public componentDidMount(): void {
        const { action } = this.props;
        switch (action) {
            case loginActions.login:
                this.login(this.getReturnUrl()).catch((reason) => {
                    this.setState({
                        message: extractErrorMessage(reason),
                    });
                });
                break;
            case loginActions.loginCallback:
                this.processLoginCallback().catch((reason) => {
                    this.setState({
                        message: extractErrorMessage(reason),
                    });
                });
                break;
            case loginActions.loginFailed:
                {
                    const params = new URLSearchParams(window.location.search);
                    const error = params.get(queryParameterNames.message);
                    this.setState({ message: error });
                }
                break;
            case loginActions.profile:
                this.redirectToProfile();
                break;
            case loginActions.register:
                this.redirectToRegister();
                break;
        }
    }

    public render(): JSX.Element {
        const { action }: ILoginProps = this.props;
        const { message }: ILoginState = this.state;

        if (message) {
            return <div className="centered-content">{message}</div>;
        } else {
            switch (action) {
                case loginActions.login:
                case loginActions.loginCallback:
                    return <LoadingScreen />;
                case loginActions.loginFailed:
                    return <div className="centered-content">Processing login failed</div>;
                case loginActions.profile:
                case loginActions.register:
                    return <div />;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }

    public async login(returnUrl: string): Promise<void> {
        const state: IAuthorizationState = { returnUrl };
        const result = await authService.signIn(state);
        switch (result.status) {
            case AuthenticationResultStatus.redirect:
                break;
            case AuthenticationResultStatus.success:
                this.navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    public async processLoginCallback(): Promise<void> {
        const url = window.location.href;
        const result = await authService.completeSignIn(url);
        switch (result.status) {
            case AuthenticationResultStatus.redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error("Should not redirect.");
            case AuthenticationResultStatus.success:
                this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }

    public getReturnUrl(state?: IAuthorizationState): string {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(queryParameterNames.returnUrl);

        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.");
        }

        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    public redirectToRegister(): void {
        this.redirectToApiAuthorizationPath(
            `${applicationPaths.accountRegisterPath}?${queryParameterNames.returnUrl}=${encodeURI(
                applicationPaths.login,
            )}`,
        );
    }

    public redirectToProfile(): void {
        this.redirectToApiAuthorizationPath(accountPaths.route.profile);
    }

    public redirectToApiAuthorizationPath(apiAuthorizationPath: string): void {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    public navigateToReturnUrl(returnUrl: string): void {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}
