import { createContext, useContext } from "react";
import { PermissionState } from "../hooks/permission/PermissionHook";

type PermissionContextProp = {
    on: (permission: string, callback: (state: PermissionState) => void) => () => void;
    get: (permission: string) => PermissionState;
};

/**
 * The permission context.
 */
export const PermissionHubContext = createContext<PermissionContextProp | null>(null);

/**
 * The permission context hook.
 */
export const usePermissionContext = () => useContext(PermissionHubContext)!;
