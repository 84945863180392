import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons/Button";
import { HeaderActionItem } from "./HeaderActionItem";
import { HeaderToggleItem } from "./HeaderToggleItem";
import { useTableContext } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { TableHeaderCountLabel } from "./TableHeaderCountLabel";
import { TableHeaderCountPill } from "./TableHeaderCountPill";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";

import "./TableHeader.scss";

interface ITableHeaderProps {
    counterPosition: "Start" | "End";
}

export const TableHeader: React.FC<ITableHeaderProps> = <
    TEntityModel,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>({
    counterPosition,
}: ITableHeaderProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const context = useTableContext<TEntityModel, TRowItem, TFilterValue>()!;
    const {
        tableState,
        filterHelper,
        onFilterChange,
        createHelper,
        onToggleCreateEntityModal,
        deleteHelper,
        onSetItemsForDeletion,
        setIsFilterPanelOpen,
        refreshable,
        extraActions,
        keyExtractor,
    } = context;
    const { refreshTable } = useTableRefreshContext();

    const deleteBlocker = useMemo(() => {
        return (
            deleteHelper?.deleteOverride &&
            deleteHelper.deleteOverride({
                selectedItems: tableState.items
                    .filter((i) => tableState.selectedItemIds.includes(keyExtractor(i.item)))
                    .map((i) => i.item),
                totalCount: tableState.totalCount,
                tableId: tableState.tableId,
            })
        );
    }, [
        deleteHelper,
        keyExtractor,
        tableState.items,
        tableState.selectedItemIds,
        tableState.tableId,
        tableState.totalCount,
    ]);

    const renderSelectionActions = useCallback(() => {
        const selectedItemsNotEmpty = tableState.selectedItemIds.length > 0;
        return (
            <>
                {selectedItemsNotEmpty && <TableHeaderCountPill />}
                {extraActions?.map(
                    (ea) =>
                        ea.showWithSelectedItem === selectedItemsNotEmpty && (
                            <div key={ea.id}>{ea.button(context)}</div>
                        ),
                )}
                {selectedItemsNotEmpty && deleteHelper && (
                    <Button
                        ariaLabel={t(deleteHelper.buttonTextKey ?? "Common.Delete", {
                            count: tableState.selectedItemIds.length,
                        })}
                        className="delete-button"
                        icon={deleteHelper.buttonIcon || "fas fa-trash-alt"}
                        color="outline-secondary"
                        {...setSelector("deleteButton")}
                        onClick={() => onSetItemsForDeletion(tableState.selectedItemIds)}
                        disabled={!!deleteBlocker}
                    >
                        {t(deleteHelper.buttonTextKey || "Common.Delete", { count: tableState.selectedItemIds.length })}
                    </Button>
                )}
                {deleteBlocker}
            </>
        );
    }, [
        context,
        deleteBlocker,
        deleteHelper,
        extraActions,
        onSetItemsForDeletion,
        setSelector,
        t,
        tableState.selectedItemIds,
    ]);

    return (
        <>
            <div className="table-header">
                <div className="action-row">
                    <div className="actions-section">
                        {createHelper && (
                            <Button
                                ariaLabel={t(createHelper.buttonTextKey ?? "Common.Create")}
                                className="create-button"
                                icon="fas fa-plus"
                                color="outline-secondary"
                                hidden={tableState.selectedItemIds.length > 0}
                                {...setSelector("createButton")}
                                onClick={() => {
                                    if (createHelper.mode === "modal") {
                                        createHelper.onCreate &&
                                            createHelper.onCreate(() => {
                                                refreshTable(tableState.tableId);
                                            });
                                    } else {
                                        onToggleCreateEntityModal(true);
                                    }
                                }}
                            >
                                {t(createHelper.buttonTextKey ?? "Common.Create")}
                            </Button>
                        )}
                        {counterPosition === "Start" && <TableHeaderCountLabel />}
                        {renderSelectionActions()}
                    </div>
                    <div className="actions-section">
                        {counterPosition === "End" && <TableHeaderCountLabel />}
                        {filterHelper && (
                            <HeaderToggleItem
                                className="filter-toggle"
                                icon="fal fa-filter"
                                activeIcon="fas fa-filter"
                                testSelectorValue="filterToggle"
                                isActive={() => filterHelper.isActive(tableState.filterValue)}
                                onAction={() => setIsFilterPanelOpen(!tableState.isFilterPanelOpen)}
                                ariaLabel={t(tableState.isFilterPanelOpen ? "Filter.HideFilter" : "Filter.ShowFilter")}
                            />
                        )}
                        {refreshable && (
                            <HeaderActionItem
                                className="refresh-action"
                                icon="fal fa-sync"
                                testSelectorValue="refreshButton"
                                onAction={() => {
                                    refreshTable(tableState.tableId, true);
                                }}
                                ariaLabel={t("Common.Refresh")}
                            />
                        )}
                    </div>
                </div>
                {tableState.isFilterPanelOpen &&
                    filterHelper &&
                    filterHelper.filter(tableState.filterValue, onFilterChange)}
            </div>
        </>
    );
};
