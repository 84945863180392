import React from "react";
import { Nav, NavProps } from "reactstrap";
import { CrumbItem } from "./crumbs/CrumbItem";
import { useCrumbs } from "../../hooks/crumbs/Crumbs";
import { BreadcrumbDropdown } from "./BreadcrumbDropdown";
import { useCrumbHierarchyShortener } from "../../hooks/crumbs/CrumbHierarchyShortenerHook";

import "./Breadcrumb.scss";

interface IBreadcrumbProps extends NavProps {
    containerWidth: number;
}

/**
 * The Breadcrumb component.
 */
export const Breadcrumb: React.FunctionComponent<IBreadcrumbProps> = ({ containerWidth, ...props }) => {
    const { crumbs } = useCrumbs();

    const { startingCrumb, baseCrumbs, dropdownCrumbs, trailingCrumbs } = useCrumbHierarchyShortener({
        containerWidth,
        crumbs,
    });

    return (
        <Nav className="nav-breadcrumb align-items-center" navbar {...props}>
            <CrumbItem crumb={startingCrumb} testSelectorValueIndex={0} isCurrentBreadcrumb={baseCrumbs.length === 1} />
            {dropdownCrumbs.length > 0 && <BreadcrumbDropdown crumbs={dropdownCrumbs} />}
            {trailingCrumbs.map((crumb, index) => {
                return (
                    <React.Fragment key={`${index} - ${crumb.name}`}>
                        <CrumbItem
                            crumb={crumb}
                            testSelectorValueIndex={index + 1}
                            isCurrentBreadcrumb={index === trailingCrumbs.length - 1}
                        />
                    </React.Fragment>
                );
            })}
        </Nav>
    );
};
