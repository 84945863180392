import React, { useEffect } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { Outlet } from "react-router-dom";
import { useContentManagerTree } from "../../hooks/contentManager/ContentManagerTreeHook";
import { ContentManagerContext } from "../../contexts/ContentManagerContext";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useContentFileApi } from "../../hooks/contentManager/ContentFileApiHook";
import { translatableExtensionReadyAtom, translatableExtensionsAtom } from "../../atoms/ContentManager";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { LoadingScreen } from "../loading/LoadingScreen";
import { ContentManagerLoadingModal } from "./ContentManagerLoadingModal";

export const ContentManagerLayout = () => {
    const context = useContentManagerTree();
    const { projectId } = useProjectContext();

    const { getTranslatableExtensions } = useContentFileApi(projectId);

    const setTranslatableExtensions = useSetAtom(translatableExtensionsAtom);
    const translatableExtensionReady = useAtomValue(translatableExtensionReadyAtom);

    useEffect(() => {
        (async () => {
            try {
                setTranslatableExtensions(await getTranslatableExtensions());
            } catch (error) {
                defaultRequestErrorHandler(error);
                setTranslatableExtensions([]);
            }
        })();
    }, [getTranslatableExtensions, setTranslatableExtensions]);

    if (!translatableExtensionReady) return <LoadingScreen />;

    return (
        <ContentManagerContext.Provider value={context}>
            <ContentManagerLoadingModal />
            <Outlet />
        </ContentManagerContext.Provider>
    );
};
