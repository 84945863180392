import { Context, createContext, useContext } from "react";
import { FormValidator } from "../formValidators/FormValidator";
import { MultipleDependenciesResult } from "../models/dependencies/MultipleDependencyResult";

export type EntityWithName = { name: string };

/**
 * The edit entity context props.
 */
export interface IEditEntityContextProps<TEntity extends EntityWithName, TValidator extends FormValidator<unknown>> {
    entityProps: {
        setInitialEntityProperties: (entity: Partial<TEntity>) => void;
        entity: TEntity;
        initialEntity: TEntity;
        entityValid: boolean;
        setEntityProperties: (entity: Partial<TEntity>) => void;
        formValidator: TValidator;
        load: () => Promise<TEntity>;
        update: (entity?: TEntity) => Promise<void>;
        delete: (entityName: string) => Promise<void>;
        getDependencies?: () => Promise<MultipleDependenciesResult>;
        entityId?: string;
    };
    errorProps: {
        errors: { [alias in keyof TEntity]: string };
        setErrors: (errors: Partial<{ [alias in keyof TEntity]: string }>) => void;
        resetErrors: () => void;
    };
    validateNameProps: {
        isValidatingName: boolean;
        checkNameIsUnique: (value: string) => void;
    };
    deleteDialogProps: {
        dialogTitleKey: string;
        dialogBodyKey: string;
        entityCount: number;
        toggleCustomDelete?: () => void;
        custom?: boolean;
    };
    dirtyProps: {
        // The EditEntityName Component needs to at least be able to know when the name is Dirty.
        isDirty: (properties?: (keyof TEntity)[]) => boolean;
        promptDialogMessageKey: string;
    };
    savingProps: {
        isSaving: boolean;
        blockSave: boolean;
        toggleBlockSave: () => void;
    };
    permissionsProps: {
        canEdit: boolean;
        canDelete: boolean;
    };
    refreshProps: {
        subscribeRefresh: (callback: () => void) => void;
        dispatchRefresh: () => void;
        unsubscribeRefresh: (callback: () => void) => void;
    };
}

/**
 * The edit entity context.
 */
export const EditEntityContext = createContext<any>(null);

/**
 * The use edit entity context for functional component.
 */
export const useEditEntityContext = <
    TEntity extends EntityWithName,
    TValidator extends FormValidator<unknown>,
>(): IEditEntityContextProps<TEntity, TValidator> =>
    useContext<IEditEntityContextProps<TEntity, TValidator>>(
        EditEntityContext as Context<IEditEntityContextProps<TEntity, TValidator>>,
    );
