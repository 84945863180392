import React from "react";
import { Trans } from "react-i18next";

interface IDefaultDeleteConfirmDialogBody {
    confirmationBodyMessageKey: string;
    count: number;
    confirmationBodyMessageKeyParams?: Record<string, string>;
    confirmationBodyWarningMessageKey?: string;
}

export const DefaultDeleteConfirmDialogBody: React.FC<IDefaultDeleteConfirmDialogBody> = ({
    confirmationBodyMessageKey,
    count,
    confirmationBodyMessageKeyParams,
    confirmationBodyWarningMessageKey,
}: IDefaultDeleteConfirmDialogBody) => {
    return (
        <>
            <span>
                <Trans
                    i18nKey={confirmationBodyMessageKey}
                    values={{
                        count,
                        ...confirmationBodyMessageKeyParams,
                    }}
                />
            </span>
            <p className="text-danger">
                <Trans i18nKey={confirmationBodyWarningMessageKey ?? "DeleteWarning.Permanent"} values={{ count }} />
            </p>
        </>
    );
};
