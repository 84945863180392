import React from "react";
import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";
import { NullableValue } from "../models/NullableValue";

export class PermissionGroupFormValidator<T> extends FormValidator<T> {
    public static readonly descriptionMaxLength: number = 1000;

    protected schema = Yup.object().shape({
        description: Yup.string()
            .nullable(true)
            .max(PermissionGroupFormValidator.descriptionMaxLength, ({ max }) => {
                return <ValidatorError messageKey="Common.DescriptionMaxLengthValidation" options={{ max }} />;
            }),
    });

    public validateDescription(description: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.description.validate(description);
    }
}
