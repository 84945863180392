import { createContext, useContext } from "react";

export interface IBlockOptions {
    componentId: string;
    shouldBlock: boolean;
    messageKey: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

interface INavigationBlockerContextProps {
    setShouldBlock: (blockOptions: IBlockOptions) => () => void;
}

export const NavigationBlockerContext = createContext<INavigationBlockerContextProps>(null!);

export const useNavigationBlockerContext = (): INavigationBlockerContextProps => useContext(NavigationBlockerContext);
