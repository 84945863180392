import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { Table } from "../table/Table";
import { ISortField } from "../../models/ISortField";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { IHistoryModel } from "../../models/history/IHistoryModel";
import { ITableState } from "../../hooks/table/TableReducer";
import { ITableRowItem } from "../table/TableRow";
import { useLocation } from "react-router-dom";
import { IEntityResult } from "../../models/IEntityResult";
import { useTabFirstActivation } from "../../hooks/Tabs";
import { ContentManagerEntityType } from "../../models/EntityType";

/**
 * The history table props interface.
 */
interface IHistoryTableProps {
    getRecords: (updatedEntityDate?: string, offset?: number) => Promise<IEntityResult<IHistoryModel>>;
    updatedEntityDate: string;
    entityType: "DataSet" | "Template" | ContentManagerEntityType;
}

/**
 * The history table component.
 */
export const HistoryTable: React.FC<IHistoryTableProps> = ({
    getRecords,
    updatedEntityDate,
    entityType,
}: IHistoryTableProps) => {
    const location = useLocation();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    // The currentUpdatedDate is set as the updatedEntityDate by default as its purpose is to have an initialValue to compare against.
    const [currentUpdatedDate, setCurrentUpdatedDate] = useState<string>(updatedEntityDate);
    const getHistoryRecords = useCallback(
        (filterValue?: never, sortFields?: ISortField[], offset?: number) => {
            return getRecords(undefined, offset);
        },
        [getRecords],
    );

    const updateHistoryRecords = useCallback(
        (tableState: ITableState<IHistoryModel, ITableRowItem<IHistoryModel>, never>) => {
            const updatedRecords = getRecords(currentUpdatedDate, tableState.offset);
            setCurrentUpdatedDate(updatedEntityDate);
            setTriggerUpdate(false);
            return updatedRecords;
        },
        [currentUpdatedDate, getRecords, updatedEntityDate],
    );

    const isActive = location.hash.toLowerCase() === "#history";

    const { tabActivatedFirstTime } = useTabFirstActivation({ active: isActive });

    // This useEffect will update the currentUpdatedDate if changes are made to the dataset BEFORE the historyTable component is rendered to prevent triggering an update API call.
    useEffect(() => {
        if (!tabActivatedFirstTime) {
            setCurrentUpdatedDate(updatedEntityDate);
        }
    }, [tabActivatedFirstTime, updatedEntityDate]);

    useEffect(() => {
        if (isActive) {
            if (currentUpdatedDate !== updatedEntityDate) {
                setTriggerUpdate(true);
            }
        }
    }, [currentUpdatedDate, isActive, updatedEntityDate]);

    const columnDefs: IEntityTableColumnDef[] = useMemo(() => {
        return [
            {
                type: "JSX",
                content: ({ eventType, userIdentification }: IHistoryModel) => (
                    <Trans
                        i18nKey={
                            eventType === "create"
                                ? `History.${entityType}.CreationEvent`
                                : `History.${entityType}.ModificationEvent`
                        }
                        values={{ userIdentification }}
                    />
                ),
                fieldName: "event",
                displayName: "Common.Event",
                className: "description",
                shouldTruncateText: true,
            },
            {
                type: "DateTime",
                fieldName: "eventDate",
                displayName: "Common.Date",
                className: "date",
                testSelectorColumnName: "date",
            },
        ];
    }, [entityType]);

    return (
        <>
            {tabActivatedFirstTime && (
                <Table
                    columnDefs={columnDefs}
                    getRecords={getHistoryRecords}
                    updateRecords={updateHistoryRecords}
                    triggerUpdate={triggerUpdate}
                    keyExtractor={(item) => item.eventDate}
                    loadingMessageKey="Loading.History"
                    tableId="history"
                    counterPosition="Start"
                />
            )}
        </>
    );
};
