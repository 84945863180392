import React, { useEffect, useMemo, useState } from "react";
import { ValidationError } from "yup";
import { UsersInvitationFormValidator } from "../../formValidators/UsersInvitationFormValidator";
import { ValidatorError } from "../../formValidators/ValidatorError";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { ValidationStatus } from "../../models/entity/validation/ValidationStatus";
import { IUserInvitationFormField } from "../../models/user/invitations/IUserInvitationFormField";
import { IValidationResult } from "../CreateModalEntityHook";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { useEmailValidation } from "../validation/EmailValidationHook";

const extractEmailError = (err: unknown) => {
    let errorMessage: JSX.Element | string = extractErrorMessageOrEmptyString(err);
    if (
        err instanceof ValidationError &&
        err.message === "UsersInvitationFormValidator.EmailUnicityError" &&
        err.params
    ) {
        errorMessage = <ValidatorError messageKey={err.message} options={{ user: err.params.email }} />;
    }

    return errorMessage;
};

export const useUsersInvitationValidation = ({
    emails,
    groupId,
    projectId,
    organizationId,
}: IUserInvitationFormField): IValidationResult<IUserInvitationFormField, "emails"> => {
    const { errors, setError } = useEntityErrors<IUserInvitationFormField>();
    const [emailFieldTouched, setEmailFieldTouched] = useState(false);
    const [emailValidationStatus, setEmailValidationStatus] = useState<ValidationStatus>("Valid");
    const [valid, setValid] = useState(false);

    const validateEmailsUniqueness = useEmailValidation({
        projectId,
        organizationId,
    });

    const formValidator = useMemo(
        () => new UsersInvitationFormValidator(validateEmailsUniqueness),
        [validateEmailsUniqueness],
    );

    useEffect(() => {
        if (emailFieldTouched || emails.length > 0) {
            setEmailFieldTouched(true);
            setEmailValidationStatus("Validating");
            setError("emails", "");
            void formValidator
                .validateEmails(emails)
                .then(() => setEmailValidationStatus("Valid"))
                .catch((err: Error) => {
                    setEmailValidationStatus("Invalid");
                    setError("emails", extractEmailError(err));
                });
        }
    }, [emailFieldTouched, emails, formValidator, setError, groupId]);

    useEffect(() => {
        void formValidator
            .validateGroup(groupId)
            .then(() => setError("groupId", ""))
            .catch((err: Error) => setError("groupId", extractErrorMessageOrEmptyString(err)));
    }, [formValidator, groupId, setError]);

    useEffect(() => {
        void formValidator.isValid({ emails, groupId }).then(setValid);
    }, [emails, formValidator, groupId]);

    return {
        valid,
        errors,
        validationStatuses: { emails: emailValidationStatus },
        setError,
    };
};
