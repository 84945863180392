import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { Table } from "../table/Table";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { useGroups } from "../../hooks/permission/GroupsHook";
import { PermissionGroupNameColumnContent } from "./PermissionGroupNameColumnContent";
import { TabPanel } from "../tabs/TabPanel";
import { useProjectCreatePermissionGroup } from "../../hooks/permission/CreateProjectPermissionGroup";
import { ITableRowItem } from "../table/TableRow";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { ITableContextModel } from "../table/TableContext";
import { AlertPopover } from "../language/AlertPopover";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { useProjectGroupApi } from "../../hooks/project/ProjectGroupApiHook";
import { EntityType } from "../../models/EntityType";
import {
    GroupScope,
    IPermissionsGroupFilter,
    groupFilterProvider,
    partialPermissionGroupColumnDefs,
} from "./PermissionsGroupView";

import "./PermissionsGroupView.scss";

interface IPermissionsGroupViewProps {
    projectId: string;
    groupLink: (groupId: string) => string;
}

type DeleteOverrideProps = { selectedItems: IPermissionsGroupModel[] };
const deleteHelperDeleteOverride = ({ selectedItems }: DeleteOverrideProps) => {
    const systemGroup = selectedItems.find((l) => l.isSystem);
    if (systemGroup) {
        return (
            <AlertPopover
                messageKey="Group.DefaultDeleteWarning"
                messageParams={{ name: systemGroup.name }}
                alertId={systemGroup.groupId}
            />
        );
    }

    return null;
};

const scope: GroupScope = "Project";

export const ProjectPermissionsGroupView: React.FC<IPermissionsGroupViewProps> = ({
    projectId,
    groupLink,
}: IPermissionsGroupViewProps) => {
    const { getGroups } = useGroups({ projectId });
    const tableId = useMemo(() => `${scope}-${projectId}-permissions-group`, [projectId]);
    const getNameColumnRenderContent = ({ name, groupId }: IPermissionsGroupModel) => (
        <PermissionGroupNameColumnContent name={name} groupId={groupId} groupLink={groupLink} />
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            content: getNameColumnRenderContent,
            fieldName: "name",
            shouldTruncateText: true,
            displayName: "Common.Name",
            className: "name",
            sortField: {
                name: "Name",
                order: SortOrder.Asc,
            },
        },
        ...partialPermissionGroupColumnDefs,
    ];

    const { removeGroups, removeSingleGroup } = useProjectGroupApi({ projectId: projectId });

    const managePermissionKey = usePermissionKey({
        permission: PermissionKeys.project.managePermissions,
        projectId,
    });

    const permissionKeys = useMemo(() => ({ permissionKeys: [managePermissionKey] }), [managePermissionKey]);

    const { isAllowed } = usePermissionCheck(permissionKeys);

    const canManagePermissions = useMemo(() => isAllowed(managePermissionKey), [managePermissionKey, isAllowed]);
    const { createHelper } = useProjectCreatePermissionGroup({ canManagePermissions });

    const deleteHelper = useMemo(() => {
        if (canManagePermissions) {
            return {
                confirmationTitleMessageKey: "CommonGroup.DeleteTitle",
                confirmationBodyMessageKey: "CommonGroup.DeleteBody",
                deleteRecords: removeGroups,
                notificationMessageKey: "PermissionsGroupView",
                singleDelete: removeSingleGroup,
                deleteOverride: deleteHelperDeleteOverride,
                entityType: "Permissions.Group" as EntityType,
            };
        }
    }, [canManagePermissions, removeGroups, removeSingleGroup]);

    const groupsFilterHelper = useMemo(() => groupFilterProvider(scope), []);

    const actionProvider = useCallback(
        (
            item: IPermissionsGroupModel,
            context: ITableContextModel<
                IPermissionsGroupModel,
                ITableRowItem<IPermissionsGroupModel>,
                IPermissionsGroupFilter
            >,
        ): ITableRowActionProps[] => {
            const canEdit = canManagePermissions && !item.isSystem;
            const actions: ITableRowActionProps[] = [
                {
                    iconClassName: canEdit ? "fal fa-pencil" : "fal fa-eye",
                    label: canEdit ? "Common.Edit" : "Common.View",
                    tag: Link,
                    testSelectorValue: "editItem",
                    to: groupLink(item.groupId),
                },
            ];

            if (canEdit) {
                actions.push({
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.groupId]),
                    style: { color: colorStyles.red },
                    separated: true,
                });
            }

            return actions;
        },
        [canManagePermissions, groupLink],
    );

    return (
        <TabPanel labelby="group-id" tabId="group">
            <Table
                className="permissions-table"
                columnDefs={columnDefs}
                getRecords={getGroups}
                keyExtractor={(item) => item.groupId}
                nameExtractor={(item) => item.name}
                loadingMessageKey="Loading.Permissions"
                filterHelper={groupsFilterHelper}
                tableId={tableId}
                counterPosition={createHelper ? "End" : "Start"}
                createHelper={createHelper}
                selectable={canManagePermissions}
                actionProvider={actionProvider}
                deleteHelper={deleteHelper}
            />
        </TabPanel>
    );
};
