import React from "react";
import * as Yup from "yup";
import { NullableValue } from "../models/NullableValue";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

/**
 * The editable organizations fields.
 */
export interface IEditableOrganizationFields {
    description: NullableValue<string>;
}

/**
 * The organization form validator class.
 */
export class OrganizationFormValidator extends FormValidator<IEditableOrganizationFields> {
    public static readonly organizationDescriptionMaxLength: number = 1000;

    protected schema = Yup.object().shape({
        description: Yup.string()
            .nullable(true)
            .max(OrganizationFormValidator.organizationDescriptionMaxLength, ({ max }) => {
                return (
                    <ValidatorError
                        messageKey="OrganizationFormValidator.DescriptionMaxLengthValidation"
                        options={{ max }}
                    />
                );
            }),
        activationKey: Yup.string()
            .nullable()
            .matches(/^$|^[0-9a-z]{5}-[0-9a-z]{5}-[0-9a-z]{5}-[0-9a-z]{5}-[0-9a-z]{5}$/i, () => {
                return <ValidatorError messageKey="Licensing.LicenseKey.Invalid" />;
            }),
    });

    public validateDescription(description: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.description.validate(description);
    }

    public validateActivationKey(activationKey: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.activationKey.validate(activationKey);
    }
}
