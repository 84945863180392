import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { AccountView } from "../AccountView";
import { LoadingScreen } from "../../loading/LoadingScreen";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";

const loadingScreenKey = "PersonalData.View.Loading";

/**
 * The manage personal data view component.
 */
export const ManagePersonalDataView: React.FC = () => {
    const { t } = useTranslation();
    const { getPasswordSettings } = useAccountApi();
    const [hasPassword, setHasPassword] = useState<boolean | null>(null);
    const [loadingError, setLoadingError] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const settings = await getPasswordSettings();
                setHasPassword(settings.userHasPassword);
            } catch {
                setLoadingError(true);
            }
        })();
    }, [getPasswordSettings]);

    if (loadingError) {
        return <></>;
    }

    if (hasPassword === null) {
        return <LoadingScreen>{t(loadingScreenKey)}</LoadingScreen>;
    }

    return (
        <AccountView title={t("Route.PersonalData")} className="personal-data">
            <Outlet context={[hasPassword]} />
        </AccountView>
    );
};
