import React from "react";
import classNames from "classnames";
import { GroupBase, InputProps, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

interface ISelectInputProps
    extends InputProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    id: string;
    searchable?: boolean;
}

export const SelectInput: React.FC<ISelectInputProps> = ({ id, searchable, ...props }) => {
    const { setSelector } = useTestSelector();

    return (
        <components.Input
            {...props}
            id={id}
            className={classNames({ "hidden-input": !searchable })}
            {...setSelector("dropdownFilter")}
        />
    );
};
