import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTabs } from "../../hooks/TabsHook";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { TabContent } from "../tabs/TabContent";
import { LanguageOverview } from "./LanguageOverview";
import { buildDependencies, buildOverview } from "../../helpers/EntityHelper";
import { DependenciesView } from "../dependencies/DependenciesView";
import { IEntityResult } from "../../models/IEntityResult";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { useLanguageHookApi } from "../../hooks/languages/LanguageApiHook";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { ISortField } from "../../models/ISortField";
import { ILanguageModel } from "../../models/Languages/ILanguageModel";
import { LanguageFormValidator } from "../../formValidators/LanguageFormValidator";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { useProjectContext } from "../../contexts/ProjectContext";

/**
 * the list of tabs in the project settings view
 */
const editLanguageViewTabs = ["overview", "dependencies"] as const;

/**
 * the list of tabs in the project settings view as Type
 */
type EditLanguageTabType = (typeof editLanguageViewTabs)[number];

/**
 * The Project Settings View Tabs
 */
export const EditLanguageViewTabs: React.FC = () => {
    const { projectId } = useProjectContext();
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useTabs<EditLanguageTabType>(editLanguageViewTabs, "overview");
    const {
        entityProps: { entity: editableLanguage },
    } = useEditEntityContext<ILanguageModel, LanguageFormValidator>();
    const tabs: INavMenuItemProps[] = [
        buildOverview(false, false, setActiveTab),
        buildDependencies(false, false, setActiveTab),
    ];

    const { getDependenciesRecords } = useLanguageHookApi(projectId);

    const getDependenciesRecordsHandler: (
        dependencyType: DependencyType,
        offset?: number,
        sortField?: ISortField[],
        filterValue?: string,
    ) => Promise<IEntityResult<IDependencyModel>> = useCallback(
        (dependencyType: DependencyType, offset?: number, sortField?: ISortField[], filterValue?: string) =>
            getDependenciesRecords(editableLanguage.languageId, dependencyType, offset, sortField, filterValue),
        [editableLanguage.languageId, getDependenciesRecords],
    );

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <LanguageOverview />
            </TabContent>
            <TabContent tabId="dependencies" className="dependencies" title={t("Common.Dependencies")} hasNavTabs>
                <DependenciesView
                    getDependenciesRecordsHandler={getDependenciesRecordsHandler}
                    activeTab="usedBy"
                    parentEntityType="language"
                />
            </TabContent>
        </NavMenu>
    );
};
