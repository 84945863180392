import React, { useCallback, useState } from "react";
import classnames from "classnames";
import { FormGroup, UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { EntityForm } from "../forms/EntityForm";
import { FormFeedbackError } from "../forms/feedbacks/FormFeedbackError";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectEmailsComponent } from "../selects/SelectEmailsComponent";
import { SelectLanguage } from "../selects/SelectLanguage";
import { SelectUserGroup } from "../userGroups/SelectUserGroup";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { useUsersInvitationValidation } from "../../hooks/user/UsersInvitationValidation";
import { IUserInvitationFormField } from "../../models/user/invitations/IUserInvitationFormField";
import { SelectComponentOptions } from "../selects/SelectComponent";

interface ICreateUsersInvitationFormProps {
    availableGroups: SelectComponentOptions<string>[];
    onFormChange: (formData: IEntityFormData<IUserInvitationFormField>) => void;
    projectId?: string;
    organizationId?: string;
    scope?: JSX.Element;
    supportedLanguages: string[];
}

export const CreateUsersInvitationForm: React.FC<ICreateUsersInvitationFormProps> = ({
    availableGroups,
    onFormChange,
    projectId,
    organizationId,
    scope,
    supportedLanguages,
}) => {
    const { t } = useTranslation();
    const { data, errors, onChange } = useCreateModalEntity<IUserInvitationFormField, string>({
        initialData: {
            emails: [],
            language: supportedLanguages.length > 0 ? supportedLanguages[0] : "",
            groupId: availableGroups.length === 1 ? availableGroups[0].value : undefined,
            projectId,
            organizationId,
        },
        useValidation: useUsersInvitationValidation,
        isDirty: ({ emails, groupId }) => {
            // when there is only one availableGroup, the group is selected by default;
            // therefore, it needs to always be false for the dirty evaluation.
            const groupDirty = availableGroups.length > 1 && !!groupId;
            return emails.length > 0 || groupDirty;
        },
        onFormChange,
    });

    const [emailFieldTouched, setEmailFieldTouched] = useState(false);

    const handleEmailOnChange = useCallback(
        (vals: string[]) => {
            setEmailFieldTouched(true);
            onChange("emails", vals);
        },
        [onChange],
    );

    return (
        <EntityForm name="create-users-invitation" className="createUsersInvitationForm">
            <FormGroup>
                <LocalizedLabel
                    aria-required="true"
                    for="select-emails"
                    text="CreateUsersInvitationModal.UsersEmailList"
                    required
                />
                <i id="add-language-button-tooltip" className="fa fa-question-circle text-blue" />
                <UncontrolledTooltip placement="top" target="add-language-button-tooltip">
                    <div>{t("CreateUsersInvitationModal.UsersEmailListTooltip")}</div>
                </UncontrolledTooltip>
                <SelectEmailsComponent
                    id="select-emails"
                    onChange={handleEmailOnChange}
                    className={classnames({ "is-invalid": !!errors.emails && emailFieldTouched })}
                    size="large"
                    autoFocus={true}
                    placeholder=""
                />
                {errors.emails && emailFieldTouched && <FormFeedbackError error={errors.emails} />}
            </FormGroup>
            <FormGroup>
                <div className="select-component-box">
                    <SelectLanguage
                        id="select-invitation-language"
                        labelRequired
                        labelKey="Common.Language"
                        languages={supportedLanguages}
                        selectedValue={data.language}
                        onChange={(val) => onChange("language", val)}
                        size="large"
                    />
                </div>
            </FormGroup>
            {scope && <FormGroup>{scope}</FormGroup>}
            <FormGroup>
                {availableGroups.length <= 1 && (
                    <LocalizedLabel aria-required="true" text="Common.Group" required={availableGroups.length > 1} />
                )}
                <SelectUserGroup
                    labelKey="Common.Group"
                    labelRequired={availableGroups.length > 1}
                    availableGroups={availableGroups}
                    onChange={(val) => onChange("groupId", val)}
                    className={classnames({ "is-invalid": !!errors.groupId })}
                    value={data.groupId}
                />
            </FormGroup>
        </EntityForm>
    );
};
