import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileFormValidator } from "../../formValidators/ProfileFormValidator";
import { defaultRequestErrorHandler, extractErrorMessage } from "../../helpers/ErrorHelper";
import { IProfileModel } from "../../models/account/IProfileModel";
import { NotificationService } from "../../services/NotificationService";
import { LoadingScreen } from "../loading/LoadingScreen";
import { SelectComponentOptions } from "../selects/SelectComponent";
import { EditAccountEntityWrapper } from "./EditAccountEntityWrapper";
import { EditProfileForm } from "./EditProfileForm";
import { useUserContext } from "../../contexts/UserContext";
import { useAccountApi } from "../../hooks/account/AccountApiHook";
import { ITimeZoneModel } from "../../models/account/ITimeZoneModel";

const profileFormValidatorProvider = () => new ProfileFormValidator();
const loadingScreenKey = "Profile.View.Loading";

const buildOptions = (options: Record<string, string>[]) => {
    return options.map<SelectComponentOptions<string>>((item) => ({
        label: item.value,
        value: item.key,
    }));
};

const buildOptionsTimeZone = (options: ITimeZoneModel[]) => {
    return options.map<SelectComponentOptions<string>>((item) => ({
        label: item.value.displayName,
        value: item.key,
    }));
};

/**
 * The edit profile view component.
 */
export const EditProfileView: React.FC = () => {
    const { t } = useTranslation();
    const [profile, setProfile] = useState<IProfileModel>();
    const [languageOptions, setLanguageOptions] = useState<SelectComponentOptions<string>[]>([]);
    const [timeZoneOptions, setTimeZoneOptions] = useState<SelectComponentOptions<string>[]>([]);
    const { getProfile, updateProfile, getLanguages, getTimeZones } = useAccountApi();

    const { reloadClaims, refreshProfile } = useUserContext();
    const [loadingError, setLoadingError] = useState(false);

    const loadLanguages = useCallback(async () => {
        try {
            const languages = await getLanguages();
            setLanguageOptions(buildOptions(languages));
        } catch (error) {
            defaultRequestErrorHandler(error);
            setLoadingError(true);
        }
    }, [getLanguages]);

    useEffect(() => {
        (async () => {
            try {
                const profiles = await getProfile();
                setProfile(profiles);
                await loadLanguages();
            } catch (error) {
                NotificationService.addErrorNotification({
                    messageKey: extractErrorMessage(error),
                });
                setLoadingError(true);
            }
        })();
    }, [getProfile, loadLanguages]);

    useEffect(() => {
        (async () => {
            try {
                const timeZones = await getTimeZones();
                setTimeZoneOptions(buildOptionsTimeZone(timeZones));
            } catch (error) {
                defaultRequestErrorHandler(error);
                setLoadingError(true);
            }
        })();
    }, [getTimeZones]);

    const onUpdateProfile = useCallback(
        async (entity: IProfileModel): Promise<IProfileModel> => {
            const updatedProfile = await updateProfile(entity);
            if (profile) {
                if (profile.language !== updatedProfile.language) {
                    reloadClaims();
                    loadLanguages();
                }
                if (
                    profile.firstName !== updatedProfile.firstName ||
                    profile.lastName !== updatedProfile.lastName ||
                    profile.userName !== updatedProfile.userName ||
                    profile.timeZone !== updatedProfile.timeZone
                ) {
                    refreshProfile();
                }
            }

            setProfile(updatedProfile);
            NotificationService.addSuccessNotification({
                messageKey: "Profile.UpdateSuccess",
            });
            return updatedProfile;
        },
        [updateProfile, profile, reloadClaims, loadLanguages, refreshProfile],
    );

    if (loadingError) {
        return <></>;
    }

    if (!profile || !languageOptions || !timeZoneOptions) {
        return <LoadingScreen>{t(loadingScreenKey)}</LoadingScreen>;
    }

    return (
        <EditAccountEntityWrapper
            title={t("Route.Profile")}
            loadedEntity={profile}
            formValidatorProvider={profileFormValidatorProvider}
            update={onUpdateProfile}
            promptDialogMessageKey="Profile.UnsavedWarningBody"
            hasSaveButton
        >
            <EditProfileForm languageOptions={languageOptions} timeZoneOptions={timeZoneOptions} />
        </EditAccountEntityWrapper>
    );
};
