import React from "react";
import classNames from "classnames";
import { ConnectDropTarget } from "react-dnd";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./ViewBody.scss";

/**
 * The entity view body props interface.
 */
interface IEntityViewBodyProps {
    className?: string;
    children?: React.ReactNode;
    dropRef?: ConnectDropTarget;
}

/**
 * The entity view body component.
 */
export const EntityViewBody: React.FunctionComponent<IEntityViewBodyProps> = ({ className, children, dropRef }) => {
    const { setSelector } = useTestSelector();

    return (
        <section ref={dropRef} className={classNames("entity-view-body", className)} {...setSelector("tableContainer")}>
            {children}
        </section>
    );
};
