import React from "react";
import { FileIconType, FileTypeIconSize } from "@fluentui/react-file-type-icons";
import { FluentIcon } from "./FluentIcon";

interface IFluentFolderIcon {
    size?: FileTypeIconSize;
}

export const FluentFolderIcon: React.FC<IFluentFolderIcon> = ({ size }: IFluentFolderIcon) => {
    return <FluentIcon type={FileIconType.folder} size={size} />;
};
