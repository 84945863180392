import { useEffect, useMemo, useState } from "react";
import { FormValidator } from "../../formValidators/FormValidator";
import { NameValidator } from "../../formValidators/NameValidator";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { ValidationStatus } from "../../models/entity/validation/ValidationStatus";
import { NullableValue } from "../../models/NullableValue";
import { EntityError, SetError, useEntityErrors } from "../entity/EntityErrorsHook";
import { useEntityNameValidation } from "../entity/validation/EntityNameValidationHook";

interface IDefaultEntityFields {
    name: string;
    description: NullableValue<string>;
}

interface IEntityValidationProps<TFields, TFormValidator> {
    fields: TFields;
    formValidationProvider: () => TFormValidator;
    nameValidatorProvider: () => NameValidator;
}

interface IEntityValidationResult<TEntityFields> {
    valid: boolean;
    errors: EntityError<TEntityFields>;
    nameValidationStatus: ValidationStatus;
    setError: SetError<EntityError<TEntityFields>>;
}

interface IFormValidatorWithDescription<TEntity extends Omit<IDefaultEntityFields, "name">>
    extends FormValidator<TEntity> {
    validateDescription: (description: NullableValue<string>) => Promise<NullableValue<string> | undefined>;
}

export const useDefaultEntityValidation = <TEntityFields extends IDefaultEntityFields>({
    fields,
    formValidationProvider,
    nameValidatorProvider,
}: IEntityValidationProps<
    TEntityFields,
    IFormValidatorWithDescription<Omit<IDefaultEntityFields, "name">>
>): IEntityValidationResult<TEntityFields> => {
    const [defaultValues] = useState<IDefaultEntityFields>(fields);
    const { errors, setError } = useEntityErrors<TEntityFields>();
    const [valid, setValid] = useState(false);
    const { name, description, otherProperties } = useMemo(() => {
        const { name: n, description: d, ...other } = fields;

        return { name: n, description: d, otherProperties: other };
    }, [fields]);
    const nameValidator = useMemo(() => nameValidatorProvider(), [nameValidatorProvider]);
    const { nameStatus, error: nameError } = useEntityNameValidation({
        defaultName: defaultValues.name,
        name,
        nameValidator,
    });
    const formValidator = useMemo(() => formValidationProvider(), [formValidationProvider]);

    useEffect(() => {
        setError("name", nameError);
    }, [nameError, setError]);

    useEffect(() => {
        void formValidator
            .validateDescription(description)
            .then(() => setError("description", ""))
            .catch((err: Error) => setError("description", extractErrorMessageOrEmptyString(err)));
    }, [description, formValidator, setError]);

    useEffect(() => {
        void Promise.all([
            formValidator.isValid({ description, ...otherProperties }),
            nameValidator.isValid({ name }),
        ]).then((validStates) => setValid(validStates.every((val) => val)));
    }, [name, otherProperties, formValidator, nameValidator, description]);

    return {
        valid,
        errors,
        nameValidationStatus: nameStatus,
        setError,
    };
};
