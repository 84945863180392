import React from "react";
import { useTranslation } from "react-i18next";
import { ActivityListItem } from "./ActivityListItem";
import { EntityType } from "../../models/EntityType";
import { ActivityModel } from "../../models/history/ActivityModel";

interface IActivityPeriodProps {
    period: ActivityModel[];
    title: string;
    entityType: EntityType | "File";
}

export const ActivityPeriod: React.FC<IActivityPeriodProps> = ({ period, title, entityType }) => {
    const { t } = useTranslation();
    return (
        <>
            {period.length > 0 && (
                <>
                    <div>{t(title)}</div>
                    {period.map((activity) => (
                        <ActivityListItem key={activity.eventDate} activity={activity} entityType={entityType} />
                    ))}
                </>
            )}
        </>
    );
};
