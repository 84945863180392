import { CSSProperties } from "react";

export const buildLoadingAnimationSizeStyles = (size: number): CSSProperties => {
    const borderSize = Math.round(size / 6);
    return {
        "--size": `${size}px`,
        "--border-size": `${borderSize}px`,
        "--border-inset": `${-borderSize}px`,
    } as CSSProperties;
};
