import React from "react";
import { DropdownItem, DropdownItemProps } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { DropdownDivider } from "../dropdowns/DropdownDivider";

/**
 * The table row action props interface.
 */
export interface ITableRowActionProps extends DropdownItemProps {
    label: string;
    iconClassName: string;
    labelTranslationParams?: object;
    testSelectorValue?: string;
    enableTestSelector?: boolean;
    permissions?: string[];
    separated?: boolean;
    transform?: (
        actionProps: ITableRowActionProps,
        permissionHelper: {
            isAllowed: (permissionKey: string) => boolean;
            isDenied: (permissionKey: string) => boolean;
        },
    ) => ITableRowActionProps | null;
}

/**
 * The table row action component.
 */
export const TableRowAction: React.FunctionComponent<ITableRowActionProps> = (props: ITableRowActionProps) => {
    const {
        label,
        iconClassName,
        labelTranslationParams: params = {},
        testSelectorValue,
        enableTestSelector,
        transform: _,
        separated,
        ...dropdownItemProps
    } = props;
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <>
            {separated && <DropdownDivider />}
            <DropdownItem {...dropdownItemProps} {...(enableTestSelector ? setSelector(testSelectorValue) : {})}>
                <i className={iconClassName} />
                {t(label, params)}
            </DropdownItem>
        </>
    );
};
