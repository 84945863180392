import { useCallback } from "react";
import { IPermissionGroupFormFieldsModel } from "../../models/permissions/IPermissionGroupFormFieldsModel";
import { IValidationResult } from "../CreateModalEntityHook";
import { NameValidator } from "../../formValidators/NameValidator";
import { CreatePermissionGroupFormValidator } from "../../formValidators/CreatePermissionGroupFormValidator";
import { useDefaultEntityValidation } from "../validation/EntityValidationHook";
import { useProjectGroupApi } from "../project/ProjectGroupApiHook";

interface IUsePermissionGroupValidationProps {
    fields: IPermissionGroupFormFieldsModel;
    projectId: string;
}

export const usePermissionGroupValidation = ({
    fields,
    projectId,
}: IUsePermissionGroupValidationProps): IValidationResult<IPermissionGroupFormFieldsModel, "name"> => {
    const { nameIsUnique } = useProjectGroupApi({ projectId });

    const nameValidatorProvider = useCallback(() => {
        return new NameValidator({
            nameExistErrorKey: "PermissionGroupFormValidator.GroupExists",
            nameIsUnique: (name: string) => nameIsUnique(name),
        });
    }, [nameIsUnique]);

    const formValidationProvider = useCallback(() => new CreatePermissionGroupFormValidator(), []);

    const { valid, errors, nameValidationStatus, setError } = useDefaultEntityValidation({
        fields,
        nameValidatorProvider,
        formValidationProvider,
    });

    return {
        valid,
        errors,
        validationStatuses: { name: nameValidationStatus },
        setError,
    };
};
