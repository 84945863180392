import React from "react";
import { useTranslation } from "react-i18next";
import { ActiveLoginButton } from "./ActiveLoginButton";
import { AvailableLoginButton } from "./AvailableLoginButton";
import {
    IAuthenticationSchemeModel,
    IUserLoginInfoModel,
    ILoginModel,
} from "../../../models/account/IExternalProvider";

import "./EditExternalLoginsForm.scss";

/**
 * The edit external logins form props interface.
 */
interface IEditExternalLoginsFormProps {
    availableExternalLogins: IAuthenticationSchemeModel[];
    currentLogins: IUserLoginInfoModel[];
    removeLogin?: (login: ILoginModel) => void;
    initiateLogin: (provider: string) => void;
}

/**
 * The edit external logins form component.
 */
export const EditExternalLoginsForm: React.FC<IEditExternalLoginsFormProps> = ({
    availableExternalLogins,
    currentLogins,
    removeLogin,
    initiateLogin,
}: IEditExternalLoginsFormProps) => {
    const { t } = useTranslation();

    return (
        <section className="manage-external-logins">
            {currentLogins.length > 0 && (
                <div className="active-logins">
                    <h2>{t("ExternalLogins.ActiveServices")}</h2>
                    <div className="buttons-wrapper">
                        {currentLogins.map((item) => (
                            <ActiveLoginButton
                                provider={item}
                                key={item.providerKey}
                                messageKey="ExternalLogins.LogIn.Button"
                                removeLogin={removeLogin}
                            />
                        ))}
                    </div>
                </div>
            )}

            {availableExternalLogins.length > 0 && (
                <div className="available-logins">
                    <h2>{t("ExternalLogins.AddService.Title")}</h2>
                    <div className="buttons-wrapper">
                        {availableExternalLogins.map((item) => (
                            <AvailableLoginButton
                                provider={item}
                                key={item.name}
                                onClick={() => initiateLogin(item.name)}
                                messageKey="ExternalLogins.AddService.Button"
                            />
                        ))}
                    </div>
                </div>
            )}
        </section>
    );
};
