import React, { Component } from "react";
import authService, { IAuthorizationState } from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import { queryParameterNames, logoutActions, applicationPaths } from "./ApiAuthorizationConstants";
import { extractErrorMessage } from "../../helpers/ErrorHelper";
import { LoadingScreen } from "../loading/LoadingScreen";

/**
 * The Logout state interface.
 */
interface ILogoutState {
    message?: string;
    isReady: boolean;
    authenticated: boolean;
}

/**
 * The Logout props interface.
 */
interface ILogoutProps {
    action: string;
}

/**
 * The Logout component class.
 *
 * The main responsibility of this component is to handle the user's logout process.
 * This is the starting point for the logout process, which is usually initiated when a
 * user clicks on the logout button on the LoginMenu component.
 */
export class Logout extends Component<ILogoutProps, ILogoutState> {
    constructor(props: ILogoutProps) {
        super(props);

        this.state = {
            isReady: false,
            authenticated: false,
        };
    }

    public componentDidMount(): void {
        const { action }: ILogoutProps = this.props;
        switch (action) {
            case logoutActions.logout: {
                const historyState = window.history.state as Record<string, Record<string, any>>;
                if (historyState.usr.local) {
                    this.logout(this.getReturnUrl()).catch((reason) => {
                        this.setState({
                            message: extractErrorMessage(reason),
                        });
                    });
                } else {
                    // This prevents regular links to <app>/authentication/logout from triggering a logout
                    this.setState({ isReady: true, message: "The logout was not initiated from within the page." });
                }
                break;
            }
            case logoutActions.logoutCallback:
                this.processLogoutCallback().catch((reason) => {
                    this.setState({
                        message: extractErrorMessage(reason),
                    });
                });
                break;
            case logoutActions.loggedOut:
                this.setState({ isReady: true, message: "You successfully logged out!" });
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }

        void this.populateAuthenticationState();
    }

    public render(): JSX.Element | undefined {
        const { isReady, message }: ILogoutState = this.state;
        const { action } = this.props;

        if (!isReady) {
            return <div />;
        }

        if (message) {
            return <div className="centered-content">{message}</div>;
        } else {
            switch (action) {
                case logoutActions.logout:
                case logoutActions.logoutCallback:
                    return <LoadingScreen />;
            }
        }
    }

    private async logout(returnUrl: string): Promise<void> {
        const state: IAuthorizationState = { returnUrl };
        const isauthenticated = await authService.isAuthenticated();
        if (isauthenticated) {
            const result = await authService.signOut(state);
            switch (result.status) {
                case AuthenticationResultStatus.redirect:
                    break;
                case AuthenticationResultStatus.success:
                    this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.fail:
                    this.setState({ message: result.message });
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        } else {
            this.setState({ message: "You successfully logged out!" });
        }
    }

    private async processLogoutCallback(): Promise<void> {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
            case AuthenticationResultStatus.redirect:
                // There should not be any redirects as the only time completeAuthentication finishes
                // is when we are doing a redirect sign in flow.
                throw new Error("Should not redirect.");
            case AuthenticationResultStatus.success:
                this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error("Invalid authentication result status.");
        }
    }

    private async populateAuthenticationState(): Promise<void> {
        try {
            const authenticated = await authService.isAuthenticated();
            this.setState({ isReady: true, authenticated });
        } catch (error) {
            const message = extractErrorMessage(error);
            this.setState({ isReady: true, message });
        }
    }

    private getReturnUrl(state?: IAuthorizationState): string {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(queryParameterNames.returnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.");
        }

        return (state && state.returnUrl) || fromQuery || `${window.location.origin}${applicationPaths.loggedOut}`;
    }

    private navigateToReturnUrl(returnUrl: string): void {
        return window.location.replace(returnUrl);
    }
}
