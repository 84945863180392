import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Nav, TabContent } from "reactstrap";
import { Button } from "../buttons/Button";
import { INavMenuItemProps, NavMenuItem } from "./NavMenuItem";
import { getItem, setItem } from "../../helpers/LocalStoragHelper";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useUserContext } from "../../contexts/UserContext";

import "./NavMenu.scss";

/**
 * The NavMenu props interface.
 */
export interface INavMenuProps {
    activeTab?: string | number;
    navTabs: INavMenuItemProps[];
    innerTabs?: JSX.Element;
    children?: React.ReactNode;
}

/**
 * The NavMenu component.
 */
export const NavMenu: React.FC<INavMenuProps> = ({ activeTab, children, navTabs, innerTabs }: INavMenuProps) => {
    const { t } = useTranslation();
    const { userId } = useUserContext();
    const [collapsed, setCollapsed] = useState(getItem<boolean>(userId, "subNavCollapsed") ? true : false);
    const { setSelector } = useTestSelector();

    const onToggle = () => {
        setItem<boolean>(userId, "subNavCollapsed", !collapsed);
        setCollapsed(!collapsed);
    };

    return (
        <>
            <div className={`nav-menu ${collapsed ? "collapsed" : ""}`} {...setSelector("entityNavMenu")}>
                <Nav tabs>
                    {navTabs.map(({ name, icon, ...rest }) => (
                        <NavMenuItem
                            active={name === activeTab}
                            collapsed={collapsed}
                            icon={icon}
                            key={name}
                            name={name}
                            {...rest}
                        />
                    ))}
                    <Button
                        className="nav-menu-toggler"
                        ariaLabel={t(collapsed ? "Sidebar.ShowSidebarMenu" : "Sidebar.HideSidebarMenu")}
                        onClick={onToggle}
                    >
                        <i
                            className={classNames("far", collapsed ? "fa-angle-double-right" : "fa-angle-double-left")}
                        />
                    </Button>
                </Nav>
                {innerTabs}
            </div>
            <TabContent activeTab={activeTab} className="nav-menu-content">
                {children}
            </TabContent>
        </>
    );
};
