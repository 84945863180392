import React from "react";
import "moment/min/locales";
import classNames from "classnames";
import DateTime, { DatetimepickerProps } from "react-datetime";
import { Input, InputProps, InputGroupText, InputGroup } from "reactstrap";
import { LocalizedLabel } from "../LocalizedLabel";
import { FormFeedbackError } from "../feedbacks/FormFeedbackError";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { useUserContext } from "../../../contexts/UserContext";

import "react-datetime/css/react-datetime.css";
import "./ValidatedDateTime.scss";

/**
 * The validated date time props interface.
 */
interface IValidatedDateTimeProps extends DatetimepickerProps {
    error?: string | JSX.Element;
    testSelectorValue?: string;
    id?: string;
}

/**
 * The validated date time component.
 */
export const ValidatedDateTime: React.FunctionComponent<IValidatedDateTimeProps> = ({
    error,
    testSelectorValue,
    className,
    id,
    ...datetimepickerProps
}: IValidatedDateTimeProps) => {
    const { setSelector } = useTestSelector();
    const { locale } = useUserContext();

    return (
        <>
            <DateTime
                className={classNames("validated-date-time", className)}
                renderInput={(props: InputProps): JSX.Element => {
                    return (
                        <InputGroup className="has-validation">
                            <LocalizedLabel
                                for={id ?? "validated-date-time"}
                                text="ApiKey.SelectExpirationDate"
                                hidden
                            />
                            <Input
                                id={id ?? "validated-date-time"}
                                invalid={!!error}
                                {...props}
                                {...setSelector(testSelectorValue)}
                            />
                            <InputGroupText className="icon-right">
                                <i className="fas fa-calendar text-blue" />
                            </InputGroupText>
                            {error && <FormFeedbackError error={error} />}
                        </InputGroup>
                    );
                }}
                locale={locale}
                {...datetimepickerProps}
            />
        </>
    );
};
