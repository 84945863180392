import React from "react";
import { LoadingWrapper } from "../loading/LoadingWrapper";

/**
 * The entity table loading table props interface.
 */
interface IEntityTableLoadingTableProps {
    message: string;
}

/**
 * The entity table loading table component.
 */
export const EntityTableLoadingTable: React.FunctionComponent<IEntityTableLoadingTableProps> = (props: IEntityTableLoadingTableProps) => {
    const { message } = props;

    return (
        <LoadingWrapper
            className="loading-table-wrapper"
            size={48}
            message={message} />
    );
};