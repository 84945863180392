/**
 * The data set status.
 **/
export type DataSetStatus = "System.Public" | "System.Private";

/**
 * Gets the resource key based on the dataset status
 */
export enum DataSetStatusTranslationMap {
    "System.Public" = "Dataset.Filter.Status.Public",
    "System.Private" = "Dataset.Filter.Status.Private",
}
