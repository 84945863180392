import { useCallback } from "react";
import { IServerConfiguration } from "../models/server/IServerConfiguration";
import { useHttpRequest } from "./HttpRequestHook";
import { useCancellableRequest } from "./CancellableRequestHook";

const baseUrl = "/_api/serverconfiguration";

/**
 * Gets the server configuration.
 */
export const useServerConfig = () => {
    const { httpGetJson } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const getConfig = useCallback(
        (): Promise<IServerConfiguration> => cancellableRequest<IServerConfiguration>({ url: baseUrl }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    return {
        getConfig,
    };
};
