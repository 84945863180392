import { IRecentProjectModel } from "../../models/project/IRecentProjectModel";

/**
 * The home state interface.
 */
export interface IHomeState {
    triggerRefresh: boolean;
    fullscreen: boolean;
    projectsLoaded: boolean;
    recentProjectsLoaded: boolean;
    recentProjects: IRecentProjectModel[];
    projectExist: boolean;
    organizationExist: boolean;
    organizationExistLoaded: boolean;
}

interface IProjectLoadedAction {
    type: "PROJECT_LOADED";
}

interface IRecentProjectLoadedAction {
    type: "RECENT_PROJECT_LOADED";
    recentProjects: IHomeState["recentProjects"];
}

interface IToogleFullscreenAction {
    type: "TOGGLE_FULLSCREEN";
    fullscreen: IHomeState["fullscreen"];
}

interface IProjectExistAction {
    type: "SET_PROJECT_EXIST";
    projectExist: IHomeState["projectExist"];
}

interface IOrganizationExistAction {
    type: "SET_ORGANIZATION_EXIST";
    organizationExist: IHomeState["organizationExist"];
}

interface ITriggerRefreshAction {
    type: "TRIGGER_REFRESH";
    triggerRefresh: IHomeState["triggerRefresh"];
}

export type ActionTypes =
    | IProjectLoadedAction
    | IRecentProjectLoadedAction
    | IToogleFullscreenAction
    | IProjectExistAction
    | IOrganizationExistAction
    | ITriggerRefreshAction;

export const initialHomeState: IHomeState = {
    triggerRefresh: false,
    fullscreen: false,
    projectsLoaded: false,
    recentProjectsLoaded: false,
    recentProjects: [],
    projectExist: false,
    organizationExist: false,
    organizationExistLoaded: false,
};

export const homeReducer = (state: IHomeState, action: ActionTypes): IHomeState => {
    switch (action.type) {
        case "PROJECT_LOADED":
            return {
                ...state,
                projectsLoaded: true,
                triggerRefresh: false,
            };
        case "RECENT_PROJECT_LOADED":
            return {
                ...state,
                recentProjects: action.recentProjects,
                recentProjectsLoaded: true,
            };

        case "SET_PROJECT_EXIST":
            return {
                ...state,
                projectExist: action.projectExist,
            };

        case "SET_ORGANIZATION_EXIST":
            return {
                ...state,
                organizationExist: action.organizationExist,
                organizationExistLoaded: true,
            };

        case "TOGGLE_FULLSCREEN":
            return {
                ...state,
                fullscreen: action.fullscreen,
            };

        case "TRIGGER_REFRESH":
            return {
                ...state,
                triggerRefresh: action.triggerRefresh,
            };

        default:
            return state;
    }
};
