import { useEffect, useLayoutEffect, useRef } from "react";
import { unstable_useBlocker as useBlocker, useLocation, useBeforeUnload } from "react-router-dom";
export interface IBlockerControl {
    proceed: () => void;
    reset: () => void;
}

interface IBlocker {
    onBlock: (control: IBlockerControl) => void;
    enabled: boolean;
}

export const useNavigationBlocker = ({ onBlock, enabled }: IBlocker): void => {
    const onBlockRef = useRef(onBlock);
    const { pathname, search } = useLocation();
    const blocker = useBlocker(enabled);
    useLayoutEffect(() => {
        onBlockRef.current = onBlock;
    });

    useEffect(() => {
        if (blocker.state === "blocked") {
            const { pathname: blockerPathname, search: blockerSearch } = blocker.location;
            const pathnamesAreEqual = pathname + search === blockerPathname + blockerSearch;

            if (!enabled) {
                !pathnamesAreEqual ? blocker.proceed() : blocker.reset();
                return;
            }
            const locationState = blocker.location.state as { deleted?: boolean } | undefined;

            const entityDeleted = !!(locationState && locationState.deleted);

            if (entityDeleted || pathnamesAreEqual) {
                blocker.proceed();
                return;
            }

            onBlockRef.current({
                proceed: () => blocker.proceed(),
                reset: () => blocker.reset(),
            });
        }
    }, [blocker, enabled, pathname, search]);

    useBeforeUnload((e) => {
        if (enabled) {
            e.preventDefault();
            e.returnValue = false;
        }
    });
};
