import React from "react";
import { FormGroup } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { EntityForm } from "../forms/EntityForm";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { IServiceHookFormFields } from "../../models/serviceHooks/IServiceHookFormFields";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { useServiceHookValidation } from "../../hooks/serviceHook/ServiceHookValidation";
import { ServiceHookFormFields } from "./ServiceHookFormFields";

interface IServiceHookFormProps {
    onFormChange: (formData: IEntityFormData<IServiceHookFormFields>) => void;
    initialValue: IServiceHookFormFields;
    projectId: string;
}

export const ServiceHookForm: React.FC<IServiceHookFormProps> = ({ onFormChange, initialValue, projectId }) => {
    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: (fields) => useServiceHookValidation({ fields, projectId }),
    });
    const { setSelector } = useTestSelector();

    return (
        <EntityForm name="create-service-hook" className="create-service-hook">
            <FormGroup>
                <LocalizedLabel aria-required="true" for="create-service-hook-name" text="Common.Name" required />
                <ValidatedInputWithStatus
                    id="create-service-hook-name"
                    autoFocus={true}
                    name="entityName"
                    type="text"
                    { ...setSelector("nameTextbox") }
                    onChange={(e) => onChange("name", e.target.value)}
                    error={errors.name}
                    isBusy={validationStatuses.name === "Validating"}
                    value={data.name}
                />
            </FormGroup>
            <ServiceHookFormFields data={data} onChange={onChange} errors={errors} />
        </EntityForm>
    );
};
