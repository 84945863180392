import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useDrag, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { Input } from "reactstrap";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { EntityTableRow } from "../table/EntityTableRow";
import { TableRowActions } from "../tableRowActions/TableRowActions";
import { ITableContextModel, useTableContext } from "./TableContext";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useRenderItems } from "../../hooks/table/RenderItemsHook";
import { ITableRowDragAndDropProps } from "./Table";

import "./TableRow.scss";

/**
 * The table row item interface.
 */
export interface ITableRowItem<TEntityModel> {
    className?: string;
    item: TEntityModel;
}

/**
 * The table row props interface.
 */
export interface ITableRowProps<TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue> {
    item: TEntityModel;
    selected?: boolean;
    actionProvider?: (
        item: TEntityModel,
        context: ITableContextModel<TEntityModel, TRowItem, TFilterValue>,
    ) => ITableRowActionProps[];
    rowIndex: number;
}

/**
 * The table row component.
 */
export const TableRow = <
    TEntityModel extends Record<string, any>,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>({
    item,
    selected,
    actionProvider,
    rowIndex,
}: ITableRowProps<TEntityModel, TRowItem, TFilterValue>): JSX.Element => {
    const context = useTableContext<TEntityModel, TRowItem, TFilterValue>()!;
    const {
        columnDefs,
        onSelect,
        className,
        selectable,
        updateDropableStatus,
        rowDisabled,
        keyExtractor,
        useDefaultActionProviders,
        rowDragAndDropProvider,
        onSetItemsForDeletion,
        tableState: { selectedItemIds, items },
    } = context;

    const [actions, setActions] = useState<ITableRowActionProps[]>([]);

    const disabled = rowDisabled ? rowDisabled(item) : false;
    const itemKey = keyExtractor(item);

    const { setSelector } = useTestSelector();

    const internalActionProvider = useMemo(() => {
        if (!itemKey || (!actionProvider && !useDefaultActionProviders)) {
            return null;
        }

        return () => [
            ...(actionProvider ? actionProvider(item, context) : []),
            ...(useDefaultActionProviders
                ? [
                      {
                          iconClassName: "fal fa-trash-alt",
                          label: "Common.Delete",
                          testSelectorValue: "deleteItem",
                          onClick: () => onSetItemsForDeletion([itemKey]),
                          separated: true,
                      },
                  ]
                : []),
        ];
    }, [itemKey, actionProvider, useDefaultActionProviders, item, context, onSetItemsForDeletion]);

    const renderItems = useRenderItems();

    const onOpen = useCallback(() => {
        if (internalActionProvider) {
            setActions(internalActionProvider());
        }
    }, [internalActionProvider]);

    const { dragProps, dropProps }: ITableRowDragAndDropProps = useMemo(
        () => (rowDragAndDropProvider && rowDragAndDropProvider(item)) || {},
        [item, rowDragAndDropProvider],
    );

    const [{ isDragging }, drag] = useDrag(
        () => ({
            canDrag: !!dragProps?.enabled,
            type: dragProps?.itemType ?? "",
            item: () => {
                const draggedItems = items
                    .filter((i) => selectedItemIds.includes(keyExtractor(i.item)))
                    .map((i) => i.item);
                !selectedItemIds.includes(itemKey) && draggedItems.push(item);

                return draggedItems;
            },
            collect: (monitor) => ({ isDragging: monitor.isDragging() }),
        }),
        [selectedItemIds, keyExtractor, items],
    );

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: dropProps ? dropProps.supportedDrops : [],
            drop: (dropItem, monitor) => {
                const itemType = monitor.getItemType()?.toString();
                dropProps?.enabled && dropProps.onDrop(dropItem, itemType!);
            },
            canDrop: (_, monitor) => {
                const notSelected = !(monitor.getItemType() !== NativeTypes.FILE && selectedItemIds.includes(itemKey));
                return !!dropProps && dropProps.enabled && !isDragging && notSelected;
            },
            collect: (monitor) => ({ isOver: monitor.isOver() && monitor.canDrop() }),
        }),
        [dropProps, isDragging, selectedItemIds],
    );

    useEffect(() => {
        updateDropableStatus && updateDropableStatus(isOver, itemKey);
    }, [updateDropableStatus, isOver, itemKey]);

    useEffect(() => {
        isDragging && !selectedItemIds.includes(itemKey) && onSelect(itemKey);
    }, [isDragging, itemKey, onSelect, selectedItemIds]);

    const selectRow = useCallback(
        () => selectable && !disabled && onSelect(itemKey, true),
        [disabled, itemKey, onSelect, selectable],
    );

    return (
        <EntityTableRow
            className={classNames(className, { disabled, "drag-over": isOver })}
            selected={selected}
            rowIndex={rowIndex}
            dragAndDropRef={(node) => drag(drop(node))}
        >
            {selectable && (
                <td className="row-select" {...setSelector("column-select")}>
                    <LocalizedLabel for={`chk_${itemKey}`} text="Common.SelectRow" hidden />
                    <Input
                        type="checkbox"
                        id={`chk_${itemKey}`}
                        disabled={disabled}
                        checked={!!selected}
                        onChange={() => onSelect && onSelect(itemKey)}
                        tabIndex={-1}
                        {...setSelector("selectCheckbox")}
                    />
                </td>
            )}
            {columnDefs.map((columnDef, i) => (
                <React.Fragment key={columnDef.fieldName}>
                    <td
                        className={classNames(
                            columnDef.fieldName,
                            columnDef.className,
                            columnDef.shouldTruncateText ? "text-truncate" : "",
                        )}
                        onClick={selectRow}
                        {...(columnDef.testSelectorColumnName !== undefined
                            ? setSelector("column-" + columnDef.testSelectorColumnName)
                            : {})}
                    >
                        {renderItems(columnDef, {
                            item,
                            itemKey,
                            rowDisabled: disabled,
                        })}
                    </td>
                    {i === 0 && internalActionProvider && (
                        <td onClick={selectRow} className="row-actions" {...setSelector("column-actions")}>
                            {!disabled && <TableRowActions actions={actions} onOpen={onOpen} />}
                        </td>
                    )}
                </React.Fragment>
            ))}
        </EntityTableRow>
    );
};
