import { NotificationOptions } from "../models/notification/NotificationOptions";

/**
 * The notification options interface.
 */
export interface INotificationOptions extends NotificationOptions {}

/**
 * The notification service.
 */
export class NotificationService {
    public static readonly defaultSuccessNotificationAutoDismissTimeoutInSecs = 7;
    public static successNotificationAutoDismissTimeoutInSecs: number;
    private static newNotificationHandlers: ((notificationOptions: INotificationOptions) => void)[] = [];
    private static clearNotificationHandlers: (() => void)[] = [];

    public static onNewNotification(callback: (notificationOptions: INotificationOptions) => void): void {
        NotificationService.newNotificationHandlers.push(callback);
    }

    public static onClearNotification(callback: () => void): void {
        NotificationService.clearNotificationHandlers.push(callback);
    }

    public static addNotification(notificationOptions: INotificationOptions): void {
        NotificationService.addNotifications([notificationOptions]);
    }

    public static addSuccessNotification(
        notificationOptions: Pick<INotificationOptions, "message"> & Partial<INotificationOptions>,
    ): void {
        const defaultValues: Omit<INotificationOptions, "message"> = {
            type: "success",
            icon: "fas fa-check-circle",
            autoDismiss: !Number.isInteger(this.successNotificationAutoDismissTimeoutInSecs)
                ? this.defaultSuccessNotificationAutoDismissTimeoutInSecs
                : this.successNotificationAutoDismissTimeoutInSecs,
        };
        NotificationService.addNotification(Object.assign(defaultValues, notificationOptions));
    }

    public static addWarningNotification(
        notificationOptions: Pick<INotificationOptions, "message"> & Partial<INotificationOptions>,
    ): void {
        const defaultValues: Omit<INotificationOptions, "message"> = {
            type: "warning",
            icon: "fas fa-exclamation-triangle",
            autoDismiss: 0,
        };
        NotificationService.addNotification(Object.assign(defaultValues, notificationOptions));
    }

    public static addErrorNotification(
        notificationOptions: Pick<INotificationOptions, "message"> & Partial<INotificationOptions>,
    ): void {
        const defaultValues: Omit<INotificationOptions, "message"> = {
            type: "danger",
            icon: "fas fa-times-circle",
            autoDismiss: 0,
        };
        NotificationService.addNotification(Object.assign(defaultValues, notificationOptions));
    }

    public static addNotifications(notificationsOptions: INotificationOptions[]): void {
        NotificationService.newNotificationHandlers.forEach(
            (newNotificationHandler: (notificationOptions: INotificationOptions) => void) => {
                notificationsOptions.forEach((notificationOptions) => {
                    newNotificationHandler(notificationOptions);
                });
            },
        );
    }

    public static clearNotifications(): void {
        NotificationService.clearNotificationHandlers.forEach((clearNotificationHandler: () => void) => {
            clearNotificationHandler();
        });
    }

    public static clearNotificationEventsSubscriptions(): void {
        NotificationService.newNotificationHandlers = [];
        NotificationService.clearNotificationHandlers = [];
    }
}
