import { useEffect, useMemo, useState } from "react";
import { IValidationResult } from "../CreateModalEntityHook";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { VersionCommentValidator } from "../../formValidators/VersionCommentValidator";
import { IVersionComment } from "../../models/versioning/VersioningModels";

export const useVersionCommentValidation = ({
    comment,
}: IVersionComment): IValidationResult<IVersionComment, never> => {
    const { errors, setError } = useEntityErrors<IVersionComment>();
    const formValidator = useMemo(() => new VersionCommentValidator(), []);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        formValidator
            .validateComment(comment)
            .then(() => setError("comment", ""))
            .catch((err: Error) => setError("comment", extractErrorMessageOrEmptyString(err)));
    }, [comment, formValidator, setError]);

    useEffect(() => {
        void formValidator.isValid({ comment }).then(setValid);
    }, [comment, formValidator]);

    return {
        valid,
        errors,
        validationStatuses: {},
        setError,
    };
};
