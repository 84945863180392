import React from "react";
import * as Yup from "yup";
import moment from "moment";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";
import {
    ContentFileShareBehaviors,
    ContentFileShareExpirationDate,
} from "../models/contentManager/ContentManagerApiModels";

export interface IContentFileShareFormFields {
    behaviors: ContentFileShareBehaviors;
    expirationDate: ContentFileShareExpirationDate;
    timeZoneId: string | null;
}

/**
 * The content file share form validator class.
 */
export class ContentFileShareFormValidator extends FormValidator<IContentFileShareFormFields> {
    protected schema = Yup.object().shape({
        expirationDate: Yup.string()
            .nullable()
            .test(
                "validateExpirationDate",
                () => <ValidatorError messageKey="Share.InvalidDate" />,
                (expirationDate) => {
                    if (expirationDate === null) {
                        return true;
                    }

                    const dateAsMoment = moment(expirationDate, true);
                    if (!dateAsMoment.isValid()) {
                        return false;
                    }

                    return moment().isSameOrBefore(dateAsMoment);
                },
            ),
        timeZoneId: Yup.string().nullable(),
    });

    public validateExpirationDate(expirationDate: ContentFileShareExpirationDate): Promise<string | null | undefined> {
        return this.schema.fields.expirationDate.validate(expirationDate);
    }
}
