import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountView } from "../AccountView";
import { EditExternalLoginsForm } from "./EditExternalLoginsForm";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { LoadingScreen } from "../../loading/LoadingScreen";
import {
    IAuthenticationSchemeModel,
    ILoginModel,
    IUserLoginInfoModel,
} from "../../../models/account/IExternalProvider";
import { NotificationService } from "../../../services/NotificationService";
import { accountApiPaths } from "../../../PathConstants";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";

const initiateLoginCallback = (provider: string) => {
    // ChallengeResult from controller need the full browser capabiility instead of the SPA to log an external provider.
    window.location.href = accountApiPaths.redirect.externalProviderLogin(provider);
};

export const EditExternalLoginsView: React.FC = () => {
    const { t } = useTranslation();
    const [availableExternalLogins, setAvailableExternalLogins] = useState<IAuthenticationSchemeModel[]>();
    const [currentLogins, setCurrentLogins] = useState<IUserLoginInfoModel[]>();
    const {
        getUserLoginExternalProviders,
        getAvailableExternalProviders,
        getUserHasPassword,
        removeUserLoginExternalProvider,
    } = useAccountApi();
    const [loadingError, setLoadingError] = useState(false);
    const [userHasPassword, setUserHasPassword] = useState(false);
    const [triggerGetCurrentProviders, setTriggerGetCurrentProviders] = useState(true);
    const [triggerGetAvailableProviders, setTriggerGetAvailableProviders] = useState(true);

    useEffect(() => {
        if (triggerGetCurrentProviders) {
            (async () => {
                try {
                    const result = await getUserLoginExternalProviders();
                    setCurrentLogins(result);
                    setTriggerGetCurrentProviders(false);
                } catch (error) {
                    defaultRequestErrorHandler(error);
                    setLoadingError(true);
                }
            })();
        }
        if (triggerGetAvailableProviders) {
            (async () => {
                try {
                    const result = await getAvailableExternalProviders();
                    setAvailableExternalLogins(result);
                    setTriggerGetAvailableProviders(false);
                } catch (error) {
                    defaultRequestErrorHandler(error);
                    setLoadingError(true);
                }
            })();
        }
    }, [
        getUserLoginExternalProviders,
        getAvailableExternalProviders,
        triggerGetCurrentProviders,
        triggerGetAvailableProviders,
    ]);

    useEffect(() => {
        (async () => {
            try {
                const result = await getUserHasPassword();
                setUserHasPassword(result);
            } catch (error) {
                defaultRequestErrorHandler(error);
                setLoadingError(true);
            }
        })();
    }, [getUserHasPassword]);

    const removeLoginCallback = useCallback(
        async (login: ILoginModel) => {
            try {
                const success = await removeUserLoginExternalProvider(login);

                if (success) {
                    setTriggerGetCurrentProviders(true);
                    setTriggerGetAvailableProviders(true);

                    NotificationService.addSuccessNotification({
                        messageKey: "ExternalLogins.RemoveService.Success",
                    });
                } else {
                    defaultRequestErrorHandler({
                        messageKey: "ExternalLogins.RemoveService.Error",
                    });
                }
            } catch (error) {
                defaultRequestErrorHandler(error);
                setLoadingError(true);
            }
        },
        [removeUserLoginExternalProvider],
    );

    const memoizedRemoveLogin = useMemo(
        () => (userHasPassword || (currentLogins && currentLogins.length > 1) ? removeLoginCallback : undefined),
        [currentLogins, removeLoginCallback, userHasPassword],
    );

    if (loadingError) {
        return <></>;
    }

    if (triggerGetCurrentProviders || triggerGetAvailableProviders || !availableExternalLogins || !currentLogins) {
        return <LoadingScreen>{t("ExternalLogins.View.Loading")}</LoadingScreen>;
    }

    return (
        <AccountView title={t("ExternalLogins.Title")}>
            <EditExternalLoginsForm
                availableExternalLogins={availableExternalLogins}
                currentLogins={currentLogins}
                removeLogin={memoizedRemoveLogin}
                initiateLogin={initiateLoginCallback}
            />
        </AccountView>
    );
};
