import { useMemo } from "react";
import { PermissionGroupPaths } from "../../PathConstants";
import { PermissionGroupForm } from "../../components/permissions/PermissionGroupForm";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { NotificationService } from "../../services/NotificationService";
import { useDefaultTableCreateHelper } from "../table/DefaultTableCreateHelper";
import { useNavigation } from "../NavigationHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useProjectGroupApi } from "../project/ProjectGroupApiHook";
import { TableCreateHelper } from "../../components/table/Table";

interface ProjectCreatePermissionGroupHookProps {
    canManagePermissions: boolean;
}

interface ProjectCreatePermissionGroupHookResult {
    createHelper?: TableCreateHelper;
}

export const useProjectCreatePermissionGroup = ({
    canManagePermissions,
}: ProjectCreatePermissionGroupHookProps): ProjectCreatePermissionGroupHookResult => {
    const project = useProjectContext();
    const { navigate } = useNavigation();
    const { createGroup } = useProjectGroupApi({ projectId: project.projectId });

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: PermissionGroupForm,
        onCreated: ({ name, groupId }: IPermissionsGroupModel) => {
            NotificationService.addSuccessNotification({
                messageKey: "PermissionsGroupView.CreationSuccess",
                messageKeyParams: { name },
            });
            navigate(PermissionGroupPaths.link.edit.project(project.organizationName, project.name, groupId));
        },
        onCreate: (fields) => createGroup(fields),
        formProps: {
            projectId: project.projectId,
        },
        initialEntity: {
            name: "",
            description: null,
            userIds: [] as string[],
        },
        modalProps: {
            className: "create-permission-group-modal",
            titleKey: "CreatePermissionGroup.Title",
            unsavedWarningBody: "PermissionGroup.UnsavedWarningBody",
            size: "lg",
            expandable: true,
        },
    });

    const createHelper = useMemo(() => {
        if (project.projectId && canManagePermissions) {
            return defaultCreateHelper;
        }
    }, [defaultCreateHelper, canManagePermissions, project.projectId]);

    return {
        createHelper,
    };
};
