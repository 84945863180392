import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { projectPaths } from "../../PathConstants";

/**
 * The project dashboard redirect component.
 */
export const ProjectDatasetsRedirect: React.FC = () => {
    const { organizationName, projectName } = useParams<{ organizationName: string; projectName: string }>();

    return <Navigate to={projectPaths.link.dataSets(organizationName!, projectName!)} replace={true} />;
};
