import React from "react";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { Button } from "../buttons/Button";

/**
 * The loadMore props interface.
 */
export interface ILoadMoreProps {
    onLoadMore: () => void;
}

/**
 * The load more component.
 */
export const LoadMore: React.FunctionComponent<ILoadMoreProps> = (props: ILoadMoreProps): JSX.Element => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <>
            <Button
                color="secondary"
                className="col-md-6"
                onClick={props.onLoadMore}
                outline
                ariaLabel={t("Common.LoadMore")}
                {...setSelector("loadMoreButton")}
            >
                {t("Common.LoadMore")}
            </Button>
        </>
    );
};
