import React from "react";
import { Link } from "react-router-dom";
import { IProjectModel } from "../../models/project/IProjectModel";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { ProjectAvatar } from "../projects/ProjectAvatar";
import { ProjectStatus } from "../../models/project/ProjectStatus";
import { projectPaths } from "../../PathConstants";
import { LoadingAnimation } from "../loading/LoadingAnimation";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";

import "./ProjectNameWithAvatar.scss";

interface IProjectNameWithAvatarProps {
    project: IProjectModel;
    organizationName: string;
    onProjectStillAwaiting: () => void;
}

/**
 * Represents a Project Name with an avatar that displays a loading icon if the project
 * is still awaiting creation.
 */
export const ProjectNameWithAvatar: React.FC<IProjectNameWithAvatarProps> = ({
    project,
    organizationName,
    onProjectStillAwaiting,
}: IProjectNameWithAvatarProps) => {
    const { setSelector } = useTestSelector();

    const content = (
        <div className="name-wrapper">
            <div className="text-truncate">{project.name}</div>
            {project.state === ProjectStatus.AwaitingCreation && <LoadingAnimation size={24} />}
        </div>
    );

    return (
        <div className="project-container">
            <div className="item-avatar">
                <ProjectAvatar
                    name={project.name}
                    size="32"
                    color={project.state === ProjectStatus.AwaitingCreation ? colorStyles.gray500 : undefined}
                />
            </div>
            {project.isProjectUser ? (
                <Link
                    className="text-truncate"
                    to={projectPaths.link.dashboard(organizationName, project.name)}
                    onClick={(e) => {
                        if (project.state === ProjectStatus.AwaitingCreation) {
                            e.preventDefault();
                            onProjectStillAwaiting();
                        }
                    }}
                    {...setSelector("cell-link")}
                >
                    {content}
                </Link>
            ) : (
                <>{content}</>
            )}
        </div>
    );
};
