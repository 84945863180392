export type DialogType = "Warning" | "Danger" | "Delete" | "Info";

/**
 * The dialog state interface extending partial IDialogStateResult.
 */
export interface IShowProps
    extends Omit<
        IDialogState,
        "cancelLabelKey" | "proceedLabelKey" | "titleKey" | "close" | "closed" | "proceed" | "open"
    > {
    cancelLabelKey?: string;
    proceedLabelKey?: string;
    titleKey?: string;
    onClose?: () => void;
    onClosed?: () => void;
    onProceed?: () => Promise<void>;
}

/**
 * The dialog state interface.
 */
export interface IDialogState {
    type: DialogType;
    cancelLabelKey: string;
    messageKey?: string;
    contentOverwriteModal?: boolean;
    content?: JSX.Element;
    open: boolean;
    proceedLabelKey: string;
    size?: string;
    titleKey: string;
    close?: () => void;
    closed?: () => void;
    proceed?: () => Promise<void>;
    hideCancel?: boolean;
    modalClassName?: string;
}

interface IBaseAction<T> {
    payload: T;
}

interface IshowAction extends IBaseAction<IShowProps> {
    type: "SHOW_DIALOG";
}

interface IOnCloseDialogAction {
    type: "ON_CLOSE_DIALOG";
}

interface IOnClosedDialogAction {
    type: "ON_CLOSED_DIALOG";
}

export type DialogActionTypes = IshowAction | IOnCloseDialogAction | IOnClosedDialogAction;

export const initialDialogState: IDialogState = {
    type: "Warning",
    cancelLabelKey: "Common.Cancel",
    messageKey: "",
    proceedLabelKey: "Common.Proceed",
    open: false,
    titleKey: "Common.UnsavedWarningTitle",
};

export const dialogReducer = (state: IDialogState, action: DialogActionTypes): IDialogState => {
    switch (action.type) {
        case "SHOW_DIALOG":
            return {
                ...state,
                type: action.payload.type,
                cancelLabelKey: action.payload.cancelLabelKey ?? initialDialogState.cancelLabelKey,
                messageKey: action.payload.messageKey,
                content: action.payload.content,
                open: true,
                proceedLabelKey: action.payload.proceedLabelKey ?? initialDialogState.proceedLabelKey,
                size: action.payload.size,
                titleKey: action.payload.titleKey ?? initialDialogState.titleKey,
                hideCancel: action.payload.hideCancel,
                contentOverwriteModal: action.payload.contentOverwriteModal,
                modalClassName: action.payload.modalClassName,
                close: action.payload.onClose,
                closed: action.payload.onClosed,
                proceed: action.payload.onProceed,
            };
        case "ON_CLOSE_DIALOG":
            return {
                ...state,
                open: false,
            };
        case "ON_CLOSED_DIALOG":
            return initialDialogState;
        default:
            return state;
    }
};
