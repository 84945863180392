import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { useHotkeys } from "react-hotkeys-hook";
import { EntityIcon } from "../icons/EntityIcon";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import { ExpandCompressButton } from "../buttons/ExpandCompressButton";
import { useShortcutContext } from "../../contexts/ShortcutContext";
import { Button } from "../buttons/Button";

import "./SidePanelWithContext.scss";

interface ISidePanelWithContextProps extends React.PropsWithChildren {
    classname?: string;
    containerSize?: DOMRectReadOnly;
}

const sidePanelZIndex = 1045;

export const SidePanelWithContext: React.FC<ISidePanelWithContextProps> = ({ children, classname, containerSize }) => {
    const { t } = useTranslation();
    const { sidePanelState, closeSidePanel, isDisplayed } = useSidePanelContext();
    const { globalShortcutEnabled } = useShortcutContext();
    const [expanded, setExpanded] = useState<boolean>(false);
    const titleIcon = useMemo(() => {
        if (sidePanelState.entityIcon) {
            return <EntityIcon type={sidePanelState.entityIcon} />;
        }

        if (typeof sidePanelState.titleIcon === "string") {
            return <i className={sidePanelState.titleIcon} />;
        }

        if (sidePanelState.titleIcon) {
            return sidePanelState.titleIcon;
        }

        return <></>;
    }, [sidePanelState.entityIcon, sidePanelState.titleIcon]);

    useEffect(() => {
        if (!isDisplayed && expanded) {
            setExpanded(false);
        }
    }, [isDisplayed, expanded]);

    useHotkeys(
        "escape",
        () => {
            closeSidePanel();
        },
        {
            filter: () => globalShortcutEnabled && expanded,
        },
        [closeSidePanel],
    );

    const handleClose = useCallback(() => {
        closeSidePanel();
    }, [closeSidePanel]);

    const sidePanelButtons = useMemo(
        () => (
            <>
                <div className="side-panel-buttons">
                    {
                        <ExpandCompressButton
                            onToggle={(newValue) => setExpanded(newValue)}
                            fullscreen={expanded}
                            iconType="far"
                        />
                    }
                    <Button
                        ariaLabel={t("Common.Close")}
                        className="close"
                        onClick={handleClose}
                        icon="far fa-xmark-large"
                        color="secondary"
                        outline
                        description={t("Common.Close")}
                    />
                </div>
            </>
        ),
        [expanded, handleClose, t],
    );

    const customSizeStyles = expanded ? { width: containerSize?.width } : {};

    return (
        <>
            {children}
            <Offcanvas
                className={classNames("side-panel", classname, sidePanelState.classname, { expanded })}
                isOpen={isDisplayed}
                direction={sidePanelState.direction ?? "end"}
                backdrop={sidePanelState.backdrop ?? false}
                container={"sidepanel-container"}
                toggle={handleClose}
                fade
                style={customSizeStyles}
                zIndex={sidePanelZIndex}
            >
                <OffcanvasHeader
                    tag={sidePanelState.titleMultiRow ? "p" : "h2"}
                    className="side-panel__header"
                    toggle={handleClose}
                    close={sidePanelButtons}
                >
                    {titleIcon}
                    <span className={sidePanelState.titleMultiRow ? "multi-row" : "text-truncate"}>
                        {sidePanelState.title}
                    </span>
                </OffcanvasHeader>
                <OffcanvasBody>{sidePanelState.content}</OffcanvasBody>
            </Offcanvas>
            <div id="sidepanel-container" className="sidepanel-container"></div>
        </>
    );
};
