import { useCallback } from "react";
import { NavigateFunction, NavigateOptions, To, useNavigate } from "react-router-dom";

interface IUseNavigationResult {
    navigate: NavigateFunction;
    replace: (to: To, options?: Omit<NavigateOptions, "replace">) => void;
}

export const useNavigation = (): IUseNavigationResult => {
    const navigate = useNavigate();
    const replace = useCallback(
        (to: To, options?: Omit<NavigateOptions, "replace">) => navigate(to, { replace: true, ...(options || {}) }),
        [navigate],
    );

    return {
        navigate,
        replace,
    };
};
