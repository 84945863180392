import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { LoadingScreen } from "../loading/LoadingScreen";
import { EntityForm } from "../forms/EntityForm";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { useTranslateValidation } from "../../hooks/contentManager/TranslateValidation";
import { ITranslateFormFields, LanguageType } from "../../models/contentManager/ITranslateFormFields";
import { useContentFileApi } from "../../hooks/contentManager/ContentFileApiHook";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import Select, { components } from "react-select";
import classNames from "classnames";

import "../selects/SelectComponent.scss";
import "./TranslateModal.scss";

interface ITranslateFormProps {
    onFormChange: (formData: IEntityFormData<ITranslateFormFields>) => void;
    initialValue: ITranslateFormFields;
    projectId: string;
}

export const TranslateModal: React.FC<ITranslateFormProps> = ({ onFormChange, initialValue, projectId }) => {
    const { t } = useTranslation();

    const [languageOptions, setLanguageOptions] = useState<LanguageType[]>([]);

    const [isLoadingTranslatableLanguages, setIsLoadingTranslatableLanguages] = useState<boolean>(false);

    const { getTranslatableLanguages } = useContentFileApi(projectId);

    const { data, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: (fields) => useTranslateValidation({ fields }),
    });

    useEffect(() => {
        (async () => {
            try {
                setIsLoadingTranslatableLanguages(true);
                const translatableLanguages = await getTranslatableLanguages();
                setLanguageOptions(
                    translatableLanguages.map<LanguageType>((item) => ({
                        label: item.name,
                        value: item.code,
                        data: { isProjectLanguage: item.isProjectLanguage },
                    })),
                );
            } catch (error) {
                defaultRequestErrorHandler(error);
            } finally {
                setIsLoadingTranslatableLanguages(false);
            }
        })();
    }, [getTranslatableLanguages]);

    if (isLoadingTranslatableLanguages) {
        return (
            <div className="translate-modal">
                <LoadingScreen />
            </div>
        );
    }

    return (
        <EntityForm name="translate">
            <FormGroup>
                <LocalizedLabel for="select-translate-language" text="Profile.Language" />
                <Select
                    /* eslint-disable @typescript-eslint/naming-convention */
                    id="select-translate-language"
                    value={data.language}
                    options={[
                        {
                            label: t("ContentManager.Translation.ProjectLanguages"),
                            options: languageOptions.filter((lo) => lo.data?.isProjectLanguage),
                        } as any,
                        {
                            label: t("ContentManager.Translation.AvailableLanguages"),
                            options: languageOptions.filter((lo) => !lo.data?.isProjectLanguage),
                        } as any,
                    ]}
                    onChange={(language) => {
                        return onChange("language", language);
                    }}
                    isClearable
                    isSearchable
                    placeholder={t("Select.PlaceHolder")}
                    className={classNames("select-component", "large")}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    styles={{
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected ? "white" : "transparent",
                            color: "inherit",
                            "&:hover": {
                                backgroundColor: "#e9ecef",
                            },
                        }),
                        multiValueLabel: (base, state) => ({
                            ...base,
                            span: {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            },
                        }),
                    }}
                    components={{
                        // Based on: https://github.com/JedWatson/react-select/issues/3149
                        // By: riceboyle (2020-06-09)
                        SelectContainer: ({ ...dropdownSelectorContainerProps }) => {
                            return (
                                <components.SelectContainer
                                    {...dropdownSelectorContainerProps}
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    innerProps={Object.assign(dropdownSelectorContainerProps.innerProps)}
                                ></components.SelectContainer>
                            );
                        },
                        DropdownIndicator: (dropdownIndicatorProps) => (
                            <components.DropdownIndicator {...dropdownIndicatorProps}>
                                <svg height="20" width="20" viewBox="0 0 320 512" aria-hidden="true" focusable="false">
                                    <path
                                        fill="%23495057"
                                        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                                    />
                                </svg>
                            </components.DropdownIndicator>
                        ),
                        SingleValue: (singleValueProps) => (
                            <components.SingleValue {...singleValueProps}>
                                {singleValueProps.data.icon && (
                                    <i className={classNames(singleValueProps.data.icon, "single-value-icon")} />
                                )}
                                {!!singleValueProps.data.componentIcon && singleValueProps.data.componentIcon}
                                <span>{singleValueProps.data.label}</span>
                            </components.SingleValue>
                        ),
                        IndicatorSeparator: null,
                    }}
                />
            </FormGroup>
            <span className="disclaimer-message">✨ {t("ContentManager.File.TranslateDisclaimer")}</span>
        </EntityForm>
    );
};
