import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ObjectHelper } from "../../helpers/ObjectHelper";
import { EditEntityWrapper } from "../edit/EditEntityWrapper";
import { ProjectSettingsViewTabs } from "./ProjectSettingsViewTabs";
import { useProjectSettings } from "../../hooks/project/ProjectSettingsHook";
import { ProjectFormValidator } from "../../formValidators/ProjectFormValidator";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { DefaultEditDropdownMenu } from "../dropdowns/DefaultEditDropdownMenu";

import "./ProjectSettingsView.scss";

/**
 * The project settings view component.
 */
export const ProjectSettingsView: React.FC = () => {
    const { t } = useTranslation();
    const {
        isAllowed,
        isDenied,
        loadProject,
        permissionKeys,
        removeProject,
        nameIsUnique,
        updateProject,
        onSaveProject,
    } = useProjectSettings();

    const formValidatorProvider = useCallback(() => new ProjectFormValidator(), []);

    const canEdit = isAllowed(permissionKeys.projectWriteKey);
    const canDelete = isAllowed(permissionKeys.projectDeleteKey);

    return (
        <EditEntityWrapper
            className="projectsettingsview"
            nameIsUniqueProps={{
                nameExistErrorKey: "ProjectFormValidator.ProjectExistsForOrganization",
                nameIsUnique,
            }}
            deleteDialogProps={{
                dialogBodyKey: "Project.DeleteBody",
                dialogTitleKey: "Project.DeleteTitle",
                entityCount: 1,
            }}
            promptDialogMessageKey="ProjectSettings.UnsavedWarningBody"
            delete={removeProject}
            load={loadProject}
            update={updateProject}
            formValidatorProvider={formValidatorProvider}
            loadingScreenKey="Project.Loading"
            onSaveCallback={onSaveProject}
            propertiesComparator={{
                description: ObjectHelper.isNullableStringEqual,
            }}
            canEdit={canEdit}
            canDelete={canDelete}
            entityType="Project"
            header={
                <SectionHeader>
                    <SectionHeader.Group flexGrow>
                        <SectionHeader.Title>{t("Route.Settings.Project")}</SectionHeader.Title>
                    </SectionHeader.Group>
                    <SectionHeader.ActionGroup
                        withSaveButton={canEdit}
                        dropdownMenu={<DefaultEditDropdownMenu canDelete={canDelete} />}
                    />
                </SectionHeader>
            }
        >
            <ProjectSettingsViewTabs displayServiceHook={!isDenied(permissionKeys.serviceHookReadKey)} />
        </EditEntityWrapper>
    );
};
