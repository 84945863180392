import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingScreen } from "../loading/LoadingScreen";

/**
 * The project loading component.
 */
export const ProjectLoading: React.FC = (): JSX.Element => {
    const {
        t,
    } = useTranslation();

    return <LoadingScreen>{t("Project.Loading")}</LoadingScreen>;
};
