import { useEffect, useState } from "react";
import { IDefaultEntityProps } from "../../../models/IDefaultEntityProps";
import { ContentManagerItemPropertiesFormValidator } from "../../../formValidators/ContentManagerItemPropertiesFormValidator";
import { useEntityErrors } from "../../entity/EntityErrorsHook";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";

type FieldsType = Omit<IDefaultEntityProps, "name">;

export const useContentManagerItemPropertiesValidation = (fields: FieldsType) => {
    const [defaultValues] = useState(fields);
    const { errors, setError } = useEntityErrors<FieldsType>();
    const [valid, setValid] = useState(false);
    const [descriptionTouched, setDescriptionTouched] = useState(false);
    const [formValidator] = useState(() => new ContentManagerItemPropertiesFormValidator());

    useEffect(() => {
        setDescriptionTouched((current) => current || fields.description !== defaultValues.description);
    }, [defaultValues.description, fields.description]);

    useEffect(() => {
        if (descriptionTouched) {
            formValidator
                .validateDescription(fields.description)
                .then(() => setError("description", ""))
                .catch((e) => setError("description", extractErrorMessageOrEmptyString(e)));
        }
    }, [descriptionTouched, fields.description, formValidator, setError]);

    useEffect(() => {
        void formValidator.isValid({ description: fields.description }).then(setValid);
    }, [fields.description, formValidator]);

    return {
        valid,
        errors,
        validationStatuses: {},
        setError,
    };
};
