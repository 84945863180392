import React from "react";
import { Button } from "../buttons/Button";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The dialog action props interface.
 */
export interface IDialogActionProps {
    label: string;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    color?: string;
    outline?: boolean;
    onAction: () => void;
    testSelectorValue?: string;
}

/**
 * The dialog action component.
 */
export const DialogAction: React.FunctionComponent<IDialogActionProps> = (props: IDialogActionProps) => {
    const { className, color, disabled, label, loading, outline, testSelectorValue, onAction } = props;
    const { setSelector } = useTestSelector();

    return (
        <Button
            loading={loading}
            disabled={disabled}
            outline={outline}
            color={color ?? "primary"}
            className={className}
            onClick={onAction}
            ariaLabel={label}
            {...setSelector(testSelectorValue)}
        >
            {label}
        </Button>
    );
};
