import React from "react";
import { FormFeedback, FormFeedbackProps } from "reactstrap";
import { useTranslation } from "react-i18next";

/**
 * The form feedback error props interface.
 */
export interface IFormFeedbackErrorProps extends FormFeedbackProps {
    error: string | JSX.Element;
}

/**
 * The form feedback error component.
 */
export const FormFeedbackError: React.FunctionComponent<IFormFeedbackErrorProps> = ({
    error,
    ...formFeedbackProps
}: IFormFeedbackErrorProps) => {
    const { t } = useTranslation();

    return (
        <>
            <FormFeedback {...formFeedbackProps}>
                <span className="me-2">
                    <i className="fas fa-exclamation-circle" />
                </span>
                <span>{typeof error === "string" ? t(error) : error}</span>
            </FormFeedback>
        </>
    );
};
