import React from "react";
import * as Yup from "yup";
import { ContentFileShareFormValidator } from "./ContentFileShareFormValidator";
import { ValidatorError } from "./ValidatorError";
import { ContentFileShareBehaviors } from "../models/contentManager/ContentManagerApiModels";

export class CreateContentFileShareFormValidator extends ContentFileShareFormValidator {
    protected createschema = this.schema.concat(
        Yup.object().shape({
            behaviors: Yup.number().min(1, () => <ValidatorError messageKey="Share.Behavior.Required" />),
        }),
    );

    constructor() {
        super();
        this.schema = this.createschema;
    }

    public validateBehaviors(behaviors: ContentFileShareBehaviors): Promise<ContentFileShareBehaviors | undefined> {
        return this.createschema.fields.behaviors.validate(behaviors);
    }
}
