import React from "react";
import { FormGroup } from "reactstrap";
import { EntityForm } from "../forms/EntityForm";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { useContentValidation } from "../../hooks/contentManager/ContentFormValidation";

import "./CreateContentFolderForm.scss";

export interface ICreateContentFolderFormFields {
    name: string;
    projectId: string;
    parentFolderId: string | null;
}
interface ICreateContentFolderFormProps {
    onFormChange: (formData: IEntityFormData<ICreateContentFolderFormFields>) => void;
    initialValue: ICreateContentFolderFormFields;
}

export const CreateContentFolderForm: React.FC<ICreateContentFolderFormProps> = ({
    onFormChange,
    initialValue,
}: ICreateContentFolderFormProps) => {
    const {
        data: { name },
        errors,
        onChange,
        validationStatuses,
    } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: useContentValidation,
        isDirty: (data) => !!data.name,
    });

    const { setSelector } = useTestSelector();

    return (
        <EntityForm name="create-content-folder" className="createContentFolder">
            <FormGroup>
                <LocalizedLabel aria-required="true" for="create-content-name" text="Common.Name" required />
                <ValidatedInputWithStatus
                    id="create-content-name"
                    autoFocus={true}
                    autoComplete="off"
                    name="name"
                    type="text"
                    {...setSelector("nameTextbox")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("name", e.target.value)}
                    error={errors.name}
                    value={name}
                    isBusy={validationStatuses.name === "Validating"}
                />
            </FormGroup>
        </EntityForm>
    );
};
