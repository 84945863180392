import React from "react";
import { DropdownItem, DropdownItemProps } from "reactstrap";
import { useTranslation } from "react-i18next";
import { LoadingAnimation } from "../loading/LoadingAnimation";

/**
 * The table row loading action props interface.
 */
export interface ITableRowLoadingActionProps extends DropdownItemProps {}

/**
 * The table row loading action component.
 */
export const TableRowLoadingAction: React.FC<ITableRowLoadingActionProps> = ({
    ...dropdownItemProps
}: ITableRowLoadingActionProps) => {
    const { t } = useTranslation();

    return (
        <>
            <DropdownItem {...dropdownItemProps} className="loading-dropdown">
                <LoadingAnimation size={16} />
                {t("Common.Loading")}
            </DropdownItem>
        </>
    );
};
