import React from "react";
import classNames from "classnames";

/**
 * The entity table body props interface.
 */
interface IEntityTableBodyProps {
    className?: string;
    children?: React.ReactNode;
}

/**
 * The entity table body component.
 */
export const EntityTableBody: React.FunctionComponent<IEntityTableBodyProps> = (props: IEntityTableBodyProps) => {
    const { children, className } = props;

    return (
        <tbody className={classNames("list", className)}>
            {children}
        </tbody>
    );
};