import React, { useCallback } from "react";
import { Form, FormProps } from "reactstrap";

/**
 * The entity form component.
 */
export const EntityForm: React.FC<FormProps> = ({ onSubmit, ...formProps }) => {
    const defaultOnSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            onSubmit && onSubmit(e);
        },
        [onSubmit],
    );

    return <Form onSubmit={defaultOnSubmit} {...formProps} />;
};
