import React from "react";
import { Button } from "../buttons/Button";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The action navbar item props interface.
 */
export interface IActionNavbarItemProps {
    className?: string;
    disabled?: boolean;
    icon: string;
    onAction: () => void;
    testSelectorValue?: string;
    ariaLabel: string;
}

/**
 * The action navbar item functional component.
 */
export const HeaderActionItem: React.FunctionComponent<IActionNavbarItemProps> = ({
    className,
    icon,
    disabled,
    onAction,
    testSelectorValue,
    ariaLabel,
}: IActionNavbarItemProps): JSX.Element => {
    const { setSelector } = useTestSelector();
    return (
        <Button
            disabled={disabled}
            onClick={onAction}
            {...setSelector(testSelectorValue)}
            className={className}
            ariaLabel={ariaLabel}
        >
            <i className={icon} />
        </Button>
    );
};
