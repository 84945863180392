import React from "react";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { DependencyDeleteDialogBody } from "./DependencyDeleteDialogBody";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { EntityType } from "../../models/EntityType";

import "./DependencyDeleteDialogBody.scss";

interface IDependencyDeleteDialogBodyProps {
    dependencies: MultipleDependenciesResult;
    entityType: EntityType;
}

export const EntityDependencyDeleteDialogBody: React.FC<IDependencyDeleteDialogBodyProps> = ({
    dependencies,
    entityType,
}: IDependencyDeleteDialogBodyProps) => {
    const {
        entityProps: { entity, entityId },
    } = useEditEntityContext();

    const newEntity = { ...entity, type: entityType };

    return (
        <DependencyDeleteDialogBody
            dependencies={dependencies}
            entityType={entityType}
            accordionIds={[entityId ?? ""]}
            items={[newEntity]}
            id={entityId}
        />
    );
};
