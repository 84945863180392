import { createContext, useContext } from "react";
import { ITwoFASettings } from "../models/account/ITwoFASettings";

/**
 * The TwoFAContextProp Interface
 */
export type TwoFAContextProp = {
    twoFASettings: ITwoFASettings | null;
    fetchSettings: () => Promise<void>;
};

/**
 * The two factor authentication context.
 */
export const TwoFAContext = createContext<TwoFAContextProp | null>(null);

/**
 * The use two factor authentication context for functional component.
 */
export const useTwoFAContext = (): TwoFAContextProp => useContext(TwoFAContext) as TwoFAContextProp;
