import React, { useCallback, useMemo, useState } from "react";
import { useCreateModalEntity } from "../../../hooks/CreateModalEntityHook";
import { IContentEntityModel, IUpdateContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { IEntityFormData } from "../../../models/entity/form/IEntityFormData";
import { NotificationService } from "../../../services/NotificationService";
import { INavTabProps } from "../../tabs/NavTab";
import { NavTabs } from "../../tabs/NavTabs";
import { useContentManagerItemPropertiesValidation } from "../../../hooks/contentManager/itemProperties/ItemPropertiesValidation";
import { IDefaultEntityProps } from "../../../models/IDefaultEntityProps";
import { SaveButton } from "../../buttons/SaveButton";
import { ContentManagerItemPropertiesOverviewTab } from "./OverviewTab";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { usePermissionKey } from "../../../hooks/permission/PermissionKeyHook";
import { PermissionKeys } from "../../../PermissionKeyConstants";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { usePermissionCheck } from "../../../hooks/permission/PermissionCheckHook";
import { TabPanel } from "../../tabs/TabPanel";

import "./ItemProperties.scss";

interface IContentManageritemProps {
    item: IContentEntityModel;
    setDirty: (dirty: boolean) => void;
    saveChanges: (itemId: string, updateFolderModel: IUpdateContentEntityModel) => Promise<IContentEntityModel>;
    refreshEntity: (item: IContentEntityModel) => Promise<void>;
}

export const ContentManagerItemProperties: React.FC<IContentManageritemProps> = ({
    item,
    setDirty,
    saveChanges,
    refreshEntity,
}) => {
    const { projectId } = useProjectContext();
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const contentItemWriteKey = usePermissionKey({
        permission: PermissionKeys.contentManager.write,
        objectId: item.id,
        projectId,
    });

    const permissionsObject = useMemo(() => ({ permissionKeys: [contentItemWriteKey] }), [contentItemWriteKey]);

    const { isAllowed } = usePermissionCheck(permissionsObject);
    const canSave = useMemo(
        () => isDirty && isValid && !isSaving && isAllowed(contentItemWriteKey),
        [isDirty, isValid, isSaving, contentItemWriteKey, isAllowed],
    );

    const onFormChange = useCallback(
        ({ dirty, validationStatus }: IEntityFormData<Omit<IDefaultEntityProps, "name">>) => {
            setDirty(dirty);
            setIsDirty(dirty);
            setIsValid(validationStatus === "Valid");
        },
        [setDirty],
    );

    const { data, errors, onChange } = useCreateModalEntity({
        initialData: { description: item.description ?? "" },
        onFormChange: onFormChange,
        useValidation: useContentManagerItemPropertiesValidation,
    });

    const [activeTab, setActiveTab] = useState("overview");

    const tabs: INavTabProps[] = useMemo(
        () => [
            {
                displayName: "Common.Overview",
                name: "overview",
                invalid: false,
                dirty: isDirty,
                onToggle: setActiveTab,
                tabcontrol: "overview",
                id: "overview-id",
            },
        ],
        [isDirty],
    );

    const handleSave = useCallback(async () => {
        setIsSaving(true);
        try {
            const updatedItem = await saveChanges(item.id, { name: item.name, description: data.description });
            NotificationService.addSuccessNotification({
                messageKey: `ContentManager.${updatedItem.type}.SaveSuccess`,
                messageKeyParams: { name: updatedItem.name },
            });
            await refreshEntity(updatedItem);
        } catch (error) {
            defaultRequestErrorHandler(error);
        }

        setIsSaving(false);
    }, [data.description, item.id, item.name, refreshEntity, saveChanges]);

    return (
        <div className="item-properties-container">
            <div className="item-properties-container__content">
                <NavTabs activeTab={activeTab} navTabs={tabs}>
                    <TabPanel labelby="overview-id" tabId="overview">
                        <ContentManagerItemPropertiesOverviewTab
                            item={item}
                            descriptionData={data.description || ""}
                            handleDescriptionChange={(val) => onChange("description", val)}
                            descriptionError={errors.description}
                            readonly={!isAllowed(contentItemWriteKey)}
                        />
                    </TabPanel>
                </NavTabs>
            </div>
            <div className="item-properties-container__action-row">
                <SaveButton
                    icon="far fa-check"
                    size="sm"
                    disabled={!canSave}
                    isSaving={isSaving}
                    onClick={handleSave}
                />
            </div>
        </div>
    );
};
