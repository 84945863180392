import { useCallback } from "react";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { useProjectGroupApi } from "../project/ProjectGroupApiHook";
import { useOrganizationGroupApi } from "../organization/OrganizationGroupApiHook";
import { usePermissionGroupApi } from "./PermissionGroupApiHook";
import { IPermissionsGroupFilter } from "../../components/permissions/PermissionsGroupView";

interface UseGroupsProps {
    organizationId?: string;
    projectId?: string;
}

interface UseGroupsReturn {
    getGroups: (
        filterValue?: IPermissionsGroupFilter,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IPermissionsGroupModel>>;
}

export const useGroups = ({ organizationId, projectId }: UseGroupsProps): UseGroupsReturn => {
    const { getRecords: getProjectGroupRecords } = useProjectGroupApi({ projectId: projectId! });
    const { getRecords: getOrganizationGroupRecords } = useOrganizationGroupApi();
    const { getRecords: getPermissionGroupRecords } = usePermissionGroupApi();

    const getGroups = useCallback(
        (filterValue?: IPermissionsGroupFilter, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue.filterText;
                filters.Description = filterValue.filterText;
                if (filterValue.filterType.length > 0) {
                    filters.IsSystem = JSON.stringify(filterValue.filterType);
                }
            }

            if (projectId) {
                return getProjectGroupRecords(filters, sortFields, offset);
            }

            if (organizationId) {
                return getOrganizationGroupRecords(organizationId, filters, sortFields, offset);
            }

            return getPermissionGroupRecords(filters, sortFields, offset);
        },
        [projectId, organizationId, getProjectGroupRecords, getOrganizationGroupRecords, getPermissionGroupRecords],
    );

    return {
        getGroups,
    };
};
