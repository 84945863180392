import React, { useCallback, useMemo, useState } from "react";
import { CreateEntityContext } from "../../contexts/CreateEntityContext";
import { ModalContext, IShowModalParams } from "../../contexts/ModalContext";
import { CreateEntityWrapper } from "../create/CreateEntityWrapper";
import { CreateEntityModal } from "./CreateEntityModal";
import { useNavigationCloseHandler } from "../../hooks/modals/NavigationCloseHandlerHook";

/**
 * The model with context props interface.
 */
interface IModelWithContextProps {
    children?: React.ReactNode;
}

interface IModalState<TModalProps, TFormProps, TFormComponent extends React.ComponentType<TFormProps>> {
    onCreate?: (entity: unknown) => Promise<void>;
    onCreated: (entity: unknown) => void;
    entity: object;
    contentToDisplay?: TFormComponent;
    modalProps?: TModalProps;
    isDisplayed: boolean;
    formPropsToFlow?: TFormProps;
    readOnly?: boolean;
    allowCreateWhenNotDirty?: boolean;
    alwaysShown?: boolean;
    customFooter?: React.ReactNode;
}

const initialState = {
    isDisplayed: false,
    alwaysShown: false,
} as IModalState<any, any, any>;

export const ModalWithContext: React.FC<IModelWithContextProps> = (props: IModelWithContextProps) => {
    const [modalState, setModalState] = useState<IModalState<any, any, any>>(initialState);

    const showModal = useCallback(<TModalProps, TFormProps>(params: IShowModalParams<TModalProps, TFormProps>) => {
        setModalState({
            ...params,
            isDisplayed: true,
        });
    }, []);

    const closeModal = useCallback(() => {
        setModalState({
            ...initialState,
        });
    }, []);

    useNavigationCloseHandler({ close: closeModal, isOpen: !modalState.alwaysShown && modalState.isDisplayed });

    const memoizedModalContent = useMemo(
        () => ({
            isDisplayed: Boolean(modalState.isDisplayed),
            closeModal,
            showModal,
        }),
        [closeModal, showModal, modalState.isDisplayed],
    );

    return (
        <ModalContext.Provider value={memoizedModalContent}>
            {props.children}
            {modalState.contentToDisplay && (
                <CreateEntityWrapper
                    onCreate={modalState.onCreate}
                    onCreated={modalState.onCreated}
                    show={modalState.isDisplayed}
                    defaultEntity={modalState.entity}
                    allowCreateWhenNotDirty={modalState.allowCreateWhenNotDirty}
                >
                    <CreateEntityContext.Consumer>
                        {({ show, onChange, creating, canCreate, create, dirty, errorMessage, resetErrorMessage }) => {
                            return (
                                <CreateEntityModal
                                    {...modalState.modalProps}
                                    isCreating={creating}
                                    canCreate={canCreate}
                                    show={show}
                                    onCreate={create}
                                    onErrorMessageToggle={resetErrorMessage}
                                    errorMessage={errorMessage}
                                    isDirty={dirty}
                                    readOnly={modalState.readOnly}
                                >
                                    <modalState.contentToDisplay
                                        {...modalState.formPropsToFlow}
                                        onFormChange={onChange}
                                        onSubmit={create}
                                        initialValue={modalState.entity}
                                    />
                                </CreateEntityModal>
                            );
                        }}
                    </CreateEntityContext.Consumer>
                </CreateEntityWrapper>
            )}
        </ModalContext.Provider>
    );
};
