import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { applicationPaths } from "../../api-authorization/ApiAuthorizationConstants";
import { Button } from "../../buttons/Button";
import { useDialogContext } from "../../../contexts/DialogContext";
import { useNavigation } from "../../../hooks/NavigationHook";
import { ValidatedInput } from "../../forms/inputs/ValidatedInput";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { NotificationService } from "../../../services/NotificationService";
import { defaultRequestErrorHandler, extractErrorMessage } from "../../../helpers/ErrorHelper";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";

/**
 * The personal data confirm password view component.
 */
export const PersonalDataConfirmPasswordView: React.FC = () => {
    const { t } = useTranslation();
    const dialogContext = useDialogContext();
    const { navigate } = useNavigation();
    const [hasPassword] = useOutletContext<[boolean]>();
    const { deletePersonalData } = useAccountApi();
    const [currentPassword, setCurrentPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const onPasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(e.target.value);
        setPasswordError(!e.target.value ? "PersonalData.Delete.PasswordRequired" : "");
    }, []);

    const onDeletePersonalData = useCallback(async () => {
        try {
            await deletePersonalData(currentPassword);
            NotificationService.addSuccessNotification({
                messageKey: "PersonalData.Delete.Success",
            });
            navigate(applicationPaths.logOut, { state: { local: true } });
        } catch (error) {
            defaultRequestErrorHandler({ messageKey: extractErrorMessage(error) });
        }
    }, [deletePersonalData, currentPassword, navigate]);

    const openDeletePersonalDataDialog = () => {
        dialogContext.show({
            type: "Delete",
            messageKey: "PersonalData.Delete.Warning",
            titleKey: "PersonalData.Delete.Title",
            onProceed: onDeletePersonalData,
            proceedLabelKey: "PersonalData.Delete",
        });
    };

    return (
        <section className="delete-personal-data">
            <div className="warning-message">
                <Trans i18nKey="PersonalData.Delete.Warning" />
            </div>
            {hasPassword && (
                <div className="input-container">
                    <LocalizedLabel for="current-password" text="Route.Password" />
                    <ValidatedInput
                        name="current-password"
                        value={currentPassword}
                        type="password"
                        onChange={onPasswordChange}
                        error={passwordError}
                    />
                </div>
            )}
            <Button
                color="danger"
                onClick={openDeletePersonalDataDialog}
                disabled={hasPassword && currentPassword === ""}
                ariaLabel={t("PersonalData.Delete.Confirm")}
            >
                <i className="far fa-trash-alt" />
                {t("PersonalData.Delete.Confirm")}
            </Button>
        </section>
    );
};
