import { useMemo, useCallback, useEffect, useState } from "react";
import { ICreateContentFolderFormFields } from "../../components/contentManager/CreateContentFolderForm";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { IValidationResult } from "../CreateModalEntityHook";
import { ContentFormValidator } from "../../formValidators/ContentFormValidator";
import { NameValidator } from "../../formValidators/NameValidator";
import { useEntityNameValidation } from "../entity/validation/EntityNameValidationHook";
import { useContentFolderApi } from "./ContentFolderApiHook";

export const useContentValidation = ({
    name,
    projectId,
    parentFolderId,
}: ICreateContentFolderFormFields): IValidationResult<ICreateContentFolderFormFields, "name"> => {
    const { errors, setError } = useEntityErrors<ICreateContentFolderFormFields>();
    const [initialValues] = useState<ICreateContentFolderFormFields>({ name, projectId, parentFolderId });
    const [valid, setValid] = useState(false);
    const { nameIsUnique } = useContentFolderApi(projectId);

    const nameValidatorProvider = useCallback(() => {
        return new NameValidator({
            nameExistErrorKey: "ContentFormValidator.FolderExistsAlready",
            nameIsUnique: (n) => nameIsUnique(n, parentFolderId),
        });
    }, [nameIsUnique, parentFolderId]);

    const nameValidator = useMemo(() => nameValidatorProvider(), [nameValidatorProvider]);

    const { nameStatus, error: nameError } = useEntityNameValidation({
        defaultName: initialValues.name,
        name,
        nameValidator,
    });

    const formValidator = useMemo(() => new ContentFormValidator(), []);

    useEffect(() => {
        formValidator.setInitialValue({
            ...initialValues,
            description: null,
        });
    }, [initialValues, formValidator]);

    useEffect(() => {
        setError("name", nameError);
    }, [nameError, setError]);

    useEffect(() => {
        void formValidator.isValid({ name, description: null }).then((value) => setValid(value));
    }, [formValidator, name]);

    return {
        valid,
        errors,
        setError,
        validationStatuses: { name: nameStatus },
    };
};
