import React, { useMemo } from "react";
import classNames from "classnames";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { ContentFormValidator } from "../../../formValidators/ContentFormValidator";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { FormFeedbackError } from "../../forms/feedbacks/FormFeedbackError";
import { StatusIcon } from "../../forms/feedbacks/StatusIcon";
import { AutosizeInput } from "../../forms/inputs/AutosizeInput";
import { LocalizedInput } from "../../forms/inputs/LocalizedInput";
import { splitFileNameFromExtension } from "../../../helpers/ContentFileHelper";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";

import "../../edit/EditEntityName.scss";

export const EditItemNameInput = () => {
    const {
        validateNameProps: { checkNameIsUnique, isValidatingName },
        dirtyProps: { isDirty },
        entityProps: { entity, setEntityProperties, formValidator },
        errorProps: {
            errors: { name: nameErrorMessage },
            setErrors,
        },
    } = useEditEntityContext<IContentEntityModel, ContentFormValidator>();

    const [innerName, extension] = useMemo(() => {
        if (entity.type === "Folder") {
            return [entity.name];
        }

        return splitFileNameFromExtension(entity.name);
    }, [entity.name, entity.type]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let newName = event.target.value;
        formValidator
            .validateName(newName)
            .then(() => {
                setErrors({ name: "" });
            })
            .catch((error: unknown) => {
                setErrors({ name: extractErrorMessageOrEmptyString(error) });
            });
        if (extension) {
            newName += extension;
        }
        setEntityProperties({ name: newName });
        checkNameIsUnique(newName);
    };

    const onNameFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    const isInvalid = !isValidatingName && !!nameErrorMessage;
    const showValidationIcon = isDirty(["name"]) && !nameErrorMessage;
    const showError = !isValidatingName && nameErrorMessage;
    const { setSelector } = useTestSelector();

    return (
        <div className={classNames("entity-name", { "is-invalid": isInvalid })}>
            <div className="entity-name-inner">
                <LocalizedLabel for="edit-name" text="Common.Name" hidden />
                <LocalizedInput
                    id="edit-name"
                    placeholder={"Common.Name"}
                    autoComplete="off"
                    tag={AutosizeInput}
                    invalid={isInvalid}
                    value={innerName}
                    type="text"
                    name="name"
                    className="text-truncate"
                    onChange={onNameChange}
                    onFocus={onNameFocus}
                    {...setSelector("nameTextbox")}
                />
                {extension && <span className="entity-name__extension">{extension}</span>}
                <div className="edit-icon">
                    <i className="fas fa-pencil" />
                </div>
                {showValidationIcon && <StatusIcon isValidating={isValidatingName} />}
            </div>
            {showError && <FormFeedbackError error={nameErrorMessage} />}
        </div>
    );
};
