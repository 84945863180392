import { useCallback, useEffect } from "react";
import { hubPaths } from "../../PathConstants";
import { useHubConnection } from "./HubConnection";

/**
 * The notification model.
 */
export interface INotificationModel {
    messageKey: string;
    messageKeyParams: object;
    url: string;
}

/**
 * The notification hub props interface.
 */
interface INotificationHubConnectionProps {
    notification: (model: INotificationModel) => void;
}

/**
 * The notification hub result interface.
 */
interface INotificationHubConnectionResult {
    subscribeProjectCreation: (projectId: string) => void;
}

const notificationMethodName = "Notification";
const subscribeProjectCreationMethodName = "SubscribeProjectCreation";

/**
 * The notification hub hook.
 */
export const useNotificationHub = ({
    notification,
}: INotificationHubConnectionProps): INotificationHubConnectionResult => {
    const { connection } = useHubConnection({ hubUrl: hubPaths.notification });

    useEffect(() => {
        if (!connection) {
            return;
        }

        connection.on(notificationMethodName, notification);

        return () => {
            connection.off(notificationMethodName, notification);
        };
    }, [connection, notification]);

    const subscribeProjectCreation = useCallback(
        (projectId: string) => {
            if (!connection) {
                return;
            }
            void connection.send(subscribeProjectCreationMethodName, projectId);
        },
        [connection],
    );

    return {
        subscribeProjectCreation,
    };
};
