import React from "react";
import classNames from "classnames";
import { ArrowToggleButton } from "../buttons/ArrowToggleButton";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { FluentFolderIcon } from "../icons/FluentFolderIcon";
import { FoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import { LoadingWrapper } from "../loading/LoadingWrapper";
import { useTreeItem } from "../../hooks/contentManager/TreeItemHook";

import "./ContentManagerTreeItem.scss";

interface ContentManagerTreeItemProps {
    item: ITreeItem;
    getSubFolders: (item: ITreeItem) => Promise<FoldersTree>;
    handleCollapse: (id: string, state: boolean) => void;
    handleTreeItem: (item: ITreeItem | null) => void;
    selectedFolderId: string | null | undefined;
    setItemLoadingState: (id: string, state: boolean) => void;
}

const defaultPadding = 1.5;
const calculateNestedPadding = (level: number) => level * defaultPadding + defaultPadding;

/**
 * The content manager tree menu component.
 */
export const ContentManagerTreeItem: React.FC<ContentManagerTreeItemProps> = ({
    item,
    getSubFolders,
    handleCollapse,
    handleTreeItem,
    selectedFolderId,
    setItemLoadingState,
}) => {
    const { setSelector } = useTestSelector();
    const { onCollapse } = useTreeItem({ getSubFolders, handleCollapse, setItemLoadingState });
    const { hasChildren, id, isOpen, name, path, isLoadingChildren } = item;

    return (
        <>
            <li
                className={classNames("tree-menu-item", {
                    noSubfolder: !hasChildren,
                    active: id === selectedFolderId,
                    isChild: item.level > 0,
                })}
                style={{ paddingLeft: `${calculateNestedPadding(item.level)}rem` }}
            >
                {hasChildren && <ArrowToggleButton onClick={() => onCollapse(item)} isOpen={isOpen} />}
                <div className="tree-menu-item-name-container" onClick={() => handleTreeItem(item)} { ...setSelector(`folder-${path}`) }>
                    <FluentFolderIcon />
                    <span className="tree-menu-item-name">{name}</span>
                </div>
            </li>
            {isLoadingChildren && (
                <li className="tree-menu-loading" style={{ paddingLeft: `${calculateNestedPadding(item.level)}rem` }}>
                    <LoadingWrapper className="loading-folder-animation" size={16} message="Common.Loading" />
                </li>
            )}
        </>
    );
};
