import React from "react";
import classNames from "classnames";
import { NavItem, NavLink } from "reactstrap";
import { NavLink as NavLinkRRD } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ICrumb } from "../../../models/breadcrumbs/ICrumb";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

/**
 * The crumb item props.
 */
export interface ICrumbItemProps {
    crumb: ICrumb;
    testSelectorValueIndex?: number;
    isCurrentBreadcrumb?: boolean;
}

/**
 * The crub item component.
 */
export const CrumbItem: React.FunctionComponent<ICrumbItemProps> = (props: ICrumbItemProps): JSX.Element => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const {
        crumb: { name, path, escapeTranslation },
        isCurrentBreadcrumb = false,
    } = props;

    const labelValue = escapeTranslation ? name : t(name);

    return (
        <NavItem role="menuitem">
            <NavLink
                tag={NavLinkRRD}
                className={classNames({ isActiveBreadcrumb: isCurrentBreadcrumb })}
                to={path}
                end
                aria-current={isCurrentBreadcrumb ? "page" : "false"}
                disabled={isCurrentBreadcrumb}
                {...setSelector(`breadCrumbItem-${props.testSelectorValueIndex ?? ""}`)}
            >
                {labelValue}
            </NavLink>
        </NavItem>
    );
};
