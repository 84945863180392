import React from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { DropdownDivider } from "./DropdownDivider";

import "./EntityDropdownMenu.scss";

/**
 * The entity dropdownm menu item interface.
 */
export interface IEntityDropdownMenuItem {
    className: string;
    disabled?: boolean;
    text: string;
    testSelectorValue?: string;
    hidden?: boolean;
    onClick: () => void;
}

/**
 * The entity dropdown menu props interface.
 */
interface IEntityDropdownMenuProps {
    items: IEntityDropdownMenuItem[];
    testSelectorValue?: string;
}

/**
 * The entity dropdown menu component.
 */
export const EntityDropdownMenu: React.FC<IEntityDropdownMenuProps> = ({
    items,
    testSelectorValue: testSelectorValueDropdown,
}: IEntityDropdownMenuProps) => {
    const { setSelector } = useTestSelector();
    const { t } = useTranslation();

    return (
        <>
            <UncontrolledDropdown
                direction="start"
                className="page-header-ellipsis"
                {...setSelector(testSelectorValueDropdown)}
            >
                <DropdownToggle data-toggle="dropdown" outline {...setSelector("dropdownToggle")}>
                    <i className="far fa-ellipsis-v" />
                    <span className="screenreader-only">{t("Common.DropdownMenu")}</span>
                </DropdownToggle>
                <DropdownMenu container="body" {...setSelector(testSelectorValueDropdown)} role="menu">
                    {items &&
                        items.map(({ className, text, testSelectorValue, onClick, disabled, hidden }) => (
                            <React.Fragment key={`${text}-index`}>
                                {text === "Delete" && <DropdownDivider />}
                                {!hidden && (
                                    <DropdownItem
                                        className={text === "Delete" ? "delete-item" : ""}
                                        onClick={onClick}
                                        disabled={disabled ?? false}
                                        role="menuitem"
                                        {...setSelector(testSelectorValue)}
                                    >
                                        <i className={className} />
                                        {text}
                                    </DropdownItem>
                                )}
                            </React.Fragment>
                        ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
};
