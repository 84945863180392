import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useCreateEntityContext } from "../../contexts/CreateEntityContext";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { useModalContext } from "../../contexts/ModalContext";
import { ITranslateFormFields } from "../../models/contentManager/ITranslateFormFields";

interface TranslateModalFooterPrimaryActionProps {
    item: IContentEntityModel;
    onItemOpen: (fileId: string, fileName: string, translationLanguageCode: string) => void;
    onItemDownload: (fileId: string, fileName: string, translationLanguageCode: string) => Promise<void>;
}

export const TranslateModalFooterPrimaryAction = ({
    item,
    onItemOpen,
    onItemDownload,
}: TranslateModalFooterPrimaryActionProps) => {
    const { t } = useTranslation();

    const { canCreate, creating, ...rest } = useCreateEntityContext();
    const { closeModal } = useModalContext();

    const { language } = rest.data as ITranslateFormFields;

    const onTranslateAndOpen = () => {
        if (!language) return;

        closeModal();
        onItemOpen(item.id, item.name, language.value);
    };

    const onTranslateAndDownload = async () => {
        if (!language) return;

        closeModal();
        await onItemDownload(item.id, item.name, language.value);
    };

    return (
        <UncontrolledDropdown group direction="down">
            <Button color="primary" disabled={!canCreate || creating} onClick={onTranslateAndOpen}>
                {t("ContentManager.File.TranslateAndOpen")}
            </Button>
            <DropdownToggle caret color="primary" disabled={!canCreate || creating} />
            <DropdownMenu>
                <DropdownItem onClick={onTranslateAndDownload}>
                    {t("ContentManager.File.TranslateAndDownload")}
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
