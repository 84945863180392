import React from "react";
import { FormGroup } from "reactstrap";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { OrganizationFormValidator } from "../../../formValidators/OrganizationFormValidator";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { IOrganizationModel } from "../../../models/organization/IOrganizationModel";
import { ValidatedInput } from "../../forms/inputs/ValidatedInput";
import { LocalizedLabel } from "../../forms/LocalizedLabel";

/**
 * The Edit Organization View Tabs Overview component
 */
export const EditOrganizationViewTabsOverview = (): JSX.Element => {
    const { setSelector } = useTestSelector();

    const {
        entityProps: { entity: organization, setEntityProperties, formValidator },
        errorProps: { errors, setErrors },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();

    const onDescriptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let errorMessage: string;

        try {
            setEntityProperties({ description: event.target.value });
            await formValidator.validateDescription(event.target.value);
            errorMessage = "";
        } catch (error) {
            errorMessage = extractErrorMessageOrEmptyString(error);
        }

        setErrors({ description: errorMessage });
    };

    return (
        <FormGroup className="entity-description">
            <LocalizedLabel for="edit-organization-description" text="Common.Description" />
            <ValidatedInput
                id="edit-organization-description"
                name="description"
                type="textarea"
                {...setSelector("descriptionTextbox")}
                onChange={onDescriptionChange}
                error={errors.description}
                value={organization.description}
                disabled={!canEdit}
            />
        </FormGroup>
    );
};
