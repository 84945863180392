import React from "react";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { ContentManagerContentView } from "./ContentManagerContentView";
import { EntityViewBody } from "../views/EntityViewBody";
import { foldersTreeAtom, searchStatusAtom, selectedFolderIdAtom } from "../../atoms/ContentManager";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";
import { ContentManagerTreeMenu } from "./ContentManagerTreeMenu";
import { ContentManagerSearchResultView } from "./ContentManagerSearchResultView";
import { ContentManagerSearchBar } from "./ContentManagerSearchBar";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";

import "./ContentManagerView.scss";

/**
 * The content manager view component.
 */
export const ContentManagerView: React.FC = () => {
    const { handleSelectedPath, getSubFolders, handleCollapse, setItemLoadingState } = useContentManagerContext();
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const foldersTree = useAtomValue(foldersTreeAtom);
    const searchStatus = useAtomValue(searchStatusAtom);
    const { t } = useTranslation();

    return (
        <>
            <SectionHeader>
                <SectionHeader.Group>
                    <SectionHeader.Title>{t("ContentManager.Title")}</SectionHeader.Title>
                </SectionHeader.Group>
                <SectionHeader.Group flexGrow>
                    <ContentManagerSearchBar />
                </SectionHeader.Group>
                <SectionHeader.ActionGroup />
            </SectionHeader>
            <EntityViewBody className="content-manager-view-body">
                <ContentManagerTreeMenu
                    handleTreeItem={handleSelectedPath}
                    foldersTree={foldersTree}
                    selectedFolderId={selectedFolderId}
                    getSubFolders={getSubFolders}
                    handleCollapse={handleCollapse}
                    setItemLoadingState={setItemLoadingState}
                />
                {searchStatus ? <ContentManagerSearchResultView /> : <ContentManagerContentView />}
            </EntityViewBody>
        </>
    );
};
