import React from "react";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { SideMenuItem } from "./SideMenuItem";

/**
 * The Sidebar NavItem icon props interface.
 */
interface ISidebarNavItemIconProps {
    name: string;
    icon: string;
    testSelectorValue?: string;
}

/**
 * The Sidebar NavItem icon component.
 */
export const SidebarNavItemIcon: React.FunctionComponent<ISidebarNavItemIconProps> = ({
    name,
    icon,
    testSelectorValue,
}: ISidebarNavItemIconProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    return (
        <SideMenuItem>
            <div className="nav-link-icon-wrapper">
                <i className={icon} />
            </div>
            <span className="nav-link-text text-truncate" {...setSelector(testSelectorValue)}>
                {t(name)}
            </span>
        </SideMenuItem>
    );
};
