import React, { PropsWithChildren, useCallback } from "react";
import { Button } from "./Button";

interface IIconButtonProps {
    className?: string;
    onClick: () => void;
    label: string;
}

/**
 * The icon button component
 */
export const IconButton: React.FC<PropsWithChildren<IIconButtonProps>> = ({
    onClick,
    children,
    label,
    className,
}: PropsWithChildren<IIconButtonProps>) => {
    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLButtonElement>) => {
            if (e.key === "Enter") {
                onClick();
            }
        },
        [onClick],
    );

    return (
        <Button
            className={`icon-btn ${className ?? ""}`}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            ariaLabel={label}
            color="ghost"
        >
            {children}
        </Button>
    );
};
