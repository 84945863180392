import React, { useCallback, useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import { LocalizedLabel } from "../forms/LocalizedLabel";

import "./RadioComponentGroup.scss";

export type RadioComponentOptions<T> = {
    value: T;
    label: string;
    icon?: string;
};

interface IRadioComponentProps {
    radioName: string;
    selectedValue: number;
    options: RadioComponentOptions<IRadioComponentProps["selectedValue"]>[];
    onChange: (val: IRadioComponentProps["selectedValue"]) => void;
    disabled?: boolean;
}

export const RadioComponentGroup: React.FC<IRadioComponentProps> = ({
    radioName,
    selectedValue,
    options,
    onChange,
    disabled = false,
}: IRadioComponentProps) => {
    const changeHandler = useCallback(
        (newValue: IRadioComponentProps["selectedValue"]) => {
            onChange(newValue);
        },
        [onChange],
    );

    const radioOptions = useMemo(() => {
        return options.map(({ value, label, icon }) => (
            <FormGroup check key={value}>
                <Input
                    name={radioName}
                    type="radio"
                    checked={selectedValue === value}
                    onChange={() => changeHandler(value)}
                    className="input-radio"
                    id={radioName}
                    disabled={disabled}
                />
                <LocalizedLabel
                    for={radioName}
                    check
                    text={label}
                    icon={icon}
                    className={icon ? "label-with-icon" : ""}
                />
            </FormGroup>
        ));
    }, [changeHandler, disabled, options, radioName, selectedValue]);

    return <div className="radio-component">{radioOptions}</div>;
};
