import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { accountPaths, apiKeyPaths } from "../PathConstants";
import { buildSearchGlobalProperties } from "../helpers/SearchHelper";
import { IRoute } from "../models/IRoute";
import { HeaderNavBarContext } from "../contexts/HeaderNavBarContext";
import { SidebarLayout } from "./SidebarLayout";
import { SidebarNavItemIcon } from "../components/sidebar/SidebarNavItemIcon";
import { useAccountApi } from "../hooks/account/AccountApiHook";
import { useSearchApi } from "../hooks/search/SearchApiHook";

/**
 * The default routes.
 */
const defaultRoutes: IRoute[] = [
    {
        path: accountPaths.route.profile,
        children: <SidebarNavItemIcon icon="far fa-user" name="Route.Profile" />,
    },
    // NOTE: The path to email is temporarily disabled until its functionalities are implemented - Ref: Work item #44289.
    // {
    //     path: accountPaths.route.email,
    //     children: <SidebarNavItemIcon icon="far fa-envelope" name="Route.Email" />,
    // },
    {
        path: accountPaths.route.password,
        children: <SidebarNavItemIcon icon="far fa-lock" name="Route.Password" />,
    },
    {
        path: accountPaths.route.twoFactorAuthentication,
        children: <SidebarNavItemIcon icon="far fa-shield-check" name="Route.TwoFactorAuthentication" />,
    },
    {
        path: apiKeyPaths.link.base,
        children: <SidebarNavItemIcon icon="far fa-id-card-alt" name="Route.ApiKeys" />,
        testSelectorValue: "apiKeysItem",
    },
];

/**
 * The external logins route.
 */
const externalLoginsRoute: IRoute = {
    path: accountPaths.route.externalProviders,
    children: <SidebarNavItemIcon icon="far fa-external-link-square" name="Route.ExternalProviders" />,
};

/**
 * The profile layout component.
 */
export const ProfileLayout: React.FC = () => {
    const [profileRoutes, setProfileRoutes] = useState<IRoute[]>([]);
    const { getExternalLoginsProviderCount } = useAccountApi();
    const { searchBy } = useSearchApi();

    const initializeProfileRoutes = useCallback(async () => {
        const providersCount = await getExternalLoginsProviderCount();

        const newRoutes = [...defaultRoutes];

        if (providersCount) {
            newRoutes.splice(3, 0, externalLoginsRoute);
        }

        setProfileRoutes(newRoutes);
    }, [getExternalLoginsProviderCount]);

    useEffect(() => {
        initializeProfileRoutes();
    }, [initializeProfileRoutes]);

    const memoizedSearchGlobalProperties = useMemo(
        () => ({ search: { ...buildSearchGlobalProperties(), onSearch: searchBy } }),
        [searchBy],
    );

    return (
        <HeaderNavBarContext.Provider value={memoizedSearchGlobalProperties}>
            <SidebarLayout routes={profileRoutes}>
                <Outlet />
            </SidebarLayout>
        </HeaderNavBarContext.Provider>
    );
};
