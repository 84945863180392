import { useCallback, useMemo, useState } from "react";
import { createNewFolders } from "../../helpers/ContentManagerFolderHelper";
import { FoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import { recursiveOpenStateFolder, sortFolderTree, updateParents } from "../../helpers/TreeFolderHelper";
import { useContentFolderApi } from "./ContentFolderApiHook";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

export type ContentManagerActionType = "Move" | "Copy";

interface SelectFolderModalHookProps {
    projectId: string;
    foldersTree: FoldersTree;
    canConfirm: (destination?: ITreeItem | null) => boolean;
}

interface SelectFolderModalHookResult {
    isDisabled: boolean;
    dialogFoldersTree: FoldersTree;
    handleTreeItem: (item: ITreeItem | null) => void;
    selectedFolder: ITreeItem | null | undefined;
    getSubFolders: (item: ITreeItem) => Promise<FoldersTree>;
    handleCollapse: (id: string, state: boolean, closeParents?: boolean) => void;
    setItemLoadingState: (id: string, isLoading: boolean) => void;
}

export const useSelectFolderModal = ({
    projectId,
    foldersTree,
    canConfirm,
}: SelectFolderModalHookProps): SelectFolderModalHookResult => {
    const [dialogFoldersTree, setDialogFoldersTree] = useState<FoldersTree>(
        structuredClone(foldersTree) as FoldersTree,
    );
    const [selectedFolder, setSelectedFolder] = useState<ITreeItem | null | undefined>(undefined);

    const { getSubFolders: getContentSubFolders } = useContentFolderApi(projectId);

    const handleTreeItem = useCallback((item: ITreeItem | null) => {
        setSelectedFolder(item);
    }, []);

    const isDisabled = useMemo<boolean>(() => {
        let isDisabledInternal = selectedFolder === undefined; // there are no selected destination folder

        if (!isDisabledInternal) {
            isDisabledInternal = !canConfirm(selectedFolder);
        }

        return isDisabledInternal;
    }, [selectedFolder, canConfirm]);

    const getSubFolders = useCallback(
        async (item: ITreeItem): Promise<FoldersTree> => {
            try {
                const subFolders = await getContentSubFolders(item.id);
                const newFolders = createNewFolders(item, subFolders);
                if (newFolders.length < 1) {
                    return {} as FoldersTree;
                }

                setDialogFoldersTree((olderTree) => {
                    let sortedFolders = sortFolderTree([...olderTree, ...newFolders]);
                    sortedFolders = updateParents(sortedFolders, newFolders);
                    return sortedFolders;
                });
                return newFolders;
            } catch (error) {
                defaultRequestErrorHandler({
                    messageKey: "ContentManager.Folder.LoadingError",
                });
                return {} as FoldersTree;
            }
        },
        [getContentSubFolders],
    );

    const handleCollapse = useCallback((id: string, state: boolean, closeParents: boolean = false) => {
        setDialogFoldersTree((oldTree) => {
            const newFolderTree = [...oldTree];
            recursiveOpenStateFolder(newFolderTree, id, state, closeParents);
            return newFolderTree;
        });
    }, []);

    const setItemLoadingState = useCallback((id: string, isLoading: boolean) => {
        setDialogFoldersTree((oldTree) => {
            const item = oldTree.find((f) => f.id === id);
            if (item) {
                item.isLoadingChildren = isLoading;
            }
            return oldTree;
        });
    }, []);

    return {
        isDisabled,
        dialogFoldersTree,
        handleTreeItem,
        selectedFolder,
        getSubFolders,
        handleCollapse,
        setItemLoadingState,
    };
};
