import React from "react";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ProjectAvatar } from "../projects/ProjectAvatar";

import "./ProjectScope.scss";

interface IProjectScopeProps {
    name: string;
}

export const ProjectScope: React.FC<IProjectScopeProps> = ({ name }) => (
    <div className="project-scope">
        <LocalizedLabel aria-required="true" text="Common.Project" />
        <div className="project-scope__row">
            <ProjectAvatar name={name} size="24" className="project-scope__avatar" />
            <div className="project-scope__text">{name}</div>
        </div>
    </div>
);
