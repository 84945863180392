import React, { useMemo } from "react";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import { Trans, useTranslation } from "react-i18next";
import { Dialog } from "./Dialog";

import "./InsufficientPermissionsDialog.scss";

interface InsufficentPermissionsDialogProps extends React.PropsWithChildren<{}> {
    className?: string;
    title: string;
    show: boolean;
    onCancel: () => void;
    warningMessageKey: string;
}

export const InsufficentPermissionsDialog: React.FC<InsufficentPermissionsDialogProps> = ({
    title,
    children,
    className,
    show,
    onCancel,
    warningMessageKey,
}: InsufficentPermissionsDialogProps) => {
    const { t } = useTranslation();

    useHotkeys(
        "escape",
        (e) => {
            e.preventDefault();
            onCancel();
        },
        {
            enabled: show,
        },
        [onCancel],
    );

    const closeButton = useMemo(() => {
        return {
            color: "secondary",
            outline: true,
            label: t("Common.Close"),
            onAction: onCancel,
            testSelectorValue: "cancelButton",
        };
    }, [onCancel, t]);

    return (
        <Dialog
            show={show}
            className={classNames("confirmation-dialog", className)}
            title={title}
            actions={[closeButton]}
            onClose={onCancel}
        >
            <div className="insufficient-permissions-warning">
                <span>
                    <Trans i18nKey={warningMessageKey} />
                </span>
                <Trans i18nKey="Permissions.ContactCTA" />
            </div>
            {children}
        </Dialog>
    );
};
