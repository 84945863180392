import { useMemo } from "react";
import { IEntityDropdownMenuItem } from "../../components/dropdowns/EntityDropdownMenu";
import { useDelete } from "./DeleteDropdownMenuItem";
import { useRefresh } from "./RefreshDropdownMenuItem";

/**
 * The default dropdown menu items.
 */
export const useDefaultDropdownMenuItems = (canDelete = true): IEntityDropdownMenuItem[] => {
    const deleteItem = useDelete();
    const refreshItem = useRefresh();
    const items = useMemo(() => {
        const i = [refreshItem];
        if (canDelete) {
            i.push(deleteItem);
        }

        return i;
    }, [canDelete, deleteItem, refreshItem]);

    return items;
};
