import React from "react";
import { DropdownItem, Label, Input } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The dropdown checkbox item props interface.
 */
interface IDropdownCheckboxItemProps extends React.PropsWithChildren<{}> {
    id: string;
    checked: boolean;
    testSelectorItemName?: string;
    onCheckChange: (name: string, checked: boolean) => void;
}

/**
 * The dropdown checkbox item functional component.
 */
export const DropdownCheckboxItem: React.FC<IDropdownCheckboxItemProps> = ({
    id,
    checked,
    children,
    testSelectorItemName,
    onCheckChange,
}: IDropdownCheckboxItemProps) => {
    const checkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const element = event.target;
        onCheckChange(element.id, element.checked);
    };
    const { setSelector } = useTestSelector();

    return (
        <DropdownItem toggle={false} tag={Label}>
            <Input
                type="checkbox"
                id={id}
                checked={checked}
                {...setSelector(testSelectorItemName ? `${testSelectorItemName}Item` : "")}
                onChange={checkChange}
            />
            {children}
        </DropdownItem>
    );
};
