import React from "react";
import classNames from "classnames";
import { GroupBase, SingleValueProps, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

export const SingleValue: React.FC<
    SingleValueProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>>
> = (props) => {
    const { setSelector } = useTestSelector();
    const { data } = props;

    return (
        <components.SingleValue {...props} className={classNames(props.className, data.className)}>
            {data.icon && <i className={classNames(data.icon, "single-value-icon")} />}
            {!!data.componentIcon && data.componentIcon}
            <span {...setSelector("dropdownSelectedItem")}>{data.label}</span>
        </components.SingleValue>
    );
};
