import React from "react";
import * as Yup from "yup";
import { IDefaultEntityProps } from "../models/IDefaultEntityProps";
import { NullableValue } from "../models/NullableValue";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

/**
/**
 * The project form validator class.
 */
export class ProjectFormValidator extends FormValidator<Omit<IDefaultEntityProps, "name">> {
    public static readonly projectNameMaxLength: number = 450;
    public static readonly projectDescriptionMaxLength: number = 1000;

    protected schema = Yup.object().shape({
        description: Yup.string()
            .nullable(true)
            .max(ProjectFormValidator.projectDescriptionMaxLength, ({ max }) => {
                return <ValidatorError messageKey="ProjectFormValidator.DescriptionMaxLengthValidation" options={{ max }} />;
            })
    });

    public validateDescription(description: NullableValue<string>): Promise<string | null | undefined> {
        return this.schema.fields.description.validate(description);
    }
}