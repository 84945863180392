import { createContext, useContext } from "react";
import { ISearchResult } from "../models/search/ISearchResult";

/**
 * The header navbar context props.
 */
interface IHeaderNavBarContextProps {
    search: {
        transform: (results: ISearchResult[]) => JSX.Element[];
        onSearch: (searchValue: string) => Promise<ISearchResult[]>;
        searchScopeKey: string;
    };
}

/**
 * The header navbar context.
 */
export const HeaderNavBarContext = createContext<IHeaderNavBarContextProps | null>(null);

/**
 * The use header navbar context for functional component.
 */
export const useHeaderNavBarContext = (): IHeaderNavBarContextProps =>
    useContext(HeaderNavBarContext) as IHeaderNavBarContextProps;
