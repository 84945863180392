import React, { useMemo } from "react";
import { ServiceHookType } from "../../models/serviceHooks/ServiceHookType";
import { IWebHookConfigModel } from "../../models/serviceHooks/configs/IWebHookConfigModel";

interface IServiceHookConfigDisplayProps {
    config: string;
    type: ServiceHookType;
}

export const ServiceHookConfigDisplay: React.FC<IServiceHookConfigDisplayProps> = ({ config, type }) => {
    const configDisplay = useMemo(() => {
        if (type === ServiceHookType.Webhook) {
            return (JSON.parse(config) as IWebHookConfigModel).serviceUri;
        }

        return "";
    }, [config, type]);

    return <>{configDisplay}</>;
};
