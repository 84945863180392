import React from "react";
import { useTranslation } from "react-i18next";
import { ServiceHookType, ServiceHookTypeTranslationMap } from "../../models/serviceHooks/ServiceHookType";

interface IServiceHookTypeLabelProps {
    type: ServiceHookType;
}

export const ServiceHookTypeLabel: React.FC<IServiceHookTypeLabelProps> = ({ type }) => {
    const { t } = useTranslation();

    return <>{t(ServiceHookTypeTranslationMap[type])}</>;
};
