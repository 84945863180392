import React from "react";
import { useAtom } from "jotai";
import { loadingModalStateAtom } from "../../atoms/ContentManager";
import { LoadingModal } from "../modals/LoadingModal";

export const ContentManagerLoadingModal = () => {
    const [loadingModalState, dispatch] = useAtom(loadingModalStateAtom);

    if (!loadingModalState.show) {
        return <></>;
    }

    return (
        <LoadingModal
            show={loadingModalState.show}
            labelKey={loadingModalState.label}
            onCancel={loadingModalState.onCancel ? () => dispatch({ type: "CANCEL_MODAL" }) : undefined}
            className="content-manager-loading-modal"
        />
    );
};
