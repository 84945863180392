import React from "react";
import classNames from "classnames";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { DialogAction, IDialogActionProps } from "./DialogAction";
import { DialogHeader } from "./DialogHeader";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The dialog props interface.
 */
interface IDialogProps {
    className?: string;
    modalHeaderClassName?: string;
    icon?: string;
    title: string;
    actions: IDialogActionProps[];
    show: boolean;
    size?: string;
    testSelectorValue?: string;
    onClose?: () => void;
    onClosed?: () => void;
    children?: React.ReactNode;
}

/**
 * The dialog component.
 */
export const Dialog: React.FunctionComponent<IDialogProps> = (props: IDialogProps) => {
    const {
        actions,
        className,
        modalHeaderClassName,
        children,
        icon,
        show,
        size,
        title,
        testSelectorValue,
        onClose,
        onClosed,
    } = props;

    const { setSelector } = useTestSelector();

    return (
        <>
            <Modal isOpen={show} className={classNames("dialog", className)} fade={true} centered={true} size={size} {...setSelector(testSelectorValue)} onClosed={onClosed}>
                <DialogHeader className={modalHeaderClassName} title={title} icon={icon} onClose={onClose} />
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    {actions.map((action, index) => <DialogAction key={index} {...action} { ...setSelector(action.testSelectorValue) } />)}
                </ModalFooter>
            </Modal>
        </>);
};