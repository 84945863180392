import * as Yup from "yup";
import { IDefaultEntityProps } from "../models/IDefaultEntityProps";
import { TemplateType } from "../models/templates/TemplateType";
import { TemplateFormValidator } from "./TemplateFormValidator";

/**
 * The create template form validation fields interface.
 */
export interface ICreateTemplateFormValidationFields extends Omit<IDefaultEntityProps, "name"> {
    type: TemplateType;
}

/*
 * The create template form validator class.
 */
export class CreateTemplateFormValidator extends TemplateFormValidator {
    protected createTemplateFormSchema = this.schema.concat(
        Yup.object().shape({
            type: Yup.mixed<TemplateType>().required("Required").oneOf([TemplateType.Standard]),
        }),
    );

    public validateType(type: TemplateType): Promise<TemplateType> {
        return this.createTemplateFormSchema.fields.type.validate(type);
    }
}
