import { useCallback, useEffect, useState } from "react";
import { useNavigation } from "../NavigationHook";
import { IDataSetFormFields } from "../../models/dataSets/IDataSetFormField";
import { useModalContext } from "../../contexts/ModalContext";
import { useProjectContext } from "../../contexts/ProjectContext";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { IDataSetModel } from "../../models/dataSets/IDataSetModel";
import { dataSetPaths } from "../../PathConstants";
import { NotificationService } from "../../services/NotificationService";
import { CreateDataSetForm } from "../../components/dataSets/DataSetForm";
import { ICloneDataSetModel } from "../../models/dataSets/ICloneDataSetModel";
import { useDataSetApi } from "./DataSetApiHook";

/**
 * The clone entity return interface.
 */
interface CloneEntityReturn {
    editCloneDataSet: (item: IDataSetModel) => void;
}

/**
 * The clone data set modal hook.
 */
export const useCloneDataSetModal = (): CloneEntityReturn => {
    const { navigate } = useNavigation();
    const { name: projectName, projectId, organizationName } = useProjectContext();
    const { clone, generateUniqueName } = useDataSetApi(projectId);
    const { showModal, closeModal } = useModalContext();
    const [cloneDataSetFormFields, setCloneDataSetFormFields] = useState<IDataSetFormFields>();
    const [dataSetId, setDataSetId] = useState<string>("");
    const [triggerEditDataSetClone, setTriggerEditDataSetClone] = useState(false);
    const [clonedDataSetId, setClonedDataSetId] = useState<string | null>(null);

    useEffect(() => {
        if (clonedDataSetId) {
            navigate(dataSetPaths.link.edit(organizationName, projectName, clonedDataSetId));
            setClonedDataSetId(null);
        }
    }, [clonedDataSetId, navigate, projectName, organizationName]);

    const onCloneDatasetClosed = useCallback((): void => {
        closeModal();
        setCloneDataSetFormFields(undefined);
        setDataSetId("");
    }, [closeModal]);

    const onCloned = useCallback(
        ({ name, dataSetId: id }: IDataSetModel): void => {
            onCloneDatasetClosed();

            NotificationService.addSuccessNotification({
                messageKey: "DataSet.Clone.Success",
                messageKeyParams: { name },
            });

            setClonedDataSetId(id);
        },
        [onCloneDatasetClosed],
    );

    const handleClone = useCallback(
        (entity: ICloneDataSetModel) => clone({ ...entity, dataSetId }),
        [clone, dataSetId],
    );

    const showCloneDataSetModal = useCallback(() => {
        showModal({
            entity: cloneDataSetFormFields,
            onCreate: handleClone,
            onCreated: onCloned,
            formPropsToFlow: {
                mode: "Clone",
            },
            modalProps: {
                onClose: onCloneDatasetClosed,
                createLabel: "Common.Clone",
                className: "clone-data-set-modal",
                titleKey: "DataSet.Clone",
                unsavedWarningBody: "DataSet.UnsavedWarningBody",
                size: "md",
                expandable: true,
            },
            contentToDisplay: CreateDataSetForm,
        });
    }, [cloneDataSetFormFields, handleClone, onCloneDatasetClosed, onCloned, showModal]);

    const editCloneDataSet = useCallback(
        (item: IDataSetModel) => {
            (async () => {
                setDataSetId(item.dataSetId);

                try {
                    const uniqueName = await generateUniqueName(item.dataSetId);

                    setCloneDataSetFormFields({
                        name: uniqueName.name,
                        description: "",
                        type: item.type,
                        includeSamples: true,
                        projectId,
                    });
                    setTriggerEditDataSetClone(true);
                } catch (error) {
                    defaultRequestErrorHandler(error);
                }
            })();
        },
        [projectId, generateUniqueName],
    );

    useEffect(() => {
        if (triggerEditDataSetClone && cloneDataSetFormFields) {
            showCloneDataSetModal();
            setTriggerEditDataSetClone(false);
        }
    }, [triggerEditDataSetClone, cloneDataSetFormFields, showCloneDataSetModal]);

    return {
        editCloneDataSet,
    };
};
