import React from "react";
import classNames from "classnames";
import { GroupBase, OptionProps, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

interface IOptionProps
    extends OptionProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    customOption?: React.FC<OptionProps>;
}

export const Option: React.FC<IOptionProps> = ({ customOption, ...props }) => {
    const { setSelector } = useTestSelector();
    const { data } = props;

    return (
        <components.Option {...props} className={classNames(props.className, data.className)}>
            {customOption ? (
                customOption(props as OptionProps)
            ) : (
                <>
                    {data.icon && <i className={classNames(data.icon, "single-value-icon")} />}
                    {!!data.componentIcon && data.componentIcon}
                    <span {...setSelector(`dropdownItem-${data.testSelectorValue ?? ""}`)}>{data.label}</span>
                </>
            )}
        </components.Option>
    );
};
