import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import "./SideMenuItem.scss";

interface ISideMenuItemProps extends PropsWithChildren {
    className?: string;
    dirty?: boolean;
    error?: boolean;
}

export const SideMenuItem: React.FC<ISideMenuItemProps> = ({ children, className, dirty, error }) => {
    return (
        <div className={classNames("side-menu-item", className, { dirty, error })}>
            {children}
            <div className="status-circle" />
        </div>
    );
};
