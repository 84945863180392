export type ServiceHookEventType =
    | "DataSetCreated"
    | "DataSetUpdated"
    | "DataSetDeleted"
    | "ContentFileCreated"
    | "ContentFileUpdated"
    | "ContentFileDeleted"
    | "ContentFolderCreated"
    | "ContentFolderUpdated"
    | "ContentFolderDeleted"
    | "ContentFileShareCreated"
    | "ContentFileShareUpdated"
    | "ContentFileShareDeleted"
    | "ContentFileShareAccessed";

export enum ServiceHookEventTypeTranslationMap {
    "DataSetCreated" = "ServiceHook.EventType.DataSetCreated",
    "DataSetUpdated" = "ServiceHook.EventType.DataSetUpdated",
    "DataSetDeleted" = "ServiceHook.EventType.DataSetDeleted",
    "ContentFileCreated" = "ServiceHook.EventType.ContentFileCreated",
    "ContentFileUpdated" = "ServiceHook.EventType.ContentFileUpdated",
    "ContentFileDeleted" = "ServiceHook.EventType.ContentFileDeleted",
    "ContentFolderCreated" = "ServiceHook.EventType.ContentFolderCreated",
    "ContentFolderUpdated" = "ServiceHook.EventType.ContentFolderUpdated",
    "ContentFolderDeleted" = "ServiceHook.EventType.ContentFolderDeleted",
    "ContentFileShareCreated" = "ServiceHook.EventType.ContentFileShareCreated",
    "ContentFileShareUpdated" = "ServiceHook.EventType.ContentFileShareUpdated",
    "ContentFileShareDeleted" = "ServiceHook.EventType.ContentFileShareDeleted",
    "ContentFileShareAccessed" = "ServiceHook.EventType.ContentFileShareAccessed",
}
