import React from "react";
import { InputWithCopy } from "./InputWithCopy";

interface IInputWithCopyProps {
    value: string;
    label?: string;
    displayedValueWhileCopying: string;
    testSelectorValue?: string;
}

export const ReadOnlyInputWithCopy = ({ value, label, displayedValueWhileCopying, testSelectorValue }: IInputWithCopyProps) => {
    return (
        <InputWithCopy
            name="input-with-copy"
            value={value}
            label={label}
            displayedValueWhileCopying={displayedValueWhileCopying}
            testSelectorValue={testSelectorValue}
            disabled
        />
    );
};
