import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { useBuildSearchProjectProperties } from "../helpers/SearchHelper";
import { HeaderNavBarContext } from "../contexts/HeaderNavBarContext";
import { IRoute } from "../models/IRoute";
import { dataSetPaths, languagePaths, paths, projectPaths } from "../PathConstants";
import { ProjectContext } from "../contexts/ProjectContext";
import { ProjectLoading } from "../components/projects/ProjectLoading";
import { SidebarLayout } from "./SidebarLayout";
import { SidebarNavItemIcon } from "../components/sidebar/SidebarNavItemIcon";
import { SidebarProjectItem } from "../components/projects/SidebarProjectItem";
import { useNavigation } from "../hooks/NavigationHook";
import { useProjectApi } from "../hooks/project/ProjectApiHook";
import { IProjectModel } from "../models/project/IProjectModel";
import { useSearchApi } from "../hooks/search/SearchApiHook";
import { useAppConfigContext } from "../contexts/AppConfigContext";
import { useProjectDownloadManager } from "../hooks/project/ProjectDownloadManager";

/**
 * The project layout params interface.
 */
export interface IProjectLayoutParams {
    projectName: string;
    organizationName: string;
}

/**
 * Builds sidebar routes
 * @returns [sidebar Routes, footer sidebar Routes]
 */
const buildProjectRoutes = (
    projectId: string,
    organizationName: string,
    projectName: string,
    projectDisplayName: string,
    preview: boolean,
): [IRoute[], IRoute[]] => {
    const headerRoute: (IRoute | null)[] = [
        {
            name: projectDisplayName,
            children: (
                <SidebarProjectItem
                    projectId={projectId}
                    projectName={projectDisplayName}
                    organizationName={organizationName}
                />
            ),
        },
        {
            path: projectPaths.link.dataSets(organizationName, projectName),
            children: <SidebarNavItemIcon icon="far fa-database" name="Route.DataSets" />,
            testSelectorValue: "dataSetsItem",
        },
        !preview
            ? {
                  path: projectPaths.link.templates(organizationName, projectName),
                  children: <SidebarNavItemIcon icon="far fa-file-invoice" name="Route.Templates" />,
                  testSelectorValue: "templatesItem",
              }
            : null,
        {
            path: projectPaths.link.contentManager(organizationName, projectName),
            children: <SidebarNavItemIcon icon="far fa-cabinet-filing" name="Route.ContentManager" />,
            testSelectorValue: "contentManagerItem",
        },
    ];

    return [
        headerRoute.filter((hr): hr is IRoute => !!hr),
        [
            {
                path: projectPaths.link.settings(organizationName, projectName),
                children: <SidebarNavItemIcon icon="far fa-cog" name="Route.Settings.Project" />,
                testSelectorValue: "projectSettingsItem",
            },
        ],
    ];
};

/**
 * The project layout component.
 */
export const ProjectLayout: React.FC = () => {
    const { replace } = useNavigation();
    const { organizationName, projectName } = useParams<{ organizationName: string; projectName: string }>() as Record<
        string,
        string
    >;
    const [projectModel, setProjectModel] = useState<IProjectModel>();
    const { searchBy } = useSearchApi();
    const { getByOrganizationName } = useProjectApi();

    const getProjectModel = useCallback(async () => {
        try {
            const project = await getByOrganizationName(organizationName, projectName);
            setProjectModel(project);
        } catch (error) {
            replace(paths.home);
        }
    }, [getByOrganizationName, projectName, replace, organizationName]);

    useEffect(() => {
        getProjectModel();
    }, [getProjectModel]);

    const appConfig = useAppConfigContext();

    const datasetMatch = useMatch(`${dataSetPaths.route.base}/*`);
    const settingsMatch = useMatch(`${projectPaths.route.settings}/*`);
    const languageMatch = useMatch(`${languagePaths.route.edit}/*`);
    const contentMatch = useMatch(`${projectPaths.route.contentManager}/*`);

    useEffect(() => {
        if (appConfig.preview && ((!datasetMatch && !settingsMatch && !contentMatch) || languageMatch)) {
            replace(dataSetPaths.link.base(organizationName, projectName));
        }
    }, [
        appConfig.preview,
        datasetMatch,
        projectName,
        replace,
        settingsMatch,
        organizationName,
        languageMatch,
        contentMatch,
    ]);

    const { projectId, projectDisplayName } = useMemo(() => {
        return {
            projectId: projectModel?.projectId ?? "",
            projectDisplayName: projectModel?.name ?? "",
        };
    }, [projectModel]);

    const projectContextValue = useMemo(() => {
        if (!projectModel) {
            return null;
        }
        return { ...projectModel, organizationName: organizationName };
    }, [projectModel, organizationName]);

    const onSearch = useMemo(() => (searchValue: string) => searchBy(searchValue, projectId), [projectId, searchBy]);

    const searchPropertiesObject = useMemo(() => {
        return { onSearch, organizationName, projectName };
    }, [onSearch, organizationName, projectName]);

    const searchProperties = useBuildSearchProjectProperties(searchPropertiesObject);

    const headerNavBarContextValue = useMemo(() => {
        return { search: searchProperties };
    }, [searchProperties]);

    const [routes, footerRoutes] = buildProjectRoutes(
        projectId,
        organizationName,
        projectName,
        projectDisplayName,
        appConfig.preview,
    );

    useProjectDownloadManager(projectId);

    if (!projectModel) return <ProjectLoading />;

    return (
        <ProjectContext.Provider value={projectContextValue}>
            <HeaderNavBarContext.Provider value={headerNavBarContextValue}>
                <SidebarLayout routes={routes} footerRoutes={footerRoutes}>
                    <Outlet />
                </SidebarLayout>
            </HeaderNavBarContext.Provider>
        </ProjectContext.Provider>
    );
};
