import { useCallback } from "react";
import { FoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

interface TreeItemHookProps {
    getSubFolders: (item: ITreeItem) => Promise<FoldersTree>;
    handleCollapse: (id: string, state: boolean) => void;
    setItemLoadingState: (id: string, state: boolean) => void;
}
export const useTreeItem = ({ getSubFolders, handleCollapse, setItemLoadingState }: TreeItemHookProps) => {
    const onCollapse = useCallback(
        async (item: ITreeItem) => {
            if (item.isOpen) {
                handleCollapse(item.id, false);
                return;
            }

            handleCollapse(item.id, true);

            // If we have already loaded the children, we dont need to fetch them again
            if (!item.areChildrenLoaded) {
                setItemLoadingState(item.id, true);

                try {
                    await getSubFolders(item);
                } catch (error) {
                    defaultRequestErrorHandler(error);
                } finally {
                    setItemLoadingState(item.id, false);
                }
            }
        },
        [getSubFolders, handleCollapse, setItemLoadingState],
    );

    return {
        onCollapse,
    };
};
