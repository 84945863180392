import { useCallback } from "react";
import { useAppConfigContext } from "../contexts/AppConfigContext";

/**
 * Interface for test selectors
 */
interface TestSelector {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "data-testid"?: string;
}

/**
 * Interface for the "props" generated by a test selector
 */
interface TestSelectorProps {
    setSelector: (selectorValue?: string) => TestSelector | undefined;
}

/**
 * The use test selector hook.
 */
export const useTestSelector = (): TestSelectorProps => {
    const { enableGenerateTestSelectors } = useAppConfigContext();

    const setSelector = useCallback(
        (selectorValue?: string) =>
            enableGenerateTestSelectors && selectorValue ? { "data-testid": selectorValue } : {},
        [enableGenerateTestSelectors],
    );

    return {
        setSelector,
    };
};
