import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

interface IDependencyTableNameLinkProps {
    restricted: boolean;
    editLink: string;
    entityName: string;
}

export const DependencyTableNameLink: React.FC<IDependencyTableNameLinkProps> = ({
    restricted,
    editLink,
    entityName,
}: IDependencyTableNameLinkProps) => {
    const { setSelector } = useTestSelector();
    const { t } = useTranslation();
    if (restricted) {
        return <span className="text-truncate">{t("Common.RestrictedAccess")}</span>;
    }

    if (editLink) {
        return (
            <Link className="text-truncate" to={editLink} {...setSelector("cell-link")}>
                {entityName}
            </Link>
        );
    }

    return <span className="text-truncate">{entityName}</span>;
};
