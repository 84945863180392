import React, { useCallback, useMemo } from "react";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { Link } from "react-router-dom";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { ITableDeleteHelper, Table } from "../table/Table";
import { LanguageTableNameColumn } from "./LanguageTableNameColumn";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { ITableContextModel } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { NotificationService } from "../../services/NotificationService";
import { useLanguageHookApi } from "../../hooks/languages/LanguageApiHook";
import { LanguageForm } from "./LanguageForm";
import { useModalContext } from "../../contexts/ModalContext";
import { Button } from "../buttons/Button";
import { useTranslation } from "react-i18next";
import { ILanguageModel } from "../../models/Languages/ILanguageModel";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { useDefaultTableFilterHelper } from "../../hooks/table/DefaultTableFilterHelper";
import { languagePaths } from "../../PathConstants";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";
import { AlertPopover } from "./AlertPopover";
import { TableDependencyDeleteDialog } from "../dialogs/TableDependencyDeleteDialog";
import { EntityType } from "../../models/EntityType";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";

interface ILanguageViewProps {
    projectId: string;
    projectName: string;
    organizationName: string;
}

export const buildLanguageTableColumnDefs = (
    organizationName: string,
    projectName: string,
): IEntityTableColumnDef[] => [
    {
        type: "JSX",
        content: ({ name, isDefault, languageId }: ILanguageModel) => (
            <LanguageTableNameColumn
                name={name}
                defaultLanguage={isDefault}
                organizationName={organizationName}
                projectName={projectName}
                languageId={languageId}
            />
        ),
        fieldName: "name",
        displayName: "Common.Name",
        className: "name",
        sortField: {
            name: "Name",
            order: SortOrder.Asc,
        },
    },
    {
        type: "Text",
        fieldName: "code",
        displayName: "Languages.Code",
        className: "code",
        sortField: {
            name: "Code",
            order: SortOrder.Asc,
        },
    },
];

export const LanguageView: React.FC<ILanguageViewProps> = ({ projectId, projectName, organizationName }) => {
    const { removeRecords, create, getRecords, getMultipleDependencies, setAsDefault } = useLanguageHookApi(projectId);
    const { showModal, closeModal } = useModalContext();
    const { t } = useTranslation();
    const { refreshTable } = useTableRefreshContext();

    const languageWriteKey = usePermissionKey({
        permission: PermissionKeys.language.write,
        projectId,
    });
    const languageDeleteKey = usePermissionKey({
        permission: PermissionKeys.language.delete,
        projectId,
    });

    const languageReadKey = usePermissionKey({
        permission: PermissionKeys.language.read,
        projectId,
    });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [languageWriteKey, languageDeleteKey, languageReadKey] }),
        [languageDeleteKey, languageReadKey, languageWriteKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const handleSetAsDefault = useCallback(
        (language: ILanguageModel) => {
            setAsDefault(language)
                .then(() => {
                    refreshTable("languages");
                })
                .catch((err) => {
                    NotificationService.addErrorNotification({ message: extractErrorMessageOrEmptyString(err) });
                });
        },
        [refreshTable, setAsDefault],
    );

    const columnDefs: IEntityTableColumnDef[] = useMemo(
        () => buildLanguageTableColumnDefs(organizationName, projectName),
        [organizationName, projectName],
    );

    const actionProvider = useCallback(
        (
            item: ILanguageModel,
            context: ITableContextModel<ILanguageModel, ITableRowItem<ILanguageModel>, string>,
        ): ITableRowActionProps[] => {
            const languageDeletePermission = formatPermissionKey(
                PermissionKeys.language.delete,
                item.languageId,
                projectId,
            );
            const languageWritePermission = formatPermissionKey(
                PermissionKeys.language.write,
                item.languageId,
                projectId,
            );

            return [
                {
                    iconClassName: "fal fa-eye",
                    label: "Common.View",
                    tag: Link,
                    testSelectorValue: "editItem",
                    to: languagePaths.link.edit(organizationName, projectName, item.languageId),
                    permissions: [languageWritePermission],
                    transform: (actionProps, { isAllowed: isAllowedInternal }) => {
                        const hasWritePermissionProps = {
                            label: "Common.Edit",
                            iconClassName: "fal fa-pencil",
                        };

                        if (!item.isDefault && isAllowedInternal(languageWritePermission)) {
                            return {
                                ...actionProps,
                                ...hasWritePermissionProps,
                            };
                        }

                        return actionProps;
                    },
                },
                {
                    iconClassName: "fal fa-flag-swallowtail",
                    label: "Common.SetAsDefault",
                    onClick: () => {
                        handleSetAsDefault(item);
                    },
                    disabled: item.isDefault,
                    permissions: [languageWritePermission],
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.languageId]),
                    separated: true,
                    style: !item.isDefault ? { color: colorStyles.red } : undefined,
                    permissions: [languageDeletePermission],
                    disabled: item.isDefault,
                },
            ];
        },
        [handleSetAsDefault, organizationName, projectId, projectName],
    );

    const onCreated = useCallback((): void => {
        NotificationService.addSuccessNotification({
            messageKey: "LanguageView.CreationSuccess",
        });
        refreshTable("languages");
        closeModal();
    }, [closeModal, refreshTable]);

    const showAddLanguageForm = useCallback(() => {
        showModal({
            contentToDisplay: LanguageForm,
            entity: { languageCodes: [] },
            modalProps: {
                className: "add-language-modal",
                titleKey: "LanguageForm.Title",
                unsavedWarningBody: "LanguageForm.UnsavedWarningBody",
                size: "md",
                expandable: false,
                onClose: closeModal,
                createLabel: "Common.Add",
            },
            onCreate: create,
            onCreated: onCreated,
            formPropsToFlow: {
                projectId,
            },
        });
    }, [closeModal, create, onCreated, projectId, showModal]);

    const addLanguageAction = useCallback(() => {
        if (!isAllowed(languageWriteKey)) {
            return;
        }

        return (
            <Button icon="fal fa-plus" color="primary" onClick={showAddLanguageForm} ariaLabel={t("Common.Add")}>
                {t("Common.Add")}
            </Button>
        );
    }, [isAllowed, languageWriteKey, showAddLanguageForm, t]);

    const filterHelper = useDefaultTableFilterHelper("LanguageView.FilterPlaceHolder");

    const deleteHelperDeleteOverride = useCallback(({ selectedItems }: { selectedItems: ILanguageModel[] }) => {
        const defaultLanguage = selectedItems.find((l) => l.isDefault);
        if (defaultLanguage) {
            return (
                <AlertPopover
                    messageKey="Language.DefaultDeleteWarning"
                    messageParams={{ name: defaultLanguage.name }}
                    alertId={defaultLanguage.languageId}
                />
            );
        }

        return null;
    }, []);

    const deleteHelper: ITableDeleteHelper<ILanguageModel> | undefined = useMemo(() => {
        if (!isAllowed(languageDeleteKey)) {
            return;
        }

        return {
            confirmationTitleMessageKey: "CommonLanguage.DeleteTitle",
            confirmationBodyMessageKey: "CommonLanguage.DeleteBody",
            deleteRecords: removeRecords,
            customDeleteComponent: TableDependencyDeleteDialog,
            getDependencies: getMultipleDependencies,
            notificationMessageKey: "LanguageView",
            deleteOverride: deleteHelperDeleteOverride,
            entityType: "Language" as EntityType,
        };
    }, [isAllowed, languageDeleteKey, removeRecords, getMultipleDependencies, deleteHelperDeleteOverride]);

    return (
        <Table
            className="language-table"
            columnDefs={columnDefs}
            getRecords={getRecords}
            keyExtractor={(item) => item.languageId}
            loadingMessageKey="Loading.Languages"
            tableId="languages"
            selectable={true}
            actionProvider={actionProvider}
            deleteHelper={deleteHelper}
            filterHelper={filterHelper}
            extraActions={[{ showWithSelectedItem: false, button: addLanguageAction, id: "add-language-action" }]}
            counterPosition={isAllowed(languageWriteKey) ? "End" : "Start"}
        />
    );
};
