import React from "react";
import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";
import {
    InvalidCharacterRegex,
    InvalidCharacterValidationError,
    InvalidPackageName,
    InvalidPackageNameValidationError,
} from "../helpers/InvalidCharacterHelper";

/**
 * The editable api key fields.
 */
export interface IEditableExportName {
    name: string;
}

/**
 * The api key form validator class.
 */
export class DownloadPackageNameValidator extends FormValidator<IEditableExportName> {
    public static readonly apiKeyNameMaxLength: number = 450;

    protected schema = Yup.object().shape({
        name: Yup.string()
            .required(() => <ValidatorError messageKey="Export.FileNameRequired" />)
            .matches(InvalidCharacterRegex, InvalidCharacterValidationError)
            .matches(InvalidPackageName, InvalidPackageNameValidationError)
            .strict(true),
    });

    public validateName(name: string): Promise<string> {
        return this.schema.fields.name.validate(name);
    }
}
