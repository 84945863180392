import { useCallback } from "react";
import { ISortField } from "../../models/ISortField";
import { IUserModel } from "../../models/user/IUserModel";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { IEntityResult } from "../../models/IEntityResult";
import { IUserWithGroupsAndInvitationStatusModel } from "../../models/user/IUserWithGroupsAndInvitationStatusModel";
import { useOrganizationApi } from "../organization/OrganizationApiHook";
import { useProjectApi } from "../project/ProjectApiHook";
import { useProjectGroupApi } from "../project/ProjectGroupApiHook";
import { useOrganizationGroupApi } from "../organization/OrganizationGroupApiHook";
import { usePermissionGroupApi } from "../permission/PermissionGroupApiHook";
import { useUserApi } from "./UserApiHook";

interface UseManageUsersProps {
    organizationId?: string;
    projectId?: string;
}

interface UseManageUsersReturn {
    getUsers: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IUserWithGroupsAndInvitationStatusModel>>;
    getAllNonMembers: (groupId: string) => Promise<IUserModel[]>;
    removeUsers: (groupIds: string[]) => Promise<IDeleteErrorResult[]>;
    getUserScope: () => string;
}

export const useManageUsers = ({ organizationId, projectId }: UseManageUsersProps): UseManageUsersReturn => {
    const { getUsers: getOrganizationUsers, removeUsers: removeOrganizationUsers } = useOrganizationApi();
    const { getUsers: getProjectUsers, removeUsers: removeProjectUsers } = useProjectApi();
    const { getNonMembers: getProjectGroupNonMembers } = useProjectGroupApi({ projectId: projectId! });
    const { getNonMembers: getOrganizationGroupNonMembers } = useOrganizationGroupApi();
    const { getNonMembers: getPermissionGroupNonMembers } = usePermissionGroupApi();
    const { getRecords } = useUserApi();

    const getUsers = useCallback(
        (
            filterValue?: string,
            sortFields?: ISortField[],
            offset?: number,
        ): Promise<IEntityResult<IUserWithGroupsAndInvitationStatusModel>> => {
            if (projectId) {
                return getProjectUsers(projectId, filterValue, sortFields, offset);
            }

            if (organizationId) {
                return getOrganizationUsers(organizationId, filterValue, sortFields, offset);
            }

            return getRecords(filterValue, sortFields, offset);
        },
        [organizationId, projectId, getProjectUsers, getOrganizationUsers, getRecords],
    );

    const getAllNonMembers = useCallback(
        (groupId: string) => {
            if (projectId) {
                return getProjectGroupNonMembers(groupId);
            }

            if (organizationId) {
                return getOrganizationGroupNonMembers(organizationId, groupId);
            }

            return getPermissionGroupNonMembers(groupId);
        },
        [
            organizationId,
            projectId,
            getProjectGroupNonMembers,
            getOrganizationGroupNonMembers,
            getPermissionGroupNonMembers,
        ],
    );

    const removeUsers = useCallback(
        (ids: string[]): Promise<IDeleteErrorResult[]> => {
            if (projectId) {
                return removeProjectUsers(ids, projectId);
            }

            if (organizationId) {
                return removeOrganizationUsers(ids, organizationId);
            }

            return Promise.resolve([]);
        },
        [projectId, organizationId, removeProjectUsers, removeOrganizationUsers],
    );

    const getUserScope = useCallback(() => {
        if (projectId) {
            return "Project";
        }

        if (organizationId) {
            return "Organization";
        }

        return "";
    }, [projectId, organizationId]);

    return {
        getUsers,
        getAllNonMembers,
        removeUsers,
        getUserScope,
    };
};
