import { useCallback } from "react";
import { BulkOperationSummaryWithPayload } from "../../models/BulkOperationModel";
import { ISubFolderModel } from "../../models/contentManager/ContentManagerApiModels";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { ITreeItem } from "../../models/contentManager/FolderTree";

export const useContentManagerTableActionCallback = (tableId: string) => {
    const { refreshTable } = useTableRefreshContext();
    const { onFolderCopied, onFolderMoved } = useContentManagerContext();
    const onCopyDone = useCallback(
        (
            summary: BulkOperationSummaryWithPayload<ISubFolderModel[]> | undefined,
            destination: ITreeItem | null | undefined,
            actionCancelled: boolean,
        ) => {
            if (actionCancelled || destination === undefined) {
                return;
            }

            const destinationId = destination ? destination.id : null;
            summary?.payload.forEach((item) => {
                onFolderCopied(destinationId, item);
            });
            refreshTable(tableId, true);
        },
        [onFolderCopied, refreshTable, tableId],
    );

    const onMoveDone = useCallback(
        (summary: BulkOperationSummaryWithPayload<string[]> | undefined, destination: ITreeItem | null | undefined) => {
            if (destination === undefined) {
                return;
            }
            const destinationId = destination ? destination.id : null;
            summary?.payload.forEach((id) => {
                onFolderMoved(id, destinationId);
            });
            refreshTable(tableId, true);
        },
        [onFolderMoved, refreshTable, tableId],
    );

    return {
        onCopyDone,
        onMoveDone,
    };
};
