import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { EditEntityWrapper } from "../edit/EditEntityWrapper";
import { useTranslation } from "react-i18next";
import { useProjectContext } from "../../contexts/ProjectContext";
import { EditLanguageViewTabs } from "./EditLanguageViewTabs";
import { ILanguageModel } from "../../models/Languages/ILanguageModel";
import { useLanguageHookApi } from "../../hooks/languages/LanguageApiHook";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { LanguageFormValidator } from "../../formValidators/LanguageFormValidator";
import { NotificationService } from "../../services/NotificationService";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { useNavigation } from "../../hooks/NavigationHook";
import { languagePaths } from "../../PathConstants";
import { EntityDependencyDeleteDialog } from "../dialogs/EntityDependencyDeleteDialog";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { ReturnSection } from "../layout/ReturnSection";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { DefaultEditDropdownMenu } from "../dropdowns/DefaultEditDropdownMenu";
import { EditEntityName } from "../edit/EditEntityName";
import { EditLanguageHeaderBadge } from "./EditLanguageHeaderBadge";

export const EditLanguageView: React.FC = () => {
    const { t } = useTranslation();
    const { languageId } = useParams() as Record<string, string>;
    const { projectId, organizationName, name: projectName } = useProjectContext();
    const { replace } = useNavigation();

    const { get, remove, getMultipleDependencies, update } = useLanguageHookApi(projectId);

    const languageWriteKey = usePermissionKey({ permission: PermissionKeys.language.write, projectId });
    const languageDeleteKey = usePermissionKey({ permission: PermissionKeys.language.delete, projectId });
    const languageReadKey = usePermissionKey({ permission: PermissionKeys.language.read, projectId });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [languageWriteKey, languageDeleteKey, languageReadKey] }),
        [languageWriteKey, languageDeleteKey, languageReadKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const getDependencies = useCallback(async (): Promise<MultipleDependenciesResult> => {
        try {
            return await getMultipleDependencies([languageId], "usedBy");
        } catch (error) {
            defaultRequestErrorHandler(error);
            return {} as MultipleDependenciesResult;
        }
    }, [languageId, getMultipleDependencies]);

    const load = useCallback(() => {
        return get(languageId);
    }, [get, languageId]);

    const updateLanguage = useCallback(
        async (language: ILanguageModel) => {
            const model = await update(languageId, language);
            NotificationService.addSuccessNotification({
                messageKey: "LanguageView.SaveSuccess",
                messageKeyParams: { name: language.name },
            });
            return Promise.resolve(model);
        },
        [languageId, update],
    );

    const deleteHandler = useCallback(
        (languageName: string) => {
            return remove(languageId)
                .then(() => {
                    NotificationService.addSuccessNotification({
                        messageKey: "LanguageView.DeleteSuccessSingle",
                        messageKeyParams: { name: languageName },
                    });
                    replace(languagePaths.link.base(organizationName, projectName));
                })
                .catch((e) => {
                    defaultRequestErrorHandler(e);
                });
        },
        [languageId, organizationName, projectName, remove, replace],
    );

    const formValidator = useCallback(() => new LanguageFormValidator(), []);

    const canEdit = isAllowed(languageWriteKey);
    const canDelete = isAllowed(languageDeleteKey);

    return (
        <>
            <ReturnSection
                label={t("CommonLanguage.Return")}
                route={languagePaths.link.base(organizationName, projectName)}
            />
            <EditEntityWrapper
                className="edit-language-view"
                delete={deleteHandler}
                nameIsUniqueProps={{
                    nameExistErrorKey: "",
                    nameIsUnique: () => Promise.resolve(true),
                }}
                deleteDialogProps={{
                    dialogTitleKey: "CommonLanguage.DeleteTitle",
                    dialogBodyKey: "CommonLanguage.DeleteBody",
                    entityCount: 1,
                }}
                promptDialogMessageKey="Language.UnsavedWarningBody"
                load={load}
                loadingScreenKey="Loading.Language"
                update={updateLanguage}
                canEdit={canEdit}
                canDelete={canDelete}
                formValidatorProvider={formValidator}
                entityType="Language"
                customDeleteComponent={EntityDependencyDeleteDialog}
                getDependencies={getDependencies}
                entityId={languageId}
                header={
                    <SectionHeader>
                        <SectionHeader.Group flexGrow>
                            <SectionHeader.Group column>
                                <SectionHeader.OverTitle>{t("Common.Language")}</SectionHeader.OverTitle>
                                <SectionHeader.Group>
                                    <EditEntityName staticDisplay />
                                    <EditLanguageHeaderBadge />
                                </SectionHeader.Group>
                            </SectionHeader.Group>
                        </SectionHeader.Group>
                        <SectionHeader.ActionGroup
                            withSaveButton={canEdit}
                            dropdownMenu={<DefaultEditDropdownMenu canDelete={canDelete} />}
                        />
                    </SectionHeader>
                }
            >
                <EditLanguageViewTabs />
            </EditEntityWrapper>
        </>
    );
};
