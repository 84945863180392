import React from "react";
import { Input, InputProps } from "reactstrap";
import { useTranslation } from "react-i18next";

interface ILocalizedInputProps extends InputProps {}

/**
 * The translated input functional component.
 */
export const LocalizedInput: React.FunctionComponent<ILocalizedInputProps> = (
    props: ILocalizedInputProps,
): JSX.Element => {
    const { placeholder, ...inputProps } = props;
    const {
        t,
    } = useTranslation();

    return <Input placeholder={placeholder ? t(placeholder) : placeholder} {...inputProps} />;
};
