import React from "react";
import { MultiValueGenericProps, GroupBase, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

interface IMultiValueContainerProps
    extends MultiValueGenericProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    data: SelectComponentOptions<any>;
}

export const MultiValueContainer: React.FC<IMultiValueContainerProps> = (props) => {
    const { setSelector } = useTestSelector();
    const { data } = props;

    return (
        <div {...setSelector(`dropdownSelectedItem-${data.testSelectorValue ?? ""}`)}>
            <components.MultiValueContainer {...props}></components.MultiValueContainer>
        </div>
    );
};
