import { CreateOrganizationForm } from "../../components/organizations/CreateOrganizationForm";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { useDefaultTableCreateHelper } from "../table/DefaultTableCreateHelper";
import { useOrganizationApi } from "./OrganizationApiHook";

interface OrganizationCreationProps {
    onCreated: (props: IOrganizationModel) => void;
}

export const useOrganizationCreation = ({ onCreated }: OrganizationCreationProps) => {
    const { create } = useOrganizationApi();

    return useDefaultTableCreateHelper({
        formComponent: CreateOrganizationForm,
        onCreate: create,
        onCreated,
        initialEntity: { name: "", description: "" },
        formProps: {},
        modalProps: {
            className: "create-organization-modal",
            titleKey: "CreateOrganizationModal.Title",
            titleIconClassName: "fas fa-key text-orange",
            unsavedWarningBody: "Organization.UnsavedWarningBody",
            size: "md",
            expandable: true,
        },
    });
};
