import { useCallback } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { ITableContextModel } from "../../components/table/TableContext";
import { ITableRowItem } from "../../components/table/TableRow";
import { useContentManagerSelectAction } from "./ContentManagerSelectAction";
import { useContentPropertiesSidePanel } from "./ContentPropertiesSidePanelHook";
import { ITableRowActionProps } from "../../components/tableRowActions/TableRowAction";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";
import { useContentManagerRenameModal } from "./ContentManagerRenameModalHook";
import { foldersTreeAtom, loadingModalStateAtom, selectedFolderIdAtom } from "../../atoms/ContentManager";
import { DiscoveryModel, WopiAction } from "../../models/wopi/IWopiModel";
import { microsoftExtensionIconTranslationMap } from "../../helpers/MicrosoftBrandHelper";
import { contentManagerPaths } from "../../PathConstants";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useNavigation } from "../NavigationHook";
import { useDownloadItem } from "./DownloadItemHook";
import { useTranslateFile } from "./TranslateFileHook";
import { useOpenItem } from "./OpenItemHook";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";
import { ITreeItem } from "../../models/contentManager/FolderTree";
import { contentManagerSearchParamPath } from "./ContentManagerTreeHook";
import { useCurrentFolderName } from "./CurrentFolderNameHook";
import { useContentManagerTableActionCallback } from "./ContentManagerTableActionCallbackHook";
import { useContentFileShare } from "./contentFileShare/ContentFileShareHook";

export interface IContentManagerProviderHookProps {
    projectId: string;
    selectedItems: IContentEntityModel[];
    tableId: string;
    modifyEntity?: (item: IContentEntityModel) => IContentEntityModel;
}

interface IContentManagerProviderHookResult {
    actionProvider: (
        item: IContentEntityModel,
        context: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>,
    ) => ITableRowActionProps[];
}

const modifyActionProps = (
    actionProps: ITableRowActionProps,
    item: DiscoveryModel,
    action: WopiAction,
): ITableRowActionProps => {
    actionProps.label = WopiAction.Edit === action ? "Microsoft.Open" : "Microsoft.View";
    actionProps.labelTranslationParams = { applicationName: item.appName };
    actionProps.iconClassName = microsoftExtensionIconTranslationMap[item.appName];
    return actionProps;
};

export const useContentManagerTableActionProvider = ({
    projectId,
    selectedItems,
    tableId,
    modifyEntity,
}: IContentManagerProviderHookProps): IContentManagerProviderHookResult => {
    const { navigate } = useNavigation();
    const { name: projectName, organizationName } = useProjectContext();
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const foldersTree = useAtomValue(foldersTreeAtom);
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const { renameForlderOrFile } = useContentManagerRenameModal({
        parentFolderId: selectedFolderId ?? "",
        tableId,
    });
    const { showSidePanel } = useContentPropertiesSidePanel({ selectedItems });
    const originFolderName = useCurrentFolderName(contentManagerSearchParamPath);
    const { onAction } = useContentManagerSelectAction({
        originFolderName,
        originFolderId: selectedFolderId,
        initialFoldersTree: foldersTree,
        checkParentExistsOnError: true,
    });
    const { handleSelectedPath, onLoadError } = useContentManagerContext();

    const { downloadFolder, downloadFile } = useDownloadItem();
    const { translate, canTranslateItem } = useTranslateFile();
    const { openFile, getWopiFileInfo } = useOpenItem();

    const { onCopyDone, onMoveDone } = useContentManagerTableActionCallback(tableId);

    const { createContentFileShare } = useContentFileShare();

    const actionProvider = useCallback(
        (
            item: IContentEntityModel,
            context: ITableContextModel<IContentEntityModel, ITableRowItem<IContentEntityModel>, undefined>,
        ): ITableRowActionProps[] => {
            const itemWritePermission = formatPermissionKey(PermissionKeys.contentManager.write, item.id, projectId);
            const itemDeletePermission = formatPermissionKey(PermissionKeys.contentManager.delete, item.id, projectId);
            const itemReadPermission = formatPermissionKey(PermissionKeys.contentManager.read, item.id, projectId);
            const itemSharePermission = formatPermissionKey(PermissionKeys.contentManager.share, item.id, projectId);
            let actionLevel: WopiAction;

            item = modifyEntity ? modifyEntity(item) : item;

            return [
                {
                    iconClassName: "fal fa-arrow-up-right-from-square",
                    label: "Common.Open",
                    onClick: () => {
                        if (item.type === "Folder") {
                            handleSelectedPath({ path: item.path } as ITreeItem);
                        } else {
                            openFile(item.id, item.name, undefined, actionLevel).catch((e) => onLoadError(e));
                        }
                    },
                    testSelectorValue: "openItem",
                    permissions: [itemReadPermission],
                    transform: (actionProps, { isAllowed }) => {
                        const wopiFileInfo = getWopiFileInfo(
                            item,
                            isAllowed(itemWritePermission),
                            isAllowed(itemReadPermission),
                        );

                        if (!wopiFileInfo) {
                            return actionProps;
                        }

                        const { action, microsoftProductItem } = wopiFileInfo;
                        actionLevel = action;
                        return modifyActionProps(actionProps, microsoftProductItem, action);
                    },
                },
                {
                    iconClassName: "fal fa-arrow-right",
                    label: "Common.Move",
                    testSelectorValue: "moveItem",
                    separated: true,
                    onClick: () => {
                        onAction("Move", [item], onMoveDone);
                    },
                    permissions: [itemWritePermission],
                },
                {
                    iconClassName: "fal fa-files",
                    label: "Common.Copy",
                    testSelectorValue: "copyItem",
                    onClick: () => {
                        onAction("Copy", [item], onCopyDone);
                    },
                    permissions: [itemWritePermission],
                },
                {
                    iconClassName: "fal fa-arrow-down-to-line",
                    label: "Common.Download",
                    testSelectorValue: "downloadItem",
                    onClick: () => {
                        if (item.type === "Folder") {
                            setLoadingModalState({
                                type: "OPEN_MODAL",
                                payload: {
                                    label: "Common.Download.Loading",
                                },
                            });
                            downloadFolder(item.id, `${item.name}.zip`).finally(() => {
                                setLoadingModalState({ type: "CLOSE_MODAL" });
                            });
                        } else {
                            downloadFile(item.id).catch((e) => onLoadError(e));
                        }
                    },
                    permissions: [itemReadPermission],
                },
                ...(canTranslateItem(item)
                    ? [
                          {
                              iconClassName: "fal fa-language",
                              label: "Common.Tab.Translate",
                              testSelectorValue: "translateItem",
                              onClick: () => translate(item),
                          },
                      ]
                    : []),
                {
                    iconClassName: "fal fa-pen-to-square",
                    label: "Common.Rename",
                    testSelectorValue: "renameItem",
                    onClick: () => renameForlderOrFile(item),
                    permissions: [itemWritePermission],
                },
                ...(item.type === "File"
                    ? [
                          {
                              iconClassName: "far fa-share-nodes",
                              label: "Common.Share",
                              testSelectorValue: "shareItem",
                              onClick: () => createContentFileShare(item.id),
                              permissions: [itemSharePermission],
                          },
                      ]
                    : []),
                {
                    iconClassName: "fal fa-square-poll-horizontal",
                    label: "Common.Details",
                    testSelectorValue: "detailsItem",
                    onClick: () =>
                        navigate(contentManagerPaths.link.edit(organizationName, projectName, item.id, item.type)),
                    permissions: [itemReadPermission],
                    separated: true,
                },
                {
                    iconClassName: "fal fa-window-maximize",
                    label: "Common.Properties",
                    testSelectorValue: "propertiesItem",
                    onClick: () => {
                        context.onSelect(item.id, true);
                        showSidePanel((id: string) => context.onSelect(id, true));
                    },
                    separated: true,
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    style: { color: colorStyles.red },
                    onClick: () => {
                        context.onSetItemsForDeletion([item.id]);
                    },
                    separated: true,
                    permissions: [itemDeletePermission],
                },
            ];
        },
        [
            projectId,
            modifyEntity,
            canTranslateItem,
            handleSelectedPath,
            openFile,
            onLoadError,
            getWopiFileInfo,
            onAction,
            onMoveDone,
            onCopyDone,
            setLoadingModalState,
            downloadFolder,
            downloadFile,
            translate,
            renameForlderOrFile,
            createContentFileShare,
            navigate,
            organizationName,
            projectName,
            showSidePanel,
        ],
    );

    return {
        actionProvider,
    };
};
