/**
 * The microsoft application name type.
 */
export type MicrosoftApplicationName = keyof typeof microsoftExtensionIconTranslationMap;

/**
 * The microsoft extension icon translation map helper.
 */
export const microsoftExtensionIconTranslationMap = {
    Excel: "ms-BrandIcon--icon16 ms-BrandIcon--excel",
    OneNote: "ms-BrandIcon--icon16 ms-BrandIcon--onenote",
    PowerPoint: "ms-BrandIcon--icon16 ms-BrandIcon--powerpoint",
    Visio: "ms-BrandIcon--icon16 ms-BrandIcon--visio",
    Word: "ms-BrandIcon--icon16 ms-BrandIcon--word",
} as const;
