import React from "react";
import { UserAvatar } from "../userGroups/UserAvatar";
import { FormattedDate } from "./FormattedDate";
import { IUserDisplayModel } from "../../models/account/IUserDisplayModel";

import "./TimestampInfo.scss";

interface ITimestampInfoProps {
    date: string;
    user: IUserDisplayModel;
}

export const TimestampInfo: React.FC<ITimestampInfoProps> = ({ date, user }) => {
    return (
        <div className="timestamp-info">
            <div className="timestamp-info__date">
                <FormattedDate date={date} format="DateTime" />
            </div>
            <div className="timestamp-info__user">
                <UserAvatar name={user.displayName} parentType="dropdown" initials={user.initials} />
                <span>{user.displayName}</span>
            </div>
        </div>
    );
};
