import { useCallback } from "react";
import { DownloadHttpParams, HttpParams } from "../models/HttpParams";
import {
    createHeaders,
    createUploadHeaders,
    handleFileHttpResponseBody,
    handleResponse,
    handleResponseBodyAsBlob,
    handleResponseBodyAsJson,
    handleResponseBodyAsText,
    normalizeUrl,
} from "../helpers/HttpHelper";

export const useHttpRequest = () => {
    const requestAsync = useCallback(
        async <TReturn, TParams = never>(
            params: HttpParams<TParams>,
            method: string,
            handleResponseBody: (responsePromise: Promise<Response>) => Promise<TReturn>,
            customHeaders?: Headers,
            requesBodyAsFormData: boolean = false,
        ): Promise<TReturn> => {
            let body: string | FormData | undefined;

            if (requesBodyAsFormData) {
                body = params.body as FormData;
            } else {
                body = params.body ? JSON.stringify(params.body) : undefined;
            }

            return await handleResponseBody(
                fetch(normalizeUrl(params.url, params.queryParams), {
                    ...params,
                    headers: customHeaders ?? (await createHeaders(params)),
                    method,
                    body: body,
                }),
            );
        },
        [],
    );

    const httpUpload = useCallback(
        async <TReturn, TParams>(params: HttpParams<TParams>): Promise<TReturn> =>
            requestAsync<TReturn, TParams>(
                params,
                "POST",
                handleResponseBodyAsJson,
                await createUploadHeaders(params),
                true,
            ),
        [requestAsync],
    );

    const httpDownload = useCallback(
        <TParams = never>(params: DownloadHttpParams<TParams>): Promise<void> =>
            requestAsync<void, TParams>(params, params.body ? "POST" : "GET", (rp) =>
                handleFileHttpResponseBody(rp, params.fileName),
            ),
        [requestAsync],
    );

    const httpGet = useCallback(
        <TReturn>(
            params: HttpParams<never>,
            handleResponseBody: (responsePromise: Promise<Response>) => Promise<TReturn>,
        ): Promise<TReturn> => requestAsync<TReturn>(params, "GET", handleResponseBody),
        [requestAsync],
    );

    const httpGetJson = useCallback(
        <TReturn>(params: HttpParams<never>): Promise<TReturn> => httpGet<TReturn>(params, handleResponseBodyAsJson),
        [httpGet],
    );

    const httpGetText = useCallback(
        (params: HttpParams<never>): Promise<string> => httpGet(params, handleResponseBodyAsText),
        [httpGet],
    );

    const httpGetBlob = useCallback(
        (params: HttpParams<never>): Promise<Blob> => httpGet(params, handleResponseBodyAsBlob),
        [httpGet],
    );

    const httpPost = useCallback(
        <TReturn = void, TParams = never>(params: HttpParams<TParams>): Promise<TReturn> =>
            requestAsync<TReturn, TParams>(params, "POST", handleResponseBodyAsJson),
        [requestAsync],
    );

    const httpPostText = useCallback(
        <TParams = never>(params: HttpParams<TParams>): Promise<string> =>
            requestAsync<string, TParams>(params, "POST", handleResponseBodyAsText),
        [requestAsync],
    );

    const httpPut = useCallback(
        <TReturn, TParams>(params: HttpParams<TParams>): Promise<TReturn> =>
            requestAsync<TReturn, TParams>(params, "PUT", handleResponseBodyAsJson),
        [requestAsync],
    );

    const httpDelete = useCallback(
        (params: HttpParams<never>): Promise<void> => requestAsync(params, "DELETE", handleResponse),
        [requestAsync],
    );

    const httpDeleteResponseJson = useCallback(
        <TReturn, TParams>(params: HttpParams<TParams>): Promise<TReturn> =>
            requestAsync<TReturn, TParams>(params, "DELETE", handleResponseBodyAsJson),
        [requestAsync],
    );

    return {
        httpDownload,
        httpGetJson,
        httpGetText,
        httpGetBlob,
        httpPost,
        httpPostText,
        httpPut,
        httpDelete,
        httpDeleteResponseJson,
        httpUpload,
    };
};
