import { BulkActionSummaryCancelResult } from "../models/BulkOperationModel";
import { IContentSummarizeModel } from "../models/contentManager/ContentManagerApiModels";
import { extractErrorMessageOrEmptyString } from "./ErrorHelper";

/**
 * Extract the extension from the file name.
 */
export const getFileExtension = (name: string) => {
    return name.split(".").pop();
};

export const createFailedFileUploadResult = (e: unknown): BulkActionSummaryCancelResult<IContentSummarizeModel> => {
    const errorMessage = extractErrorMessageOrEmptyString(e);
    return {
        errorCount: 1,
        errorMessages: errorMessage !== "" ? [errorMessage] : [],
        payload: {
            affectedFolders: [],
            filesUploadContentLength: 0,
        },
        successCount: 0,
        cancelled: false,
    };
};

export const splitFileNameFromExtension = (fileName: string): [string, string?] => {
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex === -1) {
        return [fileName];
    }
    return [fileName.substring(0, dotIndex), fileName.substring(dotIndex)];
};
