import React from "react";
import { EditView } from "../views/EditView";
import { useFullscreenContext } from "../../contexts/FullscreenContext";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";

import "./AccountView.scss";

/**
 * The account view props interface.
 */
interface IAccountViewProps extends React.PropsWithChildren {
    title: string;
    saveButton?: JSX.Element;
    className?: string;
}

/**
 * The account view component.
 */
export const AccountView: React.FC<IAccountViewProps> = ({ title, children, saveButton, className }) => {
    const { fullscreen } = useFullscreenContext();

    return (
        <EditView fullscreen={fullscreen}>
            <SectionHeader>
                <SectionHeader.Group flexGrow>
                    <SectionHeader.Title>{title}</SectionHeader.Title>
                </SectionHeader.Group>
                <SectionHeader.ActionGroup>{saveButton}</SectionHeader.ActionGroup>
            </SectionHeader>
            <div className={`manage-content ${className ?? ""}`}>{children}</div>
        </EditView>
    );
};
