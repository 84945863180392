import { IDeleteErrorResult } from "../models/IDeleteErrorResult";
import { NotificationService } from "../services/NotificationService";

const getWarningMessageKey = (
    itemsDeleted: string[],
    deleteErrorResult: IDeleteErrorResult[],
    notificationMessageKey: string,
) => {
    if (itemsDeleted.length === 1) {
        if (deleteErrorResult.length === 1) {
            return `${notificationMessageKey}.DeletePartialOneSuccessOneFail`;
        } else {
            return `${notificationMessageKey}.DeletePartialOneSuccessMultipleFail`;
        }
    } else if (deleteErrorResult.length === 1) {
        return `${notificationMessageKey}.DeletePartialMultipleSuccessOneFail`;
    } else {
        return `${notificationMessageKey}.DeletePartialMultipleSuccessMultipleFail`;
    }
};

export const handleDeletedItemsNotification = (
    itemsDeleted: string[],
    deleteErrorResult: IDeleteErrorResult[],
    itemName?: string,
    notificationMessageKey: string = "",
    notificationMessageKeyParams?: Record<string, string>,
): void => {
    if (deleteErrorResult.length === 0) {
        NotificationService.addSuccessNotification(
            itemsDeleted.length === 1
                ? {
                      messageKey: `${notificationMessageKey}.DeleteSuccessSingle`,
                      messageKeyParams: { name: itemName, ...notificationMessageKeyParams },
                  }
                : {
                      messageKey: `${notificationMessageKey}.DeleteSuccessMultiple`,
                      messageKeyParams: notificationMessageKeyParams,
                  },
        );
    } else {
        if (itemsDeleted.length === 0) {
            NotificationService.addErrorNotification({
                messageKey: `${notificationMessageKey}.DeleteFail`,
                messageKeyParams: notificationMessageKeyParams,
            });
        } else {
            NotificationService.addWarningNotification({
                messageKey: getWarningMessageKey(itemsDeleted, deleteErrorResult, notificationMessageKey),
                messageKeyParams: {
                    successCount: itemsDeleted.length,
                    totalCount: itemsDeleted.length + deleteErrorResult.length,
                    ...notificationMessageKeyParams,
                },
            });
        }
    }
};
