import React from "react";
import { FluentIcon } from "./FluentIcon";
import { getFileExtension } from "../../helpers/ContentFileHelper";
import { FileTypeIconSize } from "@fluentui/react-file-type-icons";

interface IFluentExtensionIconProps {
    name: string;
    size?: FileTypeIconSize;
}

export const FluentExtensionIcon: React.FC<IFluentExtensionIconProps> = ({ name, size }: IFluentExtensionIconProps) => {
    return <FluentIcon extension={getFileExtension(name)} size={size} />;
};
