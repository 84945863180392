import * as Yup from "yup";
import { DataSetFormValidator } from "./DataSetFormValidator";
import { DataSetType } from "../models/dataSets/DataSetType";
import { IDefaultEntityProps } from "../models/IDefaultEntityProps";

/**
 * The create data set form validation fields interface.
 */
export interface ICreateDataSetFormValidationFields extends Omit<IDefaultEntityProps, "name"> {
    type: string;
}

/*
 * The create data set form validator class.
 */
export class CreateDataSetFormValidator extends DataSetFormValidator {
    protected currentTimeout: number = 0;

    protected createDataSetFormSchema = this.schema.concat(
        Yup.object().shape({
            type: Yup.mixed<DataSetType>().required("Required").oneOf(["System.Form", "System.Json", "System.Xml"]),
        }),
    );

    public validateType(type: DataSetType): Promise<DataSetType> {
        return this.createDataSetFormSchema.fields.type.validate(type);
    }
}
