import React from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
    searchValueAtom,
    searchScopeAtom,
    applySearchAtom,
    selectedFolderIdAtom,
    foldersTreeAtom,
    SearchQueryParamsName,
} from "../../atoms/ContentManager";
import { InputWithScope } from "../forms/inputs/InputWithScope";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";

import "./ContentManagerSearchBar.scss";

export const ContentManagerSearchBar = () => {
    const { t } = useTranslation();
    const scope = useAtomValue(searchScopeAtom);
    const [searchValue, setSearchValue] = useAtom(searchValueAtom);
    const tree = useAtomValue(foldersTreeAtom);
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const setSearchQuery = useSetAtom(applySearchAtom);
    const { handleSelectedPath } = useContentManagerContext();
    const [searchParams] = useSearchParams();
    const searchLabel = t("ContentManager.Search.Placeholder");
    const inSearchView = searchParams.has(SearchQueryParamsName);

    const onCancel = () => {
        setSearchValue("");
        if (inSearchView) {
            // We only want to change path onCancel inside the search.
            handleSelectedPath(selectedFolderId ? tree.find((i) => i.id === selectedFolderId)! : null);
        }
    };

    return (
        <div className="content-manager-search-bar">
            <InputWithScope
                label={searchLabel}
                placeholder={searchLabel}
                onValueChange={setSearchValue}
                scope={t(scope ? "ContentManager.CurrentFolder" : "ContentManager.AllFiles")}
                value={searchValue}
                testSelectorValue="contentManagerSearchBar"
                onConfirm={() => searchValue && setSearchQuery(searchValue)}
                canDisabled={!inSearchView}
                onCancel={() => onCancel()}
            />
        </div>
    );
};
