const createKey = (userId: string, key: string) => `${userId}::${key}`;

export const getItem = <T>(userId: string, key: string): T | undefined => {
    const value = localStorage.getItem(createKey(userId, key));
    if (!value) {
        return undefined;
    }

    try {
        return JSON.parse(value) as T;
    } catch {
        return undefined;
    }
};

export const setItem = <T>(userId: string, key: string, value: T): void =>
    localStorage.setItem(createKey(userId, key), JSON.stringify(value));

export const clear = (userId: string): void => {
    const toDelete: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key?.startsWith(userId)) {
            toDelete.push(key);
        }
    }
    toDelete.forEach((k) => localStorage.removeItem(k));
};
