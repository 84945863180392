import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAtomValue, useSetAtom } from "jotai";
import { foldersTreeAtom, loadingModalStateAtom, selectedFolderIdAtom } from "../../atoms/ContentManager";
import { NotificationService } from "../../services/NotificationService";
import { extractErrorMessageOrDefault } from "../../helpers/ErrorHelper";
import {
    IContentEntityModel,
    ContentEntityIdType,
    ContentEntityType,
} from "../../models/contentManager/ContentManagerApiModels";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";
import { useAppConfigContext } from "../../contexts/AppConfigContext";
import { handleBulkOperationNotification } from "../../helpers/BulkActionHelper";
import { useContentEntityApi } from "./ContentEntityApiHook";
import { BulkOperationSummary } from "../../models/BulkOperationModel";

interface IUseContentManagerDropHandlerProps {
    handleUpload: (files: File[], targetFolderId?: string) => Promise<void>;
    tableId: string;
}

const itemIsFile = (item: FileSystemEntry): item is FileSystemFileEntry => item.isFile;
const itemIsDirectory = (item: FileSystemEntry): item is FileSystemDirectoryEntry => item.isDirectory;

const readEntries = (
    reader: FileSystemDirectoryReader,
    res: (value: File[] | PromiseLike<File[]>) => void,
    getFiles: (item: FileSystemEntry) => Promise<File[]>,
    files: File[],
) => {
    reader.readEntries((entries) => {
        if (entries.length === 0) {
            return res(files);
        }

        const filesPromises: Promise<File[]>[] = [];
        for (const entry of entries) {
            filesPromises.push(getFiles(entry));
        }

        void Promise.all(filesPromises).then((newFiles) =>
            readEntries(reader, res, getFiles, files.concat(newFiles.flat())),
        );
    });
};

export const useContentManagerDropHandler = ({ handleUpload, tableId }: IUseContentManagerDropHandlerProps) => {
    const { projectId } = useProjectContext();
    const { refreshTable } = useTableRefreshContext();
    const { onFolderMoved } = useContentManagerContext();
    const { maxFolderDepth } = useAppConfigContext();
    const { move } = useContentEntityApi({
        projectId,
        checkParentExistsOnError: true,
    });
    const { t } = useTranslation();
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const foldersTree = useAtomValue(foldersTreeAtom);
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);

    const onErrorEmptyFolderUpload = useCallback(() => {
        NotificationService.addErrorNotification({
            messageKey: "Upload.Error.Empty",
        });
    }, []);

    const handleMoveNotification = useCallback(
        (result: BulkOperationSummary, destinationFolderId: string | null, singleItemName: string) => {
            const destinationFolder = foldersTree.find((treeItem) => treeItem.id === destinationFolderId);
            const notificationParams: Record<string, string> = {
                successCount: result.successCount.toString(),
                destinationFolder: destinationFolder?.name ?? t("ContentManager.Title"),
                itemName: singleItemName,
                maxFolderDepth: maxFolderDepth.toString(),
                originFolder:
                    foldersTree.find((treeItem) => treeItem.id === selectedFolderId)?.name ?? t("ContentManager.Title"),
            };

            handleBulkOperationNotification(result, "ContentManager.Move", notificationParams);
        },
        [foldersTree, maxFolderDepth, selectedFolderId, t],
    );

    const onMoveDone = useCallback(
        (movedFoldersId: string[], destinationFolderId: string) => {
            movedFoldersId.forEach((id) => {
                onFolderMoved(id, destinationFolderId);
            });
            refreshTable(tableId);
        },
        [onFolderMoved, refreshTable, tableId],
    );

    const getFiles = useCallback((item: FileSystemEntry): Promise<File[]> => {
        return new Promise<File[]>((res) => {
            if (itemIsFile(item)) {
                item.file((file) => {
                    Object.defineProperty(file, "webkitRelativePath", {
                        value: item.fullPath.slice(1),
                    });

                    res([file]);
                });
            }

            if (itemIsDirectory(item)) {
                readEntries(item.createReader(), res, getFiles, []);
            }
        });
    }, []);

    const handleFileDrop = useCallback(
        async (dropValue: DataTransfer, targetFolderId?: string) => {
            const filesPromises: Promise<File[]>[] = [];
            for (let i = 0; i < dropValue.items.length; i++) {
                const entry = dropValue.items[i].webkitGetAsEntry();
                entry && filesPromises.push(getFiles(entry));
            }

            const fileArray = (await Promise.all(filesPromises)).flat();

            if (fileArray.length === 0) {
                onErrorEmptyFolderUpload();
                return;
            }

            handleUpload(fileArray, targetFolderId);
        },
        [getFiles, handleUpload, onErrorEmptyFolderUpload],
    );

    const handleMoveDrop = useCallback(
        async (items: IContentEntityModel[], destinationId: string) => {
            const entitiesIdType: ContentEntityIdType[] = items.map((item) => ({
                id: item.id,
                type: item.type === "Folder" ? ContentEntityType.Folder : ContentEntityType.File,
            }));
            setLoadingModalState({
                type: "OPEN_MODAL",
                payload: { label: "Move.Loading" },
            });
            try {
                const moveResult = await move({
                    destinationId,
                    targetedEntities: entitiesIdType,
                });
                onMoveDone(moveResult.payload, destinationId);
                handleMoveNotification(moveResult, destinationId, items[0].name);
            } catch (error) {
                NotificationService.addErrorNotification({
                    messageKey: extractErrorMessageOrDefault(error, "ContentManager.Move.Error"),
                });
            } finally {
                setLoadingModalState({ type: "CLOSE_MODAL" });
            }
        },
        [handleMoveNotification, move, onMoveDone, setLoadingModalState],
    );

    return {
        handleFileDrop,
        handleMoveDrop,
    };
};
