import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectComponent } from "./SelectComponent";
import { SortHelper } from "../../helpers/SortHelper";
import { SupportedLanguages } from "../dataSets/translation/SupportedLanguages";

/**
 * The select language props interface.
 */
interface ISelectLanguageProps {
    autoFocus?: boolean;
    addFirstOption?: { label: string; value: string };
    languages: string[];
    selectedValue?: string;
    size?: "large";
    testSelectorValue?: string;
    onChange: (selectedLanguage: string) => void;
    labelHidden?: boolean;
    labelKey?: string;
    labelRequired?: boolean;
    id: string;
}

/**
 * The select language component.
 */
export const SelectLanguage: React.FC<ISelectLanguageProps> = (props: ISelectLanguageProps): JSX.Element => {
    const {
        autoFocus,
        addFirstOption,
        languages,
        labelHidden = false,
        labelKey,
        selectedValue,
        size,
        testSelectorValue,
        onChange,
        labelRequired = false,
        id,
    } = props;
    const { t } = useTranslation();
    const listedLanguages = useMemo(() => {
        const sortedLanguages = languages
            .map((language) => {
                const languageLabel = t(SupportedLanguages[language]);

                return {
                    value: language,
                    label: languageLabel,
                    testSelectorValue: languageLabel,
                };
            })
            .sort((objA, objB) => SortHelper.compare(objA.label, objB.label));

        return addFirstOption
            ? [{ label: t(addFirstOption.label), value: addFirstOption.value }].concat(sortedLanguages)
            : sortedLanguages;
    }, [addFirstOption, languages, t]);

    return (
        <>
            <LocalizedLabel
                for={id}
                text={labelKey ?? "Language.SelectLanguage"}
                hidden={labelHidden}
                required={labelRequired}
                aria-required={labelRequired ? "true" : "false"}
            />
            <SelectComponent
                id={id}
                autoFocus={autoFocus}
                options={listedLanguages}
                value={selectedValue}
                size={size}
                onChange={onChange}
                testSelectorValue={testSelectorValue}
            />
        </>
    );
};
