import { useCallback, useEffect, useState } from "react";
import authService, { IClaims } from "../../components/api-authorization/AuthorizeService";

/**
 *The authorization result interface.
 */
interface IAuthorizationReturn {
    authorized: boolean;
    ready: boolean;
    userId?: string;
    locale?: string;
    reloadClaims: () => void;
}

/**
 * The authorization hook.
 */
export const useAuthorization = (): IAuthorizationReturn => {
    const [ready, setReady] = useState(false);
    const [userId, setUserId] = useState<string>();
    const [locale, setLocale] = useState<string>();
    const [authorized, setAuthorized] = useState(false);

    const getAuthenticationState = useCallback(() => {
        void authService.getClaimsFromServer().then((claims: IClaims | null) => {
            setUserId(claims?.sub);
            setLocale(claims?.locale || navigator.language || "en-CA");
            setAuthorized(!!claims);
            setReady(true);
        });
    }, []);

    const authenticationChanged = useCallback(() => {
        setReady(false);
        getAuthenticationState();
    }, [getAuthenticationState]);

    useEffect(() => {
        const subscription = authService.subscribe(() => authenticationChanged());
        getAuthenticationState();
        return () => {
            authService.unsubscribe(subscription);
        };
    }, [authenticationChanged, getAuthenticationState]);

    return {
        authorized,
        locale,
        ready,
        userId,
        reloadClaims: getAuthenticationState,
    };
};
