import React, { useCallback, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "../../contexts/DialogContext";
import { EditEntityContext, EntityWithName, IEditEntityContextProps } from "../../contexts/EditEntityContext";
import { useShortcutContext } from "../../contexts/ShortcutContext";
import { FormValidator } from "../../formValidators/FormValidator";
import { canSaveEntity } from "../../helpers/EntityHelper";
import { useEditEntity } from "../../hooks/EditEntityHook";
import { INameIsUniqueHookProps } from "../../hooks/NameIsUnique";
import { useNavigationPromptDialog } from "../../hooks/navigation/NavigationPromptDialogHook";
import { EntityForm } from "../forms/EntityForm";
import { LoadingScreen } from "../loading/LoadingScreen";
import { EditView } from "../views/EditView";
import { EditViewBody } from "../views/EditViewBody";
import { useEditEntityName } from "../../hooks/EditEntityNameHook";
import { useFullscreenContext } from "../../contexts/FullscreenContext";
import { EntityType } from "../../models/EntityType";
import { VersionComment } from "../../models/versioning/VersioningModels";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { useRefreshEditEntity } from "../../hooks/RefreshEditEntityHook";

import "./EditEntityView.scss";

export interface IBadgeProps<TEntity> {
    badgeTitleKey: string;
    badgeColor: string;
    showBadge: (entity: TEntity) => boolean;
}

/**
 * The edit entity view props interface.
 */
type IEditEntityWrapperProps<TEntity extends EntityWithName, TValidator extends FormValidator<unknown>> = Pick<
    IEditEntityContextProps<TEntity, TValidator>,
    "deleteDialogProps"
> & {
    className?: string;
    loadedEntity?: TEntity;
    nameIsUniqueProps: INameIsUniqueHookProps;
    promptDialogMessageKey: string;
    load: IEditEntityContextProps<TEntity, TValidator>["entityProps"]["load"];
    update: (entity: TEntity, versionComment: VersionComment) => Promise<TEntity>;
    delete: IEditEntityContextProps<TEntity, TValidator>["entityProps"]["delete"];
    formValidatorProvider: () => TValidator;
    loadingScreenKey: string;
    propertiesComparator?: {
        [alias in keyof TEntity]?: (a: any, b: any) => boolean;
    };
    onSaveCallback?: (entity: TEntity) => void;
    entityType?: EntityType;
    entityId?: string;

    children: React.ReactNode;
    header: React.ReactNode;
    canEdit: boolean;
    canDelete: boolean;
    getDependencies?: () => Promise<MultipleDependenciesResult>;
    customDeleteComponent?: React.ComponentType<any>;
    disableNameContext?: boolean;
};

/**
 * The edit entity view.
 */
export const EditEntityWrapper = <TEntity extends EntityWithName, TValidator extends FormValidator<unknown>>({
    children,
    className,
    loadedEntity,
    deleteDialogProps,
    nameIsUniqueProps,
    delete: deleteEntity,
    promptDialogMessageKey,
    load,
    update,
    formValidatorProvider,
    loadingScreenKey,
    propertiesComparator,
    onSaveCallback,
    entityType,
    canEdit,
    canDelete,
    getDependencies,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    customDeleteComponent: CustomDeleteComponent,
    entityId,
    disableNameContext,
    header,
}: IEditEntityWrapperProps<TEntity, TValidator>): JSX.Element => {
    const { toggleFullscreen, fullscreen } = useFullscreenContext();
    const { dialogState } = useDialogContext();
    const { globalShortcutEnabled } = useShortcutContext();
    const [customDeleteOpen, setCustomDeleteOpen] = useState<boolean>(false);

    const toggleCustomDelete = useCallback(() => {
        setCustomDeleteOpen((prevCustomDeleteOpen) => !prevCustomDeleteOpen);
    }, []);

    const {
        setInitialEntityProperties,
        entity,
        errors,
        initialEntity,
        entityValid,
        isDirty,
        setEntityProperties,
        setErrors,
        onSave,
        isSaving,
        formValidator,
        resetErrors,
        blockSave,
        toggleBlockSave,
    } = useEditEntity<TEntity, TValidator>({
        loadedEntity,
        fetchEntity: load,
        updateEntity: update,
        formValidatorProvider,
        propertiesComparator,
        onSaveCallback,
        entityType,
        disableNameContext,
    });

    const { checkNameIsUnique, isValidatingName } = useEditEntityName({
        nameIsUniqueProps,
        setErrors,
        value: initialEntity?.name,
    });

    useHotkeys(
        "ctrl+s, command+s",
        (e) => {
            e.preventDefault();

            if (
                canSaveEntity({
                    entityValid,
                    isDirty,
                    isSaving,
                    isValidatingName,
                    nameErrorMessage: errors.name,
                    blockSave,
                    canEdit,
                })
            ) {
                void onSave();
            }
        },
        {
            filter: () => globalShortcutEnabled && !dialogState.open,
            enableOnTags: ["INPUT", "TEXTAREA", "SELECT"],
        },
        [onSave, dialogState.open],
    );

    useHotkeys(
        "escape",
        () => {
            toggleFullscreen();
        },
        {
            filter: () => globalShortcutEnabled && fullscreen && !dialogState.open,
        },
        [toggleFullscreen, dialogState.open],
    );

    const { t } = useTranslation();

    useNavigationPromptDialog({
        messageKey: promptDialogMessageKey,
        isDirty: isDirty(),
        componentId: "EditEntityWrapper",
    });

    const { subscribeRefresh, dispatchRefresh, unsubscribeRefresh } = useRefreshEditEntity();

    const initialContextValue: IEditEntityContextProps<TEntity, TValidator> | undefined = useMemo(() => {
        return !entity || !initialEntity
            ? undefined
            : {
                  entityProps: {
                      setInitialEntityProperties,
                      entity,
                      initialEntity,
                      setEntityProperties,
                      entityValid,
                      formValidator,
                      load,
                      update: onSave,
                      delete: deleteEntity,
                      getDependencies,
                      entityId,
                  },
                  errorProps: {
                      errors,
                      setErrors,
                      resetErrors,
                  },
                  deleteDialogProps: {
                      ...deleteDialogProps,
                      toggleCustomDelete,
                      custom: !!CustomDeleteComponent,
                  },
                  validateNameProps: {
                      checkNameIsUnique,
                      isValidatingName,
                  },
                  dirtyProps: {
                      isDirty,
                      promptDialogMessageKey,
                  },
                  savingProps: {
                      isSaving,
                      blockSave,
                      toggleBlockSave,
                  },
                  permissionsProps: {
                      canEdit: !!canEdit,
                      canDelete: !!canDelete,
                  },
                  refreshProps: {
                      subscribeRefresh,
                      dispatchRefresh,
                      unsubscribeRefresh,
                  },
              };
    }, [
        entity,
        initialEntity,
        setInitialEntityProperties,
        setEntityProperties,
        entityValid,
        formValidator,
        load,
        onSave,
        deleteEntity,
        getDependencies,
        entityId,
        errors,
        setErrors,
        resetErrors,
        deleteDialogProps,
        toggleCustomDelete,
        CustomDeleteComponent,
        checkNameIsUnique,
        isValidatingName,
        isDirty,
        promptDialogMessageKey,
        isSaving,
        blockSave,
        toggleBlockSave,
        canEdit,
        canDelete,
        subscribeRefresh,
        dispatchRefresh,
        unsubscribeRefresh,
    ]);

    if (!entity || !initialEntity) {
        return <LoadingScreen>{t(loadingScreenKey)}</LoadingScreen>;
    }

    return (
        <EditEntityContext.Provider value={initialContextValue}>
            <EditView fullscreen={fullscreen}>
                {header}
                <EntityForm className={`edit-view-form ${className ?? ""}`}>
                    <EditViewBody>{children}</EditViewBody>
                    {(CustomDeleteComponent && (
                        <CustomDeleteComponent show={customDeleteOpen} entityType={entityType} />
                    )) ||
                        null}
                </EntityForm>
            </EditView>
        </EditEntityContext.Provider>
    );
};
