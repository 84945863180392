export const UserGroupTypeMapping = {
    applicationAdmin: "Application administrators",
    organizationAdmin: "Organization administrators",
    projectOwner: "Owners",
    projectAdmin: "Administrators",
    projectContributor: "Contributors",
    projectViewer: "Viewers",
    contentContributor: "Content contributors",
} as const;

export const PermissionGroupTypeMapping = {
    [UserGroupTypeMapping.applicationAdmin]: "applicationAdmin",
    [UserGroupTypeMapping.organizationAdmin]: "organizationAdmin",
    [UserGroupTypeMapping.projectOwner]: "projectOwner",
    [UserGroupTypeMapping.projectAdmin]: "projectAdmin",
    [UserGroupTypeMapping.projectContributor]: "projectContributor",
    [UserGroupTypeMapping.projectViewer]: "projectViewer",
    [UserGroupTypeMapping.contentContributor]: "contentContributor",
} as const;

export type UserGroupTypes = keyof typeof UserGroupTypeMapping;

export type PermissionGroupTypes = keyof typeof PermissionGroupTypeMapping;
