import React from "react";
import { useTranslation } from "react-i18next";
import { SectionHeader } from "./sectionHeader/SectionHeader";

import "./TableViewHeader.scss";

interface TableViewHeaderProps extends React.PropsWithChildren {
    titleKey: string;
}

export const TableViewHeader: React.FC<TableViewHeaderProps> = ({ titleKey, children }) => {
    const { t } = useTranslation();

    return (
        <SectionHeader>
            <SectionHeader.Group flexGrow>
                <SectionHeader.Title>{t(titleKey)}</SectionHeader.Title>
            </SectionHeader.Group>
            {children && <SectionHeader.Group>{children}</SectionHeader.Group>}
            <SectionHeader.ActionGroup />
        </SectionHeader>
    );
};
