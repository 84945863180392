import React from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { IRecentProjectModel } from "../../models/project/IRecentProjectModel";
import { ProjectAvatar } from "./ProjectAvatar";
import { projectPaths } from "../../PathConstants";

import "./SidebarProjectRecentItem.scss";

/**
 * The sidebar project recent item props interface.
 */
interface ISidebarProjectRecentItemProps {
    recentProject: IRecentProjectModel;
}

/**
 * The sidebar project recent item component.
 */
export const SidebarProjectRecentItem: React.FunctionComponent<ISidebarProjectRecentItemProps> = (
    props: ISidebarProjectRecentItemProps,
) => {
    const {
        recentProject: { organizationName, name },
    } = props;

    return (
        <DropdownItem
            tag={Link}
            className="sidebar-project-recent-item"
            to={projectPaths.link.dashboard(organizationName, name)}
            role="menuitem"
        >
            <div className="item-avatar">
                <ProjectAvatar name={name} size="30" />
            </div>
            <div className="sidebar-item-info text-truncate">
                <div className="project-name text-truncate">{name}</div>
                <div className="organization-name text-truncate">{organizationName}</div>
            </div>
        </DropdownItem>
    );
};
