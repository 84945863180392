import React from "react";
import classNames from "classnames";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { ICrumbItemProps } from "./CrumbItem";

/**
 * The breadcrumb dropdown item component.
 */

export const BreadcrumbDropdownItem: React.FunctionComponent<ICrumbItemProps> = (
    props: ICrumbItemProps,
): JSX.Element => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const {
        crumb: { name, path, escapeTranslation },
        isCurrentBreadcrumb = false,
    } = props;

    const labelValue = escapeTranslation ? name : t(name);

    return (
        <DropdownItem
            tag={Link}
            to={path}
            role="menuitem"
            className={classNames("text-truncate", { isActiveBreadcrumb: isCurrentBreadcrumb })}
            aria-current={isCurrentBreadcrumb ? "page" : "false"}
            disabled={isCurrentBreadcrumb}
            {...setSelector(`breadCrumbItem-${props.testSelectorValueIndex ?? ""}`)}
        >
            {labelValue}
        </DropdownItem>
    );
};
