import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { TemplateFormValidator } from "../../formValidators/TemplateFormValidator";
import { buildDependencies, buildHistory, buildLanguages, buildOverview } from "../../helpers/EntityHelper";
import { useTabs } from "../../hooks/TabsHook";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { HistoryTable } from "../table/HistoryTable";
import { TabContent } from "../tabs/TabContent";
import { EditTemplateViewTabsDetails } from "./tabs/EditTemplateViewTabsDetails";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { NavTabs } from "../tabs/NavTabs";
import { INavTabProps } from "../tabs/NavTab";
import { DependenciesView } from "../dependencies/DependenciesView";
import { ISortField } from "../../models/ISortField";
import { SubTabToggleAction } from "../dependencies/DependencyTableView";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useTemplateApi } from "../../hooks/template/TemplateApiHook";
import { IEntityResult } from "../../models/IEntityResult";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { TemplateLanguageView } from "./TemplateLanguageView";

import "./EditTemplateViewTabs.scss";

/**
 * the list of tabs in the edit template view
 */
const editTemplateViewTabs = ["overview", "dependencies", "languages", "history"] as const;

/**
 * the list of tabs in the edit teamplate view as Type
 */
type EditTemplateTabType = (typeof editTemplateViewTabs)[number];

/**
 * The edit template View tabs
 */
export const EditTemplateViewTabs: React.FC = () => {
    const { projectId } = useProjectContext();
    const [activeTab, setActiveTab] = useTabs<EditTemplateTabType>(editTemplateViewTabs, "overview");
    const {
        entityProps: { entity: editableTemplate },
        dirtyProps: { isDirty },
        errorProps: { errors },
    } = useEditEntityContext<ITemplateModel, TemplateFormValidator>();
    const { t } = useTranslation();
    const { getHistoryRecords, getDependenciesRecords } = useTemplateApi(projectId);
    const tabs: INavMenuItemProps[] = [
        buildOverview(!!errors.description, isDirty(["description", "dataSetIds"]), setActiveTab),
        buildLanguages(false, false, setActiveTab),
        buildDependencies(false, false, setActiveTab),
        buildHistory(false, false, setActiveTab),
    ];

    const [activeDependencyTab, setActiveDependencyTab] = useState<DependencyType>("usedBy");

    const getRecords = useCallback(
        (updatedEntityDate?: string, offset?: number) =>
            getHistoryRecords(editableTemplate.templateId, updatedEntityDate, offset),
        [editableTemplate.templateId, getHistoryRecords],
    );

    const getDependenciesRecordsHandler: (
        dependencyType: DependencyType,
        offset?: number,
        sortField?: ISortField[],
        filterValue?: string,
    ) => Promise<IEntityResult<IDependencyModel>> = useCallback(
        (dependencyType: DependencyType, offset?: number, sortField?: ISortField[], filterValue?: string) =>
            getDependenciesRecords(editableTemplate.templateId, dependencyType, offset, sortField, filterValue),
        [editableTemplate.templateId, getDependenciesRecords],
    );

    const tabsDependencies: INavTabProps[] = useMemo(() => {
        return [
            {
                displayName: "Common.Tab.UsedBy",
                name: "usedBy",
                invalid: false,
                dirty: false,
                testSelectorValue: "usedByTab",
                onToggle: setActiveDependencyTab as SubTabToggleAction,
                tabcontrol: "usedBy",
                id: "usedBy-id",
            },
            {
                displayName: "Common.Tab.DependsOn",
                name: "dependsOn",
                invalid: false,
                dirty: false,
                testSelectorValue: "dependsOnTab",
                onToggle: setActiveDependencyTab as SubTabToggleAction,
                tabcontrol: "dependsOn",
                id: "dependsOn-id",
            },
        ];
    }, []);

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <EditTemplateViewTabsDetails />
            </TabContent>
            <TabContent tabId="languages" className="languages" title={t("Common.Language_plural")}>
                <TemplateLanguageView />
            </TabContent>
            <TabContent tabId="dependencies" className="dependencies" title={t("Common.Dependencies")} hasNavTabs>
                <NavTabs navTabs={tabsDependencies} activeTab={activeDependencyTab}>
                    <DependenciesView
                        getDependenciesRecordsHandler={getDependenciesRecordsHandler}
                        activeTab={activeDependencyTab}
                        parentEntityType="template"
                    />
                </NavTabs>
            </TabContent>
            <TabContent tabId="history" className="history" title={t("Common.History")}>
                <HistoryTable
                    getRecords={getRecords}
                    updatedEntityDate={editableTemplate.updatedDate}
                    entityType="Template"
                />
            </TabContent>
        </NavMenu>
    );
};
