/**
 * The entities type in a Search Result
 */
export enum SearchEntityType {
    Organization = 0,
    Project = 1,
    DataSet = 2,
    DataSetSample = 3,
    Template = 4,
    File = 5,
    Folder = 6,
    Invalid = 99,
}
