import React from "react";
import { Input, InputGroup, InputGroupText, InputProps, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { Button } from "../../buttons/Button";

import "./InputWithScope.scss";

export interface IInputWithScopeProps extends Omit<InputProps, "value" | "onChange"> {
    label: string;
    placeholder: string;
    onValueChange: (value: string) => void;
    scope: string;
    value: string;
    testSelectorValue?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    canDisabled?: boolean;
}

export const InputWithScope: React.FunctionComponent<IInputWithScopeProps> = ({
    label,
    placeholder,
    scope,
    value,
    testSelectorValue,
    onValueChange,
    onConfirm,
    onCancel = () => onValueChange(""),
    canDisabled = true,
    ...props
}: IInputWithScopeProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onConfirm && e.key === "Enter") {
            onConfirm();
        }
    };

    return (
        <InputGroup className="input-with-scope-group" { ...setSelector(testSelectorValue) }>
            <InputGroupText>{scope}</InputGroupText>
            <>
                <Label htmlFor="input-with-scope" hidden>
                    {label}
                </Label>
                <Input
                    id="input-with-scope"
                    placeholder={placeholder}
                    value={value}
                    { ...setSelector("searchTextbox") }
                    onChange={(e) => onValueChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    {...props}
                />
                <Button
                    ariaLabel={"Common.Clear"}
                    onClick={() => onCancel()}
                    color="link"
                    tabIndex={-1}
                    description={t("Common.Clear")}
                    disabled={canDisabled && !value}
                    { ...setSelector("clearButton") }
                >
                    <i className="clear-icon far fa-xmark-large" />
                </Button>
            </>
        </InputGroup>
    );
};
