import React, { useMemo } from "react";
import classnames from "classnames";
import { NavItem, NavLink, NavLinkProps } from "reactstrap";
import { NavLink as NavLinkRRD } from "react-router-dom";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The Sidebar NavItem props interface.
 */
interface ISidebarNavItemProps {
    active?: boolean;
    external?: boolean;
    path?: string;
    onClick: (event: React.MouseEvent<any, MouseEvent>) => void;
    children?: React.ReactNode;
    testSelectorValue?: string;
}

/**
 * The Sidebar NavItem component.
 */
export const SidebarNavItem: React.FunctionComponent<ISidebarNavItemProps> = (props: ISidebarNavItemProps) => {
    const { active, external, path, onClick, children, testSelectorValue } = props;
    const { setSelector } = useTestSelector();
    const navLinkProps = useMemo<NavLinkProps>(() => {
        if (external) {
            return {
                href: path,
            };
        }

        return {
            to: path,
            tag: NavLinkRRD,
        };
    }, [path, external]);

    return (
        <NavItem className={classnames({ active })}>
            {path ? (
                <NavLink {...navLinkProps} onClick={onClick} {...setSelector(testSelectorValue)}>
                    {children}
                </NavLink>
            ) : (
                children
            )}
        </NavItem>
    );
};
