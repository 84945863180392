import React, { useCallback } from "react";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import { EditEntityWrapper } from "../edit/EditEntityWrapper";
import { EditPermissionsGroupViewTab } from "./EditPermissionsGroupViewTabs";
import { useEditPermissionsGroup } from "../../hooks/permission/EditPermissionsGroupHook";
import { LoadingScreen } from "../loading/LoadingScreen";
import { PermissionGroupFormValidator } from "../../formValidators/PermissionGroupFormValidator";
import { ReturnSection } from "../layout/ReturnSection";
import { PermissionsGroupTitle } from "./PermissionsGroupTitle";
import { IEditPermissionGroupModel } from "../../models/permissions/IEditPermissionGroupModel";
import { NotificationService } from "../../services/NotificationService";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { DefaultEditDropdownMenu } from "../dropdowns/DefaultEditDropdownMenu";

export const EditPermissionsGroupView: React.FC = () => {
    const {
        group,
        getGroup,
        projectId,
        organizationId,
        linkToGroupList,
        nameIsUnique,
        saveGroup: updateGroup,
        canEdit,
        removeGroup,
    } = useEditPermissionsGroup();
    const { t } = useTranslation();
    const groupId = group?.groupId;
    const deleteHandler = useCallback(() => {
        if (!groupId || !projectId) {
            NotificationService.addErrorNotification({
                messageKey: "PermissionsGroupView.DeleteFail",
            });
            return Promise.resolve();
        }

        return removeGroup();
    }, [groupId, projectId, removeGroup]);

    if (!group) {
        return <LoadingScreen>{t("Loading.Group")}</LoadingScreen>;
    }

    return (
        <>
            <ReturnSection label={t("PermissionsGroup.ReturnToGroups")} route={linkToGroupList} />
            <EditEntityWrapper
                className="edit-group-view"
                canEdit={canEdit}
                canDelete={canEdit}
                delete={deleteHandler}
                loadedEntity={group}
                load={getGroup}
                entityType="Permissions.Group"
                deleteDialogProps={{
                    dialogBodyKey: "CommonGroup.DeleteBody",
                    dialogTitleKey: "CommonGroup.DeleteTitle",
                    entityCount: 1,
                }}
                formValidatorProvider={() => new PermissionGroupFormValidator<IEditPermissionGroupModel>()}
                loadingScreenKey="Loading.Group"
                nameIsUniqueProps={{
                    nameExistErrorKey: "PermissionGroupFormValidator.GroupExists",
                    nameIsUnique,
                }}
                promptDialogMessageKey="PermissionGroup.UnsavedWarningBody"
                update={updateGroup}
                propertiesComparator={{ groupPermissions: isEqual }}
                header={
                    <SectionHeader>
                        <PermissionsGroupTitle permissionsGroup={group} />
                        <SectionHeader.ActionGroup
                            withSaveButton={canEdit}
                            dropdownMenu={<DefaultEditDropdownMenu canDelete={canEdit} />}
                        />
                    </SectionHeader>
                }
            >
                <EditPermissionsGroupViewTab organizationId={organizationId} projectId={projectId} />
            </EditEntityWrapper>
        </>
    );
};
