import { WopiAction } from "../models/wopi/IWopiModel";

export interface IFileDownloadRequest {
    fileId: string;
    isAttachment: boolean;
    translationLanguageCode?: string;
    action?: WopiAction;
}

export class FileDownloadService {
    private static downloadCallbacks: Record<string, [(request: IFileDownloadRequest) => void]> = {};

    public static requestFileDownload(scope: string, request: IFileDownloadRequest): void {
        const callbacks = this.downloadCallbacks[scope];
        if (!callbacks) {
            return;
        }

        for (const cb of callbacks) {
            cb(request);
        }
    }

    public static onRequestFileDownload(scope: string, callback: (request: IFileDownloadRequest) => void): () => void {
        const unsubscribe = this.subscribe(scope, callback);

        return unsubscribe;
    }

    private static subscribe(scope: string, callback: (request: IFileDownloadRequest) => void): () => void {
        const callbacks = this.downloadCallbacks[scope] || [];
        callbacks.push(callback);
        this.downloadCallbacks[scope] = callbacks;

        return () => {
            callbacks.splice(callbacks.indexOf(callback), 1);
        };
    }
}
