import { useCallback, useEffect, useMemo, useState } from "react";
import { IProjectModel } from "../../models/project/IProjectModel";
import { useParams } from "react-router-dom";
import { useNavigation } from "../NavigationHook";
import { paths, projectPaths } from "../../PathConstants";
import { usePermissionKey } from "../permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../permission/PermissionCheckHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useProjectApi } from "./ProjectApiHook";
import { NotificationService } from "../../services/NotificationService";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

interface ProjectSettingsPermissionKeys {
    projectWriteKey: string;
    projectDeleteKey: string;
    serviceHookReadKey: string;
}

/**
 * The project settings hook return.
 */
interface ProjectSettingsReturn {
    removeProject: (projectId: string) => Promise<void>;
    isAllowed: (permissionKey: string) => boolean;
    isDenied: (permissionKey: string) => boolean;
    loadProject: () => Promise<IProjectModel>;
    nameIsUnique: (name: string) => Promise<boolean>;
    permissionKeys: ProjectSettingsPermissionKeys;
    onSaveProject: (project: IProjectModel) => void;
    updateProject: (project: IProjectModel) => Promise<IProjectModel>;
}

export const useProjectSettings = (): ProjectSettingsReturn => {
    const { organizationName } = useParams<{ organizationName: string }>();
    const { projectId, organizationId } = useProjectContext();
    const { replace } = useNavigation();
    const [updatedProject, setUpdatedProject] = useState<IProjectModel | null>(null);
    const { remove, get, nameIsUnique: validateNameIsUnique, update } = useProjectApi();

    const removeProject = useCallback(
        async (projectName: string): Promise<void> => {
            try {
                await remove(projectId);
                NotificationService.addSuccessNotification({
                    messageKey: "Project.DeleteSuccessSingle",
                    messageKeyParams: { name: projectName },
                });
                replace(paths.home, { state: { deleted: true } });
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        },
        [remove, projectId, replace],
    );

    const nameIsUnique = useCallback(
        async (name: string): Promise<boolean> => {
            try {
                return await validateNameIsUnique(organizationId, name, projectId);
            } catch (error) {
                defaultRequestErrorHandler(error);
                throw error;
            }
        },
        [validateNameIsUnique, organizationId, projectId],
    );

    const projectManageKey = usePermissionKey({
        permission: PermissionKeys.project.managePermissions,
        projectId,
    });

    const serviceHookReadKey = usePermissionKey({ permission: PermissionKeys.serviceHook.read, projectId });
    const projectWriteKey = usePermissionKey({ permission: PermissionKeys.project.write, objectId: projectId });
    const projectDeleteKey = usePermissionKey({ permission: PermissionKeys.project.delete, objectId: projectId });

    const permissionsObject = useMemo(() => {
        return {
            permissionKeys: [projectWriteKey, projectDeleteKey, projectManageKey, serviceHookReadKey],
        };
    }, [projectDeleteKey, projectManageKey, projectWriteKey, serviceHookReadKey]);

    const { isAllowed, isDenied } = usePermissionCheck(permissionsObject);

    const loadProject = useCallback(() => get(projectId), [get, projectId]);

    const onSaveProject = useCallback((project: IProjectModel) => {
        setUpdatedProject(project);
        NotificationService.addSuccessNotification({
            messageKey: "Project.SaveSuccess",
            messageKeyParams: { name: project.name },
        });
    }, []);

    useEffect(() => {
        if (updatedProject) {
            setUpdatedProject(null);
            replace(projectPaths.link.settings(organizationName!, updatedProject.name));
        }
    }, [updatedProject, replace, organizationName]);

    return {
        removeProject,
        isAllowed,
        isDenied,
        loadProject,
        nameIsUnique,
        onSaveProject,
        permissionKeys: { projectWriteKey, projectDeleteKey, serviceHookReadKey },
        updateProject: update,
    };
};
