import React from "react";
import { IRecentProjectModel } from "../../models/project/IRecentProjectModel";
import { RecentProjectCard } from "./RecentProjectCard";

import "./RecentProjectsDeck.scss";

/**
 * The recent projects list props interface.
 */
interface IRecentProjectProps {
    recentProjects: IRecentProjectModel[];
}

/**
 * The recent projects component.
 */
export const RecentProjectsDeck: React.FunctionComponent<IRecentProjectProps> = (props: IRecentProjectProps) => {
    const {
        recentProjects: [project1, project2, project3, project4],
    } = props;

    return (
        <div className="recent-projects-wrapper">
            <RecentProjectCard recentProject={project1} />
            {project2 && <RecentProjectCard recentProject={project2} />}
            {project3 && <RecentProjectCard recentProject={project3} className="d-none d-lg-flex" />}
            {project4 && <RecentProjectCard recentProject={project4} className="d-none d-xl-flex" />}
        </div>
    );
};
