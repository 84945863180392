import React from "react";
import isEqual from "lodash/isEqual";
import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";
import { useEmailValidation } from "../hooks/validation/EmailValidationHook";

/**
 * The editable users invitation fields.
 */
export interface IEditableUsersInvitationFields {
    emails: string[];
    groupId?: string;
    projectId?: string;
    organizationId?: string;
}

type EmailsArray = string[] | undefined;

/**
 * The users invitation form validator class.
 */
export class UsersInvitationFormValidator extends FormValidator<IEditableUsersInvitationFields> {
    protected previousEmailResult: Promise<boolean | Yup.ValidationError> = Promise.resolve(false);
    protected currentTimeout: number = 0;
    protected emailCheckThrottle: number = 500;
    protected lastEmailsCheck: [EmailsArray, Promise<boolean | Yup.ValidationError>] = [[], Promise.resolve(false)];

    protected schema = Yup.object().shape({
        emails: Yup.array(Yup.string()).test("email-validation", async (emails) => {
            try {
                await this.emailsValidationSchema.validate(emails);
                await this.emailsAreUniqueSchema.validate(emails);
            } catch (err) {
                return err as Yup.ValidationError;
            }

            return true;
        }),
        groupId: Yup.string().required(),
    });

    protected emailsValidationSchema = Yup.array(
        Yup.string()
            .ensure()
            .email(({ value }) => {
                return (
                    <ValidatorError
                        messageKey="UsersInvitationFormValidator.InvalidEmail"
                        options={{ email: value as string }}
                    />
                );
            }),
    ).min(1, "Common.Required");

    protected emailsAreUniqueSchema = Yup.array(Yup.string().ensure()).test(
        "is-unique",
        () => {
            return <ValidatorError messageKey="UsersInvitationFormValidator.EmailValidationError" />;
        },
        (emailValues: EmailsArray, testContext: Yup.TestContext) => {
            [, this.previousEmailResult] = this.lastEmailsCheck;
            const [previousEmails, isPreviousUnique] = this.lastEmailsCheck;

            if (isEqual(previousEmails, emailValues)) {
                return isPreviousUnique;
            }

            this.lastEmailsCheck = this.throttledEmailsAreUnique(emailValues!, testContext);

            const [, newIsUnique] = this.lastEmailsCheck;
            return newIsUnique;
        },
    );

    constructor(private validateEmailsUniqueness: ReturnType<typeof useEmailValidation>) {
        super();
    }

    public validateEmails(emails: string[]): Promise<(string | undefined)[] | undefined> {
        return this.schema.fields.emails.validate(emails);
    }

    public validateGroup(group?: string): Promise<string | undefined> {
        return this.schema.fields.groupId.validate(group);
    }

    private throttledEmailsAreUnique(
        emails: string[],
        testContext: Yup.TestContext,
    ): [string[], Promise<boolean | Yup.ValidationError>] {
        const checkEmailPromise = new Promise<boolean | Yup.ValidationError>((resolve) => {
            clearTimeout(this.currentTimeout);

            this.currentTimeout = window.setTimeout(
                () =>
                    void (async () => {
                        if (this.initialValue && isEqual(this.initialValue.emails, emails)) {
                            resolve(true);
                            return;
                        }

                        try {
                            const result = await this.validateEmailsUniqueness(emails);
                            resolve(
                                result.length === 0 ||
                                    testContext.createError({
                                        message: "UsersInvitationFormValidator.EmailUnicityError",
                                        params: { email: result[0] },
                                    }),
                            );
                        } catch (error) {
                            resolve(false);
                        }
                    })(),
                this.emailCheckThrottle,
            );
        });

        return [emails, checkEmailPromise];
    }
}
