import React from "react";
import classNames from "classnames";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ISearchResultModel } from "../../models/search/ISearchResultModel";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./SearchResultItem.scss";

interface ISearchResultItemProps {
    item: ISearchResultModel;
}

export const SearchResultLinkItem: React.FC<ISearchResultItemProps> = ({
    item: { icon, name, entityTypeKey, link },
}: ISearchResultItemProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <DropdownItem
            tag={Link}
            className={classNames("search-list-item")}
            to={link}
            {...setSelector(`dropdownItem-${name}`)}
        >
            <div className="item-name-wrapper">
                {icon}
                <span className="item-name text-truncate">{name}</span>
            </div>
            <div className="item-type">{t(entityTypeKey)}</div>
        </DropdownItem>
    );
};
