import React, { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { InputGroup } from "reactstrap";
import { Button } from "../buttons/Button";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { LocalizedLabel } from "../forms/LocalizedLabel";

import "./Filter.scss";

/**
 * The filter props interface.
 */
interface IFilterProps extends React.PropsWithChildren<{}> {
    className?: string;
    clearDisabled?: boolean;
    onClear: () => void;
    active?: boolean;
}

/**
 * The filter function component.
 */
export const Filter: React.FC<IFilterProps> = ({
    children,
    className,
    clearDisabled,
    onClear,
    active,
}: IFilterProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <>
            <div className={classNames("filter-panel", className)}>
                <LocalizedLabel for="filter-input" text="Filter.EnterFilterValue" hidden />
                <InputGroup className="input-group-alternative input-group-merge">
                    {children}
                    <Button
                        ariaLabel={t("Common.Clear")}
                        className={classNames("filter-clear", { active: active })}
                        icon={isHovered ? "fas fa-xmark-large" : "far fa-xmark-large"}
                        {...setSelector("clearFiltersButton")}
                        onClick={onClear}
                        color="link"
                        tabIndex={-1}
                        disabled={clearDisabled}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        description={t("Common.Clear")}
                    />
                </InputGroup>
            </div>
        </>
    );
};
