import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Label, LabelProps } from "reactstrap";

import "./LocalizedLabel.scss";

/**
 * The localized label props interface.
 */
interface ILocalizedLabelProps extends LabelProps {
    text: string;
    icon?: string;
    required?: boolean;
}

/**
 * The localized label component.
 */
export const LocalizedLabel: React.FunctionComponent<ILocalizedLabelProps> = ({
    className,
    icon,
    required,
    text,
    ...labelProps
}: ILocalizedLabelProps) => {
    const {
        t,
    } = useTranslation();

    return (
        <Label className={classNames("localized-label", className, { required })} {...labelProps}>
            {icon && <i className={icon} />}
            {t(text)}
        </Label>
    );
};
