import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LoadingScreen } from "./components/loading/LoadingScreen";
import { AppInsightsTracking } from "./AppInsightsTracking";
import { AppConfig } from "./AppConfig";

/**
 * The App component.
 */
const App: React.FunctionComponent = () => {
    return (
        <AppConfig>
            <AppInsightsTracking>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </AppInsightsTracking>
        </AppConfig>
    );
};

export default App;
