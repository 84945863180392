import { useState, useRef, useEffect, useCallback } from "react";

type GetNext<T, R> = (o: T, controller: AbortController) => Promise<R>;
type OnFinish<T, R> = (completed: R[], errors: unknown[], remainingObjects: T[]) => void;

interface IPromisePoolConfig<TObject, TResult> {
    initialObjects: TObject[];
    getNext: GetNext<TObject, TResult>;
    onFinish: OnFinish<TObject, TResult>;
}

interface PromisePoolState<TObject, TResult> {
    isInterrupted: boolean;
    callOnFinish: boolean;
    folderId: null | string;
    objects: TObject[];
    abortControllers: (AbortController | null)[];
    completedPromiseResults: TResult[];
    failedPromiseResults: unknown[];
    getNext?: GetNext<TObject, TResult>;
    onFinish?: OnFinish<TObject, TResult>;
}

const getDefaultState = <TObject, TResult>(): PromisePoolState<TObject, TResult> => ({
    isInterrupted: false,
    callOnFinish: true,
    folderId: null,
    objects: [],
    abortControllers: [],
    completedPromiseResults: [],
    failedPromiseResults: [],
});

export const usePromisePool = <TObject, Tresult>(maxSimultaneousPromises: number) => {
    const stateRef = useRef<PromisePoolState<TObject, Tresult>>(getDefaultState<TObject, Tresult>());
    const [ongoingPromisesCounter, setOngoingPromisesCounter] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    const start = useCallback(({ getNext, initialObjects, onFinish }: IPromisePoolConfig<TObject, Tresult>) => {
        stateRef.current = {
            ...getDefaultState<TObject, Tresult>(),
            objects: initialObjects,
            onFinish,
            getNext,
        };
        setOngoingPromisesCounter(0);
        setIsRunning(true);
    }, []);

    const handleNext = useCallback(() => {
        const nextObject = stateRef.current.objects.pop();
        const controller = new AbortController();
        const controllerIndex = stateRef.current.abortControllers.push(controller) - 1;

        stateRef.current
            .getNext?.(nextObject!, controller)
            .then((r) => stateRef.current.completedPromiseResults.push(r))
            .catch((e) => stateRef.current.failedPromiseResults.push(e))
            .finally(() => {
                setOngoingPromisesCounter((c) => c - 1);
                stateRef.current.abortControllers[controllerIndex] = null;
            });

        setOngoingPromisesCounter((c) => c + 1);
    }, []);

    const interrupt = (callOnFinish: boolean) => {
        stateRef.current.callOnFinish = callOnFinish;
        stateRef.current.isInterrupted = true;
        stateRef.current.abortControllers.forEach((controller) => {
            controller?.abort();
        });
    };

    const { isInterrupted, callOnFinish } = stateRef.current;

    useEffect(() => {
        if (!isRunning || (isInterrupted && !callOnFinish)) {
            return;
        }

        if (!isInterrupted && ongoingPromisesCounter < maxSimultaneousPromises && stateRef.current.objects.length > 0) {
            handleNext();
            return;
        }

        if ((stateRef.current.objects.length === 0 || isInterrupted) && ongoingPromisesCounter === 0) {
            stateRef.current.onFinish?.(
                stateRef.current.completedPromiseResults,
                stateRef.current.failedPromiseResults,
                stateRef.current.objects,
            );
            setIsRunning(false);
        }
    }, [callOnFinish, handleNext, isInterrupted, isRunning, maxSimultaneousPromises, ongoingPromisesCounter]);

    useEffect(() => {
        return () => {
            stateRef.current.abortControllers.forEach((controller) => {
                controller?.abort();
            });
        };
    }, []);

    return {
        start,
        cancel: () => interrupt(true),
    };
};
