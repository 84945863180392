import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { ServiceHookStateTranslationMap } from "../../models/serviceHooks/ServiceHookState";
import { ServiceHookTypeTranslationMap, ServiceHookType } from "../../models/serviceHooks/ServiceHookType";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { RadioComponentGroup } from "../radio/RadioComponentGroup";
import { SelectServiceHookEventType } from "./SelectServiceHookEventType";
import { IServiceHookFormFields } from "../../models/serviceHooks/IServiceHookFormFields";
import {
    ServiceHookEventType,
    ServiceHookEventTypeTranslationMap,
} from "../../models/serviceHooks/ServiceHookEventType";
import { SelectComponentOptions } from "../selects/SelectComponent";
import { IWebHookConfigModel } from "../../models/serviceHooks/configs/IWebHookConfigModel";

interface IServiceHookFormFieldsProps {
    data: IServiceHookFormFields;
    onChange: <TField extends keyof IServiceHookFormFields>(
        field: TField,
        value: IServiceHookFormFields[TField],
    ) => void;
    errors: Record<string, string | JSX.Element | undefined>;
    disabled?: boolean;
}

const typeOptions = [
    {
        label: ServiceHookTypeTranslationMap[ServiceHookType.Webhook],
        value: ServiceHookType.Webhook,
        icon: "far fa-webhook",
    },
];

export const ServiceHookFormFields: React.FC<IServiceHookFormFieldsProps> = ({
    data,
    onChange,
    errors,
    disabled = false,
}) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const eventTypeOptions: SelectComponentOptions<ServiceHookEventType>[] = useMemo(
        () => [
            {
                label: t(ServiceHookEventTypeTranslationMap["DataSetCreated"]),
                value: "DataSetCreated",
                testSelectorValue: "dataSetCreatedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["DataSetUpdated"]),
                value: "DataSetUpdated",
                testSelectorValue: "dataSetUpdatedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["DataSetDeleted"]),
                value: "DataSetDeleted",
                testSelectorValue: "dataSetDeletedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFileCreated"]),
                value: "ContentFileCreated",
                testSelectorValue: "contentFileCreatedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFileUpdated"]),
                value: "ContentFileUpdated",
                testSelectorValue: "contentFileUpdatedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFileDeleted"]),
                value: "ContentFileDeleted",
                testSelectorValue: "contentFileDeletedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFolderCreated"]),
                value: "ContentFolderCreated",
                testSelectorValue: "contentFolderCreatedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFolderUpdated"]),
                value: "ContentFolderUpdated",
                testSelectorValue: "contentFolderUpdatedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFolderDeleted"]),
                value: "ContentFolderDeleted",
                testSelectorValue: "contentFolderDeletedItem"
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFileShareCreated"]),
                value: "ContentFileShareCreated",
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFileShareUpdated"]),
                value: "ContentFileShareUpdated",
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFileShareDeleted"]),
                value: "ContentFileShareDeleted",
            },
            {
                label: t(ServiceHookEventTypeTranslationMap["ContentFileShareAccessed"]),
                value: "ContentFileShareAccessed",
            },
        ],
        [t],
    );

    const config = useMemo(() => JSON.parse(data.config) as IWebHookConfigModel, [data.config]);
    const [url, setUrl] = useState(() => config.serviceUri);

    useEffect(() => {
        if (url !== config.serviceUri) {
            setUrl(() => config.serviceUri);
        }
    }, [config.serviceUri, url]);

    const onChangeConfig = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newUrl = e.target.value;
            setUrl(() => {
                onChange("config", JSON.stringify({ serviceUri: newUrl }));
                return newUrl;
            });
        },
        [onChange],
    );

    return (
        <>
            <FormGroup>
                <SelectServiceHookEventType
                    value={data.eventType}
                    onChange={(v) => onChange("eventType", v)}
                    options={eventTypeOptions}
                    disabled={disabled}
                />
            </FormGroup>
            <FormGroup>
                <div>{t("Common.State")}</div>
                <FormGroup switch>
                    <Input
                        id="hook-state"
                        name="state"
                        type="switch"
                        role="switch"
                        checked={!data.state}
                        onChange={() => onChange("state", data.state ? 0 : 1)}
                        disabled={disabled}
                        { ...setSelector("stateToggle") }
                    />
                    <LocalizedLabel for="hook-state" text={ServiceHookStateTranslationMap[data.state]} />
                </FormGroup>
            </FormGroup>
            <FormGroup>
                <div className="select-hook-type">{t("Common.Type")}</div>
                <RadioComponentGroup
                    radioName="ServiceHookType"
                    selectedValue={data.type}
                    options={typeOptions}
                    onChange={(type) => onChange("type", type)}
                    disabled={disabled}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="hook-config" text="Common.URL" required />
                <ValidatedInput
                    id="hook-config"
                    autoComplete="off"
                    name="config"
                    type="text"
                    onChange={onChangeConfig}
                    error={errors.config}
                    value={url}
                    disabled={disabled}
                    { ...setSelector("urlTextbox") }
                />
            </FormGroup>
        </>
    );
};
