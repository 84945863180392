import { useCallback } from "react";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import { IContentEntityModel, IUpdateContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { IContentHistoryModel, IHistoryModel } from "../../models/history/IHistoryModel";
import { WopiAction } from "../../models/wopi/IWopiModel";
import { IEntityResult } from "../../models/IEntityResult";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { ISortField } from "../../models/ISortField";
import { IFileVersioningModel, VersionComment } from "../../models/versioning/VersioningModels";
import { ITranslationLanguageModel } from "../../models/contentManager/ITranslationLanguageModel";

interface ContentFileApiHookResult {
    get: (fileId: string) => Promise<IContentEntityModel>;
    getFileDownloadUrl: (
        fileId: string,
        isAttachment: boolean,
        translationLanguageCode?: string,
        action?: WopiAction,
    ) => Promise<string>;
    nameIsUnique: (name: string, parentFolderId: string | null) => Promise<boolean>;
    update: (fileId: string, model: IUpdateContentEntityModel) => Promise<IContentEntityModel>;
    getHistory: (id: string) => Promise<IContentHistoryModel[]>;
    getTranslatableLanguages: () => Promise<ITranslationLanguageModel[]>;
    getTranslatableExtensions: () => Promise<string[]>;
    getHistoryRecords: (
        fileId: string,
        updatedEntityDate?: string,
        offset?: number,
    ) => Promise<IEntityResult<IHistoryModel>>;
    getVersioningRecords: (
        fileId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IFileVersioningModel>>;
    updateVersionComment: (fileId: string, versionId: string, comment: VersionComment) => Promise<string>;
    restoreVersion: (fileId: string, versionId: string, comment: VersionComment) => Promise<IContentEntityModel>;
}

export const useContentFileApi = (projectId: string): ContentFileApiHookResult => {
    const contentFileUrl = useCallback(
        (fileId?: string) => `/_api/project/${projectId}/file${fileId ? "/" + fileId : ""}`,
        [projectId],
    );

    const { httpGetJson, httpPut } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const get = useCallback(
        (fileId: string) => cancellableRequest<IContentEntityModel>({ url: contentFileUrl(fileId) }, httpGetJson),
        [contentFileUrl, cancellableRequest, httpGetJson],
    );

    const getFileDownloadUrl = useCallback(
        (fileId: string, isAttachment: boolean, translationLanguageCode?: string, action?: WopiAction) =>
            cancellableRequest<string>(
                {
                    url: `${contentFileUrl()}/link/${fileId}`,
                    queryParams: { isAttachment, action, ...(translationLanguageCode && { translationLanguageCode }) },
                },
                httpGetJson,
            ),
        [contentFileUrl, cancellableRequest, httpGetJson],
    );

    const nameIsUnique = useCallback(
        (name: string, parentFolderId: string | null) => {
            const params: Record<string, string> = {
                name,
            };

            if (parentFolderId) {
                params["parentFolderId"] = parentFolderId;
            }

            return cancellableRequest<boolean>(
                {
                    url: `${contentFileUrl()}/nameisunique`,
                    queryParams: params,
                },
                httpGetJson,
            );
        },
        [contentFileUrl, cancellableRequest, httpGetJson],
    );

    const update = useCallback(
        (fileId: string, model: IUpdateContentEntityModel) =>
            cancellableRequest<IContentEntityModel, IUpdateContentEntityModel>(
                { url: contentFileUrl(fileId), body: model },
                httpPut,
            ),
        [contentFileUrl, httpPut, cancellableRequest],
    );

    const getHistory = useCallback(
        (id: string) =>
            cancellableRequest<IContentHistoryModel[]>(
                {
                    url: `${contentFileUrl(id)}/detailedhistory`,
                },
                httpGetJson,
            ),
        [cancellableRequest, contentFileUrl, httpGetJson],
    );

    const getHistoryRecords = useCallback(
        (fileId: string, updatedEntityDate?: string, offset?: number) => {
            const filters: Record<string, string> = {};

            if (updatedEntityDate) {
                filters.lastUpdatedDate = updatedEntityDate;
            }

            return cancellableRequest<IEntityResult<IHistoryModel>>(
                {
                    url: `${contentFileUrl(fileId)}/history`,
                    queryParams: normalizeQueryParams({
                        filters,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [contentFileUrl, httpGetJson, cancellableRequest],
    );

    const getTranslatableLanguages = useCallback(
        () =>
            cancellableRequest<ITranslationLanguageModel[]>(
                {
                    url: `${contentFileUrl()}/translationsupportedlanguages`,
                },
                httpGetJson,
            ),
        [cancellableRequest, contentFileUrl, httpGetJson],
    );

    const getTranslatableExtensions = useCallback(
        () =>
            cancellableRequest<string[]>(
                {
                    url: `${contentFileUrl()}/translatableextensions`,
                },
                httpGetJson,
            ),
        [cancellableRequest, contentFileUrl, httpGetJson],
    );

    const getVersioningRecords = useCallback(
        (
            fileId: string,
            filterValue?: string,
            sortFields?: ISortField[],
            offset?: number,
        ): Promise<IEntityResult<IFileVersioningModel>> => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                filters.Version = filterValue;
                filters.Comment = filterValue;
            }

            return cancellableRequest<IEntityResult<IFileVersioningModel>>(
                {
                    url: `${contentFileUrl(fileId)}/version`,
                    queryParams: normalizeQueryParams(
                        {
                            filters,
                            sortFields,
                            offset,
                        },
                        false,
                    ),
                },
                httpGetJson,
            );
        },
        [cancellableRequest, contentFileUrl, httpGetJson],
    );

    const updateVersionComment = useCallback(
        (fileId: string, versionId: string, comment: VersionComment): Promise<string> =>
            cancellableRequest<string, VersionComment>(
                { url: `${contentFileUrl(fileId)}/version/${versionId}`, body: comment },
                httpPut,
            ),
        [cancellableRequest, contentFileUrl, httpPut],
    );
    const restoreVersion = useCallback(
        (fileId: string, versionId: string, comment: VersionComment): Promise<IContentEntityModel> =>
            cancellableRequest<IContentEntityModel, VersionComment>(
                {
                    url: `${contentFileUrl(fileId)}/version/${versionId}/restore`,
                    body: comment,
                },
                httpPut,
            ),
        [cancellableRequest, contentFileUrl, httpPut],
    );

    return {
        get,
        getFileDownloadUrl,
        nameIsUnique,
        update,
        getHistory,
        getTranslatableLanguages,
        getTranslatableExtensions,
        getHistoryRecords,
        getVersioningRecords,
        updateVersionComment,
        restoreVersion,
    };
};
