import { useCallback } from "react";
import { FoldersTree } from "../../models/contentManager/FolderTree";
import { useContentFolderApi } from "./ContentFolderApiHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { createNewFolders } from "../../helpers/ContentManagerFolderHelper";
import { sortFolderTree } from "../../helpers/TreeFolderHelper";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { useUserContext } from "../../contexts/UserContext";

interface LoadFolderTreeResult {
    foldersTree: FoldersTree;
    currentFolderLocationId: string | null;
}

export const useGetFoldersTreeFromPath = () => {
    const { projectId } = useProjectContext();
    const { locale } = useUserContext();

    const { getSubFoldersWithPath, getSubFolders } = useContentFolderApi(projectId);

    const loadRootSubFolders = useCallback(async () => {
        try {
            const subFolders = await getSubFolders(null);

            const newFolders = createNewFolders(null, subFolders);

            return { foldersTree: sortFolderTree(newFolders, locale), currentFolderLocationId: null };
        } catch (error) {
            defaultRequestErrorHandler(error);
            return { foldersTree: [], currentFolderLocationId: null };
        }
    }, [getSubFolders, locale]);

    const loadFolderTree = useCallback(
        async (path: string | null, onSubFolderLoadError?: (error: unknown) => void): Promise<LoadFolderTreeResult> => {
            if (!path || path === "/") {
                return loadRootSubFolders();
            }

            try {
                const { subFolders, selectedFolderId: newSelectedFolderId } = await getSubFoldersWithPath(path);

                const newFolders: FoldersTree = subFolders.map((f) => ({
                    id: f.id,
                    name: f.name,
                    parentId: f.parentId,
                    hasChildren: f.hasChildren,
                    areChildrenLoaded: f.areChildrenLoaded,
                    isOpen: f.areChildrenLoaded,
                    level: f.path.split("/").length - 2,
                    path: f.path,
                    isLoadingChildren: false,
                }));
                return {
                    foldersTree: sortFolderTree(newFolders, locale),
                    currentFolderLocationId: newSelectedFolderId,
                };
            } catch (error) {
                onSubFolderLoadError?.(error);
                return loadRootSubFolders();
            }
        },
        [getSubFoldersWithPath, loadRootSubFolders, locale],
    );

    return {
        loadFolderTree,
    };
};
