import React from "react";
import { FormGroup } from "reactstrap";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { EntityForm } from "../forms/EntityForm";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { IProjectFormFields } from "../../models/project/IProjectFormFields";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useProjectValidation } from "../../hooks/project/ProjectValidationHook";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

interface ICreateProjectFormProps {
    organizationId: string;
    onFormChange: (formData: IEntityFormData<IProjectFormFields>) => void;
}

export const CreateProjectForm: React.FC<ICreateProjectFormProps> = ({
    organizationId,
    onFormChange,
}: ICreateProjectFormProps) => {
    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: {
            description: "",
            name: "",
            organizationId,
        },
        onFormChange,
        useValidation: useProjectValidation,
    });
    const { setSelector } = useTestSelector();

    return (
        <EntityForm name="create-project" className="createProjectForm">
            <FormGroup>
                <LocalizedLabel aria-required="true" for="create-project-name" text="Common.Name" required />
                <ValidatedInputWithStatus
                    id="create-project-name"
                    autoFocus={true}
                    autoComplete="off"
                    name="name"
                    type="text"
                    {...setSelector("nameTextbox")}
                    onChange={(e) => onChange("name", e.target.value)}
                    error={errors.name}
                    isBusy={validationStatuses.name === "Validating"}
                    value={data.name}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="create-project-description" text="Common.Description" />
                <ValidatedInput
                    id="create-project-description"
                    autoComplete="off"
                    name="description"
                    type="textarea"
                    {...setSelector("descriptionTextbox")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("description", e.target.value)}
                    error={errors.description}
                    value={data.description}
                />
            </FormGroup>
        </EntityForm>
    );
};
