import React, { useContext } from "react";
import { IOrganizationModel } from "../models/organization/IOrganizationModel";

export interface IOrganizationContext {
    organization: IOrganizationModel;
    refreshOrganization: () => void;
}

export const OrganizationContext = React.createContext<IOrganizationContext | null>(null);

export const useOrganizationContext = (): IOrganizationContext | null => useContext(OrganizationContext);
