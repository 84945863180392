import React, { useCallback, useState } from "react";
import { CurrentEntitiesNameContext } from "../../contexts/CurrentEntitiesNameContext";
import { EntityType } from "../../models/EntityType";

export const CurrentEntitiesNameProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [entitiesName, setEntitiesName] = useState<Partial<Record<EntityType, string>>>({});

    const setEntityName = useCallback((entityType: EntityType, entityName: string) => {
        setEntitiesName((curr) => ({ ...curr, [entityType]: entityName }));
    }, []);

    const getEntityName = useCallback(
        (entityType: EntityType) => {
            return entitiesName[entityType] || "";
        },
        [entitiesName],
    );

    return (
        <CurrentEntitiesNameContext.Provider value={{ getEntityName, setEntityName }}>
            {children}
        </CurrentEntitiesNameContext.Provider>
    );
};
