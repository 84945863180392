import { useCallback, useMemo } from "react";
import { CreateTemplateFormValidator } from "../../formValidators/CreateTemplateFormValidator";
import { NameValidator } from "../../formValidators/NameValidator";
import { ITemplateFormFields } from "../../models/templates/ITemplateFormFields";
import { IValidationResult } from "../CreateModalEntityHook";
import { useDefaultEntityValidation } from "../validation/EntityValidationHook";
import { useTemplateApi } from "./TemplateApiHook";

export const useTemplateValidation = ({
    name,
    description,
    type,
    dataSetIds,
    projectId,
}: ITemplateFormFields): IValidationResult<ITemplateFormFields, "name"> => {
    const { nameIsUnique } = useTemplateApi(projectId);
    const nameValidatorProvider = useCallback(() => {
        return new NameValidator({
            nameExistErrorKey: "TemplateFormValidator.TemplateExists",
            nameIsUnique,
        });
    }, [nameIsUnique]);

    const formValidationProvider = useCallback(() => new CreateTemplateFormValidator(), []);

    const fields = useMemo(() => ({ name, description, type, dataSetIds }), [dataSetIds, description, name, type]);

    const { valid, errors, nameValidationStatus, setError } = useDefaultEntityValidation({
        fields,
        nameValidatorProvider,
        formValidationProvider,
    });

    return {
        valid,
        errors,
        validationStatuses: { name: nameValidationStatus },
        setError,
    };
};
