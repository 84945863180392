import { createContext, useContext } from "react";
import { IServerConfiguration } from "../models/server/IServerConfiguration";

/**
 * The App config context.
 */
export const AppConfigContext = createContext<IServerConfiguration | null>(null);

/**
 * The App config context hook.
 */
export const useAppConfigContext = (): IServerConfiguration => useContext(AppConfigContext) as IServerConfiguration;