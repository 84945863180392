import React from "react";
import classNames from "classnames";
import { Button as ReactStrapButton, ButtonProps, Spinner } from "reactstrap";

import "./Button.scss";

/**
 * The button props interface.
 */
export interface IButtonProps extends ButtonProps {
    icon?: string;
    loading?: boolean;
    description?: string;
    ariaLabel: string;
}

/**
 * The button component.
 */
export const Button: React.FC<IButtonProps> = ({
    children,
    icon,
    loading,
    onClick,
    className,
    description,
    ariaLabel,
    ...buttonProps
}) => {
    if (!children && !description) {
        throw new Error("A textless button needs a description. Please add the description prop");
    }

    return (
        <>
            <ReactStrapButton
                {...buttonProps}
                type="button"
                aria-label={ariaLabel}
                aria-disabled={!!buttonProps.disabled || undefined}
                className={classNames(
                    "button-wrapper",
                    className,
                    buttonProps.color && buttonProps.color === "link" ? "link" : "",
                )}
                onClick={onClick}
            >
                <span className="btn-hidden-description">{description}</span>
                {icon && <i className={icon} />}
                {children}
                {loading && <Spinner className="spinner" size="sm" />}
            </ReactStrapButton>
        </>
    );
};
