import React from "react";
import { Trans } from "react-i18next";

/**
 * The validator error interface.
 */
export interface IValidatorError {
    messageKey: string;
    options?: {};
    shouldUnescape?: boolean;
}

/**
 * The ValidatorError component.
 */
export const ValidatorError: React.FunctionComponent<IValidatorError> = (props: IValidatorError) => {
    return (
        <>
            <span>
                <Trans i18nKey={props.messageKey} values={props.options} shouldUnescape={props.shouldUnescape} />
            </span>
        </>
    );
};
