import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ITranslateFormFields, LanguageType } from "../models/contentManager/ITranslateFormFields";

export interface IEditableTranslateFields extends Pick<ITranslateFormFields, "language"> {}

export class TranslateFormValidator extends FormValidator<IEditableTranslateFields> {
    protected schema = Yup.object().shape({
        language: Yup.mixed<LanguageType>().required("Common.Required"),
    });

    public validateLanguage(language: LanguageType | null): Promise<LanguageType | null> {
        return this.schema.fields.language.validate(language);
    }
}
