import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons/Button";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./SearchNavbarItem.scss";

/**
 * The search navbar toggle props interface.
 */
interface ISearchNavbarToggleProps {
    onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => void;
    isPanelVisible: boolean;
}

/**
 * The search navbar toggle functional component.
 */
export const SearchNavbarToggle: React.FC<ISearchNavbarToggleProps> = ({
    onMouseDown,
    isPanelVisible,
}: ISearchNavbarToggleProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <Button
            onMouseDown={onMouseDown}
            className="search-trigger"
            color="link"
            ariaLabel={t(isPanelVisible ? "Search.HideSearch" : "Search.ShowSearch")}
            {...setSelector("globalSearchDropdownToggle")}
        >
            <i className="fal fa-search" />
        </Button>
    );
};
