/**
 * The sort helper class.
 */
export class SortHelper {
    public static compare = (labelA: string, labelB: string): number => {
        if (labelA > labelB) {
            return 1;
        }
        if (labelA < labelB) {
            return -1;
        }

        return 0;
    };
}