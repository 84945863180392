import React, { useCallback, useState } from "react";
import { SidePanelContext } from "../../contexts/SidePanelContext";
import { EntityType } from "../../models/EntityType";
import { Direction } from "reactstrap/types/lib/Offcanvas";
import { useNavigationCloseHandler } from "../../hooks/modals/NavigationCloseHandlerHook";

export interface IPanelState {
    content?: JSX.Element;
    backdrop?: boolean;
    direction?: Direction;
    title?: string | JSX.Element;
    titleIcon?: string | JSX.Element;
    titleMultiRow?: boolean;
    entityIcon?: EntityType;
    classname?: string;
    beforeClosing?: () => Promise<boolean>;
}

const initialState = {};

export const SidePanelContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [sidePanelState, setPanelState] = useState<IPanelState>(initialState);
    const [isDisplayed, setIsDisplayed] = useState(false);

    const closeSidePanel = useCallback(
        (forceClose?: boolean) => {
            if (!forceClose && sidePanelState.beforeClosing) {
                void sidePanelState.beforeClosing().then((shouldClose) => {
                    if (shouldClose) {
                        setIsDisplayed(false);
                        setPanelState(initialState);
                    }
                });

                return;
            }

            setIsDisplayed(false);
            setPanelState(initialState);
        },
        [sidePanelState],
    );

    const showSidePanel = useCallback(() => {
        setIsDisplayed(true);
    }, []);

    useNavigationCloseHandler({ close: () => closeSidePanel(true), isOpen: isDisplayed });

    return (
        <SidePanelContext.Provider
            value={{
                setSidePanelState: setPanelState,
                sidePanelState,
                closeSidePanel,
                showSidePanel,
                isDisplayed,
            }}
        >
            {children}
        </SidePanelContext.Provider>
    );
};
