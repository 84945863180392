import { useCallback } from "react";
import { useProjectApi } from "../project/ProjectApiHook";
import { useOrganizationApi } from "../organization/OrganizationApiHook";
import { useUserApi } from "../user/UserApiHook";

interface UseEmailValidationProps {
    projectId?: string;
    organizationId?: string;
}

export const useEmailValidation = ({ projectId, organizationId }: UseEmailValidationProps) => {
    const { validateEmailsUniqueness: validateOrganizationEmailsUniqueness } = useOrganizationApi();
    const { validateEmailsUniqueness: validateProjectEmailsUniqueness } = useProjectApi();
    const { validateEmailsUniqueness: validateUserEmailsUniqueness } = useUserApi();

    const validateProjectEmailsUniquenessCallback = useCallback(
        (emails: string[]) => validateProjectEmailsUniqueness(emails, projectId!),
        [projectId, validateProjectEmailsUniqueness],
    );
    const validateOrganizationEmailsUniquenessCallback = useCallback(
        (emails: string[]) => validateOrganizationEmailsUniqueness(emails, organizationId!),
        [organizationId, validateOrganizationEmailsUniqueness],
    );
    const validateUserEmailsUniquenessCallback = useCallback(
        (emails: string[]) => validateUserEmailsUniqueness(emails),
        [validateUserEmailsUniqueness],
    );

    if (projectId) {
        return validateProjectEmailsUniquenessCallback;
    }

    if (organizationId) {
        return validateOrganizationEmailsUniquenessCallback;
    }

    return validateUserEmailsUniquenessCallback;
};
