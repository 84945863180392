import React, { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { LoadingScreen } from "../../loading/LoadingScreen";

// eslint-disable-next-line @typescript-eslint/naming-convention
const EditApiKeyView = lazy(() => import("../EditApiKeyView").then((module) => ({ default: module.EditApiKeyView })));

export const LazyEditApiKeyView = (props: React.ComponentProps<typeof EditApiKeyView>) => {
    const { t } = useTranslation();
    return (
        <Suspense fallback={<LoadingScreen>{t("Loading.ApiKey")}</LoadingScreen>}>
            <EditApiKeyView {...props} />
        </Suspense>
    );
};
