import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";
import { useNavigationPromptDialog } from "../../navigation/NavigationPromptDialogHook";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { useSidePanelContext } from "../../../contexts/SidePanelContext";
import { BeforeNavigate } from "../ContentManagerTreeHook";

interface IUseItemPropertiesSidePanelDirtyHandlerProps {
    selectedItems?: IContentEntityModel[];
    reselectHandler?: (currentId: string) => void;
    setBeforeNavigate: (fn: BeforeNavigate) => void;
}

interface IUseItemPropertiesSidePanelDirtyHandlerResult {
    setIsDirty: Dispatch<SetStateAction<boolean>>;
    currentItems?: IContentEntityModel[];
}

export const useItemPropertiesSidePanelDirtyHandler = ({
    selectedItems,
    reselectHandler,
    setBeforeNavigate,
}: IUseItemPropertiesSidePanelDirtyHandlerProps): IUseItemPropertiesSidePanelDirtyHandlerResult => {
    const { setSidePanelState, closeSidePanel } = useSidePanelContext();
    const [isDirty, setIsDirty] = useState(false);
    const [isLeaving, setIsLeaving] = useState(false);
    const [confirmNavigate, setConfirmNavigate] = useState(false);
    const [, setDidNavigate] = useState(false);
    const [currentItems, setCurrentItems] = useState(selectedItems);
    const [itemDeleted, setItemDeleted] = useState(false);
    const beforeClosingRef = useRef<((value: boolean | PromiseLike<boolean>) => void) | null>(null);

    useEffect(() => {
        const itemsAreEqual = isEqual(currentItems, selectedItems);

        if (itemsAreEqual) {
            setDidNavigate(false);
            return;
        }

        if (currentItems?.length === 1 && !itemsAreEqual && !itemDeleted) {
            setIsLeaving(true);
            return;
        }

        itemDeleted && setItemDeleted(false);
        isDirty && setIsDirty(false);
        setDidNavigate((navigated) => {
            if (!navigated) {
                setCurrentItems(selectedItems);
            }

            return false;
        });
    }, [itemDeleted, currentItems, selectedItems, isDirty]);

    useEffect(() => {
        const callback = (event: Event) => {
            const deletedItems = (event as CustomEvent).detail as string[];

            currentItems?.length === 1 && deletedItems.find((id) => id === currentItems[0].id) && setItemDeleted(true);
        };

        document.addEventListener("tableState.ItemsDeleted", callback);

        return () => document.removeEventListener("tableState.ItemsDeleted", callback);
    }, [currentItems]);

    useEffect(() => {
        if (!isDirty && isLeaving && !confirmNavigate) {
            setIsLeaving(false);

            if (!beforeClosingRef.current) {
                setCurrentItems(selectedItems);
            } else {
                beforeClosingRef.current(true);
                beforeClosingRef.current = null;
            }
        }
    }, [confirmNavigate, isDirty, isLeaving, selectedItems]);

    useEffect(() => {
        if (confirmNavigate) {
            if (beforeClosingRef.current) {
                setCurrentItems([]);
                beforeClosingRef.current(true);
                beforeClosingRef.current = null;
            } else {
                setCurrentItems(selectedItems);
            }

            setDidNavigate(true);
            setIsLeaving(false);
            setConfirmNavigate(false);
        }
    }, [selectedItems, confirmNavigate]);

    useNavigationPromptDialog({
        componentId: "contentManagerSidePanel",
        isDirty,
        isLeaving,
        messageKey: "ContentManager.ItemProperties.UnsavedWarningBody",
        onCancel: () => {
            if (beforeClosingRef.current) {
                beforeClosingRef.current(false);
                beforeClosingRef.current = null;
            } else {
                currentItems && currentItems.length === 1 && reselectHandler && reselectHandler(currentItems[0].id);
            }

            setIsLeaving(false);
        },
        onConfirm: () => {
            setIsDirty(false);

            if (!isLeaving) {
                closeSidePanel(true);
                return;
            }

            setConfirmNavigate(true);
        },
    });

    const beforeClosing = useCallback(() => {
        return new Promise<boolean>((res) => {
            beforeClosingRef.current = res;
            setIsLeaving(true);
        });
    }, []);

    useEffect(() => {
        if (currentItems && currentItems.length === 1) {
            setSidePanelState((prev) => ({
                ...prev,
                beforeClosing,
            }));
        }
    }, [beforeClosing, currentItems, setSidePanelState]);

    useEffect(() => {
        setBeforeNavigate(beforeClosing);

        return () => setBeforeNavigate(null);
    }, [beforeClosing, setBeforeNavigate]);

    return {
        currentItems,
        setIsDirty,
    };
};
