import React from "react";
import { LoadingWrapper } from "../loading/LoadingWrapper";

/**
 * The entity table loading row props interface.
 */
interface IEntityTableLoadingRowProps {
    colspan: number;
    message: string;
}

/**
 * The entity table loading row component.
 */
export const EntityTableLoadingRow: React.FunctionComponent<IEntityTableLoadingRowProps> = (props: IEntityTableLoadingRowProps) => {
    const { colspan, message } = props;

    return (
        <tr className="loading-row">
            <td colSpan={colspan}>
                <LoadingWrapper size={24} message={message} />
            </td>
        </tr>
    );
};