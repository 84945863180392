import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons/Button";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { useTemplateDesignDownload } from "../../hooks/template/TemplateDesignDownloadHook";
import { useEditEntityContext } from "../../contexts/EditEntityContext";

interface IEditTemplateDesignButtonProps {
    loadedTemplate: ITemplateModel;
}

export const EditTemplateDesignButton: React.FC<IEditTemplateDesignButtonProps> = ({ loadedTemplate }) => {
    const { t } = useTranslation();
    const { handleDesign, fetchingLanguages } = useTemplateDesignDownload();
    const {
        dirtyProps: { isDirty },
    } = useEditEntityContext();

    const designLabel = t("Common.Design");
    const dirty = useMemo(() => isDirty(), [isDirty]);
    return (
        <Button
            onClick={() => handleDesign(loadedTemplate.templateId, loadedTemplate.name)}
            icon="fal fa-compass-drafting"
            color="outline-secondary"
            key="design-action"
            ariaLabel={designLabel}
            disabled={dirty || fetchingLanguages}
            loading={fetchingLanguages}
        >
            {designLabel}
        </Button>
    );
};
