import React from "react";
import { ServiceHookEventType } from "../../models/serviceHooks/ServiceHookEventType";
import { SelectComponent, SelectComponentOptions } from "../selects/SelectComponent";
import { LocalizedLabel } from "../forms/LocalizedLabel";

interface ISelectServiceHookEventTypeProps {
    value: ServiceHookEventType | "";
    options: SelectComponentOptions<ServiceHookEventType>[];
    onChange: (value: ServiceHookEventType) => void;
    disabled: boolean;
}

export const SelectServiceHookEventType: React.FC<ISelectServiceHookEventTypeProps> = ({
    value,
    options,
    onChange,
    disabled,
}) => {
    return (
        <>
            <LocalizedLabel for="select-service-hook-event" text="Common.EventType" required />
            <SelectComponent
                id="select-service-hook-event"
                size="large"
                value={value}
                options={options}
                onChange={onChange}
                testSelectorValue="eventTypeDropdown"
                disabled={disabled}
            />
        </>
    );
};
