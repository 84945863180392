import React from "react";
import { TabContent } from "reactstrap";
import { DependenciesTable } from "../table/DependenciesTable";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { IEntityResult } from "../../models/IEntityResult";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { ISortField } from "../../models/ISortField";
import { TabPanel } from "../tabs/TabPanel";

import "./DependenciesView.scss";

interface DependenciesViewProps {
    activeTab: DependencyType;
    getDependenciesRecordsHandler: (
        dependencyType: DependencyType,
        offset?: number,
        sortField?: ISortField[],
        filterValue?: string,
    ) => Promise<IEntityResult<IDependencyModel>>;
    parentEntityType: string;
}

export const DependenciesView: React.FC<DependenciesViewProps> = ({
    activeTab,
    getDependenciesRecordsHandler,
    parentEntityType,
}: DependenciesViewProps) => {
    return (
        <div className="dependencies-view">
            <TabContent activeTab={activeTab}>
                <TabPanel labelby="dependsOn-id" tabId="dependsOn" className="dependencies">
                    <DependenciesTable
                        getRecords={getDependenciesRecordsHandler}
                        dependencyType="dependsOn"
                        active={activeTab === "dependsOn"}
                        parentEntityType={parentEntityType}
                    />
                </TabPanel>
                <TabPanel labelby="usedBy-id" tabId="usedBy" className="dependencies">
                    <DependenciesTable
                        getRecords={getDependenciesRecordsHandler}
                        dependencyType="usedBy"
                        active={activeTab === "usedBy"}
                        parentEntityType={parentEntityType}
                    />
                </TabPanel>
            </TabContent>
        </div>
    );
};
