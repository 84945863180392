import { createContext, useContext } from "react";

/**
 * The NavBar search context props.
 */
interface INavBarSearchContextProps {
    setIsPanelVisible: (isPanelVisible: boolean) => void;
}

/**
 * The NavBar search context context.
 */
export const NavBarSearchContext = createContext<INavBarSearchContextProps | null>(null);

/**
 * The use NavBar search context.
 */
export const useNavBarSearchContext = (): INavBarSearchContextProps =>
    useContext(NavBarSearchContext) as INavBarSearchContextProps;
