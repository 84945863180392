import React from "react";
import { Label } from "reactstrap";
import { PermissionAccess } from "../../models/permissions/PermissionAccess";
import { SelectComponent, SelectComponentOptions } from "./SelectComponent";

interface ISelectPermissionProps {
    readonly?: boolean;
    value: PermissionAccess;
    onChange: (value?: PermissionAccess) => void;
    label: string;
}

const options: SelectComponentOptions<PermissionAccess>[] = [
    {
        value: PermissionAccess.Allow,
        label: "Allow",
        icon: "fal fa-check",
        className: "text-success",
    },
    {
        value: PermissionAccess.Deny,
        label: "Deny",
        icon: "fal fa-ban",
        className: "text-danger",
    },
    {
        value: PermissionAccess.NotSet,
        label: "Not set",
    },
];

export const SelectPermission: React.FC<ISelectPermissionProps> = ({ label, readonly, value, onChange }) => {
    return (
        <>
            <Label for="select-permission">{label}</Label>
            <SelectComponent
                id="select-permission"
                options={options}
                value={value}
                disabled={readonly}
                onChange={onChange}
            />
        </>
    );
};
