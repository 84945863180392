import React, { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { LoadingScreen } from "../../loading/LoadingScreen";

// eslint-disable-next-line @typescript-eslint/naming-convention
const ApiKeysView = lazy(() => import("../ApiKeysView").then((module) => ({ default: module.ApiKeysView })));

export const LazyApiKeysView = (props: React.ComponentProps<typeof ApiKeysView>) => {
    const { t } = useTranslation();
    return (
        <Suspense fallback={<LoadingScreen>{t("Loading.ApiKeys")}</LoadingScreen>}>
            <ApiKeysView {...props} />
        </Suspense>
    );
};
