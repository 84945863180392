import React from "react";
import { FilterDropdownCheckbox } from "./FilterDropdownCheckbox";
import { FilterOption, IFilterOptionsComponents, OptionType } from "../../models/IFilterOptions";
import { Trans } from "react-i18next";
import { DropdownCheckboxItem } from "../dropdowns/DropdownCheckboxItem";
import { LabelCircle } from "../labelCircle/LabelCircle";

/**
 * Represent the filter props.
 */
interface IMultipleDropDownFilterProps<TOption extends OptionType> {
    text: string;
    filterComponents: IFilterOptionsComponents<TOption>;
    index: number;
    onFilterChange: (filterIndex: number, options: TOption[]) => void;
}

/**
 * Creates the Translated Component
 * @param filterValue the selected filters
 * @param filtersOptions the list of possible filters
 * @param defaultCheckboxLabel The resource key when no filters are selected
 * @returns The Translated Select Component
 */
const getFilterString = <TOption extends OptionType>(
    filterValue: TOption[],
    filtersOptions: FilterOption<TOption>[],
    defaultCheckboxLabel: string,
): JSX.Element => {
    let messageKey = defaultCheckboxLabel;
    if (filterValue.length > 0) {
        const firstOption = filtersOptions.find((f) => f.filterValue === filterValue[0]);
        messageKey = firstOption?.labelKey ?? "";
    }
    return <Trans i18nKey={messageKey} values={{ count: filterValue.length, filterCount: filterValue.length - 1 }} />;
};

const checkboxIsChecked = <TOption extends OptionType>(option: TOption, activeFilter: TOption[]): boolean => {
    return activeFilter.some((fs) => fs === option);
};

const checkboxLabel = <TOption extends OptionType>(
    defaultCheckBoxLabelName: string,
    filterOption: FilterOption<TOption>[],
    activeFilter: TOption[],
): JSX.Element => {
    return (
        <span className={activeFilter.length > 0 ? "selectedItems" : ""}>
            {getFilterString(activeFilter, filterOption, defaultCheckBoxLabelName)}
        </span>
    );
};

export const FilterDropDownCheckboxComponent = <TOption extends OptionType>({
    text,
    index,
    filterComponents,
    onFilterChange,
}: IMultipleDropDownFilterProps<TOption>): JSX.Element => {
    const filterDropdownCheckboxChange = (id: string, checked: boolean): void => {
        const selectedOption = filterComponents.filtersOptions.find(
            (f) => f.filterValue.toString() === id,
        ) as FilterOption<TOption>;
        if (!selectedOption) {
            return;
        }

        const newFilter = checked
            ? filterComponents.activeFilter.concat(selectedOption.filterValue)
            : filterComponents.activeFilter.filter((s) => s !== selectedOption.filterValue);

        onFilterChange(index, newFilter);
    };

    const onClear = () => {
        onFilterChange(index, []);
    };

    return (
        <FilterDropdownCheckbox
            label={checkboxLabel(
                filterComponents.defaultCheckboxLabel,
                filterComponents.filtersOptions,
                filterComponents.activeFilter,
            )}
            testSelectorValueFilterName={filterComponents.testSelectorCheckboxFilterValue}
            onClear={onClear}
            canClear={filterComponents.activeFilter.length > 0}
        >
            {filterComponents.filtersOptions.map(
                ({ filterValue, labelKey, labelColor, testSelectorItemName, escapeTranslation }) => {
                    const stringValue = filterValue.toString();
                    return (
                        <DropdownCheckboxItem
                            id={stringValue}
                            key={stringValue}
                            testSelectorItemName={testSelectorItemName}
                            onCheckChange={filterDropdownCheckboxChange}
                            checked={checkboxIsChecked(filterValue, filterComponents.activeFilter)}
                        >
                            {labelColor && <LabelCircle color={labelColor} />}
                            {escapeTranslation ? labelKey : <Trans i18nKey={labelKey} />}
                        </DropdownCheckboxItem>
                    );
                },
            )}
        </FilterDropdownCheckbox>
    );
};
