import React from "react";
import { LoadingAnimation } from "../loading/LoadingAnimation";
import { ModalBody, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Button } from "../buttons/Button";

interface ILoadingModalBodyProps {
    onClose?: () => void;
    onCancel?: () => void;
    labelKey: string;
}

export const LoadingModalContent: React.FC<ILoadingModalBodyProps> = ({ labelKey, onClose, onCancel }) => {
    const { t } = useTranslation();

    const closeButton = (
        <Button
            color="flat"
            description={t("Common.Close")}
            className="close"
            onClick={onClose}
            ariaLabel={t("Common.Close")}
        >
            <i className="fal fa-times" />
        </Button>
    );

    return (
        <>
            {onClose && <ModalHeader close={closeButton} />}
            <ModalBody>
                <LoadingAnimation size={32} />
                <div className="main-label">{t(labelKey)}</div>
                <div className="notice-label">
                    {onClose ? t("Loading.NoticeLabel.CanClose") : t("Loading.NoticeLabel.CannotClose")}
                </div>
                {onCancel && (
                    <Button ariaLabel={t("Common.Cancel")} onClick={onCancel} outline>
                        {t("Common.Cancel")}
                    </Button>
                )}
            </ModalBody>
        </>
    );
};
