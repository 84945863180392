import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TemplateIcon from "../../assets/img/icons/Template.svg";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { useNavigation } from "../../hooks/NavigationHook";
import { ITableDeleteHelper, Table } from "../table/Table";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useDefaultTableFilterHelper } from "../../hooks/table/DefaultTableFilterHelper";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { templatePaths } from "../../PathConstants";
import { ITemplateWithDataSetsModel } from "../../models/templates/ITemplateWithDataSetsModel";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { EntityIcon } from "../icons/EntityIcon";
import { TemplateType } from "../../models/templates/TemplateType";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { useDefaultTableCreateHelper } from "../../hooks/table/DefaultTableCreateHelper";
import { NotificationService } from "../../services/NotificationService";
import { TemplateForm } from "./TemplateForm";
import { useCloneTemplate } from "../../hooks/template/CloneTemplateHook";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { ITableContextModel } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { TableViewHeader } from "../views/TableViewHeader";
import { TableDependencyDeleteDialog } from "../dialogs/TableDependencyDeleteDialog";
import { EntityType } from "../../models/EntityType";
import { useTemplateApi } from "../../hooks/template/TemplateApiHook";
import { useTemplateDesignDownload } from "../../hooks/template/TemplateDesignDownloadHook";

const convertToTemplateModel = ({ dataSets, ...rest }: ITemplateWithDataSetsModel): ITemplateModel => {
    return {
        ...rest,
        timestamp: "",
        updatedDate: "",
        dataSetIds: dataSets.map((ds) => ds.dataSetId),
    };
};

/**
 * The template view component.
 */
export const TemplateView: React.FC = (): JSX.Element => {
    const { navigate } = useNavigation();
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const filterHelper = useDefaultTableFilterHelper("TemplateView.FilterPlaceHolder");
    const { name: projectName, projectId, organizationName } = useProjectContext();

    const { editCloneTemplate } = useCloneTemplate();
    const { removeRecords, getRecords, create, getMultipleDependencies } = useTemplateApi(projectId);
    const { handleDesign } = useTemplateDesignDownload();

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            content: ({ name, templateId, type }: ITemplateWithDataSetsModel) => {
                if (type === TemplateType.Standard) {
                    return (
                        <div>
                            <EntityIcon type="Template.Standard" />
                            <Link
                                className="text-truncate"
                                to={templatePaths.link.edit(organizationName, projectName, templateId)}
                                {...setSelector("cell-link")}
                            >
                                {name}
                            </Link>
                        </div>
                    );
                }

                return <></>;
            },
            fieldName: "name",
            displayName: "Common.Name",
            shouldTruncateText: true,
            className: "name",
            sortField: {
                name: "Name",
                order: SortOrder.Asc,
            },
            testSelectorColumnName: "name",
        },
        {
            type: "Text",
            fieldName: "description",
            displayName: "Common.Description",
            sortField: {
                name: "Description",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "description",
        },
        {
            type: "Text",
            content: ({ type }: ITemplateWithDataSetsModel) => {
                if (type === TemplateType.Standard) {
                    return t("Common.Standard");
                }

                return t("Common.Invalid");
            },
            fieldName: "type",
            displayName: "Common.Type",
            sortField: {
                name: "Type",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "type",
        },
        {
            type: "JSX",
            content: ({ dataSets }: ITemplateWithDataSetsModel) => {
                return (
                    <>
                        {dataSets
                            ?.map((dataset) => dataset.name)
                            .sort()
                            .join(", ")}
                    </>
                );
            },
            fieldName: "Data Sets",
            displayName: "Common.DataSets",
            shouldTruncateText: true,
            testSelectorColumnName: "dataSets",
        },
    ];

    const actionProvider = useCallback(
        (
            item: ITemplateWithDataSetsModel,
            context: ITableContextModel<ITemplateWithDataSetsModel, ITableRowItem<ITemplateWithDataSetsModel>, string>,
        ): ITableRowActionProps[] => {
            const templateWritePermission = formatPermissionKey(
                PermissionKeys.template.write,
                item.templateId,
                projectId,
            );
            const templateDeletePermission = formatPermissionKey(
                PermissionKeys.template.delete,
                item.templateId,
                projectId,
            );
            const languageReadPermission = formatPermissionKey(PermissionKeys.language.read, undefined, projectId);

            return [
                {
                    iconClassName: "fal fa-compass-drafting",
                    label: "Common.Design",
                    onClick: () => handleDesign(item.templateId, item.name),
                    permissions: [templateWritePermission, languageReadPermission],
                },
                {
                    iconClassName: "fal fa-eye",
                    label: "Common.View",
                    tag: Link,
                    testSelectorValue: "editItem",
                    to: templatePaths.link.edit(organizationName, projectName, item.templateId),
                    permissions: [templateWritePermission],
                    transform: (actionProps, { isAllowed: internalIsAllowed }) => {
                        const hasWritePermissionProps = {
                            label: "Common.Edit",
                            iconClassName: "fal fa-pencil",
                        };

                        if (internalIsAllowed(templateWritePermission)) {
                            return {
                                ...actionProps,
                                ...hasWritePermissionProps,
                            };
                        }

                        return actionProps;
                    },
                },
                {
                    iconClassName: "fal fa-light fa-clone",
                    label: "Common.Clone",
                    testSelectorValue: "cloneItem",
                    onClick: () => editCloneTemplate(convertToTemplateModel(item)),
                    permissions: [templateWritePermission],
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.templateId]),
                    permissions: [templateDeletePermission],
                    separated: true,
                    style: { color: colorStyles.red },
                },
            ];
        },
        [projectId, organizationName, projectName, handleDesign, editCloneTemplate],
    );

    const onCreated = useCallback(
        ({ name, templateId }: ITemplateModel): void => {
            NotificationService.addSuccessNotification({
                messageKey: "TemplateView.CreationSuccess",
                messageKeyParams: { name },
            });
            navigate(templatePaths.link.edit(organizationName, projectName, templateId));
        },
        [navigate, projectName, organizationName],
    );

    const templateWriteKey = usePermissionKey({
        permission: PermissionKeys.template.write,
        projectId,
    });
    const templateDeleteKey = usePermissionKey({
        permission: PermissionKeys.template.delete,
        projectId,
    });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [templateWriteKey, templateDeleteKey] }),
        [templateDeleteKey, templateWriteKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: TemplateForm,
        onCreated,
        onCreate: create,
        formProps: {
            mode: "Create",
        },
        initialEntity: {
            templateId: "",
            name: "",
            description: "",
            type: TemplateType.Standard,
            dataSetIds: [],
            projectId,
        },
        modalProps: {
            className: "create-template-modal",
            titleKey: "CreateTemplate.Title",
            titleIconClassName: "fas fa-file-invoice text-red",
            unsavedWarningBody: "Template.UnsavedWarningBody",
            size: "lg",
            expandable: true,
        },
    });

    const createHelper = useMemo(() => {
        if (!isAllowed(templateWriteKey)) {
            return;
        }

        return defaultCreateHelper;
    }, [defaultCreateHelper, isAllowed, templateWriteKey]);

    const deleteHelper: ITableDeleteHelper<ITemplateWithDataSetsModel> | undefined = useMemo(() => {
        if (!isAllowed(templateDeleteKey)) {
            return;
        }

        return {
            confirmationTitleMessageKey: "CommonTemplate.DeleteTitle",
            confirmationBodyMessageKey: "CommonTemplate.DeleteBody",
            deleteRecords: removeRecords,
            notificationMessageKey: "TemplateView",
            customDeleteComponent: TableDependencyDeleteDialog,
            getDependencies: getMultipleDependencies,
            entityType: "Template" as EntityType,
        };
    }, [removeRecords, getMultipleDependencies, isAllowed, templateDeleteKey]);

    const noResultsCTAProps = useMemo(() => {
        if (!isAllowed(templateWriteKey)) {
            return;
        }

        return {
            title: "Route.Templates",
            description: "EntityDescription.Templates",
            createTitle: "Common.Create",
            logo: {
                imgSrc: TemplateIcon,
                imgAlt: "",
            },
        };
    }, [isAllowed, templateWriteKey]);

    return (
        <>
            <TableViewHeader titleKey="Route.Templates" />
            <Table
                className="template-table"
                columnDefs={columnDefs}
                getRecords={getRecords}
                keyExtractor={(item) => item.templateId}
                nameExtractor={(item) => item.name}
                loadingMessageKey="Loading.Templates"
                filterHelper={filterHelper}
                createHelper={createHelper}
                tableId="templates"
                actionProvider={actionProvider}
                deleteHelper={deleteHelper}
                selectable={isAllowed(templateDeleteKey)}
                noResultsCTAProps={noResultsCTAProps}
            />
        </>
    );
};
