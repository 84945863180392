import { useEffect, useMemo, useState } from "react";
import {
    ContentFileShareFormValidator,
    IContentFileShareFormFields,
} from "../../../formValidators/ContentFileShareFormValidator";
import { useEntityErrors } from "../../entity/EntityErrorsHook";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { IValidationResult } from "../../CreateModalEntityHook";
import { CreateContentFileShareFormValidator } from "../../../formValidators/CreateContentFileShareFormValidator";

interface IContentFileShareFormValidationProps {
    data: IContentFileShareFormFields;
    checkBehaviors: boolean;
}

export const useContentFileShareFormValidation = ({
    data,
    checkBehaviors,
}: IContentFileShareFormValidationProps): IValidationResult<IContentFileShareFormFields, never> => {
    const { errors, setError } = useEntityErrors<IContentFileShareFormFields>();
    const [valid, setValid] = useState(false);

    const { behaviors, expirationDate } = data;

    const formValidator = useMemo(() => {
        if (checkBehaviors) {
            return new CreateContentFileShareFormValidator();
        }

        return new ContentFileShareFormValidator();
    }, [checkBehaviors]);

    useEffect(() => {
        void formValidator
            .validateExpirationDate(expirationDate)
            .then(() => setError("expirationDate", ""))
            .catch((err: Error) => setError("expirationDate", extractErrorMessageOrEmptyString(err)));
    }, [expirationDate, formValidator, setError]);

    useEffect(() => {
        if (!checkBehaviors) {
            return;
        }

        void (formValidator as CreateContentFileShareFormValidator)
            .validateBehaviors(behaviors)
            .then(() => setError("behaviors", ""))
            .catch((err: Error) => setError("behaviors", extractErrorMessageOrEmptyString(err)));
    }, [behaviors, checkBehaviors, formValidator, setError]);

    useEffect(() => {
        void formValidator.isValid(data).then((value) => setValid(value));
    }, [formValidator, data]);

    return {
        valid,
        errors,
        setError,
        validationStatuses: {},
    };
};
