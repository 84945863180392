import React from "react";
import { useTableContext } from "./TableContext";
import { ITableRowItem, TableRow } from "./TableRow";
import { EntityTableLoadingRow } from "../table/EntityTableLoadingRow";

/**
 * The table body component.
 */
export const TableBody: React.FC<{}> = <
    TEntityModel extends Record<string, any>,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>(): JSX.Element => {
    const context = useTableContext<TEntityModel, TRowItem, TFilterValue>();

    if (!context) return <></>;

    const { tableState, columnDefs, keyExtractor, loadingMessageKey, actionProvider, selectable } = context;

    if (tableState.isLoading && tableState.offset === 0) return <></>;

    let columnCount = columnDefs.length;
    if (actionProvider) columnCount++;
    if (selectable) columnCount++;

    return (
        <tbody className="list">
            {tableState.items.map((row, index) => {
                const itemId = keyExtractor(row.item);

                return (
                    <TableRow
                        key={itemId}
                        selected={tableState.selectedItemIds.some((selectedItemId) => selectedItemId === itemId)}
                        actionProvider={actionProvider}
                        item={row.item}
                        rowIndex={index}
                    />
                );
            })}
            {tableState.isLoading && tableState.offset > 0 && (
                <EntityTableLoadingRow colspan={columnCount} message={loadingMessageKey} />
            )}
        </tbody>
    );
};
