import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useNavigation } from "../../../hooks/NavigationHook";
import { twoFactorAuthenticationPaths } from "../../../PathConstants";
import { NotificationService } from "../../../services/NotificationService";
import { Button } from "../../buttons/Button";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";

/**
 * The Disable Authenticator Component.
 */
export const DisableAuthenticator: React.FC = () => {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { disableTwoFA } = useAccountApi();

    const onDisable = useCallback(async () => {
        try {
            await disableTwoFA();
            NotificationService.addSuccessNotification({
                messageKey: "2FA.Disable.Success",
            });
            navigate(twoFactorAuthenticationPaths.route.base);
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [disableTwoFA, navigate]);

    return (
        <>
            <section>
                <h2>{t("2FA.Disable.Title")}</h2>
            </section>
            <section>
                <div className="warning-message">
                    <Trans
                        i18nKey={"2FA.Disable.Warning"}
                        components={{
                            ResetAuthenticatorRedirect: (
                                <NavLink to={twoFactorAuthenticationPaths.route.resetAuthenticator} />
                            ),
                        }}
                    />
                </div>
                <Button color="danger" onClick={onDisable} ariaLabel={t("2FA.Disable")}>
                    <i className="far fa-ban" />
                    {t("2FA.Disable")}
                </Button>
            </section>
        </>
    );
};
