import { useEffect } from "react";
import { EntityWithName } from "../contexts/EditEntityContext";
import { INameIsUniqueHookProps, useNameIsUnique } from "./NameIsUnique";

/**
 * The edit entity name hook Props.
 */
interface IEditEntityNameHookProps<TEntity> {
    nameIsUniqueProps: INameIsUniqueHookProps;
    value: string | undefined;
    setErrors: (errors: { [alias in keyof TEntity]: string }) => void;
}

/**
 * The edit entity name hook result.
 */
interface IEntityHookResult {
    checkNameIsUnique: (name: string) => void;
    isValidatingName: boolean;
}

/**
 * The use edit entity name hook.
 */
export const useEditEntityName = <TEntity extends EntityWithName>({
    nameIsUniqueProps,
    setErrors,
    value,
}: IEditEntityNameHookProps<TEntity>): IEntityHookResult => {
    type ErrorType = {
        [alias in keyof TEntity]: string;
    };

    const { checkNameIsUnique, errorMessage, isValidating } = useNameIsUnique({
        ...nameIsUniqueProps,
        initialName: value,
    });

    // Set set the errorMessage as the name property in the error object
    useEffect(() => {
        setErrors({
            name: errorMessage,
        } as ErrorType);
    }, [errorMessage, setErrors]);

    return {
        checkNameIsUnique,
        isValidatingName: isValidating,
    };
};
