import React from "react";
import { Trans } from "react-i18next";
import { LabelCircle } from "../labelCircle/LabelCircle";
import { ServiceHookState } from "../../models/serviceHooks/ServiceHookState";

interface IServiceHookStateIndicatorProps {
    state: ServiceHookState;
}

export const ServiceHookStateIndicator: React.FC<IServiceHookStateIndicatorProps> = ({ state }) => {
    if (state === ServiceHookState.Enabled) {
        return (
            <div>
                <LabelCircle color="green" />
                <Trans i18nKey="Common.State.Enabled" />
            </div>
        );
    }

    if (state === ServiceHookState.Disabled) {
        return (
            <div>
                <LabelCircle color="red" />
                <Trans i18nKey="Common.State.Disabled" />
            </div>
        );
    }

    return <></>;
};
