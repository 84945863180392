import React, { useCallback } from "react";
import moment from "moment-timezone";
import { FormGroup } from "reactstrap";
import { EntityForm } from "../../forms/EntityForm";
import { ShareLink } from "../../../models/contentManager/ContentManagerApiModels";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { useCreateModalEntity } from "../../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../../models/entity/form/IEntityFormData";
import { IContentFileShareFormFields } from "../../../formValidators/ContentFileShareFormValidator";
import { useContentFileShareFormValidation } from "../../../hooks/contentManager/contentFileShare/ContentFileShareFormValidationHook";
import { DateChangeValue, DateWithTimeZoneSelect } from "../../forms/DateWithTimeZoneSelect";
import { ReadOnlyInputWithCopy } from "../../forms/inputs/ReadOnlyInputWithCopy";
import {
    behaviorTranslationMap,
    formatContentFileShareExpirationDate,
    getDefaultContentFileShareExpirationDate,
} from "../../../helpers/ContentFileShareHelper";

import "./ContentFileShareForm.scss";

interface IContentFileEditShareFormProps {
    onFormChange: (formData: IEntityFormData<IContentFileShareFormFields>) => void;
    initialValue: IContentFileShareFormFields;
    shareLinks: ShareLink[];
}

export const EditContentFileShareForm = ({
    initialValue,
    onFormChange,
    shareLinks,
}: IContentFileEditShareFormProps) => {
    const { setSelector } = useTestSelector();

    const {
        data: { expirationDate, timeZoneId },
        errors,
        onChange,
    } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: (data) => useContentFileShareFormValidation({ data, checkBehaviors: false }),
        isDirty: (data) => {
            const areDateSimilar =
                (data.expirationDate === null && initialValue.expirationDate === null) ||
                moment(data.expirationDate).isSame(initialValue.expirationDate, "day");
            return !areDateSimilar || initialValue.timeZoneId !== data.timeZoneId;
        },
    });

    const onDateChange = useCallback(
        (value: DateChangeValue) => {
            onChange(
                "expirationDate",
                value ? formatContentFileShareExpirationDate(value.date, value.timeZone.offset) : null,
            );
            onChange("timeZoneId", value ? value.timeZone.timeZoneId : null);
        },
        [onChange],
    );

    return (
        <EntityForm className="content-file-share-form">
            <FormGroup>
                <FormGroup>
                    {shareLinks.map((shareLink) => (
                        <FormGroup key={shareLink.url}>
                            <ReadOnlyInputWithCopy
                                value={shareLink.url}
                                label={behaviorTranslationMap[shareLink.behavior]}
                                displayedValueWhileCopying="Share.LinkCopied"
                                testSelectorValue={`behaviorLink-${shareLink.behavior}`}
                            />
                        </FormGroup>
                    ))}
                </FormGroup>
                <FormGroup className="select-expiration-date" {...setSelector("selectExpirationDateFormGroup")}>
                    <DateWithTimeZoneSelect
                        defaultState={{
                            isDateNever: expirationDate === null,
                            date: expirationDate
                                ? moment.parseZone(expirationDate).utcOffset(0, true)
                                : getDefaultContentFileShareExpirationDate(),
                            defaultTimeZoneId: timeZoneId ?? undefined,
                        }}
                        dateError={errors.expirationDate}
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        onDateChange={onDateChange}
                        canSetDateToNever={true}
                        neverCheckboxLabel="Share.CreatePermanentHyperlinks"
                        neverCheckboxTestSelectorValue="createPermanentHyperlinksCheckbox"
                    />
                </FormGroup>
            </FormGroup>
        </EntityForm>
    );
};
