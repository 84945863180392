import moment from "moment";
import {
    ContentFileShareBehaviors,
    ContentFileShareExpirationDate,
} from "../models/contentManager/ContentManagerApiModels";

export const formatContentFileShareExpirationDate = (
    expirationDate: moment.Moment | string | null,
    offset?: number,
): ContentFileShareExpirationDate => {
    if (!expirationDate) {
        return null;
    }

    // If date is not a correct format, return the value without formatting.
    if (!moment.isMoment(expirationDate)) {
        return expirationDate;
    }

    if (offset) {
        expirationDate.utcOffset(offset, true);
    }

    expirationDate.hour(0).minute(0).second(0).millisecond(0);

    return expirationDate.toISOString(true);
};

export const getDefaultContentFileShareExpirationDate = (): moment.Moment => {
    return moment().add(6, "month").hours(0).minutes(0).seconds(0).milliseconds(0).utcOffset(0, true);
};

export const behaviorTranslationMap = {
    [ContentFileShareBehaviors.Download]: "Common.Download",
    [ContentFileShareBehaviors.View]: "Share.ViewInBrowser",
} as const;
