import React, { useEffect, useMemo, useState } from "react";
import { FormGroup } from "reactstrap";
import { EntityForm } from "../forms/EntityForm";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectComponent, SelectComponentOptions } from "../selects/SelectComponent";
import { useLanguageHookApi } from "../../hooks/languages/LanguageApiHook";
import { IAvailableLanguageModel } from "../../models/Languages/IAvailableLanguageModel";
import { ILanguageFormFields } from "../../formValidators/LanguageFormValidator";

interface ILanguageFormProps {
    onFormChange: (formData: IEntityFormData<{ languageCodes: string[] }>) => void;
    initialValue: ILanguageFormFields;
    projectId: string;
}

export const LanguageForm: React.FC<ILanguageFormProps> = ({
    onFormChange,
    initialValue,
    projectId,
}: ILanguageFormProps) => {
    const { data, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: () => {
            return {
                valid: true,
                errors: { languageCodes: "" },
                validationStatuses: { languageCodes: "Valid" },
                setError: () => {
                    return { language: "" };
                },
            };
        },
    });

    const { getAvailableLanguages } = useLanguageHookApi(projectId);

    const [languages, setLanguages] = useState<IAvailableLanguageModel[]>([]);

    useEffect(() => {
        let active = true;
        const loadLanguages = async () => {
            const loadedLanguages = await getAvailableLanguages();
            if (!active) {
                return;
            }
            setLanguages(loadedLanguages);
        };

        loadLanguages();
        return () => {
            active = false;
        };
    }, [getAvailableLanguages]);

    const listedLanguages: SelectComponentOptions<string>[] = useMemo(() => {
        return languages.map((l) => ({
            value: l.code,
            label: l.displayName,
        }));
    }, [languages]);

    return (
        <EntityForm name="add-language" className="addLanguageForm">
            <FormGroup>
                <LocalizedLabel for="select-languages" text="EntityType.Languages_plural" />
                <SelectComponent
                    id="select-languages"
                    multiple
                    onChange={(lang: string[]) => onChange("languageCodes", lang)}
                    options={listedLanguages}
                    size="large"
                    searchable={true}
                    value={data.languageCodes}
                />
            </FormGroup>
        </EntityForm>
    );
};
