import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { Card as ReactStrapCard, CardHeaderProps, CardBodyProps, CardHeader, CardBody } from "reactstrap";

import "./Card.scss";

interface ICardProps extends CardBodyProps, CardHeaderProps {
    headerContent: JSX.Element;
    className?: string;
}

export const Card: React.FC<PropsWithChildren<ICardProps>> = ({ className, headerContent, children }: ICardProps) => {
    return (
        <ReactStrapCard className={classNames("card-wrapper", className)}>
            <CardHeader>{headerContent}</CardHeader>
            <CardBody>{children}</CardBody>
        </ReactStrapCard>
    );
};
