import React from "react";
import { NotificationHubContext } from "../../contexts/NotificationHubContext";
import { INotificationOptions, NotificationService } from "../../services/NotificationService";
import { INotificationModel, useNotificationHub } from "../../hooks/hubs/NotificationHub";
import { TranslationLink } from "./TranslationLink";

/**
 * The notifications hub provider interface.
 */
interface INotificationHubProps {
    children?: React.ReactNode;
}

/**
 * The notification callback.
 */
const notification = (model: INotificationModel) => {
    const notificationOptions: Partial<INotificationOptions> = {
        messageKey: model.messageKey,
        messageKeyParams: model.messageKeyParams,
    };

    if (model.url) {
        notificationOptions.components = {
            translationlink: <TranslationLink to={model.url} />,
        };
    }

    NotificationService.addSuccessNotification(notificationOptions);
};

/**
 * The notification hub provider.
 */
export const NotificationHubProvider: React.FC<INotificationHubProps> = (props: INotificationHubProps) => {
    const { subscribeProjectCreation } = useNotificationHub({ notification });

    return (
        <NotificationHubContext.Provider value={{ subscribeProjectCreation }}>
            {props.children}
        </NotificationHubContext.Provider>
    );
};
