import React from "react";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import { useTranslation } from "react-i18next";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { ISortField } from "../../models/ISortField";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { EntityTableSelectableRowHeader } from "./EntityTableSelectableRowHeader";

/**
 * The entity table header props interface.
 */
interface IEntityTableHeaderProps {
    columnDefs: IEntityTableColumnDef[];
    className?: string;
    hasActions?: boolean;
    selectable?: boolean;
    sortField?: ISortField;
    selectedItemIds?: string[];
    onSort?: (sortField: ISortField) => void;
}

const renderColumnSortArrow = (sortOrder: SortOrder): JSX.Element | undefined => {
    switch (sortOrder) {
        case SortOrder.Asc:
            return <i className="far fa-long-arrow-up" />;
        case SortOrder.Desc:
            return <i className="far fa-long-arrow-down" />;
        default:
            return undefined;
    }
};

/**
 * The entity table header component.
 */
export const EntityTableHeader: React.FunctionComponent<IEntityTableHeaderProps> = (props: IEntityTableHeaderProps) => {
    const { className, columnDefs, hasActions, selectable, sortField, onSort } = props;
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const setFieldSortOrder = (fieldName: string, order: SortOrder): void => {
        if (!onSort || !fieldName) {
            return;
        }

        switch (order) {
            case SortOrder.Asc:
                order = SortOrder.Desc;
                break;
            case SortOrder.Desc:
                order = SortOrder.Asc;
                break;
            default:
                order = SortOrder.Asc;
                break;
        }

        onSort({ name: fieldName, order });
    };

    return (
        <>
            <thead className={classNames("thead-light", className)}>
                <tr>
                    {selectable && (
                        <td className="col-select-item">
                            <EntityTableSelectableRowHeader />
                        </td>
                    )}
                    {columnDefs.map((colDef: IEntityTableColumnDef, i) => {
                        let currentColSortOrder = SortOrder.None;

                        if (colDef.sortField && sortField) {
                            currentColSortOrder =
                                sortField.name === colDef.sortField.name ? sortField.order : SortOrder.None;
                        }

                        const classes = classNames(colDef.className, {
                            sort: colDef.sortField,
                            asc: colDef.sortField && currentColSortOrder === SortOrder.Asc,
                            desc: colDef.sortField && currentColSortOrder === SortOrder.Desc,
                        });

                        const sort = () =>
                            colDef.sortField && setFieldSortOrder(colDef.sortField.name, currentColSortOrder);

                        return (
                            <React.Fragment key={colDef.fieldName}>
                                <th
                                    className={classes}
                                    scope="col"
                                    {...setSelector(`sort-${colDef.testSelectorColumnName || ""}`)}
                                    onClick={sort}
                                >
                                    <span>
                                        {colDef.bypassTranslation
                                            ? colDef.displayName
                                            : t(colDef.displayName).split(" ").map(capitalize).join(" ")}
                                    </span>
                                    {renderColumnSortArrow(currentColSortOrder)}
                                </th>
                                {i === 0 && hasActions && <td className="col-action-item" />}
                            </React.Fragment>
                        );
                    })}
                </tr>
            </thead>
        </>
    );
};
