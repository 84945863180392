import { useCallback } from "react";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import { ICreateUsersInvitationModel } from "../../models/user/invitations/ICreateUsersInvitationModel";
import { ISortField } from "../../models/ISortField";
import { IEntityResult } from "../../models/IEntityResult";
import { IUserWithGroupsAndInvitationStatusModel } from "../../models/user/IUserWithGroupsAndInvitationStatusModel";
import { IResendUserInvitationRequest } from "../../models/user/invitations/IResendUserInvitationRequest";
import { normalizeQueryParams } from "../../helpers/HttpHelper";

interface UserApiHookReturn {
    getRecords: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IUserWithGroupsAndInvitationStatusModel>>;
    hasPermissions: (
        permissionChecks: { permission: string; objectId?: string }[],
    ) => Promise<{ [key: string]: boolean }>;
    invite: (createUsersInvitationModel: ICreateUsersInvitationModel) => Promise<number>;
    resendInvite: (resendUserInvitationRequest: IResendUserInvitationRequest) => Promise<number>;
    validateEmailsUniqueness: (emails: string[]) => Promise<string[]>;
}

const userUrl = "/_api/users";

export const useUserApi = (): UserApiHookReturn => {
    const { httpGetJson, httpPost } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const getRecords = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Email = filterValue;
            }

            return cancellableRequest<IEntityResult<IUserWithGroupsAndInvitationStatusModel>>(
                {
                    url: userUrl,
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const hasPermissions = useCallback(
        (permissionChecks: { permission: string; objectId?: string }[]) => {
            const url = `${userUrl}/haspermission`;
            const uniqueId = `${url}${JSON.stringify(permissionChecks)}`;
            return cancellableRequest<{ [key: string]: boolean }, { permission: string; objectId?: string }[]>(
                { url, body: permissionChecks },
                httpPost,
                uniqueId,
            );
        },
        [httpPost, cancellableRequest],
    );

    const invite = useCallback(
        (createUsersInvitationModel: ICreateUsersInvitationModel) =>
            cancellableRequest<number, ICreateUsersInvitationModel>(
                { url: `${userUrl}/invite`, body: createUsersInvitationModel },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const resendInvite = useCallback(
        (resendUserInvitationRequest: IResendUserInvitationRequest) =>
            cancellableRequest<number, IResendUserInvitationRequest>(
                { url: `${userUrl}/resendInvite`, body: resendUserInvitationRequest },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const validateEmailsUniqueness = useCallback(
        (emails: string[]) =>
            cancellableRequest<string[], string[]>({ url: `${userUrl}/emailsareunique`, body: emails }, httpPost),
        [httpPost, cancellableRequest],
    );

    return {
        getRecords,
        hasPermissions,
        invite,
        resendInvite,
        validateEmailsUniqueness,
    };
};
