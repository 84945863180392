import React from "react";

interface IArrowToggleIconProps {
    isOpen: boolean;
}

/**
 * The arrow toggle icon component.
 */
export const ArrowToggleIcon: React.FC<IArrowToggleIconProps> = ({ isOpen }: IArrowToggleIconProps) => {
    return <i className={`toggle-arrow far ${isOpen ? "fa-chevron-down" : "fa-chevron-right"}`} />;
};
