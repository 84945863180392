import React from "react";
import { Input, InputProps } from "reactstrap";
import { NullableValue } from "../../../models/NullableValue";
import { FormFeedbackError } from "../feedbacks/FormFeedbackError";

/**
 * The validated input props interface.
 */
export interface IValidatedInputProps extends Omit<InputProps, "value"> {
    error?: string | JSX.Element;
    name: string;
    value?: NullableValue<string> | number | readonly string[];
}

/**
 * The validated input component.
 */
export const ValidatedInput: React.FunctionComponent<IValidatedInputProps> = ({
    error,
    name,
    ...inputProps
}: IValidatedInputProps) => {
    const props = {
        ...inputProps,
        value: inputProps.value ?? "",
    };

    return (
        <>
            <Input id={name} invalid={!!error} {...props} />
            {error && <FormFeedbackError error={error} />}
        </>
    );
};
