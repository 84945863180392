import React, { useCallback, useMemo } from "react";
import OrganizationIcon from "../../assets/img/icons/Organization.svg";
import { Link } from "react-router-dom";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { AvatarWrapper } from "../avatars/AvatarWrapper";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { IOrganizationWithProjectCountModel } from "../../models/organization/IOrganizationWithProjectCountModel";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { NotificationService } from "../../services/NotificationService";
import { SortOrder } from "../../models/SortOrder";
import { organizationPaths } from "../../PathConstants";
import { Table } from "../table/Table";
import { useDefaultTableFilterHelper } from "../../hooks/table/DefaultTableFilterHelper";
import { useSidebarContext } from "../../contexts/SidebarContext";
import { useOrganizationCreation } from "../../hooks/organization/OrganizationCreationHook";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { ITableContextModel } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { TableViewHeader } from "../views/TableViewHeader";
import { useOrganizationApi } from "../../hooks/organization/OrganizationApiHook";
import { useNavigation } from "../../hooks/NavigationHook";

import "./OrganizationsView.scss";

const permissionObject = {
    permissionKeys: [PermissionKeys.organization.write, PermissionKeys.organization.delete],
};

export const OrganizationsView: React.FC<{}> = () => {
    const { setRefreshSidebar } = useSidebarContext();
    const { remove, removeRecords, getRecords } = useOrganizationApi();
    const { navigate } = useNavigation();

    const filterHelper = useDefaultTableFilterHelper("OrganizationsView.FilterPlaceHolder");
    const onOrganizationCreated = useCallback(
        ({ name, organizationId }: IOrganizationModel) => {
            setRefreshSidebar(true);
            NotificationService.addSuccessNotification({
                messageKey: "OrganizationsView.CreationSuccess",
                messageKeyParams: { name },
            });
            navigate(organizationPaths.link.edit(organizationId));
        },
        [navigate, setRefreshSidebar],
    );

    const onTableLoaded = useCallback(() => {
        setRefreshSidebar(true);
    }, [setRefreshSidebar]);

    const { isAllowed } = usePermissionCheck(permissionObject);

    const createHelperHook = useOrganizationCreation({ onCreated: onOrganizationCreated });

    const createHelper = useMemo(() => {
        if (!isAllowed(PermissionKeys.organization.write)) {
            return;
        }

        return createHelperHook;
    }, [createHelperHook, isAllowed]);

    const { setSelector } = useTestSelector();

    const actionProvider = useCallback(
        (
            item: IOrganizationWithProjectCountModel,
            context: ITableContextModel<
                IOrganizationWithProjectCountModel,
                ITableRowItem<IOrganizationWithProjectCountModel>,
                string
            >,
        ): ITableRowActionProps[] => {
            const organizationWritePermission = formatPermissionKey(
                PermissionKeys.organization.write,
                item.organizationId,
            );
            const organizationDeletePermission = formatPermissionKey(
                PermissionKeys.organization.delete,
                item.organizationId,
            );

            return [
                {
                    iconClassName: "fal fa-eye",
                    label: "Common.View",
                    tag: Link,
                    testSelectorValue: "editItem",
                    to: organizationPaths.link.edit(item.organizationId),
                    permissions: [organizationWritePermission],
                    transform: (actionProps, { isAllowed: internalIsAllowed }) => {
                        const hasWritePermissionProps = {
                            label: "Common.Edit",
                            iconClassName: "fal fa-pencil",
                        };

                        if (internalIsAllowed(organizationWritePermission)) {
                            return {
                                ...actionProps,
                                ...hasWritePermissionProps,
                            };
                        }

                        return actionProps;
                    },
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.organizationId]),
                    permissions: [organizationDeletePermission],
                    separated: true,
                    style: { color: colorStyles.red },
                },
            ];
        },
        [],
    );

    const content = useCallback(
        (organization: IOrganizationModel) => (
            <div className="organization-container">
                <div className="item-avatar">
                    <AvatarWrapper className="organization-avatar" name={organization.name} size="32" />
                </div>
                <Link
                    className="text-truncate"
                    to={organizationPaths.link.edit(organization.organizationId)}
                    {...setSelector("cell-link")}
                >
                    {organization.name}
                </Link>
            </div>
        ),
        [setSelector],
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            fieldName: "name",
            displayName: "Common.Name",
            sortField: {
                name: "Name",
                order: SortOrder.Asc,
            },
            content,
            shouldTruncateText: true,
            testSelectorColumnName: "name",
        },
        {
            type: "Text",
            fieldName: "description",
            displayName: "Common.Description",
            sortField: {
                name: "Description",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "description",
        },
        {
            type: "Number",
            fieldName: "projectsCount",
            displayName: "Common.Projects",
            className: "projects-count center-cell",
            sortField: {
                name: "ProjectsCount",
                order: SortOrder.Asc,
            },
            testSelectorColumnName: "projectsCount",
        },
    ];

    const noResultsCTAProps = useMemo(() => {
        if (!isAllowed(PermissionKeys.organization.write)) {
            return;
        }

        return {
            title: "Route.Organizations",
            description: "EntityDescription.Organizations",
            createTitle: "Common.Create",
            logo: {
                imgSrc: OrganizationIcon,
                imgAlt: "",
            },
        };
    }, [isAllowed]);

    return (
        <>
            <TableViewHeader titleKey="Route.Organizations" />
            <Table
                className="organization-table"
                actionProvider={actionProvider}
                columnDefs={columnDefs}
                getRecords={getRecords}
                keyExtractor={(item) => item.organizationId}
                nameExtractor={(item) => item.name}
                loadingMessageKey="Loading.Organizations"
                createHelper={createHelper}
                deleteHelper={{
                    confirmationTitleMessageKey: "CommonOrganization.DeleteTitle",
                    confirmationBodyMessageKey: "CommonOrganization.DeleteBody",
                    deleteRecords: removeRecords,
                    notificationMessageKey: "OrganizationsView",
                    onDeleted: () => setRefreshSidebar(true),
                    singleDelete: remove,
                }}
                filterHelper={filterHelper}
                selectable={isAllowed(PermissionKeys.organization.delete)}
                tableId="organizations"
                noResultsCTAProps={noResultsCTAProps}
                counterPosition={createHelper ? "End" : "Start"}
                onLoaded={onTableLoaded}
            />
        </>
    );
};
