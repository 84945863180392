import { IGroupPermissionDataModel } from "../../../models/permissions/IGroupPermissionDataModel";
import { IGroupPermissionModel } from "../../../models/permissions/IGroupPermissionModel";
import { IPermissionModel } from "../../../models/permissions/IPermissionModel";
import { PermissionAccess } from "../../../models/permissions/PermissionAccess";

interface IUseGroupPermissionDataResult {
    extract: (groupPermissionsData: IGroupPermissionDataModel[]) => IGroupPermissionModel[];
    serialize: (groupPermissions: IGroupPermissionModel[]) => IGroupPermissionDataModel[];
}

const entityTranslationMap = new Map<string, string>([
    ["organization", "Organization"],
    ["project", "Project"],
    ["dataset", "Data Set"],
    ["template", "Template"],
    ["app", "Application"],
    ["content", "Content"],
    ["servicehook", "Service Hook"],
    ["language", "Language"],
]);

const permissionTranslationMap = new Map<string, (scope: string) => string>([
    ["read", (scope) => `View ${scope}`],
    ["write", (scope) => `Write ${scope}`],
    ["delete", (scope) => `Delete ${scope}`],
    ["import", () => "Import a package"],
    ["export", () => "Export a package"],
    ["share", () => "Share content"],
    ["manageSettings", (scope) => `Manage ${scope} settings`],
    ["managePermissions", () => "Manage permissions"],
]);

// if true, contains the scope and permissions, if false contains nothing.
type ExtractPermissionType = [true, string, IPermissionModel] | [false];

const extractPermission = (permissionData: IGroupPermissionDataModel): ExtractPermissionType => {
    const [scope, key] = permissionData.permission.split("/");
    const getLabel = permissionTranslationMap.get(key);
    if (!getLabel) {
        // eslint-disable-next-line no-console
        console.warn(
            `Missing permission key '${key}' in translation map. this permission was skipped and isn't displayed`,
        );

        return [false];
    }

    const scopeLabel = entityTranslationMap.get(scope)?.toLowerCase() ?? scope;

    const permission: IPermissionModel = {
        name: key,
        label: getLabel(scopeLabel),
        value: permissionData.accessType ?? PermissionAccess.NotSet,
        readonly: permissionData.isSystem,
    };

    return [true, scope, permission];
};

/**
 * extract the groups and split them into scope
 * @param groupPermissionsData the group permission back end data.
 * @returns a GroupPermissionModel array containing the permissions split by scope
 */
const extractGroupPermission = (groupPermissionsData: IGroupPermissionDataModel[]): IGroupPermissionModel[] => {
    // takes in an array of group permission data, formats it and groups it by scope.
    return groupPermissionsData.reduce<IGroupPermissionModel[]>((acc, gpd) => {
        // format the permission and extract the scope
        const [valid, scope, permission] = extractPermission(gpd);

        if (!valid) {
            return acc;
        }

        // search the final object (acc) for the current scope
        let groupPermission = acc.find((p) => p.scope === scope);
        if (groupPermission) {
            groupPermission.permissions.push(permission);
        } else {
            // if the scope object doesn't exist yet, create it and push in the final object (acc)
            groupPermission = {
                scope,
                label: entityTranslationMap.get(scope) ?? scope,
                permissions: [permission],
            };

            acc.push(groupPermission);
        }

        return acc;
    }, []);
};

const serializeGroupPermission = (groupPermissions: IGroupPermissionModel[]): IGroupPermissionDataModel[] => {
    return groupPermissions.reduce<IGroupPermissionDataModel[]>((acc, { scope, permissions }) => {
        return [
            ...acc,
            ...permissions.map<IGroupPermissionDataModel>(({ value, readonly, name }) => {
                return {
                    accessType: value === PermissionAccess.NotSet ? null : value,
                    isSystem: readonly,
                    permission: `${scope}/${name}`,
                };
            }),
        ];
    }, []);
};

export const useGroupPermissionData = (): IUseGroupPermissionDataResult => {
    return { extract: extractGroupPermission, serialize: serializeGroupPermission };
};
