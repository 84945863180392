import React, { useCallback, useEffect, useState } from "react";
import { IRecentProjectModel } from "../../models/project/IRecentProjectModel";
import { SidebarProjectRecentItems } from "./SidebarProjectRecentItems";
import { useProjectApi } from "../../hooks/project/ProjectApiHook";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

import "./SidebarProjectItem.scss";

/**
 * The sidebar project item props interface.
 */
interface ISidebarProjectItemProps {
    projectId: string;
    projectName: string;
    organizationName: string;
    isCollapsed?: boolean;
}

const recentProjetsToLoad = 5;

/**
 * The sidebar project item component.
 */
export const SidebarProjectItem: React.FunctionComponent<ISidebarProjectItemProps> = ({
    projectId,
    projectName,
    organizationName,
    isCollapsed,
}) => {
    const { getRecentProjects } = useProjectApi();
    const [recentProjects, setRecentProjects] = useState<IRecentProjectModel[]>([]);

    const initializeRecentProjects = useCallback(async () => {
        try {
            const projects = await getRecentProjects(recentProjetsToLoad, projectId);
            setRecentProjects(projects);
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [projectId, getRecentProjects]);

    useEffect(() => {
        if (!projectId) {
            return;
        }

        initializeRecentProjects();
    }, [initializeRecentProjects, projectId]);

    return (
        <div className="sidebar-project-item">
            <SidebarProjectRecentItems items={recentProjects} projectName={projectName} disabled={!isCollapsed} />
            <div className="sidebar-item-info sidebar-project-item-info">
                <div className="project-name text-truncate">{projectName}</div>
                <div className="organization-name text-truncate">{organizationName}</div>
            </div>
            <SidebarProjectRecentItems items={recentProjects} />
        </div>
    );
};
