import { createContext, useContext } from "react";
import { useDialog } from "../hooks/dialog/Dialog";

export type DialogContextProp = Pick<ReturnType<typeof useDialog>, "show" | "dialogState" | "close">;

/**
 * The dialog context.
 */
export const DialogContext = createContext<DialogContextProp | null>(null);

/**
 * The use dialog context for functional component.
 */
export const useDialogContext = (): DialogContextProp => useContext(DialogContext) as DialogContextProp;
