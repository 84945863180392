import React, { useState } from "react";
import { ShortcutContext } from "../../contexts/ShortcutContext";

interface IShortcutProviderProps {
    children?: React.ReactNode;
}

/**
 * The Shortcut Provider component.
 */
export const ShortcutProvider: React.FC<IShortcutProviderProps> = (props: IShortcutProviderProps) => {
    const [enabled, setEnabled] = useState(true);
    return (
        <>
            <ShortcutContext.Provider value={{ globalShortcutEnabled: enabled, setGlobalShortcutEnabled: setEnabled }}>
                {props.children}
            </ShortcutContext.Provider>
        </>
    );
};
