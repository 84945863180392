import { useCallback, useState } from "react";

export type EntityError<TEntity> = {
    [key in keyof TEntity]?: string | JSX.Element;
};

export interface IEntityErrorsProps<TEntity> {
    initial: EntityError<TEntity>;
}

export type SetError<TErrors> = <TErrorKey extends keyof TErrors>(name: TErrorKey, value: TErrors[TErrorKey]) => void;

export interface IEntityErrorsResult<TErrors> {
    errors: TErrors;
    setError: SetError<TErrors>;
}

export const useEntityErrors = <TEntity>(
    props?: IEntityErrorsProps<TEntity>,
): IEntityErrorsResult<EntityError<TEntity>> => {
    const [errors, setErrors] = useState<EntityError<TEntity>>(props?.initial ?? {});
    const setError = useCallback<SetError<EntityError<TEntity>>>((name, value) => {
        setErrors((oldErrors) => ({ ...oldErrors, [name]: value }));
    }, []);

    return {
        errors,
        setError,
    };
};
