import { useMediaQuery } from "react-responsive";

const maxMobileWidth: number = 576;

/**
 * The mobile media query hook
 */
export const useMobileMediaQuery = (): boolean => {
    const isMobile = useMediaQuery({ maxWidth: maxMobileWidth });

    return isMobile;
};
