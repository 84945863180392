import React from "react";
import { useClonableDropdownMenuItems } from "../../hooks/dropdownMenuItems/ClonableDropDownMenuitems";
import { useCloneTemplate } from "../../hooks/template/CloneTemplateHook";
import { EntityDropdownMenu } from "../dropdowns/EntityDropdownMenu";

interface IEditTemplateCustomDropdownMenuProps {
    canDelete: boolean;
    canEdit: boolean;
}

export const EditTemplateCustomDropdownMenu: React.FC<IEditTemplateCustomDropdownMenuProps> = ({
    canDelete,
    canEdit,
}) => {
    const { editCloneTemplate } = useCloneTemplate();

    const items = useClonableDropdownMenuItems({ onCloneClick: editCloneTemplate, canDelete, canEdit });

    return <EntityDropdownMenu items={items} testSelectorValue="entityActionsMenu" />;
};
