import { useEffect, useMemo, useState } from "react";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { ITranslateFormFields } from "../../models/contentManager/ITranslateFormFields";
import { TranslateFormValidator } from "../../formValidators/TranslateFormValidator";

interface ITranslateValidationProps {
    fields: ITranslateFormFields;
}

export const useTranslateValidation = ({ fields }: ITranslateValidationProps) => {
    const { errors, setError } = useEntityErrors<ITranslateFormFields>();
    const [valid, setValid] = useState(false);

    const formValidator = useMemo(() => {
        return new TranslateFormValidator();
    }, []);

    useEffect(() => {
        formValidator
            .validateLanguage(fields.language)
            .then(() => setError("language", ""))
            .catch((err: Error) => setError("language", extractErrorMessageOrEmptyString(err)));
    }, [fields.language, formValidator, setError]);

    useEffect(() => {
        formValidator.isValid({ language: fields.language }).then(setValid);
    }, [fields.language, formValidator]);

    return {
        valid,
        errors,
        validationStatuses: {},
        setError,
    };
};
