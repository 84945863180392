import React, { useMemo } from "react";
import { useCallback, useEffect, useRef } from "react";
import { useDialogContext } from "../../contexts/DialogContext";
import { useNavigationBlockerContext } from "../../contexts/NavigationBlockerContext";
import { ConfirmationDialogBody } from "../../components/dialogs/ConfirmationDialogBody";

/**
 * The navigation prompt dialog props interface.;
 */
interface INavigationPromptDialogProps {
    componentId: string;
    messageKey: string;
    titleKey?: string;
    isDirty: boolean;
    isLeaving?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

/**
 * The navigation prompt dialog component.
 */
export const useNavigationPromptDialog = ({
    isDirty,
    messageKey,
    titleKey,
    onConfirm,
    onCancel,
    isLeaving,
    componentId,
}: INavigationPromptDialogProps): void => {
    const { show: showDialog } = useDialogContext();

    const { setShouldBlock } = useNavigationBlockerContext();

    const unblockRef = useRef<() => void>();

    useEffect(() => {
        unblockRef.current = setShouldBlock({ componentId, shouldBlock: isDirty, messageKey, onConfirm, onCancel });
    }, [componentId, isDirty, messageKey, onCancel, onConfirm, setShouldBlock]);

    useEffect(() => {
        return unblockRef.current && unblockRef.current();
    }, []);

    const content = useMemo(() => <ConfirmationDialogBody messageKeys={[messageKey]} />, [messageKey]);

    const showDirtyDialog = useCallback(() => {
        showDialog({
            type: "Warning",
            titleKey: titleKey,
            content,
            onProceed: () => {
                onConfirm && onConfirm();
                return Promise.resolve();
            },
            onClose: () => {
                onCancel && onCancel();
            },
        });
    }, [content, onCancel, onConfirm, showDialog, titleKey]);

    const showDirtyDialogRef = useRef(showDirtyDialog);

    useEffect(() => {
        showDirtyDialogRef.current = showDirtyDialog;
    }, [showDirtyDialog]);

    useEffect(() => {
        if (isLeaving && isDirty) {
            showDirtyDialogRef.current();
        }
    }, [isLeaving, isDirty]);
};
