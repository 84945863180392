import React from "react";
import Moment from "react-moment";
import "moment/min/locales";
import { useUserContext } from "../../contexts/UserContext";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

interface ILocaleDateProps {
    date: string | moment.Moment;
    format?: keyof typeof formatTranslationMap;
    className?: string;
    utc?: boolean;
}

const formatTranslationMap = {
    Date: "L",
    DateTime: "L LTS",
    DateWithTimezone: "L [(UTC]Z[)]",
} as const;

export const FormattedDate: React.FC<ILocaleDateProps> = ({ date, format = "Date", className, utc = false }) => {
    const { locale } = useUserContext();
    const { setSelector } = useTestSelector();

    return (
        <Moment
            format={formatTranslationMap[format]}
            locale={locale}
            className={className}
            utc={utc}
            {...setSelector("date")}
        >
            {date}
        </Moment>
    );
};
