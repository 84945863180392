import React from "react";
import { useTranslation } from "react-i18next";

interface INavigationPromptDialogBodyProps {
    messageKeys: string[];
}

export const ConfirmationDialogBody: React.FC<INavigationPromptDialogBodyProps> = ({ messageKeys }) => {
    const { t } = useTranslation();

    return (
        <>
            {messageKeys.map((message) => (
                <p key={message}>{t(message)}</p>
            ))}
            <p>{t("Common.ShouldProceed")}</p>
        </>
    );
};
