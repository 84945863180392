import React from "react";
import classNames from "classnames";
import { useTableContext } from "./TableContext";
import { ITableRowItem } from "./TableRow";
import { LoadMore } from "../loadMore/LoadMore";

/**
 * The table footer component.
 */
export const TableFooter: React.FC<{}> = <
    TEntityModel,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>(): JSX.Element => {
    const context = useTableContext<TEntityModel, TRowItem, TFilterValue>();

    if (!context) return <></>;

    const { tableState, columnDefs, canLoadMore, className, onLoadMore, actionProvider, selectable } = context;

    if (tableState.items.length === 0) return <></>;

    let columnCount = columnDefs.length;
    if (actionProvider) columnCount++;
    if (selectable) columnCount++;

    if (canLoadMore && !tableState.isLoading) {
        return (
            <tfoot className={classNames(className, "text-center")}>
                <tr>
                    <td colSpan={columnCount}>
                        <LoadMore onLoadMore={onLoadMore} />
                    </td>
                </tr>
            </tfoot>
        );
    }

    return <></>;
};
