import React from "react";
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./FilterDropdownCheckbox.scss";

/**
 * The filter dropdown checkbox props interface.
 */
interface IFilterDropdownCheckboxProps extends React.PropsWithChildren<{}> {
    canClear: boolean;
    label: JSX.Element | string;
    testSelectorValueFilterName?: string;
    onClear: () => void;
}

/**
 * The filter dropdown checkbox function component.
 */
export const FilterDropdownCheckbox: React.FC<IFilterDropdownCheckboxProps> = ({
    canClear,
    children,
    label,
    testSelectorValueFilterName,
    onClear,
}: IFilterDropdownCheckboxProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const labelValue = typeof label === "string" ? t(label) : label;

    return (
        <>
            <span
                className="filter-dropdown"
                {...setSelector(testSelectorValueFilterName ? `${testSelectorValueFilterName}Filter` : "")}
            >
                <UncontrolledDropdown addonType="append" data-toggle="dropdown">
                    <DropdownToggle className="toggle" color="" size="md" {...setSelector("filterToggle")}>
                        {labelValue}
                        <div className="icon-wrapper">
                            <i className="far fa-chevron-down" />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu flip={false} end={true} className="dropdown-wrapper">
                        {children}
                        <DropdownItem
                            className="clear-dropdown-filter"
                            toggle={false}
                            {...setSelector("clearButton")}
                            onClick={onClear}
                            disabled={!canClear}
                        >
                            <i className="fal fa-times" />
                            {t("Common.Clear")}
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </span>
        </>
    );
};
