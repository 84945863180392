import React, { useEffect } from "react";
import classNames from "classnames";
import { FormGroup } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../../buttons/Button";
import { Card } from "../../card/Card";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { FormattedDate } from "../../date/FormattedDate";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { OrganizationFormValidator } from "../../../formValidators/OrganizationFormValidator";
import {
    IOrganizationModel,
    OrganizationLicenseState,
    OrganizationLicenseStateTranslationMap,
} from "../../../models/organization/IOrganizationModel";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { InputWithCopy } from "../../forms/inputs/InputWithCopy";
import { ReadOnlyInputWithCopy } from "../../forms/inputs/ReadOnlyInputWithCopy";
import { useLicenseActivation } from "../../../hooks/organization/LicenseActivationHook";

import "./EditOrganizationViewTabsLicense.scss";

const getLicenseStateClassname = (licenseState: OrganizationLicenseState) => {
    switch (licenseState) {
        case OrganizationLicenseState.Expired:
        case OrganizationLicenseState.Inactive:
            return "invalid";
        case OrganizationLicenseState.Active:
            return "active";
        default:
            return "";
    }
};

export const EditOrganizationViewTabsLicense = () => {
    const { t } = useTranslation();
    const {
        entityProps: { entity: organization, formValidator, setEntityProperties },
        permissionsProps: { canEdit },
        errorProps: {
            setErrors,
            errors: { licenseKey: activationKeyError },
        },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();

    const { isActivating, tryActivateLicense } = useLicenseActivation();

    useEffect(() => {
        let cancel = false;
        const checkLicenseKey = async () => {
            let errorMessage: string;

            try {
                await formValidator.validateActivationKey(organization.licenseKey);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            if (!cancel) {
                setErrors({ licenseKey: errorMessage });
            }
        };

        void checkLicenseKey();

        return () => {
            cancel = true;
        };
    }, [formValidator, organization.licenseKey, setErrors]);

    const onActivationKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEntityProperties({ licenseKey: event.target.value });
    };

    return (
        <Card
            headerContent={
                <Trans
                    i18nKey={"License.State"}
                    components={{
                        LicenseState: (
                            <span className={`license-state ${getLicenseStateClassname(organization.licenseState)}`} />
                        ),
                    }}
                    values={{ licenseState: t(OrganizationLicenseStateTranslationMap[organization.licenseState]) }}
                />
            }
            className="entity-license"
        >
            <FormGroup className="entity-license__body">
                <div>
                    <ReadOnlyInputWithCopy
                        label="Organization.OrganizationId"
                        displayedValueWhileCopying={"Organization.OrganizationId.Copied"}
                        value={organization.organizationId}
                    />
                </div>
                <FormGroup>
                    <LocalizedLabel for="edit-organization-license-key" text="Licensing.ActivationKey" />
                    <div className="entity-license__activate">
                        <div className="entity-license__activate__input">
                            <InputWithCopy
                                id="edit-organization-license-key"
                                name="license-key"
                                type="textfield"
                                onChange={onActivationKeyChange}
                                error={activationKeyError}
                                value={organization.licenseKey ?? ""}
                                disabled={!canEdit}
                                displayedValueWhileCopying="Licensing.ActivationKeyCopied"
                            />
                        </div>
                        <Button
                            ariaLabel="activate-license-key"
                            color="secondary"
                            outline
                            disabled={!!activationKeyError || !organization.licenseKey}
                            onClick={tryActivateLicense}
                            loading={isActivating}
                            className="btn-activate"
                        >
                            {t("Common.Activate")}
                        </Button>
                    </div>
                </FormGroup>
            </FormGroup>
            {organization.licenseState !== OrganizationLicenseState.None && (
                <FormGroup className="entity-license__footer">
                    <div>
                        {organization.licenseActivatedDate && (
                            <Trans
                                i18nKey={"License.LastActivated"}
                                components={{
                                    LastActivatedDate: (
                                        <FormattedDate date={organization.licenseActivatedDate} format="DateTime" />
                                    ),
                                }}
                            />
                        )}
                    </div>
                    <div
                        className={classNames("entity-license__footer__date", {
                            invalid: organization.licenseState === OrganizationLicenseState.Expired,
                        })}
                    >
                        {organization.licenseExpirationDate && (
                            <Trans
                                i18nKey={"License.ExpirationDate"}
                                components={{
                                    ExpirationDate: (
                                        <FormattedDate date={organization.licenseExpirationDate} format="Date" utc />
                                    ),
                                }}
                            />
                        )}
                    </div>
                </FormGroup>
            )}
        </Card>
    );
};
