import React, { useEffect, useRef } from "react";
import { Form } from "reactstrap";
import { SearchInput } from "./SearchInput";

import "./SearchNavbarItem.scss";

/**
 * The search navbar panel props interface.
 */
interface ISearchNavbarPanelProps {
    isVisible: boolean;
    searchScopeKey: string;
    searchValue: string;
    onBlur: () => void;
    onValueChange: (value: string) => void;
}

/**
 * The search navbar panel functional component.
 */
export const SearchNavbarPanel: React.FC<ISearchNavbarPanelProps> = ({
    isVisible,
    searchScopeKey,
    searchValue,
    onBlur,
    onValueChange,
}: ISearchNavbarPanelProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (isVisible) {
            inputRef.current?.focus();
        } else {
            onValueChange("");
        }
    }, [isVisible, inputRef, onValueChange]);

    return (
        <>
            {isVisible && (
                <Form className="form-inline navbar-search-light" onSubmit={(event) => event.preventDefault()}>
                    <div className="search-panel">
                        <SearchInput
                            searchScope={searchScopeKey}
                            value={searchValue}
                            onValueChange={onValueChange}
                            onBlur={onBlur}
                        />
                    </div>
                </Form>
            )}
        </>
    );
};
