import React, { useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { FormGroup } from "reactstrap";
import { useAddMemberValidation } from "./AddMemberValidation";
import { IAddMemberFields } from "../../../../formValidators/AddMemberFormValidator";
import { defaultRequestErrorHandler } from "../../../../helpers/ErrorHelper";
import { useCreateModalEntity } from "../../../../hooks/CreateModalEntityHook";
import { useManageUsers } from "../../../../hooks/user/ManageUsersHook";
import { IEntityFormData } from "../../../../models/entity/form/IEntityFormData";
import { EntityForm } from "../../../forms/EntityForm";
import { SelectComponentOptions } from "../../../selects/SelectComponent";
import { FormFeedbackError } from "../../../forms/feedbacks/FormFeedbackError";
import { SelectMembers } from "../../../selects/SelectMembers";

interface PermissionsFormProps {
    initialValue: IAddMemberFields;
    onFormChange: (formData: IEntityFormData<IAddMemberFields>) => void;
    organizationId?: string;
    projectId?: string;
    groupId: string;
}

export const AddMemberForm: React.FC<PermissionsFormProps> = ({
    initialValue,
    groupId,
    organizationId,
    projectId,
    onFormChange,
}) => {
    const [listedUsers, setListedUsers] = useState<SelectComponentOptions<string>[]>([]);
    const { getAllNonMembers } = useManageUsers({ organizationId, projectId });

    const {
        data: { userIds },
        errors,
        onChange,
    } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: useAddMemberValidation,
        isDirty: (data) => data.userIds.length > 0,
    });

    const memberFieldChangeHandler = useCallback(
        (val: string[]) => {
            onChange("userIds", val);
        },
        [onChange],
    );

    const getListedUsers = useCallback(async () => {
        try {
            const availableUsers = await getAllNonMembers(groupId);
            setListedUsers(
                availableUsers.map((user) => {
                    return {
                        label: user.email,
                        value: user.userId,
                        data: user,
                    };
                }),
            );
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [groupId, getAllNonMembers]);

    useEffect(() => {
        getListedUsers();
    }, [getListedUsers]);

    return (
        <EntityForm>
            <FormGroup>
                <SelectMembers
                    className={classNames({ "is-invalid": !!errors.userIds })}
                    value={userIds}
                    onChange={memberFieldChangeHandler}
                    options={listedUsers}
                    labelKey="Common.Users"
                    labelRequired
                />
                {errors.userIds && <FormFeedbackError error={errors.userIds} />}
            </FormGroup>
        </EntityForm>
    );
};
