import React from "react";
import classNames from "classnames";

import "./View.scss";

/**
 * The entity view props interface.
 */
interface IEntityViewProps {
    className?: string;
    fullscreen?: boolean;
    children?: React.ReactNode;
}

/**
 * The entity view component.
 */
export const EntityView: React.FunctionComponent<IEntityViewProps> = (props: IEntityViewProps) => {
    const { className, children, fullscreen } = props;

    return <section className={classNames("entity-view", className, { fullscreen })}>{children}</section>;
};