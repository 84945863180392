import React from "react";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { ConnectDropTarget } from "react-dnd";
import { Button } from "../buttons/Button";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./TableNoResultCTA.scss";

interface ITableNoResultCTAProps {
    logo?: {
        imgSrc: string;
        imgAlt: string;
    };
    title: string;
    description: string;
    keepTableHeader?: boolean;
    noCreateAction?: boolean;
    createTitle?: string;
    onClick?: (() => void) | null;
    dropRef?: ConnectDropTarget;
    className?: string;
}

export const TableNoResultCTA: React.FC<ITableNoResultCTAProps> = ({
    logo,
    title,
    description,
    createTitle,
    onClick,
    noCreateAction,
    dropRef,
    className,
}: ITableNoResultCTAProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <div className={classNames("table-no-result-cta-wrapper", className)} ref={dropRef}>
            <div className="table-no-result-cta-box" {...setSelector("tableNoResultCTADiv")}>
                {logo && (
                    <div className="table-no-result-cta-left">
                        <img alt={logo?.imgAlt} className="navbar-brand-img" src={logo?.imgSrc} />
                    </div>
                )}
                <div className={classNames("table-no-result-cta-right", !logo && "no-logo")}>
                    <div className="table-no-result-cta-top-right">
                        <Trans i18nKey={title} />
                    </div>
                    <div className="table-no-result-cta-middle-right">
                        <Trans i18nKey={description} />
                    </div>
                    <div className="table-no-result-cta-bottom-right">
                        {onClick && !noCreateAction && (
                            <Button
                                ariaLabel={t(createTitle ?? "Common.Create")}
                                icon="fas fa-plus"
                                color="primary"
                                className="table-no-result-cta"
                                {...setSelector("createButton")}
                                onClick={onClick}
                            >
                                {t(createTitle ?? "Common.Create")}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
