export const availableColors = [
    "#94B8EA",
    "#6A9CE2",
    "#2f75d6",
    "#1d5095",
    "#15396b",
    "#be97e8",
    "#a56ddf",
    "#6d28b5",
    "#582092",
    "#3f1768",
    "#e39cdb",
    "#d874cd",
    "#b132a3",
    "#8b2780",
    "#631c5c",
    "#f18dbb",
    "#ec60a0",
    "#e83e8C",
    "#cd186b",
    "#9f1353",
    "#ed5e5e",
    "#e83030",
    "#c81616",
    "#a11212",
    "#730d0d",
    "#fd9e4e",
    "#fd7e14",
    "#e46902",
    "#b15102",
    "#ffd24d",
    "#ffc107",
    "#b38600",
    "#4ccc91",
    "#31ac73",
    "#288b5d",
    "#1c6342",
    "#39dfd5",
    "#20c9be",
    "#199a92",
    "#126e68",
    "#33cde5",
    "#17a2b8",
    "#148c9f",
    "#0e6471",
];
