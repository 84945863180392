import React from "react";
import { useDefaultDropdownMenuItems } from "../../hooks/dropdownMenuItems/DefaultDropdownMenuItems";
import { EntityDropdownMenu, IEntityDropdownMenuItem } from "./EntityDropdownMenu";

interface IDefaultEditDropdownMenuProps {
    canDelete: boolean;
}

export const DefaultEditDropdownMenu: React.FC<IDefaultEditDropdownMenuProps> = ({ canDelete }) => {
    const items: IEntityDropdownMenuItem[] = useDefaultDropdownMenuItems(canDelete);

    return <EntityDropdownMenu items={items} testSelectorValue="entityActionsMenu" />;
};
