import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { UserAvatar } from "../userGroups/UserAvatar";
import { EntityType } from "../../models/EntityType";
import { FormattedDate } from "../date/FormattedDate";
import { ActivityModel, IMoveActivityModel } from "../../models/history/ActivityModel";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./ActivityListItem.scss";

interface IActivityListItemProps {
    activity: ActivityModel;
    entityType: EntityType | "File";
}

const activityEventTypeLookup = {
    move: "Move",
    create: "Create",
    update: "Update",
};

const isMoveActivity = (activity: ActivityModel): activity is IMoveActivityModel =>
    (activity as IMoveActivityModel).eventType === "move";

export const ActivityListItem: React.FC<IActivityListItemProps> = ({ activity, entityType }) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <div className="activity-item" { ...setSelector("activityItem") }>
            <UserAvatar
                name={activity.userIdentification.displayName}
                initials={activity.userIdentification.initials}
            />
            <div className="activity-item__info">
                <div>
                    <Trans
                        i18nKey={`History.${entityType}.${activityEventTypeLookup[activity.eventType]}Event`}
                        values={{
                            userIdentification: activity.userIdentification.displayName,
                            source: isMoveActivity(activity) && (activity.source || t("ContentManager.Files")),
                            destination:
                                isMoveActivity(activity) && (activity.destination || t("ContentManager.Files")),
                        }}
                    />
                </div>
                <div>
                    <FormattedDate date={activity.eventDate} format="DateTime" />
                </div>
            </div>
        </div>
    );
};
