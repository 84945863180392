import React from "react";
import classNames from "classnames";
import { MultiValueGenericProps, GroupBase, MultiValueProps, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

interface IMultiValueLabelProps
    extends MultiValueGenericProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    data: SelectComponentOptions<any>;
    customMultiValueLabel?: (props: MultiValueProps) => JSX.Element | null;
}

export const MultiValueLabel: React.FC<IMultiValueLabelProps> = ({ customMultiValueLabel, ...props }) => {
    const { setSelector } = useTestSelector();
    const { data } = props;

    return (
        <components.MultiValueLabel {...props}>
            {customMultiValueLabel ? (
                customMultiValueLabel(props as MultiValueProps)
            ) : (
                <div className={classNames("multi-value-label", data.className)}>
                    {data.icon && <i className={data.icon} />}
                    {!!data.componentIcon && data.componentIcon}
                    <span {...setSelector("selectedItemLabel")}>{data.label}</span>
                </div>
            )}
        </components.MultiValueLabel>
    );
};
