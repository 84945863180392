import { useCallback, useReducer } from "react";
import { dialogReducer, IShowProps, IDialogState, initialDialogState } from "./DialogReducer";

/**
 * The use dialog result interface.
 */
interface IUseDialogResult {
    dialogState: Omit<IDialogState, "close" | "closed" | "proceed">;
    close: () => void;
    closed: () => void;
    proceed: () => Promise<void>;
    show: (props: IShowProps) => void;
}

/**
 * The use dialog hook.
 */
export const useDialog = (): IUseDialogResult => {
    const [dialogState, dispatch] = useReducer(dialogReducer, initialDialogState);

    const show = ({
        content,
        messageKey,
        titleKey,
        cancelLabelKey,
        proceedLabelKey,
        type,
        onClose,
        onClosed,
        onProceed,
        hideCancel,
        contentOverwriteModal,
        modalClassName,
        size,
    }: IShowProps) => {
        dispatch({
            type: "SHOW_DIALOG",
            payload: {
                type,
                titleKey,
                content,
                messageKey,
                proceedLabelKey,
                cancelLabelKey,
                onClosed,
                onClose,
                onProceed,
                hideCancel,
                contentOverwriteModal,
                modalClassName,
                size,
            },
        });
    };

    const { close: stateClose, closed: stateClosed, proceed: stateProceed } = dialogState;

    const close = useCallback(() => {
        if (stateClose) {
            stateClose();
        }
        dispatch({ type: "ON_CLOSE_DIALOG" });
    }, [stateClose]);

    const closed = useCallback(() => {
        if (stateClosed) {
            stateClosed();
        }
        dispatch({ type: "ON_CLOSED_DIALOG" });
    }, [stateClosed]);

    const proceed = useCallback(async () => {
        if (stateProceed) {
            await stateProceed();
        }
        dispatch({ type: "ON_CLOSE_DIALOG" });
    }, [stateProceed]);

    return {
        dialogState,
        proceed,
        close,
        closed,
        show,
    };
};
