import React from "react";
import * as Yup from "yup";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

export interface IAddMemberFields {
    userIds: string[];
}

export class AddMemberFormValidator extends FormValidator<IAddMemberFields> {
    protected schema = Yup.object().shape({
        userIds: Yup.array().min(1, () => <ValidatorError messageKey="NameValidator.Required" />),
    });

    public validateUsers(userIds: string[]): Promise<(string | undefined)[] | undefined> {
        return this.schema.fields.userIds.validate(userIds);
    }
}
