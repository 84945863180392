import { createContext, useContext } from "react";
import { IPanelState } from "../components/sidePanel/SidePanelProvider";

export interface IPanelContextProps {
    setSidePanelState: (initialState: IPanelState | ((prevState: IPanelState) => IPanelState)) => void;
    sidePanelState: IPanelState;
    isDisplayed: boolean;
    showSidePanel: () => void;
    closeSidePanel: (forceClose?: boolean) => void;
}

export const SidePanelContext = createContext<IPanelContextProps>(null!);

export const useSidePanelContext = (): IPanelContextProps => useContext(SidePanelContext);
