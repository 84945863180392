import React, { useEffect, useState } from "react";
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ai } from "./AppInsights";
import { useAppConfigContext } from "./contexts/AppConfigContext";

/**
 * The app insights tracking props interface.
 */
interface IAppInsighsTrackingProps {
    children?: React.ReactNode;
}

/**
 * The App Insights Tracking component.
 */
export const AppInsightsTracking: React.FC<IAppInsighsTrackingProps> = (props: IAppInsighsTrackingProps) => {
    const [reactPlugin, setReactPlugin] = useState(() => new ReactPlugin());
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    const { appInsightConnectionString } = useAppConfigContext();

    useEffect(() => {
        if (isInitialized || !appInsightConnectionString) return;

        const rp = ai.initialize(appInsightConnectionString);
        setReactPlugin(rp);
        setIsInitialized(true);
    }, [isInitialized, appInsightConnectionString]);

    return (
        <AppInsightsErrorBoundary onError={() => <></>} appInsights={reactPlugin}>
            <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>
        </AppInsightsErrorBoundary>
    );
};
