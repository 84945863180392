import React from "react";
import { Alert, Badge, FormGroup, Input, Label } from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import ExperlogixDocumentsIcon from "../../assets/img/icons/ExperlogixDocumentsText.svg";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";

import "./PreviewConsentForm.scss";

interface IPreviewConsentFormProps {
    onFormChange: (formData: IEntityFormData<{ consent: boolean }>) => void;
}

export const PreviewConsentForm: React.FC<IPreviewConsentFormProps> = ({ onFormChange }) => {
    const { t } = useTranslation();

    const { data, onChange } = useCreateModalEntity({
        initialData: {
            consent: false,
        },
        onFormChange,
        useValidation: ({ consent }) => ({
            valid: consent,
            errors: {},
            validationStatuses: {},
            setError: () => {
                return;
            },
        }),
        isDirty: () => false,
    });

    return (
        <>
            <div className="preview-consent-header">
                <img
                    alt={"Experlogix Documents Logo"}
                    className="preview-consent-header__logo"
                    src={ExperlogixDocumentsIcon}
                />
                <Badge color="primary">Preview</Badge>
            </div>
            <Alert color="danger" className="preview-disclaimer">
                <h2 className="preview-disclaimer__title">{t("Preview.Consent.Title")}</h2>
                <p>
                    <Trans i18nKey="Preview.Consent.Disclaimer" />
                </p>
            </Alert>
            <FormGroup check>
                <Input
                    type="checkbox"
                    checked={data.consent}
                    onChange={(e) => onChange("consent", e.target.checked)}
                    id="consent-checkbox"
                />
                <Label check for="consent-checkbox">
                    {t("Preview.Consent.Understand")}
                </Label>
            </FormGroup>
        </>
    );
};
