import classNames from "classnames";
import React from "react";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { AutosizeInput } from "../forms/inputs/AutosizeInput";
import { LocalizedInput } from "../forms/inputs/LocalizedInput";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { StatusIcon } from "../forms/feedbacks/StatusIcon";
import { FormFeedbackError } from "../forms/feedbacks/FormFeedbackError";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";

import "./EditEntityName.scss";

interface IEditEntityNameProps {
    staticDisplay?: boolean;
}

/**
 * The edit entity name component with context.
 */
export const EditEntityName: React.FC<IEditEntityNameProps> = ({ staticDisplay }): JSX.Element => {
    const {
        validateNameProps: { checkNameIsUnique, isValidatingName },
        dirtyProps: { isDirty },
        entityProps: { entity, setEntityProperties, initialEntity },
        errorProps: {
            errors: { name: nameErrorMessage },
        },
        permissionsProps: { canEdit },
    } = useEditEntityContext();

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEntityProperties({ name: event.target.value });
        checkNameIsUnique(event.target.value);
    };

    const onNameFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    const isInvalid = !isValidatingName && !!nameErrorMessage;
    const showValidationIcon = isDirty(["name"]) && !nameErrorMessage;
    const showError = !isValidatingName && nameErrorMessage;
    const { setSelector } = useTestSelector();

    if (staticDisplay || !canEdit) {
        return <SectionHeader.Title>{initialEntity.name}</SectionHeader.Title>;
    }

    return (
        <div className={classNames("entity-name", { "is-invalid": isInvalid })}>
            <div className="entity-name-inner">
                <LocalizedLabel for="edit-name" text="Common.Name" hidden />
                <LocalizedInput
                    id="edit-name"
                    placeholder={"Common.Name"}
                    autoComplete="off"
                    tag={AutosizeInput}
                    invalid={isInvalid}
                    value={entity.name}
                    type="text"
                    name="name"
                    className="text-truncate"
                    onChange={onNameChange}
                    onFocus={onNameFocus}
                    {...setSelector("nameTextbox")}
                />
                <div className="edit-icon">
                    <i className="fas fa-pencil" />
                </div>
                {showValidationIcon && <StatusIcon isValidating={isValidatingName} />}
            </div>
            {showError && <FormFeedbackError error={nameErrorMessage} />}
        </div>
    );
};
