import React, { useCallback } from "react";
import { PermissionHubContext } from "../../contexts/PermissionContext";
import { usePermissionManager } from "../../hooks/permission/PermissionManagerHook";
import { PermissionState } from "../../hooks/permission/PermissionHook";

/**
 * The permissions hub provider interface.
 */
interface IPermissionHubProviderProps extends React.PropsWithChildren<{}> {}

/**
 * The permission hub provider.
 */
export const PermissionHubProvider: React.FC<IPermissionHubProviderProps> = ({
    children,
}: IPermissionHubProviderProps) => {
    const manager = usePermissionManager();
    const onHandler = useCallback(
        (permission: string, callback: (state: PermissionState) => void): (() => void) => {
            return manager.onChange(permission, callback);
        },
        [manager],
    );

    const getHandler = useCallback(
        (permission: string): PermissionState => {
            return manager.get(permission);
        },
        [manager],
    );

    return (
        <PermissionHubContext.Provider value={{ on: onHandler, get: getHandler }}>
            {children}
        </PermissionHubContext.Provider>
    );
};
