import { useCallback } from "react";
import * as _ from "lodash";
import { IContentSummarizeModel } from "../../models/contentManager/ContentManagerApiModels";
import { useContentEntityApi } from "./ContentEntityApiHook";
import { useAppConfigContext } from "../../contexts/AppConfigContext";
import { usePromisePool } from "../PromisePoolHook";
import { NotificationService } from "../../services/NotificationService";
import { extractErrorMessageAsHtml } from "../../helpers/ErrorHelper";
import { InvalidCharacterRegex } from "../../helpers/InvalidCharacterHelper";
import { createFailedFileUploadResult } from "../../helpers/ContentFileHelper";
import { BulkActionSummaryCancelResult } from "../../models/BulkOperationModel";
import { createCancelledActionsResult } from "../../helpers/BulkActionHelper";

const isPathSectionInvalid = (pathSection: string) => !InvalidCharacterRegex.test(pathSection);

const onUploadFinished = (
    completedUploads: BulkActionSummaryCancelResult<IContentSummarizeModel>[],
    errors: unknown[],
    remainingObjects: File[],
) => {
    const summary: BulkActionSummaryCancelResult<IContentSummarizeModel> = {
        errorCount: 0,
        errorMessages: [],
        payload: {
            affectedFolders: [],
            filesUploadContentLength: 0,
        },
        successCount: 0,
        cancelled: false,
    };

    // Add all remaining objects as an error with cancellable set to true.
    remainingObjects.forEach(() => {
        completedUploads.push(
            createCancelledActionsResult({
                affectedFolders: [],
                filesUploadContentLength: 0,
            }),
        );
    });

    errors.forEach((e) => {
        completedUploads.push(createFailedFileUploadResult(e));
    });

    completedUploads.forEach((cu) => {
        if (cu.successCount === 1) {
            summary.successCount++;
        } else {
            summary.errorCount++;
        }

        if (cu.errorMessages && cu.errorMessages.length === 1) {
            summary.errorMessages.push(cu.errorMessages[0]);
        }

        summary.payload.affectedFolders.push(...cu.payload.affectedFolders);
        summary.payload.filesUploadContentLength += cu.payload.filesUploadContentLength;

        // if at least 1 result has cancelled to true, it means the upload was cancelled.
        if (cu.cancelled) {
            summary.cancelled = true;
        }
    });

    summary.payload.affectedFolders = _.uniqBy(summary.payload.affectedFolders, "contentFolderId");

    return summary;
};

export const useUpload = (projectId: string) => {
    const { maxSimultaneousUploadRequest, maxFolderDepth, fileUploadMaxRequestBodySizeInBytes } = useAppConfigContext();
    const { upload } = useContentEntityApi({
        projectId,
        checkParentExistsOnError: true,
    });

    const uploadFile = useCallback(
        async (f: File, folderId: string | null, abortController: AbortController) => {
            const fullPath = f.webkitRelativePath !== "" ? f.webkitRelativePath : `/${f.name}`;
            const fileToUpload = new FormData();
            fileToUpload.append(f.name, f, fullPath);
            return await upload(folderId, fileToUpload, abortController);
        },
        [upload],
    );

    const { cancel, start } = usePromisePool<File, BulkActionSummaryCancelResult<IContentSummarizeModel>>(
        maxSimultaneousUploadRequest,
    );

    const uploadFiles = useCallback(
        async (
            filesToUpload: File[],
            folderId: string | null,
        ): Promise<BulkActionSummaryCancelResult<IContentSummarizeModel>> => {
            return new Promise((resolve) => {
                start({
                    initialObjects: filesToUpload,
                    onFinish: (
                        completed: BulkActionSummaryCancelResult<IContentSummarizeModel>[],
                        errors: unknown[],
                        remainingObjects: File[],
                    ) => {
                        const summary = onUploadFinished(completed, errors, remainingObjects);
                        resolve(summary);
                    },
                    getNext: (file, controller) => uploadFile(file, folderId, controller),
                });
            });
        },
        [start, uploadFile],
    );

    const handleUploadSummaryNotification = useCallback(
        (summary: BulkActionSummaryCancelResult<IContentSummarizeModel>, errorInvalidCharacterCount: number) => {
            const { errorCount, errorMessages, successCount, cancelled } = summary;
            const totalCount = successCount + errorCount + errorInvalidCharacterCount;

            if (cancelled) {
                NotificationService.addWarningNotification({
                    messageKey: "Upload.Cancel",
                });
                return;
            }

            if (errorCount === 0 && errorInvalidCharacterCount === 0) {
                NotificationService.addSuccessNotification({
                    messageKey: "Upload.Success",
                });
                return;
            }

            if (successCount === 0 && errorCount === 1 && errorMessages.length === 1) {
                NotificationService.addErrorNotification({
                    message: extractErrorMessageAsHtml(errorMessages[0]),
                    messageKeyParams: {
                        maxFolderDepth,
                    },
                });
                return;
            }

            if (successCount === 0) {
                NotificationService.addErrorNotification({
                    messageKey: "Upload.Failure",
                    messageKeyParams: {
                        maxFolderDepth,
                    },
                });
                return;
            }

            NotificationService.addWarningNotification({
                messageKey: "Upload.Success.Partial",
                messageKeyParams: {
                    successCount,
                    totalCount,
                    maxFolderDepth,
                },
            });
        },
        [maxFolderDepth],
    );

    const validateFiles = useCallback(
        (fileList: File[]) => {
            let filesSize = 0;
            let errorInvalidCharacterCount = 0;
            const validFiles: File[] = [];

            fileList.forEach((file) => {
                filesSize += file.size;

                // When uploading a file via the CTA Upload File(s), webkitRelativePath can be empty.
                const hasInvalidCharacterInPath =
                    !!file.webkitRelativePath && file.webkitRelativePath.split("/").some(isPathSectionInvalid);
                if (hasInvalidCharacterInPath || !InvalidCharacterRegex.test(file.name)) {
                    errorInvalidCharacterCount++;
                    return;
                }

                validFiles.push(file);
            });
            if (!validFiles.length) {
                NotificationService.addErrorNotification({
                    messageKey: "Upload.InvalidCharacter",
                });
                return;
            }

            if (filesSize > fileUploadMaxRequestBodySizeInBytes) {
                NotificationService.addErrorNotification({
                    messageKey: "Upload.FilesExceedLimit",
                    messageKeyParams: {
                        size: parseFloat((fileUploadMaxRequestBodySizeInBytes / Math.pow(1024, 3)).toFixed(2)),
                    },
                });

                return;
            }

            return {
                validFiles,
                errorInvalidCharacterCount,
            };
        },
        [fileUploadMaxRequestBodySizeInBytes],
    );

    return {
        uploadFiles,
        onCancel: cancel,
        handleUploadSummaryNotification,
        validateFiles,
    };
};
