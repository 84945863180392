import React from "react";
import classNames from "classnames";
import { NavItem, NavLink, NavItemProps } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./NavTab.scss";

/**
 * The NavTab props interface.
 */
export interface INavTabProps extends NavItemProps {
    name: string;
    displayName: string;
    invalid?: boolean;
    dirty?: boolean;
    testSelectorValue?: string;
    hidden?: boolean;
    onToggle: (name: string) => void;
    tabcontrol?: string;
}

/**
 * The NavTab component.
 */
export const NavTab: React.FC<INavTabProps> = (props: INavTabProps) => {
    const { name, invalid, dirty, displayName, testSelectorValue, onToggle, tabcontrol, ...navItemProps } = props;
    const onTabClick = () => {
        onToggle(name);
    };
    const { setSelector } = useTestSelector();
    const { t } = useTranslation();

    return (
        <>
            <NavItem
                className={classNames("nav-tab", { error: invalid, dirty: dirty })}
                {...navItemProps}
                {...setSelector(testSelectorValue)}
            >
                <NavLink href="#" onClick={onTabClick}>
                    <span>{t(displayName)}</span>
                    <span className="status-circle" />
                </NavLink>
            </NavItem>
        </>
    );
};
