import React, { useEffect, useRef, useState } from "react";
import { initReactI18next, I18nextProvider } from "react-i18next";
import { createInstance as I18nCreateInstance, i18n } from "i18next";
import Backend from "i18next-http-backend";
import { useUserContext } from "../../contexts/UserContext";
import { LoadingScreen } from "../loading/LoadingScreen";

import "../modals/EntityModal.scss";

const i18nDefault = I18nCreateInstance();

interface I18NextWithContextProps {
    children?: React.ReactNode;
}

const initialize18n = (i18nRef: i18n, language: string): Promise<unknown> => {
    return i18nRef.init(
        {
            backend: {
                loadPath: `${window.location.origin}/_api/resources/{{lng}}`,
            },
            lng: language,
            fallbackLng: false, // No need to have a fallback here, the backend will always return something.
            ns: ["GWS"],
            defaultNS: "GWS",
            debug: true,
            keySeparator: false, // we do not use keys in form messages.welcome
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            react: {
                transSupportBasicHtmlNodes: true,
                transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "div"],
            },
            compatibilityJSON: "v3",
            load: "currentOnly",
            returnNull: false,
        },
        (err: Error, t: (...params: any[]) => string) => {
            if (err) {
                // eslint-disable-next-line no-console
                return console.error(err);
            }
        },
    );
};

/**
 * The i18next with context.
 */
export const I18nextWithContext: React.FC<I18NextWithContextProps> = ({ children }: I18NextWithContextProps) => {
    const userContext = useUserContext();
    const [i18nReady, setI18nReady] = useState(false);
    const i18nRef = useRef<i18n>();

    const locale = userContext?.locale;

    useEffect(() => {
        void (async () => {
            const language = locale ?? navigator.language ?? "en";

            if (!i18nRef.current) {
                i18nRef.current = i18nDefault.use(initReactI18next).use(Backend);

                await initialize18n(i18nRef.current, language);

                setI18nReady(true);
            } else {
                await i18nRef.current.changeLanguage(language);
            }
        })();
    }, [locale]);

    if (!i18nReady) {
        return <LoadingScreen />;
    }

    return <I18nextProvider i18n={i18nDefault}>{children}</I18nextProvider>;
};
