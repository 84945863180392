/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import classNames from "classnames";
import { useHotkeys } from "react-hotkeys-hook";
import { useFullscreenContext } from "../../../contexts/FullscreenContext";
import { useShortcutContext } from "../../../contexts/ShortcutContext";
import { EditEntitySaveButton } from "../../buttons/EditEntitySaveButton";
import { ExpandCompressButton } from "../../buttons/ExpandCompressButton";

import "./SectionHeader.scss";
import { EntityIcon, IEntityIconProps } from "../../icons/EntityIcon";

interface ISectionHeaderProps extends React.PropsWithChildren {
    flexGrow?: boolean;
    column?: boolean;
}
interface ISectionHeaderActionGroupProps extends React.PropsWithChildren {
    dropdownMenu?: React.ReactNode;
    withSaveButton?: boolean;
}

const Main: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <section className="section-header">{children}</section>;
};

const Group: React.FC<ISectionHeaderProps> = ({ flexGrow, column, children }) => {
    return <div className={classNames("section-header__group", { grow: flexGrow, column })}>{children}</div>;
};

const ActionGroup: React.FC<ISectionHeaderActionGroupProps> = ({ children, dropdownMenu, withSaveButton }) => {
    const { toggleFullscreen, fullscreen } = useFullscreenContext();
    const { globalShortcutEnabled } = useShortcutContext();

    useHotkeys(
        "escape",
        () => {
            toggleFullscreen();
        },
        {
            filter: () => globalShortcutEnabled && fullscreen,
        },
        [toggleFullscreen],
    );

    return (
        <Group>
            {children}
            {withSaveButton && <EditEntitySaveButton />}
            <ExpandCompressButton fullscreen={fullscreen} onToggle={toggleFullscreen} />
            {dropdownMenu}
        </Group>
    );
};

const OverTitle: React.FC<React.PropsWithChildren> = ({ children }) => (
    <div className="section-header__group__over-title text-truncate">{children}</div>
);

const SubTitle: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <span className="section-header__group__sub-title text-truncate">{children}</span>;
};

const Icon: React.FC<IEntityIconProps> = ({ type }) => {
    return (
        <div className="section-header__icon">
            <EntityIcon type={type} weight="light" />
        </div>
    );
};

const Title: React.FC<React.PropsWithChildren> = ({ children }) => <h1 className="text-truncate">{children}</h1>;

/*
    The section header component and namespace for inner components.
*/
export const SectionHeader = Object.assign(Main, {
    Group,
    ActionGroup,
    Title,
    OverTitle,
    SubTitle,
    Icon,
});
