import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { TableRefreshContext } from "../../contexts/TableRefreshContext";

type Listener = (fromRefreshButton: boolean) => void;

export const TableRefreshContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [listenersMap, setListenersMap] = useState<Map<string, Listener[]>>(new Map());

    const subscribe = useCallback((id: string, callback: Listener) => {
        setListenersMap((prevMap) => {
            const listenerList = prevMap.get(id) || [];
            const updatedMap = new Map(prevMap);
            updatedMap.set(id, [...listenerList, callback]);
            return updatedMap;
        });
    }, []);

    const unsubscribe = useCallback((id: string, callback: Listener) => {
        setListenersMap((prevMap) => {
            const listenerList = prevMap.get(id) || [];
            const updatedList = listenerList.filter((listener) => listener !== callback);
            const updatedMap = new Map(prevMap);
            updatedMap.set(id, updatedList);
            return updatedMap;
        });
    }, []);

    const refreshTable = useCallback(
        (id: string, fromRefreshButton: boolean = false) => {
            const listenerList = listenersMap.get(id) || [];
            listenerList.forEach((listener) => listener(fromRefreshButton));
        },
        [listenersMap],
    );

    const tableRefreshContextProps = useMemo(
        () => ({
            subscribe,
            unsubscribe,
            refreshTable,
        }),
        [refreshTable, subscribe, unsubscribe],
    );
    return <TableRefreshContext.Provider value={tableRefreshContextProps}>{children}</TableRefreshContext.Provider>;
};
