import { createContext, useContext } from "react";

interface IFullscreenContextProps {
    fullscreen: boolean;
    toggleFullscreen: () => void;
}

/**
 * The fullscreen context.
 */
export const FullscreenContext = createContext<IFullscreenContextProps>(null!);

/**
 * The use fullscreen context for functional component.
 */
export const useFullscreenContext = (): IFullscreenContextProps => useContext(FullscreenContext);
