import React, { useCallback, useMemo } from "react";
import { CreateEntityContext, ICreateEntityContext } from "../../contexts/CreateEntityContext";
import { useCreateEntity } from "../../hooks/CreateEntityHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";

/**
 * The create entity wrapper props.
 */
interface ICreateEntityWrapperProps<TEntity> {
    show: boolean;
    defaultEntity: TEntity;
    onCreate?: (entity: TEntity) => Promise<any>;
    onCreated: (entity: TEntity) => void;
    children?: React.ReactNode;
    allowCreateWhenNotDirty?: boolean;
}

/**
 * The create entity wrapper.
 */
export const CreateEntityWrapper = <TEntity extends object>({
    children,
    defaultEntity,
    show,
    onCreate,
    onCreated,
    allowCreateWhenNotDirty = false,
}: ICreateEntityWrapperProps<TEntity>): JSX.Element => {
    const { entity, errorMessage, creating, valid, dirty, create, setValid, setEntity, setErrorMessage, setDirty } =
        useCreateEntity<TEntity>({
            initialEntity: defaultEntity,
            createEntity: onCreate,
            onCreated,
        });
    const canCreate = useMemo(
        () => (allowCreateWhenNotDirty || dirty) && valid && !creating,
        [creating, dirty, valid, allowCreateWhenNotDirty],
    );
    const onChange = useCallback(
        (formData: IEntityFormData<TEntity>) => {
            setEntity(formData.data);
            setValid(formData.validationStatus === "Valid");
            setDirty(formData.dirty);
        },
        [setEntity, setValid, setDirty],
    );
    const memoizedCreate = useCallback(async () => {
        canCreate && (await create());
    }, [canCreate, create]);
    const memoizedResetErrorMessage = useCallback(() => setErrorMessage(""), [setErrorMessage]);

    const initialContextValue: ICreateEntityContext<TEntity> = useMemo(
        () => ({
            canCreate,
            creating,
            data: entity,
            dirty,
            errorMessage,
            show,
            create: memoizedCreate,
            onChange,
            resetErrorMessage: memoizedResetErrorMessage,
        }),
        [canCreate, creating, entity, dirty, errorMessage, show, memoizedCreate, onChange, memoizedResetErrorMessage],
    );

    return <CreateEntityContext.Provider value={initialContextValue}>{children}</CreateEntityContext.Provider>;
};
