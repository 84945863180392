import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { OrganizationFormValidator } from "../../formValidators/OrganizationFormValidator";
import { useTabs } from "../../hooks/TabsHook";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { EditOrganizationViewTabsOverview } from "./tabs/EditOrganizationViewTabsOverview";
import { EditOrganizationViewTabsProjects } from "./tabs/EditOrganizationViewTabsProjects";
import { TabContent } from "../tabs/TabContent";
import { UsersView } from "../users/UsersView";
import { PermissionsTabs } from "../permissions/PermissionsTabs";
import { PermissionGroupPaths } from "../../PathConstants";
import { buildLicense, buildOverview, buildPermissions, buildProjects, buildUsers } from "../../helpers/EntityHelper";
import { EditOrganizationViewTabsLicense } from "./tabs/EditOrganizationViewTabsLicense";

/**
 * the list of tabs in the edit organization view
 */
const editOrganizationViewTabs = ["projects", "users", "overview", "permissions", "license"] as const;

/**
 * the list of tabs in the edit organization view as Type
 */
type EditOrganizationTabType = (typeof editOrganizationViewTabs)[number];

/**
 * The Edit Organization View Tabs
 */
export const EditOrganizationViewTabs = (): JSX.Element => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useTabs<EditOrganizationTabType>(editOrganizationViewTabs, "projects");

    const {
        entityProps: { entity: organization },
        dirtyProps: { isDirty },
        errorProps: { errors },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();

    const tabs: INavMenuItemProps[] = useMemo(
        () => [
            buildProjects(false, false, setActiveTab),
            buildUsers(false, false, setActiveTab),
            buildPermissions(false, false, setActiveTab),
            buildLicense(false, false, setActiveTab),
            buildOverview(!!errors.description, isDirty(["description"]), setActiveTab),
        ],
        [errors.description, isDirty, setActiveTab],
    );

    const filteredTabs = useMemo(() => tabs.filter((tab) => canEdit || tab.name !== "permissions"), [canEdit, tabs]);

    return (
        <NavMenu activeTab={activeTab} navTabs={filteredTabs}>
            <TabContent
                tabId="projects"
                className="projects"
                title={t("Common.Projects")}
                testSelectorValue="tabPaneProjects"
            >
                <EditOrganizationViewTabsProjects />
            </TabContent>
            <TabContent tabId="users" className="users" title={t("Common.Users")}>
                <UsersView
                    entityName={organization.name}
                    readOnly={!canEdit}
                    organizationId={organization.organizationId}
                    canDelete={canEdit}
                />
            </TabContent>
            <TabContent tabId="permissions" className="permissions" title={t("Common.Permissions")} hasNavTabs>
                <PermissionsTabs
                    organizationId={organization.organizationId}
                    groupLink={(groupId) =>
                        PermissionGroupPaths.link.edit.organization(organization.organizationId, groupId)
                    }
                />
            </TabContent>
            <TabContent tabId="license" className="license" title={t("Common.License")}>
                <EditOrganizationViewTabsLicense />
            </TabContent>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <EditOrganizationViewTabsOverview />
            </TabContent>
        </NavMenu>
    );
};
