import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Input } from "reactstrap";
import { Button } from "../../buttons/Button";
import { formatBytes } from "../../../helpers/formattingHelper";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { TimestampInfo } from "../../date/TimestampInfo";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { FormFeedbackError } from "../../forms/feedbacks/FormFeedbackError";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { ActivityList } from "../../activityList/ActivityList";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { IContentHistoryModel } from "../../../models/history/IHistoryModel";
import { ActivityModel } from "../../../models/history/ActivityModel";
import { useContentFileApi } from "../../../hooks/contentManager/ContentFileApiHook";
import { useContentFolderApi } from "../../../hooks/contentManager/ContentFolderApiHook";
import { useNavigation } from "../../../hooks/NavigationHook";
import { contentManagerPaths } from "../../../PathConstants";
import { ReadOnlyInputWithCopy } from "../../forms/inputs/ReadOnlyInputWithCopy";

interface IContentManagerItemPropertiesOverviewTabProps {
    item: IContentEntityModel;
    handleDescriptionChange: (val: string) => void;
    descriptionData: string;
    descriptionError?: string | JSX.Element;
    readonly?: boolean;
}

const isMoveAction = (currentParent: IContentEntityModel | null, previousParent: IContentEntityModel | null) =>
    currentParent?.id !== previousParent?.id;

const mapContentHistoryToActivity = (historyList: IContentHistoryModel[]): Promise<ActivityModel[]> => {
    return Promise.resolve(
        historyList.map<ActivityModel>((h, i) => {
            if (
                h.eventType === "update" &&
                i < historyList.length - 1 &&
                isMoveAction(h.parentFolder, historyList[i + 1].parentFolder)
            ) {
                return {
                    eventType: "move",
                    eventDate: h.eventDate,
                    userIdentification: h.userIdentification,
                    destination: h.parentFolder?.name,
                    source: historyList[i + 1].parentFolder?.name,
                };
            }

            return h;
        }),
    );
};

export const ContentManagerItemPropertiesOverviewTab: React.FC<IContentManagerItemPropertiesOverviewTabProps> = ({
    item,
    handleDescriptionChange,
    descriptionData,
    descriptionError,
    readonly,
}) => {
    const { navigate } = useNavigation();
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const projectContext = useProjectContext();
    const { getHistory: getFolderHistory } = useContentFolderApi(projectContext?.projectId || "");
    const { getHistory: getFileHistory } = useContentFileApi(projectContext?.projectId || "");

    const getHistory = useCallback(
        () =>
            (item.type === "Folder" ? getFolderHistory(item.id) : getFileHistory(item.id)).then(
                mapContentHistoryToActivity,
            ),
        [getFileHistory, getFolderHistory, item.id, item.type],
    );

    return (
        <div className="item-properties-container__overview">
            <FormGroup>
                <Button
                    ariaLabel={t("Common.Details")}
                    onClick={() =>
                        navigate(
                            contentManagerPaths.link.edit(
                                projectContext.organizationName,
                                projectContext.name,
                                item.id,
                                item.type,
                            ),
                        )
                    }
                    color="outline-secondary"
                    description={t("Common.Details")}
                    icon="fal fa-square-poll-horizontal"
                    className="details-button"
                    {...setSelector("detailsButton")}
                >
                    {t("Common.Details")}
                </Button>
            </FormGroup>
            {item.type === "File" && (
                <FormGroup>
                    <div className="item-properties-container__size">{t("Common.Size")}</div>
                    <div>{formatBytes(item.size ?? 0)}</div>
                </FormGroup>
            )}
            <FormGroup>
                <ReadOnlyInputWithCopy
                    value={item.path}
                    label="Common.Location"
                    displayedValueWhileCopying="ContentManager.LocationCopied"
                />
            </FormGroup>
            <FormGroup {...setSelector("modifiedByFormGroup")}>
                <div className="item-properties-container__content__modifiedBy">{t("Common.Modified")}</div>
                <TimestampInfo date={item.modifiedOn} user={item.modifiedBy} />
            </FormGroup>
            <FormGroup {...setSelector("createdByFormGroup")}>
                <div className="item-properties-container__content__createdBy">{t("Common.Created")}</div>
                <TimestampInfo date={item.createdOn} user={item.createdBy} />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="item-properties-description" text="Common.Description" />
                <Input
                    id="item-properties-description"
                    type="textarea"
                    rows={5}
                    value={descriptionData}
                    onChange={(e) => handleDescriptionChange(e.target.value)}
                    invalid={!!descriptionError}
                    disabled={readonly}
                    {...setSelector("descriptionTextbox")}
                />
                {descriptionError && <FormFeedbackError error={descriptionError} />}
            </FormGroup>
            <div>
                <h2>{t("Common.History")}</h2>
                <ActivityList entityType={item.type === "Folder" ? "Folder" : "File"} getHistory={getHistory} />
            </div>
        </div>
    );
};
