import React from "react";
import { AppConfigContext } from "./contexts/AppConfigContext";
import { LoadingScreen } from "./components/loading/LoadingScreen";
import { NotificationService } from "./services/NotificationService";
import { useAppConfig } from "./hooks/AppConfigHook";

/**
 * The app config props interface.
 */
interface IAppConfigProps {
    children?: React.ReactNode;
}

/**
 * The app config component.
 */
export const AppConfig: React.FC<IAppConfigProps> = (props: IAppConfigProps) => {
    const [isLoading, config] = useAppConfig();

    if (isLoading) {
        return <LoadingScreen />;
    }

    NotificationService.successNotificationAutoDismissTimeoutInSecs =
        config.successNotificationAutoDismissTimeoutInSecs;

    return <AppConfigContext.Provider value={config}>{props.children}</AppConfigContext.Provider>;
};
