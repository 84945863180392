/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { NullableValue } from "../models/NullableValue";

type SourceType = object | undefined | null;

/**
 * The object helper class.
 */
export class ObjectHelper {
    public static isEqual = (objA: any, objB: any): boolean => {
        if (
            (objA === null && objB !== null) ||
            (objA !== null && objB === null) ||
            (typeof objA === "undefined" && typeof objB !== "undefined") ||
            (typeof objA !== "undefined" && typeof objB === "undefined")
        ) {
            return false;
        }

        const keysA = Object.keys(objA as object);
        const keysB = Object.keys(objB as object);

        if (keysA.length !== keysB.length) {
            return false;
        }

        for (const key of keysA) {
            if (!objB[key]) {
                return false;
            }

            if (typeof objA[key] === "object") {
                if (!ObjectHelper.isEqual(objA[key], objB[key])) {
                    return false;
                }
            } else if (objA[key] !== objB[key]) {
                return false;
            }
        }

        return true;
    };

    public static containsKeyValuePair = (
        source: SourceType,
        keyValuePair: { key: string; value: string },
    ): boolean => {
        if (!source) {
            return false;
        }

        return Object.entries(source).some(([key, value]) => {
            if (key === keyValuePair.key && keyValuePair.value === value) {
                return true;
            }

            if (typeof value === "object") {
                if (Array.isArray(value)) {
                    return value.some((val: object | null | undefined) =>
                        ObjectHelper.containsKeyValuePair(val, keyValuePair),
                    );
                }

                return ObjectHelper.containsKeyValuePair(value as object | null | undefined, keyValuePair);
            }

            return false;
        });
    };

    // This method is used for the special case where null and "" should be equal for NullableValue<string>.
    public static isNullableStringEqual = (objA: NullableValue<string>, objB: NullableValue<string>): boolean => {
        return (!objA && !objB) || objA === objB;
    };
}
