import React from "react";
import { TabPane, TabPaneProps } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The tab panel props interface.
 */
export interface ITabPanelProps extends TabPaneProps {
    labelby: string;
    testSelectorValue?: string;
}

/**
 * The tab panel component.
 */
export const TabPanel: React.FC<ITabPanelProps> = ({ labelby, testSelectorValue, tabId, className, children }) => {
    const { setSelector } = useTestSelector();

    return (
        <TabPane role="tabpanel" aria-labelledby={labelby} id={tabId as string} tabId={tabId} className={className} { ...setSelector(testSelectorValue) }>
            {children}
        </TabPane>
    );
};
