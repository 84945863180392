import React, { useCallback, useMemo } from "react";
import { Table } from "../table/Table";
import { buildLanguageTableColumnDefs } from "../language/LanguageView";
import { useProjectContext } from "../../contexts/ProjectContext";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { useDefaultTableFilterHelper } from "../../hooks/table/DefaultTableFilterHelper";
import { useTemplateApi } from "../../hooks/template/TemplateApiHook";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { TemplateFormValidator } from "../../formValidators/TemplateFormValidator";
import { ITemplateLanguageModel } from "../../models/Languages/ITemplateLanguageModel";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { useTemplateDesignDownload } from "../../hooks/template/TemplateDesignDownloadHook";

export const TemplateLanguageView: React.FC = () => {
    const { projectId, organizationName, name: projectName } = useProjectContext();
    const {
        dirtyProps: { isDirty },
        entityProps: {
            entity: { templateId },
            initialEntity: { name: initialName },
        },
        permissionsProps: { canEdit },
    } = useEditEntityContext<ITemplateModel, TemplateFormValidator>();
    const { getLanguageRecords } = useTemplateApi(projectId);
    const { handleDesign } = useTemplateDesignDownload();

    const columnDefs: IEntityTableColumnDef[] = useMemo(
        () => buildLanguageTableColumnDefs(organizationName, projectName),
        [organizationName, projectName],
    );

    const filterHelper = useDefaultTableFilterHelper("LanguageView.FilterPlaceHolder");

    const getRecords: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<ITemplateLanguageModel>> = useCallback(
        (filterValue, sortFields, offset) => {
            return getLanguageRecords(templateId, filterValue, sortFields, offset);
        },
        [getLanguageRecords, templateId],
    );

    const actionProvider = useMemo(() => {
        if (!canEdit) {
            return;
        }

        return (item: ITemplateLanguageModel): ITableRowActionProps[] => {
            return [
                {
                    iconClassName: "fal fa-compass-drafting",
                    label: "Common.Design",
                    onClick: () => {
                        handleDesign(templateId, initialName, item.code);
                    },
                    disabled: isDirty(),
                },
            ];
        };
    }, [canEdit, initialName, templateId, isDirty, handleDesign]);

    return (
        <Table
            className="language-table"
            columnDefs={columnDefs}
            getRecords={getRecords}
            keyExtractor={(item) =>
                item.createdBy ? item.templateLanguageId : `virtual-templateLanguage-${item.code}`
            }
            loadingMessageKey="Loading.Languages"
            tableId="templateLanguages"
            filterHelper={filterHelper}
            actionProvider={actionProvider}
            counterPosition="Start"
        />
    );
};
