import React from "react";
import { LabelCircle } from "../labelCircle/LabelCircle";
import { useTranslation } from "react-i18next";

interface IServiceHookStatusCodeIndicatorProps {
    statusCode?: number;
}

export const ServiceHookStatusCodeIndicator: React.FC<IServiceHookStatusCodeIndicatorProps> = ({ statusCode }) => {
    const { t } = useTranslation();
    return (
        <div>
            <LabelCircle color={!statusCode || statusCode >= 400 ? "red" : "green"} />
            {statusCode ?? t("Common.None")}
        </div>
    );
};
