import React from "react";
import { TabPane } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./TabContent.scss";

/**
 * The Tab Content With Title props
 */
interface ITabContentProps {
    title?: string;
    tabId: string;
    className: string;
    hasNavTabs?: boolean;
    noSection?: boolean;
    testSelectorValue?: string;
    children?: React.ReactNode;
}

/**
 * The Tab Content With Title component
 */
export const TabContent: React.FC<ITabContentProps> = ({
    title,
    children,
    tabId,
    className,
    hasNavTabs,
    noSection,
    testSelectorValue,
}: ITabContentProps) => {
    const { setSelector } = useTestSelector();

    return (
        <TabPane tabId={tabId} className={className} {...setSelector(testSelectorValue)}>
            {title && <h2 className={`tab-content-title ${hasNavTabs ? "has-navTabs" : ""}`}>{title}</h2>}
            {noSection ? children : <section className="tab-content-section">{children}</section>}
        </TabPane>
    );
};
