import { createContext, useContext } from "react";
import { NullableValue } from "../models/NullableValue";
import { ITimeZoneModel } from "../models/account/ITimeZoneModel";

/**
 * The content of the user context
 */
type UserContextProp = {
    userId: string;
    locale?: string;
    reloadClaims: () => void;
    refreshProfile: () => void;
    name?: string;
    initials?: string;
    previewConsent?: boolean;
    availableTimeZones: ITimeZoneModel[];
    userTimeZone: NullableValue<string>;
};

/**
 * The user context.
 */
export const UserContext = createContext<UserContextProp | null>(null);

/**
 * The use user context for functional component.
 */
export const useUserContext = (): UserContextProp => useContext(UserContext) as UserContextProp;
