import React from "react";
import * as Yup from "yup";
import { IDefaultEntityProps } from "../models/IDefaultEntityProps";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";
import { NullableValue } from "../models/NullableValue";

export class ContentManagerItemPropertiesFormValidator extends FormValidator<Omit<IDefaultEntityProps, "name">> {
    public static readonly descriptionMaxLength: number = 1000;

    protected schema = Yup.object().shape({
        description: Yup.string()
            .nullable(true)
            .max(ContentManagerItemPropertiesFormValidator.descriptionMaxLength, ({ max }) => {
                return (
                    <ValidatorError
                        messageKey="ContentManagerItemPropertiesFormValidator.DescriptionMaxLengthValidation"
                        options={{ max }}
                    />
                );
            }),
    });

    public validateDescription(description: NullableValue<string>): Promise<string | null | undefined> {
        return this.schema.fields.description.validate(description);
    }
}
