import { ValidatorError } from "../formValidators/ValidatorError";
import React from "react";

/**
 * Regex form of forbiden characters for the name of any object (organization, project, data set, data set sample, API key, etc...)
 */
export const InvalidCharacterRegex = /^[^\\/:*?"<>%|]+$/;

export const InvalidPackageName = /^(?:(?!^(?:CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$).)+$/i;

/**
 * Validator Error for invalid character.
 */
export const InvalidCharacterValidationError = (): JSX.Element => (
    <ValidatorError messageKey="Common.InvalidCharacter" shouldUnescape={true} />
);

/**
 * Validator Error for invalid package name.
 */
export const InvalidPackageNameValidationError = (): JSX.Element => (
    <ValidatorError messageKey="Common.InvalidPackageName" shouldUnescape={true} />
);
