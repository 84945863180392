import React, { useCallback } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";

import "./ExpandCompressButton.scss";

/**
 * The expand/compress button props interface.
 */
interface IExpandCompressButtonProps {
    fullscreen?: boolean;
    onToggle: (fullscreen: boolean) => void;
    iconType?: "far" | "fal";
}

/**
 * The expand/compress button component.
 */
export const ExpandCompressButton: React.FC<IExpandCompressButtonProps> = ({
    fullscreen,
    onToggle,
    iconType = "fal",
}: IExpandCompressButtonProps) => {
    const toggleFullscreen = useCallback(() => onToggle(!fullscreen), [fullscreen, onToggle]);
    const { t } = useTranslation();

    return (
        <Button
            ariaLabel={t(fullscreen ? "Common.Compress" : "Common.Expand")}
            icon={classNames(
                iconType,
                fullscreen
                    ? "fa-arrow-down-left-and-arrow-up-right-to-center"
                    : "fa-arrow-up-right-and-arrow-down-left-from-center",
            )}
            className="expand-compress"
            color="secondary"
            onClick={toggleFullscreen}
            outline
            description={t(fullscreen ? "Common.Compress" : "Common.Expand")}
        />
    );
};
