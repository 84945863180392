import { useEffect, useMemo, useState } from "react";
import { AddMemberFormValidator, IAddMemberFields } from "../../../../formValidators/AddMemberFormValidator";
import { extractErrorMessageOrEmptyString } from "../../../../helpers/ErrorHelper";
import { IValidationResult } from "../../../../hooks/CreateModalEntityHook";
import { useEntityErrors } from "../../../../hooks/entity/EntityErrorsHook";

export const useAddMemberValidation = ({ userIds }: IAddMemberFields): IValidationResult<IAddMemberFields, never> => {
    const { errors, setError } = useEntityErrors<IAddMemberFields>();
    const [valid, setValid] = useState(false);
    const [usersVisited, setUsersVisited] = useState(false);
    const formValidator = useMemo(() => new AddMemberFormValidator(), []);

    useEffect(() => {
        formValidator.setInitialValue({ userIds: [] });
    }, [formValidator]);

    useEffect(() => {
        !usersVisited && userIds.length > 0 && setUsersVisited(!!userIds);
    }, [usersVisited, userIds]);

    useEffect(() => {
        if (!usersVisited) {
            return;
        }

        formValidator
            .validateUsers(userIds)
            .then(() => setError("userIds", ""))
            .catch((err: Error) => setError("userIds", extractErrorMessageOrEmptyString(err)));
    }, [userIds, formValidator, setError, usersVisited]);

    useEffect(() => {
        void formValidator.isValid({ userIds }).then((value) => setValid(value));
    }, [formValidator, userIds]);

    return {
        valid,
        errors,
        setError,
        validationStatuses: {},
    };
};
