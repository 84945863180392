import { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAtom, useSetAtom } from "jotai";
import {
    SearchQueryParamsName,
    contentViewFolderTreeNeedRefreshAtom,
    foldersTreeAtom,
    loadingModalStateAtom,
    searchQueryAtom,
    selectedFolderIdAtom,
} from "../../atoms/ContentManager";
import { createNewFolders, removeFolders as removeFoldersFromTree } from "../../helpers/ContentManagerFolderHelper";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { FoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import {
    getSelectedFolderFromPath,
    recursiveOpenStateFolder,
    sortFolderTree,
    updateParents,
} from "../../helpers/TreeFolderHelper";
import { useNavigation } from "../NavigationHook";
import { IContentFolderUploadModel, ISubFolderModel } from "../../models/contentManager/ContentManagerApiModels";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useContentFolderApi } from "./ContentFolderApiHook";
import { useModalContext } from "../../contexts/ModalContext";
import { useGetFoldersTreeFromPath } from "./GetFoldersTreeFromPathHook";
import { useUserContext } from "../../contexts/UserContext";

export const contentManagerSearchParamPath = "path";

export type BeforeNavigate = (() => Promise<boolean>) | null;

/**
 * The content manager context return props interface.
 */
interface ContentManagerTreeReturnProps {
    addFolders: (folders: FoldersTree) => void;
    onNewFolderCreated: (folder: ITreeItem) => void;
    removeFolders: (ids: string[], parentId: string | null) => void;
    handleCollapse: (id: string, state: boolean) => void;
    handleSelectedPath: (item: ITreeItem | null) => void;
    getSubFolders: (item: ITreeItem) => Promise<FoldersTree>;
    handleUploadSummary: (affectedFolders: IContentFolderUploadModel[], targetFolderId: string | null) => void;
    setItemLoadingState: (id: string, isLoading: boolean) => void;
    onFolderMoved: (itemId: string, newParentId: string | null) => void;
    onFolderCopied: (destinationId: string | null, newFolder: ISubFolderModel) => void;
    setBeforeNavigate: (fn: BeforeNavigate) => void;
    onRefreshCallback: (id: string | null) => void;
    onLoadError: (error: unknown) => void;
}

export const useContentManagerTree = (): ContentManagerTreeReturnProps => {
    const [foldersTree, setFoldersTree] = useAtom(foldersTreeAtom);
    const [selectedFolderId, setSelectedFolderId] = useAtom(selectedFolderIdAtom);
    const [searchQuery, setSearchQuery] = useAtom(searchQueryAtom);
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const [searchParams] = useSearchParams();
    const searchPathOnLoad = searchParams.get(contentManagerSearchParamPath);
    const [searchPath, setSearchPath] = useState<string | null>(searchParams.get(contentManagerSearchParamPath));
    const { projectId } = useProjectContext();
    const { getSubFolders: getContentSubFolders, get: getFolder } = useContentFolderApi(projectId);
    const location = useLocation();
    const { navigate } = useNavigation();
    const setBeforeNavigateRef = useRef<(() => Promise<boolean>) | null>(null);
    const [contentViewNeedRefresh, setContentViewNeedRefresh] = useAtom(contentViewFolderTreeNeedRefreshAtom);
    const { closeModal, isDisplayed } = useModalContext();
    const { locale } = useUserContext();

    const onNewFolderCreated = useCallback(
        (folder: ITreeItem) => {
            setFoldersTree((oldTree) => {
                const foldersTreeCopy = [...oldTree];

                // if parent is the root, we simply add and sort.
                if (folder.parentId === null) {
                    foldersTreeCopy.push(folder);
                    sortFolderTree(foldersTreeCopy, locale);
                    return foldersTreeCopy;
                }

                // if the parent has no children, we add the new folder and open the parent
                const parent = foldersTreeCopy[foldersTreeCopy.findIndex((f) => f.id === folder.parentId)];
                if (!parent.hasChildren) {
                    parent.hasChildren = true;
                    parent.areChildrenLoaded = true;
                    parent.isOpen = true;
                    foldersTreeCopy.push(folder);
                    sortFolderTree(foldersTreeCopy, locale);
                    return foldersTreeCopy;
                }

                // if the parent has already loaded its children we add it to the list and sort.
                if (parent.areChildrenLoaded) {
                    foldersTreeCopy.push(folder);
                    sortFolderTree(foldersTreeCopy, locale);
                    return foldersTreeCopy;
                }

                // If the parent has subfolders but we haven't loaded them,
                // we don't need to add them to the list of folders since it will be there when we fetch the subfolders
                return foldersTreeCopy;
            });
        },
        [locale, setFoldersTree],
    );

    const addFolders = useCallback(
        (folders: ITreeItem[]) => {
            if (folders.length < 1) {
                return;
            }

            setFoldersTree((olderTree) => {
                let sortedFolders = sortFolderTree([...olderTree, ...folders], locale);
                sortedFolders = updateParents(sortedFolders, folders);
                return sortedFolders;
            });
        },
        [locale, setFoldersTree],
    );

    const removeFolders = useCallback(
        (ids: string[], parentId: string | null) => {
            setFoldersTree((oldTree) => {
                const newTree = removeFoldersFromTree(ids, parentId, oldTree);

                return newTree;
            });
        },
        [setFoldersTree],
    );

    const onFolderMoved = useCallback(
        (folderId: string, newParentId: string | null) => {
            const parent = newParentId === null ? null : foldersTree.find((f) => f.id === newParentId);
            const movedFolder = foldersTree.find((f) => f.id === folderId);
            if (!movedFolder) {
                return;
            }
            removeFolders([folderId], movedFolder.parentId);

            if (parent === undefined) {
                return;
            }

            if (parent && !parent.areChildrenLoaded) {
                setFoldersTree((oldTree) => {
                    const targetFolderIndex = oldTree.findIndex((x) => x.id === newParentId);

                    const newArray = [
                        ...oldTree.slice(0, targetFolderIndex),
                        { ...oldTree[targetFolderIndex], hasChildren: true },
                        ...oldTree.slice(targetFolderIndex + 1),
                    ];

                    return newArray;
                });
                return;
            }

            addFolders([
                {
                    ...movedFolder,
                    parentId: newParentId,
                    level: parent ? parent.level + 1 : 0,
                    path: `${parent?.path ?? ""}/${movedFolder.name}`,
                    areChildrenLoaded: false,
                    isOpen: false,
                },
            ]);
        },
        [addFolders, foldersTree, removeFolders, setFoldersTree],
    );

    const onFolderCopied = useCallback(
        (destinationId: string | null, newFolder: ISubFolderModel) => {
            const parent = destinationId === null ? null : foldersTree.find((f) => f.id === destinationId);

            if (parent === undefined) {
                return;
            }

            onNewFolderCreated({
                areChildrenLoaded: false,
                hasChildren: newFolder.hasChildren,
                id: newFolder.id,
                isLoadingChildren: false,
                isOpen: false,
                level: parent ? parent.level + 1 : 0,
                name: newFolder.name,
                parentId: destinationId,
                path: `${parent?.path ?? ""}/${newFolder.name}`,
            });
        },
        [foldersTree, onNewFolderCreated],
    );

    const handleCollapse = useCallback(
        (id: string, state: boolean, closeParents: boolean = false) => {
            setFoldersTree((oldTree) => {
                const newFolderTree = [...oldTree];
                recursiveOpenStateFolder(newFolderTree, id, state, closeParents);
                return newFolderTree;
            });
        },
        [setFoldersTree],
    );

    const getSubFolders = useCallback(
        async (item: ITreeItem) => {
            try {
                const subFolders = await getContentSubFolders(item.id);
                const newFolders = createNewFolders(item, subFolders);
                addFolders(newFolders);
                return newFolders;
            } catch (error) {
                defaultRequestErrorHandler(error);
                return {} as FoldersTree;
            }
        },
        [addFolders, getContentSubFolders],
    );

    const setItemLoadingState = useCallback(
        (id: string, isLoading: boolean) => {
            setFoldersTree((oldTree) => {
                const item = oldTree.find((f) => f.id === id);
                if (item) {
                    item.isLoadingChildren = isLoading;
                }
                return oldTree;
            });
        },
        [setFoldersTree],
    );

    const loadSubFoldersFromPath = useCallback(
        async (path: string) => {
            const {
                selectedFolderId: selectedFolderIdFromPath,
                selectedFolderName,
                parentFolderItem,
            } = getSelectedFolderFromPath(path, foldersTree);

            if (selectedFolderIdFromPath) {
                handleCollapse(selectedFolderIdFromPath, true, true);

                const selectedFolder = foldersTree.find((ft) => ft.id === selectedFolderIdFromPath);
                if (selectedFolder?.hasChildren && !selectedFolder?.areChildrenLoaded) {
                    setItemLoadingState(selectedFolderIdFromPath, true);
                    setSelectedFolderId(selectedFolderIdFromPath);

                    await getSubFolders(selectedFolder);
                    setItemLoadingState(selectedFolderIdFromPath, false);
                } else {
                    setSelectedFolderId(selectedFolderIdFromPath);
                }
            } else {
                const newFolders = await getSubFolders(parentFolderItem!);
                setSelectedFolderId(newFolders.filter((nf: ITreeItem) => nf.name === selectedFolderName)[0].id);
                handleCollapse(parentFolderItem!.id, true, true);
            }
        },
        [foldersTree, getSubFolders, handleCollapse, setItemLoadingState, setSelectedFolderId],
    );

    const handleSelectedPath = useCallback(
        (item: ITreeItem | null) => {
            // Rely on the fact that the path is always available instead of item id like not present on breadcrumb / left menu or back and forward buttons.
            if (setBeforeNavigateRef.current) {
                void setBeforeNavigateRef.current().then((shouldNavigate) => {
                    if (shouldNavigate) {
                        searchParams.set("path", item === null ? "/" : item.path);
                        searchParams.delete(SearchQueryParamsName);

                        navigate({
                            ...location,
                            search: searchParams.toString(),
                        });
                    }
                });
                return;
            }

            const pathParam = item === null ? "/" : item.path;

            if (searchParams.has(SearchQueryParamsName) || searchParams.get("path") !== pathParam) {
                searchParams.set("path", pathParam);
                searchParams.delete(SearchQueryParamsName);

                navigate({
                    ...location,
                    search: searchParams.toString(),
                });
            }
        },
        [location, navigate, searchParams],
    );

    useEffect(() => {
        const searchParam = searchParams.get(SearchQueryParamsName) ?? "";

        if (searchParam !== searchQuery) {
            setSearchQuery(searchParam);
        }
    }, [location, searchParams, searchQuery, setSearchQuery]);

    const onLoadError = useCallback(
        (error: unknown) => {
            setFoldersTree((oldTree) => {
                const item = oldTree.find((x) => x.id === selectedFolderId);
                const newTree = removeFoldersFromTree([selectedFolderId!], item?.parentId ?? null, oldTree);

                return newTree;
            });
            handleSelectedPath(null);
            defaultRequestErrorHandler(error);
        },
        [handleSelectedPath, selectedFolderId, setFoldersTree],
    );

    const { loadFolderTree } = useGetFoldersTreeFromPath();

    useEffect(() => {
        if (!contentViewNeedRefresh) {
            return;
        }

        (async () => {
            const { currentFolderLocationId, foldersTree: loadedFoldersTree } = await loadFolderTree(
                searchPathOnLoad,
                onLoadError,
            );
            setSelectedFolderId(currentFolderLocationId);
            setFoldersTree(loadedFoldersTree);
            setContentViewNeedRefresh(false);
        })();
    }, [
        loadFolderTree,
        setFoldersTree,
        setSelectedFolderId,
        contentViewNeedRefresh,
        setContentViewNeedRefresh,
        searchPathOnLoad,
        onLoadError,
    ]);

    useEffect(() => {
        const currentSearchPath = searchParams.get(contentManagerSearchParamPath);

        // if the path is the same as the current one, we don't need to do anything
        if (currentSearchPath === searchPath) {
            return;
        }

        setSearchPath(currentSearchPath);

        // Ensure the create folder modal is closed when we navigate to another folder.
        if (isDisplayed) {
            closeModal();
        }
        setLoadingModalState({ type: "CLOSE_MODAL" });

        if (!currentSearchPath || currentSearchPath === "/") {
            // searchPath is null when we click on the Content menu / breadcrumb.
            setSelectedFolderId(null);
        } else {
            loadSubFoldersFromPath(currentSearchPath);
        }
    }, [
        searchParams,
        loadSubFoldersFromPath,
        searchPath,
        isDisplayed,
        closeModal,
        setSelectedFolderId,
        setLoadingModalState,
    ]);

    const handleUploadSummary = useCallback(
        (affectedFolders: IContentFolderUploadModel[], targetFolderId: string | null) => {
            setFoldersTree((oldTree) => {
                const newFolders: FoldersTree = [];
                for (const folder of affectedFolders) {
                    const existingFolder = oldTree.find((n) => n && n.id === folder.contentFolderId);
                    const existingFolderParent = oldTree.find((n) => n && n.id === folder.parentContentFolderId);

                    // The folder need to be added if any of these conditions is true:
                    // folder doesnt exist AND is at root level
                    // folder doesnt exist AND the parent is in the tree AND its children are already loaded
                    // folder doesnt exist AND the parent is in the tree AND the parent doesnt have any children
                    if (
                        !existingFolder &&
                        (folder.parentContentFolderId === null ||
                            (existingFolderParent &&
                                (existingFolderParent.areChildrenLoaded || !existingFolderParent.hasChildren)))
                    ) {
                        newFolders.push({
                            areChildrenLoaded: false,
                            hasChildren: folder.hasChildren,
                            id: folder.contentFolderId,
                            name: folder.name,
                            parentId: targetFolderId ?? selectedFolderId,
                            isOpen: false,
                            level: existingFolderParent ? existingFolderParent.level + 1 : 0,
                            path: existingFolderParent
                                ? `${existingFolderParent.path}/${folder.name}`
                                : `/${folder.name}`,
                            isLoadingChildren: false,
                        });
                    }

                    // if it exists, make sure the hasChildren property doesn't need to be updated.
                    if (existingFolder && !existingFolder.hasChildren && folder.hasChildren) {
                        existingFolder.hasChildren = true;
                        existingFolder.areChildrenLoaded = false;
                    }
                }

                let sortedFolders = sortFolderTree([...oldTree, ...newFolders]);
                sortedFolders = updateParents(sortedFolders, newFolders);
                return sortedFolders;
            });
        },
        [selectedFolderId, setFoldersTree],
    );

    const onRefreshCallback = useCallback(
        (id: string | null) => {
            if (!id) {
                setFoldersTree([]);

                (async () => {
                    try {
                        const subFolders = await getContentSubFolders(null);
                        const newFolders = createNewFolders(null, subFolders);
                        setFoldersTree(sortFolderTree(newFolders));
                    } catch (error) {
                        defaultRequestErrorHandler(error);
                    }
                })();
                return;
            }

            setFoldersTree((oldTree) => {
                const existingItem = oldTree.find((x) => x.id === id)!;
                existingItem.isLoadingChildren = true;
                const existingChildren = oldTree.filter((item) => item.parentId === id).map((item) => item.id);

                if (existingChildren.length > 0) {
                    const newTree = removeFoldersFromTree(existingChildren, id, oldTree, false);
                    return newTree;
                }

                return oldTree;
            });

            (async () => {
                try {
                    const subFolders = await getContentSubFolders(id);

                    setFoldersTree((oldTree) => {
                        const existingItem = oldTree.find((x) => x.id === id)!;
                        existingItem.isLoadingChildren = false;

                        if (subFolders.length === 0) {
                            existingItem.hasChildren = false;
                            existingItem.isOpen = false;
                            existingItem.areChildrenLoaded = false;
                            return [...oldTree];
                        }

                        existingItem.isOpen = true;
                        existingItem.areChildrenLoaded = true;

                        const newFolders = createNewFolders(existingItem, subFolders);
                        oldTree = sortFolderTree([...oldTree, ...newFolders]);
                        oldTree = updateParents(oldTree, newFolders);
                        return oldTree;
                    });
                } catch (error) {
                    try {
                        await getFolder(id);
                    } catch {
                        // If we don't have the folder here, it means the getRecords api call will also fail
                        // so we can let the table handle the error.
                        return;
                    }

                    onLoadError(error);
                }
            })();
        },
        [getContentSubFolders, onLoadError, setFoldersTree, getFolder],
    );

    const setBeforeNavigate = useCallback((fn: (() => Promise<boolean>) | null) => {
        setBeforeNavigateRef.current = fn;
    }, []);

    return {
        addFolders,
        onNewFolderCreated,
        onFolderMoved,
        removeFolders,
        handleCollapse,
        handleSelectedPath,
        getSubFolders,
        handleUploadSummary,
        setItemLoadingState,
        onFolderCopied,
        setBeforeNavigate,
        onRefreshCallback,
        onLoadError,
    };
};
