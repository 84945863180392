import { ElementType, WeakValidationMap, useEffect, useState } from "react";
import { TableCreateHelper } from "../../components/table/Table";
import { useModalContext } from "../../contexts/ModalContext";

interface IUseDefaultTableCreateHelper<
    TEntity,
    TEntityFields,
    TComponentProps,
    TFormComponentProps,
    TComponent = ElementType,
> {
    onCreate: (entityFields: TEntityFields) => Promise<TEntity>;
    onCreated: (entity: TEntity) => void;
    initialEntity: TEntityFields;
    formComponent: TComponent;
    modalProps: TComponentProps;
    formProps: TFormComponentProps;
    buttonTextKey?: string;
}

interface ICreateModalProps {
    className?: string;
    titleKey: string;
    titleIconClassName?: string;
    unsavedWarningBody: string;
    size?: string;
    expandable?: boolean;
    onClose?: () => void;
}

export const useDefaultTableCreateHelper = <
    TEntity,
    TEntityFields,
    TModalProps extends ICreateModalProps,
    TFormProps extends WeakValidationMap<{}>,
>({
    formComponent,
    modalProps,
    formProps,
    initialEntity,
    buttonTextKey,
    onCreate,
    onCreated,
}: IUseDefaultTableCreateHelper<TEntity, TEntityFields, TModalProps, TFormProps>): TableCreateHelper => {
    const { showModal, closeModal } = useModalContext();
    const [createdEntity, setCreatedEntity] = useState<TEntity | null>(null);
    const { onClose, ...partialModalProps } = modalProps;

    useEffect(() => {
        if (createdEntity) {
            setCreatedEntity(null);
            onCreated(createdEntity);
        }
    }, [createdEntity, onCreated]);

    return {
        mode: "modal",
        onCreate: (onEntityCreated: (entity: TEntity) => void) => {
            showModal({
                entity: initialEntity,
                onCreate,
                onCreated: (entity: TEntity) => {
                    onEntityCreated(entity);
                    closeModal();
                    setCreatedEntity(entity);
                },
                formPropsToFlow: formProps,
                modalProps: {
                    onClose: () => {
                        closeModal();
                        onClose?.();
                    },
                    ...partialModalProps,
                },
                contentToDisplay: formComponent,
            });
        },
        buttonTextKey,
    };
};
