import { createContext, useContext } from "react";
import { IEntityFormData } from "../models/entity/form/IEntityFormData";

/**
 * The create entity context.
 */
export interface ICreateEntityContext<TEntity> {
    show: boolean;
    errorMessage: string;
    data: TEntity;
    dirty: boolean;
    canCreate: boolean;
    creating: boolean;
    onChange: (formData: IEntityFormData<TEntity>) => void;
    create: () => Promise<void>;
    resetErrorMessage: () => void;
    createLabel?: string;
}

/**
 * The create entity context.
 */
export const CreateEntityContext = createContext<ICreateEntityContext<any>>(null!);

export const useCreateEntityContext = () => useContext(CreateEntityContext);
