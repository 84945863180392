import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { languagePaths } from "../../PathConstants";

interface ILanguageTableNameColumnProps {
    name: string;
    defaultLanguage: boolean;
    organizationName: string;
    projectName: string;
    languageId: string;
}

export const LanguageTableNameColumn: React.FC<ILanguageTableNameColumnProps> = ({
    name,
    defaultLanguage,
    organizationName,
    projectName,
    languageId,
}) => {
    const { t } = useTranslation();
    return (
        <div>
            <Link className="text-truncate" to={languagePaths.link.edit(organizationName, projectName, languageId)}>
                {name}
            </Link>
            {defaultLanguage && <Badge color="primary">{t("Language.Default")}</Badge>}
        </div>
    );
};
