import React from "react";
import * as Yup from "yup";
import * as YupType from "yup/lib/types";
import { InvalidCharacterRegex, InvalidCharacterValidationError } from "../helpers/InvalidCharacterHelper";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

/**
 * The name validator fields interface.
 */
interface INameValidatorFields {
    name: string;
}

/**
 * The name validator props interface.
 */
interface INameValidatorProps {
    nameExistErrorKey: string;
    nameIsUnique: (name: string) => Promise<boolean>;
    nameLeadingTrailingSpacesErrorKey?: string;
}

/**
 * The name validator class.
 */
export class NameValidator extends FormValidator<INameValidatorFields> {
    private static readonly nameMaxLength: number = 450;
    protected schema = Yup.object().shape({
        name: Yup.string()
            .max(NameValidator.nameMaxLength, ({ max }) => {
                return <ValidatorError messageKey="NameValidator.NameMaxLengthValidation" options={{ max }} />;
            })
            .required(() => <ValidatorError messageKey="NameValidator.Required" />)
            .test(
                "is-unique",
                (params: YupType.MessageParams) => {
                    if (!this.validCharacterStatusError) {
                        return InvalidCharacterValidationError;
                    } else {
                        return (
                            <ValidatorError
                                messageKey={this.nameExistErrorKey}
                                options={{ name: params.value as string }}
                            />
                        );
                    }
                },
                (nameValue?: string) => {
                    const [previousName, isPreviousUnique] = this.lastNameCheck;
                    if (previousName === nameValue) {
                        return isPreviousUnique;
                    }

                    this.lastNameCheck = this.throttledNameIsUnique(nameValue);

                    // The name isn't used for now so we only destructure the promise.
                    const [, newIsUnique] = this.lastNameCheck;

                    return newIsUnique;
                },
            )
            .test(
                "match",
                (params: YupType.MessageParams) => {
                    return InvalidCharacterValidationError;
                },
                (value) => (this.validCharacterStatusError = InvalidCharacterRegex.test(value!)),
            ),
    });

    private lastNameCheck: [string | undefined, Promise<boolean | Yup.ValidationError>] = ["", Promise.resolve(false)];

    private currentTimeout: number = 0;
    private nameCheckThrottle: number = 500;
    private nameExistErrorKey: string = "";
    private nameLeadingTrailingSpacesErrorKey: string = "";
    private nameIsUnique: (name: string) => Promise<boolean>;

    private validCharacterStatusError: boolean = true;

    constructor({
        nameExistErrorKey,
        nameLeadingTrailingSpacesErrorKey = "NameValidator.NameLeadingTrailingSpacesError",
        nameIsUnique,
    }: INameValidatorProps) {
        super();
        this.nameExistErrorKey = nameExistErrorKey;
        this.nameIsUnique = nameIsUnique;
        this.nameLeadingTrailingSpacesErrorKey = nameLeadingTrailingSpacesErrorKey;
    }

    public validateName(name: string): Promise<string> {
        return this.schema.fields.name.validate(name);
    }

    private throttledNameIsUnique(name?: string): [string | undefined, Promise<boolean | Yup.ValidationError>] {
        const checkNamePromise = new Promise<boolean | Yup.ValidationError>((resolve) => {
            if (this.currentTimeout) {
                clearTimeout(this.currentTimeout);
            }

            this.currentTimeout = window.setTimeout(() => {
                if (!name || name.length > NameValidator.nameMaxLength) {
                    resolve(false);

                    return;
                }

                if (this.initialValue && this.initialValue.name.toUpperCase() === name.toUpperCase()) {
                    resolve(true);

                    return;
                }

                if (FormValidator.valueHasTrailingSpaces(name)) {
                    resolve(new Yup.ValidationError(this.nameLeadingTrailingSpacesErrorKey, name, "name"));

                    return;
                }

                (async () => {
                    try {
                        const response = await this.nameIsUnique(name);

                        if (!this.validCharacterStatusError) {
                            resolve(false);
                        } else {
                            resolve(response);
                        }
                    } catch (error) {
                        resolve(new Yup.ValidationError("FormValidator.ValidationError", name, "name"));
                    }
                })();
            }, this.nameCheckThrottle);
        });

        return [name, checkNamePromise];
    }
}
