import { INavMenuItemProps } from "../components/navbars/NavMenuItem";
import { useEditEntity } from "../hooks/EditEntityHook";

/**
 * Build the projects tab.
 */
export const buildProjects = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Projects",
    name: "projects",
    testSelectorValue: "projectsItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-suitcase",
});

/**
 * Build the overview tab.
 */
export const buildOverview = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Overview",
    name: "overview",
    testSelectorValue: "overviewItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-th-large",
});

/**
 * Build the design tab.
 */
export const buildDesign = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Design",
    name: "design",
    testSelectorValue: "designItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-drafting-compass",
});

/**
 * Build the schema tab.
 */
export const buildSchema = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Schema",
    name: "schema",
    testSelectorValue: "schemaItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-brackets-curly",
});

/**
 * Build the sample tab.
 */
export const buildSamples = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Samples",
    name: "samples",
    testSelectorValue: "samplesItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-file-alt",
});

/**
 * Build the settings tab.
 */
export const buildSettings = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Settings",
    name: "settings",
    testSelectorValue: "settingsItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-cog",
});

/**
 * Build the dependencies tab.
 */
export const buildDependencies = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Dependencies",
    name: "dependencies",
    testSelectorValue: "dependenciesItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-chart-network",
});

/**
 * Build the versioning tab.
 */
export const buildVersioning = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Versions",
    name: "versions",
    testSelectorValue: "versionsItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-file-check",
});

/**
 * Build the history tab.
 */
export const buildHistory = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.History",
    name: "history",
    testSelectorValue: "historyItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-history",
});

/**
 * Build the language tab.
 */
export const buildLanguages = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
    hidden?: boolean,
): INavMenuItemProps => ({
    displayName: "Common.Language_plural",
    name: "languages",
    testSelectorValue: "languageItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-globe",
    hidden,
});

/**
 * Build the users tab.
 */
export const buildUsers = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Users",
    name: "users",
    testSelectorValue: "usersItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-user",
});

/**
 * Build the permissions tab.
 */
export const buildPermissions = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Permissions",
    name: "permissions",
    testSelectorValue: "permissionsItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-lock",
});

/**
 * Build the service hooks tab.
 */
export const buildServiceHooks = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
    hidden?: boolean,
): INavMenuItemProps => ({
    displayName: "ServiceHook.Title",
    name: "serviceHooks",
    testSelectorValue: "serviceHooksItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-webhook",
    hidden,
});

/**
 * Build the shares tab.
 */
export const buildShares = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.Shares",
    name: "shares",
    testSelectorValue: "sharesItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-share-nodes",
});

/**
 * Build the license tab.
 */
export const buildLicense = (
    invalid: boolean | undefined,
    dirty: boolean | undefined,
    onToggle: (name: string) => void,
): INavMenuItemProps => ({
    displayName: "Common.License",
    name: "license",
    testSelectorValue: "licenseItem",
    invalid,
    dirty,
    onToggle,
    icon: "far fa-id-card",
});

type CanSaveProps = Pick<ReturnType<typeof useEditEntity>, "isDirty" | "entityValid"> & {
    blockSave: boolean;
    isValidatingName: boolean;
    nameErrorMessage: string;
    isSaving: boolean;
    canEdit: boolean;
};

export const canSaveEntity = ({
    isDirty,
    isSaving,
    isValidatingName,
    nameErrorMessage,
    blockSave,
    entityValid,
    canEdit,
}: CanSaveProps): boolean =>
    canEdit && !blockSave && !isValidatingName && isDirty() && !nameErrorMessage && !isSaving && entityValid;
