import { EntityType } from "../EntityType";
import { TranslatableHashType } from "./HashType";

type BaseCrumb = {
    hash?: TranslatableHashType;
};

export type NamedCrumb = BaseCrumb & { name: string };

export type DynamicCrumb = BaseCrumb & { parameterName: string };

export type EntityCrumb = BaseCrumb & { entityType: EntityType };

export type ContentManagerEntityCrumb = BaseCrumb & { entityType: "ContentManagerItem" };

export type CrumbType = NamedCrumb | DynamicCrumb | EntityCrumb | ContentManagerEntityCrumb;

export const isDynamicCrumb = (crumb: CrumbType): crumb is DynamicCrumb => "parameterName" in crumb;

export const isEntityCrumb = (crumb: CrumbType): crumb is EntityCrumb =>
    "entityType" in crumb && crumb["entityType"] !== "ContentManagerItem";

export const isContentManagerEntityCrumb = (crumb: CrumbType): crumb is ContentManagerEntityCrumb =>
    "entityType" in crumb && crumb["entityType"] === "ContentManagerItem";
