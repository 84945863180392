import React from "react";
import { Modal } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useHotkeys } from "react-hotkeys-hook";
import { LoadingModalContent } from "./LoadingModalContent";

import "./EntityModal.scss";

/**
 * The load modal props interface.
 */
interface ILoadingModalProps {
    className?: string;
    show: boolean;
    onButtonClose?: () => void;
    onCancel?: () => void;
    size?: string;
    labelKey: string;
    children?: React.ReactNode;
}

/**
 * The load modal component.
 */
export const LoadingModal: React.FC<ILoadingModalProps> = ({
    className,
    onButtonClose,
    onCancel,
    labelKey,
    size = "sm",
    show,
}: ILoadingModalProps) => {
    const { t } = useTranslation();

    useHotkeys(
        "escape",
        (e) => {
            e.preventDefault();
            onButtonClose?.();
        },
        {
            enabled: show,
        },
        [show, onButtonClose],
    );

    return (
        <Modal className={classNames("loading-modal", className)} isOpen={show} fade={true} centered={true} size={size}>
            <LoadingModalContent labelKey={t(labelKey)} onClose={onButtonClose} onCancel={onCancel} />
        </Modal>
    );
};
