import React, { useMemo } from "react";
import classNames from "classnames";
import { ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { Button } from "../buttons/Button";

/**
 * The dialog props interface.
 */
interface IDialogHeaderProps {
    className?: string;
    icon?: string;
    title: string;
    onClose?: () => void;
}

/**
 * The dialog header component.
 */
export const DialogHeader: React.FunctionComponent<IDialogHeaderProps> = (props: IDialogHeaderProps) => {
    const { className, icon, title, onClose } = props;
    const { t } = useTranslation();

    const { setSelector } = useTestSelector();

    const closeButton = useMemo(() => {
        return (
            <Button
                color="flat"
                description={t("Common.Close")}
                type="button"
                className="close"
                onClick={onClose}
                {...setSelector("closeButton")}
                ariaLabel={t("Common.Close")}
            >
                <i className="fal fa-times" />
            </Button>
        );
    }, [onClose, setSelector, t]);

    return (
        <>
            <ModalHeader className={classNames("dialog-header", className)} close={closeButton}>
                {icon && <i className={icon} />}
                <span>{title}</span>
            </ModalHeader>
        </>
    );
};
