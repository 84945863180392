import React, { useMemo } from "react";
import { ContainerProps, GroupBase, components } from "react-select";
import { SelectComponentOptions } from "../SelectComponent";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";

interface ISelectContainerProps
    extends ContainerProps<SelectComponentOptions<any>, boolean, GroupBase<SelectComponentOptions<any>>> {
    testSelectorValue?: string;
}

export const SelectContainer: React.FC<ISelectContainerProps> = ({ innerProps, testSelectorValue, ...props }) => {
    const { setSelector } = useTestSelector();

    const internalInnerProps = useMemo(() => {
        return {
            ...innerProps,
            ...setSelector(testSelectorValue),
        };
    }, [innerProps, setSelector, testSelectorValue]);

    return <components.SelectContainer innerProps={internalInnerProps} {...props} />;
};
