import React, { useCallback } from "react";
import { FormGroup } from "reactstrap";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { IServiceHookModel } from "../../models/serviceHooks/IServiceHooksModel";
import { ServiceHookFormValidator } from "../../formValidators/ServiceHookFormValidator";
import { ServiceHookFormFields } from "../serviceHook/ServiceHookFormFields";
import { IServiceHookFormFields } from "../../models/serviceHooks/IServiceHookFormFields";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { ServiceHookEventType } from "../../models/serviceHooks/ServiceHookEventType";

import "./ServiceHookOverview.scss";

export const ServiceHookOverview: React.FC<{}> = () => {
    const {
        entityProps: { entity, setEntityProperties, formValidator },
        errorProps: { errors, setErrors },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IServiceHookModel, ServiceHookFormValidator>();

    const validateConfig = useCallback(
        (config: string) => {
            const error = { config: "" };
            void formValidator
                .validateConfig(config)
                .catch((e) => {
                    error.config = extractErrorMessageOrEmptyString(e);
                })
                .finally(() => {
                    setErrors(error);
                });
        },
        [formValidator, setErrors],
    );

    const validateEventType = useCallback(
        (eventType: "" | ServiceHookEventType) => {
            const error = { eventType: "" };
            void formValidator
                .validateEventType(eventType)
                .catch((e) => {
                    error.eventType = extractErrorMessageOrEmptyString(e);
                })
                .finally(() => {
                    setErrors(error);
                });
        },
        [formValidator, setErrors],
    );

    const onChange = useCallback(
        <TField extends keyof IServiceHookFormFields>(field: TField, value: IServiceHookFormFields[TField]) => {
            setEntityProperties({ [field]: value });
            if (field === "config") {
                validateConfig(value as string);
            }
            if (field === "eventType") {
                validateEventType(value as "" | ServiceHookEventType);
            }
        },
        [setEntityProperties, validateConfig, validateEventType],
    );

    return (
        <FormGroup className="service-hook-overview">
            <ServiceHookFormFields
                data={entity as IServiceHookFormFields}
                onChange={onChange}
                errors={errors}
                disabled={!canEdit}
            />
        </FormGroup>
    );
};
