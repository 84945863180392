import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../buttons/Button";
import { IAuthenticationSchemeModel } from "../../../models/account/IExternalProvider";

import "./EditExternalLoginsForm.scss";

/**
 * The available login button props interface.
 */
interface IAvailableLoginButtonProps {
    provider: IAuthenticationSchemeModel;
    onClick: () => void;
    messageKey: string;
}

/**
 * The available login button component.
 */
export const AvailableLoginButton: React.FC<IAvailableLoginButtonProps> = ({
    provider,
    onClick,
    messageKey,
}: IAvailableLoginButtonProps) => {
    const { t } = useTranslation();
    return (
        <Button
            className={provider.displayName.toLowerCase()}
            onClick={onClick}
            color="outline-secondary"
            ariaLabel={provider.displayName}
        >
            <i className={`logo fab fa-${provider.displayName.toLowerCase()}`} />
            {t(messageKey, { service: provider.displayName })}
        </Button>
    );
};
