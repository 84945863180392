import React from "react";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { OrganizationFormValidator } from "../../../formValidators/OrganizationFormValidator";
import { IOrganizationModel } from "../../../models/organization/IOrganizationModel";
import { OrganizationProjectsView } from "../OrganizationProjectsView";

export const EditOrganizationViewTabsProjects: React.FC = () => {
    const {
        entityProps: { initialEntity: organization },
    } = useEditEntityContext<IOrganizationModel, OrganizationFormValidator>();

    return (
        <OrganizationProjectsView organizationId={organization.organizationId} organizationName={organization.name} />
    );
};
