import React, { useMemo } from "react";
import classNames from "classnames";
import Avatar, { ReactAvatarProps } from "react-avatar";

import "./UserAvatar.scss";

export interface IUserAvatarProps extends ReactAvatarProps {
    parentType?: keyof typeof avatarStyleMapping;
}

const avatarStyleMapping = {
    dropdown: { size: "1.5rem", className: "user-avatar__dropdown" },
    list: { size: "2rem", className: "user-avatar__list" },
    pageHeader: { size: "3rem", className: "user-avatar__page-header" },
};

/**
 * The user avatar component.
 */
export const UserAvatar = ({ parentType = "list", className, initials, ...rest }: IUserAvatarProps) => {
    const upperCaseInitials = useMemo(() => initials && (initials as string).toLocaleUpperCase(), [initials]);
    const { className: classNameUserGroupStyle, size } = avatarStyleMapping[parentType];

    return (
        <Avatar
            className={classNames(className, classNameUserGroupStyle)}
            maxInitials={2}
            fgColor="#FFFFFF"
            size={size}
            round
            initials={upperCaseInitials}
            {...rest}
        />
    );
};
