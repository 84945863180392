import { createContext, useContext } from "react";

/**
 * The notification hub context prop.
 */
export type NotificationHubContextProp = { subscribeProjectCreation: (projectId: string) => void };

/**
 * The notification hub context.
 */
export const NotificationHubContext = createContext<NotificationHubContextProp | null>(null);

/**
 * The notification hub context for functional component.
 */
export const useNotificationHubContext = (): NotificationHubContextProp =>
    useContext(NotificationHubContext) as NotificationHubContextProp;
