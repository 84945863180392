import React, { useCallback } from "react";
import { PermissionSection } from "./PermissionSection";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { IEditPermissionGroupModel } from "../../../models/permissions/IEditPermissionGroupModel";
import { IPermissionModel } from "../../../models/permissions/IPermissionModel";
import { IGroupPermissionModel } from "../../../models/permissions/IGroupPermissionModel";

import "./ManageGroupPermissions.scss";

export const ManageGroupPermissions: React.FC = () => {
    const {
        entityProps: {
            entity: { groupPermissions },
            setEntityProperties,
        },
    } = useEditEntityContext<IEditPermissionGroupModel, any>();

    const handlePermissionsChange = useCallback(
        (scope: string, permissions: IPermissionModel[]) => {
            const groupIndex = groupPermissions.findIndex((gp) => gp.scope === scope);

            const modifiedGroup: IGroupPermissionModel = { ...groupPermissions[groupIndex], permissions };

            const newGroupPermissions = [...groupPermissions];
            newGroupPermissions.splice(groupIndex, 1, modifiedGroup);

            setEntityProperties({ groupPermissions: newGroupPermissions });
        },
        [groupPermissions, setEntityProperties],
    );

    return (
        <div className="groups-permissions-container">
            {groupPermissions.map(({ scope, label, permissions }) => {
                const handlePermissionChange = (newPermissions: IPermissionModel[]) =>
                    handlePermissionsChange(scope, newPermissions);
                return (
                    <PermissionSection
                        handlePermissionChange={handlePermissionChange}
                        key={scope}
                        title={label}
                        permissions={permissions}
                    />
                );
            })}
        </div>
    );
};
