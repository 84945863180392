import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IEntityDropdownMenuItem } from "../../components/dropdowns/EntityDropdownMenu";
import { useDialogContext } from "../../contexts/DialogContext";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { ConfirmationDialogBody } from "../../components/dialogs/ConfirmationDialogBody";

/**
 * The refresh dropdown menu item.
 */
export const useRefresh = (): IEntityDropdownMenuItem => {
    const { t } = useTranslation();
    const editEntityContext = useEditEntityContext();
    const dialogContext = useDialogContext();

    const {
        dirtyProps: { isDirty, promptDialogMessageKey },
        entityProps: { load, setEntityProperties, setInitialEntityProperties },
        errorProps: { resetErrors },
        refreshProps: { dispatchRefresh },
    } = editEntityContext;

    const content = useMemo(
        () => <ConfirmationDialogBody messageKeys={[promptDialogMessageKey]} />,
        [promptDialogMessageKey],
    );

    const loadDetails = async (): Promise<void> => {
        try {
            const originalEntity = await load();
            setEntityProperties(originalEntity);
            setInitialEntityProperties(originalEntity);
            resetErrors();
            dispatchRefresh();
        } catch (error) {
            return;
        }
    };

    const onRefresh = () => {
        if (isDirty()) {
            dialogContext.show({
                type: "Warning",
                content,
                onProceed: loadDetails,
            });
        } else {
            void loadDetails();
        }
    };

    return {
        className: "fal fa-sync",
        text: t("Common.Refresh"),
        testSelectorValue: "refreshItem",
        onClick: onRefresh,
    };
};
