import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";

interface PermissionKeyHookProps {
    permission: string;
    objectId?: string;
    projectId?: string;
}

export const usePermissionKey = ({ permission, objectId, projectId }: PermissionKeyHookProps): string => {
    return formatPermissionKey(permission, objectId, projectId);
};
