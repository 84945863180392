import React from "react";
import { DefaultTableFilter } from "../../components/table/DefaultTableFilter";
import { ITableFilterHelper } from "../../components/table/Table";

export const useDefaultTableFilterHelper = (placeholderKey: string): ITableFilterHelper<string> => {
    return {
        filter: (filterValue, onChange) => (
            <DefaultTableFilter placeHolderNameKey={placeholderKey} value={filterValue} onChange={onChange} />
        ),
        isActive: value => Boolean(value),
        defaultFilterValue: "",
    };
};