import React from "react";
import { Outlet } from "react-router-dom";
import { useOrganizationSidebar } from "../../hooks/organization/OrganizationSidebarHook";
import { SidebarLayout } from "../../layouts/SidebarLayout";

export const OrganizationLayout = () => {
    const { footerRoutes, mainRoutes } = useOrganizationSidebar();

    return (
        <SidebarLayout footerRoutes={footerRoutes} routes={mainRoutes}>
            <Outlet />
        </SidebarLayout>
    );
};
