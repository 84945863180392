import React, { useCallback, useMemo, useState } from "react";
import { IBlockOptions, NavigationBlockerContext } from "../../contexts/NavigationBlockerContext";
import { IBlockerControl, useNavigationBlocker } from "../../hooks/navigation/NavigationBlockerHook";
import { useDialogContext } from "../../contexts/DialogContext";
import { ConfirmationDialogBody } from "../dialogs/ConfirmationDialogBody";

interface IBlockedRecord {
    shouldBlock: boolean;
    messageKey: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

export const NavigationBlockerProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}: React.PropsWithChildren) => {
    const { show: showDialog } = useDialogContext();

    const [isBlockedRecords, setIsBlockedRecords] = useState<Record<string, IBlockedRecord>>({});

    const setShouldBlock: (options: IBlockOptions) => () => void = useCallback(
        ({ componentId, shouldBlock, ...options }) => {
            setIsBlockedRecords((prevIsBlocked) => {
                if (!prevIsBlocked[componentId] || shouldBlock !== prevIsBlocked[componentId].shouldBlock) {
                    return {
                        ...prevIsBlocked,
                        [componentId]: { shouldBlock, ...options },
                    };
                }

                return prevIsBlocked;
            });

            return () => setShouldBlock({ shouldBlock: false, componentId, ...options });
        },
        [],
    );

    const shouldBlock = useMemo(() => {
        return Object.values(isBlockedRecords).some((rec) => rec.shouldBlock);
    }, [isBlockedRecords]);

    const messageKeys = useMemo(() => {
        return Object.values(isBlockedRecords)
            .filter((record) => record.shouldBlock)
            .map((record) => record.messageKey);
    }, [isBlockedRecords]);

    const onProceed = useMemo(() => {
        const proceed = Object.values(isBlockedRecords)
            .filter((record) => record.shouldBlock)
            .map((record) => record.onConfirm);
        return () => {
            proceed.forEach((p) => p && p());
        };
    }, [isBlockedRecords]);

    const onCancel = useMemo(() => {
        const cancel = Object.values(isBlockedRecords)
            .filter((record) => record.shouldBlock)
            .map((record) => record.onCancel);
        return () => {
            cancel.forEach((p) => p && p());
        };
    }, [isBlockedRecords]);

    const content = useMemo(() => <ConfirmationDialogBody messageKeys={messageKeys} />, [messageKeys]);

    const onBlock = useCallback(
        (blocker: IBlockerControl) => {
            showDialog({
                type: "Warning",
                content,
                onProceed: () => {
                    onProceed();
                    return Promise.resolve(blocker.proceed());
                },
                onClose: () => {
                    onCancel();
                    blocker.reset();
                },
            });
        },
        [content, onCancel, onProceed, showDialog],
    );

    useNavigationBlocker({
        onBlock,
        enabled: shouldBlock,
    });

    return <NavigationBlockerContext.Provider value={{ setShouldBlock }}>{children}</NavigationBlockerContext.Provider>;
};
