import { useCallback } from "react";
import { IAddMemberResultModel } from "../../models/permissions/IAddMemberResultModel";
import { useCancellableRequest } from "../CancellableRequestHook";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { useHttpRequest } from "../HttpRequestHook";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { ISortField } from "../../models/ISortField";
import { IEntityResult } from "../../models/IEntityResult";
import { IUserModel } from "../../models/user/IUserModel";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { IUserGroupModel } from "../../models/user/IUserGroupModel";
import { IPermissionsGroupWithAccessInfoModel } from "../../models/permissions/IPermissionsGroupWithAccessInfoModel";

interface PermissionGroupApiHookReturn {
    addMembers: (ugroupId: string, serIds: string[]) => Promise<IAddMemberResultModel>;
    getAllGroups: () => Promise<IUserGroupModel[]>;
    getById: (groupId: string) => Promise<IPermissionsGroupWithAccessInfoModel>;
    getMemberRecords: (
        groupId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IUserModel>>;
    getNonMembers: (groupId: string) => Promise<IUserModel[]>;
    getRecords: (
        filters: Record<string, string>,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IPermissionsGroupModel>>;
    removeMembers: (groupId: string, userIds: string[]) => Promise<IDeleteErrorResult[]>;
}

const baseUrl = (groupId?: string) => `/_api/groups${groupId ? "/" + groupId : ""}`;

export const usePermissionGroupApi = (): PermissionGroupApiHookReturn => {
    const { httpGetJson, httpPost, httpDeleteResponseJson } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const addMembers = useCallback(
        (groupId: string, userIds: string[]): Promise<IAddMemberResultModel> =>
            cancellableRequest<IAddMemberResultModel, string[]>(
                { url: `${baseUrl(groupId)}/members`, body: userIds },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const getAllGroups = useCallback(
        () => cancellableRequest<IUserGroupModel[]>({ url: `${baseUrl()}/all` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getById = useCallback(
        (groupId: string) =>
            cancellableRequest<IPermissionsGroupWithAccessInfoModel>({ url: `${baseUrl(groupId)}` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getMemberRecords = useCallback(
        (groupId: string, filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Email = filterValue;
            }

            return cancellableRequest<IEntityResult<IUserModel>>(
                {
                    url: `${baseUrl(groupId)}/members`,
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const getNonMembers = useCallback(
        (groupId: string) =>
            cancellableRequest<IUserModel[]>(
                {
                    url: `${baseUrl(groupId)}/nonmembers`,
                },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    const getRecords = useCallback(
        (filters: Record<string, string>, sortFields?: ISortField[], offset?: number) => {
            return cancellableRequest<IEntityResult<IPermissionsGroupModel>>(
                {
                    url: baseUrl(),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const removeMembers = useCallback(
        (groupId: string, userIds: string[]) =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: `${baseUrl(groupId)}/members`, body: userIds },
                httpDeleteResponseJson,
            ),
        [httpDeleteResponseJson, cancellableRequest],
    );

    return {
        addMembers,
        getAllGroups,
        getById,
        getMemberRecords,
        getNonMembers,
        getRecords,
        removeMembers,
    };
};
