import React, { cloneElement } from "react";
import { Collapse, NavbarBrand, Navbar, Nav, NavbarBrandProps } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../buttons/Button";
import { IRoute } from "../../models/IRoute";
import { SidebarNavItem } from "./SidebarNavItem";

import "./Sidebar.scss";

/**
 * The Sidebar props interface.
 */
interface ISidebarProps {
    toggleSidenav: () => void;
    collapseSidenav: () => void;
    sidenavOpen: boolean;
    routes: IRoute[];
    footerRoutes?: IRoute[];
    logo?: {
        innerLink?: string;
        outterLink?: string;
        imgSrc: string;
        imgAlt: string;
    };
}

const isHTMLElement = (element: JSX.Element) => {
    return typeof element.type === "string";
};

export const sidebarWidthBreakpoint = 768;

/**
 * The Sidebar class component.
 */
export const Sidebar: React.FC<ISidebarProps> = ({
    routes,
    footerRoutes,
    logo,
    toggleSidenav,
    sidenavOpen,
    collapseSidenav,
}) => {
    const { t } = useTranslation();
    const location = useLocation();

    let navbarBrandProps: Readonly<NavbarBrandProps>;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: "_blank",
        };
    } else {
        navbarBrandProps = {};
    }

    // verifies if routeName is the one active (in browser input)
    const isActiveRoute = (routeName: string): boolean => {
        return !!(location && location.pathname.indexOf(routeName.split(/[?#]/)[0]) > -1);
    };

    // this is used on mobile devices, when a user navigates the sidebar will autoclose
    const handleCollapse = (): void => {
        if (window.innerWidth < sidebarWidthBreakpoint) {
            collapseSidenav();
        }
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createNavItems = (navItems: IRoute[]): (null | JSX.Element)[] => {
        return navItems.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }

            return (
                <SidebarNavItem
                    key={key}
                    onClick={handleCollapse}
                    path={prop.path}
                    active={prop.path ? isActiveRoute(prop.path) : false}
                    external={prop.external}
                    testSelectorValue={prop.testSelectorValue}
                >
                    {prop.children &&
                        (isHTMLElement(prop.children)
                            ? prop.children
                            : cloneElement(prop.children, { isCollapsed: sidenavOpen }))}
                </SidebarNavItem>
            );
        });
    };

    return (
        <Navbar className="main-sidenav sidenav navbar-vertical bg-white" container={false}>
            <div className="sidenav-header">
                <Button
                    className="sidenav-toggler single"
                    onClick={toggleSidenav}
                    color="light"
                    ariaLabel={t(sidenavOpen ? "Navigation.Toggle.Show" : "Navigation.Toggle.Hide")}
                >
                    <i className="fas fa-bars" />
                </Button>
                {logo && (
                    <NavbarBrand {...navbarBrandProps} tabIndex={-1}>
                        <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
                    </NavbarBrand>
                )}
                <Button
                    className="sidenav-toggler"
                    onClick={toggleSidenav}
                    color="light"
                    ariaLabel={t(sidenavOpen ? "Navigation.Toggle.Show" : "Navigation.Toggle.Hide")}
                >
                    <i className="fas fa-bars" />
                </Button>
            </div>
            <div className="navbar-inner">
                <Collapse navbar isOpen={true}>
                    <Nav navbar>{createNavItems(routes)}</Nav>
                </Collapse>
            </div>
            {footerRoutes && (
                <div className="sidenav-footer">
                    <Collapse navbar isOpen={true}>
                        <Nav navbar>{createNavItems(footerRoutes)}</Nav>
                    </Collapse>
                </div>
            )}
        </Navbar>
    );
};
