import React from "react";
import { FormGroup, Input } from "reactstrap";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { ProjectFormValidator } from "../../formValidators/ProjectFormValidator";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { IProjectModel } from "../../models/project/IProjectModel";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { LocalizedLabel } from "../forms/LocalizedLabel";

import "./ProjectOverview.scss";
import { formatBytes } from "../../helpers/formattingHelper";

export const ProjectOverview = (): JSX.Element => {
    const {
        entityProps: { entity: project, setEntityProperties, formValidator },
        errorProps: { errors, setErrors },
        validateNameProps: { checkNameIsUnique, isValidatingName },
        dirtyProps: { isDirty },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IProjectModel, ProjectFormValidator>();

    const { setSelector } = useTestSelector();

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setEntityProperties({ name: event.target.value });
        checkNameIsUnique(event.target.value);
    };

    const onNameFocus = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.target.select();
    };

    const onDescriptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let errorMessage: string;

        try {
            setEntityProperties({ description: event.target.value });
            await formValidator.validateDescription(event.target.value);
            errorMessage = "";
        } catch (error) {
            errorMessage = extractErrorMessageOrEmptyString(error);
        }

        setErrors({ description: errorMessage });
    };

    const isInvalid = !isValidatingName && !!errors.name;

    return (
        <div className="project-overview">
            <FormGroup className="entity-name">
                <LocalizedLabel for="edit-project-name" text="Common.Name" />
                <ValidatedInputWithStatus
                    id="edit-project-name"
                    autoFocus={true}
                    autoComplete="off"
                    name="name"
                    type="text"
                    invalid={isInvalid}
                    onChange={onNameChange}
                    error={errors.name}
                    isBusy={isValidatingName}
                    value={project.name}
                    hideStatus={!isDirty(["name"])}
                    onFocus={onNameFocus}
                    disabled={!canEdit}
                    {...setSelector("nameTextbox")}
                />
            </FormGroup>
            <FormGroup className="entity-description">
                <LocalizedLabel for="edit-project-description" text="Common.Description" />
                <ValidatedInput
                    id="edit-project-description"
                    name="description"
                    type="textarea"
                    onChange={onDescriptionChange}
                    error={errors.description}
                    value={project.description}
                    disabled={!canEdit}
                    {...setSelector("descriptionTextbox")}
                />
            </FormGroup>
            <FormGroup className="entity-storagesize">
                <LocalizedLabel for="edit-project-storagesize" text="Common.StorageUsed" />
                <Input id="edit-project-storagesize" disabled value={formatBytes(project.storageSize, 2)} />
            </FormGroup>
        </div>
    );
};
