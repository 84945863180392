import { ElementType, WeakValidationMap, useCallback } from "react";
import { useModalContext } from "../../contexts/ModalContext";
import { IExportFormModel } from "../../models/IExportFormModel";

interface IUseDefaultTableExportHelper<
    TEntity,
    TEntityFields extends IExportFormModel<TEntity>,
    TComponentProps,
    TFormComponentProps,
    TComponent = ElementType,
> {
    onExport: (entityFields: TEntityFields) => void;
    formComponent: TComponent;
    modalProps: TComponentProps;
    formProps: TFormComponentProps;
    initialEntity: Omit<TEntityFields, "items" | "packageName">;
    generateInitialPackageName: () => string;
}

interface IExportModalProps {
    className?: string;
    titleKey: string;
    unsavedWarningBody: string;
    size?: string;
    expandable?: boolean;
    onClose?: () => void;
    /** If not provided, "Export" will be used as default value. */
}

export const useDefaultExportHelper = <
    TEntity extends { name: string },
    TEntityFields extends IExportFormModel<TEntity>,
    TModalProps extends IExportModalProps,
    TFormProps extends WeakValidationMap<{}>,
>({
    formComponent,
    modalProps,
    formProps,
    onExport,
    initialEntity,
    generateInitialPackageName,
}: IUseDefaultTableExportHelper<TEntity, TEntityFields, TModalProps, TFormProps>): ((
    itemsToExport: TEntity[],
) => void) => {
    const { showModal, closeModal } = useModalContext();
    const { onClose, className, ...partialModalProps } = modalProps;

    const onCreateAction = useCallback(
        async (entity: TEntityFields) => {
            onExport(entity);
            return Promise.resolve({});
        },
        [onExport],
    );

    return (itemsToExport: TEntity[]) => {
        showModal({
            entity: {
                items: itemsToExport,
                packageName: itemsToExport.length === 1 ? itemsToExport[0].name : generateInitialPackageName(),
                ...initialEntity,
            },
            onCreate: onCreateAction,
            onCreated: () => {
                closeModal();
            },
            formPropsToFlow: formProps,
            modalProps: {
                onClose: () => {
                    closeModal();
                    onClose?.();
                },
                className: "export-modal",
                ...partialModalProps,
            },
            contentToDisplay: formComponent,
        });
    };
};
