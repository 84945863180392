import { createContext, useContext, Context } from "react";
import { ITableState } from "../../hooks/table/TableReducer";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { ITableDeleteHelper, ITableFilterHelper, TableCreateHelper, ITableRowDragAndDropProps } from "./Table";
import { ITableRowItem } from "./TableRow";
import { IExtraActions } from "../../models/IExtraActionsModel";

/**
 * The table context model interface.
 */
export interface ITableContextModel<TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue> {
    tableState: ITableState<TEntityModel, TRowItem, TFilterValue>;
    onFilterChange: (filterValue: TFilterValue) => void;
    columnDefs: IEntityTableColumnDef[];
    canLoadMore: boolean;
    className?: string;
    onLoadMore: () => void;
    keyExtractor: (item: TEntityModel) => string;
    onSelect: (itemId: string, overwriteSelection?: boolean) => void;
    loadingMessageKey: string;
    onToggleCreateEntityModal: (isDisplayed: boolean) => void;
    onToggleDeleteEntityModal: (isDisplayed: boolean) => void;
    onSetItemsForDeletion: (itemIdsToDelete: string[]) => void;
    useDefaultActionProviders?: boolean;
    rowDisabled?: (item: TEntityModel) => boolean;
    actionProvider?: (
        item: TEntityModel,
        context: ITableContextModel<TEntityModel, TRowItem, TFilterValue>,
    ) => ITableRowActionProps[];
    rowDragAndDropProvider?: (item: TEntityModel) => ITableRowDragAndDropProps;
    updateDropableStatus?: (canDrop: boolean, id: string) => void;
    selectable?: boolean;
    refreshable?: boolean;
    createHelper?: TableCreateHelper;
    deleteHelper?: ITableDeleteHelper<TEntityModel>;
    filterHelper?: ITableFilterHelper<TFilterValue>;
    setIsFilterPanelOpen: (open: boolean) => void;
    selectAll: () => void;
    unselectAll: () => void;
    extraActions?: IExtraActions<TEntityModel, TRowItem, TFilterValue>[];
}

export const TableContext = createContext<any>(null);

export const useTableContext = <
    TEntityModel,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>(): ITableContextModel<TEntityModel, TRowItem, TFilterValue> | null =>
    useContext<ITableContextModel<TEntityModel, TRowItem, TFilterValue> | null>(
        TableContext as Context<ITableContextModel<TEntityModel, TRowItem, TFilterValue> | null>,
    );
