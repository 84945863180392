import React, { useContext } from "react";
import { useContentManagerTree } from "../hooks/contentManager/ContentManagerTreeHook";

type ContentManagerContextProps = ReturnType<typeof useContentManagerTree>;

/**
 * The ContentManager context.
 */
export const ContentManagerContext = React.createContext<ReturnType<typeof useContentManagerTree> | null>(null);

/**
 * The use content manager context.
 */
export const useContentManagerContext = (): ContentManagerContextProps =>
    useContext(ContentManagerContext) as ContentManagerContextProps;
