import React from "react";
import { Route } from "react-router";
import { applicationPaths, loginActions } from "../api-authorization/ApiAuthorizationConstants";
import { Login } from "../api-authorization/Login";

export const loginRoutes = (
    <Route>
        <Route path={applicationPaths.login} element={<Login action={loginActions.login} />} />
        <Route path={applicationPaths.loginFailed} element={<Login action={loginActions.loginFailed} />} />
        <Route path={applicationPaths.loginCallback} element={<Login action={loginActions.loginCallback} />} />
        <Route path={applicationPaths.profile} element={<Login action={loginActions.profile} />} />
        <Route path={applicationPaths.register} element={<Login action={loginActions.register} />} />
    </Route>
);
