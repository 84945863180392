import { useMemo } from "react";
import { ValidationStatus } from "../../models/entity/validation/ValidationStatus";

interface IUseValidationStatusProps {
    validationStatuses: Record<string, ValidationStatus>;
    valid: boolean;
}

export const useValidationStatus = ({ validationStatuses, valid }: IUseValidationStatusProps): ValidationStatus => {
    const validationStatus = useMemo<ValidationStatus>(() => {
        let isValidating = false;
        let isNotValid = false;

        for (const status in validationStatuses) {
            if (validationStatuses[status] === "Invalid") {
                isNotValid = true;
            } else if (validationStatuses[status] === "Validating") {
                isValidating = true;
            }
        }

        if (isValidating) {
            return "Validating";
        }
        if (isNotValid) {
            return "Invalid";
        }

        return valid ? "Valid" : "Invalid";
    }, [validationStatuses, valid]);

    return validationStatus;
};
