import React from "react";
import { Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { ILanguageModel } from "../../models/Languages/ILanguageModel";
import { FormValidator } from "../../formValidators/FormValidator";

export const EditLanguageHeaderBadge: React.FC = () => {
    const {
        entityProps: {
            entity: { isDefault },
        },
    } = useEditEntityContext<ILanguageModel, FormValidator<unknown>>();
    const { t } = useTranslation();

    return <>{isDefault && <Badge color="primary">{t("Language.Default")}</Badge>}</>;
};
