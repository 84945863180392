import { useCallback } from "react";
import {
    ContentFileShareBehaviors,
    IContentEntityModel,
    IContentFileShareModel,
    ICreateContentFileShareModel,
    IUpdateContentFileShareModel,
} from "../../../models/contentManager/ContentManagerApiModels";
import { useCancellableRequest } from "../../CancellableRequestHook";
import { useHttpRequest } from "../../HttpRequestHook";
import { ISortField } from "../../../models/ISortField";
import { IEntityResult } from "../../../models/IEntityResult";
import { normalizeQueryParams } from "../../../helpers/HttpHelper";
import { IDeleteErrorResult } from "../../../models/IDeleteErrorResult";

export type ShareStatus = boolean;

export interface ShareTableFilter {
    filterText: string;
    status: ShareStatus[];
    behaviors: ContentFileShareBehaviors[];
}

interface IContentFileShareApiProps {
    projectId: string;
}

export const useContentFileShareApi = ({ projectId }: IContentFileShareApiProps) => {
    const contentFileShareLink = useCallback(
        (fileId: string, shareId?: string) => `/_api/project/${projectId}/file/${fileId}/shares/${shareId ?? ""}`,
        [projectId],
    );

    const { httpGetJson, httpPut, httpDelete, httpDeleteResponseJson, httpPost } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const get = useCallback(
        (fileId: string, shareId: string) =>
            cancellableRequest<IContentEntityModel>({ url: contentFileShareLink(fileId, shareId) }, httpGetJson),
        [contentFileShareLink, cancellableRequest, httpGetJson],
    );

    const getRecords = useCallback(
        (fileId: string, filterValue?: ShareTableFilter, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                if (filterValue.filterText) {
                    filters["Key"] = filterValue.filterText;
                }

                if (filterValue.status.length > 0) {
                    filters["Expired"] = JSON.stringify(filterValue.status.map((s) => (!s).toString()));
                }

                if (filterValue.behaviors.length > 0) {
                    filters["Behaviors"] = JSON.stringify(filterValue.behaviors);
                }
            }

            const queryParams = normalizeQueryParams(
                {
                    filters,
                    sortFields,
                    offset,
                },
                false,
            );

            return cancellableRequest<IEntityResult<IContentFileShareModel>>(
                {
                    url: contentFileShareLink(fileId),
                    queryParams,
                },
                httpGetJson,
            );
        },
        [contentFileShareLink, httpGetJson, cancellableRequest],
    );

    const create = useCallback(
        (fileId: string, model: ICreateContentFileShareModel) =>
            cancellableRequest<IContentFileShareModel, ICreateContentFileShareModel>(
                {
                    url: contentFileShareLink(fileId),
                    body: model,
                },
                httpPost,
            ),
        [cancellableRequest, contentFileShareLink, httpPost],
    );

    const update = useCallback(
        (fileId: string, contentFileShareId: string, model: IUpdateContentFileShareModel) =>
            cancellableRequest<IContentFileShareModel, IUpdateContentFileShareModel>(
                { url: `${contentFileShareLink(fileId, contentFileShareId)}`, body: model },
                httpPut,
            ),
        [contentFileShareLink, httpPut, cancellableRequest],
    );

    const remove = useCallback(
        (fileId: string, contentFileShareId: string) =>
            cancellableRequest({ url: contentFileShareLink(fileId, contentFileShareId) }, httpDelete),
        [contentFileShareLink, httpDelete, cancellableRequest],
    );

    const removeRecords = useCallback(
        (fileId: string, ids: string[]) =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: contentFileShareLink(fileId), body: ids },
                httpDeleteResponseJson,
            ),
        [contentFileShareLink, cancellableRequest, httpDeleteResponseJson],
    );

    return {
        get,
        getRecords,
        create,
        update,
        remove,
        removeRecords,
    };
};
