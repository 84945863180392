import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface IUseModalNavigationHandlerProps {
    close: () => void;
    isOpen: boolean;
}

/**
 * this hook handle the closing of modals when navigating
 */
export const useNavigationCloseHandler = ({ close, isOpen }: IUseModalNavigationHandlerProps) => {
    const { pathname } = useLocation();
    const [currentPathname, setCurrentPathname] = useState(pathname);

    const pathnamesNotEqual = pathname !== currentPathname;

    useEffect(() => {
        isOpen && pathnamesNotEqual && close();
        pathnamesNotEqual && setCurrentPathname(pathname);
    }, [close, isOpen, pathname, pathnamesNotEqual]);
};
