import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { TwoFAContext, TwoFAContextProp } from "../../../contexts/TwoFAContext";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { ITwoFASettings } from "../../../models/account/ITwoFASettings";
import { AccountView } from "../AccountView";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";

import "./Manage2FAView.scss";

/**
 * The Manage 2FA View.
 */
export const Manage2FAView: React.FC = () => {
    const { t } = useTranslation();
    const [settings, setSettings] = useState<ITwoFASettings | null>(null);
    const { getTwoFASettings } = useAccountApi();

    const fetchSettings = useCallback(async () => {
        try {
            const newSettings = await getTwoFASettings();
            setSettings(() => {
                return { ...newSettings };
            });
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [getTwoFASettings]);

    const contextValue = useMemo<TwoFAContextProp>(() => {
        return {
            fetchSettings,
            twoFASettings: settings,
        };
    }, [fetchSettings, settings]);

    return (
        <TwoFAContext.Provider value={contextValue}>
            <AccountView title={t("2FA.Title")} className="two-factor-authentication">
                <Outlet />
            </AccountView>
        </TwoFAContext.Provider>
    );
};
