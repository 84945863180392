import React from "react";
import { DataSetType } from "../../models/dataSets/DataSetType";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectComponent, SelectComponentOptions } from "../selects/SelectComponent";

import "./SelectDataSetType.scss";

interface ISelectDataSetTypeProps {
    value: DataSetType;
    options: SelectComponentOptions<DataSetType>[];
    onChange: (value: DataSetType) => void;
}

export const SelectDataSetType: React.FC<ISelectDataSetTypeProps> = ({ value, options, onChange }) => {
    if (options.length === 1) {
        const [type] = options;
        return (
            <>
                <LocalizedLabel text="Common.Type" />
                <LocalizedLabel className="single-type-content" icon={type.icon} text={type.label} />
            </>
        );
    }

    return (
        <div className="multiple-type-content">
            <LocalizedLabel for="select-dataset-type" text="Common.Type" />
            <SelectComponent
                id="select-dataset-type"
                size="large"
                value={value}
                options={options}
                onChange={onChange}
                testSelectorValue="typeDropdown"
            />
        </div>
    );
};
