import { useCallback } from "react";
import { useHttpRequest } from "../HttpRequestHook";
import { IProfileModel } from "../../models/account/IProfileModel";
import { IChangePasswordModel, IUserPasswordSettingsModel } from "../../models/account/IPasswordModel";
import { IAuthenticationSchemeModel, ILoginModel, IUserLoginInfoModel } from "../../models/account/IExternalProvider";
import { IAuthenficatorModel } from "../../models/account/IAuthentificatorModel";
import { ITwoFASettings } from "../../models/account/ITwoFASettings";
import { useCancellableRequest } from "../CancellableRequestHook";
import { ITimeZoneModel } from "../../models/account/ITimeZoneModel";

interface AccountHookReturn {
    addPassword: (newPassword: string) => Promise<void>;
    changePassword: (changePasswordModel: IChangePasswordModel) => Promise<void>;
    deletePersonalData: (password: string) => Promise<void>;
    disableTwoFA: () => Promise<void>;
    downloadPersonalData: () => Promise<void>;
    forgetTwoFABrowser: () => Promise<void>;
    getAvailableExternalProviders: () => Promise<IAuthenticationSchemeModel[]>;
    getEmail: () => Promise<string>;
    getExternalLoginsProviderCount: () => Promise<number>;
    getLanguages: () => Promise<Record<string, string>[]>;
    getNewRecoveryCodes: () => Promise<string[]>;
    getPasswordSettings: () => Promise<IUserPasswordSettingsModel>;
    getProfile: () => Promise<IProfileModel>;
    getSharedKeyAndCodeURI: () => Promise<IAuthenficatorModel>;
    getTimeZones: () => Promise<ITimeZoneModel[]>;
    getTwoFASettings: () => Promise<ITwoFASettings>;
    getUserLoginExternalProviders: () => Promise<IUserLoginInfoModel[]>;
    getUserHasPassword: () => Promise<boolean>;
    removeUserLoginExternalProvider: (login: ILoginModel) => Promise<boolean>;
    resetTwoFA: () => Promise<void>;
    consentPreview: (consent: boolean) => Promise<void>;
    updateEmail: (email: string) => Promise<void>;
    updateProfile: (model: IProfileModel) => Promise<IProfileModel>;
    verifyEmail: () => Promise<void>;
    verify2FA: (input: string) => Promise<string[] | null>;
}

const accountUrl = "/_api/account";
const emailUrl = `${accountUrl}/email`;
const externalProvidersUrl = `${accountUrl}/externalproviders`;
const passwordUrl = `${accountUrl}/password`;
const profileUrl = `${accountUrl}/profile`;
const twoFactorAuthenticatorUrl = `${accountUrl}/twofa`;

export const useAccountApi = (): AccountHookReturn => {
    const { httpGetJson, httpPost, httpDownload } = useHttpRequest();

    const { cancellableRequest } = useCancellableRequest();

    const addPassword = useCallback(
        (newPassword: string) => cancellableRequest({ url: `${passwordUrl}/add`, body: newPassword }, httpPost),
        [httpPost, cancellableRequest],
    );

    const changePassword = useCallback(
        (changePasswordModel: IChangePasswordModel) =>
            cancellableRequest({ url: `${passwordUrl}/change`, body: changePasswordModel }, httpPost),
        [httpPost, cancellableRequest],
    );

    const deletePersonalData = useCallback(
        (password: string) => cancellableRequest({ url: `${accountUrl}/personaldata`, body: password }, httpPost),
        [httpPost, cancellableRequest],
    );

    const disableTwoFA = useCallback(
        () => cancellableRequest({ url: `${twoFactorAuthenticatorUrl}/disable` }, httpPost),
        [httpPost, cancellableRequest],
    );

    const downloadPersonalData = useCallback(
        () => cancellableRequest({ url: `${accountUrl}/personaldata` }, httpDownload),
        [httpDownload, cancellableRequest],
    );

    const forgetTwoFABrowser = useCallback(
        () => cancellableRequest({ url: `${twoFactorAuthenticatorUrl}/forgetbrowser` }, httpPost),
        [httpPost, cancellableRequest],
    );

    const getAvailableExternalProviders = useCallback(
        () =>
            cancellableRequest<IAuthenticationSchemeModel[]>(
                {
                    url: `${externalProvidersUrl}/availableProviders`,
                },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    const getEmail = useCallback(
        () => cancellableRequest<string>({ url: emailUrl }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getExternalLoginsProviderCount = useCallback(
        () => cancellableRequest<number>({ url: `${externalProvidersUrl}/providerscount` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getLanguages = useCallback(
        () =>
            cancellableRequest<Record<string, string>[]>(
                {
                    url: `${accountUrl}/languages`,
                },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    const getNewRecoveryCodes = useCallback(
        () => cancellableRequest<string[]>({ url: `${twoFactorAuthenticatorUrl}/recoverycodes` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getPasswordSettings = useCallback(
        () => cancellableRequest<IUserPasswordSettingsModel>({ url: `${passwordUrl}/settings` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getProfile = useCallback(
        () => cancellableRequest<IProfileModel>({ url: profileUrl }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getSharedKeyAndCodeURI = useCallback(
        () =>
            cancellableRequest<IAuthenficatorModel>(
                { url: `${twoFactorAuthenticatorUrl}/authentificator` },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    const getTimeZones = useCallback(
        () =>
            cancellableRequest<ITimeZoneModel[]>(
                {
                    url: `${accountUrl}/timezones`,
                },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    const getTwoFASettings = useCallback(
        () => cancellableRequest<ITwoFASettings>({ url: `${twoFactorAuthenticatorUrl}/settings` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getUserLoginExternalProviders = useCallback(
        () =>
            cancellableRequest<IUserLoginInfoModel[]>(
                { url: `${externalProvidersUrl}/userloginproviders` },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    const getUserHasPassword = useCallback(
        () => cancellableRequest<boolean>({ url: `${accountUrl}/userhaspassword` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const resetTwoFA = useCallback(
        () => cancellableRequest({ url: `${twoFactorAuthenticatorUrl}/reset` }, httpPost),
        [httpPost, cancellableRequest],
    );

    const removeUserLoginExternalProvider = useCallback(
        (login: ILoginModel) =>
            cancellableRequest<boolean, ILoginModel>(
                { url: `${externalProvidersUrl}/removeuserloginprovider`, body: login },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const consentPreview = useCallback(
        (consent: boolean) =>
            cancellableRequest<void, boolean>({ url: `${accountUrl}/consentpreview`, body: consent }, httpPost),
        [cancellableRequest, httpPost],
    );

    const updateEmail = useCallback(
        (email: string) => cancellableRequest<void, string>({ url: `${emailUrl}/change`, body: email }, httpPost),
        [httpPost, cancellableRequest],
    );

    const updateProfile = useCallback(
        (profile: IProfileModel) =>
            cancellableRequest<IProfileModel, IProfileModel>({ url: profileUrl, body: profile }, httpPost),
        [httpPost, cancellableRequest],
    );

    const verify2FA = useCallback(
        (input: string) =>
            cancellableRequest<string[] | null, string>(
                { url: `${twoFactorAuthenticatorUrl}/authentificator`, body: input },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const verifyEmail = useCallback(
        () => cancellableRequest<void, never>({ url: `${emailUrl}/verify` }, httpPost),
        [httpPost, cancellableRequest],
    );

    return {
        addPassword,
        changePassword,
        deletePersonalData,
        disableTwoFA,
        downloadPersonalData,
        forgetTwoFABrowser,
        getAvailableExternalProviders,
        getEmail,
        getExternalLoginsProviderCount,
        getLanguages,
        getNewRecoveryCodes,
        getPasswordSettings,
        getProfile,
        getSharedKeyAndCodeURI,
        getTimeZones,
        getTwoFASettings,
        getUserLoginExternalProviders,
        getUserHasPassword,
        removeUserLoginExternalProvider,
        resetTwoFA,
        consentPreview,
        updateEmail,
        updateProfile,
        verify2FA,
        verifyEmail,
    };
};
