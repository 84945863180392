import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { FormGroup, Row } from "reactstrap";
import { EntityForm } from "../forms/EntityForm";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useContentManagerRenameValidation } from "../../hooks/contentManager/ContentManagerRenameValidation";
import { EntityType } from "../../models/EntityType";
import { IUpdateContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { IContentManagerRenameFormFields } from "../../models/contentManager/ContentManagerRenameFormFields";
import { splitFileNameFromExtension } from "../../helpers/ContentFileHelper";

import "./ContentManagerRenameForm.scss";

interface IContentManagerRenameFormProps {
    initialValue: IUpdateContentEntityModel;
    onFormChange: (formData: IEntityFormData<IContentManagerRenameFormFields>) => void;
    parentFolderId: string;
    contentType: EntityType;
    projectId: string;
}

export const ContentManagerRenameForm: React.FC<IContentManagerRenameFormProps> = ({
    initialValue,
    onFormChange,
    parentFolderId,
    contentType,
    projectId,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { setSelector } = useTestSelector();
    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: (fields) =>
            useContentManagerRenameValidation({ fields, projectId, parentFolderId, contentType }),
    });

    useEffect(() => {
        inputRef.current?.focus();
        inputRef.current?.select();
    }, []);

    const [nameValue, extension] = useMemo(() => {
        if (contentType === "Folder") {
            return [data.name];
        }

        return splitFileNameFromExtension(data.name);
    }, [contentType, data.name]);

    const handleNameChange = useCallback(
        (value: string): void => {
            let name = value;
            if (extension) {
                name += extension;
            }

            onChange("name", name);
        },
        [extension, onChange],
    );

    return (
        <EntityForm name="rename-file-folder" className="rename-file-folder">
            <Row>
                <FormGroup>
                    <LocalizedLabel aria-required="true" for="rename-file-folder" text="Common.Name" required />
                    <div className="name-field">
                        <ValidatedInputWithStatus
                            innerRef={inputRef}
                            autoFocus={true}
                            autoComplete="off"
                            name="rename-file-folder"
                            type="text"
                            {...setSelector("nameTextbox")}
                            onChange={(e) => handleNameChange(e.target.value)}
                            error={errors.name}
                            isBusy={validationStatuses.name === "Validating"}
                            value={nameValue}
                        />
                        <span className="extension">{extension}</span>
                    </div>
                </FormGroup>
            </Row>
        </EntityForm>
    );
};
