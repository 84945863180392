import { useCallback, useRef } from "react";
import { usePermission, PermissionState } from "./PermissionHook";

const parsePermissionKey = (permissionKey: string) => {
    const [permission, objectId, projectId] = permissionKey.split(":");
    return {
        permission,
        objectId,
        projectId,
    };
};

type GetPermissionRequest = ReturnType<typeof usePermission>["getPermissions"];
type GetPermissionResult = ReturnType<GetPermissionRequest>;

class PermissionManager {
    private permissions: Record<string, PermissionState> = {};
    private permissionCallbacks: Record<string, [(state: PermissionState) => void]> = {};

    private permissionsPromises: Record<string, GetPermissionResult | undefined> = {};

    constructor(public getPermissionsRequest: GetPermissionRequest) {}

    public get(permission: string): PermissionState {
        // queue fetch or refresh for 'permission'

        if (!this.permissionsPromises[permission]) {
            const { permission: permissionKey, objectId, projectId } = parsePermissionKey(permission);
            this.permissionsPromises[permission] = this.getPermissionsRequest([
                { permission: permissionKey, objectId, projectId },
            ]);
        }

        this.permissionsPromises[permission]?.then((res) => {
            for (const { permission: p, state } of res) {
                this.set(p, state);
            }
        });

        this.permissionsPromises[permission]?.then(() => {
            this.permissionsPromises[permission] = undefined;
        });

        return this.permissions[permission] || "unknown";
    }

    public on(permission: string, callback: (state: PermissionState) => void): () => void {
        const unsubscribe = this.subscribe(permission, callback);

        callback(this.get(permission));

        return unsubscribe;
    }

    public set(permission: string, state: PermissionState): void {
        this.permissions[permission] = state;
        const callbacks = this.permissionCallbacks[permission];
        if (!callbacks) {
            return;
        }

        for (const cb of callbacks) {
            cb(state);
        }
    }

    private subscribe(permission: string, callback: (state: PermissionState) => void): () => void {
        const callbacks = this.permissionCallbacks[permission] || [];
        callbacks.push(callback);
        this.permissionCallbacks[permission] = callbacks;

        return () => {
            callbacks.splice(callbacks.indexOf(callback), 1);
        };
    }
}

export const usePermissionManager = () => {
    const { getPermissions } = usePermission();

    const manager = useRef(new PermissionManager(getPermissions));
    const getHandler = useCallback((permission: string) => {
        return manager.current.get(permission);
    }, []);
    const onChangeHandler = useCallback((permission: string, callback: (state: PermissionState) => void) => {
        return manager.current.on(permission, callback);
    }, []);

    return {
        get: getHandler,
        onChange: onChangeHandler,
    };
};
