import { useMemo } from "react";
import { useMatches } from "react-router";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import trimEnd from "lodash/trimEnd";
import { isContentManagerEntityCrumb, isDynamicCrumb, isEntityCrumb } from "../../models/breadcrumbs/CrumbType";
import { ICrumb } from "../../models/breadcrumbs/ICrumb";
import { IRouteHandle } from "../../models/router/IRouteHandle";
import { contentManagerSearchParamPath } from "../contentManager/ContentManagerTreeHook";
import { HashTranslationMap, isTranslatableHash } from "../../models/breadcrumbs/HashType";
import { useCurrentEntitiesNameContext } from "../../contexts/CurrentEntitiesNameContext";
import { SearchQueryParamsName } from "../../atoms/ContentManager";
import { contentManagerPaths } from "../../PathConstants";
import { EntityType } from "../../models/EntityType";
import { EditContentManagerItemSearchParam } from "../contentManager/DetailsView/EditContentItemHook";

type Unpacked<T> = T extends (infer U)[] ? U : T;
type UseMatchesReturnType = ReturnType<typeof useMatches>;
type TMatchNoHandle = Omit<Unpacked<UseMatchesReturnType>, "handle">;
export type OverridenUseMatches = (TMatchNoHandle & { handle?: IRouteHandle })[];

interface CrumbsHookResult {
    crumbs: ICrumb[];
    searchParamCrumbs: ICrumb[];
}

/**
 * The use crumbs hook.
 */
export const useCrumbs = (): CrumbsHookResult => {
    const [searchParams] = useSearchParams();
    const { hash, pathname: currentPath } = useLocation();
    const { getEntityName } = useCurrentEntitiesNameContext();
    const matches = useMatches();
    const urlparams = useParams();

    const isItemEditView = useMemo(() => "type" in urlparams && "itemId" in urlparams, [urlparams]);

    const regularCrumb: ICrumb[] = useMemo(() => {
        return (matches as OverridenUseMatches)
            .filter((match) => match.handle?.crumb)
            .map(({ handle, pathname, params }) => {
                const crumb = handle!.crumb!;
                let crumbName = "";
                let escapeTranslation = true;
                if (isDynamicCrumb(crumb)) {
                    crumbName = params[crumb.parameterName]!;
                } else if (isEntityCrumb(crumb)) {
                    crumbName = getEntityName(crumb.entityType);
                } else if (isContentManagerEntityCrumb(crumb)) {
                    crumbName = getEntityName(params["type"] as EntityType);
                } else {
                    crumbName = crumb.name;
                    escapeTranslation = false;
                }

                if (pathname !== "/") {
                    pathname = trimEnd(pathname, "/");
                }

                const newCrumb: ICrumb = {
                    name: crumbName,
                    path: `${pathname}${crumb.hash || ""}`,
                    escapeTranslation,
                };
                return newCrumb;
            });
    }, [getEntityName, matches]);

    const hashCrumbs: ICrumb[] = useMemo(() => {
        const innerCrumbs = [];
        if (hash.length > 0) {
            const isTranslatable = isTranslatableHash(hash);

            if (!isTranslatable) {
                // eslint-disable-next-line no-console
                console.warn(
                    `Missing translatable hash value '${hash}' in translation map. this hash wasn't translated`,
                );
            }

            innerCrumbs.push({
                name: isTranslatable ? HashTranslationMap[hash] : hash,
                path: currentPath + hash,
                escapeTranslation: !isTranslatable,
            });
        }

        return innerCrumbs;
    }, [currentPath, hash]);

    const contentPathParamCrumbs: ICrumb[] = useMemo(() => {
        const paramCrumbs = [];
        if (searchParams.has(contentManagerSearchParamPath)) {
            const path = searchParams.get(contentManagerSearchParamPath)!;
            const pathCrumbs = path.split("/").filter((crumb) => crumb !== "");
            let searchParamPath = `?${contentManagerSearchParamPath}=`;

            for (const crumb of pathCrumbs) {
                searchParamPath += `/${crumb}`;
                paramCrumbs.push({
                    name: crumb,
                    path: searchParamPath,
                    escapeTranslation: true,
                });
            }
        }

        return paramCrumbs;
    }, [searchParams]);

    const contentEditPathParamCrumbs: ICrumb[] = useMemo(() => {
        const paramCrumbs = [];
        if (searchParams.has(EditContentManagerItemSearchParam)) {
            const path = searchParams.get(EditContentManagerItemSearchParam)!;
            const pathCrumbs = path.split("/").filter((crumb) => crumb !== "");
            let searchParamPath = `?${contentManagerSearchParamPath}=`;
            const basePath = contentManagerPaths.link.base(urlparams["organizationName"]!, urlparams["projectName"]!);

            for (const crumb of pathCrumbs) {
                searchParamPath += encodeURIComponent(`/${crumb}`);
                const fullPath = `${basePath}/${searchParamPath}`;
                paramCrumbs.push({
                    name: crumb,
                    path: fullPath,
                    escapeTranslation: true,
                });
            }
        }

        return paramCrumbs;
    }, [searchParams, urlparams]);

    const permissionGroupCrumbs: ICrumb[] = useMemo(() => {
        const permGroupCrumbs = [];
        if (searchParams.has("permissionGroup")) {
            const name = searchParams.get("permissionGroup");
            if (name) {
                permGroupCrumbs.push({
                    name,
                    path: `?permissionGroup=${name}`,
                    escapeTranslation: true,
                });
            }
        }
        return permGroupCrumbs;
    }, [searchParams]);

    const isSearchResult = useMemo(() => searchParams.has(SearchQueryParamsName), [searchParams]);

    const crumbs: ICrumb[] = useMemo(() => {
        if (isSearchResult) {
            return [
                ...regularCrumb,
                ...hashCrumbs,
                ...contentPathParamCrumbs,
                ...permissionGroupCrumbs,
                {
                    name: "Search.Results",
                },
            ];
        }

        if (isItemEditView) {
            const begginingCrumbs = regularCrumb.slice();
            const last = begginingCrumbs.pop()!;

            return [...begginingCrumbs, ...contentEditPathParamCrumbs, last, ...hashCrumbs, ...permissionGroupCrumbs];
        }

        if (regularCrumb.length && contentPathParamCrumbs.length) {
            return [...regularCrumb, ...contentPathParamCrumbs, ...hashCrumbs, ...permissionGroupCrumbs];
        }

        return [...regularCrumb, ...hashCrumbs, ...permissionGroupCrumbs];
    }, [
        isSearchResult,
        isItemEditView,
        regularCrumb,
        contentPathParamCrumbs,
        hashCrumbs,
        permissionGroupCrumbs,
        contentEditPathParamCrumbs,
    ]);

    return { crumbs, searchParamCrumbs: contentPathParamCrumbs };
};
