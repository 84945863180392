import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "./ConfirmationDialog";

import "./DeleteConfirmationDialog.scss";

export type DeleteConfirmationIcon = "none" | "trash";

/**
 * The delete confirmation dialog props interface.
 */
interface IDeleteConfirmationDialogProps {
    className?: string;
    cancelLabel?: string;
    deleteLabel?: string;
    title: string;
    show: boolean;
    onCancel: () => void;
    onDelete: () => Promise<void>;
    children?: React.ReactNode;
    dialogIcon?: DeleteConfirmationIcon;
    confirmed?: boolean;
}

/**
 * The delete confirmation dialog component.
 */
export const DeleteConfirmationDialog: React.FunctionComponent<IDeleteConfirmationDialogProps> = (
    props: IDeleteConfirmationDialogProps,
) => {
    const {
        className,
        cancelLabel,
        deleteLabel,
        title,
        children,
        show,
        onCancel,
        onDelete,
        dialogIcon,
        confirmed = true,
    } = props;
    const { t } = useTranslation();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const onDeleteCallback = useCallback(async () => {
        setIsDeleting(true);

        await onDelete();

        setIsDeleting(false);
    }, [onDelete]);

    return (
        <>
            <ConfirmationDialog
                show={show}
                className={classNames("delete-confirmation-dialog", className)}
                icon={dialogIcon === "trash" ? "fas fa-trash-alt" : ""}
                title={title}
                cancelButton={{
                    color: "secondary",
                    outline: true,
                    label: cancelLabel || t("Common.Cancel"),
                    onAction: onCancel,
                    testSelectorValue: "cancelButton",
                }}
                confirmationButton={{
                    color: "danger",
                    disabled: isDeleting || !confirmed,
                    loading: isDeleting,
                    label: deleteLabel || t("Common.Delete"),
                    onAction: () => void onDeleteCallback(),
                    testSelectorValue: "confirmButton",
                }}
                testSelectorValue="confirmationDialog"
            >
                {children}
            </ConfirmationDialog>
        </>
    );
};
