import React, { useCallback } from "react";
import { FormGroup } from "reactstrap";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { FormValidator } from "../../formValidators/FormValidator";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { IDefaultEntityProps } from "../../models/IDefaultEntityProps";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * FormValidator class that contains at least a validateDescription method
 * This is used to specify that the formValidator from the useEditEntityContext contains a validateDescription method
 */
abstract class FormValidatorWithDescription<T> extends FormValidator<T> {
    public abstract validateDescription(description: string): Promise<string>;
}

/**
 * Generic component that render a textbox used to edit an entity's description.
 * This component required the use of the useEditEntityContext
 */
export const EditDescription = <TValidator extends FormValidatorWithDescription<unknown>>(): JSX.Element => {
    const {
        entityProps: { entity, setEntityProperties, formValidator },
        errorProps: { errors, setErrors },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IDefaultEntityProps, TValidator>();

    const onDescriptionChange = useCallback(
        async ({ target: { value: description } }: React.ChangeEvent<HTMLInputElement>) => {
            let errorMessage: string;

            try {
                setEntityProperties({ description });
                await formValidator.validateDescription(description);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            setErrors({ description: errorMessage });
        },
        [formValidator, setEntityProperties, setErrors],
    );
    const { setSelector } = useTestSelector();

    return (
        <FormGroup className="entity-description">
            <LocalizedLabel for="edit-description" text="Common.Description" />
            <ValidatedInput
                id="edit-description"
                name="description"
                type="textarea"
                onChange={onDescriptionChange}
                error={errors.description}
                value={entity.description}
                {...setSelector("descriptionTextbox")}
                disabled={!canEdit}
            />
        </FormGroup>
    );
};
