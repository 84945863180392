import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtomValue } from "jotai";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useContentManagerTableExtraActions } from "../../hooks/contentManager/ContentManagerTableExtraActionsHook";
import { Table } from "../table/Table";
import { ISortField } from "../../models/ISortField";
import { useContentEntityApi } from "../../hooks/contentManager/ContentEntityApiHook";
import { useContentManagerTableHelper } from "../../hooks/contentManager/ContentManagerTableHelperHook";
import { useContentManagerTableActionProvider } from "../../hooks/contentManager/ContentManagerTableActionProviderHook";
import { searchQueryAtom, selectedFolderIdAtom } from "../../atoms/ContentManager";
import { CrumbHierarchyShortener } from "./CrumbHierarchyShortener";
import { useCrumbs } from "../../hooks/crumbs/Crumbs";
import { ContentManagerSeachTableId } from "../../models/contentManager/constant";
import { useContentManagerTableColumnDefs } from "../../hooks/contentManager/ContentManagerTableColumnDefsHook";
import { useContentManagerTableHandleRefresh } from "./ContentManagerTableHandleRefreshHook";
import { ITreeItem } from "../../models/contentManager/FolderTree";
import { useOpenItem } from "../../hooks/contentManager/OpenItemHook";
import { ContentItemIcon } from "./ContentItemIcon";

const keyExtractor = (item: IContentEntityModel) => item.id;
const nameExtractor = (item: IContentEntityModel) => item.name;

const modifyPath = (item: IContentEntityModel): IContentEntityModel => {
    const itemCopy = { ...item };
    itemCopy.path = itemCopy.path.length > 1 ? `${itemCopy.path}/${itemCopy.name}` : `/${itemCopy.name}`;
    return itemCopy;
};

/**
 * The content manager content view component.
 */
export const ContentManagerSearchResultView = () => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const { onLoadError, handleSelectedPath } = useContentManagerContext();
    const { projectId } = useProjectContext();
    const [selectedItems, setSelectedItems] = useState<IContentEntityModel[]>([]);
    const contentReadKey = usePermissionKey({ permission: PermissionKeys.contentManager.read, projectId });
    const contentWriteKey = usePermissionKey({ permission: PermissionKeys.contentManager.write, projectId });
    const contentDeleteKey = usePermissionKey({ permission: PermissionKeys.contentManager.delete, projectId });
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const { searchParamCrumbs } = useCrumbs();
    const permissionsObject = useMemo(
        () => ({ permissionKeys: [contentWriteKey, contentDeleteKey, contentReadKey] }),
        [contentDeleteKey, contentWriteKey, contentReadKey],
    );
    const { bulkActionDownload } = useContentManagerTableExtraActions({
        projectId,
        selectedItems,
        tableId: ContentManagerSeachTableId,
    });
    const { deleteHelper } = useContentManagerTableHelper({
        projectId,
        tableId: ContentManagerSeachTableId,
    });

    const { openFile, getWopiFileInfo } = useOpenItem();

    const { actionProvider } = useContentManagerTableActionProvider({
        projectId,
        selectedItems,
        tableId: ContentManagerSeachTableId,
        modifyEntity: modifyPath,
    });
    const { isAllowed } = usePermissionCheck(permissionsObject);
    const searchValue = useAtomValue(searchQueryAtom);
    const { getSearchContentRecords } = useContentEntityApi({
        projectId,
        checkParentExistsOnError: true,
    });

    const getRecords = useCallback(
        (_: undefined, sortFields?: ISortField[], offset?: number) => {
            return getSearchContentRecords(selectedFolderId, searchValue, sortFields, offset);
        },
        [getSearchContentRecords, searchValue, selectedFolderId],
    );

    const [crumbContainerWidth, setCrumbContainerWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            setCrumbContainerWidth(event[0].contentBoxSize[0].inlineSize);
        });

        resizeObserver.observe(document.getElementById("container-manager-width")!);
    }, []);

    const columnNameContent = useCallback(
        (item: IContentEntityModel) => (
            <button
                className="content-name-container"
                aria-label={item.name}
                onClick={(e) => {
                    e.stopPropagation();
                    if (item.type === "Folder") {
                        item.path = modifyPath(item).path;
                        handleSelectedPath({ path: item.path } as ITreeItem);
                        return;
                    }

                    const wopiFileInfo = getWopiFileInfo(item, isAllowed(contentWriteKey), isAllowed(contentReadKey));
                    openFile(item.id, item.name, undefined, wopiFileInfo?.action).catch(onLoadError);
                }}
            >
                <ContentItemIcon name={item.name} type={item.type} />
                <span className="folder-name text-truncate" { ...setSelector("cell-link") }>{item.name}</span>
            </button>
        ),
        [contentReadKey, contentWriteKey, getWopiFileInfo, handleSelectedPath, isAllowed, onLoadError, openFile, setSelector],
    );

    const columnDefs = useContentManagerTableColumnDefs({
        columnNameContent,
        withLocationColumn: true,
    });

    useContentManagerTableHandleRefresh(ContentManagerSeachTableId, selectedFolderId);

    return (
        <div className="result-view-container">
            <div id="container-manager-width" className="content-manager-search-table-header">
                <span className="search-result-location">{t("ContentManager.Search.Result.Title")}</span>
                {searchParamCrumbs.length > 0 && (
                    <>
                        <span className="crumb-files">{t("ContentManager.Files")}</span>
                        <CrumbHierarchyShortener containerWidth={crumbContainerWidth} crumbs={searchParamCrumbs} />
                    </>
                )}
                <span className="default-location">
                    {searchParamCrumbs.length === 0 && t("ContentManager.AllFiles")}
                </span>
            </div>
            <Table
                className="content-manager-table"
                columnDefs={columnDefs}
                actionProvider={actionProvider}
                getRecords={getRecords}
                keyExtractor={keyExtractor}
                nameExtractor={nameExtractor}
                loadingMessageKey="ContentManager.Loading"
                tableId={ContentManagerSeachTableId}
                selectable={isAllowed(contentDeleteKey)}
                deleteHelper={deleteHelper}
                onSelectionChange={setSelectedItems}
                extraActions={[{ showWithSelectedItem: true, button: bulkActionDownload, id: "bulkActionDownload" }]}
                onLoadError={onLoadError}
                noResultMessageKey={searchValue}
                counterPosition={selectedItems.length > 0 ? "End" : "Start"}
            />
        </div>
    );
};
