import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useCurrentFolderName = (paramName: string): string | null => {
    const [params] = useSearchParams();

    const folderInfo = useMemo(() => {
        if (params.has(paramName)) {
            const path = params.get(paramName)!;
            const decodedPath = decodeURIComponent(path);
            const lastFolder = decodedPath.split("/").pop();
            return lastFolder || null;
        }

        return null;
    }, [paramName, params]);

    return folderInfo;
};
