import React, { useCallback, useMemo, useState } from "react";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useTemplateApi } from "./TemplateApiHook";
import { useModalContext } from "../../contexts/ModalContext";
import { TemplateLanguageSelectForm } from "../../components/templates/TemplateLanguageSelectForm";
import { ITemplateLanguageModel } from "../../models/Languages/ITemplateLanguageModel";
import { LoadingModalContent } from "../../components/modals/LoadingModalContent";
import { useDialogContext } from "../../contexts/DialogContext";
import { NotificationService } from "../../services/NotificationService";
import { extractErrorMessage } from "../../helpers/ErrorHelper";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";

interface TemplateDesignDownloadReturn {
    handleDesign: (templateId: string, name: string, languageCode?: string) => void;
    fetchingLanguages: boolean;
}

export const useTemplateDesignDownload = (): TemplateDesignDownloadReturn => {
    const { projectId } = useProjectContext();
    const { getAvailableLanguages, downloadDesignDocument } = useTemplateApi(projectId);
    const { showModal, closeModal } = useModalContext();
    const { show: showDialog, close: closeDialog } = useDialogContext();
    const { refreshTable } = useTableRefreshContext();
    const [fetchingLanguages, setFetchingLanguages] = useState<boolean>(false);
    const showDesignTemplateModal = useCallback(
        (languages: ITemplateLanguageModel[], templateId: string, name: string) => {
            closeDialog();
            showModal({
                contentToDisplay: TemplateLanguageSelectForm,
                allowCreateWhenNotDirty: true,
                formPropsToFlow: {
                    languageList: languages,
                },
                modalProps: {
                    createLabel: "Common.Download",
                    titleKey: "Template.Design",
                    size: "md",
                    expandable: false,
                    onClose: closeModal,
                },
                onCreate: ({ languageCode }: { languageCode: string }) => {
                    return downloadDesignDocument(templateId, languageCode, `${name}-${languageCode}.docx`).then(
                        () => languageCode,
                    );
                },
                onCreated: () => {
                    closeModal();
                },
                entity: { languageCode: languages.find((l) => l.isDefault)!.code },
            });
        },
        [closeDialog, closeModal, downloadDesignDocument, showModal],
    );

    const dialogContent = useMemo(() => <LoadingModalContent labelKey={"Template.PreparingDownloadTemplate"} />, []);

    const showLoadingDialog = useCallback(() => {
        showDialog({
            content: dialogContent,
            contentOverwriteModal: true,
            type: "Info",
            size: "sm",
            modalClassName: "loading-modal",
        });
    }, [dialogContent, showDialog]);

    const directDownloadDocument = useCallback(
        async (templateId: string, name: string, languageCode: string) => {
            try {
                await downloadDesignDocument(templateId, languageCode, `${name}-${languageCode}.docx`);
            } catch (error) {
                refreshTable("templateLanguages");
                NotificationService.addErrorNotification({ message: extractErrorMessage(error) });
            }

            closeDialog();
        },
        [closeDialog, downloadDesignDocument, refreshTable],
    );

    const handleDesign = useCallback(
        (templateId: string, name: string, languageCode?: string) => {
            if (!languageCode) {
                setFetchingLanguages(true);
                getAvailableLanguages(templateId).then((languages) => {
                    if (languages.length > 1) {
                        showDesignTemplateModal(languages, templateId, name);
                    } else {
                        directDownloadDocument(templateId, name, languages[0].code);
                    }
                    setFetchingLanguages(false);
                });
            } else {
                showLoadingDialog();
                directDownloadDocument(templateId, name, languageCode);
            }
        },
        [directDownloadDocument, getAvailableLanguages, showDesignTemplateModal, showLoadingDialog],
    );

    return {
        handleDesign,
        fetchingLanguages,
    };
};
