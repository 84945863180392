import React from "react";
import { SearchNavBarResultTitle } from "./SearchNavBarResultTitle";

import "./SearchNavbarItem.scss";

/**
 * The search navbar results props interface.
 */
interface ISearchNavbarResultsProps {
    loading: boolean;
    searchValue: string;
    searchResults: JSX.Element[];
}

/**
 * The search navbar results functional component.
 */
export const SearchNavbarResults: React.FC<ISearchNavbarResultsProps> = ({
    loading,
    searchValue,
    searchResults,
}: ISearchNavbarResultsProps) => {
    return (
        <>
            <div className="searchresult-title text-truncate">
                <SearchNavBarResultTitle loading={loading} value={searchValue} resultCount={searchResults.length} />
            </div>
            {searchResults}
        </>
    );
};
