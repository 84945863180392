import { createContext, useContext } from "react";
import { EntityType } from "../models/EntityType";

interface ICurrentEntitiesNameContextProps {
    setEntityName: (entityType: EntityType, entityName: string) => void;
    getEntityName: (entityType: EntityType) => string;
}

export const CurrentEntitiesNameContext = createContext<ICurrentEntitiesNameContextProps>(null!);

export const useCurrentEntitiesNameContext = (): ICurrentEntitiesNameContextProps =>
    useContext(CurrentEntitiesNameContext);
