import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The save button props interface.
 */
interface ISaveButtonProps {
    disabled?: boolean;
    icon?: string;
    isSaving: boolean;
    size?: string;
    onClick: () => void;
}

/**
 * The save button component.
 */
export const SaveButton: React.FC<ISaveButtonProps> = ({
    disabled,
    icon,
    isSaving,
    size,
    onClick,
}: ISaveButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <Button
            ariaLabel={t("Common.Save")}
            className="save"
            loading={isSaving}
            icon={icon ?? "fas fa-save"}
            color="primary"
            onClick={onClick}
            disabled={disabled}
            size={size}
            {...setSelector("saveButton")}
        >
            {t("Common.Save")}
        </Button>
    );
};
