import React from "react";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { IPasswordModel, IUserPasswordSettingsModel } from "../models/account/IPasswordModel";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

YupPassword(Yup);

const defaultPasswordSettings: IUserPasswordSettingsModel = {
    userName: "",
    passwordOptions: {
        requireDigit: true,
        requiredLength: 6,
        requiredUniqueChars: 1,
        requireLowercase: true,
        requireNonAlphanumeric: true,
        requireUppercase: true,
    },
    userHasPassword: true,
    passwordMaxLengthOptions: {
        requireMaxLength: 100,
    },
};

/**
 * The password form validator class.
 */
export class PasswordFormValidator extends FormValidator<IPasswordModel> {
    protected schema = Yup.object().shape({
        newPassword: Yup.string()
            .password()
            .required("Password.NewPasswordIsRequired")
            .min(this.settings.passwordOptions.requiredLength, () => (
                <ValidatorError
                    messageKey="Password.RequireMinLength"
                    options={{ min: this.settings.passwordOptions.requiredLength }}
                />
            ))
            .max(this.settings.passwordMaxLengthOptions.requireMaxLength, () => (
                <ValidatorError
                    messageKey="Password.RequireMaxLength"
                    options={{ max: this.settings.passwordMaxLengthOptions.requireMaxLength }}
                />
            ))
            .minNumbers(this.settings.passwordOptions.requireDigit ? 1 : 0, "Password.RequiresDigit")
            .minUppercase(this.settings.passwordOptions.requireUppercase ? 1 : 0, "Password.RequireUppercase")
            .minLowercase(this.settings.passwordOptions.requireLowercase ? 1 : 0, "Password.RequiresLowercase")
            .minSymbols(
                this.settings.passwordOptions.requireNonAlphanumeric ? 1 : 0,
                "Password.RequiresNonAlphanumeric",
            ),
        confirmedPassword: Yup.string()
            .oneOf([Yup.ref("newPassword"), Yup.ref("$newPassword"), null], "Password.NotMatching")
            .required(() => <ValidatorError messageKey="Password.ConfirmedPasswordIsRequired" />),
        currentPassword: Yup.string().when([], {
            is: () => this.settings.userHasPassword,
            then: Yup.string().required("Password.OldPasswordIsRequired"),
        }),
    });

    constructor(private settings: IUserPasswordSettingsModel = defaultPasswordSettings) {
        super();
    }

    public validateNewPassword(newPassword: string): Promise<string | undefined> {
        return this.schema.fields.newPassword.validate(newPassword);
    }

    public validateOldPassword(oldPassword: string): Promise<string | undefined> {
        return this.schema.fields.currentPassword.validate(oldPassword);
    }

    public validateConfirmedPassword(confirmedPassword: string, newPassword: string): Promise<string | undefined> {
        return this.schema.fields.confirmedPassword.validate(confirmedPassword, {
            context: {
                newPassword,
            },
        });
    }
}
