import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EditView } from "../views/EditView";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { TabContent } from "../tabs/TabContent";
import { PermissionsTabs } from "../permissions/PermissionsTabs";
import { UsersView } from "../users/UsersView";
import { useTabs } from "../../hooks/TabsHook";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { LoadingScreen } from "../loading/LoadingScreen";
import { useNavigation } from "../../hooks/NavigationHook";
import { PermissionGroupPaths, paths } from "../../PathConstants";
import { useDialogContext } from "../../contexts/DialogContext";
import { useFullscreenContext } from "../../contexts/FullscreenContext";
import { TableViewHeader } from "../views/TableViewHeader";
import { buildPermissions, buildUsers } from "../../helpers/EntityHelper";

import "./ApplicationSettingsView.scss";

/**
 * the list of tabs in the application settings view
 */
const applicationSettingsViewTabs = ["users", "permissions"] as const;

/**
 * the list of tabs in the application settings view as Type
 */
type ApplicationSettingsViewTabType = (typeof applicationSettingsViewTabs)[number];

const permissionKeys = [PermissionKeys.app.manage];
const permissionKeysObject: Parameters<typeof usePermissionCheck>[0] = { permissionKeys };

/**
 * The application settings view component.
 */
export const ApplicationSettingsView: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { fullscreen } = useFullscreenContext();

    const [activeTab, setActiveTab] = useTabs<ApplicationSettingsViewTabType>(applicationSettingsViewTabs, "users");

    const { isAllowed, isDenied } = usePermissionCheck(permissionKeysObject);

    const hasAdminManagePermission = isAllowed(PermissionKeys.app.manage);
    const doesNotHaveAdminManagePermission = isDenied(PermissionKeys.app.manage);

    const tabs: INavMenuItemProps[] = [
        buildUsers(false, false, setActiveTab),
        buildPermissions(false, false, setActiveTab),
    ];

    const { replace } = useNavigation();
    const { show: showDialog } = useDialogContext();

    useEffect(() => {
        const navigateToHome = () => {
            replace(paths.home);
        };
        if (doesNotHaveAdminManagePermission) {
            showDialog({
                onProceed: () => Promise.resolve(navigateToHome()),
                onClose: navigateToHome,
                proceedLabelKey: "Common.OK",
                hideCancel: true,
                type: "Warning",
                titleKey: "Permissions.Insufficient",
                messageKey: "Permissions.ContactCTA",
            });
        }
    }, [doesNotHaveAdminManagePermission, showDialog, replace]);

    if (!hasAdminManagePermission) {
        return <LoadingScreen />;
    }

    return (
        <EditView fullscreen={fullscreen}>
            <TableViewHeader titleKey="Route.ApplicationSettings" />
            <div className="application-settings-content">
                <NavMenu activeTab={activeTab} navTabs={tabs}>
                    <TabContent tabId="users" className="users" title={t("Common.Users")}>
                        <UsersView />
                    </TabContent>
                    <TabContent tabId="permissions" className="permissions" title={t("Common.Permissions")} hasNavTabs>
                        <PermissionsTabs groupLink={(groupId) => PermissionGroupPaths.link.edit.appSettings(groupId)} />
                    </TabContent>
                </NavMenu>
            </div>
        </EditView>
    );
};
