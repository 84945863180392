import { useState, useEffect, useCallback, useMemo } from "react";
import { FormValidator } from "../formValidators/FormValidator";
import { useCurrentEntitiesNameContext } from "../contexts/CurrentEntitiesNameContext";
import { EntityType } from "../models/EntityType";
import { EntityWithName } from "../contexts/EditEntityContext";
import { VersionComment } from "../models/versioning/VersioningModels";
import { defaultRequestErrorHandler } from "../helpers/ErrorHelper";

/**
 * The entity hook result.
 */
interface IEntityHookResult<TEntity, TValidator> {
    setInitialEntityProperties: (initialEntity: Partial<TEntity>) => void;
    entity: TEntity | undefined;
    initialEntity: TEntity | undefined;
    entityValid: boolean;
    errors: { [alias in keyof TEntity]: string };
    isDirty: (properties?: (keyof TEntity)[]) => boolean;
    setEntityProperties: (entity: Partial<TEntity>) => void;
    setErrors: (errors: Partial<{ [alias in keyof TEntity]: string }>) => void;
    onSave: () => Promise<void>;
    isSaving: boolean;
    formValidator: TValidator;
    resetErrors: () => void;
    blockSave: boolean;
    toggleBlockSave: () => void;
}

/**
 * The entity hook Props.
 */
export interface IEntityHookProps<TEntity, TValidator> {
    loadedEntity?: TEntity;
    fetchEntity?: () => Promise<TEntity>;
    updateEntity: (entity: TEntity, versionComment: VersionComment) => Promise<TEntity>;
    formValidatorProvider: () => TValidator;
    propertiesComparator?: {
        [alias in keyof TEntity]?: (a: any, b: any) => boolean;
    };
    onSaveCallback?: (entity: TEntity) => void;
    entityType?: EntityType;
    disableNameContext?: boolean;
}

const entityHasName = (entity: any): entity is EntityWithName => Object.hasOwn(entity as EntityWithName, "name");

/**
 * The use edit entity hook.
 */
export const useEditEntity = <TEntity, TValidator extends FormValidator<unknown>>({
    updateEntity,
    formValidatorProvider,
    propertiesComparator,
    onSaveCallback,
    loadedEntity,
    fetchEntity,
    entityType,
    disableNameContext,
}: IEntityHookProps<TEntity, TValidator>): IEntityHookResult<TEntity, TValidator> => {
    type ErrorType = {
        [alias in keyof TEntity]: string;
    };

    const [initialEntity, setInitialEntity] = useState<TEntity | undefined>(loadedEntity);
    const [entity, setEntity] = useState<TEntity | undefined>(loadedEntity);
    const [errors, setErrorsState] = useState<ErrorType>({} as ErrorType);
    const [isSaving, setIsSaving] = useState(false);
    const [entityValid, setEntityValid] = useState(false);
    const [checkSavingCallback, setCheckSavingCallback] = useState(false);
    const [blockSave, setBlockSave] = useState(false);

    const formValidator = useMemo(() => formValidatorProvider(), [formValidatorProvider]);

    useEffect(() => {
        setEntityValid(false);
        void formValidator.isValid(entity, { context: { ...entity } }).then((validationResult) => {
            setEntityValid(validationResult);
        });
    }, [entity, formValidator]);

    const setEntityProperties = useCallback((e: Partial<TEntity>) => {
        setEntity((currentEntity) => {
            if (!currentEntity) {
                return currentEntity;
            }

            return {
                ...currentEntity,
                ...e,
            };
        });
    }, []);

    const setErrors = useCallback((e: Partial<ErrorType>) => {
        setErrorsState((oldErrors) => ({
            ...oldErrors,
            ...e,
        }));
    }, []);

    const resetErrors = () => setErrorsState({} as ErrorType);

    const setInitialEntityProperties = (e: Partial<TEntity>) => {
        setInitialEntity((currentInitialEntity) => {
            if (!currentInitialEntity) {
                return currentInitialEntity;
            }

            return {
                ...currentInitialEntity,
                ...e,
            };
        });
    };

    const isDirty = useCallback(
        (properties?: (keyof TEntity)[]): boolean => {
            if (!entity || !initialEntity) {
                return false;
            }

            if (!properties) {
                properties = Object.keys(entity) as (keyof TEntity)[];
            }

            for (const p of properties) {
                const comparator = propertiesComparator ? propertiesComparator[p] : undefined;
                if (comparator) {
                    if (!comparator(entity[p], initialEntity[p])) {
                        return true;
                    }
                } else if (entity[p] !== initialEntity[p]) {
                    return true;
                }
            }

            return false;
        },
        [entity, initialEntity, propertiesComparator],
    );

    const { setEntityName } = useCurrentEntitiesNameContext();

    useEffect(() => {
        if (!disableNameContext && entityType && initialEntity && entityHasName(initialEntity)) {
            setEntityName(entityType, initialEntity.name);
        }
        return () => {
            !disableNameContext && entityType && setEntityName(entityType, "");
        };
    }, [disableNameContext, entityType, initialEntity, setEntityName]);

    const onSave = useCallback(async () => {
        setIsSaving(true);

        try {
            const newEntity = await updateEntity(entity!, null);
            setEntity(newEntity);
            setInitialEntity(newEntity);
            setCheckSavingCallback(true);
        } catch (error) {
            defaultRequestErrorHandler(error);
        } finally {
            setIsSaving(false);
        }
    }, [entity, updateEntity]);

    const loadEntity = useCallback(async (): Promise<void> => {
        try {
            const fetchedEntity = fetchEntity && (await fetchEntity());
            if (fetchedEntity) {
                setEntity(fetchedEntity);
                setInitialEntity(fetchedEntity);
            }
        } catch (e) {
            setEntity(undefined);
            setInitialEntity(undefined);
        }
    }, [fetchEntity]);

    // We set the entity only when the hook is created.
    useEffect(() => {
        if (!loadedEntity) {
            void loadEntity();
        }
    }, [loadEntity, loadedEntity]);

    // Once the entity is saved, the useEffect will verify if a onSaveCallback exists and execute it.
    // We cannot call the onSaveCallback directly in the onSave method since the entity is still considered dirty,
    // so we created this useEffect to wait for the state to be updated before executing the onSaveCallback.
    useEffect(() => {
        if (entity && checkSavingCallback && onSaveCallback) {
            onSaveCallback(entity);
        }
        setCheckSavingCallback(false);
    }, [entity, onSaveCallback, checkSavingCallback]);

    const toggleBlockSave = useCallback(() => {
        setBlockSave((prev) => {
            return !prev;
        });
    }, []);

    return {
        setInitialEntityProperties,
        entity,
        initialEntity,
        entityValid,
        errors,
        isDirty,
        setEntityProperties,
        setErrors,
        onSave,
        isSaving,
        formValidator,
        resetErrors,
        blockSave,
        toggleBlockSave,
    };
};
