import { useCallback } from "react";
import { useAtomValue } from "jotai";
import { selectedFolderIdAtom } from "../atoms/ContentManager";
import { useCancellableRequest } from "./CancellableRequestHook";
import { useHttpRequest } from "./HttpRequestHook";
import { IContentEntityModel } from "../models/contentManager/ContentManagerApiModels";
import { useContentManagerContext } from "../contexts/ContentManagerContext";

interface ContentManagerCancellableRequest {
    projectId: string;
    checkParentExistsOnError: boolean;
}

export const useContentManagerCancellableRequest = ({
    projectId,
    checkParentExistsOnError,
}: ContentManagerCancellableRequest) => {
    const { cancellableRequest } = useCancellableRequest();
    const { httpGetJson } = useHttpRequest();
    const selectedFolderId = useAtomValue(selectedFolderIdAtom);
    const { onLoadError } = useContentManagerContext();

    const checkIfParentFolderExists = useCallback(
        async <TReturn>(originalRequestError: Error, reject: (reason?: any) => void): Promise<TReturn> =>
            new Promise<TReturn>(() => {
                cancellableRequest<IContentEntityModel>(
                    {
                        url: `/_api/project/${projectId}/folder/${selectedFolderId!}`,
                    },
                    httpGetJson,
                )
                    .then(() => reject(originalRequestError))
                    .catch((parentFolderMissingError) => {
                        onLoadError(parentFolderMissingError);
                    });
            }),
        [cancellableRequest, httpGetJson, onLoadError, projectId, selectedFolderId],
    );

    const contentManagerCancellableRequest = useCallback(
        async <TReturn>(request: () => Promise<TReturn>): Promise<TReturn> =>
            new Promise<TReturn>((resolve, reject) => {
                request()
                    .then(resolve)
                    .catch((originalRequestError: Error) => {
                        if (selectedFolderId && checkParentExistsOnError) {
                            checkIfParentFolderExists(originalRequestError, reject);
                            return;
                        }

                        reject(originalRequestError);
                    });
            }),
        [checkIfParentFolderExists, selectedFolderId, checkParentExistsOnError],
    );

    return {
        contentManagerCancellableRequest,
    };
};
