import { BulkActionSummaryCancelResult, BulkOperationSummary } from "../models/BulkOperationModel";
import { NotificationService } from "../services/NotificationService";
import { extractErrorMessageAsHtml } from "./ErrorHelper";

const getWarningMessageKey = (summary: BulkOperationSummary, notificationMessageKey: string) => {
    if (summary.successCount === 1) {
        return summary.errorCount === 1
            ? `${notificationMessageKey}.OneSuccessOneFail`
            : `${notificationMessageKey}.OneSuccessMultipleFail`;
    }

    return summary.errorCount === 1
        ? `${notificationMessageKey}.MultipleSuccessOneFail`
        : `${notificationMessageKey}.MultipleSuccessMultipleFail`;
};

export const handleBulkOperationNotification = (
    summary: BulkOperationSummary,
    notificationMessageKey: string,
    notificationMessageKeyParams?: Record<string, string>,
) => {
    if (summary.errorCount === 0) {
        NotificationService.addSuccessNotification(
            summary.successCount === 1
                ? {
                      messageKey: `${notificationMessageKey}.SuccessSingle`,
                      messageKeyParams: notificationMessageKeyParams,
                  }
                : {
                      messageKey: `${notificationMessageKey}.SuccessMultiple`,
                      messageKeyParams: notificationMessageKeyParams,
                  },
        );
    } else {
        if (summary.errorCount === 1 && summary.successCount === 0 && summary.errorMessages.length === 1) {
            NotificationService.addErrorNotification({
                message: extractErrorMessageAsHtml(summary.errorMessages[0]),
            });
        } else if (summary.successCount === 0) {
            NotificationService.addErrorNotification({
                messageKey: `${notificationMessageKey}.Failure`,
                messageKeyParams: {
                    ...notificationMessageKeyParams,
                    count: summary.errorCount,
                },
            });
        } else {
            const totalAmountOfItems = summary.errorCount + summary.successCount;
            NotificationService.addWarningNotification({
                messageKey: getWarningMessageKey(summary, notificationMessageKey),
                messageKeyParams: {
                    successCount: summary.successCount,
                    totalCount: totalAmountOfItems,
                    ...notificationMessageKeyParams,
                    count: totalAmountOfItems,
                },
            });
        }
    }
};

export const handleBulkOperationCancelNotification = <T>(
    summary: BulkActionSummaryCancelResult<T>,
    notificationMessageKey: string,
    notificationMessageKeyParams?: Record<string, string>,
) => {
    if (summary.cancelled) {
        NotificationService.addWarningNotification({
            messageKey: `${notificationMessageKey}.Cancelled`,
        });
        return;
    }

    handleBulkOperationNotification(summary, notificationMessageKey, notificationMessageKeyParams);
};

export const createCancelledActionsResult = <T>(payload: T): BulkActionSummaryCancelResult<T> => ({
    errorCount: 1,
    errorMessages: [],
    payload,
    successCount: 0,
    cancelled: true,
});
