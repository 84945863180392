import React from "react";
import { Route } from "react-router-dom";
import { publicPaths } from "../../PathConstants";
import { I18nextWithContext } from "../resources/I18nextWithContext";
import { LazyDataSetViewer } from "../dataSets/lazy/LazyDataSetViewer";

export const publicRoutes = (
    <Route>
        <Route
            path={publicPaths.dataset}
            element={
                <I18nextWithContext>
                    <LazyDataSetViewer isPublicDataSet />
                </I18nextWithContext>
            }
        />
    </Route>
);
