import React, { useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import classNames from "classnames";
import { Dialog } from "./Dialog";
import { IDialogActionProps } from "./DialogAction";

import "./ConfirmationDialog.scss";

/**
 * The generic confirmation dialog props interface.
 */
interface IConfirmationDialogProps extends React.PropsWithChildren<{}> {
    className: string;
    icon: string;
    title: string;
    show: boolean;
    cancelButton: IDialogActionProps;
    confirmationButton: IDialogActionProps;
    testSelectorValue?: string;
    onClosed?: () => void;
}

/**
 * The generic confirmation dialog component.
 */
export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
    title,
    children,
    className,
    show,
    icon,
    cancelButton,
    confirmationButton,
    testSelectorValue,
    onClosed,
}: IConfirmationDialogProps) => {
    const actions = useMemo(() => [cancelButton, confirmationButton], [cancelButton, confirmationButton]);

    useHotkeys(
        "escape",
        (e) => {
            e.preventDefault();
            cancelButton.onAction();
        },
        {
            enabled: show,
        },
        [cancelButton],
    );

    useHotkeys(
        "ctrl+s, command+s",
        (e) => {
            e.preventDefault();
            confirmationButton.onAction();
        },
        {
            enabled: show,
            filter: () => document.activeElement?.tagName !== "BUTTON",
            filterPreventDefault: false,
        },
        [confirmationButton, show],
    );

    return (
        <Dialog
            show={show}
            className={classNames("confirmation-dialog", className)}
            icon={icon}
            title={title}
            actions={actions}
            testSelectorValue={testSelectorValue}
            onClose={cancelButton.onAction}
            onClosed={onClosed}
        >
            {children}
        </Dialog>
    );
};
