import React from "react";
import { Trans } from "react-i18next";
import { LoadingWrapper } from "../loading/LoadingWrapper";

/**
 * The search navbar title props interface.
 */
interface ISearchNavBarResultTitleProps {
    loading: boolean;
    value: string;
    resultCount: number;
}

/**
 * The search navbar results component.
 */
export const SearchNavBarResultTitle: React.FC<ISearchNavBarResultTitleProps> = ({ loading, value, resultCount }: ISearchNavBarResultTitleProps) => {
    if (loading) {
        return <LoadingWrapper size={16} message="SearchResult.Loading" />;
    }

    return <Trans i18nKey={resultCount ? "SearchResult.Title" : "SearchResult.NoResult"} values={{ value }} />;
};