import React from "react";
import Avatar, { ReactAvatarProps } from "react-avatar";

/**
 * The avatar wrapper props.
 */
export interface IAvatarWrapperProps extends ReactAvatarProps {}

const defaultAvatarSize = "48";

/**
 * The avatar wrapper component.
 */
export const AvatarWrapper: React.FC<IAvatarWrapperProps> = ({ size, ...rest }: IAvatarWrapperProps) => (
    <Avatar {...rest} size={size || defaultAvatarSize} maxInitials={3} />
);
