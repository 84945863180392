import { useCallback, useState } from "react";

export interface IEntityDataResult<TFields> {
    data: TFields;
    initialData: TFields;
    setInitialData: React.Dispatch<React.SetStateAction<TFields>>;
    onChange: <TField extends keyof TFields>(field: TField, value: TFields[TField]) => void;
}

export const useEntityData = <TFields extends Record<string, any>>(
    initialData: () => TFields,
): IEntityDataResult<TFields> => {
    const [data, setData] = useState<TFields>(initialData);
    const [initial, setInitial] = useState<TFields>(() => data);

    const onChange = useCallback(
        <TField extends keyof TFields>(field: TField, value: TFields[TField]) =>
            setData((prevData) => ({ ...prevData, [field]: value })),
        [],
    );

    return {
        data,
        initialData: initial,
        onChange,
        setInitialData: setInitial,
    };
};
