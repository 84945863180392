import React from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ICrumb } from "../../models/breadcrumbs/ICrumb";
import { BreadcrumbDropdownItem } from "./crumbs/BreadcrumbDropdownItem";

import "./BreadcrumbDropdown.scss";

interface IBreadcrumbDropdownProps {
    crumbs: ICrumb[];
}

export const BreadcrumbDropdown: React.FC<IBreadcrumbDropdownProps> = ({ crumbs }) => {
    return (
        <UncontrolledDropdown className="breadcrumb-dropdown" direction="end">
            <DropdownToggle outline data-toggle="dropdown">
                <i className="far fa-ellipsis" />
            </DropdownToggle>
            <DropdownMenu className="breadcrumb-menu" container="body" role="menu">
                {crumbs.map((c, i) => {
                    return <BreadcrumbDropdownItem crumb={c} testSelectorValueIndex={i} key={`${i} - ${c.name}`} />;
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
