/**
 * Formats a byte number to its most readable size.
 * @param bytes the number of bytes.
 * @returns a readable string.
 */
export const formatBytes = (bytes: number, decimal: number = 0) => {
    if (bytes <= 0) {
        return "0 B";
    }

    const k = 1024;
    const sizes = ["B", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    if (i > sizes.length) {
        return "";
    }

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimal))} ${sizes[i]}`;
};
