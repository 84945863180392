import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useShortcutContext } from "../../contexts/ShortcutContext";
import { useNavigationPromptDialog } from "../../hooks/navigation/NavigationPromptDialogHook";
import { FormValidator } from "../../formValidators/FormValidator";
import { EditAccountEntityContext, IEditAccountEntityContextProps } from "../../contexts/EditAccountEntityContext";
import { useDialogContext } from "../../contexts/DialogContext";
import { AccountView } from "./AccountView";
import { useEditEntity } from "../../hooks/EditEntityHook";
import { SaveButton } from "../buttons/SaveButton";

/**
 * The edit account entity view props interface.
 */
type IEditAccountEntityWrapperProps<TEntity, TValidator extends FormValidator<unknown>> = {
    children?: React.ReactNode;
    formValidatorProvider: () => TValidator;
    loadedEntity: TEntity;
    promptDialogMessageKey: string;
    hasSaveButton?: boolean;
    title: string;
    update: (entity: TEntity) => Promise<TEntity>;
};

/**
 * The edit account entity wrapper.
 */
export const EditAccountEntityWrapper = <TEntity, TValidator extends FormValidator<unknown>>({
    children,
    formValidatorProvider,
    loadedEntity,
    promptDialogMessageKey,
    hasSaveButton,
    title,
    update,
}: IEditAccountEntityWrapperProps<TEntity, TValidator>) => {
    const { globalShortcutEnabled } = useShortcutContext();
    const [canSave, setCanSave] = useState(false);
    const { dialogState } = useDialogContext();

    const {
        entity,
        errors,
        initialEntity,
        isDirty,
        setEntityProperties,
        setErrors,
        onSave,
        isSaving,
        formValidator,
        resetErrors,
    } = useEditEntity<TEntity, TValidator>({
        updateEntity: update,
        formValidatorProvider,
        loadedEntity,
    });

    const canSaveEntity = useCallback(
        () => isDirty() && !isSaving && formValidator.isValid(entity),
        [entity, formValidator, isDirty, isSaving],
    );

    useEffect(() => {
        (async () => {
            setCanSave(await canSaveEntity());
        })();
    }, [canSaveEntity]);

    useNavigationPromptDialog({
        messageKey: promptDialogMessageKey,
        isDirty: isDirty(),
        componentId: "EditAccountEntityWrapper",
    });

    useHotkeys(
        "ctrl+s, command+s",
        (e) => {
            e.preventDefault();
            void (async () => {
                if (await canSaveEntity()) {
                    void onSave();
                }
            })();
        },
        {
            filter: () => globalShortcutEnabled && !dialogState.open,
            enableOnTags: ["INPUT", "TEXTAREA", "SELECT"],
        },
        [onSave, dialogState.open],
    );

    const initialContextValue: IEditAccountEntityContextProps<TEntity, TValidator> | undefined = useMemo(() => {
        return !entity || !initialEntity
            ? undefined
            : {
                  entityProps: {
                      entity,
                      initialEntity,
                      setEntityProperties,
                      formValidator,
                      update: onSave,
                      canSave,
                      isSaving,
                  },
                  errorProps: {
                      errors,
                      setErrors,
                      resetErrors,
                  },
                  dirtyProps: {
                      isDirty,
                      promptDialogMessageKey,
                  },
              };
    }, [
        canSave,
        entity,
        errors,
        formValidator,
        initialEntity,
        isDirty,
        isSaving,
        onSave,
        promptDialogMessageKey,
        resetErrors,
        setEntityProperties,
        setErrors,
    ]);
    const saveButton = useMemo(() => {
        if (hasSaveButton) {
            return <SaveButton isSaving={isSaving} disabled={!canSave} onClick={() => void onSave()} />;
        }
    }, [canSave, isSaving, onSave, hasSaveButton]);

    return (
        <AccountView title={title} saveButton={saveButton}>
            <EditAccountEntityContext.Provider value={initialContextValue}>
                {children}
            </EditAccountEntityContext.Provider>
        </AccountView>
    );
};
