import { HubConnection, IHttpConnectionOptions, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { useState, useEffect, useCallback } from "react";
import authService from "../../components/api-authorization/AuthorizeService";
import { useAppConfigContext } from "../../contexts/AppConfigContext";

/**
 * The hub connection props interface.
 */
interface IHubConnectionProps {
    hubUrl: string;
    withAuthentification?: boolean;
}

/**
 * The hub connection result interface.
 */
interface IHubConnectionResult {
    connection: HubConnection | null;
}

/**
 * The hub connection hook.
 */
export const useHubConnection = ({
    hubUrl,
    withAuthentification = true,
}: IHubConnectionProps): IHubConnectionResult => {
    const { signalRLogLevel } = useAppConfigContext();

    const [connection, setConnection] = useState<null | HubConnection>(null);

    const buildOptions = useCallback(async () => {
        const options: IHttpConnectionOptions = {};

        if (withAuthentification) {
            const token = await authService.getAccessToken();

            if (token) {
                // token is read inside HubConnectionBuilder.
                /* istanbul ignore next */
                options.accessTokenFactory = () => token;
            }
        }

        return options;
    }, [withAuthentification]);

    useEffect(() => {
        if (!hubUrl) {
            return;
        }

        void (async (): Promise<void> => {
            const options = await buildOptions();

            const hubConnection = new HubConnectionBuilder()
                .withUrl(hubUrl, options)
                .withAutomaticReconnect()
                .configureLogging(signalRLogLevel)
                .build();
            setConnection(hubConnection);
        })();
    }, [hubUrl, signalRLogLevel, buildOptions]);

    useEffect(() => {
        if (!connection) {
            return;
        }

        if (connection.state === HubConnectionState.Disconnected) {
            void connection.start();
        }
    }, [connection]);

    return {
        connection,
    };
};
