/**
 * The data set type.
 **/
export type DataSetType = "System.Form" | "System.Json" | "System.Xml";

/**
 * Gets the resource key based on the dataset type
 */
export enum DataSetTypeTranslationMap {
    "System.Form" = "Dataset.Filter.Type.Form",
    "System.Xml" = "Dataset.Filter.Type.Xml",
    "System.Json" = "Dataset.Filter.Type.Json",
}
