import React from "react";
import { useTranslation } from "react-i18next";
import { useTabs } from "../../hooks/TabsHook";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { TabContent } from "../tabs/TabContent";
import { ServiceHookOverview } from "./ServiceHookOverview";
import { ServiceHookExecutionView } from "./ServiceHookExecutionView";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { IServiceHookModel } from "../../models/serviceHooks/IServiceHooksModel";
import { ServiceHookFormValidator } from "../../formValidators/ServiceHookFormValidator";
import { buildOverview } from "../../helpers/EntityHelper";

/**
 * the list of tabs in the project settings view
 */
const editServiceHookViewTabs = ["overview", "executions"] as const;

/**
 * the list of tabs in the project settings view as Type
 */
type EditServiceHookTabType = (typeof editServiceHookViewTabs)[number];

/**
 * The Project Settings View Tabs
 */
export const EditServiceHookViewTabs: React.FC = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useTabs<EditServiceHookTabType>(editServiceHookViewTabs, "overview");
    const {
        dirtyProps: { isDirty },
    } = useEditEntityContext<IServiceHookModel, ServiceHookFormValidator>();

    const tabs: INavMenuItemProps[] = [
        buildOverview(false, isDirty(["eventType", "state", "type", "config"]), setActiveTab),
        {
            displayName: "ServiceHookExecutions.Title",
            name: "executions",
            testSelectorValue: "executionsItem",
            onToggle: setActiveTab,
            icon: "far fa-play",
        },
    ];

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <ServiceHookOverview />
            </TabContent>
            <TabContent tabId="executions" className="executions" title={t("ServiceHookExecutions.Title")}>
                <ServiceHookExecutionView />
            </TabContent>
        </NavMenu>
    );
};
