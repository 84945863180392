import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { Trans } from "react-i18next";

interface IAlertIconProps {
    messageKey: string;
    messageParams: Record<string, string>;
    alertId: string;
}

export const AlertPopover: React.FC<IAlertIconProps> = ({ messageKey, messageParams, alertId }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <span
            className="nav-item"
            id={`Popover-Alert-${alertId}`}
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            onFocus={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            tabIndex={0}
            role="alert"
        >
            <i className="fa-solid fa-circle-info nav-link info-icon" />
            <Popover target={`Popover-Alert-${alertId}`} placement="top" isOpen={isOpen}>
                <PopoverBody className="info-popover">
                    <Trans i18nKey={messageKey} values={messageParams} />
                </PopoverBody>
            </Popover>
        </span>
    );
};
