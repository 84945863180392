import React, { Dispatch, SetStateAction } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { Trans, useTranslation } from "react-i18next";
import { string } from "yup";

interface IDependencyConfirmationProps {
    setConfirmation: Dispatch<SetStateAction<boolean>>;
}

export const DependencyConfirmation: React.FC<IDependencyConfirmationProps> = ({
    setConfirmation,
}: IDependencyConfirmationProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    const confirmationString = t("Dependency.Confirmation");

    const deleteConfirmationSchema = string()
        .required()
        .test("is-confirmed", "", (value) => value?.toUpperCase() === confirmationString.toUpperCase());

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (deleteConfirmationSchema.validateSync(event.target.value)) {
                setConfirmation(true);
            }
        } catch (err) {
            setConfirmation(false);
            return;
        }
    };

    return (
        <FormGroup>
            <Label for="delete-confirmation">
                <Trans
                    i18nKey={"Common.DeleteWarning.Confirmation"}
                    values={{ confirmationValue: confirmationString }}
                ></Trans>
            </Label>
            <Input type="text" id="delete-confirmation" { ...setSelector("yesDeleteSelectedItemTextbox") } onChange={onChange} />
        </FormGroup>
    );
};
