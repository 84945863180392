import React, { useCallback, useEffect, useState } from "react";
import { useModalContext } from "../../contexts/ModalContext";
import { useAppConfigContext } from "../../contexts/AppConfigContext";
import { PreviewConsentForm } from "./PreviewConsentForm";
import { useNavigation } from "../../hooks/NavigationHook";
import { applicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import { useAccountApi } from "../../hooks/account/AccountApiHook";
import { useUserContext } from "../../contexts/UserContext";

export const PreviewConsentWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { showModal, closeModal } = useModalContext();
    const { preview } = useAppConfigContext();
    const { previewConsent, refreshProfile } = useUserContext();
    const { replace } = useNavigation();
    const { consentPreview } = useAccountApi();
    const [didClose, setDidClose] = useState(false);

    const onSubmit = useCallback(
        ({ consent }: { consent: boolean }) => {
            return consentPreview(consent);
        },
        [consentPreview],
    );

    useEffect(() => {
        if (didClose) {
            replace(applicationPaths.logOut, { state: { local: true } });
        }
    }, [didClose, replace]);

    useEffect(() => {
        if (preview && previewConsent === false) {
            showModal({
                contentToDisplay: PreviewConsentForm,
                allowCreateWhenNotDirty: true,
                alwaysShown: true,
                entity: false,
                formPropsToFlow: {},
                modalProps: {
                    onClose: () => {
                        setDidClose(true);
                        closeModal();
                    },
                    createLabel: "Preview.Consent.Accept",
                    className: "preview-consent-modal",
                    cancelLabel: "Preview.Consent.Refuse",
                    size: "lg",
                    expandable: true,
                    headerless: true,
                },
                onCreate: onSubmit,
                onCreated: () => {
                    refreshProfile();
                    closeModal();
                },
            });
        }
    }, [closeModal, onSubmit, preview, previewConsent, refreshProfile, replace, showModal]);

    return <>{children}</>;
};
