import React from "react";
import { Link } from "react-router-dom";
import { UserGroupAvatar } from "../userGroups/UserGroupAvatar";

interface IPermissionGroupNameColumnContentProps {
    name: string;
    groupId: string;
    groupLink: (groupId: string) => string;
}

export const PermissionGroupNameColumnContent: React.FC<IPermissionGroupNameColumnContentProps> = ({
    name,
    groupId,
    groupLink,
}) => {
    return (
        <div className="permissions-group-wrapper">
            <span className="permissions-group-wrapper__avatar">
                <UserGroupAvatar groupName={name} parentType="list" />
            </span>
            <Link className="permissions-group-wrapper__name text-truncate" to={groupLink(groupId)}>
                {name}
            </Link>
        </div>
    );
};
