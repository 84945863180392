import { ContentManagerEntityType } from "./models/EntityType";

export const pathKeys = {
    root: "/",
    apiKey: "ApiKeys",
    applicationSettings: "_applicationSettings",
    dataSet: "_dataSets",
    template: "_templates",
    dataSetViewer: "_dataSetViewer",
    hubs: `${window.location.origin}/hubs/`,
    projectDashboard: "_dashboard",
    organization: "_organizations",
    user: "_users",
    home: "_home",
    projectSettings: "_projectSettings",
    email: "Email",
    password: "Password",
    externalProviders: "ExternalProviders",
    profile: "Profile",
    personalData: "PersonalData",
    twoFactorAuthentication: "TwoFactorAuthentication",
    contentManager: "_content",
};

export const applicationSettingsBasePath = {
    base: `/${pathKeys.applicationSettings}`,
};

const accountBasePath = "/_account";
const accountApiBasePath = "/_api/account";
const projectBasePath = "/:organizationName/:projectName";
const dataSetBasePath = `${projectBasePath}/${pathKeys.dataSet}`;
const organizationBasePath = `/${pathKeys.organization}`;
const templateBasePath = `${projectBasePath}/${pathKeys.template}`;
const publicBasePath = "/_public";
const contentManagerBasePath = `${projectBasePath}/${pathKeys.contentManager}`;

const specificProjectPath = (organizationName: string, projectName: string): string =>
    `/${encodeURIComponent(organizationName)}/${encodeURIComponent(projectName)}`;

const dataSetBasePathLink = (organizationName: string, projectName: string): string => {
    return `${specificProjectPath(organizationName, projectName)}/${pathKeys.dataSet}`;
};
const templateBasePathLink = (organizationName: string, projectName: string): string => {
    return `${specificProjectPath(organizationName, projectName)}/${pathKeys.template}`;
};

const contentManagerBasePathLink = (organizationName: string, projectName: string): string => {
    return `${specificProjectPath(organizationName, projectName)}/${pathKeys.contentManager}`;
};

export const accountApiPaths = {
    externalProvidersLoginProvider: `${accountApiBasePath}/externalproviders/loginprovider`,
    externalProvidersLoginProviderCallback: `${accountApiBasePath}/externalproviders/loginprovidercallback`,
    redirect: {
        externalProviderLogin: (provider: string) =>
            `${accountApiPaths.externalProvidersLoginProvider}?provider=${provider}&redirectUrl=${encodeURIComponent(
                `${accountApiPaths.externalProvidersLoginProviderCallback}?redirectUrl=${accountPaths.route.externalProviders}`,
            )}`,
    },
};

export const accountPaths = {
    route: {
        base: accountBasePath,
        apiKeys: `${accountBasePath}/${pathKeys.apiKey}`,
        password: `${accountBasePath}/${pathKeys.password}`,
        email: `${accountBasePath}/${pathKeys.email}`,
        externalProviders: `${accountBasePath}/${pathKeys.externalProviders}`,
        profile: `${accountBasePath}/${pathKeys.profile}`,
        personalData: `${accountBasePath}/${pathKeys.personalData}`,
        twoFactorAuthentication: `${accountBasePath}/${pathKeys.twoFactorAuthentication}`,
    },
};

export const apiKeyPaths = {
    route: {
        base: accountPaths.route.apiKeys,
        edit: `${accountPaths.route.apiKeys}/Edit/:apiKeyId`,
    },
    link: {
        base: accountPaths.route.apiKeys,
        edit: (apiKeyId: string): string => `${accountPaths.route.apiKeys}/Edit/${apiKeyId}`,
    },
};

export const dataSetPaths = {
    route: {
        base: dataSetBasePath,
        edit: `${dataSetBasePath}/Edit/:dataSetId`,
    },
    link: {
        base: dataSetBasePathLink,
        edit: (organizationName: string, projectName: string, dataSetId: string): string => {
            return `${dataSetBasePathLink(organizationName, projectName)}/Edit/${dataSetId}`;
        },
    },
};

export const templatePaths = {
    route: {
        base: templateBasePath,
        edit: `${templateBasePath}/Edit/:templateId`,
    },
    link: {
        base: templateBasePathLink,
        edit: (organizationName: string, projectName: string, templateId: string): string => {
            return `${templateBasePathLink(organizationName, projectName)}/Edit/${templateId}`;
        },
    },
};

export const dataSetSamplePaths = {
    link: {
        edit: (organizationName: string, projectName: string, dataSetId: string, dataSetSampleId: string): string => {
            return `${dataSetBasePathLink(
                organizationName,
                projectName,
            )}/Edit/${dataSetId}?dataSetSampleId=${dataSetSampleId}#samples`;
        },
    },
};

export const dataSetViewerPaths = {
    route: {
        base: `/${pathKeys.dataSetViewer}/:projectId/:dataSetId`,
    },
    link: {
        base: (projectId: string, dataSetId: string): string => {
            return `/${pathKeys.dataSetViewer}/${projectId}/${dataSetId}`;
        },
    },
};

export const contentManagerPaths = {
    route: {
        base: contentManagerBasePath,
        edit: `${contentManagerBasePath}/Edit/`,
        editItem: `${contentManagerBasePath}/Edit/:type/:itemId`,
    },
    link: {
        base: contentManagerBasePathLink,
        folder: (organizationName: string, projectName: string, folderPath: string): string => {
            const params = new URLSearchParams({ path: folderPath });
            return `${contentManagerBasePathLink(organizationName, projectName)}?${params.toString()}`;
        },
        edit: (organizationName: string, projectName: string, itemId: string, itemType: ContentManagerEntityType) => {
            return `${contentManagerBasePathLink(organizationName, projectName)}/Edit/${itemType}/${itemId}`;
        },
    },
};

export const projectPaths = {
    route: {
        base: projectBasePath,
        dashboard: `${projectBasePath}/${pathKeys.projectDashboard}`,
        dataSets: dataSetPaths.route.base,
        templates: templatePaths.route.base,
        contentManager: contentManagerPaths.route.base,
        settings: `${projectBasePath}/${pathKeys.projectSettings}`,
        serviceHooks: `${projectBasePath}/${pathKeys.projectSettings}/ServiceHooks`,
        languages: `${projectBasePath}/${pathKeys.projectSettings}/Languages`,
    },
    link: {
        base: specificProjectPath,
        dashboard: (organizationName: string, projectName: string): string => {
            return `${specificProjectPath(organizationName, projectName)}/${pathKeys.projectDashboard}`;
        },
        dataSets: dataSetPaths.link.base,
        templates: templatePaths.link.base,
        contentManager: contentManagerPaths.link.base,
        settings: (organizationName: string, projectName: string): string => {
            return `${specificProjectPath(organizationName, projectName)}/${pathKeys.projectSettings}`;
        },
    },
};

export const serviceHooksPaths = {
    route: {
        edit: `${projectPaths.route.settings}/ServiceHook/:serviceHookId`,
    },
    link: {
        edit: (organizationName: string, projectName: string, serviceHookId: string): string => {
            return `${projectPaths.link.settings(organizationName, projectName)}/ServiceHook/${serviceHookId}`;
        },
        base: (organizationName: string, projectName: string): string => {
            return `${specificProjectPath(organizationName, projectName)}/${pathKeys.projectSettings}#serviceHooks`;
        },
    },
};

export const languagePaths = {
    route: {
        edit: `${projectPaths.route.settings}/Languages/:languageId`,
    },
    link: {
        edit: (organizationName: string, projectName: string, languageId: string): string => {
            return `${projectPaths.link.settings(organizationName, projectName)}/Languages/${languageId}`;
        },
        base: (organizationName: string, projectName: string): string => {
            return `${specificProjectPath(organizationName, projectName)}/${pathKeys.projectSettings}#languages`;
        },
    },
};

export const organizationPaths = {
    route: {
        base: organizationBasePath,
        edit: `${organizationBasePath}/Edit/:organizationId`,
    },
    link: {
        base: organizationBasePath,
        edit: (organizationId: string): string => `${organizationBasePath}/Edit/${organizationId}`,
    },
};

const groupPermissionsPath = (basePath: string) => `${basePath}/Groups/:groupId`;

export const PermissionGroupPaths = {
    route: {
        edit: {
            project: groupPermissionsPath(projectPaths.route.settings),
            organization: groupPermissionsPath(organizationPaths.route.edit),
            appSettings: groupPermissionsPath(applicationSettingsBasePath.base),
        },
    },
    link: {
        edit: {
            project: (organizationName: string, projectName: string, groupId: string): string =>
                `${projectPaths.link.settings(organizationName, projectName)}/Groups/${groupId}`,
            organization: (organizationId: string, groupId: string) =>
                `${organizationPaths.link.edit(organizationId)}/Groups/${groupId}`,
            appSettings: (groupId: string) => `${applicationSettingsBasePath.base}/Groups/${groupId}`,
        },
        base: {
            project: (organizationName: string, projectName: string): string =>
                `${specificProjectPath(organizationName, projectName)}/${pathKeys.projectSettings}#permissions`,
            organization: (organizationId: string) => `${organizationPaths.link.edit(organizationId)}#permissions`,
            appSettings: () => `${applicationSettingsBasePath.base}#permissions`,
        },
    },
};

export const twoFactorAuthenticationPaths = {
    route: {
        base: accountPaths.route.twoFactorAuthentication,
        configure: `${accountPaths.route.twoFactorAuthentication}/_configure`,
        resetAuthenticator: `${accountPaths.route.twoFactorAuthentication}/_resetAuthenticator`,
        resetRecoveryCodes: `${accountPaths.route.twoFactorAuthentication}/_resetRecoveryCodes`,
        disable: `${accountPaths.route.twoFactorAuthentication}/_disable`,
    },
};

export const personalDataPaths = {
    route: {
        base: accountPaths.route.personalData,
        delete: `${accountPaths.route.personalData}/_delete`,
    },
};

export const userPaths = {
    base: `/${pathKeys.user}`,
};

export const publicPaths = {
    base: publicBasePath,
    dataset: `${publicBasePath}${dataSetViewerPaths.route.base}`,
};

export const paths = {
    home: `${pathKeys.root}${pathKeys.home}`,
    account: accountBasePath,
    apiKeys: apiKeyPaths.route.base,
    email: accountPaths.route.email,
    password: accountPaths.route.password,
    externalProviders: accountPaths.route.externalProviders,
    profile: accountPaths.route.profile,
    personalData: accountPaths.route.personalData,
    applicationSettings: applicationSettingsBasePath.base,
    dataSets: dataSetPaths.route.base,
    templates: templatePaths.route.base,
    dataSetViewer: dataSetViewerPaths.route.base,
    project: projectPaths.route.base,
    users: userPaths.base,
    organizations: organizationPaths.route.base,
    twoFactorAuthentication: twoFactorAuthenticationPaths.route.base,
    serviceHook: serviceHooksPaths.route.edit,
    contentManager: contentManagerPaths.route.base,
    language: languagePaths.route.edit,
    projectPermissionGroup: PermissionGroupPaths.route.edit.project,
    organizationPermissionGroup: PermissionGroupPaths.route.edit.organization,
    applicationPermissionGroup: PermissionGroupPaths.route.edit.appSettings,
};

export const translationPaths = {
    [paths.home]: "Route.Home",
    [paths.account]: "Account.Manage",
    [paths.apiKeys]: "Route.ApiKeys",
    [paths.applicationSettings]: "Route.ApplicationSettings",
    [projectPaths.route.contentManager]: "Route.ContentManager",
    [projectPaths.route.dataSets]: "Route.DataSets",
    [projectPaths.route.templates]: "Route.Templates",
    [projectPaths.route.settings]: "Route.Settings.Project",
    [paths.organizations]: "Route.Organizations",
    [paths.users]: "Route.Users",
    [paths.email]: "Route.Email",
    [paths.password]: "Route.Password",
    [paths.externalProviders]: "Route.ExternalProviders",
    [paths.profile]: "Route.Profile",
    [paths.personalData]: "Route.PersonalData",
    [paths.twoFactorAuthentication]: "2FA.Title",
    [paths.serviceHook]: "ServiceHook.Title",
    [paths.language]: "Common.Language_plural",
    [paths.contentManager]: "Route.ContentManager",
};

export const hubPaths = {
    notification: `${pathKeys.hubs}notification`,
    project: `${pathKeys.hubs}project`,
};
