import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";
import { EntityForm } from "../forms/EntityForm";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ITemplateFormFields } from "../../models/templates/ITemplateFormFields";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { TemplateType } from "../../models/templates/TemplateType";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { SelectDataSets } from "../selects/SelectDataSets";
import { RadioComponentGroup } from "../radio/RadioComponentGroup";
import { EntityTypeClassName } from "../../models/EntityTypeClassName";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useTemplateValidation } from "../../hooks/template/TemplateValidation";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./TemplateForm.scss";

type FormMode = "Create" | "Clone";

interface ITemplateFormProps {
    initialValue: ITemplateFormFields;
    onFormChange: (formData: IEntityFormData<ITemplateFormFields>) => void;
    mode: FormMode;
}

export const TemplateForm: React.FC<ITemplateFormProps> = ({
    initialValue,
    onFormChange,
    mode,
}: ITemplateFormProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: useTemplateValidation,
        isDirty:
            mode === "Clone"
                ? () => {
                      return true;
                  }
                : undefined,
    });

    const handleOnFocus = () => {
        inputRef.current?.focus();
        inputRef.current?.select();
    };

    useEffect(() => {
        mode === "Clone" && handleOnFocus();
    }, [mode]);

    return (
        <EntityForm name="create-template" className="createTemplateForm">
            <FormGroup>
                <LocalizedLabel aria-required="true" for="create-template-name" text="Common.Name" required />
                <ValidatedInputWithStatus
                    id="create-template-name"
                    innerRef={inputRef}
                    name="name"
                    type="text"
                    autoFocus={true}
                    value={data.name}
                    error={errors.name}
                    isBusy={validationStatuses.name === "Validating"}
                    {...setSelector("nameTextbox")}
                    onChange={(e) => onChange("name", e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="create-template-description" text="Common.Description" />
                <ValidatedInput
                    id="create-template-description"
                    name="description"
                    type="textarea"
                    value={data.description}
                    error={errors.description}
                    {...setSelector("descriptionTextbox")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("description", e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <div className="select-template-type">{t("CommonTemplate.TemplateType")}</div>
                <RadioComponentGroup
                    radioName="TemplateType"
                    selectedValue={data.type}
                    options={[
                        {
                            label: "Common.Standard",
                            value: TemplateType.Standard,
                            icon: EntityTypeClassName["TemplateStandard"],
                        },
                    ]}
                    onChange={(type) => onChange("type", type)}
                />
            </FormGroup>
            <FormGroup>
                <SelectDataSets
                    projectId={initialValue.projectId}
                    selectedValues={data.dataSetIds}
                    onChange={(dataSetIds) => onChange("dataSetIds", dataSetIds)}
                />
            </FormGroup>
        </EntityForm>
    );
};
