import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSetAtom } from "jotai";
import { IEntityDropdownMenuItem } from "../../../components/dropdowns/EntityDropdownMenu";
import { useContentManagerSelectAction } from "../ContentManagerSelectAction";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { ContentFormValidator } from "../../../formValidators/ContentFormValidator";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { WopiAction } from "../../../models/wopi/IWopiModel";
import { useOpenItem } from "../OpenItemHook";
import { useDownloadItem } from "../DownloadItemHook";
import { useTranslateFile } from "../TranslateFileHook";
import { microsoftExtensionIconTranslationMap } from "../../../helpers/MicrosoftBrandHelper";
import { useNavigation } from "../../NavigationHook";
import { contentManagerPaths } from "../../../PathConstants";
import { useCurrentFolderName } from "../CurrentFolderNameHook";
import { FoldersTree, ITreeItem } from "../../../models/contentManager/FolderTree";
import { loadingModalStateAtom } from "../../../atoms/ContentManager";
import { EditContentManagerItemSearchParam } from "./EditContentItemHook";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { BulkOperationSummaryWithPayload } from "../../../models/BulkOperationModel";

interface IEditContentItemDropdownMenuProps {
    canEdit: boolean;
    canRead: boolean;
    foldersTree: FoldersTree;
    parentFolderId: string | null;
}

export const useEditContentItemDropdownMenu = ({
    canEdit,
    canRead,
    foldersTree,
    parentFolderId,
}: IEditContentItemDropdownMenuProps): IEntityDropdownMenuItem[] => {
    const { organizationName, name: projectName } = useProjectContext();
    const { replace } = useNavigation();
    const {
        entityProps: { entity, setEntityProperties, setInitialEntityProperties },
        dirtyProps: { isDirty },
    } = useEditEntityContext<IContentEntityModel, ContentFormValidator>();
    const { t } = useTranslation();
    const setLoadingModalState = useSetAtom(loadingModalStateAtom);
    const originFolderName = useCurrentFolderName(EditContentManagerItemSearchParam);

    const { onAction } = useContentManagerSelectAction({
        originFolderName,
        originFolderId: parentFolderId,
        initialFoldersTree: foldersTree,
        checkParentExistsOnError: false,
    });

    const { openFile, gotoFolder, getWopiFileInfo } = useOpenItem();
    const { downloadFile, downloadFolder } = useDownloadItem();
    const { translate, canTranslateItem } = useTranslateFile();

    const tryOpenFile = useCallback(
        async (fileId: string, fileName: string, translationLanguageCode?: string, action?: WopiAction) => {
            try {
                await openFile(fileId, fileName, translationLanguageCode, action);
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        },
        [openFile],
    );

    const onMoveComplete = useCallback(
        (summary: BulkOperationSummaryWithPayload<string[]> | undefined, destination: ITreeItem | null) => {
            if (summary && summary.successCount === 1) {
                const path = destination ? `${destination.path}/${entity.name}` : `/${entity.name}`;
                setEntityProperties({ path });
                setInitialEntityProperties({ path });
                replace(contentManagerPaths.link.edit(organizationName, projectName, entity.id, entity.type));
            }
        },
        [
            entity.id,
            entity.name,
            entity.type,
            replace,
            organizationName,
            projectName,
            setEntityProperties,
            setInitialEntityProperties,
        ],
    );

    const entityIsDirty = isDirty();

    const openItemDropdownItem = useMemo<IEntityDropdownMenuItem>(() => {
        const openMenu: Omit<IEntityDropdownMenuItem, "onClick"> = {
            text: t("Common.Open"),
            className: "fal fa-arrow-up-right-from-square",
            testSelectorValue: "openItem",
            disabled: entityIsDirty,
            hidden: !canRead,
        };

        if (entity.type === "Folder") {
            return {
                ...openMenu,
                onClick: () => {
                    gotoFolder(entity.path);
                },
            };
        }

        const wopiFileInfo = getWopiFileInfo(entity, canEdit, canRead);
        if (!wopiFileInfo) {
            return {
                ...openMenu,
                onClick: () => {
                    tryOpenFile(entity.id, entity.name);
                },
            };
        }

        const { action, microsoftProductItem } = wopiFileInfo;
        return {
            text: t(WopiAction.Edit === action ? "Microsoft.Open" : "Microsoft.View", {
                applicationName: microsoftProductItem.appName,
            }),
            className: microsoftExtensionIconTranslationMap[microsoftProductItem.appName],
            disabled: entityIsDirty,
            onClick: () => {
                tryOpenFile(entity.id, entity.name, undefined, action);
            },
            hidden: !canRead,
        };
    }, [getWopiFileInfo, entity, canEdit, canRead, t, entityIsDirty, gotoFolder, tryOpenFile]);

    return [
        openItemDropdownItem,
        {
            text: t("Common.Move"),
            className: "fal fa-arrow-right",
            disabled: entityIsDirty,
            testSelectorValue: "moveToItem",
            onClick: () => {
                onAction("Move", [entity], onMoveComplete);
            },
            hidden: !canEdit,
        },
        {
            text: t("Common.Copy"),
            className: "fal fa-files",
            disabled: entityIsDirty,
            testSelectorValue: "copyToItem",
            onClick: () => {
                onAction("Copy", [entity]);
            },
            hidden: !canEdit,
        },
        {
            text: t("Common.Download"),
            className: "fal fa-arrow-down-to-line",
            disabled: entityIsDirty,
            testSelectorValue: "downloadItem",
            onClick: () => {
                if (entity.type === "Folder") {
                    setLoadingModalState({
                        type: "OPEN_MODAL",
                        payload: {
                            label: "Common.Download.Loading",
                        },
                    });
                    downloadFolder(entity.id, `${entity.name}.zip`)
                        .catch((error) => {
                            defaultRequestErrorHandler(error);
                        })
                        .finally(() => {
                            setLoadingModalState({ type: "CLOSE_MODAL" });
                        });
                } else {
                    downloadFile(entity.id).catch((error) => {
                        defaultRequestErrorHandler(error);
                    });
                }
            },
            hidden: !canRead,
        },
        ...(canTranslateItem(entity)
            ? [
                  {
                      text: t("Common.Tab.Translate"),
                      className: "fal fa-language",
                      disabled: entityIsDirty,
                      onClick: () => {
                          translate(entity);
                      },
                      hidden: !canRead,
                  },
              ]
            : []),
    ];
};
