import React, { useCallback, useEffect, useRef } from "react";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import { ContentManagerItemPropertiesView } from "../../components/contentManager/itemProperties/ItemPropertiesView";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";

interface IUseContentPropertiesSidePanelProps {
    selectedItems?: IContentEntityModel[];
}

interface IUseContentPropertiesSidePanelResult {
    showSidePanel: (reselectHandler?: (currentId: string) => void) => void;
}

export const useContentPropertiesSidePanel = ({
    selectedItems,
}: IUseContentPropertiesSidePanelProps): IUseContentPropertiesSidePanelResult => {
    const { setSidePanelState, showSidePanel, isDisplayed } = useSidePanelContext();
    const reselectHandlerRef = useRef<(currentId: string) => void>();
    const { setBeforeNavigate } = useContentManagerContext();

    useEffect(() => {
        isDisplayed &&
            setSidePanelState((prevState) => ({
                ...prevState,
                content: (
                    <ContentManagerItemPropertiesView
                        selectedItems={selectedItems}
                        reselectHandler={reselectHandlerRef.current}
                        setBeforeNavigate={setBeforeNavigate}
                    />
                ),
            }));
    }, [isDisplayed, selectedItems, setSidePanelState, setBeforeNavigate]);

    const show: (reselectHandler?: (currentId: string) => void) => void = useCallback(
        (reselectHandler) => {
            reselectHandlerRef.current = reselectHandler;
            showSidePanel();
        },
        [showSidePanel],
    );

    return {
        showSidePanel: show,
    };
};
