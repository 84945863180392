import { useCallback, useMemo } from "react";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { useNavigation } from "../NavigationHook";
import { organizationPaths } from "../../PathConstants";
import { useSidebarContext } from "../../contexts/SidebarContext";
import { extractErrorMessage, defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { NotificationService } from "../../services/NotificationService";
import { useOrganizationApi } from "./OrganizationApiHook";
import { useParams } from "react-router-dom";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../permission/PermissionCheckHook";
import { usePermissionKey } from "../permission/PermissionKeyHook";
import { useOrganizationContext } from "../../contexts/OrganizationContext";

interface EditOrganizationPermissionKeys {
    organizationWriteKey: string;
    organizationDeleteKey: string;
}

interface EditOrganizationHookReturn {
    isAllowed: (permissionKey: string) => boolean;
    permissionKeys: EditOrganizationPermissionKeys;
    loadOrganization: () => Promise<IOrganizationModel>;
    nameIsUniqueValidator: (name: string) => Promise<boolean>;
    removeOrganization: (organizationName: string) => Promise<void>;
    saveOrganization: (organization: IOrganizationModel) => Promise<IOrganizationModel>;
}

export const useEditOrganization = (): EditOrganizationHookReturn => {
    const { organizationId } = useParams<{ organizationId: string }>() as Record<string, string>;
    const { get, nameIsUnique, remove, update } = useOrganizationApi();
    const organizationContext = useOrganizationContext();
    const { setRefreshSidebar } = useSidebarContext();

    const { replace } = useNavigation();

    const saveOrganization = useCallback(
        async (organization: IOrganizationModel): Promise<IOrganizationModel> => {
            // error is handled by onSave method in EditEntityWrapper
            const result = await update(organization);
            NotificationService.addSuccessNotification({
                messageKey: "EditOrganizationView.SaveSuccess",
                messageKeyParams: { name: result.name },
            });
            setRefreshSidebar(true);
            organizationContext?.refreshOrganization();
            return result;
        },
        [update, setRefreshSidebar, organizationContext],
    );

    const removeOrganization = useCallback(
        async (organizationName: string): Promise<void> => {
            try {
                await remove(organizationId);
                NotificationService.addSuccessNotification({
                    messageKey: "OrganizationsView.DeleteSuccessSingle",
                    messageKeyParams: { name: organizationName },
                });
                replace(organizationPaths.link.base, { state: { deleted: true } });
                setRefreshSidebar(true);
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        },
        [remove, organizationId, replace, setRefreshSidebar],
    );

    const loadOrganization = useCallback(async (): Promise<IOrganizationModel> => {
        try {
            return await get(organizationId);
        } catch (error) {
            NotificationService.addErrorNotification({ message: extractErrorMessage(error) });
            replace(organizationPaths.link.base, { state: { loadError: true } });
            setRefreshSidebar(true);
            organizationContext?.refreshOrganization();
            return {} as IOrganizationModel;
        }
    }, [get, organizationId, replace, setRefreshSidebar, organizationContext]);

    const organizationWriteKey = usePermissionKey({
        permission: PermissionKeys.organization.write,
        objectId: organizationId,
    });

    const organizationDeleteKey = usePermissionKey({
        permission: PermissionKeys.organization.delete,
        objectId: organizationId,
    });

    const permissionsObject = useMemo(
        () => ({
            permissionKeys: [organizationWriteKey, organizationDeleteKey],
        }),
        [organizationWriteKey, organizationDeleteKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    return {
        isAllowed,
        permissionKeys: { organizationWriteKey: organizationWriteKey, organizationDeleteKey: organizationDeleteKey },
        nameIsUniqueValidator: nameIsUnique,
        saveOrganization,
        removeOrganization,
        loadOrganization,
    };
};
