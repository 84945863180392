import React, { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataSetIcon from "../../assets/img/icons/Data set.svg";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { ISortField } from "../../models/ISortField";
import { SortOrder } from "../../models/SortOrder";
import { NotificationService } from "../../services/NotificationService";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { IDataSetModel } from "../../models/dataSets/IDataSetModel";
import { DataSetType, DataSetTypeTranslationMap } from "../../models/dataSets/DataSetType";
import { useProjectContext } from "../../contexts/ProjectContext";
import { dataSetPaths } from "../../PathConstants";
import { ITableDeleteHelper, Table } from "../table/Table";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { EntityIcon } from "../icons/EntityIcon";
import { useDefaultTableCreateHelper } from "../../hooks/table/DefaultTableCreateHelper";
import { CreateDataSetForm } from "./DataSetForm";
import { useCloneDataSetModal } from "../../hooks/dataSet/CloneDataSetModalHook";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { DataSetStatus, DataSetStatusTranslationMap } from "../../models/dataSets/DataSetStatus";
import { DefaultTableFilterWithOptions } from "../table/DefaultTableFilterWithOptions";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";
import { ITableContextModel } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { LoadingModal } from "../modals/LoadingModal";
import { useNavigation } from "../../hooks/NavigationHook";
import { useDefaultExportHelper } from "../../hooks/table/DefaultTableExportHelper";
import { DatasetExportView } from "../export/DatasetExportView";
import { IDataSetExportFormFields } from "../../models/dataSets/IDataSetExportFormFields";
import { useDataSetApi } from "../../hooks/dataSet/DataSetApiHook";
import { TableViewHeader } from "../views/TableViewHeader";
import { Button } from "../buttons/Button";
import { TableDependencyDeleteDialog } from "../dialogs/TableDependencyDeleteDialog";
import { EntityType } from "../../models/EntityType";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

import "./DataSetView.scss";

const testSelectorDataSetMapping: Record<string, string> = {
    "System.Form": "form",
    "System.Xml": "xml",
    "System.Json": "json",
};

const testSelectorDataSetStatusMapping: Record<string, string> = {
    "System.Public": "public",
    "System.Private": "private",
};

/**
 * represents the filter object used by the dataset view table
 */
export interface IDataSetFilter {
    filterText: string;
    typeFilter: DataSetType[];
    statusFilter: DataSetStatus[];
}

/**
 * the default state of the dataset view table filter
 */
const defaultFilterValue: IDataSetFilter = {
    filterText: "",
    typeFilter: [],
    statusFilter: [],
};

const filterOptionsType = Object.keys(DataSetTypeTranslationMap).map((key) => ({
    filterValue: key as DataSetType,
    labelKey: DataSetTypeTranslationMap[key as DataSetType],
    testSelectorItemName: testSelectorDataSetMapping[key as DataSetType],
}));

const filterOptionsStatus = Object.keys(DataSetStatusTranslationMap).map((key) => ({
    filterValue: key as DataSetStatus,
    labelKey: DataSetStatusTranslationMap[key as DataSetStatus],
    testSelectorItemName: testSelectorDataSetStatusMapping[key as DataSetStatus],
}));

/**
 * The data set view component.
 */
export const DataSetView: React.FC = (): JSX.Element => {
    const { navigate } = useNavigation();
    const { name: projectName, projectId, organizationName } = useProjectContext();
    const [showExportLoadingModal, setShowExportLoadingModal] = useState(false);
    const { editCloneDataSet } = useCloneDataSetModal();
    const { create, removeRecords, exportDataSets, getRecords, getMultipleDependencies } = useDataSetApi(projectId);

    const { t } = useTranslation();
    const getRecordsInternal = useCallback(
        (filterValue?: IDataSetFilter, sortFields?: ISortField[], offset?: number) =>
            getRecords(filterValue?.filterText, filterValue?.typeFilter, filterValue?.statusFilter, sortFields, offset),
        [getRecords],
    );

    const onCreated = useCallback(
        ({ name, dataSetId }: IDataSetModel): void => {
            NotificationService.addSuccessNotification({
                messageKey: "DataSetView.CreationSuccess",
                messageKeyParams: { name },
            });
            navigate(dataSetPaths.link.edit(organizationName, projectName, dataSetId));
        },
        [navigate, projectName, organizationName],
    );
    const { setSelector } = useTestSelector();

    const nameContent = useCallback(
        ({ name, type, dataSetId }: IDataSetModel) => (
            <div className="dataset-container">
                <EntityIcon type={type} />
                <Link
                    className="text-truncate"
                    to={dataSetPaths.link.edit(organizationName, projectName, dataSetId)}
                    {...setSelector("cell-link")}
                >
                    {name}
                </Link>
            </div>
        ),
        [projectName, organizationName, setSelector],
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            fieldName: "name",
            displayName: "Common.Name",
            sortField: {
                name: "Name",
                order: SortOrder.Asc,
            },
            content: nameContent,
            shouldTruncateText: true,
            testSelectorColumnName: "name",
        },
        {
            type: "Text",
            fieldName: "description",
            displayName: "Common.Description",
            sortField: {
                name: "Description",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "description",
        },
        {
            type: "Text",
            displayName: "Common.Type",
            className: "type",
            fieldName: "type",
            content: ({ type }: IDataSetModel) => t(DataSetTypeTranslationMap[type]),
            sortField: {
                name: "Type",
                order: SortOrder.Asc,
            },
            testSelectorColumnName: "type",
        },
    ];

    const actionProvider = useCallback(
        (
            item: IDataSetModel,
            context: ITableContextModel<IDataSetModel, ITableRowItem<IDataSetModel>, IDataSetFilter>,
        ): ITableRowActionProps[] => {
            const dataSetWritePermission = formatPermissionKey(PermissionKeys.dataSet.write, item.dataSetId, projectId);
            const dataSetDeletePermission = formatPermissionKey(
                PermissionKeys.dataSet.delete,
                item.dataSetId,
                projectId,
            );

            return [
                {
                    iconClassName: "fal fa-eye",
                    label: "Common.View",
                    tag: Link,
                    testSelectorValue: "editItem",
                    to: dataSetPaths.link.edit(organizationName, projectName, item.dataSetId),
                    permissions: [dataSetWritePermission],
                    transform: (actionProps, { isAllowed }) => {
                        const hasWritePermissionProps = {
                            label: "Common.Edit",
                            iconClassName: "fal fa-pencil",
                        };

                        if (isAllowed(dataSetWritePermission)) {
                            return {
                                ...actionProps,
                                ...hasWritePermissionProps,
                            };
                        }

                        return actionProps;
                    },
                },
                {
                    iconClassName: "fal fa-light fa-clone",
                    label: "Common.Clone",
                    testSelectorValue: "cloneItem",
                    onClick: () => editCloneDataSet(item),
                    permissions: [dataSetWritePermission],
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.dataSetId]),
                    permissions: [dataSetDeletePermission],
                    separated: true,
                    style: { color: colorStyles.red },
                },
            ];
        },
        [projectId, organizationName, projectName, editCloneDataSet],
    );

    const dataSetsWriteKey = usePermissionKey({ permission: PermissionKeys.dataSet.write, projectId });
    const dataSetsDeleteKey = usePermissionKey({ permission: PermissionKeys.dataSet.delete, projectId });
    const projectExportKey = usePermissionKey({ permission: PermissionKeys.project.export, projectId });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [dataSetsWriteKey, dataSetsDeleteKey, projectExportKey] }),
        [dataSetsDeleteKey, dataSetsWriteKey, projectExportKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: CreateDataSetForm,
        onCreated,
        onCreate: create,
        formProps: {},
        initialEntity: {
            name: "",
            description: "",
            type: "System.Form",
            projectId,
        },
        modalProps: {
            className: "create-data-set-modal",
            titleKey: "CreateDataSetForm.Title",
            titleIconClassName: "fas fa-database text-blue",
            unsavedWarningBody: "DataSet.UnsavedWarningBody",
            size: "md",
            expandable: true,
        },
    });

    const exportDataSetsInternal = useCallback(
        async (ids: string[], packageName: string, includeSamples: boolean) => {
            setShowExportLoadingModal(true);

            try {
                await exportDataSets({
                    ids,
                    includeSamples,
                    packageName,
                });

                NotificationService.addSuccessNotification({
                    messageKey: "DataSet.Export.Success",
                });
            } catch (error) {
                defaultRequestErrorHandler(error);
            } finally {
                setShowExportLoadingModal(false);
            }
        },
        [exportDataSets],
    );

    const defaultExportHelper = useDefaultExportHelper({
        formComponent: DatasetExportView,
        onExport: (entityFields: IDataSetExportFormFields) => {
            const datasetsIds = entityFields.items.map((i) => i.dataSetId);
            setShowExportLoadingModal(true);
            exportDataSetsInternal(datasetsIds, entityFields.packageName, entityFields.includeSamples);
        },
        formProps: {},
        modalProps: {
            className: "",
            titleKey: "DataSet.Export.Title",
            unsavedWarningBody: "DataSet.Export.UnsavedWarningBody",
            size: "lg",
            expandable: true,
            createLabel: "Common.Export",
        },
        initialEntity: {
            includeSamples: true,
        },
        generateInitialPackageName: () => `Data_sets_package_${moment().unix()}`,
    });

    const createHelper = useMemo(() => {
        if (!isAllowed(dataSetsWriteKey)) {
            return;
        }

        return defaultCreateHelper;
    }, [dataSetsWriteKey, defaultCreateHelper, isAllowed]);

    const deleteHelper: ITableDeleteHelper<IDataSetModel> | undefined = useMemo(() => {
        if (!isAllowed(dataSetsDeleteKey)) {
            return;
        }

        return {
            confirmationTitleMessageKey: "CommonDataSet.DeleteTitle",
            confirmationBodyMessageKey: "CommonDataSet.DeleteBody",
            deleteRecords: removeRecords,
            notificationMessageKey: "DataSetsView",
            customDeleteComponent: TableDependencyDeleteDialog,
            getDependencies: getMultipleDependencies,
            entityType: "DataSet" as EntityType,
        };
    }, [dataSetsDeleteKey, removeRecords, getMultipleDependencies, isAllowed]);

    const exportAction = useCallback(
        (tableContext: ITableContextModel<IDataSetModel, ITableRowItem<IDataSetModel>, IDataSetFilter>) => {
            if (!isAllowed(projectExportKey)) {
                return;
            }

            const selectedItems = tableContext.tableState.items
                .filter((i) => tableContext.tableState.selectedItemIds.indexOf(i.item.dataSetId) !== -1)
                .map((ir) => ir.item);
            return (
                <Button
                    className="export-button"
                    icon="fa-solid fa-file-export"
                    color="outline-secondary"
                    hidden={tableContext.tableState.selectedItemIds.length < 1}
                    {...setSelector("exportButton")}
                    onClick={() => defaultExportHelper(selectedItems)}
                    ariaLabel={t("Common.Export")}
                >
                    {t("Common.Export")}
                </Button>
            );
        },
        [defaultExportHelper, isAllowed, projectExportKey, setSelector, t],
    );

    const noResultsCTAProps = useMemo(() => {
        if (!isAllowed(dataSetsWriteKey)) {
            return;
        }

        return {
            title: "Route.DataSets",
            description: "EntityDescription.DataSets",
            createTitle: "Common.Create",
            logo: {
                imgSrc: DataSetIcon,
                imgAlt: "",
            },
        };
    }, [dataSetsWriteKey, isAllowed]);

    const filterComponent = useCallback(
        (filterValue: IDataSetFilter, onChange: (value: IDataSetFilter) => void) => (
            <DefaultTableFilterWithOptions
                text={filterValue.filterText}
                onChange={({ filters, filterText }) => {
                    onChange({
                        typeFilter: filters[0] as DataSetType[],
                        statusFilter: filters[1] as DataSetStatus[],
                        filterText,
                    });
                }}
                filterComponents={[
                    {
                        defaultCheckboxLabel: "Common.Type",
                        filtersOptions: filterOptionsType,
                        testSelectorCheckboxFilterValue: "type",
                        activeFilter: filterValue.typeFilter,
                    },
                    {
                        defaultCheckboxLabel: "Common.Status",
                        filtersOptions: filterOptionsStatus,
                        testSelectorCheckboxFilterValue: "status",
                        activeFilter: filterValue.statusFilter,
                    },
                ]}
                filterPlaceHolder="DataSetsView.Filter.PlaceHolder"
            />
        ),
        [],
    );

    return (
        <>
            <TableViewHeader titleKey="Route.DataSets" />
            <LoadingModal show={showExportLoadingModal} labelKey="Export.Loading" />
            <Table
                className="dataset-table"
                actionProvider={actionProvider}
                columnDefs={columnDefs}
                getRecords={getRecordsInternal}
                keyExtractor={(item) => item.dataSetId}
                nameExtractor={(item) => item.name}
                loadingMessageKey="DataSet.Loading"
                createHelper={createHelper}
                deleteHelper={deleteHelper}
                filterHelper={{
                    filter: filterComponent,
                    defaultFilterValue: defaultFilterValue,
                    isActive: (value) =>
                        !!value.filterText || value.typeFilter?.length + value.statusFilter?.length > 0,
                }}
                selectable={isAllowed(dataSetsDeleteKey) || isAllowed(projectExportKey)}
                tableId={`${projectId}-datasets`}
                noResultsCTAProps={noResultsCTAProps}
                extraActions={[{ showWithSelectedItem: true, button: exportAction, id: "exportAction" }]}
            />
        </>
    );
};
