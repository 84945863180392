import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PasswordFormValidator } from "../../../formValidators/PasswordFormValidator";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { IPasswordModel, IUserPasswordSettingsModel } from "../../../models/account/IPasswordModel";
import { NotificationService } from "../../../services/NotificationService";
import { LoadingScreen } from "../../loading/LoadingScreen";
import { EditAccountEntityWrapper } from "../EditAccountEntityWrapper";
import { PasswordForm } from "./PasswordForm";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";

const defaultPasswordModel: IPasswordModel = {
    userName: "",
    newPassword: "",
    confirmedPassword: "",
    currentPassword: "",
};

export const EditPasswordView: React.FC = () => {
    const { t } = useTranslation();
    const [passwordSettings, setPasswordSettings] = useState<IUserPasswordSettingsModel>();
    const { addPassword, changePassword, getPasswordSettings } = useAccountApi();

    useEffect(() => {
        (async () => {
            try {
                const settings = await getPasswordSettings();
                setPasswordSettings(settings);
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        })();
    }, [getPasswordSettings]);

    const passwordFormValidatorProvider = useCallback(
        () => new PasswordFormValidator(passwordSettings),
        [passwordSettings],
    );

    const onUpdatePassword = useCallback(
        async (entity: IPasswordModel): Promise<IPasswordModel> => {
            try {
                if (passwordSettings?.userHasPassword) {
                    await changePassword({
                        oldPassword: entity.currentPassword!,
                        newPassword: entity.newPassword,
                    });
                } else {
                    await addPassword(entity.newPassword);
                }

                NotificationService.addSuccessNotification({
                    messageKey: "Password.PasswordChange.Success",
                });
                return defaultPasswordModel;
            } catch (error) {
                defaultRequestErrorHandler(error);
                return {} as IPasswordModel;
            }
        },
        [addPassword, changePassword, passwordSettings?.userHasPassword],
    );

    const memoizedPasswordModel = useMemo<IPasswordModel>(() => {
        return { ...defaultPasswordModel, userName: passwordSettings?.userName ?? "" };
    }, [passwordSettings?.userName]);

    if (!passwordSettings) {
        return <LoadingScreen>{t("Common.Loading")}</LoadingScreen>;
    }

    return (
        <EditAccountEntityWrapper
            title={t("Password.Title")}
            formValidatorProvider={passwordFormValidatorProvider}
            loadedEntity={memoizedPasswordModel}
            update={onUpdatePassword}
            promptDialogMessageKey="Password.UnsavedWarningBody"
        >
            <PasswordForm hasPassword={passwordSettings?.userHasPassword} />
        </EditAccountEntityWrapper>
    );
};
