import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import colorStyles from "../../../assets/scss/custom/_colorExports.module.scss";
import { LabelCircle } from "../../labelCircle/LabelCircle";
import { Table, TableCreateHelper } from "../../table/Table";
import { ContentManagerFileShareTableId } from "../../../models/contentManager/constant";
import { EditItemShareBehavior } from "./EditItemShareBehavior";
import { IEntityTableColumnDef } from "../../../models/IEntityTableColumnDef";
import { ISortField } from "../../../models/ISortField";
import { SortOrder } from "../../../models/SortOrder";
import { PermissionKeys } from "../../../PermissionKeyConstants";
import { ITableContextModel } from "../../table/TableContext";
import { ITableRowItem } from "../../table/TableRow";
import { ITableRowActionProps } from "../../tableRowActions/TableRowAction";
import { usePermissionCheck } from "../../../hooks/permission/PermissionCheckHook";
import { usePermissionKey } from "../../../hooks/permission/PermissionKeyHook";
import { useProjectContext } from "../../../contexts/ProjectContext";
import {
    useContentFileShareApi,
    ShareStatus,
    ShareTableFilter,
} from "../../../hooks/contentManager/contentFileShare/ContentFileShareApiHook";
import { useContentFileShare } from "../../../hooks/contentManager/contentFileShare/ContentFileShareHook";
import {
    ContentFileShareBehaviors,
    IContentFileShareModel,
    ShareLink,
} from "../../../models/contentManager/ContentManagerApiModels";
import { DefaultTableFilterWithOptions } from "../../table/DefaultTableFilterWithOptions";
import { IFilterOptionsComponents } from "../../../models/IFilterOptions";
import { useTableRefreshContext } from "../../../contexts/TableRefreshContext";
import { Button } from "../../buttons/Button";
import { useUserContext } from "../../../contexts/UserContext";

import "./EditFileShareTableView.scss";

interface IEditFileShareTableView {
    fileId: string;
}

const getFilterComponents = (filterValue: ShareTableFilter) =>
    [
        {
            defaultCheckboxLabel: "Common.Status",
            filtersOptions: [
                {
                    filterValue: true,
                    labelKey: "Common.Key.Active",
                    labelColor: "green",
                    testSelectorItemName: "active",
                },
                {
                    filterValue: false,
                    labelKey: "Common.Key.Expired",
                    labelColor: "red",
                    testSelectorItemName: "expired",
                },
            ],
            testSelectorCheckboxFilterValue: "status",
            activeFilter: filterValue.status,
        },
        {
            defaultCheckboxLabel: "Share.Behaviors",
            filtersOptions: [
                {
                    filterValue: ContentFileShareBehaviors.View,
                    labelKey: "Share.ViewInBrowser",
                    testSelectorItemName: "view",
                },
                {
                    filterValue: ContentFileShareBehaviors.Download,
                    labelKey: "Common.Download",
                    testSelectorItemName: "download",
                },
            ],
            testSelectorCheckboxFilterValue: "behaviors",
            activeFilter: filterValue.behaviors,
        },
    ] as IFilterOptionsComponents<ShareStatus | ContentFileShareBehaviors>[];

const defaultFilterValue: ShareTableFilter = {
    filterText: "",
    status: [],
    behaviors: [],
};

const keyExtractor = (item: IContentFileShareModel) => item.contentFileShareId;

export const EditFileShareTableView = ({ fileId }: IEditFileShareTableView) => {
    const { t } = useTranslation();
    const { projectId } = useProjectContext();
    const { refreshTable } = useTableRefreshContext();
    const { locale } = useUserContext();
    const { getRecords: getShareRecords, removeRecords: removeShareRecords } = useContentFileShareApi({ projectId });
    const contentShareKey = usePermissionKey({
        permission: PermissionKeys.contentManager.share,
        projectId,
        objectId: fileId,
    });
    const permissionsObject = useMemo(() => ({ permissionKeys: [contentShareKey] }), [contentShareKey]);
    const { isAllowed } = usePermissionCheck(permissionsObject);
    const { createContentFileShare, updateContentFileShare } = useContentFileShare();

    const getRecords = useCallback(
        (filterValue?: ShareTableFilter, sortFields?: ISortField[], offset?: number) =>
            getShareRecords(fileId, filterValue, sortFields, offset),
        [fileId, getShareRecords],
    );

    const deleteRecords = useCallback(
        (ids: string[]) => {
            return removeShareRecords(fileId, ids);
        },
        [fileId, removeShareRecords],
    );

    const behaviorContent = (expired: boolean, shareLinks: ShareLink[]) => {
        const orderedBehaviors = [...shareLinks].sort((a: ShareLink, b: ShareLink) => b.behavior - a.behavior);
        return (
            <div className="share-behavior">
                {orderedBehaviors.map((urlConfig) => (
                    <EditItemShareBehavior isExpired={expired} urlConfig={urlConfig} testSelectorValue={`behavior-${urlConfig.behavior}`} key={urlConfig.url} />
                ))}
            </div>
        );
    };

    const statusContent = (expired: boolean) => {
        return (
            <div className="share-status">
                <LabelCircle color={expired ? "red" : "green"} />
                {t(expired ? "Common.Key.Expired" : "Common.Key.Active")}
            </div>
        );
    };

    const expirationDateContent = (expirationDate: string | null) => {
        if (!expirationDate) {
            return <span>{t("Common.Never")}</span>;
        }

        const date = moment.parseZone(expirationDate);
        if (locale) {
            date.locale(locale);
        }

        return date.format("L [(UTC]Z[)]");
    };

    const shareKeyContent = (share: IContentFileShareModel) => (
        <Button
            className="share-key-column"
            onClick={() => updateContentFileShare(share, refreshShareTable)}
            ariaLabel="Share.Key"
            color="ghost"
            disabled={share.expired}
        >
            {share.key}
        </Button>
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            fieldName: "key",
            displayName: "Share.Key",
            shouldTruncateText: true,
            testSelectorColumnName: "key",
            content: (share: IContentFileShareModel) => shareKeyContent(share),
        },
        {
            type: "JSX",
            content: ({ expired, shareLinks }: IContentFileShareModel) => behaviorContent(expired, shareLinks),
            fieldName: "behaviors",
            displayName: "Share.Behaviors",
            shouldTruncateText: true,
            testSelectorColumnName: "behaviors",
            className: "behaviors",
        },
        {
            type: "JSX",
            content: ({ expired }: IContentFileShareModel) => statusContent(expired),
            fieldName: "status",
            displayName: "Common.Status",
            sortField: {
                name: "Expired",
                order: SortOrder.Asc,
            },
            shouldTruncateText: true,
            testSelectorColumnName: "status",
            className: "status",
        },
        {
            type: "DateTime",
            fieldName: "createdOn",
            displayName: "Common.CreatedOn",
            sortField: {
                name: "CreatedOn",
                order: SortOrder.Asc,
            },
            testSelectorColumnName: "createdOn",
            className: "createdOn",
        },
        {
            type: "Text",
            content: ({ createdBy }: IContentFileShareModel) => createdBy.displayName,
            fieldName: "createdBy",
            displayName: "Common.CreatedBy",
            testSelectorColumnName: "createdBy",
            className: "createdBy",
        },
        {
            type: "JSX",
            content: ({ expirationDate }: IContentFileShareModel) => expirationDateContent(expirationDate),
            fieldName: "expirationDate",
            displayName: "Common.ExpiresOn",
            sortField: {
                name: "ExpirationDate",
                order: SortOrder.Asc,
            },
            testSelectorColumnName: "expirationDate",
            className: "expirationDate",
        },
    ];

    const refreshShareTable = useCallback(() => {
        refreshTable(ContentManagerFileShareTableId);
    }, [refreshTable]);

    const createContentFileShareModal: TableCreateHelper = {
        mode: "modal",
        onCreate: (onEntityCreated: (entity: any) => void) => createContentFileShare(fileId, onEntityCreated),
    };

    const actionProvider = useCallback(
        (
            item: IContentFileShareModel,
            context: ITableContextModel<
                IContentFileShareModel,
                ITableRowItem<IContentFileShareModel>,
                ShareTableFilter
            >,
        ): ITableRowActionProps[] => {
            return [
                {
                    iconClassName: "fal fa-pencil",
                    label: "Common.Edit",
                    testSelectorValue: "editItem",
                    onClick: () => updateContentFileShare(item, refreshShareTable),
                    disabled: item.expired,
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.contentFileShareId]),
                    separated: true,
                    style: { color: colorStyles.red },
                },
            ];
        },
        [refreshShareTable, updateContentFileShare],
    );

    const noResultsCTAProps = useMemo(() => {
        if (!isAllowed(contentShareKey)) {
            return;
        }

        return {
            title: "Common.Shares",
            description: "EntityDescription.Shares",
            createTitle: "Common.Create",
        };
    }, [contentShareKey, isAllowed]);

    const filterComponent = useCallback(
        (filterValue: ShareTableFilter, onChange: (value: ShareTableFilter) => void) => (
            <DefaultTableFilterWithOptions
                text={filterValue.filterText}
                onChange={({ filters, filterText }) => {
                    onChange({
                        status: filters[0] as ShareStatus[],
                        behaviors: filters[1] as ContentFileShareBehaviors[],
                        filterText,
                    });
                }}
                filterComponents={getFilterComponents(filterValue)}
                filterPlaceHolder="Share.Filter.PlaceHolder"
            />
        ),
        [],
    );

    return (
        <Table
            tableId={ContentManagerFileShareTableId}
            className="content-manager-file-share-table"
            columnDefs={columnDefs}
            actionProvider={actionProvider}
            getRecords={getRecords}
            selectable={isAllowed(contentShareKey)}
            keyExtractor={keyExtractor}
            loadingMessageKey="Share.Loading"
            createHelper={isAllowed(contentShareKey) ? createContentFileShareModal : undefined}
            filterHelper={{
                filter: filterComponent,
                defaultFilterValue,
                isActive: (value) => !!value.filterText || value.behaviors?.length + value.status?.length > 0,
            }}
            defaultSortField={{
                name: "CreatedOn",
                order: SortOrder.Desc,
            }}
            deleteHelper={{
                confirmationTitleMessageKey: "Share.DeleteTitle",
                confirmationBodyMessageKey: "Share.DeleteBody",
                confirmationBodyWarningMessageKey: "Share.DeleteWarning",
                deleteRecords,
                notificationMessageKey: "Share",
            }}
            noResultsCTAProps={noResultsCTAProps}
        />
    );
};
