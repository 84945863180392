import React, { useMemo } from "react";
import { ITableRowItem } from "../table/TableRow";
import { useTableContext } from "../table/TableContext";
import { IDataSetFilter } from "../dataSets/DataSetView";
import { EntityType } from "../../models/EntityType";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { DependencyDeleteDialogBody } from "./DependencyDeleteDialogBody";

import "./DependencyDeleteDialogBody.scss";

interface IDependencyDeleteDialogBodyProps {
    dependencies: MultipleDependenciesResult;
}

interface NameTypeModel {
    name: string;
    type: EntityType;
}

export const TableDependencyDeleteDialogBody: React.FC<IDependencyDeleteDialogBodyProps> = <
    TEntity extends NameTypeModel,
>({
    dependencies,
}: IDependencyDeleteDialogBodyProps) => {
    const context = useTableContext<TEntity, ITableRowItem<TEntity>, IDataSetFilter>();

    const selectedItems = useMemo(() => {
        if (context) {
            return context.tableState.items.filter((item) =>
                context.tableState.itemIdsToDelete.includes(context.keyExtractor(item.item)),
            );
        }
    }, [context]);

    const accordionIds = context && selectedItems ? selectedItems.map((item) => context.keyExtractor(item.item)) : [];

    const items = selectedItems?.map((item) => item.item);

    const entityType = context?.deleteHelper?.entityType ?? "";

    const keyExtractor = context?.keyExtractor;

    return (
        <DependencyDeleteDialogBody
            dependencies={dependencies}
            entityType={entityType}
            accordionIds={accordionIds}
            items={items}
            keyExtractor={keyExtractor}
        />
    );
};
