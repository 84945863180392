import { IValidationResult } from "../CreateModalEntityHook";
import { IDataSetExportFormFields } from "../../models/dataSets/IDataSetExportFormFields";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { useEffect, useMemo, useState } from "react";
import { DownloadPackageNameValidator } from "../../formValidators/DownloadPackageNameValidator";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";

export const useDataSetExportValidation = ({
    packageName,
}: IDataSetExportFormFields): IValidationResult<IDataSetExportFormFields, never> => {
    const { errors, setError } = useEntityErrors<IDataSetExportFormFields>();
    const formValidator = useMemo(() => new DownloadPackageNameValidator(), []);
    const [valid, setValid] = useState(false);

    useEffect(() => {
        formValidator.setInitialValue({ name: packageName });
    }, [formValidator, packageName]);

    useEffect(() => {
        formValidator
            .validateName(packageName)
            .then(() => setError("packageName", ""))
            .catch((err: Error) => setError("packageName", extractErrorMessageOrEmptyString(err)));
    }, [formValidator, packageName, setError]);

    useEffect(() => {
        void formValidator.isValid({ name: packageName }).then((value) => setValid(value));
    }, [formValidator, packageName]);

    return {
        valid,
        errors,
        validationStatuses: {},
        setError,
    };
};
