import React, { useMemo } from "react";
import { HeaderNavBarContext } from "../contexts/HeaderNavBarContext";
import { buildSearchGlobalProperties } from "../helpers/SearchHelper";
import { SidebarProvider } from "../components/sidebar/SidebarProvider";
import { OrganizationLayout } from "../components/organizations/OrganizationLayout";
import { useSearchApi } from "../hooks/search/SearchApiHook";

export const DefaultLayout: React.FC = () => {
    const { searchBy } = useSearchApi();

    const memoizedSearch = useMemo(
        () => ({
            search: { ...buildSearchGlobalProperties(), onSearch: searchBy },
        }),
        [searchBy],
    );

    return (
        <HeaderNavBarContext.Provider value={memoizedSearch}>
            <SidebarProvider>
                <OrganizationLayout />
            </SidebarProvider>
        </HeaderNavBarContext.Provider>
    );
};
