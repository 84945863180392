import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectComponentOptions } from "../components/selects/SelectComponent";

export const useTranslateSelectComponentOptions = <T>(
    selectComponentOptions: SelectComponentOptions<T>[],
): SelectComponentOptions<T>[] => {
    const {
        t,
    } = useTranslation();
    const translatedSelectComponentOptions: SelectComponentOptions<T>[] = useMemo(
        () =>
            selectComponentOptions.map(({ label, ...sco }) => ({
                ...sco,
                label: t(label),
            })),
        [selectComponentOptions, t],
    );

    return translatedSelectComponentOptions;
};
