import React from "react";
import { FormGroup, Input } from "reactstrap";
import { EntityForm } from "../forms/EntityForm";
import { IDataSetExportFormFields } from "../../models/dataSets/IDataSetExportFormFields";
import { IDataSetModel } from "../../models/dataSets/IDataSetModel";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ReadOnlyTable } from "../table/ReadOnlyTable";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useDataSetExportValidation } from "../../hooks/dataSet/DataSetExportValidation";
import { useTranslation } from "react-i18next";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { DataSetTypeTranslationMap } from "../../models/dataSets/DataSetType";

import "./DatasetExportView.scss";

interface IExportDataSetFormProps {
    initialValue: IDataSetExportFormFields;
    onFormChange: (formData: IEntityFormData<IDataSetExportFormFields>) => void;
}

/**
 * The export view component.
 */
export const DatasetExportView: React.FC<IExportDataSetFormProps> = ({
    initialValue,
    onFormChange,
}: IExportDataSetFormProps): JSX.Element => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const { data, onChange, errors } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: useDataSetExportValidation,
        isDirty: () => true,
    });

    const exportItem = (item: IDataSetModel) => (
        <div className="dataset-item-container">
            <i className="far fa-database" />
            <span>{item.name}</span>
            <span className="dataset-item-type">{t(DataSetTypeTranslationMap[item.type])}</span>
        </div>
    );

    const columnDefs: IEntityTableColumnDef[] = [
        {
            type: "JSX",
            fieldName: "dataset",
            displayName: "Common.DataSets",
            className: "dataset",
            shouldTruncateText: true,
            testSelectorColumnName: "dataset",
            content: (item: IDataSetModel) => exportItem(item),
        },
    ];

    return (
        <EntityForm className="dataset-export-view-form">
            <div className="export-modal-subheader">{t("Common.Export.Description")}</div>
            <ReadOnlyTable
                className="dataset-export-view-table"
                columnDefs={columnDefs}
                content={data.items}
                borderless
            />
            <FormGroup className="include-samples">
                <Input
                    id="includes-samples-checkbox"
                    type="checkbox"
                    checked={data.includeSamples}
                    {...setSelector("includeDatasetSamplesCheckbox")}
                    onChange={() => onChange("includeSamples", !data.includeSamples)}
                />
                <LocalizedLabel check for="includes-samples-checkbox" text="DataSet.Export.IncludeDataSetSamples" />
            </FormGroup>
            <FormGroup className="export-file-name">
                <LocalizedLabel required for="export-file-name" text="DataSet.Export.ExportPackageName" />
                <ValidatedInput
                    name="export-file-name"
                    id="export-file-name"
                    value={data.packageName}
                    type="text"
                    {...setSelector("exportPackageNameTextbox")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("packageName", e.target.value)}
                    error={errors.packageName}
                />
            </FormGroup>
        </EntityForm>
    );
};
