import { useCallback, useState } from "react";
import { ISearchResult } from "../../models/search/ISearchResult";

/**
 * The search service hook props interface.
 * T represents the actual type returned by the search service.
 */
interface SearchServiceProps<T extends ISearchResult> {
    onSearch: (searchQuery: string) => Promise<T[]>;
    transform: (element: T[]) => JSX.Element[];
}

/**
 * The search service hook results interface.
 */
interface SearchServiceReturn {
    clearResults: () => void;
    search: (searchQuery: string) => Promise<void>;
    searchResults: JSX.Element[];
}

/**
 * The search service hook.
 */
export const useSearchService = <T extends ISearchResult>({
    onSearch,
    transform,
}: SearchServiceProps<T>): SearchServiceReturn => {
    const [searchResults, setSearchResults] = useState<JSX.Element[]>([]);

    const search = useCallback(
        async (searchQuery: string) => {
            const result = await onSearch(searchQuery);
            setSearchResults(transform(result));
        },
        [transform, onSearch],
    );

    const clearResults = useCallback(() => {
        setSearchResults([]);
    }, []);

    return {
        clearResults,
        search,
        searchResults,
    };
};
