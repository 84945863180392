import { useEffect } from "react";
import { ICreateEntityResult } from "../models/entity/form/ICreateEntityResult";
import { IEntityFormData } from "../models/entity/form/IEntityFormData";
import { ValidationStatus } from "../models/entity/validation/ValidationStatus";
import { useEntityData } from "./entity/EntityDataHook";
import { EntityError, SetError } from "./entity/EntityErrorsHook";
import { useValidationStatus } from "./validation/ValidationStatusHook";

interface ICreateModalEntityResult<TEntity, TValidations extends string> extends ICreateEntityResult<TEntity> {
    validationStatuses: Record<TValidations, ValidationStatus>;
}

/**
 * Represents what a validation hook should return in order to be used by IUseCreateModalEntityProps
 */
export interface IValidationResult<TEntity extends Record<string, any>, TValidations extends string> {
    valid: boolean;
    errors: EntityError<TEntity>;
    validationStatuses: Record<TValidations, ValidationStatus>;
    setError: SetError<EntityError<TEntity>>;
}

export interface IUseCreateModalEntityProps<TEntity extends Record<string, any>, TValidations extends string> {
    initialData: TEntity;
    onFormChange: (formData: IEntityFormData<TEntity>) => void;
    isDirty?: (data: TEntity) => boolean;
    useValidation: (data: TEntity) => IValidationResult<TEntity, TValidations>;
}

/**
 * A generic hook that can be used by for creating entities with a simple interface
 */
export const useCreateModalEntity = <TEntity extends Record<string, any>, TValidations extends string>({
    initialData,
    onFormChange,
    isDirty,
    useValidation,
}: IUseCreateModalEntityProps<TEntity, TValidations>): ICreateModalEntityResult<TEntity, TValidations> => {
    const { data, onChange } = useEntityData<TEntity>(() => initialData);

    const isDirtyGeneric = () => {
        const properties = Object.keys(initialData) as (keyof TEntity)[];

        for (const p of properties) {
            if (data[p] !== initialData[p]) {
                return true;
            }
        }

        return false;
    };

    const dirty = isDirty ? isDirty(data) : isDirtyGeneric();

    const { errors, validationStatuses, valid, setError } = useValidation(data);

    const validationStatus = useValidationStatus({ validationStatuses, valid });

    useEffect(() => {
        onFormChange({
            data,
            dirty,
            validationStatus,
        });
    }, [data, dirty, validationStatus, onFormChange]);

    return {
        data,
        errors,
        onChange,
        setError,
        validationStatuses,
    };
};
