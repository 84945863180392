import { useEffect, useMemo, useState } from "react";
import { IServiceHookFormFields } from "../../models/serviceHooks/IServiceHookFormFields";
import { NameValidator } from "../../formValidators/NameValidator";
import { useEntityNameValidation } from "../entity/validation/EntityNameValidationHook";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { ServiceHookFormValidator } from "../../formValidators/ServiceHookFormValidator";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";
import { useServiceHookApi } from "./ServiceHookApiHook";

interface ServiceHookValidationProps {
    fields: IServiceHookFormFields;
    projectId: string;
}

export const useServiceHookValidation = ({ fields, projectId }: ServiceHookValidationProps) => {
    const { nameIsUnique } = useServiceHookApi(projectId);

    const [defaultValues] = useState<IServiceHookFormFields>(fields);
    const { errors, setError } = useEntityErrors<IServiceHookFormFields>();
    const [valid, setValid] = useState(false);
    const [configTouched, setConfigTouched] = useState(false);

    const nameValidator = useMemo(() => {
        return new NameValidator({
            nameExistErrorKey: "ServiceHookValidator.NameExistsForProject",
            nameIsUnique,
        });
    }, [nameIsUnique]);

    const formValidator = useMemo(() => {
        return new ServiceHookFormValidator();
    }, []);

    const { nameStatus, error: nameError } = useEntityNameValidation({
        defaultName: defaultValues.name,
        name: fields.name,
        nameValidator,
    });

    useEffect(() => {
        setError("name", nameError);
    }, [nameError, setError]);

    useEffect(() => {
        !configTouched && setConfigTouched(fields.config !== defaultValues.config);
    }, [configTouched, defaultValues.config, fields.config]);

    useEffect(() => {
        formValidator
            .validateEventType(fields.eventType)
            .then(() => setError("eventType", ""))
            .catch((err: Error) => setError("eventType", extractErrorMessageOrEmptyString(err)));
    }, [fields.config, fields.eventType, formValidator, setError]);

    useEffect(() => {
        if (configTouched) {
            formValidator
                .validateConfig(fields.config)
                .then(() => setError("config", ""))
                .catch((err: Error) => setError("config", extractErrorMessageOrEmptyString(err)));
        }
    }, [configTouched, fields.config, formValidator, setError]);

    useEffect(() => {
        void Promise.all([
            nameValidator.isValid({ name: fields.name }),
            formValidator.isValid({ config: fields.config, eventType: fields.eventType }),
        ]).then((v) => {
            setValid(v.every((val) => val));
        });
    }, [fields.config, fields.eventType, fields.name, formValidator, nameValidator]);

    return {
        valid,
        errors,
        validationStatuses: { name: nameStatus },
        setError,
    };
};
