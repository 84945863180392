import React from "react";
import { LoadingAnimation } from "./LoadingAnimation";

/**
 * The loading screen component.
 */
export const LoadingScreen: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <div className="centered-content loading-screen">
            <LoadingAnimation size={48} />
            {children}
        </div>
    );
};
