import React, { useCallback, useMemo } from "react";
import { AboutInformations } from "../../components/navbars/AboutInformations";
import { useDialogContext } from "../../contexts/DialogContext";

export const useAboutDialog = () => {
    const { show } = useDialogContext();

    const content = useMemo(() => <AboutInformations />, []);

    const showAboutDialog = useCallback(() => {
        show({
            hideCancel: true,
            onProceed: () => {
                return Promise.resolve();
            },
            type: "Info",
            titleKey: "About.Title",
            proceedLabelKey: "Common.Close",
            content,
        });
    }, [content, show]);

    return {
        showAboutDialog,
    };
};
