import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

import "./Filter.scss";

/**
 * The filter input props interface.
 */
export interface IFilterInputProps {
    placeHolderName: string;
    throttle: number;
    onValueChange: (value: string) => void;
    value?: string;
    innerRef?: React.RefObject<HTMLInputElement>;
    onBlur?: () => void;
}

/**
 * The filter input functional component.
 */
export const FilterInput: React.FunctionComponent<IFilterInputProps> = ({
    value,
    throttle,
    onValueChange,
    placeHolderName,
    innerRef,
    onBlur,
}: IFilterInputProps): JSX.Element => {
    const [internalValue, setInternalValue] = useState(value || "");
    const canTriggerOnValueChange = useRef(false);
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    useEffect(() => {
        if (!canTriggerOnValueChange.current) {
            return;
        }

        const currentTimeout = setTimeout(() => {
            onValueChange(internalValue);
        }, throttle);

        return () => clearTimeout(currentTimeout);
    }, [internalValue, throttle, onValueChange]);

    useEffect(() => {
        canTriggerOnValueChange.current = false;
        setInternalValue(value || "");
    }, [value]);

    const valueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        canTriggerOnValueChange.current = true;
        setInternalValue(event.target.value);
    };

    return (
        <>
            <Input
                id="filter-input"
                autoFocus={true}
                placeholder={t(placeHolderName)}
                type="text"
                value={internalValue}
                {...setSelector("filterTextbox")}
                onChange={valueChange}
                innerRef={innerRef}
                onBlur={() => onBlur && onBlur()}
            />
        </>
    );
};
