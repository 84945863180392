import React from "react";

/**
 * The dropdown divider component.
 */
export const DropdownDivider: React.FunctionComponent = () => {
    return (
        <div className="divider">
            <div className="line"></div>
        </div>
    );
};
