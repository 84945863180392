import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IEntityDropdownMenuItem } from "../../components/dropdowns/EntityDropdownMenu";
import { EntityWithName, useEditEntityContext } from "../../contexts/EditEntityContext";
import { FormValidator } from "../../formValidators/FormValidator";
import { useDefaultDropdownMenuItems } from "./DefaultDropdownMenuItems";

/**
 * The clonable entity drop down menu item props interface.
 */
interface IClonableDropdownMenuItemsProps<T extends EntityWithName> {
    onCloneClick: (entity: T) => void;
    canDelete: boolean;
    canEdit: boolean;
}

/**
 * The clonable entity dropdown menu items.
 */
export const useClonableDropdownMenuItems = <T extends EntityWithName>({
    onCloneClick,
    canDelete,
    canEdit,
}: IClonableDropdownMenuItemsProps<T>): IEntityDropdownMenuItem[] => {
    const { t } = useTranslation();
    const editEntityContext = useEditEntityContext<T, FormValidator<unknown>>();

    const [refresh, ...rest] = useDefaultDropdownMenuItems(canDelete);

    const items = useMemo(() => {
        if (canEdit) {
            return [
                refresh,
                {
                    className: "fal fa-clone",
                    text: t("Common.Clone"),
                    disabled: editEntityContext.dirtyProps.isDirty(),
                    testSelectorValue: "cloneItem",
                    onClick: () => onCloneClick(editEntityContext.entityProps.entity),
                },
                ...rest,
            ];
        }

        return [refresh, ...rest];
    }, [canEdit, editEntityContext.dirtyProps, editEntityContext.entityProps.entity, onCloneClick, refresh, rest, t]);

    return items;
};
