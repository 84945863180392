const hashValues = [
    "#users",
    "#permissions",
    "#overview",
    "#serviceHooks",
    "#design",
    "#dependencies",
    "#schema",
    "#samples",
    "#history",
    "#settings",
    "#executions",
    "#projects",
    "#versions",
    "#languages",
    "#members",
    "#shares",
    "#license",
] as const;

export type TranslatableHashType = (typeof hashValues)[number];

export enum HashTranslationMap {
    "#users" = "Common.Users",
    "#permissions" = "Common.Permissions",
    "#overview" = "Common.Overview",
    "#serviceHooks" = "ServiceHook.Title",
    "#design" = "Common.Design",
    "#dependencies" = "Common.Dependencies",
    "#schema" = "Common.Schema",
    "#samples" = "Common.Samples",
    "#history" = "Common.History",
    "#settings" = "Common.Settings",
    "#executions" = "ServiceHookExecutions.Title",
    "#projects" = "Common.Projects",
    "#versions" = "Common.Versions",
    "#languages" = "Common.Language_plural",
    "#members" = "Common.Members",
    "#shares" = "Common.Shares",
    "#license" = "Common.License",
}

export const isTranslatableHash = (hash: string): hash is TranslatableHashType =>
    hashValues.includes(hash as TranslatableHashType);
