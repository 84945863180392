import { useRef, useState, useCallback } from "react";

interface IUseTableDropResult {
    canDropOnMain: boolean;
    updateDropableStatus: (canDrop: boolean, id: string) => void;
}

export const useTableDrop = (): IUseTableDropResult => {
    const rowDropableStatus = useRef<Record<string, boolean>>({});
    const [canDropOnMain, setCanDropOnMain] = useState(true);

    const updateDropableStatus = useCallback((canDrop: boolean, id: string) => {
        rowDropableStatus.current = { ...rowDropableStatus.current, [id]: canDrop };
        setCanDropOnMain(!Object.values(rowDropableStatus.current).some((rds) => rds));
    }, []);

    return {
        canDropOnMain,
        updateDropableStatus,
    };
};
