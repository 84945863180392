import React, { useCallback, useState } from "react";
import { Accordion } from "reactstrap";
import { useTranslation } from "react-i18next";
import { DependencyModalItemDisplay } from "./DependencyModalItemDisplay";
import { EntityType } from "../../models/EntityType";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";

import "./DependencyDeleteDialogBody.scss";

interface IDependencyDeleteDialogBodyProps<T> {
    dependencies: MultipleDependenciesResult;
    entityType: EntityType | "";
    items?: T[];
    accordionIds: string[];
    keyExtractor?: (item: T) => string;
    id?: string;
}

interface NameTypeModel {
    name: string;
    type: EntityType;
}

export const DependencyDeleteDialogBody = <TEntity extends NameTypeModel>({
    dependencies,
    entityType,
    items,
    accordionIds,
    keyExtractor,
    id: entityId,
}: IDependencyDeleteDialogBodyProps<TEntity>) => {
    const { t } = useTranslation();

    const [openAccordions, setOpenAccordions] = useState(accordionIds);

    const toggleAccordionOpen = useCallback((accordionId: string) => {
        setOpenAccordions((prev) => {
            if (prev.includes(accordionId)) {
                return prev.filter((accordion) => accordion !== accordionId);
            } else {
                return [...prev, accordionId];
            }
        });
    }, []);

    return (
        <div className="dependency-collapse">
            <div className="dependency-collapse__title">{t(`${entityType}.Selected`)}</div>
            <div className="dependency-collapse__display">
                <Accordion open={openAccordions} toggle={toggleAccordionOpen} flush>
                    {items?.map((item) => {
                        const id = entityId ?? keyExtractor!(item);
                        return (
                            <DependencyModalItemDisplay
                                entityName={item.name}
                                key={id}
                                id={id}
                                type={item.type || entityType}
                                dependencies={dependencies[id] || []}
                            />
                        );
                    })}
                </Accordion>
            </div>
        </div>
    );
};
