import authService from "../components/api-authorization/AuthorizeService";
import { HttpParams } from "../models/HttpParams";
import { IProblemDetails } from "../models/IProblemDetails";
import { ISortField } from "../models/ISortField";
import { SortOrder } from "../models/SortOrder";

interface QueryParams {
    filters?: Record<string, string>;
    sortFields?: ISortField[];
    offset?: number;
    take?: number;
    mode?: string;
}

const noContentStatusCode = 204;

const createDefaultHeaders = async (params: HttpParams<unknown>, headers: Headers): Promise<Headers> => {
    if (params.authenticated || params.authenticated === undefined) {
        const token = await authService.getAccessToken();

        const authHeaderValues: Record<string, string> = !token ? {} : { Authorization: `Bearer ${token}` };
        params.additionalHeaderValues = params.additionalHeaderValues
            ? { ...authHeaderValues, ...params.additionalHeaderValues }
            : authHeaderValues;
    }

    if (params.additionalHeaderValues) {
        Object.entries(params.additionalHeaderValues).forEach(([key, value]) => {
            headers.append(key, value);
        });
    }

    return headers;
};

const createDefaultSortFields = (sortFields: ISortField[], defaultField?: string): ISortField[] => {
    const defaultFirstField = defaultField ?? "Name";
    if (!sortFields.some((field) => field.name === defaultFirstField)) {
        sortFields.push({ name: defaultFirstField, order: SortOrder.Asc });
    }

    return sortFields;
};

export const normalizeUrl = (url: string, queryParams?: {}): string => {
    const urlBuilder = new URL(url.startsWith(window.location.origin) ? url : window.location.origin + url);
    const urlSearchParams = new URLSearchParams(queryParams);
    urlSearchParams.forEach((v, k) => urlBuilder.searchParams.append(k, v));

    return urlBuilder.toString();
};

export const createHeaders = async (params: HttpParams<unknown>): Promise<Headers> => {
    const headers = new Headers({
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json; charset=utf-8",
    });

    return createDefaultHeaders(params, headers);
};

export const createUploadHeaders = async (params: HttpParams<unknown>): Promise<Headers> => {
    const headers = new Headers({
        Accept: "application/json; charset=utf-8",
    });

    return createDefaultHeaders(params, headers);
};

export const handleResponse = async (responsePromise: Promise<Response>): Promise<void> => {
    const response = await responsePromise;
    if (response.ok) {
        return;
    }

    throw await handleHttpError(response);
};

export const handleResponseBodyAsText = async (responsePromise: Promise<Response>): Promise<string> => {
    const response = await responsePromise;
    if (response.ok) {
        return await response.text();
    }

    throw await handleHttpError(response);
};

export const handleResponseBodyAsJson = async <TReturn>(responsePromise: Promise<Response>): Promise<TReturn> => {
    const response = await responsePromise;
    if (response.ok) {
        return (response.status !== noContentStatusCode ? await response.json() : {}) as TReturn;
    }

    throw await handleHttpError(response);
};

export const handleResponseBodyAsBlob = async (responsePromise: Promise<Response>): Promise<Blob> => {
    const response = await responsePromise;
    if (response.ok) {
        return await response.blob();
    }

    throw await handleHttpError(response);
};

export const handleFileHttpResponseBody = async (
    responsePromise: Promise<Response>,
    fileName?: string,
): Promise<void> => {
    const response = await responsePromise;
    const contentDispositionHeader = response.headers.get("Content-Disposition");
    if (response.ok && contentDispositionHeader) {
        fileName = fileName ?? contentDispositionHeader.split("filename=")[1].split(";")[0];
        const file = await response.blob();
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        link.click();
        link.parentNode!.removeChild(link);
        return;
    }

    throw await handleHttpError(response);
};

export const normalizeQueryParams = (
    { filters, offset, sortFields, take, mode }: QueryParams,
    useDefaultSort: boolean = true,
    defaultField?: string,
): Record<string, string> => {
    const paramsBuilder: Record<string, string> = {};

    if (filters) {
        let index = 0;
        Object.entries(filters).forEach(([key, value]) => {
            if (value.trim() !== "") {
                paramsBuilder[`filterFields[${index}].name`] = key;
                paramsBuilder[`filterFields[${index}].value`] = value;
                index++;
            }
        });
    }

    paramsBuilder["offset"] = `${offset ?? ""}`;

    if (sortFields) {
        let normalizedSortFields = sortFields;

        if (useDefaultSort) {
            normalizedSortFields = createDefaultSortFields(normalizedSortFields, defaultField);
        }

        normalizedSortFields.forEach(({ name, order }, index) => {
            paramsBuilder[`sortFields[${index}].name`] = `${name}`;
            paramsBuilder[`sortFields[${index}].order`] = `${order}`;
        });
    }

    if (take) {
        paramsBuilder["take"] = `${take}`;
    }

    if (mode) {
        paramsBuilder["mode"] = mode;
    }

    return paramsBuilder;
};

const handleHttpError = async (response: Response): Promise<Error> => {
    if (response.status === 504) {
        throw new Error("Common.Error.Timeout", { cause: response.status });
    }

    const responseText = await response.text();
    let errorMessage: string;

    const contentType = response.headers?.get("content-type");
    if (response.status === 500 || (contentType && contentType.indexOf("application/problem+json") !== -1)) {
        try {
            const detailsError = JSON.parse(responseText) as IProblemDetails;
            errorMessage = detailsError.detail ?? responseText;
        } catch {
            errorMessage = responseText;
        }
    } else {
        errorMessage = responseText;
    }

    throw new Error(errorMessage, { cause: response.status });
};
