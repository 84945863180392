import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import QRCode from "qrcode";
import { Button } from "../../buttons/Button";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { NotificationService } from "../../../services/NotificationService";
import { twoFactorAuthenticationPaths } from "../../../PathConstants";
import { useNavigation } from "../../../hooks/NavigationHook";
import { LoadingScreen } from "../../loading/LoadingScreen";
import { RecoveryCodes } from "./RecoveryCodes";
import { ValidatedInput } from "../../forms/inputs/ValidatedInput";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";

import "./Configure2FA.scss";

/**
 * The Configure TwoFA state.
 */
interface ConfigureTwoFAState {
    verificationKey: string;
    verificationKeyError: string;
    uniqueKey: string;
    url: string;
    recoveryCodes: string[];
}

/**
 * The Configure 2FA Component.
 */
export const Configure2FA: React.FC = () => {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { getSharedKeyAndCodeURI, verify2FA } = useAccountApi();
    const [configureTwoFAState, setConfigureTwoFAState] = useState<ConfigureTwoFAState>({
        recoveryCodes: [],
        uniqueKey: "",
        url: "",
        verificationKey: "",
        verificationKeyError: "",
    });

    const { recoveryCodes, uniqueKey, url, verificationKey, verificationKeyError } = configureTwoFAState;

    const onVerify = useCallback(async () => {
        try {
            const result = await verify2FA(verificationKey);
            NotificationService.addSuccessNotification({
                messageKey: "2FA.Enable.VerifySuccess",
            });
            if (result) {
                setConfigureTwoFAState({
                    ...configureTwoFAState,
                    recoveryCodes: result,
                });
            } else {
                navigate(twoFactorAuthenticationPaths.route.base);
            }
        } catch (error) {
            setConfigureTwoFAState({
                ...configureTwoFAState,
                verificationKeyError: t("2FA.Enable.VerifyError"),
            });
        }
    }, [verify2FA, verificationKey, configureTwoFAState, navigate, t]);

    useEffect(() => {
        (async () => {
            try {
                const model = await getSharedKeyAndCodeURI();
                try {
                    const generatedUrl = await QRCode.toDataURL(model.codeURI);
                    setConfigureTwoFAState((oldState) => {
                        return {
                            ...oldState,
                            uniqueKey: model.sharedKey,
                            url: generatedUrl,
                        };
                    });
                } catch (error) {
                    defaultRequestErrorHandler(error);
                }
            } catch (error) {
                defaultRequestErrorHandler(error);
            }
        })();
    }, [getSharedKeyAndCodeURI]);

    if (recoveryCodes.length > 0) {
        return <RecoveryCodes recoveryCodes={recoveryCodes} />;
    }

    if (!url) {
        return <LoadingScreen />;
    }

    return (
        <div className="configure">
            <h2 className="configure-header">{t("2FA.Enable.Title")}</h2>
            <div className="configure-content">
                <span>{t("2FA.Enable.StepsTitle")}</span>
                <ol>
                    <li className="configure-steps">
                        <Trans
                            i18nKey={"2FA.Enable.Step1"}
                            components={{
                                MSAndroidUrl: <NavLink to={t("2FA.Enable.MSAndroidUrl")} target="_blank" />,
                                MSiOSUrl: <NavLink to={t("2FA.Enable.MSiOSUrl")} target="_blank" />,
                                GoogleAndroidUrl: <NavLink to={t("2FA.Enable.GoogleAndroidUrl")} target="_blank" />,
                                GoogleiOSUrl: <NavLink to={t("2FA.Enable.GoogleiOSUrl")} target="_blank" />,
                            }}
                        />
                    </li>
                    <li className="configure-steps">
                        <Trans
                            i18nKey={"2FA.Enable.Step2"}
                            components={{ UniqueKey: <span className="unique-key" /> }}
                            values={{ key: uniqueKey }}
                        />
                    </li>
                    {url && <img src={url} alt="qrcode" />}
                    <li className="configure-steps">{t("2FA.Enable.Step3")}</li>
                </ol>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onVerify();
                    }}
                >
                    <FormGroup className="verify-authenticator">
                        <LocalizedLabel for="verify-authenticator" text={t("2FA.Enable.VerificationCode")} />
                        <ValidatedInput
                            name="verify-authenticator"
                            value={verificationKey}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setConfigureTwoFAState({
                                    ...configureTwoFAState,
                                    verificationKey: e.target.value,
                                    verificationKeyError: "",
                                });
                            }}
                            error={verificationKeyError}
                        />
                        <Button color="primary" onClick={onVerify} ariaLabel={t("2FA.Enable.Verify")}>
                            {t("2FA.Enable.Verify")}
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        </div>
    );
};
