import { useCallback, useState } from "react";

export const useRefreshEditEntity = () => {
    const [listeners, setListeners] = useState<(() => void)[]>([]);

    const subscribeRefresh = useCallback((callback: () => void) => {
        setListeners((currentListeners) => [...currentListeners, callback]);
    }, []);

    const dispatchRefresh = useCallback(() => {
        listeners.forEach((cb) => cb());
    }, [listeners]);

    const unsubscribeRefresh = useCallback((callback: () => void) => {
        setListeners((currentListeners) => currentListeners.filter((l) => l !== callback));
    }, []);

    return {
        subscribeRefresh,
        dispatchRefresh,
        unsubscribeRefresh,
    };
};
