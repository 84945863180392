import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../../buttons/Button";
import { twoFactorAuthenticationPaths } from "../../../PathConstants";
import { useNavigation } from "../../../hooks/NavigationHook";
import { NotificationService } from "../../../services/NotificationService";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";

/**
 * The Reset Authenticator Component.
 */
export const ResetAuthenticator: React.FC = () => {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { resetTwoFA } = useAccountApi();

    const onReset = useCallback(async () => {
        try {
            await resetTwoFA();
            NotificationService.addSuccessNotification({
                messageKey: "2FA.ResetAuthentificator.Success",
            });
            navigate(twoFactorAuthenticationPaths.route.base);
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [resetTwoFA, navigate]);

    return (
        <>
            <section>
                <h2>{t("2FA.ResetAuthenticator.Title")}</h2>
            </section>
            <section>
                <div className="warning-message">
                    <Trans i18nKey={"2FA.ResetAuthenticator.Warning"} />
                </div>
                <Button color="danger" onClick={onReset} ariaLabel={t("2FA.ResetAuthenticator.Title")}>
                    {t("2FA.ResetAuthenticator.Title")}
                </Button>
            </section>
        </>
    );
};
