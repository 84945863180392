import React from "react";
import { useCrumbHierarchyShortener } from "../../hooks/crumbs/CrumbHierarchyShortenerHook";
import { ICrumb } from "../../models/breadcrumbs/ICrumb";

import "./CrumbHierarchyShortener.scss";

interface ICrumbHierarchyProps {
    containerWidth: number;
    crumbs: ICrumb[];
}

/**
 * The Crumb hierarchy shortener.
 */
export const CrumbHierarchyShortener: React.FunctionComponent<ICrumbHierarchyProps> = ({ containerWidth, crumbs }) => {
    const { dropdownCrumbs, startingCrumb, trailingCrumbs } = useCrumbHierarchyShortener({
        containerWidth,
        crumbs,
    });

    return (
        <div className="crumb-hierarchy">
            <span className="crumb text-truncate">{startingCrumb.name}</span>
            {dropdownCrumbs.length > 0 && <span className="crumb">...</span>}
            {trailingCrumbs.map((crumb, index) => {
                return (
                    <React.Fragment key={`${index} - ${crumb.name}`}>
                        <span className="crumb text-truncate">{crumb.name}</span>
                    </React.Fragment>
                );
            })}
        </div>
    );
};
