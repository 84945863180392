import { useCallback, useEffect, useReducer } from "react";
import { extractErrorMessageOrEmptyString } from "../helpers/ErrorHelper";

/**
 * The create entity hook result.
 */
interface ICreateEntityHookResult<TEntity> {
    creating: boolean;
    dirty: boolean;
    entity: TEntity;
    errorMessage: string;
    initialEntity: TEntity;
    valid: boolean;
    create: () => Promise<void>;
    setEntity: (entity: TEntity) => void;
    setErrorMessage: (error: string) => void;
    setValid: (valid: boolean) => void;
    setDirty: (dirty: boolean) => void;
    setInitialEntity: (initialEntity: TEntity) => void;
}

/**
 * The create entity hook Props.
 */
interface ICreateEntityHookProps<TEntity> {
    initialEntity: TEntity;
    createEntity?: (entity: TEntity) => Promise<TEntity>;
    onCreated?: (entity: TEntity) => void;
}

interface ISetInitialEntity<TEntity> {
    type: "SET_INITIAL_ENTITY";
    value: TEntity;
}

interface ISetEntity<TEntity> {
    type: "SET_ENTITY";
    value: TEntity;
}

interface ISetCreating {
    type: "SET_CREATING";
    value: boolean;
}

interface ISetValid {
    type: "SET_VALID";
    value: boolean;
}

interface ISetDirty {
    type: "SET_DIRTY";
    value: boolean;
}

interface ICreatedEntity<TEntity> {
    type: "CREATED_ENTITY";
    value: TEntity;
}

interface ISetErrorMessage {
    type: "SET_ERRORMESSAGE";
    value: string;
}

type CreateEntityReducerAction<TEntity> =
    | ISetInitialEntity<TEntity>
    | ISetEntity<TEntity>
    | ISetCreating
    | ISetValid
    | ISetDirty
    | ISetErrorMessage
    | ICreatedEntity<TEntity>;

interface ICreateEntityState<TEntity> {
    initialEntity: TEntity;
    entity: TEntity;
    creating: boolean;
    valid: boolean;
    dirty: boolean;
    errorMessage: string;
    createdEntity: TEntity | null;
}

const createEntityReducer = <TEntity>(
    state: ICreateEntityState<TEntity>,
    action: CreateEntityReducerAction<TEntity>,
): ICreateEntityState<TEntity> => {
    switch (action.type) {
        case "SET_INITIAL_ENTITY":
            return {
                ...state,
                initialEntity: action.value,
            };
        case "SET_ENTITY":
            return {
                ...state,
                entity: action.value,
            };
        case "SET_CREATING":
            return {
                ...state,
                creating: action.value,
            };
        case "SET_VALID":
            return {
                ...state,
                valid: action.value,
            };
        case "SET_DIRTY":
            return {
                ...state,
                dirty: action.value,
            };
        case "SET_ERRORMESSAGE":
            return {
                ...state,
                errorMessage: action.value,
            };
        case "CREATED_ENTITY":
            return {
                ...state,
                creating: false,
                dirty: false,
                createdEntity: action.value,
            };
    }
};

/**
 * The use create entity hook.
 */
export const useCreateEntity = <TEntity>({
    initialEntity: initialEntityProp,
    createEntity,
    onCreated,
}: ICreateEntityHookProps<TEntity>): ICreateEntityHookResult<TEntity> => {
    const [{ creating, dirty, entity, errorMessage, initialEntity, valid, createdEntity }, dispatch] = useReducer(
        (state: ICreateEntityState<TEntity>, action: CreateEntityReducerAction<TEntity>) =>
            createEntityReducer<TEntity>(state, action),
        {
            initialEntity: initialEntityProp,
            entity: initialEntityProp,
            creating: false,
            valid: false,
            dirty: false,
            errorMessage: "",
            createdEntity: null,
        },
    );

    useEffect(() => {
        if (createdEntity) {
            onCreated?.(createdEntity);
        }
    }, [createdEntity, onCreated]);

    const setEntity = useCallback((newEntity: TEntity) => {
        dispatch({ type: "SET_ENTITY", value: newEntity });
    }, []);

    const setInitialEntity = useCallback((newInitialEntity: TEntity) => {
        dispatch({ type: "SET_INITIAL_ENTITY", value: newInitialEntity });
    }, []);

    const setValid = useCallback((newValid: boolean) => {
        dispatch({ type: "SET_VALID", value: newValid });
    }, []);

    const setDirty = useCallback((newDirty: boolean) => {
        dispatch({ type: "SET_DIRTY", value: newDirty });
    }, []);

    const setErrorMessage = useCallback((newErrorMessage: string) => {
        dispatch({ type: "SET_ERRORMESSAGE", value: newErrorMessage });
    }, []);

    const create = useCallback(async () => {
        if (!createEntity) return;

        dispatch({ type: "SET_CREATING", value: true });

        try {
            const newEntity = await createEntity(entity);
            dispatch({ type: "CREATED_ENTITY", value: newEntity });
        } catch (err) {
            dispatch({ type: "SET_CREATING", value: false });
            dispatch({ type: "SET_ERRORMESSAGE", value: extractErrorMessageOrEmptyString(err) });
        }
    }, [createEntity, entity]);

    return {
        creating,
        dirty,
        entity,
        errorMessage,
        initialEntity,
        valid,
        setEntity,
        setInitialEntity,
        setValid,
        create,
        setDirty,
        setErrorMessage,
    };
};
