import React from "react";
import { buildLoadingAnimationSizeStyles } from "./LoadingAnimationSizeHelper";

import "./LoadingAnimation.scss";

/**
 * The loading animation props.
 */
interface ILoadingAnimationProps {
    size: number;
}

/**
 * The loading animation component.
 */
export const LoadingAnimation: React.FunctionComponent<ILoadingAnimationProps> = ({
    size = 48,
}: ILoadingAnimationProps): JSX.Element => {
    const styles = buildLoadingAnimationSizeStyles(size);
    return <span className="loading-spinner" style={styles}></span>;
};
