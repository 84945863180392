import { useCallback } from "react";
import { ISortField } from "../../models/ISortField";
import { IEntityResult } from "../../models/IEntityResult";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { useHttpRequest } from "../HttpRequestHook";
import { useCancellableRequest } from "../CancellableRequestHook";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { ILanguageModel } from "../../models/Languages/ILanguageModel";
import { IAvailableLanguageModel } from "../../models/Languages/IAvailableLanguageModel";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { IMultipleDependenciesRequest } from "../../models/dependencies/IMultipleDependenciesRequest";

export interface ICreateLanguagesModel {
    languageCodes: string[];
}

interface LanguageApiHookResult {
    get: (languageId: string) => Promise<ILanguageModel>;
    getRecords: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<ILanguageModel>>;
    getDependenciesRecords: (
        languageId: string,
        dependencyType: DependencyType,
        offset?: number,
        sortFields?: ISortField[],
        filterValue?: string,
    ) => Promise<IEntityResult<IDependencyModel>>;
    getMultipleDependencies: (ids: string[], dependencyType: DependencyType) => Promise<MultipleDependenciesResult>;
    remove: (languageId: string) => Promise<void>;
    removeRecords: (languageId: string[]) => Promise<IDeleteErrorResult[]>;
    create: (language: ICreateLanguagesModel) => Promise<ILanguageModel[]>;
    getAvailableLanguages: () => Promise<IAvailableLanguageModel[]>;
    update: (languageId: string, language: Partial<ILanguageModel>) => Promise<ILanguageModel>;
    setAsDefault: (language: ILanguageModel) => Promise<ILanguageModel>;
}

export const useLanguageHookApi = (projectId: string): LanguageApiHookResult => {
    const { httpGetJson, httpPost, httpDelete, httpDeleteResponseJson, httpPut } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const baseUrl = useCallback(
        (languageId?: string): string => {
            return `/_api/projects/${projectId}/languages${languageId ? "/" + languageId : ""}`;
        },
        [projectId],
    );

    const get = useCallback(
        (languageId: string) =>
            cancellableRequest<ILanguageModel>(
                {
                    url: baseUrl(languageId),
                },
                httpGetJson,
            ),
        [cancellableRequest, baseUrl, httpGetJson],
    );

    const getRecords = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Code = filterValue;
            }

            return cancellableRequest<IEntityResult<ILanguageModel>>(
                {
                    url: baseUrl(),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [cancellableRequest, baseUrl, httpGetJson],
    );

    const getDependenciesRecords = useCallback(
        (
            languageId: string,
            dependencyType: DependencyType,
            offset?: number,
            sortFields?: ISortField[],
            filterValue?: string,
        ): Promise<IEntityResult<IDependencyModel>> => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                filters.EntityName = filterValue;
                filters.EntityType = filterValue;
            }

            return cancellableRequest<IEntityResult<IDependencyModel>>(
                {
                    url: `${baseUrl(languageId)}/dependencies`,
                    queryParams: normalizeQueryParams(
                        {
                            filters,
                            sortFields,
                            offset,
                            mode: dependencyType,
                        },
                        true,
                        "EntityName",
                    ),
                },
                httpGetJson,
            );
        },
        [cancellableRequest, baseUrl, httpGetJson],
    );

    const getMultipleDependencies = useCallback(
        (ids: string[], dependencyType: DependencyType) => {
            return cancellableRequest<MultipleDependenciesResult, IMultipleDependenciesRequest>(
                { url: `${baseUrl()}/multipleDependencies`, body: { entityIds: ids, mode: dependencyType } },
                httpPost,
            );
        },
        [baseUrl, cancellableRequest, httpPost],
    );

    const update = useCallback(
        (languageId: string, language: Partial<ILanguageModel>): Promise<ILanguageModel> =>
            cancellableRequest<ILanguageModel, Partial<ILanguageModel>>(
                {
                    url: `${baseUrl(languageId)}`,
                    body: {
                        ...language,
                    },
                },
                httpPut,
            ),
        [baseUrl, cancellableRequest, httpPut],
    );

    const remove = useCallback(
        (languageId: string): Promise<void> => cancellableRequest({ url: baseUrl(languageId) }, httpDelete),
        [cancellableRequest, baseUrl, httpDelete],
    );

    const removeRecords = useCallback(
        (languageIds: string[]): Promise<IDeleteErrorResult[]> =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: baseUrl(), body: languageIds },
                httpDeleteResponseJson,
            ),
        [cancellableRequest, baseUrl, httpDeleteResponseJson],
    );

    const create = useCallback(
        (language: ICreateLanguagesModel): Promise<ILanguageModel[]> =>
            cancellableRequest<ILanguageModel[], ICreateLanguagesModel>({ url: baseUrl(), body: language }, httpPost),
        [cancellableRequest, baseUrl, httpPost],
    );

    const getAvailableLanguages = useCallback(
        () => cancellableRequest<IAvailableLanguageModel[]>({ url: `${baseUrl()}/available` }, httpGetJson),
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const setAsDefault = useCallback(
        (language: ILanguageModel) => update(language.languageId, { ...language, isDefault: true }),
        [update],
    );

    return {
        get,
        getRecords,
        getDependenciesRecords,
        getMultipleDependencies,
        remove,
        removeRecords,
        create,
        getAvailableLanguages,
        update,
        setAsDefault,
    };
};
