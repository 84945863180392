import React from "react";
import { FormGroup } from "reactstrap";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { EntityForm } from "../forms/EntityForm";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { IOrganizationFormFields } from "../../models/organization/IOrganizationFormFields";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useOrganizationValidation } from "../../hooks/organization/OrganizationValidationHook";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

interface ICreateOrganizationFormProps {
    onFormChange: (formData: IEntityFormData<IOrganizationFormFields>) => void;
}

export const CreateOrganizationForm: React.FC<ICreateOrganizationFormProps> = ({
    onFormChange,
}: ICreateOrganizationFormProps) => {
    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: {
            description: "",
            name: "",
        },
        onFormChange,
        useValidation: useOrganizationValidation,
    });

    const { setSelector } = useTestSelector();

    return (
        <EntityForm name="create-organization" className="createOrganizationForm">
            <FormGroup>
                <LocalizedLabel aria-required="true" for="create-organization-name" text="Common.Name" required />
                <ValidatedInputWithStatus
                    id="create-organization-name"
                    autoFocus={true}
                    autoComplete="off"
                    name="name"
                    type="text"
                    {...setSelector("nameTextbox")}
                    onChange={(e) => onChange("name", e.target.value)}
                    error={errors.name}
                    isBusy={validationStatuses.name === "Validating"}
                    value={data.name}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="create-organization-description" text="Common.Description" />
                <ValidatedInput
                    id="create-organization-description"
                    autoComplete="off"
                    name="description"
                    type="textarea"
                    {...setSelector("descriptionTextbox")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("description", e.target.value)}
                    error={errors.description}
                    value={data.description}
                />
            </FormGroup>
        </EntityForm>
    );
};
