import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AddMemberForm } from "./members/AddMemberForm";
import { ITableDeleteHelper, Table } from "../../table/Table";
import { useDefaultTableFilterHelper } from "../../../hooks/table/DefaultTableFilterHelper";
import { SortOrder } from "../../../models/SortOrder";
import { IUserModel } from "../../../models/user/IUserModel";
import { UserAvatar } from "../../userGroups/UserAvatar";
import { IEntityTableColumnDef } from "../../../models/IEntityTableColumnDef";
import { ISortField } from "../../../models/ISortField";
import { NotificationService } from "../../../services/NotificationService";
import { useDefaultTableCreateHelper } from "../../../hooks/table/DefaultTableCreateHelper";
import { ITableContextModel } from "../../table/TableContext";
import { ITableRowActionProps } from "../../tableRowActions/TableRowAction";
import { ITableRowItem } from "../../table/TableRow";
import { IAddMemberFields } from "../../../formValidators/AddMemberFormValidator";
import { IAddMemberResultModel } from "../../../models/permissions/IAddMemberResultModel";
import { useGroupPermission } from "../../../hooks/permission/groupPermission/GroupPermissionHook";
import { useTableRefreshContext } from "../../../contexts/TableRefreshContext";
import { paths } from "../../../PathConstants";
import { useUserContext } from "../../../contexts/UserContext";
import { useNavigation } from "../../../hooks/NavigationHook";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { IPermissionsGroupModel } from "../../../models/permissions/IPermissionsGroupModel";
import { useManageGroupMembersPermissionKey } from "../../../hooks/permission/ManageGroupMembersPermissionKeyHook";
import { PermissionKeys } from "../../../PermissionKeyConstants";
import { usePermissionCheck } from "../../../hooks/permission/PermissionCheckHook";
import { formatPermissionKey } from "../../../helpers/PermissionKeyHelper";

import "./ManageGroupMembers.scss";

interface IManageGroupMemberProps {
    projectId?: string;
    organizationId?: string;
}

const columnDefs: IEntityTableColumnDef[] = [
    {
        type: "JSX",
        content: ({ name, email, firstName, lastName, initials }: IUserModel) => (
            <div className={classNames("name")}>
                <div className="member__avatar">
                    <UserAvatar name={firstName && lastName ? name : email} parentType="list" initials={initials} />
                </div>
                <div className="member__info text-truncate">
                    <div className="member__name text-truncate">{name}</div>
                    <div className="member__email text-truncate">{email}</div>
                </div>
            </div>
        ),
        fieldName: "email",
        displayName: "Common.Name",
        shouldTruncateText: true,
        className: "name",
        sortField: {
            name: "Name",
            order: SortOrder.Asc,
        },
    },
];

const resetProjectPermissions = (projectId: string, resetPermission: (permissionKey: string) => void) => {
    const projectPermissionKeys = [
        formatPermissionKey(PermissionKeys.project.read, projectId),
        formatPermissionKey(PermissionKeys.project.write, projectId),
        formatPermissionKey(PermissionKeys.project.delete, projectId),
        formatPermissionKey(PermissionKeys.project.managePermissions, undefined, projectId),
    ];
    for (const key of projectPermissionKeys) {
        resetPermission(key);
    }
};

const resetOrganizationPermissions = (organizationId: string, resetPermission: (permissionKey: string) => void) => {
    const organizationPermissionKeys = [
        formatPermissionKey(PermissionKeys.organization.read, organizationId),
        formatPermissionKey(PermissionKeys.organization.write, organizationId),
        formatPermissionKey(PermissionKeys.organization.delete, organizationId),
    ];
    for (const key of organizationPermissionKeys) {
        resetPermission(key);
    }
};

const resetApplicationPermissions = (resetPermission: (permissionKey: string) => void) => {
    const applicationManageKey = formatPermissionKey(PermissionKeys.app.manage);

    resetPermission(applicationManageKey);
};

export const ManageGroupMembers: React.FC<IManageGroupMemberProps> = ({
    projectId,
    organizationId,
}: IManageGroupMemberProps) => {
    const {
        entityProps: {
            entity: { groupId },
        },
    } = useEditEntityContext<IPermissionsGroupModel, any>();

    const { canManage } = useManageGroupMembersPermissionKey({ projectId, organizationId });
    const filterHelper = useDefaultTableFilterHelper("GroupMembersView.FilterPlaceHolder");
    const { t } = useTranslation();
    const { addMembers, getMemberRecords, removeMembers } = useGroupPermission({
        projectId,
        organizationId: organizationId,
    });
    const { refreshTable } = useTableRefreshContext();
    const { userId: currentUserId } = useUserContext();
    const { replace } = useNavigation();
    const [refreshPermissions, setRefreshPermissions] = useState<boolean>(false);

    const onCreated = useCallback(
        ({ userIds }: IAddMemberResultModel): void => {
            NotificationService.addSuccessNotification({
                messageKey: "GroupMembersView.AddUserConfirmation",
                messageKeyParams: { users: userIds.length },
            });
            refreshTable("users");
        },
        [refreshTable],
    );

    const permissionsObject = useMemo(() => {
        if (projectId) {
            const projectReadKey = formatPermissionKey(PermissionKeys.project.read, projectId);
            return { permissionKeys: [projectReadKey] };
        }

        if (organizationId) {
            const organizationReadKey = formatPermissionKey(PermissionKeys.organization.read, organizationId);
            return { permissionKeys: [organizationReadKey] };
        }

        const applicationManageKey = formatPermissionKey(PermissionKeys.app.manage);
        return { permissionKeys: [applicationManageKey] };
    }, [projectId, organizationId]);

    const { isAllowed, isDenied, resetPermission } = usePermissionCheck(permissionsObject);
    const handleCurrentUserRemovedFromGroup = useCallback(
        (permissionKey: string) => {
            if (isDenied(permissionKey)) {
                replace(paths.home);
                return;
            }

            if (isAllowed(permissionKey) && refreshPermissions) {
                setRefreshPermissions(false);
            }
        },
        [refreshPermissions, isAllowed, isDenied, replace],
    );
    useEffect(() => {
        if (projectId) {
            const projectReadKey = formatPermissionKey(PermissionKeys.project.read, projectId);
            handleCurrentUserRemovedFromGroup(projectReadKey);
            return;
        }

        if (organizationId) {
            const organizationReadKey = formatPermissionKey(PermissionKeys.organization.read, organizationId);
            handleCurrentUserRemovedFromGroup(organizationReadKey);
            return;
        }

        const applicationManageKey = formatPermissionKey(PermissionKeys.app.manage);
        handleCurrentUserRemovedFromGroup(applicationManageKey);
    }, [projectId, organizationId, handleCurrentUserRemovedFromGroup]);

    const onDeleted = useCallback(
        (deletedUserIds?: string[]) => {
            const isCurrentUserDeleted = deletedUserIds?.some((deletedUserId) => deletedUserId === currentUserId);

            if (isCurrentUserDeleted) {
                // Reset the current user permissions for the scope.
                projectId && resetProjectPermissions(projectId, resetPermission);
                organizationId && resetOrganizationPermissions(organizationId, resetPermission);
                !projectId && !organizationId && resetApplicationPermissions(resetPermission);

                setRefreshPermissions(true);
            }
        },
        [currentUserId, projectId, resetPermission, organizationId],
    );

    const create = useCallback(
        ({ userIds }: IAddMemberFields) => {
            return addMembers(groupId, userIds);
        },
        [groupId, addMembers],
    );
    const deleteRecords = useCallback((ids: string[]) => removeMembers(groupId, ids), [groupId, removeMembers]);
    const getMembers = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number) =>
            getMemberRecords(groupId, filterValue, sortFields, offset),
        [groupId, getMemberRecords],
    );

    const actionsProviderCallback = useCallback(
        (
            item: IUserModel,
            context: ITableContextModel<IUserModel, ITableRowItem<IUserModel>, string>,
        ): ITableRowActionProps[] => [
            {
                iconClassName: "fal fa-minus",
                label: "Common.Remove",
                testSelectorValue: "removeItem",
                onClick: () => {
                    context.onSetItemsForDeletion([item.userId]);
                },
            },
        ],
        [],
    );

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: AddMemberForm,
        onCreated,
        onCreate: create,
        formProps: {
            mode: "Create",
            groupId,
            projectId,
            organizationId,
        },
        initialEntity: {
            name: "",
            userIds: [],
        },
        modalProps: {
            className: "",
            titleKey: "CommonGroup.AddMembers",
            unsavedWarningBody: "Common.UnsavedWarningBody",
            createLabel: "Common.Add",
            size: "lg",
            expandable: true,
        },
        buttonTextKey: "Common.Add",
    });

    const createHelper = useMemo(() => {
        if (!canManage) {
            return;
        }

        return defaultCreateHelper;
    }, [canManage, defaultCreateHelper]);

    const deleteHelper: ITableDeleteHelper<IUserModel> | undefined = useMemo(() => {
        if (!canManage) {
            return;
        }

        return {
            confirmationTitleMessageKey: "CommonMember.RemoveTitle",
            confirmationBodyMessageKey: "CommonMember.RemoveBody",
            deleteRecords,
            notificationMessageKey: "GroupMembersView",
            buttonTextKey: "Common.Remove",
            buttonIcon: "fas fa-minus",
            dialogIcon: "none",
            deleteLabel: t("Common.Remove"),
            onDeleted,
        };
    }, [canManage, deleteRecords, t, onDeleted]);

    const actionsProvider = useMemo(() => {
        if (!canManage) {
            return;
        }

        return actionsProviderCallback;
    }, [actionsProviderCallback, canManage]);

    const noResultCta = useMemo(() => {
        if (!canManage) {
            return;
        }

        return {
            title: "Common.Members",
            description: "CommonMember.AddDescription",
            createTitle: "CommonGroup.AddMembers",
            logo: {
                imgSrc: "",
                imgAlt: "",
            },
        };
    }, [canManage]);

    return (
        <Table
            className="group-members-table"
            columnDefs={columnDefs}
            getRecords={getMembers}
            keyExtractor={(item) => item.userId}
            nameExtractor={(item) => item.name}
            loadingMessageKey="Loading.Members"
            filterHelper={filterHelper}
            createHelper={createHelper}
            tableId="group-members"
            selectable={canManage}
            actionProvider={actionsProvider}
            deleteHelper={deleteHelper}
            noResultsCTAProps={noResultCta}
            counterPosition={createHelper ? "End" : "Start"}
        />
    );
};
