import React, { useCallback, useEffect, useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { SortOrder } from "../../models/SortOrder";
import { Table } from "../table/Table";
import { ServiceHookStateIndicator } from "./ServiceHookStateIndicator";
import { IServiceHookModel } from "../../models/serviceHooks/IServiceHooksModel";
import { ServiceHookType } from "../../models/serviceHooks/ServiceHookType";
import { ServiceHookState } from "../../models/serviceHooks/ServiceHookState";
import { FilterOption } from "../../models/IFilterOptions";
import { DefaultTableFilterWithOptions } from "../table/DefaultTableFilterWithOptions";
import { IServiceHookFilter } from "../../models/serviceHooks/IServiceHookFilter";
import { useDefaultTableCreateHelper } from "../../hooks/table/DefaultTableCreateHelper";
import { ServiceHookForm } from "./ServiceHookForm";
import { ServiceHookTypeLabel } from "./ServiceHookTypeLabel";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { NotificationService } from "../../services/NotificationService";
import { ITableContextModel } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { ITableRowActionProps } from "../tableRowActions/TableRowAction";
import { paths, serviceHooksPaths } from "../../PathConstants";
import { ServiceHookEventTypeLabel } from "./ServiceHookEventTypeLabel";
import { ServiceHookConfigDisplay } from "./ServiceHookConfigDisplay";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";
import { useDialogContext } from "../../contexts/DialogContext";
import { useNavigation } from "../../hooks/NavigationHook";
import { useServiceHookApi } from "../../hooks/serviceHook/ServiceHookApiHook";

interface ServiceHookViewProps {
    projectId: string;
    organizationName: string;
    projectName: string;
    canRead: boolean;
}

const defaultFilterValue: IServiceHookFilter = {
    filterText: "",
    filterState: [],
};

const filtersOptionsArray: FilterOption<ServiceHookState>[] = [
    {
        filterValue: ServiceHookState.Enabled,
        labelKey: "Common.Filter.Enabled",
        labelColor: "green",
        testSelectorItemName: "enabled",
    },
    {
        filterValue: ServiceHookState.Disabled,
        labelKey: "Common.Filter.Disabled",
        labelColor: "red",
        testSelectorItemName: "disabled",
    },
];

const defaultConfig = JSON.stringify({ serviceUri: "" });

export const ServiceHookView: React.FC<ServiceHookViewProps> = ({
    projectId,
    organizationName,
    projectName,
    canRead,
}) => {
    const { getRecords, create, removeRecords } = useServiceHookApi(projectId);
    const { setSelector } = useTestSelector();

    const nameContent = useCallback(
        ({ name, serviceHookId }: IServiceHookModel) => (
            <div className={classNames("name")}>
                <div>
                    <i className="far fa-webhook"></i>
                </div>
                <Link
                    className="text-truncate"
                    to={serviceHooksPaths.link.edit(organizationName, projectName, serviceHookId)}
                    { ...setSelector("cell-link") }
                >
                    {name}
                </Link>
            </div>
        ),
        [organizationName, projectName, setSelector],
    );

    const eventTypeContent = useCallback(
        ({ eventType }: IServiceHookModel) => <ServiceHookEventTypeLabel eventType={eventType} />,
        [],
    );

    const stateContent = useCallback(({ state }: IServiceHookModel) => <ServiceHookStateIndicator state={state} />, []);

    const typeContent = useCallback(({ type }: IServiceHookModel) => <ServiceHookTypeLabel type={type} />, []);

    const serviceContent = useCallback(
        ({ config, type }: IServiceHookModel) => <ServiceHookConfigDisplay config={config} type={type} />,
        [],
    );

    const columnDefs: IEntityTableColumnDef[] = useMemo(
        () => [
            {
                type: "JSX",
                content: nameContent,
                fieldName: "name",
                displayName: "Common.Name",
                shouldTruncateText: true,
                className: "name",
                sortField: {
                    name: "Name",
                    order: SortOrder.Asc,
                },
                testSelectorColumnName: "name"
            },
            {
                type: "JSX",
                displayName: "Common.EventType",
                shouldTruncateText: true,
                fieldName: "eventType",
                sortField: {
                    name: "EventType",
                    order: SortOrder.Asc,
                },
                content: eventTypeContent,
                testSelectorColumnName: "eventType"
            },
            {
                type: "JSX",
                displayName: "Common.State",
                shouldTruncateText: true,
                fieldName: "state",
                content: stateContent,
                sortField: {
                    name: "State",
                    order: SortOrder.Asc,
                },
                testSelectorColumnName: "state"
            },
            {
                type: "JSX",
                displayName: "Common.Type",
                shouldTruncateText: true,
                className: "type",
                fieldName: "type",
                content: typeContent,
                testSelectorColumnName: "type"
            },
            {
                type: "JSX",
                displayName: "Common.Service",
                shouldTruncateText: true,
                fieldName: "config",
                content: serviceContent,
                testSelectorColumnName: "service"
            },
        ],
        [eventTypeContent, nameContent, stateContent, typeContent, serviceContent],
    );

    const onCreated = useCallback(({ name }: IServiceHookModel): void => {
        NotificationService.addSuccessNotification({
            messageKey: "ServiceHookView.CreationSuccess",
            messageKeyParams: { name },
        });
    }, []);

    const defaultCreateHelper = useDefaultTableCreateHelper({
        formComponent: ServiceHookForm,
        formProps: {
            projectId,
        },
        onCreate: create,
        onCreated,
        initialEntity: {
            name: "",
            eventType: "",
            type: ServiceHookType.Webhook,
            state: ServiceHookState.Enabled,
            config: defaultConfig,
        },
        modalProps: {
            className: "create-service-hook-modal",
            titleKey: "CreateServiceHookForm.Title",
            titleIconClassName: "far fa-webhook",
            unsavedWarningBody: "ServiceHook.UnsavedWarningBody",
            size: "md",
            expandable: true,
        },
    });

    const serviceHookWriteKey = usePermissionKey({ permission: PermissionKeys.serviceHook.write, projectId });
    const serviceHookDeleteKey = usePermissionKey({ permission: PermissionKeys.serviceHook.delete, projectId });
    const serviceHookReadKey = usePermissionKey({ permission: PermissionKeys.serviceHook.read, projectId });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [serviceHookWriteKey, serviceHookDeleteKey, serviceHookReadKey] }),
        [serviceHookWriteKey, serviceHookDeleteKey, serviceHookReadKey],
    );

    const { isAllowed, isDenied } = usePermissionCheck(permissionsObject);

    const doesNotHaveServiceHookPermission = isDenied(PermissionKeys.serviceHook.read);

    const createHelper = useMemo(() => {
        if (!isAllowed(serviceHookWriteKey)) {
            return;
        }

        return defaultCreateHelper;
    }, [serviceHookWriteKey, defaultCreateHelper, isAllowed]);

    const deleteHelper = useMemo(() => {
        if (!isAllowed(serviceHookDeleteKey)) {
            return;
        }

        return {
            confirmationTitleMessageKey: "CommonServiceHook.DeleteTitle",
            confirmationBodyMessageKey: "CommonServiceHook.DeleteBody",
            deleteRecords: removeRecords,
            notificationMessageKey: "ServiceHookView",
        };
    }, [removeRecords, isAllowed, serviceHookDeleteKey]);

    const actionProvider = useCallback(
        (
            item: IServiceHookModel,
            context: ITableContextModel<IServiceHookModel, ITableRowItem<IServiceHookModel>, IServiceHookFilter>,
        ): ITableRowActionProps[] => {
            const webhookWritePermission = formatPermissionKey(
                PermissionKeys.serviceHook.write,
                item.serviceHookId,
                projectId,
            );
            const webhookDeletePermission = formatPermissionKey(
                PermissionKeys.serviceHook.delete,
                item.serviceHookId,
                projectId,
            );
            return [
                {
                    iconClassName: "fal fa-eye",
                    label: "Common.View",
                    tag: Link,
                    testSelectorValue: "editItem",
                    to: serviceHooksPaths.link.edit(organizationName, projectName, item.serviceHookId),
                    permissions: [webhookWritePermission],
                    transform: (actionProps, { isAllowed: internalIsAllowed }) => {
                        const hasWritePermissionProps = {
                            label: "Common.Edit",
                            iconClassName: "fal fa-pencil",
                        };

                        if (internalIsAllowed(webhookWritePermission)) {
                            return {
                                ...actionProps,
                                ...hasWritePermissionProps,
                            };
                        }

                        return actionProps;
                    },
                },
                {
                    iconClassName: "fal fa-trash-alt",
                    label: "Common.Delete",
                    testSelectorValue: "deleteItem",
                    onClick: () => context.onSetItemsForDeletion([item.serviceHookId]),
                    permissions: [webhookDeletePermission],
                    separated: true,
                    style: { color: colorStyles.red },
                },
            ];
        },
        [projectName, organizationName, projectId],
    );

    const { replace } = useNavigation();
    const { show: showDialog } = useDialogContext();

    useEffect(() => {
        const navigateToHome = () => {
            replace(paths.home);
        };
        if (!canRead) {
            showDialog({
                onProceed: () => Promise.resolve(navigateToHome()),
                onClose: navigateToHome,
                proceedLabelKey: "Common.OK",
                hideCancel: true,
                type: "Warning",
                titleKey: "Permissions.Insufficient",
                messageKey: "Permissions.ContactCTA",
            });
        }
    }, [doesNotHaveServiceHookPermission, showDialog, replace, canRead]);

    const noResultsCTAProps = useMemo(() => {
        if (!isAllowed(serviceHookWriteKey)) {
            return;
        }

        return {
            title: "ServiceHook.Title",
            description: "EntityDescription.ServiceHook",
            createTitle: "Common.Create",
            logo: {
                imgSrc: "",
                imgAlt: "",
            },
        };
    }, [isAllowed, serviceHookWriteKey]);

    const filter = useCallback(
        (filterValue: IServiceHookFilter, onChange: (filterValue: IServiceHookFilter) => void) => (
            <DefaultTableFilterWithOptions
                text={filterValue.filterText}
                onChange={({ filters, filterText }) => {
                    onChange({
                        filterState: filters[0],
                        filterText,
                    });
                }}
                filterPlaceHolder="ServiceHookView.FilterPlaceHolder"
                filterComponents={[
                    {
                        defaultCheckboxLabel: "Common.State",
                        filtersOptions: filtersOptionsArray,
                        testSelectorCheckboxFilterValue: "state",
                        activeFilter: filterValue.filterState,
                    },
                ]}
            />
        ),
        [],
    );

    return (
        <>
            <Table
                className="service-hook-table"
                columnDefs={columnDefs}
                getRecords={getRecords}
                keyExtractor={(item) => item.serviceHookId}
                loadingMessageKey="Loading.ServiceHook"
                tableId="serviceHooks"
                createHelper={createHelper}
                filterHelper={{
                    filter,
                    isActive: (value) => !!value.filterText || (!!value.filterState && value.filterState.length > 0),
                    defaultFilterValue: defaultFilterValue,
                }}
                deleteHelper={deleteHelper}
                selectable={!!deleteHelper}
                actionProvider={actionProvider}
                noResultsCTAProps={noResultsCTAProps}
                counterPosition={createHelper ? "End" : "Start"}
            />
        </>
    );
};
