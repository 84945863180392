import { useCallback } from "react";
import { DownloadHttpParams, HttpParams } from "../models/HttpParams";

export const useRequestWithCancelHandler = () => {
    const requestWithCancelHandler = useCallback(
        async <TReturn, TAbortResult, TParams = never>(
            params: HttpParams<TParams> | DownloadHttpParams<TParams>,
            requestService: (params: HttpParams<TParams>) => Promise<TReturn>,
            abortResult: TAbortResult,
        ) =>
            new Promise<TReturn | TAbortResult>((resolve, reject) => {
                requestService(params)
                    .then(resolve)
                    .catch((internalError) => {
                        if (internalError instanceof Error && internalError.name === "AbortError") {
                            resolve(abortResult);
                            return;
                        }
                        reject(internalError as Error);
                    });
            }),
        [],
    );

    return {
        requestWithCancelHandler,
    };
};
