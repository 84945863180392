import React from "react";
import { EntityForm } from "../forms/EntityForm";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";

interface IReadVersionCommentFormProps {
    initialValue: { comment: string };
    onFormChange: (formData: IEntityFormData<{ comment: string }>) => void;
}

export const ReadVersionCommentForm = ({ initialValue, onFormChange }: IReadVersionCommentFormProps) => {
    return (
        <EntityForm name="read-version-comment" className="version-comment-form">
            <p>{initialValue.comment}</p>
        </EntityForm>
    );
};
