import { useCallback } from "react";
import { useHttpRequest } from "../HttpRequestHook";
import { useCancellableRequest } from "../CancellableRequestHook";
import { ISearchResult } from "../../models/search/ISearchResult";

interface SearchHookReturn {
    searchBy: (queryParams: string, projectId?: string) => Promise<ISearchResult[]>;
}

const baseUrl = (projectId?: string) =>
    projectId ? `/_api/project/${projectId}/search` : "/_api/organizations/search";

export const useSearchApi = (): SearchHookReturn => {
    const { httpGetJson } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const searchBy = useCallback(
        (searchQuery: string, projectId?: string) =>
            cancellableRequest<ISearchResult[]>(
                {
                    url: baseUrl(projectId),
                    queryParams: { searchQuery },
                },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    return {
        searchBy,
    };
};
