import { useEffect, useState } from "react";
import { ICrumb } from "../../models/breadcrumbs/ICrumb";

interface CrumbHierarchyShortenerProps {
    containerWidth: number;
    crumbs: ICrumb[];
}

interface CrumbHierarchyShortenerHookResult {
    baseCrumbs: ICrumb[];
    dropdownCrumbs: ICrumb[];
    startingCrumb: ICrumb;
    trailingCrumbs: ICrumb[];
}

export const useCrumbHierarchyShortener = ({
    containerWidth,
    crumbs,
}: CrumbHierarchyShortenerProps): CrumbHierarchyShortenerHookResult => {
    const [maxItems, setMaxItems] = useState(0);

    useEffect(() => {
        if (containerWidth) {
            const maxWidth = Math.floor(containerWidth / 325);
            setMaxItems(maxWidth >= 2 ? maxWidth : 2);
        }
    }, [containerWidth]);

    const baseCrumbs = [...crumbs];
    const dropdownCrumbs = baseCrumbs.splice(1, baseCrumbs.length - maxItems);
    const [startingCrumb, ...trailingCrumbs] = baseCrumbs;

    return {
        baseCrumbs,
        dropdownCrumbs,
        startingCrumb,
        trailingCrumbs,
    };
};
