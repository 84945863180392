interface IUseDownloadFileReturn {
    triggerFileDownload: (downloadUrl: string) => void;
}

const triggerFileDownload = (downloadUrl: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = downloadUrl;
    downloadLink.target = "_blank";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export const useDownloadFile = (): IUseDownloadFileReturn => {
    return { triggerFileDownload };
};
