import React, { useEffect, useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { ILanguageModel } from "../../models/Languages/ILanguageModel";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { useTranslation } from "react-i18next";
import { FormValidator } from "../../formValidators/FormValidator";
import { UserAvatar } from "../userGroups/UserAvatar";
import { FormattedDate } from "../date/FormattedDate";

import "./LanguageOverview.scss";

export const LanguageOverview: React.FC<{}> = () => {
    const { t } = useTranslation();
    const {
        entityProps: { entity, setEntityProperties, initialEntity },
        permissionsProps: { canEdit },
    } = useEditEntityContext<ILanguageModel, FormValidator<ILanguageModel>>();

    const toggleDefault = () => {
        setEntityProperties({ isDefault: !entity.isDefault });
    };

    const [checked, setChecked] = useState<boolean>();

    // Required to force the switch component to update when the user uses the refresh option from the edit entity dropdown
    useEffect(() => {
        setChecked(initialEntity.isDefault);
    }, [initialEntity]);

    return (
        <div className="language__overview">
            <div className="language__details-container">
                <FormGroup className="language__name">
                    <LocalizedLabel for="language-name" text="Common.Name" />
                    <Input id="language-name" name="language-name" type="text" value={entity.name} disabled />
                </FormGroup>
                <FormGroup className="language__code">
                    <LocalizedLabel for="language-code" text="Common.Code" />
                    <Input id="language-code" name="language-code" type="text" value={entity.code} disabled />
                </FormGroup>
            </div>
            <FormGroup className="default-switch" switch>
                <Input
                    id="default-switch"
                    type="switch"
                    role="switch"
                    defaultChecked={checked}
                    disabled={!canEdit || initialEntity.isDefault}
                    onChange={toggleDefault}
                />
                <LocalizedLabel for="default-switch" check text={"Language.Default"} />
            </FormGroup>
            <FormGroup className="language__user-details">
                <div className="language__user-details__AddedBy">{t("Common.AddedBy")}</div>
                <div className="language__user-details__user">
                    <UserAvatar name={entity.createdBy} parentType="list" />
                    <span>{entity.createdBy}</span>
                </div>
                <div className="language__user-details__date">
                    <FormattedDate date={entity.createdDate} format="DateTime" />
                </div>
            </FormGroup>
        </div>
    );
};
