import React, { useCallback } from "react";
import { FormGroup, Input, Row } from "reactstrap";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectComponent, SelectComponentOptions } from "../selects/SelectComponent";
import { IProfileModel } from "../../models/account/IProfileModel";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { ProfileFormValidator } from "../../formValidators/ProfileFormValidator";
import { useEditAccountEntityContext } from "../../contexts/EditAccountEntityContext";
import { extractErrorMessageOrEmptyString } from "../../helpers/ErrorHelper";

/**
 * The profile form props interface.
 */
interface ProfileFormProps {
    languageOptions: SelectComponentOptions<string>[];
    timeZoneOptions: SelectComponentOptions<string>[];
}

/**
 * The profile form.
 */
export const EditProfileForm: React.FC<ProfileFormProps> = ({ languageOptions, timeZoneOptions }: ProfileFormProps) => {
    const {
        entityProps: { entity, setEntityProperties, formValidator },
        errorProps: { errors, setErrors },
    } = useEditAccountEntityContext<IProfileModel, ProfileFormValidator>();

    const onFirstNameChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            let errorMessage: string;

            try {
                const targetValue = event.target.value || null;
                setEntityProperties({ firstName: targetValue });
                await formValidator.validateFirstName(targetValue);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            setErrors({ firstName: errorMessage });
        },
        [formValidator, setEntityProperties, setErrors],
    );

    const onLastNameChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            let errorMessage: string;

            try {
                const targetValue = event.target.value || null;
                setEntityProperties({ lastName: targetValue });
                await formValidator.validateLastName(targetValue);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            setErrors({ lastName: errorMessage });
        },
        [formValidator, setEntityProperties, setErrors],
    );

    const onPhoneNumberChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            let errorMessage: string;

            try {
                const targetValue = event.target.value || null;
                setEntityProperties({ phoneNumber: targetValue });
                await formValidator.validatePhoneNumber(targetValue);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            setErrors({ phoneNumber: errorMessage });
        },
        [formValidator, setEntityProperties, setErrors],
    );

    const onLanguageChange = (language?: string) => {
        setEntityProperties({ language });
    };

    const onTimeZoneChange = (timeZone?: string) => {
        setEntityProperties({ timeZone });
    };

    return (
        <Row>
            <FormGroup>
                <LocalizedLabel for="username" text="Profile.Email" />
                <Input id="username" name="username" value={entity.userName} readOnly disabled />
            </FormGroup>
            <FormGroup className="col-md-6">
                <LocalizedLabel for="firstname" text="Profile.FirstName" />
                <ValidatedInput
                    id="firstname"
                    name="firstname"
                    value={entity.firstName}
                    error={errors.firstName}
                    onChange={onFirstNameChange}
                />
            </FormGroup>
            <FormGroup className="col-md-6">
                <LocalizedLabel for="lastname" text="Profile.LastName" />
                <ValidatedInput
                    id="lastname"
                    name="lastname"
                    value={entity.lastName}
                    error={errors.lastName}
                    onChange={onLastNameChange}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="phoneNumber" text="Profile.PhoneNumber" />
                <ValidatedInput
                    id="phoneNumber"
                    name="phoneNumber"
                    value={entity.phoneNumber}
                    error={errors.phoneNumber}
                    onChange={onPhoneNumberChange}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="select-profile-language" text="Profile.Language" />
                <SelectComponent
                    id="select-profile-language"
                    size="large"
                    value={entity.language}
                    options={languageOptions}
                    onChange={onLanguageChange}
                    searchable={true}
                    clearable={true}
                />
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="select-profile-timezone" text="Profile.TimeZone" />
                <SelectComponent
                    id="select-profile-timezone"
                    size="large"
                    value={entity.timeZone}
                    options={timeZoneOptions}
                    onChange={onTimeZoneChange}
                    searchable={true}
                    clearable={true}
                />
            </FormGroup>
        </Row>
    );
};
