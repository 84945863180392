import React from "react";
import { createRoot } from "react-dom/client";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import * as serviceWorker from "./serviceWorker";
import { AppRouter } from "./AppRouter";

import "focus-visible/dist/focus-visible";

// plugins styles downloaded
import "@fortawesome/fontawesome-pro/css/all.min.css";
import "formiojs/dist/formio.full.min.css";
import "office-ui-fabric-core/dist/css/fabric.css";

// core styles
import "./assets/scss/styles.scss";

// Add animations
import "./assets/scss/animate.css";

initializeFileTypeIcons();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<AppRouter />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
