import { useCallback, useMemo } from "react";
import { NameValidator } from "../../formValidators/NameValidator";
import { OrganizationFormValidator } from "../../formValidators/OrganizationFormValidator";
import { IOrganizationFormFields } from "../../models/organization/IOrganizationFormFields";
import { IValidationResult } from "../CreateModalEntityHook";
import { useDefaultEntityValidation } from "../validation/EntityValidationHook";
import { useOrganizationApi } from "./OrganizationApiHook";

export const useOrganizationValidation = ({
    description,
    name,
}: IOrganizationFormFields): IValidationResult<IOrganizationFormFields, "name"> => {
    const { nameIsUnique } = useOrganizationApi();
    const nameValidatorProvider = useCallback(() => {
        return new NameValidator({
            nameExistErrorKey: "OrganizationFormValidator.OrganizationExists",
            nameIsUnique: nameIsUnique,
        });
    }, [nameIsUnique]);

    const fields = useMemo(() => {
        return { name, description };
    }, [description, name]);

    const formValidationProvider = useCallback(() => new OrganizationFormValidator(), []);
    const { valid, errors, nameValidationStatus, setError } = useDefaultEntityValidation({
        fields,
        nameValidatorProvider,
        formValidationProvider,
    });

    return {
        valid,
        errors,
        validationStatuses: { name: nameValidationStatus },
        setError,
    };
};
