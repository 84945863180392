import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../buttons/Button";
import { NotificationService } from "../../../services/NotificationService";
import { RecoveryCodes } from "./RecoveryCodes";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";

/**
 * The Reset Recovery Codes Component
 */
export const ResetRecoveryCodes: React.FC = () => {
    const { t } = useTranslation();
    const { getNewRecoveryCodes } = useAccountApi();
    const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);

    const onGenerate = useCallback(async () => {
        try {
            const newCodes = await getNewRecoveryCodes();
            NotificationService.addSuccessNotification({
                messageKey: "2FA.RecoveryCodes.GenerateSuccess",
            });
            setRecoveryCodes(newCodes);
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [getNewRecoveryCodes]);

    if (recoveryCodes.length > 0) {
        return <RecoveryCodes recoveryCodes={recoveryCodes} />;
    }

    return (
        <>
            <section>
                <h2>{t("2FA.RecoveryCodes.GenerateTitle")}</h2>
            </section>
            <section>
                <Button color="primary" onClick={onGenerate} ariaLabel={t("2FA.RecoveryCodes.Generate")}>
                    {t("2FA.RecoveryCodes.Generate")}
                </Button>
            </section>
        </>
    );
};
