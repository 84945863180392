import { Context, createContext, useContext } from "react";
import { FormValidator } from "../formValidators/FormValidator";

/**
 * The edit entity context props.
 */
export interface IEditAccountEntityContextProps<TEntity, TValidator extends FormValidator<unknown>> {
    entityProps: {
        entity: TEntity;
        initialEntity: TEntity;
        setEntityProperties: (entity: Partial<TEntity>) => void;
        formValidator: TValidator;
        update: () => Promise<void>;
        canSave: boolean;
        isSaving: boolean;
    };
    errorProps: {
        errors: { [alias in keyof TEntity]: string };
        setErrors: (errors: Partial<{ [alias in keyof TEntity]: string }>) => void;
        resetErrors: () => void;
    };
    dirtyProps: {
        isDirty: (properties?: (keyof TEntity)[]) => boolean;
        promptDialogMessageKey: string;
    };
}

/**
 * The edit entity context.
 */
export const EditAccountEntityContext = createContext<any | null>(null);

/**
 * The use edit entity context for functional component.
 */
export const useEditAccountEntityContext = <
    TEntity,
    TValidator extends FormValidator<unknown>,
>(): IEditAccountEntityContextProps<TEntity, TValidator> =>
    useContext<IEditAccountEntityContextProps<TEntity, TValidator>>(
        EditAccountEntityContext as Context<IEditAccountEntityContextProps<TEntity, TValidator>>,
    );
