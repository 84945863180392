import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * The entity reload hook return interface.
 */
interface EntityReloadTabsHookReturn {
    entityReloadByLocationPath: boolean;
}

/**
 * The entity reload hook.
 */
export const useEntityReloadByLocationPath = (): EntityReloadTabsHookReturn => {
    const location = useLocation();
    const [entityReloadByLocationPath, setEntityReloadByLocationPath] = useState(false);

    const lastPathName = useRef("");

    useEffect(() => {
        setEntityReloadByLocationPath(lastPathName.current !== "" && lastPathName.current !== location.pathname);
        lastPathName.current = location.pathname;
    }, [location.pathname]);

    return {
        entityReloadByLocationPath: entityReloadByLocationPath,
    };
};
