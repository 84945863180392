import { useCallback } from "react";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import { IProjectModel } from "../../models/project/IProjectModel";
import { IProjectFormFields } from "../../models/project/IProjectFormFields";
import { IRecentProjectModel } from "../../models/project/IRecentProjectModel";
import { ISingleProjectDataModel } from "../../models/project/ISingleProjectDataModel";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { ICreateUsersInvitationModel } from "../../models/user/invitations/ICreateUsersInvitationModel";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { IUserWithGroupsAndInvitationStatusModel } from "../../models/user/IUserWithGroupsAndInvitationStatusModel";
import { IProjectModelWithOrganizationName } from "../../models/project/IProjectModelWithOrganizationName";
import { normalizeQueryParams } from "../../helpers/HttpHelper";

/**
 * The project api hook return.
 */
interface ProjectApiHookReturn {
    create: (project: IProjectFormFields) => Promise<IProjectModel>;
    remove: (projectId: string) => Promise<void>;
    removeRecords: (projectIds: string[]) => Promise<IDeleteErrorResult[]>;
    get: (projectId: string) => Promise<IProjectModel>;
    getByOrganizationName: (organizationName: string, projectName: string) => Promise<IProjectModel>;
    getRecords: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
        take?: number,
    ) => Promise<IEntityResult<IProjectModelWithOrganizationName>>;
    getCounts: () => Promise<number>;
    getUsers: (
        projectId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IUserWithGroupsAndInvitationStatusModel>>;
    getRecentProjects: (count?: number, excludedProjectId?: string) => Promise<IRecentProjectModel[]>;
    getSingleProjectData: () => Promise<ISingleProjectDataModel>;
    hasPermissions: (
        projectId: string,
        permissionChecks: { permission: string; objectId?: string }[],
    ) => Promise<{ [key: string]: boolean }>;
    inviteUsers: (createUsersInvitationModel: ICreateUsersInvitationModel, projectId: string) => Promise<number>;
    nameIsUnique: (organizationId: string, name: string, projectId?: string) => Promise<boolean>;
    removeUsers: (ids: string[], projectId: string) => Promise<IDeleteErrorResult[]>;
    update: (project: IProjectModel) => Promise<IProjectModel>;
    validateEmailsUniqueness: (emails: string[], projectId: string) => Promise<string[]>;
}

const baseUrl = (projectId?: string) => `/_api/projects${projectId ? "/" + projectId : ""}`;

export const useProjectApi = (): ProjectApiHookReturn => {
    const { httpGetJson, httpPost, httpPut, httpDelete, httpDeleteResponseJson } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const create = useCallback(
        (project: IProjectFormFields) =>
            cancellableRequest<IProjectModel, IProjectFormFields>({ url: baseUrl(), body: project }, httpPost),
        [httpPost, cancellableRequest],
    );

    const get = useCallback(
        (projectId: string) => cancellableRequest<IProjectModel>({ url: baseUrl(projectId) }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getByOrganizationName = useCallback(
        (organizationName: string, projectName: string) =>
            cancellableRequest<IProjectModel>({ url: `${baseUrl()}/${organizationName}/${projectName}` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getCounts = useCallback(
        () => cancellableRequest<number>({ url: `${baseUrl()}/count` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const getRecords = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number, take?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Description = filterValue;
            }

            return cancellableRequest<IEntityResult<IProjectModelWithOrganizationName>>(
                {
                    url: baseUrl(),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                        take,
                    }),
                },
                httpGetJson,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const getUsers = useCallback(
        (projectId: string, filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                filters.Name = filterValue;
                filters.Email = filterValue;
            }

            return cancellableRequest<IEntityResult<IUserWithGroupsAndInvitationStatusModel>>(
                {
                    url: `${baseUrl(projectId)}/users`,
                    queryParams: normalizeQueryParams(
                        {
                            filters,
                            sortFields,
                            offset,
                        },
                        false,
                    ),
                },
                httpGetJson,
            );
        },
        [httpGetJson, cancellableRequest],
    );

    const getRecentProjects = useCallback(
        (count?: number, excludedProjectId?: string) =>
            cancellableRequest<IRecentProjectModel[]>(
                {
                    url: `${baseUrl()}/recent`,
                    queryParams: {
                        count: count && count > 0 ? count.toString() : "",
                        excludedProjectId: excludedProjectId ?? "",
                    },
                },
                httpGetJson,
            ),
        [httpGetJson, cancellableRequest],
    );

    const getSingleProjectData = useCallback(
        () => cancellableRequest<ISingleProjectDataModel>({ url: `${baseUrl()}/singleprojectdata` }, httpGetJson),
        [httpGetJson, cancellableRequest],
    );

    const hasPermissions = useCallback(
        (projectId: string, permissionChecks: { permission: string; objectId?: string }[]) => {
            const url = `${baseUrl(projectId)}/users/haspermission`;
            const uniqueId = `${url}${JSON.stringify(permissionChecks)}`;
            return cancellableRequest<{ [key: string]: boolean }, { permission: string; objectId?: string }[]>(
                { url, body: permissionChecks },
                httpPost,
                uniqueId,
            );
        },
        [httpPost, cancellableRequest],
    );

    const inviteUsers = useCallback(
        (createUsersInvitationModel: ICreateUsersInvitationModel, projectId: string) =>
            cancellableRequest<number, ICreateUsersInvitationModel>(
                { url: `${baseUrl(projectId)}/users/invite`, body: createUsersInvitationModel },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    const nameIsUnique = useCallback(
        (organizationId: string, name: string, projectId?: string) => {
            const params: Record<string, string> = { organizationId, name };

            if (projectId) {
                params.projectId = projectId;
            }

            return cancellableRequest<boolean>({ url: `${baseUrl()}/nameisunique`, queryParams: params }, httpGetJson);
        },
        [httpGetJson, cancellableRequest],
    );

    const removeUsers = useCallback(
        (ids: string[], projectId: string) =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: `${baseUrl(projectId)}/users/removeUsers`, body: ids },
                httpDeleteResponseJson,
            ),
        [httpDeleteResponseJson, cancellableRequest],
    );

    const update = useCallback(
        (project: IProjectModel) =>
            cancellableRequest<IProjectModel, IProjectModel>(
                { url: `${baseUrl(project.projectId)}`, body: project },
                httpPut,
            ),
        [httpPut, cancellableRequest],
    );

    const remove = useCallback(
        (projectId: string) => cancellableRequest({ url: baseUrl(projectId) }, httpDelete),
        [httpDelete, cancellableRequest],
    );

    const removeRecords = useCallback(
        (projectIds: string[]) =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: baseUrl(), body: projectIds },
                httpDeleteResponseJson,
            ),
        [cancellableRequest, httpDeleteResponseJson],
    );

    const validateEmailsUniqueness = useCallback(
        (emails: string[], projectId: string) =>
            cancellableRequest<string[], string[]>(
                { url: `${baseUrl(projectId)}/users/emailsareunique`, body: emails },
                httpPost,
            ),
        [httpPost, cancellableRequest],
    );

    return {
        create,
        get,
        getByOrganizationName,
        getRecords,
        getCounts,
        getUsers,
        getRecentProjects,
        getSingleProjectData,
        hasPermissions,
        inviteUsers,
        nameIsUnique,
        remove,
        removeRecords,
        removeUsers,
        update,
        validateEmailsUniqueness,
    };
};
