import React, { useCallback } from "react";
import { Button, ButtonProps } from "reactstrap";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
declare module "react" {
    interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
        // extends React's HTMLAttributes
        webkitdirectory?: string;
    }
}

/**
 * The file dialog button props interface.
 */
interface IFileDialogButton extends ButtonProps {
    acceptedExtension?: string;
    acceptedFileTypes?: string[];
    testSelectorValue?: string;
    name?: string;
    multiple?: boolean;
    webkitdirectory?: string;
    onFilesLoaded: (fileList: FileList) => void;
    onInvalidFileType: () => void;
    labelKey: string;
}

/**
 * The file dialog button component
 */
export const FileDialogButton: React.FunctionComponent<IFileDialogButton> = (props: IFileDialogButton): JSX.Element => {
    const fileInput = React.useRef<HTMLInputElement>(null);
    const {
        onFilesLoaded,
        onInvalidFileType,
        acceptedExtension,
        acceptedFileTypes,
        testSelectorValue,
        name,
        multiple,
        webkitdirectory,
        onClick,
        labelKey,
        ...buttonProps
    } = props;
    const onClickHandler = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            onClick?.(e);
            fileInput.current?.click();
        },
        [onClick],
    );
    const { setSelector } = useTestSelector();

    return (
        <>
            <Button {...buttonProps} onClick={onClickHandler} />
            <LocalizedLabel for={name} hidden text={labelKey} />
            <input
                id={name}
                hidden
                type="file"
                accept={acceptedExtension}
                ref={fileInput}
                multiple={multiple}
                name={name}
                webkitdirectory={webkitdirectory}
                {...setSelector(testSelectorValue)}
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                    const { files } = target;
                    if (files && files.length > 0) {
                        if (
                            acceptedFileTypes &&
                            !Array.from(files).every((file) => acceptedFileTypes.some((mime) => file.type === mime))
                        ) {
                            onInvalidFileType();
                        } else {
                            onFilesLoaded(files);
                        }
                    }

                    target.value = "";
                }}
            />
        </>
    );
};
