import { FoldersTree, ITreeItem } from "../models/contentManager/FolderTree";
import { ISubFolderModel } from "../models/contentManager/ContentManagerApiModels";
import { recursiveTreeDelete } from "./TreeFolderHelper";

export const createNewFolders = (item: ITreeItem | null, subFolders: ISubFolderModel[]) => {
    const newFolders: FoldersTree = subFolders.map((sf) => ({
        id: sf.id,
        name: sf.name,
        parentId: item?.id ?? null,
        hasChildren: sf.hasChildren,
        areChildrenLoaded: false,
        isOpen: false,
        level: item ? item.level + 1 : 0,
        path: `${item?.path ?? ""}/${sf.name}`,
        isLoadingChildren: false,
    }));

    return newFolders;
};

export const removeFolders = (
    ids: string[],
    parentId: string | null,
    foldersTree: FoldersTree,
    updateItemUi: boolean = true,
) => {
    let folders = [...foldersTree];

    ids = ids.filter((id) => foldersTree.findIndex((f) => f.id === id) !== -1);

    ids.forEach((id) => {
        folders = recursiveTreeDelete(folders, id);
    });

    if (parentId !== null && folders.findIndex((f) => f.parentId === parentId) === -1) {
        const parentFolder = folders.find((f) => f.id === parentId)!;

        if (updateItemUi) {
            parentFolder.hasChildren = false;
            parentFolder.isOpen = false;
            parentFolder.areChildrenLoaded = false;
        }
    }

    return folders;
};
