import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { applicationPaths } from "../api-authorization/ApiAuthorizationConstants";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { accountPaths } from "../../PathConstants";
import { UserAvatar } from "../userGroups/UserAvatar";
import { useUserContext } from "../../contexts/UserContext";
import { DropdownDivider } from "../dropdowns/DropdownDivider";
import { useAboutDialog } from "../../hooks/about/AboutDialogHook";

import "./ProfileDropdownMenu.scss";

/**
 * The profile dropdown menu component.
 */
export const ProfileDropdownMenu: React.FC = () => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const { showAboutDialog } = useAboutDialog();

    const { name, initials } = useUserContext();

    return (
        <>
            <UncontrolledDropdown className="profile-dropdown-menu" nav {...setSelector("userProfileMenuToggle")}>
                <DropdownToggle
                    className="avatar avatar-sm"
                    tag="button"
                    type="button"
                    aria-label={t("Profile.ShowProfileMenu", { name })}
                >
                    <UserAvatar size={"2.5rem"} name={name} initials={initials} />
                </DropdownToggle>
                <DropdownMenu role="menu">
                    <DropdownItem
                        tag={Link}
                        to={accountPaths.route.profile}
                        {...setSelector("myProfileMenuItem")}
                        role="menuitem"
                    >
                        <i className="fal fa-user text-xt-blue" />
                        <span>{t("Profile.MyProfile")}</span>
                    </DropdownItem>
                    <DropdownItem onClick={showAboutDialog} {...setSelector("aboutItem")} role="menuitem">
                        <i className="fal fa-circle-info" />
                        <span>{t("Common.About")}</span>
                    </DropdownItem>
                    <DropdownDivider />
                    <DropdownItem
                        className="delete-item"
                        tag={Link}
                        to={{
                            pathname: applicationPaths.logOut,
                        }}
                        state={{ local: true }}
                        {...setSelector("logOutMenuItem")}
                        role="menuitem"
                    >
                        <i className="fal fa-sign-out text-xt-blue" />
                        <span>{t("Profile.LogOut")}</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
};
