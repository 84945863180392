import { useEffect } from "react";
import { useContentManagerContext } from "../../contexts/ContentManagerContext";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";

export const useContentManagerTableHandleRefresh = (tableId: string, selectedFolderId: string | null) => {
    const { onRefreshCallback } = useContentManagerContext();
    const { subscribe, unsubscribe } = useTableRefreshContext();

    useEffect(() => {
        const callback = (refreshAllSubscribed?: boolean) => {
            if (refreshAllSubscribed) {
                onRefreshCallback(selectedFolderId);
            }
        };

        subscribe(tableId, callback);
        return () => {
            unsubscribe(tableId, callback);
        };
    }, [onRefreshCallback, selectedFolderId, subscribe, unsubscribe, tableId]);
};
