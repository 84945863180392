import { useEffect } from "react";
import { hubPaths } from "../../PathConstants";
import { useHubConnection } from "./HubConnection";

/**
 * The project hub props interface.
 */
interface IProjectHubProps {
    projectCompleted: (model: { projectId: string }) => void;
}

/**
 * The project hub hook.
 */
export const useProjectHub = ({ projectCompleted }: IProjectHubProps): void => {
    const { connection } = useHubConnection({ hubUrl: hubPaths.project });

    useEffect(() => {
        if (!connection) {
            return;
        }

        connection.on("ProjectCompleted", projectCompleted);

        return () => {
            connection.off("ProjectCompleted", projectCompleted);
        };
    }, [connection, projectCompleted]);
};
