import { ComponentType, createContext, ElementType, useContext } from "react";

export interface IShowModalParams<TModalProps, TFormProps> {
    entity: any;
    onCreate?: (entityFields: any) => Promise<any>;
    onCreated: (entity: any) => void;
    modalProps: TModalProps;
    contentToDisplay: ElementType;
    formPropsToFlow: TFormProps;
    readOnly?: boolean;
    allowCreateWhenNotDirty?: boolean;
    alwaysShown?: boolean;
}

interface IModalContextProps<TModalProps, TFormProps> {
    isDisplayed: boolean;
    closeModal: () => void;
    showModal: (params: IShowModalParams<TModalProps, TFormProps>) => void;
}

export const ModalContext = createContext<IModalContextProps<ComponentType["propTypes"], ComponentType["propTypes"]>>(
    null!,
);

export const useModalContext = (): IModalContextProps<ComponentType["propTypes"], ComponentType["propTypes"]> =>
    useContext(ModalContext);
