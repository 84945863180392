import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { ProjectFormValidator } from "../../formValidators/ProjectFormValidator";
import { useTabs } from "../../hooks/TabsHook";
import { IProjectModel } from "../../models/project/IProjectModel";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { PermissionsTabs } from "../permissions/PermissionsTabs";
import { TabContent } from "../tabs/TabContent";
import { UsersView } from "../users/UsersView";
import { ProjectOverview } from "./ProjectOverview";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { ServiceHookView } from "../serviceHook/ServiceHookView";
import { useProjectContext } from "../../contexts/ProjectContext";
import { LanguageView } from "../language/LanguageView";
import { useAppConfigContext } from "../../contexts/AppConfigContext";
import { PermissionGroupPaths } from "../../PathConstants";
import {
    buildLanguages,
    buildOverview,
    buildPermissions,
    buildServiceHooks,
    buildUsers,
} from "../../helpers/EntityHelper";

interface IProjectSettingsViewTabsProps {
    displayServiceHook: boolean;
}

/**
 * The Project Settings View Tabs
 */
export const ProjectSettingsViewTabs: React.FC<IProjectSettingsViewTabsProps> = ({ displayServiceHook }) => {
    const { t } = useTranslation();
    const { projectId, organizationName, name } = useProjectContext();
    const { preview } = useAppConfigContext();
    const projectSettingsViewTabs = useMemo(() => {
        const tabs = ["users", "permissions", "overview", "serviceHooks"];
        !preview && tabs.push("languages");
        return tabs;
    }, [preview]);
    const [activeTab, setActiveTab] = useTabs<(typeof projectSettingsViewTabs)[number]>(
        projectSettingsViewTabs,
        "users",
    );

    const {
        dirtyProps: { isDirty },
        errorProps: { errors },
        permissionsProps: { canEdit },
    } = useEditEntityContext<IProjectModel, ProjectFormValidator>();

    const projectManagePermissionsKey = usePermissionKey({
        permission: PermissionKeys.project.managePermissions,
        projectId,
    });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [projectManagePermissionsKey] }),
        [projectManagePermissionsKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);

    const canManage = isAllowed(projectManagePermissionsKey);

    const tabs: INavMenuItemProps[] = [
        buildUsers(false, false, setActiveTab),
        buildLanguages(false, false, setActiveTab, preview),
        buildPermissions(false, false, setActiveTab),
        buildOverview(!!errors.description, isDirty(["description", "name"]), setActiveTab),
        buildServiceHooks(false, false, setActiveTab, !displayServiceHook),
    ];

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="permissions" className="permissions" title={t("Common.Permissions")} hasNavTabs>
                <PermissionsTabs
                    projectId={projectId}
                    groupLink={(id) => PermissionGroupPaths.link.edit.project(organizationName, name, id)}
                />
            </TabContent>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <ProjectOverview />
            </TabContent>
            <TabContent tabId="users" className="users" title={t("Common.Users")}>
                <UsersView
                    entityName={name}
                    projectId={projectId}
                    readOnly={!canEdit}
                    canDelete={canEdit && canManage}
                />
            </TabContent>
            {!preview && (
                <TabContent tabId="languages" className="languages" title={t("Common.Language_plural")}>
                    <LanguageView projectId={projectId} projectName={name} organizationName={organizationName} />
                </TabContent>
            )}
            {activeTab === "serviceHooks" && (
                <TabContent tabId="serviceHooks" className="serviceHook" title={t("ServiceHook.Title")} testSelectorValue="serviceHooksTab">
                    <ServiceHookView
                        projectId={projectId}
                        projectName={name}
                        organizationName={organizationName}
                        canRead={displayServiceHook}
                    />
                </TabContent>
            )}
        </NavMenu>
    );
};
