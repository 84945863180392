import React from "react";
import { FluentExtensionIcon } from "../icons/FluentExtensionIcon";
import { FluentFolderIcon } from "../icons/FluentFolderIcon";
import { ContentManagerEntityType } from "../../models/EntityType";

interface IContentItemIconProps {
    type: ContentManagerEntityType;
    name: string;
}

export const ContentItemIcon = ({ name, type }: IContentItemIconProps) => {
    return (
        <div className="fluent-icon-wrapper">
            {type === "Folder" ? <FluentFolderIcon /> : <FluentExtensionIcon name={name} />}
        </div>
    );
};
