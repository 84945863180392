import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigation } from "./NavigationHook";

type ITabsHookResult = readonly [string, (newTab: string) => void];

/**
 * the useTabs hook.
 * @param validTabs list of string representing that different tabs.
 * @param defaultTab the tabs that will be set as activeTab if no hash is found in the url.
 */
export const useTabs = <TabType extends string>(validTabs: readonly string[], defaultTab: TabType): ITabsHookResult => {
    const { replace } = useNavigation();
    const location = useLocation();

    const tab = location.hash?.substring(1);

    const [activeTab, setActiveTab] = useState<string>(validTabs.includes(tab) ? tab : defaultTab);

    const changeTab = useCallback(
        (newTab: string): void => {
            replace({ hash: `#${newTab}`, search: location.search });
        },
        [replace, location.search],
    );

    useEffect(() => {
        const hash = location.hash;
        if (location.hash !== "" && hash !== `#${activeTab}`) {
            const newTab = hash.substring(1);

            if (validTabs.some((d) => d === location.hash.substring(1))) {
                setActiveTab(newTab);
            }
        } else if (location.hash === "") {
            changeTab(activeTab);
        }
    }, [activeTab, location.hash, setActiveTab, validTabs, changeTab]);

    return [activeTab, changeTab] as const;
};
