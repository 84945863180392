import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { ActivityPeriod } from "./ActivityPeriod";
import { EntityType } from "../../models/EntityType";
import { ActivityModel } from "../../models/history/ActivityModel";

import "./ActivityList.scss";

interface IActivityListProps {
    entityType: EntityType | "File";
    getHistory: () => Promise<ActivityModel[]>;
}

export const ActivityList: React.FC<IActivityListProps> = ({ entityType, getHistory }) => {
    const [activities, setActivities] = useState<ActivityModel[]>([]);

    useEffect(() => {
        void getHistory().then(setActivities);
    }, [getHistory]);

    const activitiesByPeriod = useMemo(() => {
        const result = {
            thisWeek: [] as ActivityModel[],
            lastWeek: [] as ActivityModel[],
            thisMonth: [] as ActivityModel[],
            lastMonth: [] as ActivityModel[],
            older: [] as ActivityModel[],
        };

        const today = moment();
        const thisWeek = today.clone().startOf("isoWeek").subtract(1, "d");
        const lastWeek = thisWeek.clone().subtract(1, "w");
        const thisMonth = today.clone().startOf("M");
        const lastMonth = thisMonth.clone().subtract(1, "M");

        activities.forEach((a) => {
            const eventDate = moment(a.eventDate);
            if (eventDate.isSameOrAfter(thisWeek, "D")) {
                result.thisWeek.push(a);
            } else if (eventDate.isSameOrAfter(lastWeek, "D")) {
                result.lastWeek.push(a);
            } else if (eventDate.isSameOrAfter(thisMonth, "D")) {
                result.thisMonth.push(a);
            } else if (eventDate.isSameOrAfter(lastMonth, "D")) {
                result.lastMonth.push(a);
            } else {
                result.older.push(a);
            }
        });

        return result;
    }, [activities]);

    return (
        <div className="activity-list">
            <ActivityPeriod period={activitiesByPeriod.thisWeek} title="Activity.ThisWeek" entityType={entityType} />
            <ActivityPeriod period={activitiesByPeriod.lastWeek} title="Activity.LastWeek" entityType={entityType} />
            <ActivityPeriod period={activitiesByPeriod.thisMonth} title="Activity.ThisMonth" entityType={entityType} />
            <ActivityPeriod period={activitiesByPeriod.lastMonth} title="Activity.LastMonth" entityType={entityType} />
            <ActivityPeriod period={activitiesByPeriod.older} title="Activity.Older" entityType={entityType} />
        </div>
    );
};
