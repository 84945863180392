import { useEffect, useState } from "react";

export const useTabFirstActivation = ({ active }: { active: boolean }): { tabActivatedFirstTime: boolean } => {
    const [tabActivatedFirstTime, setTabActivatedFirstTime] = useState<boolean>(false);

    useEffect(() => {
        if (active && !tabActivatedFirstTime) {
            setTabActivatedFirstTime(true);
        }
    }, [active, tabActivatedFirstTime]);

    return {
        tabActivatedFirstTime
    };
};