import { useCallback } from "react";
import { ContentEntityIdTypeName, ContentEntityType } from "../../models/contentManager/ContentManagerApiModels";
import { useContentEntityApi } from "./ContentEntityApiHook";
import { useProjectContext } from "../../contexts/ProjectContext";
import { useContentFileApi } from "./ContentFileApiHook";

export const useDownloadItem = () => {
    const { projectId } = useProjectContext();
    const { download } = useContentEntityApi({
        projectId,
        checkParentExistsOnError: true,
    });
    const { getFileDownloadUrl } = useContentFileApi(projectId);

    const downloadFile = useCallback(
        async (fileId: string, translationLanguageCode?: string) => {
            const uri = await getFileDownloadUrl(fileId, true, translationLanguageCode);
            window.open(uri);
        },
        [getFileDownloadUrl],
    );

    const downloadItems = useCallback(
        async (entities: ContentEntityIdTypeName[], name: string) => {
            await download(entities, name);
        },
        [download],
    );

    const downloadFolder = useCallback(
        async (folderId: string, folderName: string) => {
            await downloadItems(
                [
                    {
                        type: ContentEntityType.Folder,
                        id: folderId,
                        name: folderName,
                    },
                ],
                folderName,
            );
        },
        [downloadItems],
    );

    return {
        downloadFolder,
        downloadFile,
        downloadItems,
    };
};
