import React from "react";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { UserGroupAvatar } from "../userGroups/UserGroupAvatar";
import { EditEntityName } from "../edit/EditEntityName";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";

interface IPermissionsGroupTitleProps {
    permissionsGroup: IPermissionsGroupModel;
}

export const PermissionsGroupTitle: React.FC<IPermissionsGroupTitleProps> = ({
    permissionsGroup,
}: IPermissionsGroupTitleProps) => {
    return (
        <SectionHeader.Group flexGrow>
            <UserGroupAvatar groupName={permissionsGroup.name} parentType="pageHeader" />
            <SectionHeader.Group column>
                <EditEntityName staticDisplay />
                <SectionHeader.SubTitle>{permissionsGroup.description}</SectionHeader.SubTitle>
            </SectionHeader.Group>
        </SectionHeader.Group>
    );
};
