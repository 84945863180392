/**
 * The supported languages.
 */
export const SupportedLanguages: { [lang: string]: string } = {
    "en": "Language.English",
    "fr": "Language.French",
    "es": "Language.Spanish",
    "de": "Language.German",
    "nl": "Language.Dutch",
    "pt": "Language.Portuguese",
    "ru": "Language.Russian",
    "it": "Language.Italian",
    "cs": "Language.Czech",
    "el": "Language.Greek",
    "sv": "Language.Swedish",
    "fi": "Language.Finnish",
    "no": "Language.Norwegian",
    "da": "Language.Danish",
};