import React from "react";
import { Trans, useTranslation } from "react-i18next";

/**
 * The Recovery Codes Component Props Interface.
 */
interface IRecoveryCodesProps {
    recoveryCodes: string[];
}

/**
 * The Recovery Codes Component.
 */
export const RecoveryCodes: React.FC<IRecoveryCodesProps> = ({ recoveryCodes }: IRecoveryCodesProps) => {
    const { t } = useTranslation();

    return (
        <>
            <section>
                <h2>{t("2FA.RecoveryCodes.Title")}</h2>
                <div className="warning-message">
                    <Trans i18nKey={"2FA.RecoveryCodes.Description"} />
                </div>
            </section>
            {recoveryCodes && (
                <section className="recovery-codes">
                    {recoveryCodes.map((recoveryCode) => {
                        return <div key={recoveryCode}>{recoveryCode}</div>;
                    })}
                </section>
            )}
        </>
    );
};
