import { FoldersTree, ITreeItem } from "../models/contentManager/FolderTree";

/**
 * Sorts 2 tree item based on their name.
 * If a subFolder is null, it means that they have been deleted, so they won't be displayed.
 * @param a The first tree item.
 * @param b The second tree item.
 * @returns -1 if a should be before b, 1 otherwise and 0 if they are equal.
 */
export const sortSubFoldersByName = (locale?: string) => (a: ITreeItem | null, b: ITreeItem | null) => {
    if (!a && b) {
        return 1;
    }

    if (!b && a) {
        return -1;
    }

    const itemAName = a!.name.toUpperCase();
    const itemBName = b!.name.toUpperCase();

    if (!locale) {
        if (itemAName < itemBName) {
            return -1;
        }

        if (itemAName > itemBName) {
            return 1;
        }

        return 0;
    }

    return new Intl.Collator(locale, { sensitivity: "accent" }).compare(itemAName, itemBName);
};

export const recursiveTreeDelete = (folderTree: FoldersTree, folderId: string) => {
    const subFoldersToDelete = folderTree.filter((f) => f.parentId === folderId).map((f) => f.id);
    let newFolderTree = folderTree.filter((f) => f.id !== folderId);

    subFoldersToDelete.forEach((subFolderId) => {
        newFolderTree = recursiveTreeDelete(newFolderTree, subFolderId);
    });

    return newFolderTree;
};

/**
 * Recursive function that opens a folder and all its parents.
 * @param folders The folders tree.
 * @param folderId The folder id to open.
 * @param state The state to set to the folder.
 * @param closeParents If true, all the parents of the folder will be closed.
 * @returns The updated folders tree.
 */
export const recursiveOpenStateFolder = (
    folders: FoldersTree,
    folderId: string | null,
    state: boolean,
    closeParents: boolean = true,
) => {
    if (!folderId) {
        return;
    }
    const folder = folders.find((f) => f.id === folderId);
    if (folder) {
        folder.isOpen = state;
        if (closeParents) {
            recursiveOpenStateFolder(folders, folder.parentId, state, closeParents);
        }
    }
};

/**
 * Update parents of newly added subfolders.
 * @param foldersTree The folders tree
 * @param newFolders the list of new folders that was added
 * @returns The updated folderTree.
 */
export const updateParents = (foldersTree: FoldersTree, newFolders: FoldersTree) => {
    const parentIds = newFolders.map((f) => f.parentId);

    parentIds.forEach((id) => {
        if (!id) {
            return;
        }
        const folderIndex = foldersTree.findIndex((f) => f.id === id);
        if (folderIndex >= 0) {
            foldersTree[folderIndex].hasChildren = true;
            foldersTree[folderIndex].areChildrenLoaded = true;
        }
    });

    return foldersTree;
};

/**
 * Sorts the folders tree.
 * @param foldersTree The unsorted folders tree.
 * @returns The sorted Folder tree.
 */
export const sortFolderTree = (foldersTree: FoldersTree, locale?: string) => {
    const sortSubFoldersByParent = (a: ITreeItem, b: ITreeItem) => {
        if (a.parentId === b.parentId) {
            return sortSubFoldersByName(locale)(a, b);
        }

        const result = checkForCommonSiblings(a, b, foldersTree);

        if (typeof result === "number") {
            return result;
        }

        const [aParent, bParent] = result!;
        return sortSubFoldersByName(locale)(aParent, bParent);
    };

    return foldersTree.sort(sortSubFoldersByParent);
};

/**
 * This function tries to find the 2 first subFolder that have the same parent
 * between 2 subFolders by recursively going up the folderTree.
 * @param a The first subFolder.
 * @param b The second subFolder.
 * @param folderTree The folderTree.
 * @returns The first 2 subFolder that have the same parent
 */
const checkForCommonSiblings = (
    a: ITreeItem,
    b: ITreeItem,
    folderTree: FoldersTree,
): [ITreeItem, ITreeItem] | number | undefined => {
    let aDepth = a.level;
    let bDepth = b.level;

    let nextAParent = getParent(a, folderTree);
    let nextBParent = getParent(b, folderTree);

    let aParent: ITreeItem = a;
    let bParent: ITreeItem = b;

    // we iterate until a and b are on the same depth
    // we also check if a or b is a parent of the other folder
    while (aDepth !== bDepth) {
        if (aDepth > bDepth) {
            if (nextAParent === b) {
                return 1;
            }

            const nextParent = getParent(nextAParent, folderTree);
            aDepth--;
            aParent = nextAParent!;
            nextAParent = nextParent;
        } else {
            if (nextBParent === a) {
                return -1;
            }

            const nextParent = getParent(nextBParent, folderTree);
            bDepth--;
            bParent = nextBParent!;
            nextBParent = nextParent!;
        }
    }

    // check if they have the same when they are at the same depth.
    if (nextAParent === nextBParent) {
        return [aParent, bParent];
    }

    let depth = aDepth;

    // We iterate until we find 2 common siblings.
    while (depth >= 0) {
        aParent = nextAParent!;
        bParent = nextBParent!;

        nextAParent = getParent(nextAParent, folderTree);
        nextBParent = getParent(nextBParent, folderTree);

        if (nextAParent?.id === nextBParent?.id) {
            return [aParent, bParent];
        }

        depth--;
    }
};
/**
 * Finds the parent subFolder of a subFolder.
 * @param folder the subFolder.
 * @param foldersTree the subFolders tree.
 * @returns The parent of the subFolder or null if the subFolder is at root level.
 */
const getParent = (folder: ITreeItem | null, foldersTree: FoldersTree): ITreeItem | null => {
    return folder === null || folder.parentId === null
        ? null
        : foldersTree[foldersTree.findIndex((f) => f.id === folder.parentId)];
};

/**
 * Determines if a folder should be displayed in the treeView by checking if one of its parent is closed.
 * @param folder the subFolder.
 * @param foldersTree the folders tree.
 * @returns true or false depending on if the subFolder should be displayed.
 */
export const shouldDisplayItem = (folder: ITreeItem, foldersTree: FoldersTree): boolean => {
    const parent = getParent(folder, foldersTree);

    if (parent === null) {
        return true;
    }

    if (!parent.isOpen) {
        return false;
    }

    return shouldDisplayItem(parent, foldersTree);
};

/*
The get selected folder from path return interface.
 */
interface IGetSelectedFolderFromPathReturn {
    selectedFolderId: string | null;
    parentFolderItem: ITreeItem | null;
    selectedFolderName: string | null;
}

/**
 * Find the selected folder id from a path. Usefull when a click is done on the breadcrumb /the left menu or back and forward buttons when item id is not available.
 * @param searchPath The path to search.
 * @param foldersTree The folders tree.
 * @returns The selected folder id or null if the path is invalid.
 */
export const getSelectedFolderFromPath = (
    searchPath: string,
    foldersTree: FoldersTree,
): IGetSelectedFolderFromPathReturn => {
    let parentFolder: ITreeItem | null = null;
    let parentFolderId: string | null = null;
    let selectedFolderId: string | null = null;
    let selectedFolderName: string | null = null;

    const paths = searchPath.split("/").filter((path) => path !== "");

    paths.forEach((pathPart, pathIndex) => {
        const folder = foldersTree.filter((f) => f.name === pathPart && f.parentId === parentFolderId);

        // The selected folder is not in the tree.
        if (!folder.length) {
            selectedFolderName = pathPart;
            return;
        }

        if (pathIndex < paths.length - 1) {
            parentFolder = folder[0];
            parentFolderId = parentFolder.id;
        } else {
            selectedFolderId = folder[0].id;
        }
    });

    return {
        parentFolderItem: parentFolder,
        selectedFolderId,
        selectedFolderName,
    };
};
