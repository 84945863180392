import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentManagerTreeMenu } from "./ContentManagerTreeMenu";
import { FoldersTree, ITreeItem } from "../../models/contentManager/FolderTree";
import { useSelectFolderModal } from "../../hooks/contentManager/SelectFolderModalHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";

import "./SelectFolderModal.scss";

interface SelectFolderModalProps {
    foldersTree: FoldersTree;
    projectId: string;
    canConfirm: (destination?: ITreeItem | null) => boolean;
    onFormChange: (formData: IEntityFormData<ITreeItem | null | undefined>) => void;
}

export const SelectFolderModal: React.FC<SelectFolderModalProps> = ({
    foldersTree,
    projectId,
    canConfirm,
    onFormChange,
}: SelectFolderModalProps) => {
    const { t } = useTranslation();
    const {
        dialogFoldersTree,
        handleTreeItem,
        selectedFolder,
        handleCollapse,
        getSubFolders,
        setItemLoadingState,
        isDisabled,
    } = useSelectFolderModal({ projectId, foldersTree, canConfirm });

    useEffect(() => {
        onFormChange({
            data: selectedFolder,
            dirty: false,
            validationStatus: isDisabled ? "Invalid" : "Valid",
        });
    }, [isDisabled, onFormChange, selectedFolder]);

    return (
        <>
            <h2 className="select-folder-modal-message">{t("ContentManager.SelectDestination")}</h2>
            <ContentManagerTreeMenu
                foldersTree={dialogFoldersTree}
                getSubFolders={getSubFolders}
                handleCollapse={handleCollapse}
                handleTreeItem={handleTreeItem}
                selectedFolderId={!selectedFolder ? selectedFolder : selectedFolder.id}
                setItemLoadingState={setItemLoadingState}
            />
        </>
    );
};
