import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UserContext, useUserContext } from "../../contexts/UserContext";
import { I18nextWithContext } from "../resources/I18nextWithContext";
import { useAccountApi } from "../../hooks/account/AccountApiHook";
import { NullableValue } from "../../models/NullableValue";
import { ITimeZoneModel } from "../../models/account/ITimeZoneModel";

import "../modals/EntityModal.scss";

/**
 * The user with context props.
 */
interface IUserWithContextProps {
    user: Omit<
        ReturnType<typeof useUserContext>,
        "name" | "refreshProfile" | "previewConsent" | "initials" | "availableTimeZones" | "userTimeZone"
    >;
    children?: React.ReactNode;
}

/**
 * The user information with context component.
 */
export const UserWithContext: React.FC<IUserWithContextProps> = (props: IUserWithContextProps) => {
    const { children, user } = props;
    const [name, setName] = useState<string>();
    const [timeZones, setTimeZones] = useState<ITimeZoneModel[]>([]);
    const [timeZone, setTimeZone] = useState<NullableValue<string>>(null);
    const [initials, setInitials] = useState<string>();
    const [previewConsentValue, setPreviewConsentValue] = useState<boolean>();

    const { getProfile, getTimeZones } = useAccountApi();

    const getProfileData = useCallback(async () => {
        const { firstName, lastName, userName, previewConsent, timeZone: profileTimeZone } = await getProfile();

        let fullName = userName;
        let newInitials = userName[0];

        if (firstName && lastName) {
            fullName = `${firstName} ${lastName}`;
            newInitials = firstName[0] + lastName[0];
        }

        setInitials(newInitials);
        setName(fullName);
        setTimeZone(profileTimeZone);
        setTimeZones(await getTimeZones());

        setPreviewConsentValue(previewConsent);
    }, [getProfile, setName, getTimeZones]);

    const userProfile = useMemo(() => {
        return {
            name,
            initials,
            refreshProfile: () => getProfileData(),
            previewConsent: previewConsentValue,
            availableTimeZones: timeZones,
            userTimeZone: timeZone,
            ...user,
        };
    }, [name, initials, previewConsentValue, user, getProfileData, timeZones, timeZone]);

    useEffect(() => {
        getProfileData();
    }, [getProfileData]);

    return (
        <UserContext.Provider value={userProfile}>
            <I18nextWithContext>{children}</I18nextWithContext>
        </UserContext.Provider>
    );
};
