import React from "react";
import { AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { EntityIcon } from "../icons/EntityIcon";
import { EntityType } from "../../models/EntityType";
import { dependencyTypeMap } from "../../helpers/DependencyMapHelper";

import "./DependencyModalItemDisplay.scss";

interface IDependencyModalItemDisplayProps {
    entityName: string;
    type: EntityType;
    dependencies: IDependencyModel[];
    id: string;
}

export const DependencyModalItemDisplay: React.FC<IDependencyModalItemDisplayProps> = ({
    entityName,
    type,
    dependencies,
    id,
}: IDependencyModalItemDisplayProps) => {
    const { t } = useTranslation();
    const hasDependencies = !!dependencies.length;

    return (
        <AccordionItem className="dependency-modal-list-wrapper">
            <AccordionHeader
                aria-expanded={true}
                targetId={id}
                className={classNames("main-entity-title", { "no-dependencies": !hasDependencies })}
            >
                <div className="dependency-list-icon">
                    <EntityIcon type={type} />
                </div>
                {entityName}
            </AccordionHeader>
            {hasDependencies && (
                <AccordionBody accordionId={id} className="dependency-accordion">
                    <div className="dependency-list">
                        <div className="dependency-list__title">{t("Common.Dependencies")}</div>
                        {dependencies.map((dep) => {
                            const depdendencyType = dependencyTypeMap[dep.entityType];
                            return (
                                <div key={dep.entityId} className="dependency-list__item">
                                    <div className="dependency-list-icon">
                                        <EntityIcon type={depdendencyType} />
                                    </div>
                                    {dep.restricted ? t("Common.RestrictedAccess") : dep.entityName}
                                </div>
                            );
                        })}
                    </div>
                </AccordionBody>
            )}
        </AccordionItem>
    );
};
