import { atomWithLocation } from "jotai-location";
import { router } from "../AppRouter";

const applyLocation = (location: LocationOption, options?: { replace?: boolean } | undefined): void => {
    let search = location.searchParams!.toString();

    if (!location.encode) {
        search = decodeURIComponent(location.searchParams!.toString());
    }

    router.navigate(
        {
            ...router.state.location,
            search,
        },
        { replace: options?.replace },
    );
};

type LocationOption = {
    pathname?: string;
    searchParams?: URLSearchParams;
    encode?: boolean;
};

export const atomWithRouter = atomWithLocation<LocationOption>({
    applyLocation,
    getLocation: () => ({
        searchParams: new URLSearchParams(router.state.location.search),
        pathname: router.state.location.pathname,
    }),
    preloaded: {
        searchParams: new URLSearchParams(),
        pathname: "",
    },
    subscribe: (callback) => router.subscribe(callback),
});
