import React, { useCallback } from "react";
import { Navigate, useParams } from "react-router-dom";
import { EditEntityWrapper } from "../../edit/EditEntityWrapper";
import { useEditContentItem } from "../../../hooks/contentManager/DetailsView/EditContentItemHook";
import { ContentFormValidator } from "../../../formValidators/ContentFormValidator";
import { ContentManagerEntityType } from "../../../models/EntityType";
import { EditFolderViewTabs } from "./EditFolderViewtabs";
import { EditFileViewTabs } from "./EditFileViewTabs";
import { LoadingScreen } from "../../loading/LoadingScreen";
import { ObjectHelper } from "../../../helpers/ObjectHelper";
import { contentManagerPaths } from "../../../PathConstants";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { ContentManagerItemDropdownMenu } from "./CustomContentManagerItemDropdownMenu";
import { SectionHeader } from "../../views/sectionHeader/SectionHeader";
import { ContentItemIcon } from "../ContentItemIcon";
import { EditItemNameInput } from "./EditItemNameInput";

import "./DetailsView.scss";

type ParamType = { itemId: string; type: ContentManagerEntityType };

export const EditItemDetailsView: React.FC = () => {
    const { itemId, type } = useParams<ParamType>() as ParamType;
    const { organizationName, name: projectName } = useProjectContext();

    const {
        canDelete,
        canEdit,
        canRead,
        load,
        remove,
        update,
        nameIsUnique,
        preLoadedItem,
        ready,
        foldersTree,
        itemParentId,
    } = useEditContentItem({
        itemId,
        itemType: type,
    });

    const formValidatorProvider = useCallback(() => new ContentFormValidator(), []);

    if (type !== "File" && type !== "Folder") {
        return <Navigate to={contentManagerPaths.link.base(organizationName, projectName)} />;
    }

    if (!ready) return <LoadingScreen />;

    return (
        <EditEntityWrapper
            className="edit-details-view"
            canDelete={canDelete}
            canEdit={canEdit}
            deleteDialogProps={{
                dialogBodyKey: "Common.Delete.DeleteMessage",
                dialogTitleKey: "Common.Delete.DeleteTitle",
                entityCount: 1,
            }}
            formValidatorProvider={formValidatorProvider}
            loadingScreenKey="Loading.ContentItem"
            nameIsUniqueProps={{
                nameExistErrorKey: `ContentFormValidator.${type}ExistsAlready`,
                nameIsUnique,
            }}
            promptDialogMessageKey="ContentManager.Details.UnsavedWarningBody"
            delete={remove}
            load={load}
            update={update}
            entityType={type}
            loadedEntity={preLoadedItem!}
            propertiesComparator={{
                description: ObjectHelper.isNullableStringEqual,
            }}
            header={
                <SectionHeader>
                    <SectionHeader.Group flexGrow>
                        <ContentItemIcon type={type} name={preLoadedItem!.name} />
                        <EditItemNameInput />
                    </SectionHeader.Group>
                    <SectionHeader.ActionGroup
                        withSaveButton={canEdit}
                        dropdownMenu={
                            <ContentManagerItemDropdownMenu
                                canRead={canRead}
                                canDelete={canDelete}
                                canEdit={canEdit}
                                foldersTree={foldersTree}
                                parentFolderId={itemParentId as string | null}
                            />
                        }
                    />
                </SectionHeader>
            }
        >
            {type === "Folder" ? <EditFolderViewTabs /> : <EditFileViewTabs />}
        </EditEntityWrapper>
    );
};
