import React, { RefObject, useCallback, useEffect, useRef } from "react";
import { Input } from "reactstrap";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { useTableContext } from "./TableContext";
import { ITableRowItem } from "./TableRow";

export const EntityTableSelectableRowHeader: React.FC = <
    TEntityModel extends Record<string, any>,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>() => {
    const {
        tableState: { selectedItemIds, items, tableId },
        selectAll,
        unselectAll,
    } = useTableContext<TEntityModel, TRowItem, TFilterValue>()!;
    const checkboxRef: RefObject<HTMLInputElement> = useRef(null);

    const onToggle = useCallback(
        (e: React.ChangeEvent) => {
            selectedItemIds.length === items.length ? unselectAll() : selectAll();
        },
        [items.length, selectAll, selectedItemIds.length, unselectAll],
    );

    useEffect(() => {
        if (checkboxRef.current) {
            if (selectedItemIds.length === 0) {
                checkboxRef.current.checked = false;
                checkboxRef.current.indeterminate = false;
            } else if (selectedItemIds.length < items.length) {
                checkboxRef.current.checked = false;
                checkboxRef.current.indeterminate = true;
            } else if (selectedItemIds.length === items.length) {
                checkboxRef.current.checked = true;
                checkboxRef.current.indeterminate = false;
            }
        }
    }, [items.length, selectedItemIds.length]);

    return (
        <>
            <LocalizedLabel for={`selectable-row-header ${tableId}`} text="Common.SelectAll" hidden />
            <Input id={`selectable-row-header ${tableId}`} type="checkbox" innerRef={checkboxRef} onChange={onToggle} />
        </>
    );
};
