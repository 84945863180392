import React, { useCallback } from "react";
import { Form } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { FormGroup, Input } from "reactstrap";
import { useEditAccountEntityContext } from "../../../contexts/EditAccountEntityContext";
import { PasswordFormValidator } from "../../../formValidators/PasswordFormValidator";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { IPasswordModel } from "../../../models/account/IPasswordModel";
import { Button } from "../../buttons/Button";
import { ValidatedInput } from "../../forms/inputs/ValidatedInput";
import { LocalizedLabel } from "../../forms/LocalizedLabel";

import "./EditPasswordForm.scss";

interface IPasswordFormProps {
    hasPassword: boolean;
}

export const PasswordForm: React.FC<IPasswordFormProps> = ({ hasPassword }: IPasswordFormProps) => {
    const { t } = useTranslation();
    const {
        entityProps: { entity, setEntityProperties, formValidator, update, canSave, isSaving },
        errorProps: { errors, setErrors },
    } = useEditAccountEntityContext<IPasswordModel, PasswordFormValidator>();

    const validateConfirmedPassword = useCallback(
        async (confirmedPassword: string, newPassword: string) => {
            let errorMessage: string;
            if (!confirmedPassword) {
                errorMessage = "";
            } else {
                try {
                    setEntityProperties({ confirmedPassword });
                    await formValidator.validateConfirmedPassword(confirmedPassword, newPassword);
                    errorMessage = "";
                } catch (error) {
                    errorMessage = extractErrorMessageOrEmptyString(error);
                }
            }
            setErrors({
                confirmedPassword: errorMessage,
            });
        },
        [formValidator, setEntityProperties, setErrors],
    );

    const onOldPasswordChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            let errorMessage: string;
            const currentPassword = e.target.value;

            try {
                setEntityProperties({ currentPassword });
                await formValidator.validateOldPassword(currentPassword);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            setErrors({
                currentPassword: errorMessage,
            });
        },
        [formValidator, setEntityProperties, setErrors],
    );

    const onPasswordChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            let errorMessage: string;
            const newPassword = e.target.value;

            try {
                setEntityProperties({ newPassword });
                await formValidator.validateNewPassword(newPassword);
                errorMessage = "";
            } catch (error) {
                errorMessage = extractErrorMessageOrEmptyString(error);
            }

            setErrors({
                newPassword: errorMessage,
            });

            void validateConfirmedPassword(entity.confirmedPassword, newPassword);
        },
        [entity.confirmedPassword, formValidator, setEntityProperties, setErrors, validateConfirmedPassword],
    );

    const onConfirmedPasswordChange = useCallback(
        (confirmedPassword: string, newPassword: string) => {
            setEntityProperties({ confirmedPassword });
            void validateConfirmedPassword(confirmedPassword, newPassword);
        },
        [setEntityProperties, validateConfirmedPassword],
    );

    return (
        <section className="manage-password">
            <h2>{t(hasPassword ? "Password.ChangePassword" : "Password.SetPassword.Title")}</h2>
            <Form onSubmit={(event) => event.preventDefault()}>
                {/* To be compliant : [DOM] Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq) */}
                <LocalizedLabel for="username" text="Profile.Username" hidden />
                <Input
                    id="username"
                    type="text"
                    name="username"
                    autoComplete="off"
                    hidden={true}
                    value={entity.userName}
                    readOnly={true}
                />
                <FormGroup className="change-password">
                    {hasPassword ? (
                        <div className="input-container">
                            <LocalizedLabel for="current-password" text="Password.CurrentPassword" />
                            <ValidatedInput
                                id="current-password"
                                name="current-password"
                                value={entity.currentPassword}
                                type="password"
                                onChange={onOldPasswordChange}
                                error={errors.currentPassword}
                                autoComplete="current-password"
                            />
                        </div>
                    ) : (
                        <section className="section-content">
                            <Trans i18nKey="Password.SetPassword.Description" />
                        </section>
                    )}
                    <div className="input-container">
                        <LocalizedLabel for="new-password" text="Password.NewPassword" />
                        <ValidatedInput
                            id="new-password"
                            name="new-password"
                            type="password"
                            value={entity.newPassword}
                            onChange={onPasswordChange}
                            error={errors.newPassword}
                            autoComplete="new-password"
                        />
                    </div>
                    <div className="input-container">
                        <LocalizedLabel for="confirm-new-password" text="Password.ConfirmNewPassword" />
                        <ValidatedInput
                            id="confirm-new-password"
                            name="confirm-new-password"
                            type="password"
                            value={entity.confirmedPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                onConfirmedPasswordChange(e.target.value, entity.newPassword);
                            }}
                            error={errors.confirmedPassword}
                            autoComplete="off"
                        />
                    </div>
                    <Button
                        color="primary"
                        type="submit"
                        onClick={update}
                        disabled={!canSave}
                        loading={isSaving}
                        ariaLabel={t(hasPassword ? "Password.UpdatePassword" : "Password.SetPassword.Title")}
                    >
                        {t(hasPassword ? "Password.UpdatePassword" : "Password.SetPassword.Title")}
                    </Button>
                </FormGroup>
            </Form>
        </section>
    );
};
