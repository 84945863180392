export const applicationName = "XpertdocOne";

export const queryParameterNames = {
    returnUrl: "returnUrl",
    message: "message",
};

export const logoutActions = {
    logoutCallback: "logout-callback",
    logout: "logout",
    loggedOut: "logged-out",
};

export const loginActions = {
    login: "login",
    loginCallback: "login-callback",
    loginFailed: "login-failed",
    profile: "profile",
    register: "register",
};

const prefix = "/authentication";

export const applicationPaths = {
    defaultLoginRedirectPath: "/",
    apiAuthorizationClientConfigurationUrl: `/_configuration/${applicationName}`,
    apiAuthorizationPrefix: prefix,
    login: `${prefix}/${loginActions.login}`,
    loginFailed: `${prefix}/${loginActions.loginFailed}`,
    loginCallback: `${prefix}/${loginActions.loginCallback}`,
    register: `${prefix}/${loginActions.register}`,
    profile: `${prefix}/${loginActions.profile}`,
    logOut: `${prefix}/${logoutActions.logout}`,
    loggedOut: "/Account/Logout",
    logOutCallback: `${prefix}/${logoutActions.logoutCallback}`,
    accountRegisterPath: "/Account/Register",
};
