import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ManageGroupPermissions } from "./groupTabs/ManageGroupPermissions";
import { ManageGroupMembers } from "./groupTabs/ManageGroupMembers";
import { ManageGroupSettings } from "./groupTabs/ManageGroupSettings";
import { TabContent } from "../tabs/TabContent";
import { NavMenu } from "../navbars/NavMenu";
import { INavMenuItemProps } from "../navbars/NavMenuItem";
import { useTabs } from "../../hooks/TabsHook";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { IEditPermissionGroupModel } from "../../models/permissions/IEditPermissionGroupModel";
import { buildPermissions, buildSettings } from "../../helpers/EntityHelper";

interface IEditPermissionsGroupViewProps {
    projectId?: string;
    organizationId?: string;
}

const editPermissionsGroupTabs = ["permissions", "members", "settings"] as const;

type EditPermissionsGroupTabType = (typeof editPermissionsGroupTabs)[number];

export const EditPermissionsGroupViewTab: React.FC<IEditPermissionsGroupViewProps> = ({
    projectId,
    organizationId,
}) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useTabs<EditPermissionsGroupTabType>(editPermissionsGroupTabs, "permissions");

    const {
        dirtyProps: { isDirty },
        errorProps: { errors },
    } = useEditEntityContext<IEditPermissionGroupModel, any>();

    const tabs: INavMenuItemProps[] = useMemo(
        () => [
            buildPermissions(false, isDirty(["groupPermissions"]), setActiveTab),
            {
                displayName: "Common.Tab.Members",
                name: "members",
                testSelectorValue: "membersTabs",
                onToggle: setActiveTab,
                icon: "far fa-users",
            },
            buildSettings(!!errors.description || !!errors.name, isDirty(["name", "description"]), setActiveTab),
        ],
        [errors.description, errors.name, isDirty, setActiveTab],
    );

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="permissions" className="permissions" title={t("Common.Tab.Permissions")}>
                <ManageGroupPermissions />
            </TabContent>
            <TabContent tabId="members" className="members" title={t("Common.Tab.Members")}>
                <ManageGroupMembers projectId={projectId} organizationId={organizationId} />
            </TabContent>
            <TabContent tabId="settings" className="settings" title={t("Common.Tab.Settings")}>
                <ManageGroupSettings />
            </TabContent>
        </NavMenu>
    );
};
