import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowToggleIcon } from "../icons/ArrowToggleIcon";
import { IconButton } from "./IconButton";

import "./ArrowToggleButton.scss";

interface IArrowToggleButtonProps {
    onClick: () => void;
    isOpen: boolean;
    buttonText?: string;
    className?: string;
}

/**
 * The arrow toggle button component.
 */
export const ArrowToggleButton = ({ onClick, isOpen, buttonText, className }: IArrowToggleButtonProps) => {
    const { t } = useTranslation();

    return (
        <IconButton
            className={`arrow-toggle-btn ${className ?? ""}`}
            label={t(isOpen ? "Common.Toggle.Collapse" : "Common.Toggle.Expand")}
            onClick={onClick}
        >
            <ArrowToggleIcon isOpen={isOpen} />
            {buttonText}
        </IconButton>
    );
};
