import * as Yup from "yup";
import { PermissionGroupFormValidator } from "./PermissionGroupFormValidator";
import { IPermissionGroupFormFieldsModel } from "../models/permissions/IPermissionGroupFormFieldsModel";

export type CreatePermissionGroupFormValidatorFields = Omit<IPermissionGroupFormFieldsModel, "name">;

export class CreatePermissionGroupFormValidator extends PermissionGroupFormValidator<CreatePermissionGroupFormValidatorFields> {
    protected createPermissionGroupFormSchema = this.schema.concat(
        Yup.object().shape({
            userIds: Yup.array(Yup.string().ensure()),
        }),
    );

    public validateUsers(userIds: string[]): Promise<(string | undefined)[] | undefined> {
        return this.createPermissionGroupFormSchema.fields.userIds.validate(userIds);
    }

    public isValid(data: CreatePermissionGroupFormValidatorFields): Promise<boolean> {
        return this.createPermissionGroupFormSchema.isValid(data);
    }
}
