import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { LoadingAnimation } from "../loading/LoadingAnimation";

import "./LoadingWrapper.scss";

/**
 * The loading wrapper props.
 */
interface ILoadingWrapperProps {
    message: string;
    className?: string;
    size: number;
}

/**
 * The loading wrapper component.
 */
export const LoadingWrapper: React.FC<ILoadingWrapperProps> = ({ className, message, size }: ILoadingWrapperProps) => {
    const { t } = useTranslation();

    return (
        <div className={classNames("loading-wrapper", className)}>
            <div className="loading-spinner-wrapper">
                <LoadingAnimation size={size} />
            </div>
            <div className="loading-message">{t(message)}</div>
        </div>
    );
};
