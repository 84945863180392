import React from "react";
import classNames from "classnames";
import { INavTabProps } from "../tabs/NavTab";
import { useTabs } from "../../hooks/TabsHook";
import { NavTabs } from "../tabs/NavTabs";
import { PermissionsGroupView } from "./PermissionsGroupView";
import { ProjectPermissionsGroupView } from "./ProjectPermissionsGroupView";

const permissionTabs = ["group"] as const;

type PermissionsViewTabType = (typeof permissionTabs)[number];

interface IPermissionsTabsProps {
    organizationId?: string;
    projectId?: string;
    groupLink: (groupId: string) => string;
}

export const PermissionsTabs = ({ organizationId, projectId, groupLink }: IPermissionsTabsProps) => {
    const [activeTab] = useTabs<PermissionsViewTabType>(permissionTabs, "group");

    const tabsBuilder: INavTabProps[] = [
        {
            displayName: "Common.Tab.Groups",
            name: "group",
            invalid: false,
            testSelectorValue: "groupsTab",
            onToggle: () => {
                return;
            },
            tabcontrol: "group",
            id: "group-id",
        },
    ];
    return (
        <section className={classNames("edit-permissions-group tab-content-section")}>
            <NavTabs activeTab={activeTab} navTabs={tabsBuilder}>
                {projectId ? (
                    <ProjectPermissionsGroupView projectId={projectId} groupLink={groupLink} />
                ) : (
                    <PermissionsGroupView organizationId={organizationId} groupLink={groupLink} />
                )}
            </NavTabs>
        </section>
    );
};
