import React, { useCallback, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Collapse } from "reactstrap";
import { ArrowToggleButton } from "../../buttons/ArrowToggleButton";
import { IPermissionModel } from "../../../models/permissions/IPermissionModel";
import { SelectPermission } from "../../selects/SelectPermissionComponent";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { IEditPermissionGroupModel } from "../../../models/permissions/IEditPermissionGroupModel";
import { PermissionAccess } from "../../../models/permissions/PermissionAccess";

import "./PermissionSection.scss";

interface IPermissionSectionProps {
    handlePermissionChange: (permissions: IPermissionModel[]) => void;
    title: string;
    permissions: IPermissionModel[];
}

export const PermissionSection: React.FC<IPermissionSectionProps> = ({
    handlePermissionChange,
    title,
    permissions,
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const {
        permissionsProps: { canEdit },
    } = useEditEntityContext<IEditPermissionGroupModel, any>();

    const sortedPermissions = useMemo(() => {
        return [...permissions].sort((a, b) => a.name.localeCompare(b.name));
    }, [permissions]);

    const handleChange = useCallback(
        (value: PermissionAccess, name: string) => {
            const newPermissions = cloneDeep(permissions);
            newPermissions[newPermissions.findIndex((np) => np.name === name)].value = value;

            handlePermissionChange(newPermissions);
        },
        [handlePermissionChange, permissions],
    );

    return (
        <div className="permission-section">
            <ArrowToggleButton
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen((currentIsOpen) => !currentIsOpen);
                }}
                buttonText={title}
                className="permission-section__collapse-label"
            />
            <Collapse isOpen={isOpen}>
                {sortedPermissions.map(({ name, label, value, readonly }) => {
                    const onChange = (val?: PermissionAccess) => handleChange(val!, name);

                    return (
                        <div key={name} className="permission-section__row">
                            <SelectPermission
                                onChange={onChange}
                                value={value}
                                readonly={!canEdit || readonly}
                                label={label}
                            />
                        </div>
                    );
                })}
            </Collapse>
        </div>
    );
};
