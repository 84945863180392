import React from "react";
import { Trans } from "react-i18next";
import { useTableContext } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";

export const TableHeaderCountLabel: React.FC = <
    TEntityModel,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>() => {
    const {
        tableState: { totalCount, items },
    } = useTableContext<TEntityModel, TRowItem, TFilterValue>()!;
    return (
        <span className="table-count-label">
            <Trans i18nKey="Table.CountLabel" values={{ currentCount: items.length, totalCount }} />
        </span>
    );
};
