import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

/**
 * Create the App Insights Telemetry Service
 */
const createTelemetryService = () => {
    const initialize = (connectionString: string) => {
        if (!connectionString) {
            throw new Error("Telemetry connection string not provided.");
        }

        reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
            config: {
                connectionString,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                enableAutoRouteTracking: true,
            },
        });

        appInsights.loadAppInsights();

        return reactPlugin;
    };

    return {
        reactPlugin,
        appInsights,
        initialize,
    };
};

export const ai = createTelemetryService();
export const getAppInsights = (): ApplicationInsights => appInsights;
