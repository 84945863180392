import { useCallback, useMemo } from "react";
import { NameValidator } from "../../formValidators/NameValidator";
import { ProjectFormValidator } from "../../formValidators/ProjectFormValidator";
import { IProjectFormFields } from "../../models/project/IProjectFormFields";
import { IValidationResult } from "../CreateModalEntityHook";
import { useDefaultEntityValidation } from "../validation/EntityValidationHook";
import { useProjectApi } from "./ProjectApiHook";

export const useProjectValidation = ({
    description,
    name,
    organizationId,
}: IProjectFormFields): IValidationResult<IProjectFormFields, "name"> => {
    const { nameIsUnique } = useProjectApi();

    const verifyNameIsUnique = useCallback(
        async (newProjectName: string): Promise<boolean> => {
            return await nameIsUnique(organizationId, newProjectName);
        },
        [nameIsUnique, organizationId],
    );

    const nameValidatorProvider = useCallback(
        () =>
            new NameValidator({
                nameExistErrorKey: "ProjectFormValidator.ProjectExistsForOrganization",
                nameIsUnique: verifyNameIsUnique,
            }),
        [verifyNameIsUnique],
    );
    const formValidationProvider = useCallback(() => new ProjectFormValidator(), []);

    const fields = useMemo<IProjectFormFields>(
        () => ({ name, description, organizationId }),
        [description, name, organizationId],
    );

    const { valid, errors, nameValidationStatus, setError } = useDefaultEntityValidation({
        fields,
        nameValidatorProvider,
        formValidationProvider,
    });

    return {
        valid,
        errors,
        validationStatuses: { name: nameValidationStatus },
        setError,
    };
};
