import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Select, {
    ContainerProps,
    GroupBase,
    InputProps,
    MultiValue,
    MultiValueGenericProps,
    MultiValueProps,
    OptionProps,
    SingleValue,
    StylesConfig,
} from "react-select";
import { SelectComponents as SelectComponentsType } from "react-select/dist/declarations/src/components";
import { SelectContainer } from "./innerComponents/SelectContainer";
import { DropdownIndicator } from "./innerComponents/DropdownIndicator";
import { SingleValue as SingleValueComponent } from "./innerComponents/SingleValue";
import { Option } from "./innerComponents/Option";
import { MultiValueContainer } from "./innerComponents/MultiValueContainer";
import { MultiValueLabel } from "./innerComponents/MultiValueLabel";
import { SelectInput } from "./innerComponents/SelectInput";

import "./SelectComponent.scss";

/**
 * The select component options.
 */
export type SelectComponentOptions<TValue, TData = unknown> = {
    value: TValue;
    label: string;
    icon?: string;
    data?: TData;
    className?: string;
    componentIcon?: JSX.Element;
    testSelectorValue?: string;
};

/**
 * The select component props interface.
 */
interface ISelectComponentProps<TValue> {
    options: SelectComponentOptions<TValue>[];
    autoFocus?: boolean;
    value: TValue;
    size?: "large";
    testSelectorValue?: string;
    multiple?: boolean;
    clearable?: boolean;
    searchable?: boolean;
    disabled?: boolean;
    onChange: (value?: TValue | TValue[] | null) => void;
    className?: string;
    placeholder?: string;
    customOption?: React.FC<OptionProps>;
    customMultiValueLabel?: (props: MultiValueProps) => JSX.Element | null;
    filterOptions?: (candidate: { label: string; value: string; data: any }, input: string) => boolean;
    customStyles?: () => StylesConfig<
        SelectComponentOptions<TValue>,
        boolean,
        GroupBase<SelectComponentOptions<TValue>>
    >;
    id: string;
}

const defaultStyles = (): StylesConfig<
    SelectComponentOptions<any>,
    boolean,
    GroupBase<SelectComponentOptions<any>>
> => ({
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "white" : "transparent",
        color: "inherit",
        "&:hover": {
            backgroundColor: "#e9ecef",
        },
    }),
    multiValueLabel: (base) => ({
        ...base,
        span: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    }),
});

/**
 * The select component.
 */
export const SelectComponent: React.FC<ISelectComponentProps<any>> = <TValue extends Array<TValue>>({
    autoFocus,
    clearable,
    multiple,
    options,
    size,
    searchable,
    testSelectorValue,
    onChange,
    disabled = false,
    className,
    placeholder,
    customMultiValueLabel,
    customOption,
    customStyles,
    filterOptions,
    id,
    value,
}: ISelectComponentProps<TValue>) => {
    const { t } = useTranslation();
    const internalValue = useMemo(() => {
        if (multiple) {
            return (value as Array<TValue>).map((v) => options.find((o) => o.value === v)!);
        } else {
            return options.find((option) => option.value === value) || null;
        }
    }, [options, value, multiple]);

    const selectContainer = useCallback(
        (
            containerProps: ContainerProps<
                SelectComponentOptions<TValue, unknown>,
                boolean,
                GroupBase<SelectComponentOptions<TValue, unknown>>
            >,
        ) => <SelectContainer testSelectorValue={testSelectorValue} {...containerProps} />,
        [testSelectorValue],
    );
    const optionComponent = useCallback(
        (
            optionProps: OptionProps<
                SelectComponentOptions<TValue, unknown>,
                boolean,
                GroupBase<SelectComponentOptions<TValue, unknown>>
            >,
        ) => <Option customOption={customOption} {...optionProps} />,
        [customOption],
    );
    const multiValueLabelComponent = useCallback(
        (
            multiValueProps: MultiValueGenericProps<
                SelectComponentOptions<TValue, unknown>,
                boolean,
                GroupBase<SelectComponentOptions<TValue, unknown>>
            >,
        ) => <MultiValueLabel customMultiValueLabel={customMultiValueLabel} {...multiValueProps} />,
        [customMultiValueLabel],
    );
    const inputComponent = useCallback(
        (
            inputProps: InputProps<
                SelectComponentOptions<TValue, unknown>,
                boolean,
                GroupBase<SelectComponentOptions<TValue, unknown>>
            >,
        ) => <SelectInput {...inputProps} id={id} searchable={searchable} />,
        [id, searchable],
    );

    const components: Partial<
        SelectComponentsType<SelectComponentOptions<TValue>, boolean, GroupBase<SelectComponentOptions<TValue>>>
    > = useMemo(() => {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            SelectContainer: selectContainer,
            IndicatorSeparator: null,
            DropdownIndicator,
            SingleValue: SingleValueComponent,
            Option: optionComponent,
            MultiValueContainer,
            MultiValueLabel: multiValueLabelComponent,
            Input: inputComponent,
        };
        /* eslint-enable @typescript-eslint/naming-convention */
    }, [inputComponent, multiValueLabelComponent, optionComponent, selectContainer]);

    const onChangeHandler = useCallback(
        (inputValue?: MultiValue<SelectComponentOptions<TValue>> | SingleValue<SelectComponentOptions<TValue>>) => {
            if (!inputValue) {
                onChange(inputValue);
                return;
            }

            if (multiple) {
                onChange((inputValue as SelectComponentOptions<TValue>[]).map((x) => x.value));
            } else {
                onChange((inputValue as SelectComponentOptions<TValue>).value);
            }
        },
        [multiple, onChange],
    );

    const noOptionsHandler = useCallback(() => t("Select.NoOptions"), [t]);

    const styles: StylesConfig<
        SelectComponentOptions<TValue>,
        boolean,
        GroupBase<SelectComponentOptions<TValue>>
    > = useMemo(
        () =>
            customStyles
                ? {
                      ...defaultStyles(),
                      ...(customStyles ? customStyles() : {}),
                  }
                : defaultStyles(),
        [customStyles],
    );

    return (
        <>
            <Select
                components={components}
                autoFocus={autoFocus}
                isSearchable={searchable}
                options={options}
                value={internalValue}
                isMulti={multiple}
                isDisabled={disabled}
                isClearable={clearable}
                placeholder={placeholder || t("Select.PlaceHolder")}
                noOptionsMessage={noOptionsHandler}
                filterOption={filterOptions}
                onChange={onChangeHandler}
                className={classNames("select-component", size, className)}
                styles={styles}
            />
        </>
    );
};
