import * as Yup from "yup";
import { IServiceHookFormFields } from "../models/serviceHooks/IServiceHookFormFields";
import { ServiceHookEventType } from "../models/serviceHooks/ServiceHookEventType";
import { FormValidator } from "./FormValidator";
import { IWebHookConfigModel } from "../models/serviceHooks/configs/IWebHookConfigModel";

export interface IEditableServiceHookFields extends Pick<IServiceHookFormFields, "config" | "eventType"> {}

const urlRegex = /^https?:\/\/[\w-]+(\.[\w-]+)*(:[0-9]+)?(\/.*)?$/i;

export class ServiceHookFormValidator extends FormValidator<IEditableServiceHookFields> {
    protected schema = Yup.object().shape({
        eventType: Yup.string().required("Common.Required"),
        config: Yup.string()
            .ensure()
            .test(
                "config-has-validUrl",
                () => {
                    return new Yup.ValidationError("ServiceHookFormValidator.InvalidUrl");
                },
                (configValue: string) => {
                    try {
                        const config = JSON.parse(configValue) as IWebHookConfigModel;

                        if (!config.serviceUri) {
                            return new Yup.ValidationError("Common.Required");
                        }

                        return urlRegex.test(config.serviceUri);
                    } catch (_) {
                        return new Yup.ValidationError("Common.Required");
                    }
                },
            ),
    });

    public validateEventType(eventType: ServiceHookEventType | ""): Promise<string> {
        return this.schema.fields.eventType.validate(eventType);
    }

    public validateConfig(config: string): Promise<string> {
        return this.schema.fields.config.validate(config);
    }
}
