export enum ServiceHookExecutionStatus {
    Queued = 0,
    Executing = 1,
    Succeeded = 2,
    Failed = 3,
    Skipped = 4,
}

export enum ServiceHookExecutionStatusTranslationMap {
    "ServiceHookExecutionStatus.Queued" = ServiceHookExecutionStatus.Queued,
    "ServiceHookExecutionStatus.Executing" = ServiceHookExecutionStatus.Executing,
    "ServiceHookExecutionStatus.Succeeded" = ServiceHookExecutionStatus.Succeeded,
    "ServiceHookExecutionStatus.Failed" = ServiceHookExecutionStatus.Failed,
    "ServiceHookExecutionStatus.Skipped" = ServiceHookExecutionStatus.Skipped,
}
