import React from "react";
import { FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { formatBytes } from "../../../helpers/formattingHelper";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { TimestampInfo } from "../../date/TimestampInfo";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { ContentFormValidator } from "../../../formValidators/ContentFormValidator";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { EditDescription } from "../../edit/EditDescription";
import { ReadOnlyInputWithCopy } from "../../forms/inputs/ReadOnlyInputWithCopy";

export const EditItemOverview = () => {
    const {
        entityProps: { entity },
    } = useEditEntityContext<IContentEntityModel, ContentFormValidator>();
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();

    return (
        <>
            {entity.type === "File" && (
                <FormGroup className="size-container">
                    <div className="item-properties-container__size">{t("Common.Size")}</div>
                    <div>{formatBytes(entity.size ?? 0)}</div>
                </FormGroup>
            )}
            <FormGroup className="location-container">
                <ReadOnlyInputWithCopy
                    value={entity.path}
                    label="Common.Location"
                    displayedValueWhileCopying="ContentManager.LocationCopied"
                />
            </FormGroup>
            <FormGroup className="modifiedBy-container" {...setSelector("modifiedByFormGroup")}>
                <div className="item-properties-container__content__modifiedBy">{t("Common.Modified")}</div>
                <TimestampInfo date={entity.modifiedOn} user={entity.modifiedBy} />
            </FormGroup>
            <FormGroup className="createdBy-container" {...setSelector("createdByFormGroup")}>
                <div className="item-properties-container__content__createdBy">{t("Common.Created")}</div>
                <TimestampInfo date={entity.createdOn} user={entity.createdBy} />
            </FormGroup>
            <EditDescription />
        </>
    );
};
