import React from "react";
import classNames from "classnames";
import { ConnectDropTarget } from "react-dnd";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The entity table row props interface.
 */
export interface IEntityTableRowProps {
    className?: string;
    selected?: boolean;
    rowIndex: number;
    children?: React.ReactNode;
    dragAndDropRef?: ConnectDropTarget;
}

/**
 * The entity table row component.
 */
export const EntityTableRow: React.FC<IEntityTableRowProps> = ({
    children,
    className,
    selected,
    rowIndex,
    dragAndDropRef,
}) => {
    const { setSelector } = useTestSelector();

    return (
        <tr
            className={classNames(className, { "table-success": selected })}
            {...setSelector(`table-row-${rowIndex}`)}
            ref={dragAndDropRef}
        >
            {children}
        </tr>
    );
};
