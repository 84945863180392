import { useEffect, useState } from "react";
import { NameValidator } from "../../../formValidators/NameValidator";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { ValidationStatus } from "../../../models/entity/validation/ValidationStatus";

interface IUseEntityNameValidationProps {
    nameValidator: NameValidator;
    defaultName: string;
    name: string;
}

interface INameValidationResult {
    nameStatus: ValidationStatus;
    error: string;
}

const checkStatus = (validating: boolean, error: string): ValidationStatus => {
    if (validating) return "Validating";
    if (error) return "Invalid";
    return "Valid";
};

export const useEntityNameValidation = ({
    nameValidator,
    defaultName,
    name,
}: IUseEntityNameValidationProps): INameValidationResult => {
    const [error, setError] = useState("");
    const [validatingName, setValidatingName] = useState(false);
    const [nameVisited, setNameVisited] = useState(false);
    useEffect(() => {
        nameValidator.setInitialValue({ name: defaultName });
    }, [defaultName, nameValidator]);

    useEffect(() => {
        !nameVisited && setNameVisited(!!name);
    }, [nameVisited, name]);

    useEffect(() => {
        if (!nameVisited) {
            return;
        }

        setValidatingName(true);
        void nameValidator
            .validateName(name)
            .then(() => {
                setValidatingName(false);
                setError("");
            })
            .catch((err) => {
                setValidatingName(false);
                setError(extractErrorMessageOrEmptyString(err));
            });
    }, [name, nameVisited, nameValidator]);

    return {
        nameStatus: checkStatus(validatingName, error),
        error,
    };
};
