import { useCallback, useMemo } from "react";
import { ICreateUsersInvitationModel } from "../../models/user/invitations/ICreateUsersInvitationModel";
import { useOrganizationApi } from "../organization/OrganizationApiHook";
import { useProjectApi } from "../project/ProjectApiHook";
import { useUserApi } from "./UserApiHook";

export interface IInvitationResult {
    inviteSent: number;
    inviteCount: number;
}

interface UseUsersInvitationHookProps {
    projectId?: string;
    organizationId?: string;
}

interface UseUsersInvitationHookReturn {
    createUsersInvitation: (createUserInvitationModel: ICreateUsersInvitationModel) => Promise<IInvitationResult>;
}

export const useUsersInvitationHook = ({
    projectId,
    organizationId,
}: UseUsersInvitationHookProps): UseUsersInvitationHookReturn => {
    const { inviteUsers: inviteOrganizationUsers } = useOrganizationApi();
    const { inviteUsers: inviteProjectUsers } = useProjectApi();
    const { invite: inviteSystemUsers } = useUserApi();

    const inviteOrganizationUsersRequest = useCallback(
        async (createUserInvitationModel: ICreateUsersInvitationModel) => {
            const inviteSent = await inviteOrganizationUsers(createUserInvitationModel, organizationId!);
            return { inviteSent, inviteCount: createUserInvitationModel.emails.length };
        },
        [organizationId, inviteOrganizationUsers],
    );

    const inviteSystemUsersRequest = useCallback(
        async (createUserInvitationModel: ICreateUsersInvitationModel) => {
            const inviteSent = await inviteSystemUsers(createUserInvitationModel);
            return { inviteSent, inviteCount: createUserInvitationModel.emails.length };
        },
        [inviteSystemUsers],
    );

    const inviteProjectUsersRequest = useCallback(
        async (createUserInvitationModel: ICreateUsersInvitationModel) => {
            const inviteSent = await inviteProjectUsers(createUserInvitationModel, projectId!);
            return { inviteSent, inviteCount: createUserInvitationModel.emails.length };
        },
        [projectId, inviteProjectUsers],
    );

    const createUsersInvitation = useMemo(() => {
        if (organizationId) {
            return inviteOrganizationUsersRequest;
        }

        if (projectId) {
            return inviteProjectUsersRequest;
        }

        return inviteSystemUsersRequest;
    }, [
        organizationId,
        projectId,
        inviteSystemUsersRequest,
        inviteOrganizationUsersRequest,
        inviteProjectUsersRequest,
    ]);

    return {
        createUsersInvitation,
    };
};
