import { MicrosoftApplicationName } from "../../helpers/MicrosoftBrandHelper";

/*
 * Wopi Action Enum
 */
export enum WopiAction {
    View,
    Edit,
}

/**
 * The discovery model
 */
export interface DiscoveryModel {
    appName: MicrosoftApplicationName;
    extension: string;
    action: string;
}
