import { useState, useEffect, useCallback } from "react";
import { useModalContext } from "../../contexts/ModalContext";
import { IContentEntityModel, IUpdateContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { useProjectContext } from "../../contexts/ProjectContext";
import { NotificationService } from "../../services/NotificationService";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { ContentManagerRenameForm } from "../../components/contentManager/ContentManagerRenameForm";
import { useContentFileApi } from "./ContentFileApiHook";
import { useContentFolderApi } from "./ContentFolderApiHook";

/**
 * The content maanger rename modal return interface.
 */
interface ContentManagerRenameModalReturn {
    renameForlderOrFile: (item: IContentEntityModel) => void;
}

interface ContentManagerRenameModalProps {
    parentFolderId: string;
    tableId: string;
}

export const useContentManagerRenameModal = ({
    parentFolderId,
    tableId,
}: ContentManagerRenameModalProps): ContentManagerRenameModalReturn => {
    const [triggerRenameModal, setTriggerRenameModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<IContentEntityModel>();

    const { showModal, closeModal } = useModalContext();
    const { projectId } = useProjectContext();
    const { refreshTable } = useTableRefreshContext();
    const { update: updateFile, get: getFile } = useContentFileApi(projectId);
    const { update: updateFolder, get: getFolder } = useContentFolderApi(projectId);

    const renameForlderOrFile = useCallback(
        (item: IContentEntityModel) => {
            setTriggerRenameModal(true);

            (async () => {
                const getContent = item.type === "Folder" ? getFolder : getFile;
                try {
                    const content = await getContent(item.id);
                    setCurrentItem(content);
                } catch (error) {
                    defaultRequestErrorHandler(error);
                }
            })();
        },
        [getFile, getFolder],
    );

    const onRenameClosed = useCallback((): void => {
        closeModal();
        setCurrentItem(undefined);
    }, [closeModal]);

    const rename = useCallback(
        async (updateFolderModel: IUpdateContentEntityModel) => {
            const updateContent = currentItem!.type === "Folder" ? updateFolder : updateFile;
            return updateContent(currentItem!.id, updateFolderModel);
        },
        [currentItem, updateFile, updateFolder],
    );

    const onRenamed = useCallback(
        (item: IContentEntityModel): void => {
            onRenameClosed();
            refreshTable(tableId, true);

            NotificationService.addSuccessNotification({
                messageKey:
                    item.type === "Folder"
                        ? "ContentManager.Rename.Folder.SaveSuccess"
                        : "ContentManager.Rename.File.SaveSuccess",
                messageKeyParams: { name: item.name },
            });
        },
        [onRenameClosed, refreshTable, tableId],
    );

    const showRenameModal = useCallback(
        (item: IContentEntityModel) => {
            showModal({
                entity: item,
                onCreate: rename,
                onCreated: onRenamed,
                formPropsToFlow: {
                    parentFolderId: parentFolderId,
                    contentType: item.type,
                    projectId,
                },
                modalProps: {
                    onClose: onRenameClosed,
                    createLabel: "Common.Rename",
                    className: "content-manager-rename-modal",
                    titleKey: item.type === "Folder" ? "ContentManager.Rename.Folder" : "ContentManager.Rename.File",
                    unsavedWarningBody: "ContentManager.Rename.UnsavedWarningBody",
                    size: "md",
                    expandable: false,
                },
                contentToDisplay: ContentManagerRenameForm,
            });
        },
        [showModal, rename, onRenamed, parentFolderId, projectId, onRenameClosed],
    );

    useEffect(() => {
        if (triggerRenameModal && currentItem) {
            showRenameModal(currentItem);
            setTriggerRenameModal(false);
        }
    }, [currentItem, showRenameModal, triggerRenameModal]);

    return {
        renameForlderOrFile,
    };
};
