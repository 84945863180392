import React, { useEffect, useMemo, useRef } from "react";
import { FormGroup, Input } from "reactstrap";
import { useTranslateSelectComponentOptions } from "../../hooks/TranslateSelectComponentOptionsHook";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { useDataSetValidation } from "../../hooks/dataSet/DataSetValidation";
import { IDataSetFormFields } from "../../models/dataSets/IDataSetFormField";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { EntityForm } from "../forms/EntityForm";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectComponentDataSetOptions } from "./SelectComponentDataSetOptions";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { DataSetType } from "../../models/dataSets/DataSetType";
import { SelectDataSetType } from "./SelectDataSetType";

type FormMode = "Create" | "Clone";

interface ICreateDataSetFormProps {
    initialValue: IDataSetFormFields;
    onFormChange: (formData: IEntityFormData<IDataSetFormFields>) => void;
    mode: FormMode;
}

export const CreateDataSetForm: React.FC<ICreateDataSetFormProps> = ({
    initialValue,
    onFormChange,
    mode,
}: ICreateDataSetFormProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: useDataSetValidation,
        isDirty:
            mode === "Clone"
                ? () => {
                      return true;
                  }
                : undefined,
    });

    const handleOnFocus = () => {
        inputRef.current?.focus();
        inputRef.current?.select();
    };

    useEffect(() => {
        mode === "Clone" && handleOnFocus();
    }, [mode]);

    const translatedSelectComponentOptions = useTranslateSelectComponentOptions(SelectComponentDataSetOptions);
    const selectComponentOptions = useMemo(() => {
        return mode === "Clone"
            ? [translatedSelectComponentOptions.find((option) => option.value === data.type)!]
            : translatedSelectComponentOptions;
    }, [data.type, mode, translatedSelectComponentOptions]);

    const { setSelector } = useTestSelector();

    return (
        <EntityForm name="create-dataset" className="createDataSetForm">
            <FormGroup>
                <LocalizedLabel aria-required="true" for="create-dataset-name" text="Common.Name" required />
                <ValidatedInputWithStatus
                    id="create-dataset-name"
                    innerRef={inputRef}
                    autoFocus={true}
                    autoComplete="off"
                    name="name"
                    type="text"
                    {...setSelector("nameTextbox")}
                    onChange={(e) => onChange("name", e.target.value)}
                    error={errors.name}
                    isBusy={validationStatuses.name === "Validating"}
                    value={data.name}
                />
            </FormGroup>
            {mode === "Clone" && (
                <FormGroup check>
                    <Input
                        type="checkbox"
                        id="includes-samples-checkbox"
                        checked={!!data.includeSamples}
                        {...setSelector("includeSamplesCheckbox")}
                        onChange={() => onChange("includeSamples", !data.includeSamples)}
                    />
                    <LocalizedLabel check for="includes-samples-checkbox" text="DataSet.Clone.IncludeSamples" />
                </FormGroup>
            )}
            <FormGroup>
                <LocalizedLabel for="create-description" text="Common.Description" />
                <ValidatedInput
                    id="create-description"
                    autoComplete="off"
                    name="description"
                    type="textarea"
                    {...setSelector("descriptionTextbox")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("description", e.target.value)}
                    error={errors.description}
                    value={data.description}
                />
            </FormGroup>
            <FormGroup className="col-lg-6 p-0">
                <SelectDataSetType
                    value={data.type}
                    options={selectComponentOptions}
                    onChange={(value: DataSetType) => onChange("type", value)}
                />
            </FormGroup>
        </EntityForm>
    );
};
