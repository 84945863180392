import { useTranslation } from "react-i18next";
import { IEntityDropdownMenuItem } from "../../components/dropdowns/EntityDropdownMenu";
import { useDialogContext } from "../../contexts/DialogContext";
import { useEditEntityContext } from "../../contexts/EditEntityContext";

/**
 * The delete dropdown menu item.
 */
export const useDelete = (): IEntityDropdownMenuItem => {
    const { t } = useTranslation();
    const editEntityContext = useEditEntityContext();
    const dialogContext = useDialogContext();

    const {
        entityProps: { delete: deleteEntity, entity },
        deleteDialogProps: { dialogBodyKey, dialogTitleKey, entityCount, toggleCustomDelete, custom },
    } = editEntityContext;

    const onDelete = () => {
        if (custom && toggleCustomDelete) {
            toggleCustomDelete();
            return;
        }

        dialogContext.show({
            type: "Delete",
            messageKey: t(dialogBodyKey, { count: entityCount }),
            titleKey: t(dialogTitleKey, { count: entityCount }),
            onProceed: () =>
                deleteEntity(entity.name).catch(() => {
                    return;
                }),
            proceedLabelKey: t("Common.Delete"),
        });
    };

    return {
        className: "fal fa-trash-alt",
        text: t("Common.Delete"),
        testSelectorValue: "deleteItem",
        onClick: onDelete,
    };
};
