import React from "react";
import { Route } from "react-router-dom";
import { applicationPaths, logoutActions } from "../api-authorization/ApiAuthorizationConstants";
import { Logout } from "../api-authorization/Logout";

export const logoutRoutes = (
    <Route>
        <Route path={applicationPaths.logOut} element={<Logout action={logoutActions.logout} />} />
        <Route path={applicationPaths.logOutCallback} element={<Logout action={logoutActions.logoutCallback} />} />
        <Route path={applicationPaths.loggedOut} element={<Logout action={logoutActions.loggedOut} />} />
    </Route>
);
