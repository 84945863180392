import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { DropdownItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { FluentExtensionIcon } from "../icons/FluentExtensionIcon";
import { useAppConfigContext } from "../../contexts/AppConfigContext";
import { useProjectContext } from "../../contexts/ProjectContext";
import { usePermissionKey } from "../../hooks/permission/PermissionKeyHook";
import { usePermissionCheck } from "../../hooks/permission/PermissionCheckHook";
import { WopiAction } from "../../models/wopi/IWopiModel";
import { FileDownloadService, IFileDownloadRequest } from "../../services/FileDownloadService";

import "./SearchResultItem.scss";

interface ISearchResultFileItemProps {
    name: string;
    entityId: string;
}

export const SearchResultFileItem: React.FC<ISearchResultFileItemProps> = ({
    name,
    entityId,
}: ISearchResultFileItemProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const { projectId } = useProjectContext();
    const { wopiEnabled } = useAppConfigContext();

    const contentWriteKey = usePermissionKey({
        permission: PermissionKeys.contentManager.write,
        objectId: entityId,
        projectId,
    });
    const contentReadKey = usePermissionKey({
        permission: PermissionKeys.contentManager.read,
        objectId: entityId,
        projectId,
    });

    const permissionsObject = useMemo(
        () => ({ permissionKeys: [contentWriteKey, contentReadKey] }),
        [contentReadKey, contentWriteKey],
    );

    const { isAllowed } = usePermissionCheck(permissionsObject);
    const wopiAction = useMemo(() => {
        if (!wopiEnabled) {
            return;
        }

        if (isAllowed(contentWriteKey)) {
            return WopiAction.Edit;
        }

        if (isAllowed(contentReadKey)) {
            return WopiAction.View;
        }
    }, [wopiEnabled, contentReadKey, contentWriteKey, isAllowed]);

    const handleFileClick = useCallback(() => {
        const request: IFileDownloadRequest = {
            fileId: entityId,
            isAttachment: false,
            translationLanguageCode: "",
            action: wopiAction,
        };
        FileDownloadService.requestFileDownload("project", request);
    }, [entityId, wopiAction]);

    return (
        <DropdownItem
            tag={"button"}
            className={classNames(["search-list-item", "file-item"])}
            {...setSelector(`dropdownItem-${name}`)}
            onClick={handleFileClick}
        >
            <div className="item-name-wrapper">
                <FluentExtensionIcon name={name} size={16} />
                <span className="item-name text-truncate">{name}</span>
            </div>
            <div className="item-type">{t("ContentManager.File")}</div>
        </DropdownItem>
    );
};
