import React, { useCallback, useMemo } from "react";
import { formatBytes } from "../../helpers/formattingHelper";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { SortOrder } from "../../models/SortOrder";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { CrumbHierarchyShortener } from "../../components/contentManager/CrumbHierarchyShortener";
import { ContentManagerSearchTableLocationWidth } from "../../models/contentManager/constant";

const pathToCrumb = (item: string) => {
    const crumbs = item.split("/").slice(1);
    return crumbs.map((c) => ({ name: c }));
};

interface ContentmanagerTableColumnDefsProps {
    columnNameContent: (item: IContentEntityModel) => JSX.Element;
    withLocationColumn?: boolean;
}

export const useContentManagerTableColumnDefs = ({
    columnNameContent,
    withLocationColumn,
}: ContentmanagerTableColumnDefsProps) => {
    const columnLocationContent = useCallback(
        (item: IContentEntityModel) => (
            <div className="content-location-container">
                <CrumbHierarchyShortener
                    containerWidth={ContentManagerSearchTableLocationWidth}
                    crumbs={pathToCrumb(item.path)}
                />
            </div>
        ),
        [],
    );

    const columnDefs = useMemo(() => {
        const innerColumnDefs: IEntityTableColumnDef[] = [
            {
                type: "JSX",
                fieldName: "name",
                displayName: "Common.Name",
                className: "name",
                sortField: {
                    name: "Name",
                    order: SortOrder.Asc,
                },
                shouldTruncateText: true,
                testSelectorColumnName: "name",
                content: columnNameContent,
            },
            {
                type: "DateTime",
                fieldName: "modifiedOn",
                displayName: "ContentManager.ModifiedOn",
                className: "modifiedOn",
                sortField: {
                    name: "ModifiedOn",
                    order: SortOrder.Asc,
                },
                testSelectorColumnName: "modifiedOn",
            },
            {
                type: "Text",
                content: ({ modifiedBy }: IContentEntityModel) => modifiedBy.displayName,
                fieldName: "modifiedBy",
                displayName: "ContentManager.ModifiedBy",
                className: "modifiedBy",
                shouldTruncateText: true,
                testSelectorColumnName: "modifiedBy",
            },
            {
                type: "JSX",
                fieldName: "size",
                displayName: "Common.Size",
                className: "size",
                sortField: {
                    name: "Size",
                    order: SortOrder.Asc,
                },
                shouldTruncateText: true,
                testSelectorColumnName: "size",
                content: ({ size }: IContentEntityModel) => (size ? formatBytes(size) : "-"),
            },
        ];

        if (withLocationColumn) {
            innerColumnDefs.splice(1, 0, {
                type: "JSX",
                fieldName: "path",
                displayName: "Common.Location",
                className: "path",
                shouldTruncateText: true,
                testSelectorColumnName: "path",
                content: columnLocationContent,
            });

            innerColumnDefs.pop();
        }

        return innerColumnDefs;
    }, [columnLocationContent, columnNameContent, withLocationColumn]);

    return columnDefs;
};
