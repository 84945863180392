import React, { useContext } from "react";
import { IProjectModel } from "../models/project/IProjectModel";

/**
 * The project context type.
 */
export type ProjectContextType = IProjectModel & { organizationName: string };
/**
 * The project context.
 */
export const ProjectContext = React.createContext<ProjectContextType | null>(null);

/**
 * The use project context.
 */
export const useProjectContext = (): ProjectContextType => useContext(ProjectContext) as ProjectContextType;
