import React, { useCallback, useEffect, useState } from "react";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { EntityForm } from "../forms/EntityForm";
import { FormGroup } from "reactstrap";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { ValidatedInputWithStatus } from "../forms/inputs/ValidatedInputWithStatus";
import { ValidatedInput } from "../forms/inputs/ValidatedInput";
import { SelectMembers } from "../selects/SelectMembers";
import classNames from "classnames";
import { FormFeedbackError } from "../forms/feedbacks/FormFeedbackError";
import { SelectComponentOptions } from "../selects/SelectComponent";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { useProjectGroupApi } from "../../hooks/project/ProjectGroupApiHook";
import { usePermissionGroupValidation } from "../../hooks/permission/PermissionGroupValidation";
import { IPermissionGroupFormFieldsModel } from "../../models/permissions/IPermissionGroupFormFieldsModel";

interface IPermissionGroupFormProps {
    initialValue: IPermissionGroupFormFieldsModel;
    onFormChange: (formData: IEntityFormData<IPermissionGroupFormFieldsModel>) => void;
    projectId: string;
}

export const PermissionGroupForm: React.FC<IPermissionGroupFormProps> = ({ initialValue, onFormChange, projectId }) => {
    const [listedUsers, setListedUsers] = useState<SelectComponentOptions<string>[]>([]);
    const { getAllProjectUsers } = useProjectGroupApi({ projectId });

    const { data, errors, validationStatuses, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: (fields) => usePermissionGroupValidation({ fields, projectId }),
    });

    const memberFieldChangeHandler = useCallback(
        (val: string[]) => {
            onChange("userIds", val);
        },
        [onChange],
    );

    const getListedUsers = useCallback(async () => {
        try {
            const availableUsers = await getAllProjectUsers();
            setListedUsers(
                availableUsers.map((user) => {
                    return {
                        label: user.email,
                        value: user.userId,
                        data: user,
                    };
                }),
            );
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [getAllProjectUsers]);

    useEffect(() => {
        getListedUsers();
    }, [getListedUsers]);

    return (
        <EntityForm name="create-permission-group" className="permission-group-form">
            <FormGroup>
                <FormGroup>
                    <LocalizedLabel aria-required="true" for="group-name" text="Common.Name" required />
                    <ValidatedInputWithStatus
                        id="group-name"
                        name="groupName"
                        type="text"
                        autoFocus
                        value={data.name}
                        error={errors.name}
                        isBusy={validationStatuses.name === "Validating"}
                        onChange={(e) => onChange("name", e.target.value)}
                    />
                </FormGroup>
            </FormGroup>
            <FormGroup>
                <LocalizedLabel for="group-description" text="Common.Description" />
                <ValidatedInput
                    id="group-description"
                    name="description"
                    type="textarea"
                    value={data.description}
                    error={errors.description}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("description", e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <SelectMembers
                    className={classNames({ "is-invalid": !!errors.userIds })}
                    value={data.userIds}
                    onChange={memberFieldChangeHandler}
                    options={listedUsers}
                    labelKey="Common.Users"
                    autoFocus={false}
                />
                {errors.userIds && <FormFeedbackError error={errors.userIds} />}
            </FormGroup>
        </EntityForm>
    );
};
