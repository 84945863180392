import { useCallback } from "react";
import { IContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { DiscoveryModel, WopiAction } from "../../models/wopi/IWopiModel";
import { getFileExtension } from "../../helpers/ContentFileHelper";
import { useAppConfigContext } from "../../contexts/AppConfigContext";

interface IWopiHookResult {
    findProduct: (item: IContentEntityModel, action: WopiAction) => DiscoveryModel | undefined;
}

export const useWopi = (): IWopiHookResult => {
    const { discoveryModels } = useAppConfigContext();

    const findProduct = useCallback(
        (item: IContentEntityModel, action: WopiAction) => {
            const extension = getFileExtension(item.name)!;
            if (extension.localeCompare("pdf", undefined, { sensitivity: "base" }) === 0) {
                return undefined;
            }
            // We check on discovery models after verifying that Wopi is enabled. If Wopi is enabled, discovery model records exist.
            return discoveryModels!.find(
                (e) => e.extension === extension && e.action === (WopiAction.Edit === action ? "edit" : "view"),
            );
        },
        [discoveryModels],
    );

    return {
        findProduct,
    };
};
