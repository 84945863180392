import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { Button } from "../../buttons/Button";
import { copiedDefaultTimeout } from "../../../Constants";
import { ShareLink } from "../../../models/contentManager/ContentManagerApiModels";
import { behaviorTranslationMap } from "../../../helpers/ContentFileShareHelper";

import "./EditItemShareBehavior.scss";

interface IEditItemShareBehaviorProps {
    isExpired: boolean;
    urlConfig: ShareLink;
    testSelectorValue?: string;
}

export const EditItemShareBehavior: React.FC<IEditItemShareBehaviorProps> = ({ isExpired, urlConfig, testSelectorValue }) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => setCopied(false), copiedDefaultTimeout);
            return () => clearTimeout(timeout);
        }
    }, [copied]);

    return (
        <CopyToClipboard text={urlConfig.url} onCopy={() => setCopied(true)}>
            <Button
                className={classNames("share-item-behavior", { disabled: isExpired })}
                color="ghost"
                ariaLabel={t(behaviorTranslationMap[urlConfig.behavior])}
                { ...setSelector(testSelectorValue) }
            >
                {!isExpired && <i className="fal fa-files" />}
                <span>{t(copied ? "Share.LinkCopied" : behaviorTranslationMap[urlConfig.behavior])}</span>
            </Button>
        </CopyToClipboard>
    );
};
