import React, { useEffect, useState } from "react";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { canSaveEntity } from "../../helpers/EntityHelper";
import { SaveButton } from "./SaveButton";

export const EditEntitySaveButton: React.FC = () => {
    const editEntityContext = useEditEntityContext();
    const {
        dirtyProps: { isDirty },
        validateNameProps: { isValidatingName },
        entityProps: { update, entityValid },
        errorProps: {
            errors: { name: nameErrorMessage },
        },
        savingProps: { blockSave, isSaving },
        permissionsProps: { canEdit },
    } = editEntityContext;
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        setCanSave(
            canSaveEntity({
                entityValid,
                isDirty,
                isSaving,
                isValidatingName,
                nameErrorMessage,
                blockSave,
                canEdit,
            }),
        );
    }, [blockSave, canEdit, entityValid, isDirty, isSaving, isValidatingName, nameErrorMessage]);

    return <SaveButton isSaving={isSaving} disabled={!canSave} onClick={() => void update()} />;
};
