import { useMemo } from "react";
import flattenDeep from "lodash/flattenDeep";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";

interface IUseDependenciesArgs {
    dependencies?: MultipleDependenciesResult;
}

interface UseDependenciesUtilsHookReturn {
    hasDependencies: boolean;
    hasRestrictedDependencies: boolean;
}

export const useDependenciesUtils = ({ dependencies }: IUseDependenciesArgs): UseDependenciesUtilsHookReturn => {
    const hasDependencies = useMemo(() => {
        if (!dependencies) {
            return false;
        }

        const allDependencies = Object.keys(dependencies).map((key) => {
            return dependencies[key];
        });

        return !!flattenDeep(allDependencies).length;
    }, [dependencies]);

    const hasRestrictedDependencies = useMemo(() => {
        if (!hasDependencies || !dependencies) {
            return false;
        }

        return Object.keys(dependencies).some((key) => {
            return dependencies[key].some((dep) => dep.restricted);
        });
    }, [dependencies, hasDependencies]);

    return {
        hasDependencies,
        hasRestrictedDependencies,
    };
};
