import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { LoadingModal } from "../modals/LoadingModal";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import { DefaultDeleteConfirmDialogBody } from "./DefaultDeleteConfirmDialogBody";
import { DependencyConfirmation } from "../dependencies/DependencyConfirmation";
import { InsufficentPermissionsDialog } from "./InsufficientPermissionsDialog";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { useEditEntityContext } from "../../contexts/EditEntityContext";
import { EntityType } from "../../models/EntityType";
import { EntityDependencyDeleteDialogBody } from "../dependencies/EntityDependencyDeleteDialogBody";
import { useDependenciesUtils } from "../../hooks/dependencies/DependencyUtilsHook";

export interface IEntityDependencyDeleteDialogProps {
    show: boolean;
    entityType: EntityType;
}

export const EntityDependencyDeleteDialog: React.FC<IEntityDependencyDeleteDialogProps> = ({
    show,
    entityType,
}: IEntityDependencyDeleteDialogProps) => {
    const [dependencies, setDependencies] = useState<MultipleDependenciesResult>();
    const editEntityContext = useEditEntityContext();

    const {
        entityProps: { delete: deleteEntity, getDependencies, entity },
        deleteDialogProps: { dialogBodyKey, dialogTitleKey, toggleCustomDelete },
    } = editEntityContext;

    const { hasDependencies, hasRestrictedDependencies } = useDependenciesUtils({ dependencies });

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [confirmed, setConfirmed] = useState<boolean>(false);

    useEffect(() => {
        setConfirmed(false);
    }, [dependencies, show]);

    const getEntityDependencies = useCallback(async () => {
        try {
            const entityDependencies = await getDependencies!();
            setDependencies(entityDependencies);
            setIsLoading(false);
        } catch (error) {
            defaultRequestErrorHandler(error);
            setIsLoading(false);
        }
    }, [getDependencies]);

    const onDelete = async () => {
        await deleteEntity(entity.name);
        toggleCustomDelete!();
    };

    useEffect(() => {
        if (!show) {
            return;
        }

        setIsLoading(true);
        getEntityDependencies();
    }, [show, getEntityDependencies]);

    return (
        <>
            <LoadingModal show={show && isLoading} labelKey="Loading.Dependencies" />
            {dependencies && hasRestrictedDependencies && (
                <InsufficentPermissionsDialog
                    show={show && !isLoading && !!dependencies && hasDependencies && hasRestrictedDependencies}
                    title={t("Permissions.Insufficient")}
                    onCancel={toggleCustomDelete!}
                    warningMessageKey={`${entityType}.Delete.Warning.Permissions`}
                    className="insufficent-permission-dialog"
                >
                    <EntityDependencyDeleteDialogBody dependencies={dependencies} entityType={entityType} />
                </InsufficentPermissionsDialog>
            )}
            <DeleteConfirmationDialog
                show={show && !isLoading && !hasRestrictedDependencies}
                title={t(dialogTitleKey)}
                onCancel={toggleCustomDelete!}
                onDelete={onDelete}
                confirmed={confirmed || !hasDependencies}
            >
                {hasDependencies && dependencies ? (
                    <>
                        <Trans
                            i18nKey={dialogBodyKey}
                            values={{
                                count: 1,
                            }}
                        />
                        <span>
                            <Trans i18nKey={`Dependency.${entityType}.DeleteWarning`} />
                        </span>
                        <span className="text-danger">
                            <Trans i18nKey="DeleteWarning.Permanent" />
                        </span>
                        <EntityDependencyDeleteDialogBody dependencies={dependencies} entityType={entityType} />
                        <DependencyConfirmation setConfirmation={setConfirmed} />
                    </>
                ) : (
                    <DefaultDeleteConfirmDialogBody confirmationBodyMessageKey={dialogBodyKey} count={1} />
                )}
            </DeleteConfirmationDialog>
        </>
    );
};
