import React from "react";
import classNames from "classnames";

import "./LabelCircle.scss";

export type LabelCircleColor = "gray" | "green" | "red" | null;

/**
 * The label circle props interface.
 */
export interface ILabelCircleProps {
    color: LabelCircleColor;
}

/**
 * The label circle functional component.
 */
export const LabelCircle: React.FunctionComponent<ILabelCircleProps> = (props: ILabelCircleProps): JSX.Element => {
    return <>
        <span className={classNames("rounded-circle", "label-circle", props.color !== null ? `color-${props.color}` : "")} />
    </>;
};