import { useState, useRef, useEffect } from "react";
import { NameValidator } from "../formValidators/NameValidator";
import { extractErrorMessageOrEmptyString } from "../helpers/ErrorHelper";

/**
 * The name is unique hook props interface.
 */
export interface INameIsUniqueHookProps {
    nameExistErrorKey: string;
    nameLeadingTrailingSpacesErrorKey?: string;
    nameIsUnique: (name: string) => Promise<boolean>;
    initialName?: string;
}

/**
 * The name is unique hook result interface.
 */
interface INameIsUniqueHookResult {
    isValidating: boolean;
    errorMessage: string;
    checkNameIsUnique: (name: string) => void;
}

/**
 * The use name is unique hook.
 */
export const useNameIsUnique = ({
    nameExistErrorKey,
    nameLeadingTrailingSpacesErrorKey,
    nameIsUnique,
    initialName,
}: INameIsUniqueHookProps): INameIsUniqueHookResult => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const nameValidator = useRef<NameValidator>(
        new NameValidator({
            nameIsUnique,
            nameExistErrorKey,
            nameLeadingTrailingSpacesErrorKey,
        }),
    );

    useEffect(() => {
        nameValidator.current.setInitialValue({ name: initialName ?? "" });
    }, [initialName]);

    const checkNameIsUnique = async (value: string) => {
        setIsValidating(true);
        setErrorMessage("");

        try {
            await nameValidator.current.validateName(value);
        } catch (error) {
            setErrorMessage(extractErrorMessageOrEmptyString(error));
        }

        setIsValidating(false);
    };

    return {
        errorMessage,
        isValidating,
        checkNameIsUnique: (value: string) => void checkNameIsUnique(value),
    };
};
