import React from "react";
import classNames from "classnames";
import { AvatarWrapper, IAvatarWrapperProps } from "../avatars/AvatarWrapper";

import "./ProjectAvatar.scss";

/**
 * The project avatar component.
 */
export const ProjectAvatar: React.FC<IAvatarWrapperProps> = ({ className, ...avatarProps }: IAvatarWrapperProps) => (
    <AvatarWrapper className={classNames("project-avatar", className)} {...avatarProps} />
);
