import React from "react";
import { FormGroup } from "reactstrap";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { TemplateFormValidator } from "../../../formValidators/TemplateFormValidator";
import { extractErrorMessageOrEmptyString } from "../../../helpers/ErrorHelper";
import { useTestSelector } from "../../../hooks/AutomatedTestsServiceHook";
import { ITemplateModel } from "../../../models/templates/ITemplateModel";
import { ValidatedInput } from "../../forms/inputs/ValidatedInput";
import { LocalizedLabel } from "../../forms/LocalizedLabel";
import { SelectDataSets } from "../../selects/SelectDataSets";

import "./EditTemplateViewTabsDetails.scss";

export const EditTemplateViewTabsDetails: React.FC = () => {
    const {
        entityProps: { entity: template, setEntityProperties, formValidator },
        errorProps: { errors, setErrors },
        permissionsProps: { canEdit },
    } = useEditEntityContext<ITemplateModel, TemplateFormValidator>();
    const { projectId } = useProjectContext();
    const { setSelector } = useTestSelector();

    const onDescriptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let errorMessage: string;

        try {
            setEntityProperties({ description: event.target.value });
            await formValidator.validateDescription(event.target.value);
            errorMessage = "";
        } catch (error) {
            errorMessage = extractErrorMessageOrEmptyString(error);
        }

        setErrors({ description: errorMessage });
    };

    const onDataSetsChange = (dataSets: string[]) => {
        setEntityProperties({ dataSetIds: dataSets });
    };

    return (
        <>
            <FormGroup className="entity-description">
                <LocalizedLabel for="create-template-description" text="Common.Description" />
                <ValidatedInput
                    id="create-template-description"
                    name="description"
                    type="textarea"
                    onChange={onDescriptionChange}
                    error={errors.description}
                    value={template.description}
                    disabled={!canEdit}
                    {...setSelector("descriptionTextbox")}
                />
            </FormGroup>
            <FormGroup className="entity-data-sets">
                <SelectDataSets
                    projectId={projectId}
                    selectedValues={template.dataSetIds}
                    onChange={onDataSetsChange}
                    disabled={!canEdit}
                />
            </FormGroup>
        </>
    );
};
