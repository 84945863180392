import { useCallback, useEffect, useRef } from "react";
import { DownloadHttpParams, HttpParams } from "../models/HttpParams";

export const useCancellableRequest = () => {
    const cancelRequestRef = useRef<Map<string, (() => void) | null>>(new Map());

    const cancellableRequest = useCallback(
        async <TReturn, TParams = never>(
            params: HttpParams<TParams> | DownloadHttpParams<TParams>,
            requestService: (params: HttpParams<TParams>) => Promise<TReturn>,
            id = params.url,
        ) =>
            new Promise<TReturn>((resolve, reject) => {
                // We want to controller the request to do nothing when the reqeust is aborted.
                // This promise will be sent to the GC when cancellableRequest is called again OR when the component is unmounted.
                cancelRequestRef.current.get(id)?.();

                const abortController = new AbortController();
                params.signal = abortController.signal;
                cancelRequestRef.current.set(id, () => abortController.abort());

                requestService(params)
                    .then((result) => {
                        cancelRequestRef.current.set(id, null);
                        resolve(result);
                    })
                    .catch((internalError) => {
                        if (internalError instanceof Error && internalError.name === "AbortError") {
                            return;
                        }

                        reject(internalError);
                    });
            }),
        [],
    );

    useEffect(() => {
        const currentMap = cancelRequestRef.current;
        return () => {
            currentMap.forEach((v) => {
                v?.();
            });
        };
    }, []);

    return {
        cancellableRequest,
    };
};
