import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classNames from "classnames";
import { DialogAction } from "./DialogAction";
import { Trans, useTranslation } from "react-i18next";
import { useHotkeys } from "react-hotkeys-hook";
import { DialogContext } from "../../contexts/DialogContext";
import { useDialog } from "../../hooks/dialog/Dialog";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useNavigationCloseHandler } from "../../hooks/modals/NavigationCloseHandlerHook";
import { Button } from "../buttons/Button";

import "../modals/EntityModal.scss";

/**
 * The dialog with context props interface.
 */
interface IDialogWithContextProps {
    testSelectorValueDialog?: string;
    children?: React.ReactNode;
}

/**
 * The dialog with context style interface.
 */
interface IDialogStyle {
    headerStyle?: string;
    icon?: string;
    proceedColor?: string;
    className?: string;
}

/**
 * The dialog with context component.
 */
export const DialogWithContext: React.FunctionComponent<IDialogWithContextProps> = (props: IDialogWithContextProps) => {
    const { t } = useTranslation();
    const { setSelector } = useTestSelector();
    const { dialogState, close, closed, proceed, show } = useDialog();
    const [proceeding, setProceeding] = useState(false);

    useEffect(() => {
        if (proceeding) {
            void proceed().then(() => {
                setProceeding(false);
            });
        }
    }, [proceed, proceeding]);

    const proceedHandler = useCallback(() => {
        setProceeding(true);
    }, []);

    useHotkeys(
        "ctrl+s, command+s",
        (e) => {
            e.preventDefault();
            void proceedHandler();
        },
        {
            enabled: dialogState.open && !dialogState.hideCancel,
            filter: () => document.activeElement?.tagName !== "BUTTON",
            filterPreventDefault: false,
        },
        [dialogState.open, proceedHandler],
    );

    useHotkeys(
        "escape",
        (e) => {
            e.preventDefault();
            close();
        },
        {
            enabled: dialogState.open,
        },
        [dialogState.open],
    );

    const closeButton = (
        <Button
            color="flat"
            type="button"
            className="close"
            onClick={close}
            description={t("Common.Close")}
            ariaLabel={t("Common.Close")}
        >
            <i className="fal fa-times" />
        </Button>
    );

    const getDialogStyle = (): IDialogStyle => {
        switch (dialogState.type) {
            case "Warning":
                return {
                    proceedColor: "primary",
                };
            case "Danger":
                return {
                    headerStyle: "danger-header",
                    icon: "fas fa-trash-alt",
                    proceedColor: "danger",
                };
            case "Delete": {
                return {
                    className: "delete-confirmation-dialog",
                    proceedColor: "danger",
                };
            }
            case "Info": {
                return {
                    proceedColor: "outline-secondary",
                };
            }
        }
    };

    const dialogStyle = getDialogStyle();

    useNavigationCloseHandler({ close, isOpen: dialogState.open });

    return (
        <>
            <DialogContext.Provider value={{ show, dialogState, close }}>
                {props.children}
                <Modal
                    isOpen={dialogState.open}
                    className={classNames("dialog", dialogStyle.className, dialogState.modalClassName ?? "")}
                    fade={true}
                    centered={true}
                    {...setSelector(props.testSelectorValueDialog)}
                    onClosed={closed}
                    size={dialogState.size}
                >
                    {dialogState.contentOverwriteModal ? (
                        dialogState.content
                    ) : (
                        <>
                            <ModalHeader
                                className={classNames("dialog-header", dialogStyle.headerStyle)}
                                close={closeButton}
                            >
                                {dialogStyle.icon && <i className={dialogStyle.icon} />}
                                <span>{t(dialogState.titleKey)}</span>
                            </ModalHeader>
                            <ModalBody>
                                {dialogState.content}
                                {dialogState.messageKey && (
                                    <span>
                                        <Trans i18nKey={dialogState.messageKey} />
                                    </span>
                                )}
                                {dialogState.type === "Delete" && (
                                    <p className="text-danger">
                                        <Trans i18nKey="DeleteWarning.Permanent" />
                                    </p>
                                )}
                            </ModalBody>
                            <ModalFooter>
                                {!dialogState.hideCancel && (
                                    <DialogAction
                                        color="secondary"
                                        outline={true}
                                        label={t(dialogState.cancelLabelKey)}
                                        testSelectorValue="cancelButton"
                                        onAction={close}
                                    />
                                )}
                                <DialogAction
                                    color={dialogStyle.proceedColor}
                                    label={t(dialogState.proceedLabelKey)}
                                    disabled={proceeding}
                                    loading={proceeding}
                                    testSelectorValue="confirmButton"
                                    onAction={() => void proceedHandler()}
                                />
                            </ModalFooter>
                        </>
                    )}
                </Modal>
            </DialogContext.Provider>
        </>
    );
};
