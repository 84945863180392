import { useCallback, useEffect, useReducer, useState } from "react";
import { ITableDeleteHelper, ITableFilterHelper, TableCreateHelper } from "../../components/table/Table";
import { ITableRowItem } from "../../components/table/TableRow";
import { useShortcutContext } from "../../contexts/ShortcutContext";
import { useTableRefreshContext } from "../../contexts/TableRefreshContext";
import { useUserContext } from "../../contexts/UserContext";
import { handleDeletedItemsNotification } from "../../helpers/TableHelper";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { createInitialState, createTableReducer, ITableState } from "./TableReducer";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";

/**
 * The load data type type.
 */
export type LoadDataType = "Load" | "Update";

interface UseTableResult<TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue> {
    tableState: ITableState<TEntityModel, TRowItem, TFilterValue>;
    onLoadData: (offset: number) => void;
    onUpdateData: () => void;
    onSelect: (itemId: string, overwriteSelection?: boolean) => void;
    onFilterChange: (filterValue: TFilterValue) => void;
    onSort: (sortField: ISortField) => void;
    canLoadMore: boolean;
    onLoadMore: () => void;
    onCreateEntity: (entity: TEntityModel) => void;
    onToggleCreateEntityModal: (isDisplayed: boolean) => void;
    onToggleDeleteEntityModal: (isDisplayed: boolean) => void;
    onDeleteEntities: () => Promise<void>;
    onSetItemsForDeletion: (itemIdsToDelete: string[]) => void;
    setIsFilterPanelOpen: (open: boolean) => void;
    selectAll: () => void;
    unselectAll: () => void;
}

/**
 * The use table hook.
 */
export const useTable = <TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue>(
    getRecords: (
        filterValue?: TFilterValue,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<TEntityModel>>,
    keyExtractor: (item: TEntityModel) => string,
    tableId: string,
    updateRecords?: (
        tableState: ITableState<TEntityModel, TRowItem, TFilterValue>,
    ) => Promise<IEntityResult<TEntityModel>>,
    triggerUpdate?: boolean,
    triggerRefresh?: boolean,
    nameExtractor?: (item: TEntityModel) => string,
    createHelper?: TableCreateHelper,
    deleteHelper?: ITableDeleteHelper<TEntityModel>,
    filterHelper?: ITableFilterHelper<TFilterValue>,
    defaultSortField?: ISortField,
    onSelectionChange?: (selectedItems: TEntityModel[]) => void,
    onLoadError?: (error: Error) => void,
    onLoaded?: () => void,
): UseTableResult<TEntityModel, TRowItem, TFilterValue> => {
    const { setGlobalShortcutEnabled } = useShortcutContext();
    const { subscribe, unsubscribe, refreshTable } = useTableRefreshContext();
    const [canUpdate, setCanUpdate] = useState(true);
    const { userId } = useUserContext();

    const [tableState, dispatch] = useReducer(
        createTableReducer<TEntityModel, TRowItem, TFilterValue>(keyExtractor),
        createInitialState<TEntityModel, TRowItem, TFilterValue>(
            tableId,
            userId,
            filterHelper?.defaultFilterValue,
            defaultSortField,
        ),
    );

    const mapItems = useCallback((results: IEntityResult<TEntityModel>): TRowItem[] => {
        return results.entities?.map(
            (item) =>
                ({
                    item,
                } as TRowItem),
        );
    }, []);

    const loadData = useCallback(
        async (loadType: LoadDataType, request: () => Promise<IEntityResult<TEntityModel>>) => {
            setCanUpdate(false);

            dispatch({ type: "SET_LOADING", payload: true });

            try {
                const results = await request();
                dispatch({
                    type: "SET_RESULTS",
                    payload: {
                        type: loadType,
                        filteredCount: results.filteredCount,
                        items: mapItems(results),
                        totalCount: results.totalCount,
                        offset: results.offset,
                    },
                });
            } catch (error) {
                onLoadError?.(error as Error);
                dispatch({ type: "SET_LOADING", payload: false });
            } finally {
                setCanUpdate(true);
                onLoaded && onLoaded();
            }
        },
        [mapItems, onLoadError, onLoaded],
    );

    const onLoadData = useCallback(
        (offset: number) => {
            const request = () => getRecords(tableState.filterValue, [tableState.sortField], offset);

            void loadData("Load", request);
        },
        [getRecords, tableState.filterValue, tableState.sortField, loadData],
    );

    const onUpdateData = useCallback(() => {
        if (!updateRecords) {
            return;
        }

        const request = () => updateRecords(tableState);
        void loadData("Update", request);
    }, [updateRecords, tableState, loadData]);

    const getEntityName = useCallback(() => {
        const entity = tableState.items.find(({ item }) => keyExtractor(item) === tableState.itemIdsToDelete[0]);
        if (!entity || !nameExtractor || tableState.itemIdsToDelete.length > 1) {
            return;
        }

        return nameExtractor(entity.item);
    }, [tableState.itemIdsToDelete, tableState.items, keyExtractor, nameExtractor]);

    const onToggleDeleteEntityModal = useCallback(
        (isDisplayed: boolean) => {
            dispatch({ type: "SET_DELETE_ENTITY_MODAL_DISPLAYED", payload: isDisplayed });
            setGlobalShortcutEnabled(!isDisplayed);
        },
        [setGlobalShortcutEnabled],
    );

    const onToggleCreateEntityModal = useCallback(
        (isDisplayed: boolean) => {
            dispatch({ type: "SET_CREATE_ENTITY_MODAL_DISPLAYED", payload: isDisplayed });
            setGlobalShortcutEnabled(!isDisplayed);
        },
        [setGlobalShortcutEnabled],
    );

    const onRefresh = useCallback(() => {
        dispatch({ type: "SET_REFRESH" });

        onLoadData(0);
    }, [onLoadData]);

    const onDeleteEntitiesCallback = useCallback(
        (errors: IDeleteErrorResult[]) => {
            onToggleDeleteEntityModal(false);

            const deletedIds = tableState.itemIdsToDelete.filter(
                (itemIdToDelete) => !errors.some(({ entityId }) => entityId === itemIdToDelete),
            );

            handleDeletedItemsNotification(
                deletedIds,
                errors,
                getEntityName(),
                deleteHelper!.notificationMessageKey,
                deleteHelper!.notificationMessageKeyParams,
            );

            // This event is used by the ContentManager ItemProperties side panel to handle dirty state when
            // the currrent items gets deleted. To allow bypass of the navigation blocker.
            // If we ever need to start using this anywhere else, we've got to consider refactoring this in a more
            // usable fashion first.
            const event = new CustomEvent("tableState.ItemsDeleted", { detail: deletedIds });
            document.dispatchEvent(event);

            // Give some time for the modal to be closed to update the state.
            setTimeout(() => {
                dispatch({ type: "ON_DELETE_ENTITIES", payload: errors });
                if (tableState.items.length - deletedIds.length === 0) {
                    onRefresh();
                }
            }, 200);

            deleteHelper!.onDeleted?.(deletedIds);
        },
        [
            onToggleDeleteEntityModal,
            tableState.itemIdsToDelete,
            tableState.items.length,
            getEntityName,
            deleteHelper,
            onRefresh,
        ],
    );

    const onDeleteEntities = useCallback(async () => {
        if (!deleteHelper) {
            return;
        }

        if (tableState.itemIdsToDelete.length === 1 && deleteHelper.singleDelete) {
            try {
                await deleteHelper.singleDelete(tableState.itemIdsToDelete[0]);
                onDeleteEntitiesCallback([]);
            } catch (error) {
                onToggleDeleteEntityModal(false);
                defaultRequestErrorHandler(error);
            }
        } else {
            const deleteErrorResult = await deleteHelper.deleteRecords(
                tableState.itemIdsToDelete,
                tableState.items.map((i) => i.item),
            );
            onDeleteEntitiesCallback(deleteErrorResult);
        }
    }, [
        onDeleteEntitiesCallback,
        onToggleDeleteEntityModal,
        deleteHelper,
        tableState.itemIdsToDelete,
        tableState.items,
    ]);

    const onSetItemsForDeletion = useCallback(
        (itemIdsToDelete: string[]) => {
            dispatch({ type: "SET_ITEMS_FOR_DELETION", payload: itemIdsToDelete });
            onToggleDeleteEntityModal(true);
        },
        [onToggleDeleteEntityModal],
    );

    const onSelect = useCallback(
        (itemId: string, overwriteSelection?: boolean) =>
            dispatch({ type: "SET_SELECTED_ITEM", payload: { itemId, overwriteSelection } }),
        [],
    );

    const onFilterChange = (filterValue: TFilterValue) => dispatch({ type: "SET_FILTER_VALUE", payload: filterValue });

    const onSort = (sortField: ISortField) => dispatch({ type: "SET_SORT_FIELD_VALUE", payload: sortField });

    const onLoadMore = useCallback(() => onLoadData(tableState.offset), [onLoadData, tableState.offset]);

    const onCreateEntity = useCallback(
        ({ ...props }: any) => {
            onToggleCreateEntityModal(false);

            if (!createHelper || createHelper.mode === "modal") {
                return;
            }

            if (createHelper.onCreated) {
                createHelper.onCreated(props);
            }

            onRefresh();
        },
        [createHelper, onRefresh, onToggleCreateEntityModal],
    );

    const setIsFilterPanelOpen = (open: boolean) => dispatch({ type: "SET_IS_FILTERED_OPEN", payload: open });

    useEffect(() => {
        onRefresh();
    }, [onRefresh]);

    useEffect(() => {
        if (triggerUpdate && canUpdate) {
            onUpdateData();
        }
    }, [triggerUpdate, onUpdateData, canUpdate]);

    useEffect(() => {
        if (triggerRefresh) {
            refreshTable(tableId);
        }
    }, [triggerRefresh, refreshTable, tableId]);

    useEffect(() => {
        const callback = () => {
            onRefresh();
        };

        subscribe(tableId, callback);
        return () => {
            unsubscribe(tableId, callback);
        };
    }, [onRefresh, subscribe, tableId, unsubscribe]);

    const currentOffset =
        filterHelper?.isActive && filterHelper.isActive(tableState.filterValue)
            ? tableState.filteredCount
            : tableState.totalCount;
    const canLoadMore = !tableState.isLoading && tableState.offset < currentOffset;

    const unselectAll = useCallback(() => {
        dispatch({ type: "ON_MASS_SELECT_ITEMS", payload: [] });
    }, []);

    const selectAll = useCallback(() => {
        dispatch({ type: "ON_MASS_SELECT_ITEMS", payload: tableState.items.map((i) => keyExtractor(i.item)) });
    }, [keyExtractor, tableState.items]);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(
                tableState.items
                    .filter((i) => tableState.selectedItemIds.indexOf(keyExtractor(i.item)) !== -1)
                    .map((ir) => ir.item),
            );
        }
    }, [keyExtractor, onSelectionChange, tableState.items, tableState.selectedItemIds]);

    return {
        tableState,
        onLoadData,
        onUpdateData,
        onSelect,
        onFilterChange,
        onSort,
        canLoadMore,
        onLoadMore,
        onCreateEntity,
        onToggleCreateEntityModal,
        onToggleDeleteEntityModal,
        onDeleteEntities,
        onSetItemsForDeletion,
        setIsFilterPanelOpen,
        selectAll,
        unselectAll,
    };
};
