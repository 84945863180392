import { createContext, useContext } from "react";

/**
 * The sidebar context props.
 */
export interface ISidebarContextProps {
    refreshSidebar: boolean;
    setRefreshSidebar: (triggerSidebarRefresh: boolean) => void;
}

/**
 * The sidebar context.
 */
export const SidebarContext = createContext<ISidebarContextProps | null>(null);

/**
 * The sidebar context for functional component.
 */
export const useSidebarContext = (): ISidebarContextProps => useContext(SidebarContext) as ISidebarContextProps;
