import React from "react";
import { useTranslation } from "react-i18next";
import {
    ServiceHookEventType,
    ServiceHookEventTypeTranslationMap,
} from "../../models/serviceHooks/ServiceHookEventType";

interface IServiceHookTypeLabelProps {
    eventType: ServiceHookEventType;
}

export const ServiceHookEventTypeLabel: React.FC<IServiceHookTypeLabelProps> = ({ eventType }) => {
    const { t } = useTranslation();

    return <>{t(ServiceHookEventTypeTranslationMap[eventType])}</>;
};
