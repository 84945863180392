import React from "react";
import { useTranslation } from "react-i18next";
import { dependencyTypeMap } from "../../helpers/DependencyMapHelper";

interface IDepedencyTableTypeItemProps {
    entityType: string;
}

export const DependencyTableTypeItem: React.FC<IDepedencyTableTypeItemProps> = ({
    entityType,
}: IDepedencyTableTypeItemProps) => {
    const { t } = useTranslation();

    return <>{t(`Common.${dependencyTypeMap[entityType]}`)}</>;
};
