import React from "react";
import { Link } from "react-router-dom";

import "./ReturnSection.scss";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

interface IReturnSectionProps {
    route: string;
    label: string;
    testSelectorValue?: string
}

export const ReturnSection: React.FC<IReturnSectionProps> = ({ route, label, testSelectorValue }) => {
    const { setSelector } = useTestSelector();

    return (
        <section className="return-section">
            <Link to={route}>
                <i className="far fa-rotate-left" { ...setSelector(testSelectorValue) } />
                {label}
            </Link>
        </section>
    );
};
