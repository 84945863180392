import { DateWithTimeZone } from "../DateWithTimeZone";
import { ContentManagerEntityType } from "../EntityType";
import { IUserDisplayModel } from "../account/IUserDisplayModel";

export interface IContentEntityModel {
    id: string;
    name: string;
    description: string | null;
    type: ContentManagerEntityType;
    size: number | null;
    modifiedBy: IUserDisplayModel;
    modifiedOn: string;
    createdBy: IUserDisplayModel;
    createdOn: string;
    path: string;
}

export interface ISubFolderModel {
    name: string;
    id: string;
    hasChildren: boolean;
}

export interface ICreateFolderModel {
    name: string;
    parentFolderId: string | null;
}

export interface IContentSummarizeModel {
    filesUploadContentLength: number;
    affectedFolders: IContentFolderUploadModel[];
}

export interface IContentFolderUploadModel {
    contentFolderId: string;
    name: string;
    hasChildren: boolean;
    parentContentFolderId: string | null;
}

export interface IUpdateContentEntityModel {
    name: string;
    description: string | null;
}

export interface IContentEntityActionModel {
    targetedEntities: ContentEntityIdType[];
    destinationId: string | null;
}

export enum ContentEntityType {
    File = 0,
    Folder = 1,
}

export interface ContentEntityIdType {
    id: string;
    type: ContentEntityType;
}

export interface ContentEntityIdTypeName extends ContentEntityIdType {
    name: string;
}

export type ContentFileShareExpirationDate = string | null;

export type ContentFileShareExpirationDateModel = DateWithTimeZone | null;

// These values are used as bit flags
export enum ContentFileShareBehaviors {
    View = 1,
    Download = 2,
}

export interface ShareLink {
    behavior: ContentFileShareBehaviors;
    url: string;
}

export interface IContentFileShareModel {
    contentFileShareId: string;
    contentFileId: string;
    key: string;
    shareLinks: ShareLink[];
    expirationDate: ContentFileShareExpirationDate;
    createdBy: IUserDisplayModel;
    createdOn: string;
    expired: boolean;
    timeZoneId: string;
}

export interface IUpdateContentFileShareModel {
    expirationDate: ContentFileShareExpirationDateModel;
}

export interface ICreateContentFileShareModel {
    behaviors: ContentFileShareBehaviors;
    contentFileId: string;
    expirationDate: ContentFileShareExpirationDateModel;
}
