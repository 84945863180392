import React, { useMemo } from "react";
import { FormGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import { EntityForm } from "../forms/EntityForm";
import { useCreateModalEntity } from "../../hooks/CreateModalEntityHook";
import { IEntityFormData } from "../../models/entity/form/IEntityFormData";
import { LocalizedLabel } from "../forms/LocalizedLabel";
import { SelectComponent, SelectComponentOptions } from "../selects/SelectComponent";
import { ITemplateLanguageModel } from "../../models/Languages/ITemplateLanguageModel";

interface ITemplateLanguageSelectFormProps {
    onFormChange: (formData: IEntityFormData<{ languageCode: string }>) => void;
    initialValue: { languageCode: string };
    languageList: ITemplateLanguageModel[];
}

export const TemplateLanguageSelectForm: React.FC<ITemplateLanguageSelectFormProps> = ({
    onFormChange,
    initialValue,
    languageList,
}: ITemplateLanguageSelectFormProps) => {
    const { t } = useTranslation();
    const { data, onChange } = useCreateModalEntity({
        initialData: initialValue,
        onFormChange,
        useValidation: () => {
            return {
                valid: true,
                errors: { languageCode: "" },
                validationStatuses: { languageCode: "Valid" },
                setError: () => {
                    return { language: "" };
                },
            };
        },
        isDirty: () => false,
    });

    const listedLanguages: SelectComponentOptions<string>[] = useMemo(() => {
        return languageList.map((l) => ({
            value: l.code,
            label: l.isDefault ? `${l.name} [${t("Language.Default")}]` : l.name,
        }));
    }, [languageList, t]);

    return (
        <EntityForm name="template-language-select" className="addTemplateLanguageSelectForm">
            <FormGroup>
                <LocalizedLabel for="select-languages" text="EntityType.Languages_plural" />
                <SelectComponent
                    id="select-languages"
                    onChange={(lang: string) => onChange("languageCode", lang)}
                    options={listedLanguages}
                    size="large"
                    value={data.languageCode}
                />
            </FormGroup>
        </EntityForm>
    );
};
