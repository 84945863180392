import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputWithScope } from "../forms/inputs/InputWithScope";
import { useMobileMediaQuery } from "../../hooks/MobileMediaQueryHook";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

const searchInputTrottle = 500;

/**
 * The search input props interface.
 */
export interface ISearchInputProps {
    onValueChange: (value: string) => void;
    value?: string;
    onBlur: () => void;
    searchScope: string;
}

/**
 * The search input functional component.
 */
export const SearchInput: React.FunctionComponent<ISearchInputProps> = ({
    value,
    onValueChange,
    onBlur,
    searchScope,
}: ISearchInputProps): JSX.Element => {
    const { t } = useTranslation();
    const [internalValue, setInternalValue] = useState(value || "");
    const canTriggerOnValueChange = useRef(false);
    const isMobile = useMobileMediaQuery();
    const { setSelector } = useTestSelector();

    const placeholder = t("Common.Search");

    useEffect(() => {
        if (!canTriggerOnValueChange.current) {
            return;
        }

        const currentTimeout = setTimeout(() => {
            onValueChange(internalValue);
        }, searchInputTrottle);

        return () => clearTimeout(currentTimeout);
    }, [internalValue, onValueChange]);

    useEffect(() => {
        canTriggerOnValueChange.current = false;
        setInternalValue(value || "");
    }, [value]);

    const handleValueChange = (newValue: string): void => {
        canTriggerOnValueChange.current = true;
        setInternalValue(newValue);
    };

    const onClickCheck = useCallback(
        (e: MouseEvent | TouchEvent) => {
            const inputDiv = document.querySelector(".input-with-scope-group");
            const searchIcon = document.querySelector(".search-trigger");
            const clearIcon = document.querySelector(".clear-icon");

            if (
                (!isMobile && e.target === clearIcon) ||
                e.target === searchIcon ||
                inputDiv?.contains(e.target as Node)
            ) {
                return;
            } else {
                onBlur();
            }
        },
        [isMobile, onBlur],
    );

    useEffect(() => {
        window.addEventListener("pointerdown", onClickCheck);
        return () => {
            window.removeEventListener("pointerdown", onClickCheck);
        };
    }, [onClickCheck]);

    return (
        <InputWithScope
            label={placeholder}
            onValueChange={handleValueChange}
            placeholder={placeholder}
            scope={t(searchScope)}
            value={internalValue}
            autoFocus
            {...setSelector("dropdownFilter")}
        />
    );
};
