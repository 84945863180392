import React from "react";
import classNames from "classnames";

import "./View.scss";

/**
 * The edit view props interface.
 */
interface IEditViewProps {
    className?: string;
    fullscreen?: boolean;
    children?: React.ReactNode;
}

/**
 * The edit view section component.
 */
export const EditView: React.FunctionComponent<IEditViewProps> = (props: IEditViewProps) => {
    const { className, children, fullscreen } = props;

    return <section className={classNames("edit-view", className, { fullscreen })}>{children}</section>;
};
