import React from "react";
import { Filter } from "../filter/Filter";
import { FilterInput } from "../filter/FilterInput";

const throttleFilterValue: number = 500;

/**
 * The default table filter props.
 */
interface IDefaultTableFilterProps {
    placeHolderNameKey: string;
    value: string;
    onChange: (value: string) => void;
}

/**
 * The default table filter component.
 */
export const DefaultTableFilter: React.FC<IDefaultTableFilterProps> = ({
    placeHolderNameKey,
    value,
    onChange,
}: IDefaultTableFilterProps) => {
    return (
        <Filter active={!!value} onClear={() => onChange("")} clearDisabled={!value}>
            <FilterInput
                value={value}
                throttle={throttleFilterValue}
                placeHolderName={placeHolderNameKey}
                onValueChange={onChange}
            />
        </Filter>
    );
};
