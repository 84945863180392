import { useCallback } from "react";
import { ITemplateModel } from "../../models/templates/ITemplateModel";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import { ICreateTemplateModel } from "../../models/templates/ICreateTemplateModel";
import { ICloneTemplateModel } from "../../models/templates/ICloneTemplateModel";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { ISortField } from "../../models/ISortField";
import { IEntityResult } from "../../models/IEntityResult";
import { ITemplateWithDataSetsModel } from "../../models/templates/ITemplateWithDataSetsModel";
import { IHistoryModel } from "../../models/history/IHistoryModel";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { IMultipleDependenciesRequest } from "../../models/dependencies/IMultipleDependenciesRequest";
import { ITemplateLanguageModel } from "../../models/Languages/ITemplateLanguageModel";

/**
 * The template api hook return.
 */
interface TemplateApiHookReturn {
    create: (createTemplateModel: ICreateTemplateModel) => Promise<ITemplateModel>;
    clone: (cloneTemplateModel: ICloneTemplateModel) => Promise<ITemplateModel>;
    remove: (templateId: string) => Promise<void>;
    removeRecords: (ids: string[]) => Promise<IDeleteErrorResult[]>;
    generateUniqueName: (templateId: string) => Promise<{ name: string }>;
    get: (templateId: string) => Promise<ITemplateModel>;
    getDependenciesRecords: (
        datasetId: string,
        dependencyType: DependencyType,
        offset?: number,
        sortFields?: ISortField[],
        filterValue?: string,
    ) => Promise<IEntityResult<IDependencyModel>>;
    getMultipleDependencies: (ids: string[], dependencyType: DependencyType) => Promise<MultipleDependenciesResult>;
    getHistoryRecords: (
        templateId: string,
        updatedEntityDate?: string,
        offset?: number,
    ) => Promise<IEntityResult<IHistoryModel>>;
    getLanguageRecords: (
        templateId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<ITemplateLanguageModel>>;
    getRecords: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<ITemplateWithDataSetsModel>>;
    nameIsUnique: (name: string) => Promise<boolean>;
    update: (template: ITemplateModel) => Promise<ITemplateModel>;
    getAvailableLanguages: (templateId: string) => Promise<ITemplateLanguageModel[]>;
    downloadDesignDocument: (templateId: string, languageCode: string, fileName: string) => Promise<void>;
}

/**
 * The use template api hook.
 */
export const useTemplateApi = (projectId: string): TemplateApiHookReturn => {
    const baseUrl = useCallback(
        (templateId?: string) => `/_api/project/${projectId}/templates${templateId ? "/" + templateId : ""}`,
        [projectId],
    );

    const { httpGetJson, httpPost, httpPut, httpDelete, httpDeleteResponseJson, httpDownload } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const create = useCallback(
        (createTemplateModel: ICreateTemplateModel) =>
            cancellableRequest<ITemplateModel, ICreateTemplateModel>(
                { url: baseUrl(), body: createTemplateModel },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost],
    );

    const clone = useCallback(
        (cloneTemplateModel: ICloneTemplateModel) =>
            cancellableRequest<ITemplateModel, ICloneTemplateModel>(
                { url: `${baseUrl()}/clone`, body: cloneTemplateModel },
                httpPost,
            ),
        [baseUrl, cancellableRequest, httpPost],
    );

    const remove = useCallback(
        (templateId: string) => cancellableRequest({ url: baseUrl(templateId) }, httpDelete),
        [baseUrl, httpDelete, cancellableRequest],
    );

    const removeRecords = useCallback(
        (ids: string[]) =>
            cancellableRequest<IDeleteErrorResult[], string[]>({ url: baseUrl(), body: ids }, httpDeleteResponseJson),
        [baseUrl, cancellableRequest, httpDeleteResponseJson],
    );

    const generateUniqueName = useCallback(
        (templateId: string): Promise<{ name: string }> =>
            cancellableRequest<{ name: string }>(
                { url: `${baseUrl()}/getuniquename`, queryParams: { templateId } },
                httpGetJson,
            ),
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const get = useCallback(
        (templateId: string) => cancellableRequest<ITemplateModel>({ url: baseUrl(templateId) }, httpGetJson),
        [baseUrl, httpGetJson, cancellableRequest],
    );

    const getDependenciesRecords = useCallback(
        (
            templateId: string,
            dependencyType: DependencyType,
            offset?: number,
            sortFields?: ISortField[],
            filterValue?: string,
        ): Promise<IEntityResult<IDependencyModel>> => {
            const filters: Record<string, string> = {};

            if (filterValue) {
                filters.EntityName = filterValue;
                filters.EntityType = filterValue;
            }

            return cancellableRequest<IEntityResult<IDependencyModel>>(
                {
                    url: `${baseUrl(templateId)}/dependencies`,
                    queryParams: normalizeQueryParams(
                        {
                            filters,
                            sortFields,
                            offset,
                            mode: dependencyType,
                        },
                        true,
                        "EntityName",
                    ),
                },
                httpGetJson,
            );
        },
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const getMultipleDependencies = useCallback(
        (ids: string[], dependencyType: DependencyType) => {
            return cancellableRequest<MultipleDependenciesResult, IMultipleDependenciesRequest>(
                { url: `${baseUrl()}/multipleDependencies`, body: { entityIds: ids, mode: dependencyType } },
                httpPost,
            );
        },
        [baseUrl, cancellableRequest, httpPost],
    );

    const getHistoryRecords = useCallback(
        (templateId: string, updatedEntityDate?: string, offset?: number) => {
            const filters: Record<string, string> = {};

            if (updatedEntityDate) {
                filters.lastUpdatedDate = updatedEntityDate;
            }

            return cancellableRequest<IEntityResult<IHistoryModel>>(
                {
                    url: `${baseUrl()}/history/${templateId}`,
                    queryParams: normalizeQueryParams({
                        filters,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [baseUrl, httpGetJson, cancellableRequest],
    );

    const getLanguageRecords = useCallback(
        (templateId: string, filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Code = filterValue;
            }

            return cancellableRequest<IEntityResult<ITemplateLanguageModel>>(
                {
                    url: `${baseUrl(templateId)}/languages`,
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const getRecords = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Description = filterValue;
            }

            return cancellableRequest<IEntityResult<ITemplateWithDataSetsModel>>(
                {
                    url: baseUrl(),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [baseUrl, httpGetJson, cancellableRequest],
    );

    const nameIsUnique = useCallback(
        (name: string) =>
            cancellableRequest<boolean>({ url: `${baseUrl()}/nameisunique`, queryParams: { name } }, httpGetJson),
        [baseUrl, httpGetJson, cancellableRequest],
    );

    const update = useCallback(
        (template: ITemplateModel) =>
            cancellableRequest<ITemplateModel, ITemplateModel>(
                { url: `${baseUrl(template.templateId)}`, body: template },
                httpPut,
            ),
        [baseUrl, httpPut, cancellableRequest],
    );

    const getAvailableLanguages = useCallback(
        (templateId: string) =>
            cancellableRequest<ITemplateLanguageModel[]>(
                { url: `${baseUrl(templateId)}/availableLanguages` },
                httpGetJson,
            ),
        [baseUrl, cancellableRequest, httpGetJson],
    );

    const downloadDesignDocument = useCallback(
        (templateId: string, languageCode: string, fileName: string) =>
            cancellableRequest<void, string>(
                { url: `${baseUrl(templateId)}/design/${languageCode}`, fileName: fileName },
                httpDownload,
            ),
        [baseUrl, cancellableRequest, httpDownload],
    );

    return {
        create,
        clone,
        remove,
        removeRecords,
        generateUniqueName,
        get,
        getDependenciesRecords,
        getMultipleDependencies,
        getHistoryRecords,
        getLanguageRecords,
        getRecords,
        nameIsUnique,
        update,
        getAvailableLanguages,
        downloadDesignDocument,
    };
};
