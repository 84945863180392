import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSearchService } from "../../hooks/search/SearchServiceHook";
import { ISearchResult } from "../../models/search/ISearchResult";
import { SearchNavbarPanel } from "./SearchNavbarPanel";
import { SearchNavbarResults } from "./SearchNavbarResults";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";
import { useNavBarSearchContext } from "../../contexts/NavBarSearchContext";

import "./SearchNavbarItem.scss";

/**
 * The search navbar item props interface.
 */
interface ISearchNavbarItemProps {
    onSearch: (searchQuery: string) => Promise<ISearchResult[]>;
    transform: (element: ISearchResult[]) => JSX.Element[];
    searchScopeKey: string;
    isPanelVisible: boolean;
}

/**
 * The search navbar item functional component.
 */
export const SearchNavbarItem: React.FC<ISearchNavbarItemProps> = ({
    searchScopeKey,
    onSearch,
    transform,
    isPanelVisible,
}: ISearchNavbarItemProps) => {
    const [loading, setLoading] = useState(false);
    const [isResultVisible, setIsResultVisible] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const { clearResults, searchResults, search } = useSearchService({ onSearch, transform });
    const { setIsPanelVisible } = useNavBarSearchContext();
    const { setSelector } = useTestSelector();

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            void (async (): Promise<void> => {
                setLoading(true);
                setIsResultVisible(true);
                clearResults();

                await search(searchValue.trim());

                setLoading(false);
            })();
        } else {
            setIsResultVisible(false);
        }
    }, [clearResults, searchValue, search]);

    const onBlur = () => {
        if (!searchValue) {
            setIsPanelVisible(false);
        }
    };

    useEffect(() => {
        setIsPanelVisible(isResultVisible);
    }, [setIsPanelVisible, isResultVisible]);

    return (
        <Dropdown
            className="search-navbar"
            isOpen={isResultVisible}
            {...setSelector("globalSearchDropdown")}
            toggle={() => {
                setIsResultVisible(false);
            }}
        >
            <DropdownToggle tag="div">
                <SearchNavbarPanel
                    isVisible={isPanelVisible}
                    onBlur={onBlur}
                    onValueChange={setSearchValue}
                    searchValue={searchValue}
                    searchScopeKey={searchScopeKey}
                />
            </DropdownToggle>
            <DropdownMenu end={true} className="search-input-wrapper">
                <SearchNavbarResults loading={loading} searchResults={searchResults} searchValue={searchValue} />
            </DropdownMenu>
        </Dropdown>
    );
};
