import { ITableRowItem } from "../../components/table/TableRow";
import { getItem, setItem } from "../../helpers/LocalStoragHelper";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { ISortField } from "../../models/ISortField";
import { SavedFilter } from "../../models/SavedFilter";
import { SortOrder } from "../../models/SortOrder";
import { LoadDataType } from "./Table";

export type SetResultsAction = "SET_RESULTS";

export type SetUpdateResultsAction = "SET_UPDATE_RESULTS";

export type SetResultsActions = SetResultsAction | SetUpdateResultsAction;

interface IBaseAction<T> {
    payload: T;
}

interface ISetLoadingAction extends IBaseAction<boolean> {
    type: "SET_LOADING";
}

interface ISetResultsAction<TEntityModel, TRowItem extends ITableRowItem<TEntityModel>>
    extends IBaseAction<{
        type: "Load" | "Update";
        items: TRowItem[];
        filteredCount: number;
        totalCount: number;
        offset: number;
    }> {
    type: SetResultsAction;
}

interface ISetSelectedItemAction extends IBaseAction<{ itemId: string; overwriteSelection?: boolean }> {
    type: "SET_SELECTED_ITEM";
}

interface ISetRefreshAction {
    type: "SET_REFRESH";
}

interface ISetFilterValueAction<TFilterValue> extends IBaseAction<TFilterValue> {
    type: "SET_FILTER_VALUE";
}

interface ISetSortFieldValueAction extends IBaseAction<ISortField> {
    type: "SET_SORT_FIELD_VALUE";
}

interface ISetCreateEntityModalDisplayedAction extends IBaseAction<boolean> {
    type: "SET_CREATE_ENTITY_MODAL_DISPLAYED";
}

interface ISetDeleteEntityModalDisplayedAction extends IBaseAction<boolean> {
    type: "SET_DELETE_ENTITY_MODAL_DISPLAYED";
}

interface ISetItemsForDeletionAction extends IBaseAction<string[]> {
    type: "SET_ITEMS_FOR_DELETION";
}

interface ISetIsFilteredDisplayedAction extends IBaseAction<boolean> {
    type: "SET_IS_FILTERED_OPEN";
}

interface IOnDeleteEntitiesAction extends IBaseAction<IDeleteErrorResult[]> {
    type: "ON_DELETE_ENTITIES";
}

interface IOnMassSelectItems extends IBaseAction<string[]> {
    type: "ON_MASS_SELECT_ITEMS";
}

/**
 * The table action types.
 */
export type TableActionTypes<TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue> =
    | ISetLoadingAction
    | ISetResultsAction<TEntityModel, TRowItem>
    | ISetSelectedItemAction
    | ISetRefreshAction
    | ISetFilterValueAction<TFilterValue>
    | ISetSortFieldValueAction
    | ISetCreateEntityModalDisplayedAction
    | ISetDeleteEntityModalDisplayedAction
    | ISetItemsForDeletionAction
    | ISetIsFilteredDisplayedAction
    | IOnDeleteEntitiesAction
    | IOnMassSelectItems;

/**
 * The table state interface.
 */
export interface ITableState<TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue> {
    type: LoadDataType;
    items: TRowItem[];
    filteredCount: number;
    totalCount: number;
    selectedItemIds: string[];
    isLoading: boolean;
    filterValue: TFilterValue;
    sortField: ISortField;
    offset: number;
    isCreateModalDisplayed: boolean;
    isDeleteModalDisplayed: boolean;
    itemIdsToDelete: string[];
    tableId: string;
    isFilterPanelOpen: boolean;
    userId: string;
}

/**
 * The create initial state.
 */
export const createInitialState = <TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue>(
    tableId: string,
    userId: string,
    defaultFilterValue?: TFilterValue,
    defaultSortField?: ISortField,
): ITableState<TEntityModel, TRowItem, TFilterValue> => {
    const savedFilter = getItem<SavedFilter<TFilterValue>>(userId, tableId);

    // Sometimes, the data stored in LocalStorage does not match the one used by the app
    // this happens maintly when adding a field to the TFilterValue of an entity, this code makes sure
    // that we always have the necessary, otherwise we would need to make every field optional.
    if (savedFilter && savedFilter.filterValue && defaultFilterValue) {
        for (const key in defaultFilterValue) {
            // we do a direct comparison instead of if(!savedFilter.filterValue[key]) since the stored value could
            // be "" which is a valid value, but would be considered true in this case and overwritten by the default option
            if (savedFilter.filterValue[key] === undefined) {
                savedFilter.filterValue[key] = defaultFilterValue[key];
            }
        }
    }

    return {
        type: "Load",
        filteredCount: 0,
        items: [],
        selectedItemIds: [],
        totalCount: 0,
        isLoading: true,
        filterValue: savedFilter?.filterValue ?? defaultFilterValue!,
        sortField: savedFilter?.sortField ??
            defaultSortField ?? {
                name: "Name",
                order: SortOrder.Asc,
            },
        offset: 0,
        isCreateModalDisplayed: false,
        isDeleteModalDisplayed: false,
        itemIdsToDelete: [],
        tableId,
        isFilterPanelOpen: Boolean(savedFilter?.isFilterPanelOpen),
        userId,
    };
};

/**
 * The create table reducer.
 */
export const createTableReducer = <TEntityModel, TRowItem extends ITableRowItem<TEntityModel>, TFilterValue>(
    keyExtractor: (item: TEntityModel) => string,
): ((
    state: ITableState<TEntityModel, TRowItem, TFilterValue>,
    action: TableActionTypes<TEntityModel, TRowItem, TFilterValue>,
) => ITableState<TEntityModel, TRowItem, TFilterValue>) => {
    return (
        state: ITableState<TEntityModel, TRowItem, TFilterValue>,
        action: TableActionTypes<TEntityModel, TRowItem, TFilterValue>,
    ) => {
        switch (action.type) {
            case "SET_LOADING":
                return {
                    ...state,
                    isLoading: action.payload,
                };
            case "SET_RESULTS": {
                let items: TRowItem[] = [];
                if (action.payload.type === "Load") {
                    items = state.offset === 0 ? action.payload.items : [...state.items, ...action.payload.items];
                } else {
                    items = [...action.payload.items, ...state.items];
                }

                return {
                    ...state,
                    type: action.payload.type,
                    items,
                    filteredCount: action.payload.filteredCount,
                    totalCount: action.payload.totalCount,
                    isLoading: false,
                    offset: action.payload.offset,
                    selectedItemIds: state.selectedItemIds.filter((selectedItemId) =>
                        items.some(({ item }) => keyExtractor(item) === selectedItemId),
                    ),
                };
            }
            case "SET_SELECTED_ITEM":
                if (action.payload.overwriteSelection) {
                    return {
                        ...state,
                        selectedItemIds: [action.payload.itemId],
                    };
                }

                return {
                    ...state,
                    selectedItemIds: !state.selectedItemIds.find(
                        (selectedItemId) => selectedItemId === action.payload.itemId,
                    )
                        ? [...state.selectedItemIds, action.payload.itemId]
                        : state.selectedItemIds.filter((selectedItemId) => selectedItemId !== action.payload.itemId),
                };
            case "SET_REFRESH":
                return {
                    ...state,
                    offset: 0,
                };
            case "SET_FILTER_VALUE": {
                const { userId, tableId, sortField, isFilterPanelOpen } = state;

                setItem<SavedFilter<TFilterValue>>(userId, tableId, {
                    isFilterPanelOpen,
                    filterValue: action.payload,
                    sortField,
                });

                return {
                    ...state,
                    filterValue: action.payload,
                    offset: 0,
                    isLoading: true,
                };
            }
            case "SET_SORT_FIELD_VALUE": {
                const { userId, tableId, filterValue, isFilterPanelOpen } = state;

                setItem<SavedFilter<TFilterValue>>(userId, tableId, {
                    isFilterPanelOpen,
                    filterValue,
                    sortField: action.payload,
                });

                return {
                    ...state,
                    sortField: action.payload,
                    offset: 0,
                    isLoading: true,
                };
            }
            case "SET_CREATE_ENTITY_MODAL_DISPLAYED":
                return {
                    ...state,
                    isCreateModalDisplayed: action.payload,
                };
            case "SET_DELETE_ENTITY_MODAL_DISPLAYED":
                return {
                    ...state,
                    isDeleteModalDisplayed: action.payload,
                };
            case "SET_ITEMS_FOR_DELETION":
                return {
                    ...state,
                    itemIdsToDelete: action.payload,
                };
            case "ON_DELETE_ENTITIES": {
                const successSelectedItemIds: string[] = state.itemIdsToDelete.filter(
                    (selectedItemId) => !action.payload.some((error) => error.entityId === selectedItemId),
                );

                return {
                    ...state,
                    isDeleteModalDisplayed: false,
                    selectedItemIds: state.selectedItemIds.filter((id) => successSelectedItemIds.indexOf(id) === -1),
                    itemIdsToDelete: [],
                    items: state.items.filter(
                        ({ item }) =>
                            !successSelectedItemIds.some((selectedItemId) => selectedItemId === keyExtractor(item)),
                    ),
                    offset: state.offset - successSelectedItemIds.length,
                    totalCount: state.totalCount - successSelectedItemIds.length,
                    filteredCount: state.filteredCount - successSelectedItemIds.length,
                };
            }
            case "SET_IS_FILTERED_OPEN": {
                const { userId, tableId, sortField, filterValue } = state;

                setItem<SavedFilter<TFilterValue>>(userId, tableId, {
                    isFilterPanelOpen: action.payload,
                    filterValue,
                    sortField,
                });

                return {
                    ...state,
                    isFilterPanelOpen: action.payload,
                };
            }
            case "ON_MASS_SELECT_ITEMS": {
                return {
                    ...state,
                    selectedItemIds: action.payload,
                };
            }
            default:
                return state;
        }
    };
};
