import React from "react";
import classNames from "classnames";

import "./ViewBody.scss";

/**
 * The edit view body props interface.
 */
interface IEditViewBodyProps {
    className?: string;
    children?: React.ReactNode;
}

/**
 * The edit view body section component.
 */
export const EditViewBody: React.FunctionComponent<IEditViewBodyProps> = (props: IEditViewBodyProps) => {
    const { className, children } = props;

    return <section className={classNames("edit-view-body", className)}>{children}</section>;
};