import React, { useState } from "react";
import classNames from "classnames";
import CreatableSelect from "react-select/creatable";
import { useCallback } from "react";
import { ActionMeta, InputActionMeta, Options } from "react-select";

import "./SelectEmailsComponent.scss";

/**
 * The IOption interface.
 */
export interface IOption {
    label: string;
    value: string;
}

/**
 * The select emails component props interface.
 */
interface ISelectEmailsComponentProps {
    autoFocus?: boolean;
    placeholder?: string;
    className?: string;
    size?: "large";
    onChange: (values: string[]) => void;
    id: string;
}

/**
 * A function to help in the creation of IOption element.
 */
const createOption = (label: string): IOption => {
    return {
        label,
        value: label,
    };
};

/**
 * The select emails functional component.
 */
export const SelectEmailsComponent: React.FunctionComponent<ISelectEmailsComponentProps> = ({
    autoFocus,
    className,
    placeholder,
    size,
    onChange,
    id,
}: ISelectEmailsComponentProps) => {
    const [values, setValues] = useState<Options<IOption>>([]);
    const [inputValue, setInputValue] = useState<string>("");

    const onEmailsChange = useCallback(
        (emails: Options<IOption>): void => {
            const emailsList = emails.map((option: IOption) => option.value);

            onChange(emailsList);
        },
        [onChange],
    );

    const addInputValue = useCallback((): void => {
        const inputValues = inputValue.split(/[\s,;]+/).map((value: string) => {
            return createOption(value);
        });
        const newValues = [...values, ...inputValues].filter(
            (email, index, emails) =>
                email.label && emails.findIndex((element) => element.label === email.label) === index,
        );

        if (values.length !== newValues.length) {
            setValues(newValues);
            onEmailsChange(newValues);
        }
        setInputValue("");
    }, [inputValue, values, onEmailsChange]);

    const handleValuesChange = useCallback(
        (value: Options<IOption>, action: ActionMeta<any>) => {
            if (!value) {
                return;
            }

            setValues(value);
            setInputValue("");

            onEmailsChange(value);
        },
        [onEmailsChange],
    );

    const handleInputChange = useCallback((newValue: string, actionMeta: InputActionMeta) => {
        setInputValue(newValue);
    }, []);

    const handleBlur = useCallback(() => {
        if (!inputValue) {
            return;
        }

        addInputValue();
    }, [inputValue, addInputValue]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            switch (event.key) {
                case "Enter":
                case "Tab":
                case ";":
                case ",":
                case " ":
                    if (inputValue) {
                        event.preventDefault();
                        event.nativeEvent.stopImmediatePropagation();
                        addInputValue();
                    }

                    break;
            }
        },
        [inputValue, addInputValue],
    );

    return (
        <>
            <CreatableSelect
                className={classNames("select-emails-component", className, size)}
                // eslint-disable-next-line @typescript-eslint/naming-convention
                components={{ DropdownIndicator: null }}
                autoFocus={autoFocus}
                isClearable
                menuIsOpen={false}
                onChange={handleValuesChange}
                onInputChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                backspaceRemovesValue={true}
                isMulti={true}
                placeholder={placeholder}
                inputValue={inputValue}
                value={values}
                inputId={id}
            />
        </>
    );
};
