import React from "react";
import classNames from "classnames";
import { EntityType } from "../../models/EntityType";
import { EntityTypeClassName, LightEntityTypeClassName } from "../../models/EntityTypeClassName";

type IconWeight = "regular" | "light";

/**
 * The entity icon props.
 */
export interface IEntityIconProps {
    type: EntityType;
    weight?: IconWeight;
}

export const iconSets = new Map<EntityType, EntityTypeClassName>([
    ["Organization", EntityTypeClassName.Organization],
    ["Project", EntityTypeClassName.Project],
    ["System.Form", EntityTypeClassName.DataSetForm],
    ["System.Json", EntityTypeClassName.DataSetJson],
    ["System.Xml", EntityTypeClassName.DataSetXml],
    ["DataSetSample", EntityTypeClassName.DataSetSample],
    ["DataSet", EntityTypeClassName.DataSet],
    ["ApiKey", EntityTypeClassName.ApiKey],
    ["Template", EntityTypeClassName.Template],
    ["Template.Standard", EntityTypeClassName.TemplateStandard],
    ["TemplateLanguage", EntityTypeClassName.Template],
    ["Language", EntityTypeClassName.Language],
    ["ServiceHook", EntityTypeClassName.ServiceHook],
]);

export const lightIconSets = new Map<EntityType, LightEntityTypeClassName>([
    ["Organization", LightEntityTypeClassName.Organization],
    ["Project", LightEntityTypeClassName.Project],
    ["System.Form", LightEntityTypeClassName.DataSetForm],
    ["System.Json", LightEntityTypeClassName.DataSetJson],
    ["System.Xml", LightEntityTypeClassName.DataSetXml],
    ["DataSetSample", LightEntityTypeClassName.DataSetSample],
    ["DataSet", LightEntityTypeClassName.DataSet],
    ["ApiKey", LightEntityTypeClassName.ApiKey],
    ["Template", LightEntityTypeClassName.Template],
    ["Template.Standard", LightEntityTypeClassName.TemplateStandard],
    ["TemplateLanguage", LightEntityTypeClassName.Template],
    ["Language", LightEntityTypeClassName.Language],
    ["ServiceHook", LightEntityTypeClassName.ServiceHook],
]);

const getIcon = (type: EntityType, weight: IconWeight) =>
    weight === "regular" ? iconSets.get(type) : lightIconSets.get(type);

/**
 * The entity icon component.
 */
export const EntityIcon: React.FC<IEntityIconProps> = ({ type, weight = "regular" }: IEntityIconProps) => {
    return <i className={classNames("entity-icon", getIcon(type, weight))} />;
};
