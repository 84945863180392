import React from "react";
import classNames from "classnames";
import { UserGroupTypeMapping } from "../../models/user/IUserGroups";
import { IUserAvatarProps, UserAvatar } from "./UserAvatar";
import colorStyles from "../../assets/scss/custom/_colorExports.module.scss";

interface IUserGroupAvatarProps extends IUserAvatarProps {
    groupName: string;
}

const userGroupTypeMapping: Record<string, { name: string; color: string }> = {
    [UserGroupTypeMapping.applicationAdmin]: { name: UserGroupTypeMapping.applicationAdmin, color: colorStyles.green },
    [UserGroupTypeMapping.organizationAdmin]: { name: UserGroupTypeMapping.organizationAdmin, color: colorStyles.blue },
    [UserGroupTypeMapping.projectOwner]: { name: UserGroupTypeMapping.projectOwner, color: colorStyles.blue },
    [UserGroupTypeMapping.projectAdmin]: { name: UserGroupTypeMapping.projectAdmin, color: colorStyles.darkblue },
    [UserGroupTypeMapping.projectContributor]: {
        name: UserGroupTypeMapping.projectContributor,
        color: colorStyles.green,
    },
    [UserGroupTypeMapping.projectViewer]: { name: UserGroupTypeMapping.projectViewer, color: colorStyles.darkgreen },
    [UserGroupTypeMapping.contentContributor]: {
        name: UserGroupTypeMapping.contentContributor,
        color: colorStyles.cyan,
    },
};

/**
 * The user group avatar component.
 */
export const UserGroupAvatar: React.FC<IUserGroupAvatarProps> = ({
    className,
    groupName,
    ...rest
}: IUserGroupAvatarProps) => {
    const customProps = userGroupTypeMapping[groupName] || { name: groupName };

    return <UserAvatar className={classNames("user-group", className)} {...customProps} {...rest} />;
};
