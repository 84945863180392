import { useCallback, useEffect, useState } from "react";
import { usePermissionContext } from "../../contexts/PermissionContext";
import { PermissionState } from "./PermissionHook";

export interface PermissionCheckHookReturn {
    permissions: { [index: string]: PermissionState };
    isAllowed: (permissionKey: string) => boolean;
    isDenied: (permissionKey: string) => boolean;
    isUnknown: (permissionKey: string) => boolean;
    resetPermission: (permissionKey: string) => void;
}

interface PermissionCheckHookProps {
    permissionKeys: string[];
}

export const usePermissionCheck = ({ permissionKeys }: PermissionCheckHookProps): PermissionCheckHookReturn => {
    const { on, get } = usePermissionContext();
    const [permissions, setPermissions] = useState<{ [index: string]: PermissionState }>({});

    useEffect(() => {
        const unsubscribeArray = permissionKeys.map((permissionKey) => {
            return on(permissionKey, (s) => {
                setPermissions(({ [permissionKey]: _, ...rest }) => ({
                    ...rest,
                    [permissionKey]: s,
                }));
            });
        });

        return () => unsubscribeArray.forEach((unsubscribe) => unsubscribe());
    }, [on, permissionKeys]);

    const isAllowed = useCallback(
        (permissionKey: string): boolean => permissions[permissionKey] === "allowed",
        [permissions],
    );
    const isDenied = useCallback(
        (permissionKey: string): boolean => permissions[permissionKey] === "denied",
        [permissions],
    );
    const isUnknown = useCallback(
        (permissionKey: string): boolean => permissions[permissionKey] === "unknown",
        [permissions],
    );

    const resetPermission = useCallback(
        (permissionKey: string): void => {
            setPermissions(({ [permissionKey]: _, ...rest }) => ({
                ...rest,
                [permissionKey]: "unknown",
            }));
            get(permissionKey);
        },
        [get],
    );

    return {
        permissions,
        isAllowed,
        isDenied,
        isUnknown,
        resetPermission,
    };
};
