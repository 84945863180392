import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ITableRowItem } from "../table/TableRow";
import { useTableContext } from "../table/TableContext";
import { IDataSetFilter } from "../dataSets/DataSetView";
import { defaultRequestErrorHandler } from "../../helpers/ErrorHelper";
import { LoadingModal } from "../modals/LoadingModal";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import { DefaultDeleteConfirmDialogBody } from "./DefaultDeleteConfirmDialogBody";
import { DependencyConfirmation } from "../dependencies/DependencyConfirmation";
import { InsufficentPermissionsDialog } from "./InsufficientPermissionsDialog";
import { DependencyType } from "../../models/dependencies/DependencyType";
import { MultipleDependenciesResult } from "../../models/dependencies/MultipleDependencyResult";
import { TableDependencyDeleteDialogBody } from "../dependencies/TableDependencyDeleteDialogBody";
import { useDependenciesUtils } from "../../hooks/dependencies/DependencyUtilsHook";

export interface ITableDependencyDeleteDialogProps {
    onDeleteEntities: () => Promise<void>;
    getDependencies?: (ids: string[], dependencyType: DependencyType) => Promise<MultipleDependenciesResult>;
}

export const TableDependencyDeleteDialog: React.FC<ITableDependencyDeleteDialogProps> = <TEntity extends object>({
    onDeleteEntities,
    getDependencies,
}: ITableDependencyDeleteDialogProps) => {
    const context = useTableContext<TEntity, ITableRowItem<TEntity>, IDataSetFilter>();
    const [dependencies, setDependencies] = useState<MultipleDependenciesResult>();

    const { t } = useTranslation();

    const { hasDependencies, hasRestrictedDependencies } = useDependenciesUtils({ dependencies });

    const deleteHelper = context?.deleteHelper;

    const entityType = (deleteHelper?.entityType as string) || "";

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [confirmed, setConfirmed] = useState<boolean>(false);

    const selectedItems = useMemo(() => {
        if (context) {
            return context.tableState.items.filter((item) =>
                context.tableState.itemIdsToDelete.includes(context.keyExtractor(item.item)),
            );
        }
    }, [context]);

    const selectedItemIds = useMemo(() => {
        if (context && selectedItems) {
            return selectedItems.map((item) => context.keyExtractor(item.item));
        }
    }, [context, selectedItems]);

    const displayDeleteModal = !!context?.tableState.isDeleteModalDisplayed;

    useEffect(() => {
        setConfirmed(false);
    }, [dependencies, displayDeleteModal]);

    const getEntityDependencies = useCallback(async () => {
        try {
            const entityDependencies = await getDependencies!(selectedItemIds!, "usedBy");
            setDependencies(entityDependencies);
            setIsLoading(false);
        } catch (error) {
            defaultRequestErrorHandler(error);
            setIsLoading(false);
        }
    }, [getDependencies, selectedItemIds]);

    useEffect(() => {
        if (!selectedItemIds || selectedItemIds.length === 0 || !displayDeleteModal) {
            return;
        }

        setIsLoading(true);
        getEntityDependencies();
    }, [displayDeleteModal, getEntityDependencies, selectedItemIds]);

    return (
        <>
            <LoadingModal show={displayDeleteModal && isLoading} labelKey="Loading.Dependencies" />
            {dependencies && hasRestrictedDependencies && (
                <InsufficentPermissionsDialog
                    show={
                        displayDeleteModal &&
                        !isLoading &&
                        !!dependencies &&
                        hasDependencies &&
                        hasRestrictedDependencies
                    }
                    title={t("Permissions.Insufficient")}
                    onCancel={() => context?.onToggleDeleteEntityModal(false)}
                    warningMessageKey={`${entityType}.Delete.Warning.Permissions`}
                    className="insufficent-permission-dialog"
                >
                    <TableDependencyDeleteDialogBody dependencies={dependencies} />
                </InsufficentPermissionsDialog>
            )}
            {deleteHelper && (
                <DeleteConfirmationDialog
                    show={displayDeleteModal && !isLoading && !hasRestrictedDependencies}
                    title={t(deleteHelper.confirmationTitleMessageKey, {
                        count: context?.tableState.itemIdsToDelete.length,
                        ...deleteHelper.confirmationTitleMessageKeyParams,
                    })}
                    onCancel={() => context.onToggleDeleteEntityModal(false)}
                    onDelete={onDeleteEntities}
                    dialogIcon={deleteHelper.dialogIcon}
                    deleteLabel={deleteHelper.deleteLabel}
                    confirmed={confirmed || !hasDependencies}
                >
                    {hasDependencies && dependencies ? (
                        <>
                            <Trans
                                i18nKey={deleteHelper.confirmationBodyMessageKey}
                                values={{
                                    count: selectedItems?.length,
                                }}
                            />
                            <span>
                                <Trans
                                    i18nKey={`Dependency.${entityType}.DeleteWarning`}
                                    values={{ count: selectedItems?.length }}
                                />
                            </span>
                            <span className="text-danger">
                                <Trans i18nKey="DeleteWarning.Permanent" />
                            </span>
                            <TableDependencyDeleteDialogBody dependencies={dependencies} />
                            <DependencyConfirmation setConfirmation={setConfirmed} />
                        </>
                    ) : (
                        <DefaultDeleteConfirmDialogBody
                            confirmationBodyMessageKey={deleteHelper.confirmationBodyMessageKey}
                            count={context?.tableState.itemIdsToDelete.length}
                            confirmationBodyMessageKeyParams={deleteHelper.confirmationBodyMessageKeyParams}
                        />
                    )}
                </DeleteConfirmationDialog>
            )}
        </>
    );
};
