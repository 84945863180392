import React from "react";
import { useTranslation } from "react-i18next";
import ExperlogixDocumentsLogo from "../../assets/img/icons/ExperlogixDocumentsText.svg";
import { FormattedDate } from "../date/FormattedDate";

import "./AboutInformations.scss";

export const AboutInformations: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="about-info">
            <img alt="Experlogix Documents Logo" className="about-info__logo" src={ExperlogixDocumentsLogo} />
            <div className="about-info__details-wrapper">
                <div>
                    {t("About.Version")} {process.env.REACT_APP_VERSION ?? "0.0.0"}
                </div>
                {process.env.REACT_APP_BUILD && (
                    <div>
                        {t("About.Build")} {process.env.REACT_APP_BUILD}
                    </div>
                )}
                {process.env.REACT_APP_RELEASE_DATE && (
                    <div>
                        {t("About.ReleaseDate")} <FormattedDate date={process.env.REACT_APP_RELEASE_DATE} />
                    </div>
                )}
                <div>{t("About.Copyright")}</div>
            </div>
        </div>
    );
};
