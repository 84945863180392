import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardSubtitle, CardText, CardTitle } from "reactstrap";
import { IRecentProjectModel } from "../../models/project/IRecentProjectModel";
import { ProjectAvatar } from "./ProjectAvatar";
import { projectPaths } from "../../PathConstants";

import "./RecentProjectCard.scss";

/**
 * The recent project card props interface.
 */
interface IRecentProjectCardProps {
    className?: string;
    recentProject: IRecentProjectModel;
}

/**
 * The recent project card component.
 */
export const RecentProjectCard: React.FunctionComponent<IRecentProjectCardProps> = (props: IRecentProjectCardProps) => {
    const { className, recentProject } = props;

    return (
        <Card className={classNames("recent-project", className)}>
            <div className="card-avatar">
                <ProjectAvatar name={recentProject.name} />
            </div>
            <CardBody className="text-truncate">
                <CardTitle>
                    <Link to={projectPaths.link.dashboard(recentProject.organizationName, recentProject.name)}>
                        {recentProject.name}
                    </Link>
                </CardTitle>
                <CardSubtitle>{recentProject.organizationName}</CardSubtitle>
                <CardText tag="div">{recentProject.description}</CardText>
            </CardBody>
        </Card>
    );
};
