import { createContext, useContext } from "react";

export type TableRefreshContextProps = {
    refreshTable: (tableId: string, refreshAllSubscribed?: boolean) => void;
    subscribe: (id: string, callback: (refreshAllSubscribed?: boolean) => void) => void;
    unsubscribe: (id: string, callback: (refreshAllSubscribed?: boolean) => void) => void;
};

export const TableRefreshContext = createContext<TableRefreshContextProps | null>(null);

export const useTableRefreshContext = (): TableRefreshContextProps =>
    useContext(TableRefreshContext) as TableRefreshContextProps;
