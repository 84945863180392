import { useEffect, useMemo, useState } from "react";
import { useMatches } from "react-router-dom";
import { OverridenUseMatches } from "../crumbs/Crumbs";
import { PermissionKeys } from "../../PermissionKeyConstants";
import { usePermissionCheck } from "./PermissionCheckHook";
import { formatPermissionKey } from "../../helpers/PermissionKeyHelper";

interface IUseManageGroupMembersPermissionKeyProps {
    projectId?: string;
    organizationId?: string;
}

export const useManageGroupMembersPermissionKey = ({
    projectId,
    organizationId,
}: IUseManageGroupMembersPermissionKeyProps) => {
    const scope = (useMatches() as OverridenUseMatches).filter((m) => m.handle?.scope).map((m) => m.handle?.scope)[0];
    const [canManage, setCanManage] = useState(false);

    const canManageKey = useMemo(() => {
        switch (scope) {
            case "appSettings":
                return PermissionKeys.app.manage;
            case "organization":
                return formatPermissionKey(PermissionKeys.organization.write, organizationId);
            case "project":
                return formatPermissionKey(PermissionKeys.project.managePermissions, undefined, projectId);
            default:
                return "";
        }
    }, [scope, organizationId, projectId]);

    const permissionsObject = useMemo(() => {
        return { permissionKeys: [canManageKey] };
    }, [canManageKey]);

    const { isAllowed } = usePermissionCheck(permissionsObject);

    useEffect(() => {
        setCanManage(() => isAllowed(canManageKey));
    }, [canManageKey, isAllowed]);
    return {
        canManage,
    };
};
