import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigation } from "../../../hooks/NavigationHook";
import { personalDataPaths } from "../../../PathConstants";
import { NotificationService } from "../../../services/NotificationService";
import { Button } from "../../buttons/Button";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";

import "./ManagePersonalDataForm.scss";

/**
 * The manage personal data form component.
 */
export const ManagePersonalDataForm: React.FC = () => {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { downloadPersonalData } = useAccountApi();

    const onDownloadPersonalData = useCallback(async () => {
        try {
            await downloadPersonalData();
            NotificationService.addSuccessNotification({
                messageKey: "PersonalData.Download.Success",
            });
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [downloadPersonalData]);

    return (
        <section className="manage-personal-data">
            <p>{t("PersonalData.Description1")}</p>
            <p>
                <Trans i18nKey="PersonalData.Description2" />
            </p>
            <div className="buttons-wrapper">
                <Button color="primary" onClick={onDownloadPersonalData} ariaLabel={t("PersonalData.Download")}>
                    <i className="far fa-arrow-to-bottom" />
                    {t("PersonalData.Download")}
                </Button>
                <Button
                    color="danger"
                    onClick={() => navigate(personalDataPaths.route.delete)}
                    ariaLabel={t("PersonalData.Delete")}
                >
                    <i className="far fa-trash-alt" />
                    {t("PersonalData.Delete")}
                </Button>
            </div>
        </section>
    );
};
