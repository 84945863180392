import { IAuditableEntity } from "../IAuditableEntity";
import { IDefaultEntityProps } from "../IDefaultEntityProps";

export enum OrganizationLicenseState {
    None = 0,
    Active = 1,
    Inactive = 2,
    Expired = 3,
}

export const OrganizationLicenseStateTranslationMap = {
    [OrganizationLicenseState.None]: "Licensing.LicenseState.Unlicensed",
    [OrganizationLicenseState.Active]: "Licensing.LicenseState.Active",
    [OrganizationLicenseState.Inactive]: "Licensing.LicenseState.Inactive",
    [OrganizationLicenseState.Expired]: "Licensing.LicenseState.Expired",
} as const;

/**
 * The organization model interface.
 */
export interface IOrganizationModel extends IDefaultEntityProps, IAuditableEntity {
    organizationId: string;
    licenseKey: string | null;
    licenseState: OrganizationLicenseState;
    licenseExpirationDate: string | null;
    licenseActivatedDate: string | null;
}
