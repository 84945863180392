import { SelectComponentOptions } from "../selects/SelectComponent";
import { EntityTypeClassName } from "../../models/EntityTypeClassName";
import { DataSetType } from "../../models/dataSets/DataSetType";

/**
 * The select component data set options.
 */
export const SelectComponentDataSetOptions: SelectComponentOptions<DataSetType>[] = [
    {
        value: "System.Form",
        label: "Common.Form",
        icon: EntityTypeClassName.DataSetForm,
        testSelectorValue: "formItem",
    },
    {
        value: "System.Json",
        label: "Common.Json",
        icon: EntityTypeClassName.DataSetJson,
        testSelectorValue: "jsonItem",
    },
    { value: "System.Xml", label: "Common.Xml", icon: EntityTypeClassName.DataSetXml, testSelectorValue: "xmlItem" },
];
