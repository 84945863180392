import React from "react";
import classNames from "classnames";
import { Table } from "reactstrap";
import { EntityTableBody } from "./EntityTableBody";
import { EntityTableHeader } from "./EntityTableHeader";
import { IEntityTableColumnDef } from "../../models/IEntityTableColumnDef";
import { EntityTableRow } from "./EntityTableRow";
import { useRenderItems } from "../../hooks/table/RenderItemsHook";

interface IReadOnlyTableProps<TEntityModel> {
    className: string;
    columnDefs: IEntityTableColumnDef[];
    content: TEntityModel[];
    rowDisabled?: boolean;
    keyExtractor?: (item: TEntityModel) => string;
    borderless: boolean;
}

/**
 * The read only table component.
 */
export const ReadOnlyTable = <TEntityModel extends Record<string, any>>({
    className,
    columnDefs,
    content,
    keyExtractor,
    rowDisabled = false,
    borderless = false,
}: IReadOnlyTableProps<TEntityModel>): JSX.Element => {
    const renderItems = useRenderItems();
    return (
        <Table className={classNames("entity-table", className)} responsive={true} borderless={borderless}>
            <EntityTableHeader columnDefs={columnDefs} selectable={false} />
            <EntityTableBody>
                {content.map((item: TEntityModel, index) => {
                    return (
                        <EntityTableRow rowIndex={index} key={index}>
                            {columnDefs.map((columnDef: IEntityTableColumnDef) => {
                                return (
                                    <td key={columnDef.fieldName}>
                                        {renderItems(columnDef, {
                                            item,
                                            itemKey: keyExtractor ? keyExtractor(item) : "",
                                            rowDisabled,
                                        })}
                                    </td>
                                );
                            })}
                        </EntityTableRow>
                    );
                })}
            </EntityTableBody>
        </Table>
    );
};
