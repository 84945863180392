import React from "react";
import classNames from "classnames";
import { Modal, ModalProps } from "reactstrap";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The expandable modal props interface.
 */
export interface IExpandableModalProps extends ModalProps {
    expanded: boolean;
    testSelectorValue?: string;
    children?: React.ReactNode;
}

/**
 * The expandable modal function component.
 */
export const ExpandableModal: React.FunctionComponent<IExpandableModalProps> = (props: IExpandableModalProps) => {
    const { className, expanded, testSelectorValue, ...modalProps } = props;

    const { setSelector } = useTestSelector();

    return <Modal className={classNames("expandable-modal", className, { expanded })} {...modalProps}  {...setSelector(testSelectorValue) }/>;
};