import { atom } from "jotai";
import { FoldersTree } from "../models/contentManager/FolderTree";
import { atomWithRouter } from "./AtomWithRouter";
import { atomWithReducer } from "jotai/vanilla/utils";

export const SearchQueryParamsName = "search";

interface ContentManagerLoadingModalState {
    show: boolean;
    label: string;
    onCancel?: () => void;
}

interface IOpenModal {
    type: "OPEN_MODAL";
    payload: {
        label: string;
        onCancel?: () => void;
    };
}

interface ICloseModal {
    type: "CLOSE_MODAL";
}

interface ICancelModal {
    type: "CANCEL_MODAL";
}

type ModalAction = IOpenModal | ICloseModal | ICancelModal;

const loadingModalReducer = (
    prev: ContentManagerLoadingModalState,
    action: ModalAction,
): ContentManagerLoadingModalState => {
    switch (action.type) {
        case "OPEN_MODAL":
            return {
                show: true,
                label: action.payload.label,
                onCancel: action.payload.onCancel,
            };
        case "CLOSE_MODAL":
            return {
                ...prev,
                show: false,
                onCancel: undefined,
            };
        case "CANCEL_MODAL": {
            prev.onCancel?.();
            return {
                ...prev,
                show: false,
                onCancel: undefined,
            };
        }
    }
};

// Internal atoms
const internalSearchQueryAtom = atom<string>("");

// Primitive atoms
export const selectedFolderIdAtom = atom<null | string>(null);
export const foldersTreeAtom = atom<FoldersTree>([]);
export const searchValueAtom = atom<string>("");
export const translatableExtensionsAtom = atom<string[] | null>(null);
export const loadingModalStateAtom = atomWithReducer<ContentManagerLoadingModalState, ModalAction>(
    {
        show: false,
        label: "",
    },
    loadingModalReducer,
);
export const contentViewFolderTreeNeedRefreshAtom = atom(true);

// Derived atoms
export const searchQueryAtom = atom<string, [string], void>(
    (get) => get(internalSearchQueryAtom),
    (_, set, searchValue) => {
        set(internalSearchQueryAtom, searchValue);
        set(searchValueAtom, searchValue);
    },
);

export const applySearchAtom = atom<null, [string], void>(null, (get, set, searchValue) => {
    const params = new URLSearchParams(window.location.search);

    if (!searchValue || params.get(SearchQueryParamsName) === searchValue) {
        return;
    }

    const replace = params.has(SearchQueryParamsName);

    params.set(SearchQueryParamsName, searchValue);

    set(
        atomWithRouter,
        {
            ...get(atomWithRouter),
            searchParams: params,
            encode: true,
        },
        { replace },
    );
});

export const searchScopeAtom = atom<string | null>((get) => {
    const folderTree = get(foldersTreeAtom);
    const selectedFolderId = get(selectedFolderIdAtom);

    if (selectedFolderId === null) {
        return null;
    }

    const selectedFolder = folderTree.find((x) => x.id === selectedFolderId);

    if (!selectedFolder) {
        return null;
    }

    return selectedFolder.name;
});

export const searchStatusAtom = atom<boolean>((get) => {
    const currentSearch = get(searchQueryAtom);
    return currentSearch.length > 0;
});

export const translatableExtensionReadyAtom = atom<boolean>((get) => {
    return get(translatableExtensionsAtom) !== null;
});

// onMount
searchQueryAtom.onMount = (setAtom) => () => setAtom("");
searchValueAtom.onMount = (setAtom) => () => setAtom("");
foldersTreeAtom.onMount = (setAtom) => () => setAtom([]);
translatableExtensionsAtom.onMount = (setAtom) => () => setAtom(null);
selectedFolderIdAtom.onMount = (setAtom) => () => setAtom(null);
contentViewFolderTreeNeedRefreshAtom.onMount = (setAtom) => () => setAtom(true);
