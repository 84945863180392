import * as Yup from "yup";
import * as YupType from "yup/lib/types";

/**
 * The abstract class of a FormValidator.
 */
export abstract class FormValidator<T> {
    private static readonly detectTrailingSpaces: RegExp = /^\s|\s$/;
    protected initialValue?: T;
    protected abstract schema: Yup.BaseSchema<any>;

    public static valueHasTrailingSpaces(value: string): boolean {
        return FormValidator.detectTrailingSpaces.test(value);
    }

    public validate(data: T): Promise<any> {
        return this.schema.validate(data);
    }

    public isValid(data: T, options?: YupType.ValidateOptions<YupType.AnyObject>): Promise<boolean> {
        return this.schema.isValid(data, options);
    }

    public setInitialValue(data: T): void {
        this.initialValue = data;
    }
}
