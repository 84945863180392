import { serialize } from "cookie";

export interface ICookieConfig {
    name: string;
    value: string;
    expirationDays?: number;
}

/**
 * The cookie helper class.
 */
export class CookieHelper {
    public static setValue({ name, value, expirationDays }: ICookieConfig) {
        document.cookie =
            `${encodeURIComponent(name)}=${encodeURIComponent(value)}` +
            (expirationDays !== undefined
                ? `; expires=${new Date(Date.now() + expirationDays * 24 * 60 * 60 * 1000).toUTCString()}`
                : "");
    }

    public static getValue(name: string): string {
        if (!document.cookie) {
            return "";
        }

        const cookieValue = document.cookie
            .split(";")
            .map((val) => {
                const index = val.indexOf("=");
                return {
                    name: val.substring(0, index).trim(),
                    value: val.substring(index + 1),
                };
            })
            .filter((item) => item.name === name)
            .map((item) => item.value);

        if (cookieValue.length) {
            return decodeURIComponent(cookieValue[0]);
        }

        return "";
    }

    public static remove(cookieName: string): void {
        if (!document.cookie) {
            return;
        }

        document.cookie = serialize(cookieName, "", { expires: new Date(1970, 1, 1, 0, 0, 1), path: "/" });
    }
}
