import { useEffect, useMemo, useState } from "react";
import { useEntityErrors } from "../entity/EntityErrorsHook";
import { useEntityNameValidation } from "../entity/validation/EntityNameValidationHook";
import { NameValidator } from "../../formValidators/NameValidator";
import { EntityType } from "../../models/EntityType";
import { IUpdateContentEntityModel } from "../../models/contentManager/ContentManagerApiModels";
import { IContentManagerRenameFormFields } from "../../models/contentManager/ContentManagerRenameFormFields";
import { useContentFileApi } from "./ContentFileApiHook";
import { useContentFolderApi } from "./ContentFolderApiHook";

interface UseContentManagerRenameValidation {
    fields: IUpdateContentEntityModel;
    projectId: string;
    parentFolderId: string;
    contentType: EntityType;
}

export const useContentManagerRenameValidation = ({
    fields,
    projectId,
    parentFolderId,
    contentType,
}: UseContentManagerRenameValidation) => {
    const { nameIsUnique: folderNameIsUnique } = useContentFolderApi(projectId);
    const { nameIsUnique: fileNameIsUnique } = useContentFileApi(projectId);

    const nameIsUnique = contentType === "Folder" ? folderNameIsUnique : fileNameIsUnique;
    const [defaultValues] = useState<IContentManagerRenameFormFields>(fields);
    const { errors, setError } = useEntityErrors<IContentManagerRenameFormFields>();
    const [valid, setValid] = useState(false);

    const nameValidator = useMemo(() => {
        return new NameValidator({
            nameExistErrorKey: "ContentFormValidator.FolderExistsAlready",
            nameIsUnique: (n: string) => nameIsUnique(n, parentFolderId),
        });
    }, [nameIsUnique, parentFolderId]);

    const { nameStatus, error: nameError } = useEntityNameValidation({
        defaultName: defaultValues.name,
        name: fields.name,
        nameValidator,
    });

    useEffect(() => {
        setError("name", nameError);
    }, [nameError, setError]);

    useEffect(() => {
        void nameValidator.isValid({ name: fields.name }).then(setValid);
    }, [fields.name, nameValidator]);

    return {
        errors,
        valid,
        validationStatuses: { name: nameStatus },
        setError,
    };
};
