import { useState, useEffect } from "react";
import { IServerConfiguration } from "../models/server/IServerConfiguration";
import { useServerConfig } from "./ServerConfigHook";

/**
 * The app config hook.
 */
export const useAppConfig = (): readonly [true, null] | readonly [false, IServerConfiguration] => {
    const { getConfig } = useServerConfig();

    const [config, setConfig] = useState<IServerConfiguration>();

    useEffect(() => {
        if (!config) {
            (async () => {
                setConfig(await getConfig());
            })();
        }
    }, [config, getConfig]);

    if (!config) {
        return [true, null] as const;
    }

    return [false, config] as const;
};
