/**
 * Keys format:
 * "permission": Not scoped to an objectId/projectId.
 * "permission:objectId": Scoped to an objectId.
 * "permission:objectId:projectId": Scoped to an objectId and a projectId.
 * "permission::projectId": Scoped to a projectId, not scoped to an objectId.
 */
export const formatPermissionKey = (permission: string, objectId?: string, projectId?: string): string => {
    if (projectId) {
        return `${permission}:${objectId || ""}:${projectId}`;
    }

    return objectId ? `${permission}:${objectId}` : permission;
};
