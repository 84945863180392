import React from "react";
import { IDependencyModel } from "../../models/dependencies/DependencyModel";
import { dataSetPaths, languagePaths, templatePaths } from "../../PathConstants";
import { useProjectContext } from "../../contexts/ProjectContext";
import { EntityIcon } from "../icons/EntityIcon";
import { dependencyTypeMap } from "../../helpers/DependencyMapHelper";
import { DependencyTableNameLink } from "./DependencyTableNameLink";

import "./DependencyTableNameItem.scss";

const linkPaths = (organizationName: string, projectName: string, entityType: string, entityId: string): string => {
    if (entityType === "dataset") {
        return `${dataSetPaths.link.edit(organizationName, projectName, entityId)}#overview`;
    }

    if (entityType === "template") {
        return `${templatePaths.link.edit(organizationName, projectName, entityId)}#overview`;
    }

    if (entityType === "language") {
        return `${languagePaths.link.edit(organizationName, projectName, entityId)}#overview`;
    }

    return "";
};

export const DependencyTableNameItem: React.FC<IDependencyModel> = ({
    entityId,
    entityName,
    entityType,
    restricted,
}: IDependencyModel) => {
    const { name: projectName, organizationName } = useProjectContext();
    const mappedEntityType = dependencyTypeMap[entityType];
    const editLink = linkPaths(organizationName, projectName, entityType, entityId);

    return (
        <div className="dependency-name-container">
            <EntityIcon type={mappedEntityType} />
            <DependencyTableNameLink editLink={editLink} entityName={entityName} restricted={restricted} />
        </div>
    );
};
