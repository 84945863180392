import React from "react";
import { createRoutesFromElements, RouterProvider } from "react-router";
import { createBrowserRouter, Route } from "react-router-dom";
import { authorizedRoutes } from "./components/routes/AuthorizedRoutes";
import { loginRoutes } from "./components/routes/LoginRoutes";
import { logoutRoutes } from "./components/routes/LogoutRoutes";
import { publicRoutes } from "./components/routes/PublicRoutes";
import App from "./App";

const mainRoutes = (
    <Route element={<App />}>
        {loginRoutes}
        {logoutRoutes}
        {authorizedRoutes}
        {publicRoutes}
    </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(mainRoutes));

export const AppRouter: React.FC = () => <RouterProvider router={router} />;
