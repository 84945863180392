/**
 * The entity className type enumeration.
 **/
export enum EntityTypeClassName {
    Organization = "fas fa-key text-orange",
    Project = "fas fa-briefcase text-blue",
    DataSet = "far fa-database",
    DataSetForm = "far fa-ballot text-blue",
    DataSetJson = "far fa-brackets-curly text-green",
    DataSetXml = "far fa-code text-orange",
    DataSetSample = "far fa-file-alt text-purple",
    ApiKey = "fas fa-id-card-alt text-darkblue",
    Template = "far fa-file-invoice text-red",
    TemplateStandard = "fas fa-rectangle-landscape text-green",
    Language = "far fa-globe text-blue",
    ServiceHook = "far fa-webhook",
}

export enum LightEntityTypeClassName {
    Organization = "fal fa-key text-orange",
    Project = "fal fa-briefcase text-blue",
    DataSet = "fal fa-database",
    DataSetForm = "fal fa-ballot text-blue",
    DataSetJson = "fal fa-brackets-curly text-green",
    DataSetXml = "fal fa-code text-orange",
    DataSetSample = "fal fa-file-alt text-purple",
    ApiKey = "fal fa-id-card-alt text-darkblue",
    Template = "fal fa-file-invoice text-red",
    TemplateStandard = "fal fa-rectangle-landscape text-green",
    Language = "fal fa-globe text-blue",
    ServiceHook = "fal fa-webhook",
}
