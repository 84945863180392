import React from "react";
import { Icon } from "@fluentui/react";
import { IFileTypeIconOptions, getFileTypeIconProps } from "@fluentui/react-file-type-icons";

const defaultIconSize = 24;

export const FluentIcon: React.FC<IFileTypeIconOptions> = ({
    type,
    extension,
    size = defaultIconSize,
}: IFileTypeIconOptions) => {
    return <Icon {...getFileTypeIconProps({ type, extension, size })} />;
};
