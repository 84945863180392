import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ContentManagerItemProperties } from "./ItemProperties";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { useSidePanelContext } from "../../../contexts/SidePanelContext";
import { FluentExtensionIcon } from "../../icons/FluentExtensionIcon";
import { FluentFolderIcon } from "../../icons/FluentFolderIcon";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { EntityType } from "../../../models/EntityType";
import { LoadingScreen } from "../../loading/LoadingScreen";
import { useItemPropertiesSidePanelDirtyHandler } from "../../../hooks/contentManager/itemProperties/ItemPropertiesSidePanelDirtyHandler";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { ContentManagerItemPropertiesMessageView } from "./ItemPropertiesMessageView";
import { useContentFileApi } from "../../../hooks/contentManager/ContentFileApiHook";
import { useContentFolderApi } from "../../../hooks/contentManager/ContentFolderApiHook";
import { BeforeNavigate } from "../../../hooks/contentManager/ContentManagerTreeHook";

import "./ItemProperties.scss";

interface IContentManagerItemPropertiesViewProps {
    selectedItems?: IContentEntityModel[];
    reselectHandler?: (currentId: string) => void;
    setBeforeNavigate: (fn: BeforeNavigate) => void;
}

export const ContentManagerItemPropertiesView: React.FC<IContentManagerItemPropertiesViewProps> = ({
    selectedItems,
    reselectHandler,
    setBeforeNavigate,
}) => {
    const projectContext = useProjectContext();
    const { get: getFolder, update: updateFolder } = useContentFolderApi(projectContext?.projectId || "");
    const { get: getFile, update: updateFile } = useContentFileApi(projectContext?.projectId || "");
    const [displayFailure, setDisplayFailure] = useState(false);
    const [itemProperties, setItemProperties] = useState<IContentEntityModel | null>(null);
    const { setSidePanelState } = useSidePanelContext();
    const { t } = useTranslation();

    const { currentItems, setIsDirty } = useItemPropertiesSidePanelDirtyHandler({
        selectedItems,
        reselectHandler,
        setBeforeNavigate,
    });

    useEffect(() => {
        if (!currentItems || currentItems.length !== 1) {
            const title =
                currentItems && currentItems.length > 1 ? (
                    <Trans i18nKey="ContentManager.ItemProperties.MultipleItemsTitle" count={currentItems.length} />
                ) : (
                    t("ContentManager.ItemProperties.NoItemsTitle")
                );
            const titleIcon = currentItems && currentItems.length > 1 ? "far fa-circle-check" : undefined;

            setSidePanelState((currentState) => ({
                title: title,
                titleIcon: titleIcon,
                entityIcon: undefined,
                content: currentState.content,
            }));
        }
    }, [currentItems, setSidePanelState, t]);

    const handleSetProperties = useCallback((item: IContentEntityModel) => {
        setDisplayFailure(false);
        setItemProperties(item);
    }, []);

    const getItemProperties = useCallback(
        async (item: IContentEntityModel) => {
            setItemProperties(null);
            const getProperties = item.type === "Folder" ? getFolder : getFile;

            try {
                const properties = await getProperties(item.id);
                handleSetProperties(properties);
            } catch (error) {
                defaultRequestErrorHandler(error);
                setDisplayFailure(true);
            }
        },
        [getFile, getFolder, handleSetProperties],
    );

    const getTitleIcon = useCallback((name: string, type: EntityType) => {
        if (type === "Folder") {
            return <FluentFolderIcon />;
        }

        return <FluentExtensionIcon name={name} />;
    }, []);

    useEffect(() => {
        if (currentItems && currentItems.length === 1) {
            setSidePanelState((prev) => ({
                ...prev,
                title: currentItems[0].name,
                entityIcon: undefined,
                titleIcon: getTitleIcon(currentItems[0].name, currentItems[0].type),
                titleMultiRow: false,
                classname: "item-properties-side-panel",
            }));

            getItemProperties(currentItems[0]);
        }
    }, [currentItems, getFile, getFolder, getItemProperties, getTitleIcon, setSidePanelState]);

    if (!currentItems || currentItems.length < 1) {
        return (
            <ContentManagerItemPropertiesMessageView>
                {t("ContentManager.ItemProperties.NoItemsMessage")}
            </ContentManagerItemPropertiesMessageView>
        );
    }

    if (currentItems.length > 1) {
        return <></>;
    }

    if (!itemProperties && displayFailure) {
        return (
            <ContentManagerItemPropertiesMessageView>
                {t("ContentManager.ItemProperties.LoadFailure")}
            </ContentManagerItemPropertiesMessageView>
        );
    }

    if (!itemProperties) {
        return <LoadingScreen>{t("ContentManager.ItemProperties.LoadingProperties")}</LoadingScreen>;
    }

    const updateContent = itemProperties.type === "Folder" ? updateFolder : updateFile;

    return (
        <ContentManagerItemProperties
            item={itemProperties}
            setDirty={setIsDirty}
            saveChanges={updateContent}
            refreshEntity={getItemProperties}
        />
    );
};
