import { useCallback, useEffect, useMemo, useReducer } from "react";
import { ITableFilterHelper } from "../../components/table/Table";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { IProjectModelWithOrganizationName } from "../../models/project/IProjectModelWithOrganizationName";
import { useDefaultTableFilterHelper } from "../table/DefaultTableFilterHelper";
import { homeReducer, initialHomeState } from "./HomeReducer";
import { useProjectHub } from "../../hooks/hubs/ProjectHub";
import { useOrganizationApi } from "../organization/OrganizationApiHook";
import { useProjectApi } from "../project/ProjectApiHook";

/**
 * The home hook props.
 */
interface HomeHookProps {
    onRefresh: () => void;
}

/**
 * The home hook return.
 */
interface HomeHookReturn {
    homeState: typeof initialHomeState;
    getProjects: (
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IProjectModelWithOrganizationName>>;
    toggleFullscreen: (state: boolean) => void;
    filterHelper: ITableFilterHelper<string>;
    homeOverlayLoading: boolean;
    showHomeViewDataVisibility: boolean;
    showNoOrganizationCTA: boolean;
}

/**
 * The home hook.
 */
export const useHome = ({ onRefresh }: HomeHookProps): HomeHookReturn => {
    const [homeState, dispatch] = useReducer(homeReducer, initialHomeState);
    const { getCounts: getOrganizationsCount } = useOrganizationApi();
    const { getCounts: getProjectsCount, getRecords, getRecentProjects } = useProjectApi();
    const filterHelper = useDefaultTableFilterHelper("Project.Filter.PlaceHolder");

    const projectCompleted = useCallback(() => {
        dispatch({ type: "TRIGGER_REFRESH", triggerRefresh: false });
        onRefresh();
        dispatch({ type: "TRIGGER_REFRESH", triggerRefresh: true });
    }, [onRefresh]);

    useProjectHub({ projectCompleted });

    const toggleFullscreen = useCallback((fullscreen: boolean) => {
        dispatch({ type: "TOGGLE_FULLSCREEN", fullscreen });
    }, []);

    const getProjects = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const projects = getRecords(filterValue, sortFields, offset);
            dispatch({ type: "PROJECT_LOADED" });
            return projects;
        },
        [getRecords],
    );

    useEffect(() => {
        (async () => {
            const recentProjects = await getRecentProjects();
            dispatch({ type: "RECENT_PROJECT_LOADED", recentProjects });
        })();
    }, [getRecentProjects]);

    useEffect(() => {
        (async () => {
            const projectsCount = await getProjectsCount();
            dispatch({ type: "SET_PROJECT_EXIST", projectExist: projectsCount > 0 });
        })();
    }, [getProjectsCount]);

    useEffect(() => {
        (async () => {
            const result = await getOrganizationsCount();
            dispatch({ type: "SET_ORGANIZATION_EXIST", organizationExist: result > 0 });
        })();
    }, [getOrganizationsCount]);

    const homeOverlayLoading = useMemo(
        () => !homeState.recentProjectsLoaded || !homeState.organizationExistLoaded,
        [homeState.recentProjectsLoaded, homeState.organizationExistLoaded],
    );

    const showNoOrganizationCTA = useMemo(
        () => !homeOverlayLoading && !homeState.organizationExist,
        [homeOverlayLoading, homeState.organizationExist],
    );

    const showHomeViewDataVisibility = useMemo(
        () => homeState.organizationExist && homeState.projectExist && !homeOverlayLoading,
        [homeState.organizationExist, homeState.projectExist, homeOverlayLoading],
    );

    return {
        homeState,
        getProjects,
        toggleFullscreen,
        filterHelper,
        homeOverlayLoading,
        showNoOrganizationCTA,
        showHomeViewDataVisibility,
    };
};
