import { useCallback } from "react";
import { useCancellableRequest } from "../CancellableRequestHook";
import { useHttpRequest } from "../HttpRequestHook";
import { IPermissionsGroupModel } from "../../models/permissions/IPermissionsGroupModel";
import { IEntityResult } from "../../models/IEntityResult";
import { ISortField } from "../../models/ISortField";
import { IUserModel } from "../../models/user/IUserModel";
import { IDeleteErrorResult } from "../../models/IDeleteErrorResult";
import { IAddMemberResultModel } from "../../models/permissions/IAddMemberResultModel";
import { normalizeQueryParams } from "../../helpers/HttpHelper";
import { IUserGroupModel } from "../../models/user/IUserGroupModel";
import { IPermissionGroupFormFieldsModel } from "../../models/permissions/IPermissionGroupFormFieldsModel";
import { IPermissionsGroupWithAccessInfoModel } from "../../models/permissions/IPermissionsGroupWithAccessInfoModel";

/**
 * The project group api hook return.
 */
interface ProjectGroupApiHookReturn {
    createGroup: (groupFields: IPermissionGroupFormFieldsModel) => Promise<IPermissionsGroupModel>;
    removeSingleGroup: (groupId: string) => Promise<void>;
    removeGroups: (groupId: string[]) => Promise<IDeleteErrorResult[]>;
    updateGroup: (group: IPermissionsGroupWithAccessInfoModel) => Promise<IPermissionsGroupWithAccessInfoModel>;
    addMembers: (groupId: string, userIds: string[]) => Promise<IAddMemberResultModel>;
    getById: (groupId: string) => Promise<IPermissionsGroupWithAccessInfoModel>;
    getAllGroups: () => Promise<IUserGroupModel[]>;
    getMemberRecords: (
        groupId: string,
        filterValue?: string,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IUserModel>>;
    getNonMembers: (groupId: string) => Promise<IUserModel[]>;
    getAllProjectUsers: () => Promise<IUserModel[]>;
    getRecords: (
        filters: Record<string, string>,
        sortFields?: ISortField[],
        offset?: number,
    ) => Promise<IEntityResult<IPermissionsGroupModel>>;
    removeMembers: (groupId: string, userIds: string[]) => Promise<IDeleteErrorResult[]>;
    nameIsUnique: (name: string) => Promise<boolean>;
}

interface IProjectGroupApiHookProps {
    projectId: string;
}

const baseUrl = (projectId: string, groupId?: string) =>
    `/_api/project/${projectId}/groups${groupId ? "/" + groupId : ""}`;

export const useProjectGroupApi = ({ projectId }: IProjectGroupApiHookProps): ProjectGroupApiHookReturn => {
    const { httpGetJson, httpPost, httpDeleteResponseJson, httpPut, httpDelete } = useHttpRequest();
    const { cancellableRequest } = useCancellableRequest();

    const createGroup = useCallback(
        (groupFields: IPermissionGroupFormFieldsModel) =>
            cancellableRequest<IPermissionsGroupModel, IPermissionGroupFormFieldsModel>(
                {
                    url: baseUrl(projectId),
                    body: groupFields,
                },
                httpPost,
            ),
        [cancellableRequest, httpPost, projectId],
    );

    const removeSingleGroup = useCallback(
        (groupId: string): Promise<void> => cancellableRequest({ url: baseUrl(projectId, groupId) }, httpDelete),
        [cancellableRequest, httpDelete, projectId],
    );

    const removeGroups = useCallback(
        (groupIds: string[]): Promise<IDeleteErrorResult[]> =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: baseUrl(projectId), body: groupIds },
                httpDeleteResponseJson,
            ),
        [cancellableRequest, httpDeleteResponseJson, projectId],
    );

    const addMembers = useCallback(
        (groupId: string, userIds: string[]) =>
            cancellableRequest<IAddMemberResultModel, string[]>(
                { url: `${baseUrl(projectId, groupId)}/members`, body: userIds },
                httpPost,
            ),
        [cancellableRequest, projectId, httpPost],
    );

    const getAllGroups = useCallback(
        () => cancellableRequest<IUserGroupModel[]>({ url: `${baseUrl(projectId)}/all` }, httpGetJson),
        [cancellableRequest, projectId, httpGetJson],
    );

    const getById = useCallback(
        (groupId: string) =>
            cancellableRequest<IPermissionsGroupWithAccessInfoModel>(
                { url: `${baseUrl(projectId, groupId)}` },
                httpGetJson,
            ),
        [cancellableRequest, projectId, httpGetJson],
    );

    const getMemberRecords = useCallback(
        (groupId: string, filterValue?: string, sortFields?: ISortField[], offset?: number) => {
            const filters: Record<string, string> = {};
            if (filterValue) {
                filters.Name = filterValue;
                filters.Email = filterValue;
            }

            return cancellableRequest<IEntityResult<IUserModel>>(
                {
                    url: `${baseUrl(projectId, groupId)}/members`,
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [cancellableRequest, projectId, httpGetJson],
    );

    const getNonMembers = useCallback(
        (groupId: string) =>
            cancellableRequest<IUserModel[]>({ url: `${baseUrl(projectId, groupId)}/nonmembers` }, httpGetJson),
        [cancellableRequest, projectId, httpGetJson],
    );

    const getAllProjectUsers = useCallback(
        () => cancellableRequest<IUserModel[]>({ url: `${baseUrl(projectId)}/users` }, httpGetJson),
        [cancellableRequest, httpGetJson, projectId],
    );

    const getRecords = useCallback(
        (filters: Record<string, string>, sortFields?: ISortField[], offset?: number) => {
            return cancellableRequest<IEntityResult<IPermissionsGroupModel>>(
                {
                    url: baseUrl(projectId),
                    queryParams: normalizeQueryParams({
                        filters,
                        sortFields,
                        offset,
                    }),
                },
                httpGetJson,
            );
        },
        [cancellableRequest, projectId, httpGetJson],
    );

    const removeMembers = useCallback(
        (groupId: string, userIds: string[]) =>
            cancellableRequest<IDeleteErrorResult[], string[]>(
                { url: `${baseUrl(projectId, groupId)}/members`, body: userIds },
                httpDeleteResponseJson,
            ),
        [cancellableRequest, projectId, httpDeleteResponseJson],
    );

    const updateGroup = useCallback(
        (group: IPermissionsGroupWithAccessInfoModel) =>
            cancellableRequest<IPermissionsGroupWithAccessInfoModel, IPermissionsGroupModel>(
                { url: `${baseUrl(projectId, group.groupId)}`, body: group },
                httpPut,
            ),
        [cancellableRequest, projectId, httpPut],
    );

    const nameIsUnique = useCallback(
        (name: string) =>
            cancellableRequest<boolean>(
                { url: `${baseUrl(projectId)}/nameisunique`, queryParams: { name } },
                httpGetJson,
            ),
        [cancellableRequest, projectId, httpGetJson],
    );

    return {
        createGroup,
        removeSingleGroup,
        removeGroups,
        updateGroup,
        addMembers,
        getById,
        getAllGroups,
        getMemberRecords,
        getNonMembers,
        getAllProjectUsers,
        getRecords,
        removeMembers,
        nameIsUnique,
    };
};
