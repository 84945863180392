import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationFormValidator } from "../../formValidators/OrganizationFormValidator";
import { EditEntityWrapper } from "../edit/EditEntityWrapper";
import { EditOrganizationViewTabs } from "./EditOrganizationViewTabs";
import { ObjectHelper } from "../../helpers/ObjectHelper";
import { useEditOrganization } from "../../hooks/organization/EditOrganizationHook";
import { SectionHeader } from "../views/sectionHeader/SectionHeader";
import { EditEntityName } from "../edit/EditEntityName";
import { DefaultEditDropdownMenu } from "../dropdowns/DefaultEditDropdownMenu";
import { EditEntityAvatar } from "../edit/EditEntityAvatar";

import "./EditOrganizationView.scss";

/**
 * The Edit Organization View component
 */
export const EditOrganizationView: React.FC = () => {
    const {
        isAllowed,
        permissionKeys: { organizationDeleteKey, organizationWriteKey },
        removeOrganization,
        nameIsUniqueValidator,
        loadOrganization,
        saveOrganization,
    } = useEditOrganization();

    const formValidatorProvider = useCallback(() => new OrganizationFormValidator(), []);
    const { t } = useTranslation();

    const canEdit = isAllowed(organizationWriteKey);
    const canDelete = isAllowed(organizationDeleteKey);

    return (
        <EditEntityWrapper
            className="edit-organization-view"
            nameIsUniqueProps={{
                nameExistErrorKey: "OrganizationFormValidator.OrganizationExists",
                nameIsUnique: nameIsUniqueValidator,
            }}
            deleteDialogProps={{
                dialogBodyKey: "CommonOrganization.DeleteBody",
                dialogTitleKey: "CommonOrganization.DeleteTitle",
                entityCount: 1,
            }}
            promptDialogMessageKey="Organization.UnsavedWarningBody"
            delete={removeOrganization}
            load={loadOrganization}
            update={saveOrganization}
            formValidatorProvider={formValidatorProvider}
            loadingScreenKey="OrganizationView.LoadingOrganization"
            propertiesComparator={{
                description: ObjectHelper.isNullableStringEqual,
                licenseKey: () => true,
            }}
            entityType="Organization"
            canEdit={canEdit}
            canDelete={canDelete}
            disableNameContext
            header={
                <SectionHeader>
                    <SectionHeader.Group flexGrow>
                        <EditEntityAvatar />
                        <SectionHeader.Group column>
                            <SectionHeader.OverTitle>{t("EntityType.Organization")}</SectionHeader.OverTitle>
                            <EditEntityName />
                        </SectionHeader.Group>
                    </SectionHeader.Group>
                    <SectionHeader.ActionGroup
                        withSaveButton={canEdit}
                        dropdownMenu={<DefaultEditDropdownMenu canDelete={canDelete} />}
                    />
                </SectionHeader>
            }
        >
            <EditOrganizationViewTabs />
        </EditEntityWrapper>
    );
};
