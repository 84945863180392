import * as Yup from "yup";
import { IDefaultEntityProps } from "../models/IDefaultEntityProps";
import { NullableValue } from "../models/NullableValue";
import { FormValidator } from "./FormValidator";
import { descriptionSchema } from "./PropertySchemas";

/**
 * The data set form validator class.
 */
export class DataSetFormValidator extends FormValidator<Omit<IDefaultEntityProps, "name">> {
    protected schema = Yup.object().shape({
        description: descriptionSchema(),
    });

    public validateDescription(description: NullableValue<string>): Promise<string | null | undefined> {
        return this.schema.fields.description.validate(description);
    }
}
