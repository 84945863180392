import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IOrganizationModel } from "../../models/organization/IOrganizationModel";
import { useCurrentEntitiesNameContext } from "../../contexts/CurrentEntitiesNameContext";
import { OrganizationContext } from "../../contexts/OrganizationContext";
import { LoadingScreen } from "../loading/LoadingScreen";
import { useOrganizationApi } from "../../hooks/organization/OrganizationApiHook";
import { NotificationService } from "../../services/NotificationService";
import { extractErrorMessage } from "../../helpers/ErrorHelper";
import { useNavigation } from "../../hooks/NavigationHook";
import { organizationPaths } from "../../PathConstants";
import { useSidebarContext } from "../../contexts/SidebarContext";

export const OrganizationProvider: React.FC = () => {
    const { get } = useOrganizationApi();
    const { organizationId } = useParams<{ organizationId: string }>() as Record<string, string>;
    const { setEntityName } = useCurrentEntitiesNameContext();
    const [organization, setOrganization] = useState<IOrganizationModel>();
    const { t } = useTranslation();
    const { replace } = useNavigation();
    const { setRefreshSidebar } = useSidebarContext();

    const load = useCallback(() => {
        get(organizationId)
            .then((org) => setOrganization(org))
            .catch((error) => {
                NotificationService.addErrorNotification({ message: extractErrorMessage(error) });
                replace(organizationPaths.link.base, { state: { loadError: true } });
                setRefreshSidebar(true);
                return {} as IOrganizationModel;
            });
    }, [get, organizationId, replace, setRefreshSidebar]);

    useEffect(() => {
        load();
    }, [load]);

    useEffect(() => {
        setEntityName("Organization", organization?.name ?? "");

        return () => setEntityName("Organization", "");
    }, [organization?.name, setEntityName]);

    const contextValue = useMemo(
        () =>
            organization
                ? {
                      organization,
                      refreshOrganization: load,
                  }
                : null,
        [load, organization],
    );

    if (!contextValue) {
        return <LoadingScreen>{t("OrganizationView.LoadingOrganization")}</LoadingScreen>;
    }

    return (
        <OrganizationContext.Provider value={contextValue}>
            <Outlet />
        </OrganizationContext.Provider>
    );
};
