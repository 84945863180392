import React from "react";
import * as Yup from "yup";
import { IProfileModel } from "../models/account/IProfileModel";
import { NullableValue } from "../models/NullableValue";
import { FormValidator } from "./FormValidator";
import { ValidatorError } from "./ValidatorError";

/**
 * The data set sample form validator class.
 */
export class ProfileFormValidator extends FormValidator<Omit<IProfileModel, "language" | "timeZone">> {
    public static readonly firstNameMaxLength: number = 256;
    public static readonly lastNameMaxLength: number = 256;
    public static readonly phoneNumberRegex: RegExp =
        /^(?:(?:\+|00)[1-9]\d{0,2}|0)?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}(?:[\s.-]?(?:#|ext|ext.|ext:|extension|poste)?[\s.-]?\d{1,5})?$/;

    protected schema = Yup.object().shape({
        firstName: Yup.string()
            .nullable(true)
            .strict(true)
            .trim("ProfileFormValidator.FirstNameLeadingTrailingSpacesError")
            .max(ProfileFormValidator.firstNameMaxLength, ({ max }) => {
                return (
                    <ValidatorError messageKey="ProfileFormValidator.FirstNameMaxLengthValidation" options={{ max }} />
                );
            }),
        lastName: Yup.string()
            .nullable(true)
            .strict(true)
            .trim("ProfileFormValidator.LastNameLeadingTrailingSpacesError")
            .max(ProfileFormValidator.lastNameMaxLength, ({ max }) => {
                return (
                    <ValidatorError messageKey="ProfileFormValidator.LastNameMaxLengthValidation" options={{ max }} />
                );
            }),
        phoneNumber: Yup.string()
            .nullable(true)
            .strict(true)
            .trim("ProfileFormValidator.PhoneNumberLeadingTrailingSpacesError")
            .matches(ProfileFormValidator.phoneNumberRegex, {
                excludeEmptyString: true,
                message: "ProfileFormValidator.PhoneNumberRegExValidation",
            }),
    });

    public validateFirstName(firstName: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.firstName.validate(firstName);
    }

    public validateLastName(lastName: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.lastName.validate(lastName);
    }

    public validatePhoneNumber(phoneNumber: NullableValue<string>): Promise<NullableValue<string> | undefined> {
        return this.schema.fields.phoneNumber.validate(phoneNumber);
    }
}
