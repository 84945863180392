import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTableContext } from "../table/TableContext";
import { ITableRowItem } from "../table/TableRow";
import { HeaderActionItem } from "./HeaderActionItem";

import "./TableHeaderCountPill.scss";

export const TableHeaderCountPill: React.FC = <
    TEntityModel,
    TRowItem extends ITableRowItem<TEntityModel>,
    TFilterValue,
>() => {
    const {
        unselectAll,
        tableState: { selectedItemIds },
    } = useTableContext<TEntityModel, TRowItem, TFilterValue>()!;
    const { t } = useTranslation();

    return (
        <div className="table-header-count-pill">
            <span>
                <Trans i18nKey="Table.SelectedCount" values={{ count: selectedItemIds.length }} />
            </span>
            <HeaderActionItem
                className="clear-select-action"
                icon="fa fa-xmark-large"
                onAction={unselectAll}
                ariaLabel={t("Select.UnselectAll")}
            />
        </div>
    );
};
