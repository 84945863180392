import React from "react";
import { Button } from "../buttons/Button";
import { useTestSelector } from "../../hooks/AutomatedTestsServiceHook";

/**
 * The action navbar toggle item props interface.
 */
export interface IActionNavbarToggleItemProps {
    activeIcon: string;
    className?: string;
    icon: string;
    disabled?: boolean;
    testSelectorValue?: string;
    isActive: () => boolean;
    onAction: () => void;
    ariaLabel: string;
}

/**
 * The action navbar toggle item functional component.
 */
export const HeaderToggleItem: React.FunctionComponent<IActionNavbarToggleItemProps> = ({
    activeIcon,
    className,
    icon,
    disabled,
    testSelectorValue,
    isActive,
    onAction,
    ariaLabel,
}: IActionNavbarToggleItemProps): JSX.Element => {
    const { setSelector } = useTestSelector();

    return (
        <Button
            className={className}
            disabled={disabled}
            onClick={onAction}
            ariaLabel={ariaLabel}
            {...setSelector(testSelectorValue)}
        >
            <i className={isActive() ? activeIcon : icon} />
        </Button>
    );
};
