import React from "react";
import * as DOMPurify from "dompurify";
import { NotificationService } from "../services/NotificationService";

export const extractErrorMessage = (error: unknown): string | undefined => {
    if (error) {
        if (error instanceof Error) {
            return error.message;
        }

        if (typeof error === "string") {
            return error;
        }

        if (typeof error === "object" && "message" in error && typeof error.message === "string") {
            return error.message;
        }
    }

    return undefined;
};

export const extractErrorMessageAsHtml = (error: unknown): JSX.Element => {
    const errorMessage = extractErrorMessageOrDefault(error, "");

    // eslint-disable-next-line @typescript-eslint/naming-convention
    return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(errorMessage) }} />;
};

export const extractErrorMessageOrDefault = (error: unknown, defaultErrorMessage: string): string => {
    return extractErrorMessage(error) ?? defaultErrorMessage;
};

export const extractErrorMessageOrEmptyString = (error: unknown): string => {
    return extractErrorMessageOrDefault(error, "");
};

const parseUnknownError = (error: unknown) => {
    if (error && typeof error === "object" && error instanceof Error) {
        return {
            messageKey: "Common.UnknownErrorOccured",
            messageKeyParams: { errorMessage: error.cause },
        };
    }

    return { messageKey: "Common.UnknownError" };
};

export const defaultRequestErrorHandler = (error: unknown): void => {
    let notificationObject;

    if (error && typeof error === "object" && "messageKey" in error && typeof error.messageKey === "string") {
        notificationObject = { messageKey: error.messageKey };
    } else {
        const errorMessage = extractErrorMessage(error);
        notificationObject = errorMessage ? { message: errorMessage } : parseUnknownError(error);
    }

    NotificationService.addErrorNotification(notificationObject);
};
