import React from "react";
import classNames from "classnames";
import { Input, InputProps, InputGroup, InputGroupText, Spinner } from "reactstrap";
import { FormFeedbackError } from "../feedbacks/FormFeedbackError";

import "./ValidatedInputWithStatus.scss";

/**
 * The validated input with status props interface.
 */
export interface IValidatedInputWithStatusProps extends InputProps {
    error?: string | JSX.Element;
    isBusy: boolean;
    hideStatus?: boolean;
    name: string;
}

/**
 * The validated input with status component.
 */
export const ValidatedInputWithStatus: React.FunctionComponent<IValidatedInputWithStatusProps> = ({
    error,
    isBusy,
    hideStatus = false,
    name,
    ...inputProps
}: IValidatedInputWithStatusProps) => {
    return (
        <InputGroup className={classNames("validated-input-with-status", "has-validation")}>
            <Input id={name} invalid={!isBusy && !!error} {...inputProps} />
            {(isBusy || (!error && inputProps.value)) && !hideStatus && (
                <InputGroupText className="validation-status icon-right">
                    {isBusy ? <Spinner /> : <i className="fas fa-check-circle text-primary" />}
                </InputGroupText>
            )}
            {!isBusy && error && <FormFeedbackError error={error} />}
        </InputGroup>
    );
};
