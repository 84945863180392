import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useEditEntityContext } from "../../../contexts/EditEntityContext";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { buildOverview, buildHistory, buildShares, buildVersioning } from "../../../helpers/EntityHelper";
import { useTabs } from "../../../hooks/TabsHook";
import { NavMenu } from "../../navbars/NavMenu";
import { INavMenuItemProps } from "../../navbars/NavMenuItem";
import { IContentEntityModel } from "../../../models/contentManager/ContentManagerApiModels";
import { ContentFormValidator } from "../../../formValidators/ContentFormValidator";
import { EditItemOverview } from "./EditItemOverview";
import { TabContent } from "../../tabs/TabContent";
import { useContentFileApi } from "../../../hooks/contentManager/ContentFileApiHook";
import { HistoryTable } from "../../table/HistoryTable";
import { EditFileShareTableView } from "./EditFileShareTableView";
import { VersionsView } from "../../versioning/VersionsView";
import { ISortField } from "../../../models/ISortField";
import { VersionComment } from "../../../models/versioning/VersioningModels";

import "./EditItemViewTabs.scss";

/**
 * the list of tabs in the edit file view
 */
const editFileViewTabs = ["overview", "shares", "history", "versions"] as const;

/**
 * the list of tabs in the edit file view as Type
 */
type EditFileTabType = (typeof editFileViewTabs)[number];

/**
 * The edit file view tabs
 */
export const EditFileViewTabs: React.FC = () => {
    const { projectId } = useProjectContext();
    const [activeTab, setActiveTab] = useTabs<EditFileTabType>(editFileViewTabs, "overview");
    const {
        entityProps: { entity: editableFile },
        dirtyProps: { isDirty },
        errorProps: { errors },
    } = useEditEntityContext<IContentEntityModel, ContentFormValidator>();
    const { t } = useTranslation();
    const { getHistoryRecords, getVersioningRecords, updateVersionComment, restoreVersion } =
        useContentFileApi(projectId);
    const tabs: INavMenuItemProps[] = [
        buildOverview(!!errors.description, isDirty(["description"]), setActiveTab),
        buildShares(false, false, setActiveTab),
        buildHistory(false, false, setActiveTab),
        buildVersioning(false, false, setActiveTab),
    ];

    const getRecords = useCallback(
        (updatedEntityDate?: string, offset?: number) => getHistoryRecords(editableFile.id, updatedEntityDate, offset),
        [editableFile.id, getHistoryRecords],
    );

    const getVersionRecords = useCallback(
        (filterValue?: string, sortFields?: ISortField[], offset?: number) =>
            getVersioningRecords(editableFile.id, filterValue, sortFields, offset),
        [editableFile.id, getVersioningRecords],
    );

    const handleUpdateVersionComment = useCallback(
        (versionId: string, comment: VersionComment) => updateVersionComment(editableFile.id, versionId, comment),
        [editableFile.id, updateVersionComment],
    );

    return (
        <NavMenu activeTab={activeTab} navTabs={tabs}>
            <TabContent tabId="overview" className="overview" title={t("Common.Overview")}>
                <EditItemOverview />
            </TabContent>
            <TabContent tabId="shares" className="shares" title={t("Common.Shares")}>
                <EditFileShareTableView fileId={editableFile.id} />
            </TabContent>
            <TabContent tabId="history" className="history" title={t("Common.History")}>
                <HistoryTable getRecords={getRecords} updatedEntityDate={editableFile.modifiedOn} entityType="File" />
            </TabContent>
            <TabContent tabId="versions" className="versions" title={t("Common.Versions")}>
                <VersionsView
                    getVersioningRecords={getVersionRecords}
                    updateVersionComment={handleUpdateVersionComment}
                    entityId={editableFile.id}
                    updatedEntityDate={editableFile.modifiedOn}
                    restore={restoreVersion}
                    type="File"
                />
            </TabContent>
        </NavMenu>
    );
};
