import React, { useMemo, useState } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";

interface ISidebarProviderProps {
    children?: React.ReactNode;
}

/**
 * The Sidebar Provider component.
 */
export const SidebarProvider: React.FC<ISidebarProviderProps> = (props: ISidebarProviderProps) => {
    const [refreshSidebar, setRefreshSidebar] = useState(false);

    const sidebarContextValue = useMemo(() => ({ refreshSidebar, setRefreshSidebar }), [refreshSidebar]);

    return <SidebarContext.Provider value={sidebarContextValue}>{props.children}</SidebarContext.Provider>;
};
