import React from "react";
import { Spinner } from "reactstrap";

/**
 * The status icon props interface.
 */
interface IStatusIconProps {
    isValidating: boolean;
}

/**
 * The status icon component.
 */
export const StatusIcon: React.FunctionComponent<IStatusIconProps> = (props: IStatusIconProps) => {
    const { isValidating } = props;

    return (
        <div className="status-icon">
            {isValidating ? <Spinner size="sm" /> : <i className="fas fa-check-circle text-primary" />}
        </div>
    );
};
