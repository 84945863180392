import { useCallback } from "react";
import { useProjectApi } from "../project/ProjectApiHook";
import { useUserApi } from "../user/UserApiHook";

export type PermissionState = "allowed" | "denied" | "unknown";

interface PermissionResult {
    permission: string;
    state: PermissionState;
}

interface PermissionCheck {
    permission: string;
    objectId?: string;
    projectId?: string;
}

interface PermissionHookReturn {
    getPermissions: (permissionChecks: PermissionCheck[]) => Promise<PermissionResult[]>;
}

export const usePermission = (): PermissionHookReturn => {
    const { hasPermissions: userHasPermissions } = useUserApi();
    const { hasPermissions: projectHasPermissions } = useProjectApi();

    const getPermissions = useCallback(
        async (permissionChecks: PermissionCheck[]): Promise<PermissionResult[]> => {
            const organizationPermissionCheck: PermissionCheck[] = [];
            const projectPermissionCheck: PermissionCheck[] = [];

            let projectId = "";

            for (const permissionCheck of permissionChecks) {
                if (permissionCheck.projectId) {
                    projectPermissionCheck.push(permissionCheck);
                    projectId = permissionCheck.projectId;
                } else {
                    organizationPermissionCheck.push(permissionCheck);
                }
            }
            const [organizationResult, projectResult] = await Promise.all([
                organizationPermissionCheck.length
                    ? userHasPermissions(organizationPermissionCheck)
                    : Promise.resolve([]),
                projectId ? projectHasPermissions(projectId, projectPermissionCheck) : Promise.resolve([]),
            ]);

            const results = Object.entries({ ...organizationResult, ...projectResult }).map<PermissionResult>(
                ([originalkey, value]) => {
                    return {
                        permission: originalkey,
                        state: value ? "allowed" : "denied",
                    };
                },
            );
            return results;
        },
        [userHasPermissions, projectHasPermissions],
    );

    return {
        getPermissions,
    };
};
