import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../buttons/Button";
import { INotificationOptions, NotificationService } from "../../../services/NotificationService";
import { twoFactorAuthenticationPaths } from "../../../PathConstants";
import { useNavigation } from "../../../hooks/NavigationHook";
import { useTwoFAContext } from "../../../contexts/TwoFAContext";
import { LoadingScreen } from "../../loading/LoadingScreen";
import { defaultRequestErrorHandler } from "../../../helpers/ErrorHelper";
import { TranslationLink } from "../../hubs/TranslationLink";
import { useAccountApi } from "../../../hooks/account/AccountApiHook";

/**
 * The Manage 2FA Component.
 */
export const Manage2FA: React.FC = () => {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { forgetTwoFABrowser } = useAccountApi();
    const [ready, setReady] = useState(false);

    const { twoFASettings, fetchSettings } = useTwoFAContext();

    const onForgetBrowser = useCallback(async () => {
        try {
            await forgetTwoFABrowser();
            NotificationService.addSuccessNotification({
                messageKey: "2FA.ForgetBrowser.Success",
            });
            await fetchSettings();
        } catch (error) {
            defaultRequestErrorHandler(error);
        }
    }, [forgetTwoFABrowser, fetchSettings]);

    useEffect(() => {
        (async () => {
            await fetchSettings();
        })();
    }, [fetchSettings]);

    useEffect(() => {
        if (twoFASettings) {
            setReady(true);
        }
    }, [twoFASettings]);

    useEffect(() => {
        if (twoFASettings && ready && twoFASettings.is2faEnabled) {
            const options: Partial<INotificationOptions> = {
                components: {
                    translationlink: <TranslationLink to={twoFactorAuthenticationPaths.route.resetRecoveryCodes} />,
                },
            };

            if (twoFASettings.recoveryCodesLeft === 0) {
                options.messageKey = "2FA.RecoveryLeft.None";
            } else if (twoFASettings.recoveryCodesLeft === 1) {
                options.messageKey = "2FA.RecoveryLeft.One";
            } else if (twoFASettings.recoveryCodesLeft <= 3) {
                options.messageKey = "2FA.RecoveryLeft.ThreeOrLess";
                options.messageKeyParams = {
                    codes: twoFASettings.recoveryCodesLeft.toString(),
                };
            }

            NotificationService.addWarningNotification(options);
        }
    }, [twoFASettings, ready]);

    if (!ready || !twoFASettings) {
        return <LoadingScreen />;
    }

    const { is2faEnabled, isMachineRemembered } = twoFASettings;

    return (
        <>
            <section>
                <h2>{t("2FA.Authenticator.Title")}</h2>
                <div className="buttons-wrapper">
                    <Button
                        color="primary"
                        onClick={() => navigate(twoFactorAuthenticationPaths.route.configure)}
                        ariaLabel={t(is2faEnabled ? "2FA.Authenticator.Setup" : "2FA.Authenticator.Add")}
                    >
                        <i className={is2faEnabled ? "fas fa-cog" : "far fa-plus"} />
                        {is2faEnabled ? t("2FA.Authenticator.Setup") : t("2FA.Authenticator.Add")}
                    </Button>
                    {is2faEnabled && (
                        <Button
                            outline
                            color="secondary"
                            onClick={() => navigate(twoFactorAuthenticationPaths.route.resetAuthenticator)}
                            ariaLabel={t("2FA.Authenticator.Reset")}
                        >
                            {t("2FA.Authenticator.Reset")}
                        </Button>
                    )}
                </div>
            </section>
            {is2faEnabled && (
                <section>
                    <h2>{t("2FA.Authenticator.EnabledTitle")}</h2>
                    <div className="buttons-wrapper">
                        <Button
                            color="danger"
                            onClick={() => navigate(twoFactorAuthenticationPaths.route.disable)}
                            ariaLabel={t("2FA.Disable")}
                        >
                            <i className="far fa-ban" />
                            {t("2FA.Disable")}
                        </Button>
                        <Button
                            outline
                            color="secondary"
                            onClick={() => navigate(twoFactorAuthenticationPaths.route.resetRecoveryCodes)}
                            ariaLabel={t("2FA.ResetRecoveryCodes")}
                        >
                            {t("2FA.ResetRecoveryCodes")}
                        </Button>
                        {isMachineRemembered && (
                            <Button
                                outline
                                color="secondary"
                                onClick={onForgetBrowser}
                                ariaLabel={t("2FA.ForgetBrowser")}
                            >
                                {t("2FA.ForgetBrowser")}
                            </Button>
                        )}
                    </div>
                </section>
            )}
        </>
    );
};
