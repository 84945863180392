import React, { lazy, Suspense } from "react";
import { DataSetLoading } from "../DataSetLoading";

// eslint-disable-next-line @typescript-eslint/naming-convention
const EditDataSetView = lazy(() =>
    import("../EditDataSetView").then((module) => ({ default: module.EditDataSetView })),
);

export const LazyEditDataSetView = (props: React.ComponentProps<typeof EditDataSetView>) => (
    <Suspense fallback={<DataSetLoading />}>
        <EditDataSetView {...props} />
    </Suspense>
);
