import React from "react";
import { AvatarWrapper } from "../avatars/AvatarWrapper";
import { useEditEntityContext } from "../../contexts/EditEntityContext";

export const EditEntityAvatar: React.FC = () => {
    const {
        entityProps: { initialEntity },
    } = useEditEntityContext();

    return <AvatarWrapper className="entity-avatar" name={initialEntity.name} size="48" />;
};
